import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
export default {
  decode: function (input, options) {
    let events = [],
      regions = [],
      region;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(JSON.stringify(fileJson, null, 4));

    /* Put the region data into an array */
    if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
      if (Array.isArray(fileJson.tt.head.layout.region)) {
        regions = fileJson.tt.head.layout.region;
      } else {
        regions = [fileJson.tt.head.layout.region];
      }
    }
    if (Array.isArray(fileJson.tt.body.div)) {
      fileJson.tt.body.div.forEach(paragraphGroup => {
        if (Array.isArray(paragraphGroup.p)) {
          paragraphGroup.p.forEach(paragraph => {
            if (paragraph["@_region"]) {
              region = regions.find(regionEl => {
                return regionEl["@_xml:id"] === paragraph["@_region"];
              });
            } else {
              region = false;
            }
            events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
          });
        } else {
          if (paragraphGroup.p["@_region"]) {
            region = regions.find(regionEl => {
              return regionEl["@_xml:id"] === paragraphGroup.p["@_region"];
            });
          } else {
            region = false;
          }
          events.push(ttmlFunc.multiLine.decodeSubtitle(paragraphGroup.p, region, options.frameRate, options.window));
        }
      });
    } else {
      fileJson.tt.body.div.p.forEach(paragraph => {
        if (paragraph["@_region"]) {
          region = regions.find(regionEl => {
            return regionEl["@_xml:id"] === paragraph["@_region"];
          });
        } else {
          region = false;
        }
        events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
      });
    }
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let tcFormat = encodingOptions["Timecode Format"] || "smpte";
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let regions = "";
    let paragraphs = "";
    eventGroup.events.forEach((event, index) => {
      let plainTextCustom = convertToPlainTextCustom(event.text, "\n", false, "strong", "em", "u", true);
      let plainText = convertToPlainText(event.text);
      let start = tcFormat === "smpte" ? tcLib.secToTcClock(event.start, options.frameRate) : tcLib.secToTcMs(event.start).replace(",", ".");
      let end = tcFormat === "smpte" ? tcLib.secToTcClock(event.end, options.frameRate) : tcLib.secToTcMs(event.end).replace(",", ".");
      let regionId = `${event.style}_${index + 1}`;
      let style = "basic";
      let extents = ttmlFunc.multiLine.calcExtents(plainText, encodingOptions["Font Size (%)"]);
      let origins = ttmlFunc.multiLine.calcOrigin(plainText, event.xPos, event.xOffset, event.yPos, event.yOffset, encodingOptions["Font Size (%)"], options.window);
      let alignment = event.alignment;
      regions += eol.after(`<region xml:id="${regionId}" tts:showBackground="whenActive" tts:origin="${origins}" tts:extent="${extents}" tts:textAlign="${alignment}" tts:overflow="visible" tts:wrapOption="noWrap"></region>`);
      paragraphs += eol.after(`<div>`);
      paragraphs += eol.after(`<p begin="${start.replace(";", ":")}" end="${end.replace(";", ":")}" style="${style}" region="${regionId}">${ttmlFunc.multiLine.convertToTtml(plainTextCustom)}</p>`);
      paragraphs += eol.after(`</div>`);
    });
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xml:lang="${encodingOptions["Language Code"] || "en"}" xmlns="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt" xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:profile="http://www.w3.org/ns/ttml/profile/imsc1/text" xmlns:ebutts="urn:ebu:tt:style" xmlns:itts="http://www.w3.org/ns/ttml/profile/imsc1#styling" xmlns:ittp="http://www.w3.org/ns/ttml/profile/imsc1#parameter" xmlns:ittm="http://www.w3.org/ns/ttml/profile/imsc1#metadata" ittp:aspectRatio="${encodingOptions["Aspect Ratio"] === "4:3" ? "4 3" : "16 9"}">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>
        <ttm:title>${encodingOptions["Program Name"] || "Untitled"}</ttm:title>
        <ttm:desc>IMSC1 Text Profile document created by Closed Caption Converter</ttm:desc>
        <smpte:information xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" mode="Preserved" m608:channel="CC1" m608:programName="${encodingOptions["Program Name"] || "Untitled"}" m608:captionService="F1C1CC"/>
    </metadata>`);
    output += eol.after(`<styling>
        <style xml:id="basic" tts:fontFamily="${encodingOptions["Font Family"] || "monospaceSerif"}" tts:fontSize="${encodingOptions["Font Size"] || "80"}%" tts:fontWeight="${encodingOptions["Font Weight"] || "normal"}" tts:fontStyle="${encodingOptions["Font Style"] || "normal"}" tts:lineHeight="${encodingOptions["Line Height"] || "100"}%"/>
    </styling>`);
    output += eol.after(`<layout>`);
    /* Regions Go Here: */
    output += regions;
    output += eol.after(`</layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += paragraphs;
    output += eol.after(`</body>`);
    output += `</tt>`;

    //console.log(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};