<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    tick
} from 'svelte';
import eol from 'eol';

/* CC LIB */
import _Event from "@app/external/cc-lib/dist/classes/event.js";
import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js'
import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
import encodings from "@app/external/cc-lib/dist/dict/encodings.js";
import autoFormatSimple from '@app/external/cc-lib/dist/functions/utility/autoFormatSimple.js';
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
import supportedLanguages from '@app/external/cc-lib/dist/dict/supportedLanguages.js';

let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
let plaintextImportDefaults = JSON.parse(localStorage.getItem("cc-plaintext-import-defaults")) || {};

/* Default Settings */
let srcFileName,
    showOptions = false,
    files,
    fileEncoding = defaults.encoding,
    fileContents = "",
    eventGroup = "new",
    groupType = "subtitle",
    importOption = "merge",
    language = eventGroupDefaults.language || "en-US",
    rtl = eventGroupDefaults.rtl,
    languages = supportedLanguages.languages,
    /* Import Options */
    blockImport = plaintextImportDefaults.blockImport,
    eventLinesOptions = plaintextImportDefaults.eventLinesOptions,
    eventLines = plaintextImportDefaults.eventLines || 1,
    endEventOnPunctuation = plaintextImportDefaults.endEventOnPunctuation,
    endEventOnComma = plaintextImportDefaults.endEventOnComma,
    endEventOnCharOption = plaintextImportDefaults.endEventOnCharOption,
    endEventOnChar = plaintextImportDefaults.endEventOnChar,
    startEventOnCharOption = plaintextImportDefaults.startEventOnCharOption,
    startEventOnChar = plaintextImportDefaults.startEventOnChar,
    maxCharsOption = plaintextImportDefaults.maxCharsOption,
    maxChars = plaintextImportDefaults.maxChars || 32,
    autoFormatEvent = plaintextImportDefaults.autoFormatEvent;

function updateFile() {
    if (files && files[0]) {
        let fileName = files[0].name.split(".");
        srcFileName = fileName.shift();
        updateFileContents();
    }
}

function updateFileContents() {
    if (files && files[0]) {
        toast.push("Reading file contents...", {classes: ['toast-warning']});
        let reader = new FileReader();
        reader.onload = function(e) {
            fileContents = e.target.result;
        };

        reader.readAsText(files[0], fileEncoding);
    }
}

async function importTranscriptFile() {
    toast.push("Importing events...", {classes: ['toast-warning']});

    let events = [],
        event,
        alignment = "center",
        endEventFlag = false;

    let lines = [];
    let line = "";
    let singleWord = false;
    let ccAdded = false;
    let maximumChars = maxCharsOption ? maxChars : 42;
    let maxLines = eventLinesOptions ? eventLines : 4;

    if ($eventGroupState[$projectState.selected]){
        $eventGroupState[$projectState.selected].selected = [];
        await tick();
    }

    /* Create or select the event group */
    if (eventGroup === "new") {
        let eventGroupOptions = {
            type: groupType,
            name: srcFileName,
            language: language,
            rtl: rtl
        }

        alignment = "left";
        $eventGroupState = [...$eventGroupState, new _EventGroup(eventGroupOptions)];
        $projectState.selected = $eventGroupState.length - 1;
    } else {
        $projectState.selected = $eventGroupState.findIndex(group => {
            return group.id === eventGroup;
        });

        if ($eventGroupState[$projectState.selected].type === "subtitle") {
            alignment = "center";
        } else {
            alignment = $eventGroupState[$projectState.selected].rtl ? "right" : "left";
        }
    }
    
    let paragraphs = eol.lf(fileContents).split("\n\n");
    while (paragraphs && paragraphs.length > 0) {
        let paragraph = paragraphs.shift();  
        if (blockImport){
            event = new _Event({
                text : convertToHtml(paragraph),
                alignment : alignment
            });

            events.push(event);
        } else {          
            paragraph=paragraph.split("\n");
            while (paragraph && paragraph.length > 0) {
                ccAdded = false;
                let words = paragraph.shift().split(" ");
                if (words.length === 1) {
                    singleWord = true;
                } else {
                    singleWord = false;
                }

                event = new _Event({
                    alignment: alignment
                });

                line = "";
                lines = [];
                while (words.length > 0) {
                    let word = words.shift();
                    if (line.length + word.length > maximumChars) {
                        lines.push(line);
                        line = word;
                    } else {
                        line += " " + word;
                    }

                    //console.log(lines.length, maxLines);
                    if (lines.length === maxLines) {
                        event.text = convertToHtml(lines.join(" "));
                        events.push(event);
                        ccAdded = true;
                        lines = [];
                        event = new _Event({
                            alignment: alignment
                        });
                    }

                    if (endEventOnCharOption && word.includes(endEventOnChar)) {
                        endEventFlag = true;
                    }

                    if (endEventOnComma && word.includes(",")) {
                        endEventFlag = true;
                    }

                    if (endEventOnPunctuation && word.match(/\.|\?|\!/g)) {
                        endEventFlag = true;
                    }

                    if (words[0] && startEventOnCharOption && words[0].includes(startEventOnChar)) {
                        endEventFlag = true;
                    }


                    if (endEventFlag && (line.length > 0 || lines.length >0)){
                        if (line.length > 0){
                            lines.push(line);
                            line = "";
                        }

                        event.text = convertToHtml(lines.join(" "));
                        events.push(event);
                        ccAdded = true;
                        lines = [];
                        event = new _Event({
                            alignment: alignment
                        });

                        endEventFlag = false;
                    } 
                    
                }

                if (line.length > 0){
                    lines.push(line);
                    event.text = convertToHtml(lines.join(" "));
                    events.push(event);
                } else if (lines.length > 0){
                    event.text = convertToHtml(lines.join(" "));
                    events.push(event);
                }
            }
        }        
    }

    if (autoFormatEvent){
        events.forEach((event, eventIndex, events) =>{
            events[eventIndex].text = autoFormatSimple(event.text);
        });
    }

    events = events.filter(ccEvent => {
        return convertToPlainText(ccEvent.text) !== "";
    });

    if (eventGroup === "new" || importOption === "replace") {
        $eventGroupState[$projectState.selected].events = events;
    } else {
        $eventGroupState[$projectState.selected].events = [...$eventGroupState[$projectState.selected].events, ...events];
    }

    historyState.insert({
        name: "import transcript", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    localStorage.setItem("cc-plaintext-import-defaults", JSON.stringify({
        blockImport : blockImport,
        eventLinesOptions: eventLinesOptions,
        eventLines: eventLines,
        endEventOnPunctuation: endEventOnPunctuation,
        endEventOnComma: endEventOnComma,
        endEventOnCharOption: endEventOnCharOption,
        endEventOnChar: endEventOnChar,
        startEventOnCharOption: startEventOnCharOption,
        startEventOnChar: startEventOnChar,
        maxCharsOption: maxCharsOption,
        maxChars: maxChars,
        autoFormatEvent: autoFormatEvent
    }));

    modalState.hideModal();
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'plainTextImport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="PlainTextImportModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Plain Text Import</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p class="small text-muted"><i class="bi bi-lightning-fill"></i> Powered by Closed Caption Converter | www.closedcaptionconverter.com</p>

                <div class="row">
                    <div class="col">
                        <!-- Import Form Start -->
                        <form on:submit|preventDefault="{importTranscriptFile}">
                            <!-- File Input -->
                            <div class="mb-3 shadow bg-secondary rounded p-3">
                                <label class="form-label" for="subtitleFilePicker">Select Transcript File <i class="bi bi-info-circle" title="Supported formats: .txt"></i></label>
                                <input  type="file" class="form-control" id="subtitleFilePicker" bind:files accept=".txt" on:change="{updateFile}">
                            </div>
                            <div class="row">
                                <!-- Target Event Group Option Start -->
                                <div class="mb-3 col-6">
                                    <label class="form-label text-warning" for="EventGroupSelection">Event Group</label>
                                    <select class="form-select" bind:value="{eventGroup}">
                                        {#each $eventGroupState as eventGroupOption}
                                            <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                                        {/each}
                                        <option class="fw-bold" value="new">*NEW*</option>
                                    </select>
                                </div>
                                <!-- Target Event Group Option End -->
                                <!-- Merge or Replace Option Start -->
                                {#if eventGroup !== "new"}
                                <div class="mb-3 col-6">
                                    <p >Merge/Replace Events <i class="bi bi-info-circle" title="Merge - Add events to existing group without deleting any other events. Replace - Removes all events in existing group prior to import"></i></p>

                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" bind:group={importOption} name="importOption" id="mergeImportOption" value="merge" checked>
                                        <label class="form-check-label" for="mergeImportOption">
                                            Merge
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" bind:group={importOption} name="importOption" id="replaceImportOption" value="replace">
                                        <label class="form-check-label" for="replaceImportOption">
                                            Replace
                                        </label>
                                    </div>
                                </div>
                                {:else}
                                <!-- Language -->
                                <div class="mb-3 col-12">
                                    <label class="form-label text-warning" for="Language">Group Type</label>
                                    <select class="form-select" bind:value={groupType}>
                                        <option value="subtitle">Subtitle</option>
                                        <option value="transcription">Transcription</option>
                                    </select>
                                </div>
                                <!-- Language -->
                                <div class="mb-3 col-12">
                                    <label class="form-label text-warning" for="Language">Group Language</label>
                                    <select class="form-select" bind:value={language}>
                                        {#each languages as languageOption, index(index)}
                                        <option>{languageOption}</option>
                                        {/each}
                                    </select>
                                </div>
                                <div class="mb-3 col-12">
                                    <div class="form-check form-switch mb-2">
                                        <input type="checkbox" class="form-check-input" id="rtlSwitch" bind:checked={rtl}>
                                        <label class="form-check-label" for="rtlSwitch">Right-To-Left</label>
                                    </div>
                                </div>
                                {/if}
                                <!-- Merge or Replace Option End -->
                            </div>
                            <button type="button" class="btn btn-outline-warning mb-2 dropdown-toggle" on:click="{()=> {showOptions = !showOptions}}">More Options</button>
                            <!-- Start of Advanced Settings -->
                            {#if showOptions}
                            <div id="advancedOptions" class="p-3 bg-secondary shadow rounded">
                                <div class="row pt-2 mb-2">
                                    <h5 class="col-12 lead text-warning">Import Options</h5>
                                </div>
                                <div class="mb-3 mt-2">
                                    <label class="form-label" for="srcFileEncoding">File Encoding</label>
                                    <select id="srcFileEncoding" class="form-select" bind:value="{fileEncoding}" on:change="{updateFileContents}">
                                        {#each encodings as encodingOption}
                                        <option>{encodingOption}</option>
                                        {/each}
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label" for="eventLineOptions">Max Lines Per Event <i class="bi bi-info-circle" title="The maximum lines per event before creating a new event."></i></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" bind:checked="{eventLinesOptions}" disabled={blockImport}>
                                        </div>
                                        <input type="number" class="form-control" min="1" step="1" bind:value="{eventLines}" disabled="{!eventLinesOptions || blockImport}">                                        
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label" for="eventLineOptions">Max Characters Per Line <i class="bi bi-info-circle" title="The maximum characters per line before creating a new line in the same event."></i></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" bind:checked="{maxCharsOption}" disabled={blockImport}>
                                        </div>
                                        <input type="number" min="1" step="1" bind:value="{maxChars}" disabled="{!maxCharsOption || blockImport}" class="form-control">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label" for="eventLineOptions">End Event On Character <i class="bi bi-info-circle" title="The maximum characters per line before creating a new line in the same event."></i></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" bind:checked="{endEventOnCharOption}" disabled={blockImport}>
                                        </div>
                                        <input type="text" maxlength="1" bind:value="{endEventOnChar}" disabled="{!endEventOnCharOption || blockImport}" class="form-control">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label" for="eventLineOptions">Start Event On Character <i class="bi bi-info-circle" title="The maximum characters per line before creating a new line in the same event."></i></label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" bind:checked="{startEventOnCharOption}" disabled={blockImport}>
                                        </div>
                                        <input type="text" maxlength="1" bind:value="{startEventOnChar}" disabled="{!startEventOnCharOption || blockImport}" class="form-control">
                                    </div>
                                </div>

                                <div class="mb-3 form-check">
                                    <input id="endEventOnPunctuationOption" type="checkbox" class="form-check-input" bind:checked="{endEventOnPunctuation}" disabled={blockImport}>
                                    <label class="form-check-label" for="endEventOnPunctuationOption">End event on punctuation (. ? !)</label>
                                </div>
                                <div class="mb-3 form-check">
                                    <input id="endEventOnCommaOption" type="checkbox" class="form-check-input" bind:checked="{endEventOnComma}" disabled={blockImport}>
                                    <label class="form-check-label" for="endEventOnCommaOption">End event on comma</label>
                                </div>
                                <div class="mb-3 form-check">
                                    <input id="blockImportOption" type="checkbox" class="form-check-input" bind:checked="{blockImport}">
                                    <label class="form-check-label" for="blockImportOption">Block Import</label>
                                </div>
                                <div class="mb-3 form-check">
                                    <input id="autoFormatOption" type="checkbox" class="form-check-input" bind:checked="{autoFormatEvent}">
                                    <label class="form-check-label" for="autoFormatOption">Auto Format Events <i class="bi bi-info-circle" title="Auto format each event to appear as pyramid shaped"></i></label>
                                </div>
                            </div>
                            <!-- End of Advanced Settings -->
                            {/if}
                        </form>
                        <!-- Import Form End -->
                    </div>
                    <div class="col">
                        <!-- File Preview -->
                        <form id="SourcePreviewForm">
                            <div class="mb-3">
                                <label class="form-label" for="srcPreviewText">Source Preview</label>
                                <textarea bind:value="{fileContents}" disabled class="form-control" id="srcPreviewText" rows="10" placeholder="Please select a file from the menu on the left."></textarea>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" on:click="{importTranscriptFile}" disabled="{fileContents && fileContents.length === 0}">Import Transcript</button>
            </div>
        </div>
    </div>
</div>

    <style>
#advancedOptions {
    overflow-y: auto;
    overflow-x: none;
    max-height: 30vh;
}
</style>
