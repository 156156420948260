<script>
/* Firebase */
import firebase from '@app/configs/firebase.js';
async function logout() {
    try {
        firebase.auth().signOut();
    } catch (error) {
        console.log(error);
    } finally {
        window.location.reload();
    }   
}
</script>
<a class="dropdown-item" 
   href="#!/" 
   id="logoutMenuItem"
   role="menuitem"
   aria-label="Logout from application"
   on:click={logout}>Logout</a>