<script>
  import { historyState } from "@app/store/historyStore.js";
  import { eventGroupState } from "@app/store/eventGroupStore.js";
  import { projectState } from "@app/store/projectStore.js";
  import { createEventDispatcher } from "svelte";
  import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
  const dispatch = createEventDispatcher();

  function autoTimeEvents() {
    try {
      if (
        !$eventGroupState[$projectState.selected] ||
        $eventGroupState[$projectState.selected].selected.length === 0
      ) {
        return;
      }

      let cps = $eventGroupState[$projectState.selected].maxCps || 22;
      let minDuration = $eventGroupState[$projectState.selected].minDuration || 0.83;
      let currentTime = player ? player.currentTime : 0;

      $eventGroupState[$projectState.selected].selected
        .sort()
        .forEach((eventIndex) => {
          try {
            let event = $eventGroupState[$projectState.selected].events[eventIndex];
            let text = convertToPlainText(event.text, "");
            let characters = text.length;

            if (characters === 0 || cps === 0) {
              return;
            }

            let duration = Math.max(minDuration, characters / cps);
            if (event.start > 0 && event.end > 0) {
              //Check if the next event exists, and if updating the end time of the event will cause an overlap
              if (
                !$eventGroupState[$projectState.selected].events[eventIndex + 1]
              ) {
                //If the next event does not exist, update the end time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              } else if (
                $eventGroupState[$projectState.selected].events[eventIndex + 1]
                  .start >=
                $eventGroupState[$projectState.selected].events[eventIndex]
                  .start +
                  duration
              ) {
                //If the next event exists and there is no overlap, update the end time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              } else if (
                !$eventGroupState[$projectState.selected].events[
                  eventIndex - 1
                ] &&
                $eventGroupState[$projectState.selected].events[eventIndex]
                  .end -
                  duration >=
                  0
              ) {
                //If the previous event does not exist, update the start time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              } else if (
                $eventGroupState[$projectState.selected].events[eventIndex - 1]
                  .end <=
                $eventGroupState[$projectState.selected].events[eventIndex]
                  .end -
                  duration
              ) {
                //If the previous event exists and there is no overlap, update the start time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              } else {
                //Just update the end of the Event and deal with the overlap.
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              }
            } else if (event.start > 0) {
              if (
                !$eventGroupState[$projectState.selected].events[eventIndex + 1]
              ) {
                //If the next event does not exist, update the end time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              } else if (
                $eventGroupState[$projectState.selected].events[eventIndex + 1]
                  .start >=
                $eventGroupState[$projectState.selected].events[eventIndex]
                  .start +
                  duration
              ) {
                //If the next event exists and there is no overlap, update the end time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              } else if (
                !$eventGroupState[$projectState.selected].events[eventIndex - 1]
              ) {
                //If the previous event does not exist, update the start time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[
                    eventIndex + 1
                  ].start;
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              } else if (
                $eventGroupState[$projectState.selected].events[eventIndex - 1]
                  .end <=
                $eventGroupState[$projectState.selected].events[eventIndex + 1]
                  .start -
                  duration
              ) {
                //If the previous event exists and there is no overlap, update the start time of the event
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[
                    eventIndex + 1
                  ].start;
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              } else {
                //Just update the end of the Event and deal with the overlap.
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              }
            } else if (event.end > 0) {
              //End time exists so we base the start time on that
              if (
                !$eventGroupState[$projectState.selected].events[eventIndex - 1]
              ) {
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              } else if (
                $eventGroupState[$projectState.selected].events[eventIndex - 1]
                  .end <=
                $eventGroupState[$projectState.selected].events[eventIndex]
                  .end -
                  duration
              ) {
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              } else if (
                !$eventGroupState[$projectState.selected].events[eventIndex + 1]
              ) {
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[
                    eventIndex - 1
                  ].end;
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              } else if (
                $eventGroupState[$projectState.selected].events[eventIndex + 1]
                  .start >=
                $eventGroupState[$projectState.selected].events[eventIndex - 1]
                  .end +
                  duration
              ) {
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[
                    eventIndex - 1
                  ].end;
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].end =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .start + duration;
              } else {
                $eventGroupState[$projectState.selected].events[
                  eventIndex
                ].start =
                  $eventGroupState[$projectState.selected].events[eventIndex]
                    .end - duration;
              }
            } else {
              if (
                currentTime === 0 &&
                $eventGroupState[$projectState.selected].events[
                  eventIndex - 1
                ] &&
                $eventGroupState[$projectState.selected].events[eventIndex - 1]
                  .end > 0
              ) {
                currentTime =
                  $eventGroupState[$projectState.selected].events[
                    eventIndex - 1
                  ].end;
              }

              $eventGroupState[$projectState.selected].events[
                eventIndex
              ].start = currentTime;
              $eventGroupState[$projectState.selected].events[eventIndex].end =
                currentTime + duration;
              currentTime = currentTime + duration;
            }
          } catch (err) {
            console.log(err, err.message);
          }
        });

      historyState.insert({
        name: "adjust duration", //action name
        eventGroup: $projectState.selected,
        snapshots: [
          {
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
          },
        ],
      });
    } catch (err) {
      console.log(err);
      console.log(err.message);
    } finally {
      dispatch("done");
    }
  }
</script>

<a id="btnAutoTime"
   role="menuitem"
   aria-label="Calculate timing by characters per second"
   class="dropdown-item" href="#!/" on:click={autoTimeEvents}
  >Calculate Timing by CPS</a
>
