import _eol from "eol";
const eol = _eol;
export default (function reverseText(text, reverseChars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ") {
  //console.log(text);
  let textLines = eol.split(text);
  let reverseText = "";
  textLines.forEach(textLine => {
    let numbersOn = false;
    let numbers = "";
    let chars = textLine.split("");
    chars.forEach(char => {
      if (numbersOn && reverseChars.includes(char)) {
        numbers = char + numbers;
      } else if (numbersOn) {
        numbersOn = false;
        reverseText += numbers;
        numbers = "";
        if (char === ")") {
          reverseText += "(";
        } else if (char === "(") {
          reverseText += ")";
        } else if (char === "{") {
          reverseText += "}";
        } else if (char === "}") {
          reverseText += "{";
        } else if (char === "[") {
          reverseText += "]";
        } else if (char === "]") {
          reverseText += "[";
        } else if (char === "<") {
          reverseText += ">";
        } else if (char === ">") {
          reverseText += "<";
        } else {
          reverseText += char;
        }
      } else if (reverseChars.includes(char)) {
        numbersOn = true;
        numbers = char;
      } else if (char === ")") {
        reverseText += "(";
      } else if (char === "(") {
        reverseText += ")";
      } else if (char === "{") {
        reverseText += "}";
      } else if (char === "}") {
        reverseText += "{";
      } else if (char === "[") {
        reverseText += "]";
      } else if (char === "]") {
        reverseText += "[";
      } else if (char === "<") {
        reverseText += ">";
      } else if (char === ">") {
        reverseText += "<";
      } else {
        reverseText += char;
      }
    });
    if (numbers.length > 0) {
      reverseText += numbers;
    }
    reverseText += "\n";
  });
  //console.log(reverseText.trim());
  return reverseText.trim();
});