<script>
  import { historyState } from "@app/store/historyStore.js";
  import { eventGroupState } from "@app/store/eventGroupStore.js";
  import { projectState } from "@app/store/projectStore.js";
  import { createEventDispatcher } from "svelte";
  import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
  const dispatch = createEventDispatcher();

  function autoTimeEvents() {
    try {
      if (
        !$eventGroupState[$projectState.selected] ||
        $eventGroupState[$projectState.selected].selected.length === 0
      ) {
        return;
      }

      $eventGroupState[$projectState.selected].selected
        .sort()
        .forEach((eventIndex) => {
          try {
            let startTime = ($eventGroupState[$projectState.selected].events[eventIndex-1]?.end ||  $eventGroupState[$projectState.selected].events[eventIndex].start || 0.1) + 0.5;

            let duration = $eventGroupState[$projectState.selected].events[eventIndex].audioFileDuration || convertToPlainText($eventGroupState[$projectState.selected].events[eventIndex].text, "").length/25;
            
            $eventGroupState[$projectState.selected].events[eventIndex].start = startTime;
            $eventGroupState[$projectState.selected].events[eventIndex].end = startTime + duration;            
          } catch (err) {
            console.log(err, err.message);
          }
        });

      historyState.insert({
        name: "adjust duration", //action name
        eventGroup: $projectState.selected,
        snapshots: [
          {
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState),
          },
        ],
      });
    } catch (err) {
      console.log(err);
      console.log(err.message);
    } finally {
      dispatch("done");
    }
  }
</script>

<a id="btnAutoTime"
   role="menuitem"
   aria-label="Calculate timing by audio duration"
   class="dropdown-item" href="#!/" on:click={autoTimeEvents}
  >Calculate Timing by Audio Duration</a
>
