import { writable } from 'svelte/store';
import online from "../configs/status.js";
/* Firebase */
import firebase from '@app/configs/firebase.js'; //Required even if not used.
import db from '@app/configs/firestore.js';

function createAutoReplaceStore(){
    const { subscribe, update, set} = writable([]);
    return {
        subscribe,
        loadListFromDb : async function(userId){
            let autoReplaceState = [];
            // Use local storage instead of firebase when online is set to false.
            if (online){
                let snapshot = await db.collection("users").doc(userId).collection("shortforms").get();
                snapshot.forEach(doc =>{
                    // console.log(doc.data());
                    autoReplaceState = [...autoReplaceState, doc.data()];
                });   
            } else {
                autoReplaceState = JSON.parse(localStorage.getItem("cc-auto-replace")) || [];
            }
            
            //Sort the list by the the list name.
            autoReplaceState.sort((a,b) => {
                return a.name.localeCompare(b.name);
            });

            return set(autoReplaceState);
        },
        createList : (listInfo, userId) => update(autoReplaceState => {
            // Use local storage instead of firebase when online is set to false.

            if (online){
                db.collection("users").doc(userId).collection("shortforms").doc(listInfo.id).set(listInfo);
            } else {
                localStorage.setItem("cc-auto-replace", JSON.stringify([...autoReplaceState, listInfo]));
            }

            autoReplaceState.push(listInfo); 
            return autoReplaceState;
        }),
        removeList : (listId, userId) => update(autoReplaceState => {
            // Use local storage instead of firebase when online is set to false.
            if (online){
                db.collection("users").doc(userId).collection("shortforms").doc(listId).delete();
            } else {
                localStorage.setItem("cc-auto-replace", JSON.stringify(autoReplaceState.filter(list => {
                    return list.id !== listId;
                })));
            }
            
            return autoReplaceState.filter(list => {
                return list.id !== listId;
            });
        }),
        updateList : (listInfo, userId) => update(autoReplaceState => {
            // Use local storage instead of firebase when online is set to false.
            if (online){
                db.collection("users").doc(userId).collection("shortforms").doc(listInfo.id).set(listInfo);
                let listIndex = autoReplaceState.findIndex(list =>{
                    return list.id === listInfo.id
                });
    
                autoReplaceState.splice(listIndex,1,listInfo);
            } else {
                let listIndex = autoReplaceState.findIndex(list =>{
                    return list.id === listInfo.id
                });

                autoReplaceState.splice(listIndex,1,listInfo);

                localStorage.setItem("cc-auto-replace", JSON.stringify(autoReplaceState));
            }
            
            return autoReplaceState;
        })
    }
}

export const autoReplaceState = createAutoReplaceStore();