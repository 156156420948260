import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _insertMissingOutcodes from "../functions/eventGroups/insertMissingOutcodes.js";
import _cheetahFunc from "../functions/profiles/cheetahBinFunc.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const cheetahFunc = _cheetahFunc;
const insertMissingOutcodes = _insertMissingOutcodes;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent,
      char,
      hexBuffer,
      header,
      tcFlag = false,
      textFlag = false,
      formatting,
      incodeHex,
      outcodeHex,
      italicFlag = false;
    hexBuffer = input.match(/(..?)/g);
    header = hexBuffer.splice(0, 128);
    /* debug logger */
    //console.log(header.join(" ") + "\n");
    //console.log(hexBuffer.join(" "));
    while (hexBuffer.length > 0) {
      char = hexBuffer.shift();
      if (!tcFlag) {
        if ((char === "61" || char === "62" || char === "63") && parseInt(hexBuffer[0], 16) < 24) {
          tcFlag = true;
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          incodeHex = hexBuffer.splice(0, 4);
          outcodeHex = hexBuffer.splice(0, 4);

          /* Timecode Debug */
          // console.log(char);
          // console.log(incodeHex + "-->" + cheetahFunc.decodeTc(incodeHex));
          // console.log(outcodeHex + "-->" + cheetahFunc.decodeTc(outcodeHex));

          ccEvent.start = tcLib.tcToSec(cheetahFunc.decodeTc(incodeHex), options.frameRate);
          ccEvent.end = tcLib.tcToSec(cheetahFunc.decodeTc(outcodeHex), options.frameRate);
          italicFlag = false;
          let posData = hexBuffer.splice(0, 9);
          let vHex = parseInt(posData[7], 16);
          // console.log("vHex: " + vHex);
          //Convert vHex to an integer. If the integer is less than 6 than set yPos to start. If it's greater than 9 set yPos to end. Otherwise set yPos to middle.
          if (vHex < 6) {
            ccEvent.yPos = "start";
          } else if (vHex > 9) {
            ccEvent.yPos = "end";
          } else {
            ccEvent.yPos = "middle";
          }
          textFlag = true;
        } else if ((char === "40" || char === "41" || char === "42" || char === "43") && parseInt(hexBuffer[0], 16) < 24) {
          tcFlag = true;
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          incodeHex = hexBuffer.splice(0, 4);

          /* Timecode Debug */
          // console.log(char);
          // console.log(incodeHex + "-->" + cheetahFunc.decodeTc(incodeHex));

          ccEvent.start = tcLib.tcToSec(cheetahFunc.decodeTc(incodeHex), options.frameRate);
          italicFlag = false;
          if (char !== "40") {
            let posData = hexBuffer.splice(0, 9);
            let vHex = parseInt(posData[7], 16);
            // console.log("vHex: " + vHex);
            if (vHex < 6) {
              ccEvent.yPos = "start";
            } else if (vHex > 9) {
              ccEvent.yPos = "end";
            } else {
              ccEvent.yPos = "middle";
            }
            textFlag = true;
          }
        }
      } else if (!textFlag && tcFlag) {
        if (char === "22") {
          textFlag = true;
          formatting = hexBuffer.splice(0, 8);
        } else if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
          ccEvent.text += "\n";
          tcFlag = false;
        }
      } else if (textFlag) {
        if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
          ccEvent.text += "\n";
          /* Remove the rest of the null codes */
          hexBuffer.splice(0, 3);
        } else if (char === "00" && hexBuffer[0] != "00") {
          if (parseInt(hexBuffer[1], 16) > 0 && parseInt(hexBuffer[1], 16) < 16) {
            if (italicFlag) {
              ccEvent.text += "</em>";
              italicFlag = false;
            }
            ccEvent.text += "\n";
            /* Remove the rest of the null codes */
            hexBuffer.splice(0, 3);
          } else {
            tcFlag = false;
            textFlag = false;
            //console.log(ccEvent.text);
            if (italicFlag) {
              ccEvent.text += "</em>";
              italicFlag = false;
            }
            ccEvent.text = ccEvent.text.replace(/<\/em><em>/g, "<\/em> <em>");
            // console.log(ccEvent.text);
            ccEvent.text = convertToHtml(ccEvent.text.trim());
            // console.log(ccEvent.text);
            events.push(ccEvent);
            hexBuffer.splice(0, 1);
          }
        } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) <= 149) {
          /* Extended Characters */
          ccEvent.text += cheetahFunc.charLookup[char];
        } else if (char === "c0") {
          /* Open Italics */
          if (italicFlag) {
            ccEvent.text += "</em>";
            italicFlag = false;
          } else {
            ccEvent.text += "<em>";
            italicFlag = true;
          }
        } else if (char === "d0") {
          /* Close Italics */
          if (italicFlag) {
            ccEvent.text += "</em>";
            italicFlag = false;
          } else {
            ccEvent.text += "<em>";
            italicFlag = true;
          }
        } else {
          ccEvent.text += cheetahFunc.decodeChar(char, 1252);
        }
      }
    }
    return events;
  },
  encode: function (eventGroup, options) {
    try {
      // Create a copy of the events and sort by start time
      let events = [...eventGroup.events].sort((a, b) => a.start - b.start);

      // Insert dash events between captions with gaps
      const eventsWithDashes = [];
      for (let i = 0; i < events.length; i++) {
        eventsWithDashes.push(events[i]);

        // If not the last event, check for a gap
        if (i < events.length - 1) {
          const currentEnd = events[i].end;
          const nextStart = events[i + 1].start;

          // If there's a gap, insert a dash event
          if (nextStart - currentEnd > 0.1) {
            // More than 3 frames at 29.97fps
            const dashEvent = {
              start: currentEnd,
              end: nextStart,
              text: "-",
              // Single dash character for testing
              alignment: "center",
              yPos: "end"
            };
            eventsWithDashes.push(dashEvent);
          }
        }
      }
      eventsWithDashes.push({
        start: events[events.length - 1].end,
        end: events[events.length - 1].end + 1,
        text: "-",
        // Single dash character for testing
        alignment: "center",
        yPos: "end"
      });

      // Initial buffer setup - 128 byte header
      let buffer = [];

      // Add header bytes
      buffer.push(0xEA, 0x22, 0x01, 0x00);

      // Add number of paragraphs (low byte, high byte) - now including dash events
      const numberOfLines = eventsWithDashes.length;
      buffer.push(numberOfLines % 256);
      buffer.push(Math.floor(numberOfLines / 256));

      // Add fixed bytes - using the values from working example
      buffer.push(0x09, 0xA8, 0xAF, 0x4F);

      // Fill the rest of the header with zeros (total header size is 128 bytes)
      for (let i = 0; i < 118; i++) {
        buffer.push(0x00);
      }

      // Process each event (including dash events)
      for (let index = 0; index < eventsWithDashes.length; index++) {
        const event = eventsWithDashes[index];

        // Better HTML to plain text conversion for handling line breaks
        let text = convertToPlainText(event.text);
        // Check for multi-line subtitles
        const textLines = text.split('\n');

        // Convert start and end times to timecode
        const startTc = tcLib.secToTc(event.start, eventGroup.frameRate);
        const endTc = tcLib.secToTc(event.end, eventGroup.frameRate);

        // console.log("--------------------");
        // console.log("Text: " + text);
        // console.log("Start TC: " + startTc);
        // console.log("End TC: " + endTc);

        // Prepare text bytes
        const textBytes = [];
        let italic = false;

        // Check if text has italic formatting
        if (event.text.includes('<em>') || event.text.includes('<i>')) {
          italic = true;
          textBytes.push(0xD0); // Open italic
        }

        // Process text content character by character
        for (let j = 0; j < text.length; j++) {
          if (text[j] === '\n') {
            // Handle line breaks - ALWAYS add exactly 4 null bytes
            textBytes.push(0x00, 0x00, 0x00, 0x00);

            // If text was in italics, reopen italics tag after line break
            if (italic) {
              textBytes.push(0xD0);
            }
          } else {
            // Handle normal characters and special characters
            const char = text[j];
            let charCode;

            // Check if it's a special character using reverse lookup
            const specialCharEntry = Object.entries(cheetahFunc.charLookup).find(([_, val]) => val === char);
            if (specialCharEntry) {
              charCode = parseInt(specialCharEntry[0], 16);
            } else {
              // Regular character - convert to Windows-1252 encoding
              if (char === "-" && text.length === 1) {
                // Special case for dash events. Add invisible space character
                charCode = 0x0A;
              } else {
                charCode = char.charCodeAt(0);
              }
            }
            textBytes.push(charCode);
          }
        }

        // Make sure text doesn't already end with multiple null bytes
        // Remove any trailing nulls first
        while (textBytes.length > 0 && textBytes[textBytes.length - 1] === 0) {
          textBytes.pop();
        }

        // Add exactly one null byte to terminate the text
        textBytes.push(0x00);

        // Determine if we should use full format or short format
        const useFullFormat = true;

        // Style information based on alignment
        let justification = 3; // Default center
        let verticalPos = 0x0F; // Default bottom
        let horizontalPos = 0x10; // Default center

        // Check for alignment
        if (event.alignment === 'left') {
          justification = 1;
          horizontalPos = 0x02;
        } else if (event.alignment === 'right') {
          justification = 2;
          horizontalPos = 0x1E;
        }

        // Check for vertical position
        if (event.yPos === 'start') {
          verticalPos = 0x01;
        } else if (event.yPos === 'middle') {
          verticalPos = 0x08;
        }

        // Full style block
        const fullStyles = [0x12, 0x01, 0x00, 0x00, 0x00, 0x00, justification, verticalPos, horizontalPos];

        // Short style block
        const shortStyles = [0x00, 0x00, justification, verticalPos, horizontalPos];

        // Calculate total entry length (excluding the length byte itself)
        let length;
        let typeByte;

        // For dash events, use a special type byte (0x40) like in the example
        if (useFullFormat) {
          // Full format with end time
          // Each line number corresponds to a different type byte (0x61 for 1 line, 0x62 for 2 lines, etc.)
          typeByte = 0x61 + Math.min(textLines.length - 1, 3); // Cap at 4 lines (0x64)
          length = textBytes.length + 20;
        } else {
          // Short format without end time
          typeByte = 0x41 + Math.min(textLines.length - 1, 3); // Cap at 4 lines (0x44)
          length = textBytes.length + 16;
        }

        // Record the start position for this entry (after length byte)
        const entryStartPos = buffer.length;

        // Add length byte
        buffer.push(length - 1);

        // Add type byte
        buffer.push(typeByte);

        // Add start time - properly extract frames
        const startTcParts = startTc.split(':');
        const startHours = parseInt(startTcParts[0]);
        const startMinutes = parseInt(startTcParts[1]);
        const startSeconds = parseInt(startTcParts[2]);
        const startFrames = parseInt(startTcParts[3] || 0); // Get actual frames, not a calculation

        buffer.push(startHours, startMinutes, startSeconds, startFrames);
        if (useFullFormat) {
          // Add end time for full format - properly extract frames
          const endTcParts = endTc.split(':');
          const endHours = parseInt(endTcParts[0]);
          const endMinutes = parseInt(endTcParts[1]);
          const endSeconds = parseInt(endTcParts[2]);
          const endFrames = parseInt(endTcParts[3] || 0); // Get actual frames, not a calculation

          buffer.push(endHours, endMinutes, endSeconds, endFrames);

          // Add style information
          buffer.push(...fullStyles);
        } else {
          // For short format, add fixed values and short style
          buffer.push(0x02, 0x01, 0x00, 0x00);
          buffer.push(...shortStyles);
        }

        // Add text bytes
        buffer.push(...textBytes);

        // Check if we need to pad with zeros to reach the expected length
        // The +1 for length byte should not be included in the padding calculation
        const bytesWritten = buffer.length - entryStartPos;
        const paddingNeeded = length - bytesWritten - 1; // +1 because length byte isn't counted in 'length'

        if (paddingNeeded > 0) {
          for (let i = 0; i < paddingNeeded; i++) {
            buffer.push(0x00);
          }
        }
      }

      // Convert binary buffer to hex string
      return Buffer.from(buffer).toString('hex');
    } catch (err) {
      console.error('Error encoding Cheetah Cap binary:', err);
      throw new Error("Cheetah Cap binary encoding failed: " + err.message);
    }
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input.toLowerCase();
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      eventGroup = insertMissingOutcodes(eventGroup);
      eventGroup.events = eventGroup.events.filter(event => {
        return event.end - event.start < 20;
      });
      return eventGroup;
    }
  }
};