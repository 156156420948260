<script>
    import {
        modalState
    } from '@app/store/modalStore.js';
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import {
        historyState
    } from '@app/store/historyStore.js';
    import {
        projectState
    } from '@app/store/projectStore.js';
    import { toast } from '@zerodevx/svelte-toast';
    import {
        fade
    } from 'svelte/transition';
    
    let srcGroupId = "", trgGroupId = "";

    function copyTimecodesFromGroup(){
        try {
            let srcIndex = $eventGroupState.findIndex(eventGroup =>{
            return eventGroup.id === srcGroupId;
            });

            let trgIndex = $eventGroupState.findIndex(eventGroup =>{
                return eventGroup.id === trgGroupId;
            });

            if (srcIndex === trgIndex){
                throw new Error("Source and target event groups match. Nothing to copy.");
            }
            
            $eventGroupState[srcIndex].events.forEach((event, index) => {
                if ($eventGroupState[trgIndex].events[index]){
                    $eventGroupState[trgIndex].events[index].start = event.start;
                    $eventGroupState[trgIndex].events[index].end = event.end;
                }
            });

            toast.push("Timecodes copied successfully", {
                classes: ["toast-success"]
            });

            historyState.insert({
                name: "copy timecode", //action name
                eventGroup: $projectState.selected,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });
        } catch(err){
            console.log(err, err.message);

            toast.push("Timecode copy failed", {
                classes: ["toast-danger"]
            });
        } finally {
            modalState.hideModal();
        }
    }
    
    </script>
    
    <div transition:fade="{{duration: 100}}" class="modal {$modalState === 'copyTcFromGroup' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="BlankFramesModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">TC Copy</h4>
                    <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
                </div>
                <div class="modal-body">
                    <form on:submit|preventDefault="{copyTimecodesFromGroup}">
                        <div class="mb-3">
                            <label class="form-label" for="blank frames">Source Group</label>
                            <select class="form-select" bind:value="{srcGroupId}">
                                {#each $eventGroupState as eventGroupOption}
                                    <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                                {/each}
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="blank frames">Target Group</label>
                            <select class="form-select" bind:value="{trgGroupId}">
                                {#each $eventGroupState as eventGroupOption}
                                    <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                                {/each}
                            </select>
                        </div>
                        <button class="btn btn-primary float-end" type="button" on:click="{copyTimecodesFromGroup}">Copy</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    