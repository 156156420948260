<script>
import {
    fade
} from 'svelte/transition';
import { onMount } from 'svelte';
import { clickOutside } from 'svelte-use-click-outside';
import ContextMenuCut from './ContextMenuCut.svelte';
import ContextMenuCopy from './ContextMenuCopy.svelte';
import ContextMenuCopyText from './ContextMenuCopyText.svelte';
import ContextMenuPaste from './ContextMenuPaste.svelte';
import ContextMenuDelete from './ContextMenuDelete.svelte';
import ContextMenuAutoFormat from './ContextMenuAutoFormat.svelte';
import ContextMenuAutoTime from './ContextMenuAutoTime.svelte';
import ContextMenuMerge from './ContextMenuMerge.svelte';
import ContextMenuApprove from './ContextMenuApprove.svelte';
import ContextMenuForcedSubtitle from './ContextMenuForcedSubtitle.svelte';
import ContextMenuSelectAll from './ContextMenuSelectAll.svelte';
import ContextMenuEventTemplate from './ContextMenuEventTemplate.svelte';
import ContextMenuPasteAttributes from './ContextMenuPasteAttributes.svelte';
import MenuDivider from './MenuDivider.svelte';

export let x;
export let y;
export let showContextMenu = false;

let contextMenuHeight;

onMount(async () => {
    //get the screen height and the context menu height to see if the context menu is going past the screen height
    contextMenuHeight = parseInt(document.getElementById("EditorContextMenu").offsetHeight);
});

function calculateYPosition() {
    if (contextMenuHeight === undefined) {
        return;
    }
    
    let screenHeight = parseInt(window.innerHeight);
    //if the context menu is going past the screen height, then adjust the y position
    if (parseInt(y) + contextMenuHeight > screenHeight) {
        y = screenHeight - contextMenuHeight;        
    }
}

function closeContextMenu(){
    showContextMenu = false;
}

$: calculateYPosition(y);
</script>

<div id="EditorContextMenu" class="dropdown-menu" transition:fade={{ duration: 250 }} style="top: {y}px; left: {x}px;" use:clickOutside={closeContextMenu}>
    <h6 class="dropdown-header">Menu</h6>
    <ContextMenuCut on:done={closeContextMenu}></ContextMenuCut>
    <ContextMenuCopy on:done={closeContextMenu}></ContextMenuCopy>
    <ContextMenuCopyText on:done={closeContextMenu}></ContextMenuCopyText>
    <ContextMenuPaste on:done={closeContextMenu}></ContextMenuPaste>
    <ContextMenuPasteAttributes on:done={closeContextMenu}></ContextMenuPasteAttributes>
    <!-- Divider -->
    <MenuDivider></MenuDivider>
    <ContextMenuDelete on:done={closeContextMenu}></ContextMenuDelete>
    <!-- Divider -->
    <MenuDivider></MenuDivider>
    <ContextMenuSelectAll on:done={closeContextMenu}></ContextMenuSelectAll>
    <ContextMenuAutoFormat on:done={closeContextMenu}></ContextMenuAutoFormat>
    <ContextMenuAutoTime on:done={closeContextMenu}></ContextMenuAutoTime>
    <ContextMenuMerge on:done={closeContextMenu}></ContextMenuMerge>
    <ContextMenuForcedSubtitle on:done={closeContextMenu}></ContextMenuForcedSubtitle>
    <MenuDivider></MenuDivider>
    <ContextMenuApprove on:done={closeContextMenu}></ContextMenuApprove>
    <!-- Divider -->
    <MenuDivider></MenuDivider>
    <ContextMenuEventTemplate on:done={closeContextMenu}></ContextMenuEventTemplate>
</div>

<style>
#EditorContextMenu {
    position: fixed;
    display: grid;
    max-width: 340px;
    z-index: 10000;
}
</style>
