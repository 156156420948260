export default (function compressText(htmlString, maxCharLimit = 32, maxParagraphLimit = 4) {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const paragraphs = Array.from(doc.getElementsByTagName('p'));

  // Helper function to get display character count of a paragraph
  const getCharCount = element => element.textContent.length;

  // Helper function to get words from a paragraph
  const getWords = element => element.textContent.trim().split(/\s+/);

  // Helper function to create a new paragraph
  const createParagraph = content => {
    const p = doc.createElement('p');
    p.innerHTML = content;
    return p;
  };

  // Helper function to find the longest paragraph index and length
  const findLongestParagraph = () => {
    let maxLength = 0;
    let maxIndex = 0;
    paragraphs.forEach((p, index) => {
      const length = getCharCount(p);
      if (length > maxLength) {
        maxLength = length;
        maxIndex = index;
      }
    });
    return {
      index: maxIndex,
      length: maxLength
    };
  };

  // Try to optimize text layout
  let modified = false;
  let iterations = 0;
  const maxIterations = 100; // Prevent infinite loops

  while (!modified && iterations < maxIterations) {
    iterations++;
    const {
      index: longestParaIndex,
      length: currentLongest
    } = findLongestParagraph();

    // First try Scenario C: Create new paragraph if possible
    if (paragraphs.length < maxParagraphLimit) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      const lastParaWords = getWords(lastParagraph);
      if (lastParaWords.length > 1) {
        const lastWord = lastParaWords[lastParaWords.length - 1];
        const newParaText = lastWord;
        const newCurrentText = lastParaWords.slice(0, -1).join(' ');
        if (newParaText.length <= maxCharLimit && newCurrentText.length <= maxCharLimit) {
          lastParagraph.innerHTML = newCurrentText;
          const newPara = createParagraph(newParaText);
          lastParagraph.parentNode.appendChild(newPara);
          paragraphs.push(newPara);
          modified = true;
          break;
        }
      }
    }

    // Then try Scenarios A & B: Moving words between paragraphs
    if (!modified) {
      // First try moving words from the longest paragraph (Scenario A)
      const longestParaWords = getWords(paragraphs[longestParaIndex]);
      if (longestParaWords.length > 1) {
        // Try moving to previous paragraph
        if (longestParaIndex > 0) {
          const prevPara = paragraphs[longestParaIndex - 1];
          const firstWord = longestParaWords[0];
          const newPrevText = prevPara.innerHTML + ' ' + firstWord;
          const newCurrentText = longestParaWords.slice(1).join(' ');
          if (newPrevText.length <= maxCharLimit && newCurrentText.length <= maxCharLimit && Math.max(newPrevText.length, newCurrentText.length) < currentLongest) {
            prevPara.innerHTML = newPrevText;
            paragraphs[longestParaIndex].innerHTML = newCurrentText;
            modified = true;
            break;
          }
        }

        // Try moving to next paragraph
        if (longestParaIndex < paragraphs.length - 1) {
          const nextPara = paragraphs[longestParaIndex + 1];
          const lastWord = longestParaWords[longestParaWords.length - 1];
          const newCurrentText = longestParaWords.slice(0, -1).join(' ');
          const newNextText = lastWord + ' ' + nextPara.innerHTML;
          if (newNextText.length <= maxCharLimit && newCurrentText.length <= maxCharLimit && Math.max(newCurrentText.length, newNextText.length) < currentLongest) {
            nextPara.innerHTML = newNextText;
            paragraphs[longestParaIndex].innerHTML = newCurrentText;
            modified = true;
            break;
          }
        }
      }

      // If we couldn't move words from the longest paragraph,
      // try moving words from other paragraphs (Scenario B)
      if (!modified) {
        for (let i = 0; i < paragraphs.length - 1; i++) {
          if (i === longestParaIndex) continue;
          const currentPara = paragraphs[i];
          const nextPara = paragraphs[i + 1];
          const currentWords = getWords(currentPara);
          if (currentWords.length > 1) {
            const lastWord = currentWords[currentWords.length - 1];
            const newCurrentText = currentWords.slice(0, -1).join(' ');
            const newNextText = lastWord + ' ' + nextPara.innerHTML;
            const newCurrentLength = newCurrentText.length;
            const newNextLength = newNextText.length;
            const oldCurrentLength = currentPara.textContent.length;
            if (newCurrentLength <= maxCharLimit && newNextLength <= maxCharLimit && newCurrentLength < oldCurrentLength && newNextLength < oldCurrentLength) {
              currentPara.innerHTML = newCurrentText;
              nextPara.innerHTML = newNextText;
              modified = true;
              break;
            }
          }
        }
      }
    }

    // Scenario C: Create new paragraph if possible
    if (!modified && paragraphs.length < maxParagraphLimit && longestParaIndex === paragraphs.length - 1 && words.length > 1) {
      const lastWord = words[words.length - 1];
      const newParaText = lastWord;
      const newCurrentText = words.slice(0, -1).join(' ');
      if (newParaText.length <= maxCharLimit && newCurrentText.length <= maxCharLimit) {
        paragraphs[longestParaIndex].innerHTML = newCurrentText;
        const newPara = createParagraph(newParaText);
        paragraphs[longestParaIndex].parentNode.appendChild(newPara);
        paragraphs.push(newPara);
        modified = true;
        break;
      }
    }

    // If no modifications were possible in this iteration, break
    if (!modified) break;
  }

  // If no modifications were made or max iterations reached, return original string
  if (!modified || iterations >= maxIterations) return htmlString;

  // Convert the modified DOM back to string
  const resultHTML = Array.from(doc.body.getElementsByTagName('p')).map(p => p.outerHTML).join('');
  return resultHTML;
});