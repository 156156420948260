import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class Issue {
  constructor(options = {
    name: "Untitled",
    priority: "P1",
    status: "open",
    type: "other",
    description: " ",
    eventGroupId: " ",
    eventIds: []
  }) {
    this.id = uuidv4(), this.name = options.name || "Untitled", this.priority = options.priority || "P1", this.status = options.status || "open", this.type = options.type || "other", this.description = options.description || " ", this.eventGroupId = options.eventGroupId, this.eventIds = options.eventIds || [];
  }
});