import _escapeTextContent from "../quill/escapeTextContent.js";
import _cleanExtraSpanTags from "../quill/cleanExtraSpanTags.js";
import _combineSpanTags from "../quill/combineSpanTags.js";
import _flexbox from "../../dict/flexbox.js";
import _convertToPlainText from "../quill/convertToPlainText.js";
import _stripTags from "../quill/stripTags.js";
import _getLongestLine from "../utility/getLongestLine.js";
import { decode as _decodeHtml } from "html-entities";
import _eol from "eol";
import _findCenter from "../utility/findCenter.js";
import _quillClasses from "../../dict/quillClasses.js";
import _convertToHtml from "../quill/convertToHtml.js";
import _tcLib from "../../lib/timecode.js";
import _Event from "../../classes/event.js";
/* Creating a single TTML functions file for managing all of the different functions we use across profiles. */

/* Multi-line profiles: */
/* 
    Ttml
*/

/* Single-line profiles: */
/* 
    NetflixTtCaptions
*/

const Event = _Event;
const tcLib = _tcLib;
const convertToHtml = _convertToHtml;
const quillClasses = _quillClasses;
const findCenter = _findCenter;
const eol = _eol;
const decodeHtml = _decodeHtml;
const getLongestLine = _getLongestLine;
const stripTags = _stripTags;
const convertToPlainText = _convertToPlainText;
const flexbox = _flexbox;
const combineSpanTags = _combineSpanTags;
const cleanExtraSpanTags = _cleanExtraSpanTags;
const escapeTextContent = _escapeTextContent;
export default {
  /* General Functions */
  frameRateMap: {
    "23.976": "24",
    "24": "24",
    "25": "25",
    "29.97": "30",
    "30": "30",
    "50": "50",
    "59.94": "60",
    "60": "60"
  },
  frameRateMultiplierMap: {
    "23.976": "1000 1001",
    "24": "1 1",
    "25": "1 1",
    "29.97": "1000 1001",
    "30": "1 1",
    "50": "1 1",
    "59.94": "1000 1001",
    "60": "1 1"
  },
  eventStyleMap: {
    "Pop-On": "pop",
    "Roll-Up": "rollup",
    "Paint-On": "paint"
  },
  singleLine: {
    Display: class {
      constructor(options = {
        style: "Pop-On",
        start: false,
        end: false,
        lines: []
      }) {
        this.style = options.style || "Pop-On", this.start = options.start || false, this.end = options.end || false, this.lines = options.lines || [];
      }
      insertLine(options = {
        text: "",
        html: "",
        original: "",
        extentX: 0,
        extentY: 0,
        originX: 0,
        originY: 0,
        region: {}
      }) {
        this.lines.push({
          text: options.text || "",
          html: options.html || "",
          original: options.original || "",
          extentX: options.extentX || 0,
          extentY: options.extentY || 0,
          originX: options.originX || 0,
          originY: options.originY || 0,
          region: options.region || {}
        });
      }
    },
    decodeDisplay: function (display, win) {
      //console.log(JSON.stringify(display, null, 4));
      let ccEvent = new Event();
      let html = display.lines.map(line => {
        return line.html;
      }).join("\n");
      ccEvent.start = display.start;
      ccEvent.end = display.end;
      let positionInfo = this.getPositionInfo(display, win);
      //console.log(display,positionInfo);
      ccEvent.alignment = positionInfo.alignment;
      ccEvent.xPos = positionInfo.xPos;
      ccEvent.yPos = positionInfo.yPos;
      ccEvent.xOffset = positionInfo.xOffset;
      ccEvent.yOffset = positionInfo.yOffset;
      ccEvent.text = convertToHtml(html, [quillClasses.align[positionInfo.alignment]]);
      //console.log(ccEvent.text);
      return ccEvent;
    },
    getPositionInfo: function (display, win) {
      let xPos,
        yPos,
        xOffset,
        yOffset,
        alignment = "center",
        details;

      /* extent = size of element */
      /* origin = placement of element */
      details = this.getPosDetails(display.lines);
      alignment = this.getDisplayAlignment(details);
      let x = this.getXPos(display.lines, details, alignment, win);
      let y = this.getYPos(display.lines, win);
      xPos = x.position;
      xOffset = x.offset;
      yPos = y.position;
      yOffset = y.offset;
      return {
        alignment: alignment,
        xPos: xPos,
        xOffset: xOffset,
        yPos: yPos,
        yOffset: yOffset
      };
    },
    getPosDetails: function (lines) {
      return lines.map(line => {
        let alignment = "right";
        let center = findCenter(100, line.extentX);
        if (line.originX > center - 5 && line.originX < center + 5) {
          alignment = "center";
        } else if (line.originX < center) {
          alignment = "left";
        }
        return {
          start: line.originX,
          end: parseFloat(line.originX) + parseFloat(line.extentX),
          length: line.text.length,
          alignment: alignment
        };
      });
    },
    getDisplayAlignment: function (details) {
      if (details.length === 1) {
        return "center";
      }
      let xPosSame = details.every(detail => {
        return detail.start === details[0].start;
      });
      let endSame = details.every(detail => {
        return detail.end === details[0].end;
      });
      let alignmentCenter = details.every(detail => {
        return detail.alignment === "center";
      });
      if (xPosSame) {
        return "left";
      } else if (alignmentCenter) {
        return "center";
      } else if (endSame) {
        return "right";
      } else {
        return "center";
      }
    },
    getXPos: function (lines, details, alignment, win) {
      let xInfo = {
        position: "center",
        offset: 0
      };
      let lowestXPos = Math.min.apply(Math, lines.map(function (line) {
        return line.originX;
      }));
      let highestEnd = Math.max.apply(Math, details.map(function (detail) {
        return detail.end;
      }));
      if (alignment === "left") {
        xInfo.position = "start";
        xInfo.offset = lowestXPos / 100 * win.width;
      } else if (alignment === "center") {
        xInfo.position = "center";
        xInfo.offset = 0;
      } else {
        xInfo.position = "end";
        xInfo.offset = (100 - highestEnd) / -100 * win.width;
      }
      return xInfo;
    },
    getYPos: function (lines, win) {
      let yInfo = {
        position: "end",
        offset: win.height * -0.10
      };
      let highestYPos = Math.max.apply(Math, lines.map(function (line) {
        return line.originY;
      }));
      let lowestYPos = Math.min.apply(Math, lines.map(function (line) {
        return line.originY;
      }));
      if (highestYPos === 0 && lowestYPos === 0 && lines[0].region) {
        let regionExtent = lines[0].region["@_tts:extent"] || "80% 40%";
        let regionOrigin = lines[0].region["@_tts:origin"] || "10% 50%";
        let displayAlign = lines[0].region["@_tts:displayAlign"] || "before";
        let xExtent = parseFloat(regionExtent.split(" ")[0].replace("%", ""));
        let yExtent = parseFloat(regionExtent.split(" ")[1].replace("%", ""));
        let xOrigin = parseFloat(regionOrigin.split(" ")[0].replace("%", ""));
        let yOrigin = parseFloat(regionOrigin.split(" ")[1].replace("%", ""));

        //console.log(xExtent, yExtent, yOrigin, xOrigin, displayAlign);
        if (displayAlign === "after") {
          if (yOrigin + yExtent < 40) {
            yInfo.position = "start";
            yInfo.offset = 0.10 * win.height;
          } else if (yOrigin + yExtent < 60) {
            yInfo.position = "center";
            yInfo.offset = parseInt(Math.min(yOrigin, yExtent) / 100 * win.height);
          } else {
            yInfo.position = "end";
            yInfo.offset = (100 - (yOrigin + yExtent)) / -100 * win.height;
          }
        } else {
          if (yOrigin < 40) {
            yInfo.position = "start";
            yInfo.offset = 0.10 * win.height;
          } else if (yOrigin < 60) {
            yInfo.position = "center";
            yInfo.offset = 0;
          } else {
            yInfo.position = "end";
            yInfo.offset = -0.10 * win.height;
          }
        }
      } else if (lowestYPos > 60) {
        yInfo.position = "end";
        yInfo.offset = (100 - highestYPos) / -100 * win.height;
      } else if (highestYPos < 40) {
        yInfo.position = "start";
        yInfo.offset = lowestYPos / 100 * win.height;
      } else {
        yInfo.position = "center";
        yInfo.offset = (lowestYPos - findCenter(100, lines.length * 5.33)) / 100 * win.height;
      }
      return yInfo;
    },
    calcExtents: function (text, fontSize = 80) {
      /* 80 is the font size (80%). 5.33 is the font height (5.33%) (1/19))*/
      let xExtent = (stripTags(text).trim().length / 32 * fontSize).toFixed(2);
      let yExtent = 5.33;
      return `${xExtent}% ${yExtent}%`;
      /*e.g 37.5% 5.33% */
    },
    calcOrigin: function (event, text, lineNumber, totalLines, fontSize = 80, win) {
      let xOrigin = this.calcXOrigin(event, text, fontSize, win);
      let yOrigin = this.calcYOrigin(event, lineNumber, totalLines, fontSize, win);
      return `${xOrigin.toFixed(2)}% ${yOrigin.toFixed(2)}%`; /*e.g 37.5% 5.33% */
    },
    calcXOrigin(event, text, fontSize = 80, win) {
      let totalChars = 40 + (40 - fontSize / 100 * 40);
      let xOrigin,
        longestLine = getLongestLine(convertToPlainText(event.text)),
        xExtent = (stripTags(text).length / totalChars * fontSize).toFixed(2),
        xExtentOfLongestLine = (longestLine.length / totalChars * fontSize).toFixed(2);
      //console.log(xExtent, text);
      if (event.xPos === "start") {
        if (event.alignment === "left") {
          xOrigin = event.xOffset / win.width * 100;
        } else if (event.alignment === "center") {
          xOrigin = findCenter(xExtentOfLongestLine, xExtent) + event.xOffset / win.width * 100;
        } else {
          xOrigin = xExtentOfLongestLine - xExtent + event.xOffset / win.width * 100;
        }
      } else if (event.xPos === "center") {
        xOrigin = findCenter(100, xExtent) + event.xOffset / win.width * 100;
        //console.log(xOrigin, xExtent, event.xOffset, win.width, text);
      } else {
        if (event.alignment === "left") {
          xOrigin = 100 - xExtentOfLongestLine + event.xOffset / win.width * 100;
        } else if (event.alignment === "center") {
          xOrigin = 100 - (xExtentOfLongestLine + findCenter(xExtentOfLongestLine, xExtent)) + event.xOffset / win.width * 100;
        } else {
          xOrigin = 100 - xExtent + event.xOffset / win.width * 100;
        }
      }
      return parseFloat(xOrigin);
    },
    calcYOrigin(event, lineNumber, totalLines, fontSize = 80, win) {
      let yOrigin,
        totalChars = 19 + (19 - fontSize / 100 * 19),
        lineHeight = 1 / totalChars * 100;
      if (event.yPos === "start") {
        yOrigin = lineHeight * lineNumber + event.yOffset / win.height * 100;
      } else if (event.yPos === "center") {
        yOrigin = findCenter(100, totalLines * lineHeight) + lineNumber * lineHeight;
      } else {
        yOrigin = 100 - (totalLines - 1 - lineNumber) * lineHeight + event.yOffset / win.height * 100;
      }

      //console.log(event.text,lineHeight,yOrigin, event.yPos, event.yOffset,win.height, totalLines, lineNumber)

      return parseFloat(yOrigin);
    },
    convertToTtml: function (html) {
      return html.replace(/<strong>|<\/strong>/gmi, "").replace(/(<em><u>)+/gmi, "<span tts:fontStyle='italic'  tts:textDecoration='underline'>").replace(/(<\/em><\/u>)+/gmi, "</span>").replace(/(<em>)+/gmi, "<span tts:fontStyle='italic'>").replace(/(<\/em>)+/gmi, "</span>").replace(/(<u>)+/gmi, "<span tts:textDecoration='underline'>").replace(/(<\/u>)+/gmi, "</span>").replace(/(<\/span>)+/g, "</span>");
    }
  },
  multiLine: {
    /* Decode Functions */
    decodeSubtitle: function (subtitle, region, frameRate, win, ignorePos = false) {
      //console.log(subtitle, region, frameRate, win, ignorePos);
      let ccEvent = new Event();
      let start = tcLib.parseTcToSec(subtitle["@_begin"], frameRate, true);
      let end = tcLib.parseTcToSec(subtitle["@_end"], frameRate, true);
      //console.log("Before:",subtitle["#text"])
      let html = this.formatText(subtitle["#text"]);
      //console.log("after:",html);
      if (!ignorePos) {
        let positionInfo = this.getPositionInfo(subtitle, region, win);
        ccEvent.alignment = positionInfo.alignment;
        ccEvent.xPos = positionInfo.xPos;
        ccEvent.yPos = positionInfo.yPos;
        ccEvent.xOffset = parseInt(positionInfo.xOffset);
        ccEvent.yOffset = parseInt(positionInfo.yOffset);
        ccEvent.text = convertToHtml(html, [quillClasses.align[positionInfo.alignment]]);
        //console.log(ccEvent.text);
      } else {
        ccEvent.text = convertToHtml(html);
      }
      ccEvent.start = start;
      ccEvent.end = end;
      //console.log(ccEvent);
      return ccEvent;
    },
    removeRedundantTags: function (input) {
      const tags = ['em', 'u']; // Add more tags here if needed
      let result = input;
      for (const tag of tags) {
        const regex = new RegExp(`<${tag}>((?:<${tag}>.*?<\/${tag}>|.)*?)<\/${tag}>`, 'g');
        let previousResult;
        do {
          previousResult = result;
          result = result.replace(regex, (match, content) => {
            // Remove inner tags of the same type
            content = content.replace(new RegExp(`<${tag}>|<\/${tag}>`, 'g'), '');
            return `<${tag}>${content}</${tag}>`;
          });
        } while (result !== previousResult);
      }
      return result;
    },
    replaceNestedItalicSpans: function (input) {
      const regex = /<span tts:fontStyle="italic">((?:[^<]|<(?!span tts:fontStyle="italic"))*(?:<span tts:fontStyle="italic">(?:[^<]|<(?!span tts:fontStyle="italic"))*<\/span>)*(?:[^<]|<(?!span tts:fontStyle="italic"))*)<\/span>/g;
      let result = input;
      let previousResult;
      do {
        previousResult = result;
        result = result.replace(regex, '<em>$1</em>');
      } while (result !== previousResult);
      return result;
    },
    replaceSpanTagsWithFormatTags: function (str) {
      //Look after just the italic tags
      str = this.replaceNestedItalicSpans(str);
      // Replace <span> tags with italic and underline attributes
      //console.log(str);
      str = str.replace(/<span\s+tts:fontStyle\s*=\s*(['"]?)italic\1\s+tts:textDecoration\s*=\s*(['"]?)underline\2\s*>(.*?)<\/span>/gi, '<em><u>$3</u></em>');

      // Replace <span> tags with italic attribute
      str = str.replace(/<span\s+tts:fontStyle\s*=\s*(['"]?)italic\1\s*>(.*?)<\/span>/gi, '<em>$2</em>');

      // Replace <span> tags with italic attribute
      str = str.replace(/<span\s+style\s*=\s*(['"]?)italic\1\s*>(.*?)<\/span>/gi, '<em>$2</em>');

      // Replace <span> tags with underline attribute
      str = str.replace(/<span\s+tts:textDecoration\s*=\s*(['"]?)underline\1\s*>(.*?)<\/span>/gi, '<u>$2</u>');

      // Replace <span> tags with italic attribute
      str = str.replace(/<span[^>]*style\s*=\s*["'][^"']*["'][^>]*>(.*?)<\/span>/gi, '<em>$1</em>');
      str = str.replace(/<span[^>]*>(.*?)<\/span>/gi, '$1');
      str = str.replace(/<\/?span[^>]*>/g, "");
      //console.log(str);

      str = this.removeRedundantTags(str);
      return str;
    },
    formatText: function (text) {
      if (!text) {
        return "";
      }
      let fText = "";
      text = text.toString();
      //console.log("Before:", text);
      text = this.replaceSpanTagsWithFormatTags(text);
      text.split(/<br\/>|<br>|<br \/>/).forEach(textLine => {
        fText += this.replaceSpanTagsWithFormatTags(textLine).trim() + "\n";
      });
      //console.log("after:", fText.trim());
      return decodeHtml(fText.trim());
    },
    getPositionInfo: function (subtitle, regionInfo, win) {
      //console.log(subtitle, regionInfo, win);
      let xPos,
        yPos,
        xOffset,
        yOffset,
        alignment = "center",
        extents,
        origins,
        displayAlign;

      /* extent = size of element */
      /* origin = placement of element */
      extents = this.getExtents(subtitle, regionInfo);
      origins = this.getOrigins(subtitle, regionInfo);
      displayAlign = this.getDisplayAlign(subtitle, regionInfo);
      alignment = flexbox.alignmentNormalize[this.getAlignment(subtitle, regionInfo)];
      let x = this.getXPos(origins, extents, win);
      let y = this.getYPos(origins, extents, displayAlign, win);
      //console.log(x,y);
      xPos = x.position;
      xOffset = x.offset;
      yPos = y.position;
      yOffset = y.offset;
      return {
        alignment: alignment,
        xPos: xPos,
        xOffset: xOffset,
        yPos: yPos,
        yOffset: yOffset
      };
    },
    getDisplayAlign: function (subtitle, regionInfo) {
      if (regionInfo && regionInfo["@_tts:displayAlign"]) {
        return regionInfo["@_tts:displayAlign"];
      } else if (subtitle["@_tts:displayAlign"]) {
        return subtitle["@_tts:displayAlign"];
      } else {
        return false;
      }
    },
    getExtents: function (subtitle, regionInfo) {
      if (regionInfo && regionInfo["@_tts:extent"]) {
        return {
          x: parseFloat(regionInfo["@_tts:extent"].split(" ")[0].replace("%", "")),
          y: parseFloat(regionInfo["@_tts:extent"].split(" ")[1].replace("%", ""))
        };
      } else if (subtitle["@_tts:extent"]) {
        return {
          x: parseFloat(subtitle["@_tts:extent"].split(" ")[0].replace("%", "")),
          y: parseFloat(subtitle["@_tts:extent"].split(" ")[1].replace("%", ""))
        };
      } else {
        return false;
      }
    },
    getOrigins: function (subtitle, regionInfo) {
      if (regionInfo && regionInfo["@_tts:origin"]) {
        return {
          x: parseFloat(regionInfo["@_tts:origin"].split(" ")[0].replace("%", "")),
          y: parseFloat(regionInfo["@_tts:origin"].split(" ")[1].replace("%", ""))
        };
      } else if (subtitle["@_tts:origin"]) {
        return {
          x: parseFloat(subtitle["@_tts:origin"].split(" ")[0].replace("%", "")),
          y: parseFloat(subtitle["@_tts:origin"].split(" ")[1].replace("%", ""))
        };
      } else {
        return false;
      }
    },
    getAlignment: function (subtitle, regionInfo) {
      if (subtitle["@_tts:textAlign"]) {
        return subtitle["@_tts:textAlign"];
      } else if (regionInfo && regionInfo["@_tts:textAlign"]) {
        return regionInfo["@_tts:textAlign"];
      } else {
        return "center";
      }
    },
    getXPos: function (origins, extents, win) {
      let xInfo = {
        position: "center",
        offset: 0
      };
      if (!origins || !extents || !origins.x || !extents.x) {
        return xInfo;
      }
      if (origins && extents) {
        let center = findCenter(100, extents.x);
        if (origins.x < center + 5 && origins.x > center - 5) {
          xInfo.position = "center";
          xInfo.offset = 0;
        } else if (origins.x > center) {
          xInfo.position = "end";
          xInfo.offset = (100 - (origins.x + extents.x)) / -100 * win.height;
        } else {
          xInfo.position = "start";
          xInfo.offset = origins.x / 100 * win.height;
        }
      }
      return xInfo;
    },
    getYPos: function (origins, extents, displayAlign, win) {
      //console.log(origins, extents, displayAlign);
      let yInfo = {
        position: "end",
        offset: win.height * -0.10
      };
      if (!origins || !extents || !origins.y || !extents.y) {
        return yInfo;
      }
      if (displayAlign === "after") {
        if (origins.y + extents.y < 40) {
          yInfo.position = "start";
          yInfo.offset = 0.10 * win.height;
        } else if (origins.y + extents.y < 60) {
          yInfo.position = "center";
          yInfo.offset = parseInt(Math.min(origins.y, extents.y) / 100 * win.height);

          //console.log(yInfo.offset);
        } else {
          yInfo.position = "end";
          yInfo.offset = parseInt((100 - (origins.y + extents.y)) / -100 * win.height);
        }
      } else {
        if (origins.y < 40) {
          yInfo.position = "start";
          yInfo.offset = 0.10 * win.height;
        } else if (origins.y < 60) {
          yInfo.position = "center";
          yInfo.offset = 0;
        } else {
          yInfo.position = "end";
          yInfo.offset = yInfo.offset = -0.10 * win.height;
        }
      }
      return yInfo;
    },
    calcXOrigin(text, xPos, xOffset, fontSize = 80, win) {
      let xOrigin = 0,
        longestLine = getLongestLine(text),
        xExtent = parseFloat((longestLine.length / 32 * fontSize).toFixed(2));
      if (xPos === "start") {
        xOrigin = 0;
      } else if (xPos === "center") {
        xOrigin = findCenter(100, xExtent);
      } else {
        xOrigin = 100 - xExtent;
      }
      return parseFloat(xOrigin) + xOffset / win.width * 100;
    },
    calcYOrigin(text, yPos, yOffset, win) {
      let yOrigin,
        totalLines = eol.split(text).length,
        vOffset = yOffset / win.height * 80;
      if (yPos === "start") {
        yOrigin = 0;
      } else if (yPos === "center") {
        yOrigin = findCenter(100, totalLines * 5.33);
      } else {
        yOrigin = 100 - totalLines * 5.33;
      }
      return parseFloat(yOrigin) + vOffset;
    },
    calcExtents: function (text, fontSize = 80, lineHeight = 5.33) {
      let longestLine = getLongestLine(text);
      /* 80 is the font size (80%). 5.33 is the line height (5.33%) */
      let xExtent = longestLine.length / 32 * fontSize;
      let yExtent = lineHeight * eol.split(text).length;
      return `${xExtent.toFixed(2)}% ${yExtent.toFixed(2)}%`;
      /*e.g 37.5% 5.33% */
    },
    calcOrigin: function (text, xPos, xOffset, yPos, yOffset, fontSize = 80, win) {
      let xOrigin = this.calcXOrigin(text, xPos, xOffset, fontSize, win);
      let yOrigin = this.calcYOrigin(text, yPos, yOffset, win);
      return `${xOrigin.toFixed(2)}% ${yOrigin.toFixed(2)}%`; /*e.g 37.5% 5.33% */
    },
    convertToTtml: function (html) {
      // console.log("---------------");
      // console.log("BEFORE:", html);
      html = html.replace(/(<strong><em><u>)+/gmi, "<span tts:fontWeight='bold' tts:fontStyle='italic'  tts:textDecoration='underline'>").replace(/(<\/u><\/em><\/strong>)+/gmi, "</span>").replace(/(<strong><u>)+/gmi, "<span tts:fontWeight='bold' tts:textDecoration='underline'>").replace(/(<\/u><\/strong>)+/gmi, "</span>").replace(/(<strong><em>)+/gmi, "<span tts:fontWeight='bold' tts:fontStyle='italic'>").replace(/(<\/em><\/strong>)+/gmi, "</span>").replace(/(<em><strong>)+/gmi, "<span tts:fontWeight='bold' tts:fontStyle='italic'>").replace(/(<\/strong><\/em>)+/gmi, "</span>").replace(/(<strong>)+/gmi, "<span tts:fontWeight='bold'>").replace(/(<\/strong>)+/gmi, "</span>").replace(/(<em><u>)+/gmi, "<span tts:fontStyle='italic'  tts:textDecoration='underline'>").replace(/(<\/u><\/em>)+/gmi, "</span>").replace(/(<em>)+/gmi, "<span tts:fontStyle='italic'>").replace(/(<\/em>)+/gmi, "</span>").replace(/(<u>)+/gmi, "<span tts:textDecoration='underline'>").replace(/(<\/u>)+/gmi, "</span>").replace(/<span\s+style=['"]color:\s*([^;'"]+)[;]?['"]/gi, "<span tts:color='$1'");
      html = combineSpanTags(html);
      html = cleanExtraSpanTags(html);

      // console.log("AFTER:", html);
      html = html.replace(/(<\/span>)+/g, "</span>").replace(/(?:\r\n|\r|\n)/g, "<br/>");
      return escapeTextContent(html);
    }
  }
};