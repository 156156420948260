<script>
	import { playerState } from "@app/store/playerStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { adrState} from "@app/store/adrStore.js";
	import { onMount, onDestroy } from "svelte";
    import debounce from "just-debounce-it";
	import Plyr from "plyr";
    import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
	let frameTimer;
	const updateTcFrame = async function () {
		//console.log("TC UPDATE "+player.currentTime);
		try {
			if (player) {
				playerState.updateTime(player.currentTime);
			}
		} catch (err) {
			console.log("unable to update current time in store.", err.message);
		}
	};

    const goToMidi = debounce((currentTime) => {
        let intValue = (currentTime * ($adrState.bpm/60))/0.25;
        let binaryValue = intValue.toString(2).padStart(14,"0");
        let binA = binaryValue.slice(-7);
        let binB = binaryValue.slice(0, 7);
        let spp = [0xf2, parseInt(binA, 2), parseInt(binB, 2)];
        let tc = tcLib.secToTc(currentTime, $projectState.frameRate, $projectState.dropFrame);
        let tcArray = tc.split(/:|;/g);
        let midiCmd = [0xf0, 0x7f, 0x00, 0x06, 0x44,
                0x06, 0x01, ...tcArray, 0x00, 0xf7];

        //console.log(`MIDI SEND: ${midiCmd} (${tc})`);
        $adrState.selectedOutput.send(midiCmd);
        /* Go to Song Position */
        $adrState.selectedOutput.send(spp);
    }, 500, true)

	onMount(async () => {
		window.player = new Plyr("#player", {
			"keyboard": {
				global : false,
                focused : false,
			},
			fullscreen: { enabled: true },
			debug: false,
			controls: ["progress", "pip", "current-time"],
			settings: [],
			/* markers : {
        enabled : true,
        points : [{
            time : 2,
            label: "shot change"
        }]
    }, */
			muted: false,
			seekTime: 1,
			volume: 1,
			speed: { selected: 1, options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4, 8, 16] },
			disableContextMenu: true,
		});

		player.on("timeupdate", () => {
            // console.log("Time Update");
			playerState.updateTime(player.currentTime);
            if ($adrState.enable && $adrState.selectedOutput){
                goToMidi(player.currentTime)
            }
		});

		player.on("playing", () => {
			//console.log("PLAYING");
			playerState.updateDuration(player.duration);

			if (frameTimer) {
				clearInterval(frameTimer);
			}

			frameTimer = setInterval(updateTcFrame, 50);
		});

		player.on("play", () => {
			//console.log("PLAY");
			playerState.updateDuration(player.duration);

            if ($adrState.enable && $adrState.selectedOutput){
                if ($adrState.record){
                    /* Record Command */
                    //console.log(`MIDI SEND: RECORD`);
                    $adrState.selectedOutput.send([0xf0, 0x7f, 0x00, 0x06, 0x06, 0xf7]);
                    $adrState.selectedOutput.send([251]);
                } else {
                    /* Playback command */
                    //console.log(`MIDI SEND: PLAY`);
                    $adrState.selectedOutput.send([0xf0, 0x7f, 0x00, 0x06, 0x02, 0xf7]);
                    $adrState.selectedOutput.send([251]);  
                }                
            }

			if (frameTimer) {
				clearInterval(frameTimer);
			}

			frameTimer = setInterval(updateTcFrame, 50);
		});

		player.on("pause", () => {
			playerState.updatePlayingStatus(false);
			if (frameTimer) {
				clearInterval(frameTimer);
			}

            if ($adrState.enable && $adrState.selectedOutput){
                /* Pause Command */
                //console.log(`MIDI SEND: PAUSE`);
                $adrState.selectedOutput.send([0xf0, 0x7f, 0x00, 0x06, 0x01, 0xf7]);    
                $adrState.selectedOutput.send([252]);  
            }
		});

		player.on("seeked"), () => {
            playerState.updateTime(player.currentTime);
        };

		player.on("error"), () => {
			console.log("ERROR");
		};

		player.volume = 1;
		player.speed = 1;
	});
	
	onDestroy(() => {
		player.destroy();
	});
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<video id="player" poster="./assets/img/poster.jpg" crossorigin controls />
