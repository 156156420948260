import { writable } from 'svelte/store';

function createAuthStore() {
    const { subscribe, set, update} = writable({});
    return {
        subscribe,
        reset : () => set({}),
        updateTeam : (teamInfo) => update(authState => {
            authState.team = teamInfo;
            return authState;
        }),
        updateUser : (userInfo) => update(authState => {
            authState.user = userInfo;
            return authState;
        }),
        setAuth : (auth) => set(auth)
    }
}

export const authState = createAuthStore();