// Components
import { wrap } from "svelte-spa-router/wrap";
import Creator from "./pages/Creator.svelte";
import Login from "./pages/Login.svelte";
import LoginOffline from "./pages/LoginOffline.svelte";
import firebase from "./configs/firebase";
import online from "./configs/status.js";
let activationToken = localStorage.getItem("cc-offline-license");

/* Check User Status */
function getCurrentUser(auth) {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
}


// Export the route definition object
export default {
	// Exact path
	"/": wrap({
		component: Creator,
		conditions: [
			async () => {
                if (window.self !== window.top && !online){
                    return true;
                } else if (online) {
					let user = await getCurrentUser(firebase.auth());
					if (user) {
						return true;
					} else {
						return false;
					}
				} else {
                    if (activationToken) {
						return true;
					} else {
						return false;
					}
				}
			},
		],
	}),
	"/login": wrap({
		component: Login,
		conditions: [
			async () => {
                if (window.self !== window.top && !online){
                    return false;
                } else if (online) {
					let user = await getCurrentUser(firebase.auth());
					if (user) {
						return false;
					} else {
						return true;
					}
				} else {
                    if (activationToken) {
						return false;
					} else {
						return true;
					}
                }
			},
		],
	}),
    "/loginOffline": wrap({
		component: LoginOffline,
		conditions: [
			async () => {
                if (window.self !== window.top && !online){
                    return false;
                } else if (online) {
					return false;
				} else {
                    if (activationToken) {
						return false;
					} else {
						return true;
					}
                }
			},
		],
	}),
};
