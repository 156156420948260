export default (function wrapStyledTextWithSpan(htmlString) {
  // Define the regex pattern for matching tags with style attributes such as <b>, <i>, <u>, or <em>
  const styledTagPattern = /<(b|i|u|em) style="(.*?)">(.*?)<\/(b|i|u|em)>/gi;

  // Replace occurrences of styled tags with a span with the style attribute, wrapping the original tag
  const transformedHtmlString = htmlString.replace(styledTagPattern, (match, p1, p2, p3, p4) => {
    // Ensure the closing tag matches the opening tag
    if (p1 !== p4) {
      console.error(`Opening tag <${p1}> doesn't match closing tag </${p4}>. Skipping replacement.`);
      return match;
    }
    // Wrap the original tag content with a <span> containing the style
    return `<span style="${p2}"><${p1}>${p3}</${p4}></span>`;
  });
  return transformedHtmlString;
});