<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import stripTags from "@app/external/cc-lib/dist/functions/quill/stripTags.js";

let alignmentMap = {
    left: false,
    right: "right",
    center: "center"
}

/* Compressions makes the event longer which requires us to remove a word from the longest line and move it to a shorter line*/
const compressEvent = function() {
    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, selectedEventIndex) => {
        let lineInfo = [];
        let longestLineIndex = 0;
        let longestLength = 0;
        let longerLine;
        let newTextLines = "";
        if (!$eventGroupState[$projectState.selected].events[eventIndex].text) {
            return;
        }
        let textLines = $eventGroupState[$projectState.selected].events[eventIndex].text.split("</p>").filter(textLine => {
            return stripTags(textLine).length > 0;
        });

        textLines.forEach((line, index) => {
            let cleanHtml = line.replace(/(<([^>]+)>)/gi, "");

            if (cleanHtml.length > longestLength) {
                longestLength = cleanHtml.length;
                longestLineIndex = index;
            }
            if (cleanHtml.length > 0) {
                lineInfo.push({
                    index: index,
                    html: line,
                    clean: cleanHtml,
                    length: cleanHtml.length
                });
            }
        });

        if (lineInfo[longestLineIndex].clean.split(" ").length > 1) {
            if (lineInfo[longestLineIndex + 1] && lineInfo[longestLineIndex - 1] && longestLineIndex !== lineInfo.length - 1) {
                if (lineInfo[longestLineIndex + 1].length - 2 < lineInfo[longestLineIndex - 1].length) {
                    longerLine = lineInfo[longestLineIndex].clean.split(" ");
                    lineInfo[longestLineIndex + 1].clean = longerLine.pop() + " " + lineInfo[longestLineIndex + 1].clean;
                    lineInfo[longestLineIndex].clean = longerLine.join(" ");
                } else {
                    longerLine = lineInfo[longestLineIndex].clean.split(" ");
                    lineInfo[longestLineIndex - 1].clean += " " + longerLine.shift();
                    lineInfo[longestLineIndex].clean = longerLine.join(" ");
                }
            } else if (lineInfo[longestLineIndex + 1] && longestLineIndex !== lineInfo.length - 1) {
                longerLine = lineInfo[longestLineIndex].clean.split(" ");
                lineInfo[longestLineIndex + 1].clean = longerLine.pop() + " " + lineInfo[longestLineIndex + 1].clean;
                lineInfo[longestLineIndex].clean = longerLine.join(" ");
            } else if (lineInfo[longestLineIndex - 1] && longestLineIndex !== lineInfo.length - 1) {
                longerLine = lineInfo[longestLineIndex].clean.split(" ");
                lineInfo[longestLineIndex - 1].clean += " " + longerLine.shift();
                lineInfo[longestLineIndex].clean = longerLine.join(" ");
            } else {
                /* create a new line */
                longerLine = lineInfo[longestLineIndex].clean.split(" ");

                lineInfo.push({
                    index: longestLineIndex + 1,
                    html: "<p>",
                    clean: longerLine.pop(),
                    length: null
                });

                lineInfo[longestLineIndex].clean = longerLine.join(" ");
            }

            lineInfo.forEach(line => {
                newTextLines += line.html.split(">")[0] + ">" + line.clean.trim() + "</p>";
            });

            if (selectedEventIndex === 0 && quillEditor) {
                quillEditor.clipboard.dangerouslyPasteHTML(newTextLines);
                quillEditor.setSelection(0, quillEditor.getLength());
                quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
                quillEditor.setSelection(quillEditor.getLength(),0);
            } else {
                $eventGroupState[$projectState.selected].events[eventIndex].text = newTextLines;
            }
        }
    });

    historyState.insert({
        name: "compress event(s)", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
};
</script>
<li class="nav-item" title="Compress Text">
    <a class="nav-link" href="#!/" on:click="{compressEvent}" class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" ><i class="bi bi-distribute-horizontal"></i></a>
</li>
