<script>
export let type;
</script>

{#if type==='subtitle'}
<i class="bi bi-badge-cc" aria-hidden="true"></i>
{:else if type ==='transcription'}
<i class="bi bi-card-text" aria-hidden="true"></i>
{:else if type ==='audio description'}
<i class="bi bi-badge-ad" aria-hidden="true"></i>
{:else}
<i class="bi bi-translate" aria-hidden="true"></i>
{/if}
