<script>
import {
    historyState
} from '@app/store/historyStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { createEventDispatcher } from 'svelte';
import { toast } from '@zerodevx/svelte-toast';
import autoFormat from '@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js';

const dispatch = createEventDispatcher();

function autoFormatEvents() {
    try {
        let maxLines = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].maxLines : 2;
        let maxChars = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].maxChars : 32;
        let minDuration = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].minDuration : 1;

        let tempSelect = [...$eventGroupState[$projectState.selected].selected];
    
        try {
            $eventGroupState[$projectState.selected] = autoFormat($eventGroupState[$projectState.selected], maxLines, maxChars, minDuration, true, $eventGroupState[$projectState.selected].selected);
        } catch (err){
            console.log(err.message);
        }

        $eventGroupState[$projectState.selected].selected = [];
        setTimeout(() => {
            $eventGroupState[$projectState.selected].selected = tempSelect;
        }, 50);
        
        toast.push("Auto format completed", {
            classes: ["toast-success"]
        });

        historyState.insert({
            name: "auto format", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally{
        dispatch("done");
    }
}
</script>

<a id="btnAutoFormat"
   role="menuitem"
   aria-label="Auto format event text"
   class="dropdown-item" href="#!/"
   on:click={autoFormatEvents}>
   Auto Format Text
</a>
