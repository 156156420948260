<script>
import {
    explorerContextState
} from '@app/store/explorerContextStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
    import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';

/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import storage from '@app/configs/storage.js';
let userId = firebase.auth().currentUser.uid,
    teamId = $authState.team ? $authState.team.id : null,
    homeRef = db.collection("users").doc(userId).collection("storage"),
    teamRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null,
    storageRef = storage.ref(),
    teamDriveUploadBasePath = "teams/" + teamId + "/storage/",
    myDriveUploadBasePath = "users/" + userId + "/projects/";

async function saveProject() {
    if ($projectState.rootDir === 'team' && !teamId) {
        teamId = $authState.team ? $authState.team.id : null;        
        teamRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
        teamDriveUploadBasePath = "teams/" + teamId + "/storage/";
    }

    let projectJson = JSON.parse(JSON.stringify($projectState));
    projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
    projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
    projectJson.issues = JSON.parse(JSON.stringify($issueState));
    projectJson.markers = JSON.parse(JSON.stringify($markerState));
    projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
    projectJson.style = JSON.parse(JSON.stringify($styleState));

    let projectFilePath = $projectState.rootDir === 'team' ? teamDriveUploadBasePath+ $projectState.id : myDriveUploadBasePath + $projectState.id;

    storageRef.child(projectFilePath).putString(JSON.stringify(projectJson)).then((snapshot) => {
        //console.log("project file saved!", snapshot);
        let projectInfo = {
            id: $projectState.id,
            name: $projectState.name,
            type: $projectState.type === "team" ? "team project" : "project",
            updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            shared : $projectState.rootDir === 'team' ? true : false,
            locationId: $projectState.folderId
        }

        return ($projectState.rootDir === 'team' ? teamRef : homeRef).doc(projectInfo.id).update(projectInfo);
    }).then(() => {
        toast.push("Project saved successfully", {classes: ['toast-success']});
    }).catch((error) => {
        console.log("Error saving project: ", error);
        toast.push(error.message, {classes: ['toast-danger']});
    }).finally(() => {
        historyState.reset();
    });
}

async function saveProjectAs() {
    $explorerContextState = "save";
    modalState.showModal("storageExplorer");
}

</script>
<a class="dropdown-item" href="#!/" on:click="{() => $projectState.folderId || $projectState.folderId === null ? saveProject() : saveProjectAs()}">Save Project</a>
        <a class="dropdown-item" href="#!/" on:click="{saveProjectAs}">Save Project As...</a>