import _eol from "eol";
import _hex2ascii from "hex2ascii";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const hex2ascii = _hex2ascii;
const eol = _eol;
function isTimecode(str) {
  // Use a regular expression to match the timecode pattern HH:MM:SS:FF
  // H: 0-9 or 00-23, M: 0-9 or 00-59, S: 0-9 or 00-59, F: 00-29
  const timecodePattern = /^(?:[0-9]|0[0-9]|1[0-9]|2[0-3]):(?:[0-5][0-9]):(?:[0-5][0-9]):(?:[0-5][0-9])$/;
  return timecodePattern.test(str);
}
function parseHexTc(hexCodes) {
  let tc = "";
  hexCodes.filter(code => {
    return code !== "00";
  }).forEach(code => {
    tc += hex2ascii(code);
  });
  return tc;
}

/*  
    Parse the text from the file and extract the correct caption text.
    e.g. {\rtf1\fbidis\ansi\ansicpg1252\deff0\nouicompat\deflang1033{\fonttbl{\f0\fnil\fcharset0 Courier New;}}
{\*\generator Riched20 10.0.22621}\viewkind4\uc1 
\pard\ltrpar\qc\f0\fs18 [gentle music playing]\par
} should return [gentle music playing]
*/
function parseCaptionText(text) {
  // console.log("-----------");
  // console.log("Input: "+text);
  //Remove anything between {} unless ;

  text = text.replace(/{.+}/gmi, "");
  text = text.replace(/\\\w+/gmi, "");
  // console.log("Input: "+text);
  text = text.replace(/}$/gmi, "").trim();
  // console.log("output: "+text);
  return text ?? "";
}
export default {
  decode: function (input, options) {
    let events = [];
    let tc;
    let tcFlag = true;
    let hexCodes = input.match(/(..?)/g);
    while (hexCodes.length > 0) {
      let code = hexCodes.shift();
      if (code === "0b") {
        tc = parseHexTc(hexCodes.splice(0, 21));
        //Check that tc matches the correct format 00:00:00:00
        if (tc != "00:00:00:00" && tc != "00:00:00;00" && isTimecode(tc) && hexCodes[0] + hexCodes[1] + hexCodes[2] === "000000") {
          //console.log(tc);
          hexCodes.splice(0, 3);
          tcFlag = true;
        }
      } else if (tcFlag && code === "7b") {
        //console.log("Start of event");
        let evText = [hex2ascii(code)];
        let lookingForEnd = true;
        while (lookingForEnd) {
          if (hexCodes[0] + hexCodes[1] + hexCodes[2] === "fffeff") {
            lookingForEnd = false;
            hexCodes.splice(0, 3);
          } else {
            evText.push(hex2ascii(hexCodes.shift()));
          }
        }
        tcFlag = false;
        let ev = new Event({
          text: convertToHtml(parseCaptionText(evText.join(""))),
          start: tcLib.tcToSec(tc, options.frameRate)
        });
        events.push(ev);
      }
    }
    events.forEach((ev, index, evs) => {
      ev.end = evs[index + 1] ? evs[index + 1].start : ev.start + 2;
    });

    // console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Closed Caption Converter does not support writing CaptionMaker Plus files. Please contact us to have CaptionMaker Plus support added.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};