import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
import _eol from "eol";
const eol = _eol;
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent,
      blockInfo,
      blockItem,
      tcFlag = false,
      subtitleBlocks = input.split("\n");
    subtitleBlocks.forEach(block => {
      /* Replace any tabs for spaces so that we can support all versions of encore files */
      tcFlag = false;
      blockInfo = block.replace(/\t|\W\t/g, " ").split(" ");
      while (blockInfo.length > 0) {
        blockItem = blockInfo.shift();
        if (/\d\d:\d\d:\d\d:\d\d/.test(blockItem)) {
          tcFlag = true;
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          ccEvent.start = tcLib.tcToSec(blockItem, options.frameRate);
          ccEvent.end = tcLib.tcToSec(blockInfo.shift(), options.frameRate);
          if (events.length > 0 && events[events.length - 1].start === ccEvent.start) {
            ccEvent.text = events[events.length - 1].text + "\n" + blockInfo.splice(0).join(" ").trim().replace(/⏎/g, "\n");
            events.pop();
          } else {
            ccEvent.text = blockInfo.splice(0).join(" ").trim().replace(/⏎/g, "\n");
          }
          events.push(ccEvent);
        }
      }
      if (!tcFlag && events[events.length - 1]) {
        events[events.length - 1].text += "\n" + block;
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "";
    eventGroup.events.forEach(event => {
      output += tcLib.secToTc(event.start, options.frameRate);
      output += "\t";
      output += tcLib.secToTc(event.end, options.frameRate);
      output += "\t";
      output += convertToPlainText(event.text) + "\n";
    });
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      eventGroup.events.forEach((event, index, events) => {
        events[index].text = convertToHtml(event.text);
      });
      return eventGroup;
    }
  }
};