<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import stripTags from "@app/external/cc-lib/dist/functions/quill/stripTags.js";

/* Expand makes the event wider which requires us to add a word to the longest line from a shorter line*/
const expandEvent = function() {
    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, selectedEventIndex) => {
        let lineInfo = [];
        let longestLineIndex = 0;
        let longestLength = 0;
        let shorterLine;
        let newTextLines = "";
        if (!$eventGroupState[$projectState.selected].events[eventIndex].text) {
            return;
        }

        let textLines = $eventGroupState[$projectState.selected].events[eventIndex].text.split("</p>").filter(textLine => {
            return stripTags(textLine).length > 0;
        });

        if (textLines.length > 1) {
            textLines.forEach((line, index) => {
                let cleanHtml = line.replace(/(<([^>]+)>)/gi, "");

                if (cleanHtml.length > longestLength) {
                    longestLength = cleanHtml.length;
                    longestLineIndex = index;
                }
                if (cleanHtml.length > 0) {
                    lineInfo.push({
                        index: index,
                        html: line,
                        clean: cleanHtml,
                        length: cleanHtml.length
                    });
                }
            });

            if (lineInfo[longestLineIndex + 1] && lineInfo[longestLineIndex - 1]) {
                if (lineInfo[longestLineIndex + 1].length < lineInfo[longestLineIndex - 1].length) {
                    shorterLine = lineInfo[longestLineIndex + 1].clean.split(" ");
                    lineInfo[longestLineIndex].clean += " " + shorterLine.shift();
                    lineInfo[longestLineIndex + 1].clean = shorterLine.join(" ");
                } else {
                    shorterLine = lineInfo[longestLineIndex - 1].clean.split(" ");
                    lineInfo[longestLineIndex].clean = shorterLine.pop() + " " + lineInfo[longestLineIndex].clean;
                    lineInfo[longestLineIndex - 1].clean = shorterLine.join(" ");
                }
            } else if (lineInfo[longestLineIndex + 1]) {
                shorterLine = lineInfo[longestLineIndex + 1].clean.split(" ");
                lineInfo[longestLineIndex].clean += " " + shorterLine.shift();
                lineInfo[longestLineIndex + 1].clean = shorterLine.join(" ");
            } else {
                shorterLine = lineInfo[longestLineIndex - 1].clean.split(" ");
                lineInfo[longestLineIndex].clean = shorterLine.pop() + " " + lineInfo[longestLineIndex].clean;
                lineInfo[longestLineIndex - 1].clean = shorterLine.join(" ");
            }

            lineInfo.forEach(line => {
                if (line.clean.length > 0){
                    newTextLines += line.html.split(">")[0] + ">" + line.clean.trim() + "</p>";
                }                
            });

            if (selectedEventIndex === 0 && quillEditor) {
                quillEditor.clipboard.dangerouslyPasteHTML(newTextLines);
                quillEditor.setSelection(quillEditor.getLength(),0);
            } else {
                $eventGroupState[$projectState.selected].events[eventIndex].text = newTextLines;
            }
        }
    });

    historyState.insert({
        name: "expand event(s)", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
};
</script>
<li class="nav-item" title="Expand Event Text">
    <a class="nav-link" href="#!/" class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" on:click="{expandEvent}"><i class="bi bi-distribute-vertical"></i></a>
</li>
