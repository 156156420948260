<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';

import getDurationByCps from '@app/external/cc-lib/dist/functions/events/getDurationByCps.js';
import getCps from '@app/external/cc-lib/dist/functions/events/getCps.js';

let maxCps = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].maxCps : 20,
    minDuration = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].minDuration : 0.00001,
    maxDuration = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].maxDuration : 12,
    minFrameGap = 2;

let fixOverlaps = true;

function autoCorrectTiming() {
    try {
        let loops = 0
        let processing = true;
        let frameGapSec = minFrameGap/Math.round($projectState.frameRate);

        /* CPS and Duration Check */
        while (processing && loops < 20){
            processing = false;
            loops++;

            $eventGroupState[$projectState.selected].events.forEach((event, i, events) => {
                let cps = getCps(event),
                    duration = event.end - event.start,
                    idealDuration = getDurationByCps(event, maxCps),
                    prevEvent = i>0 ? events[i - 1] : null, 
                    nextEvent = i<events.length-1 ? events[i + 1] : null;

                if (cps > maxCps) {
                    processing = true;
                    if (nextEvent && event.start + idealDuration <= nextEvent.start){
                        event.end = event.start + idealDuration;
                    } else if (!nextEvent){
                        event.end = event.start + idealDuration;
                    } else if (prevEvent && prevEvent.end <= event.end-idealDuration) {
                        event.start = event.end-idealDuration;
                    } else if (!prevEvent && 0 <= event.end-idealDuration){
                        event.start = event.end-idealDuration;
                    } else {
                        event.end = event.start + idealDuration;
                        if (fixOverlaps){
                            events[i+1].start = event.end;
                        }                        
                    }

                    duration = event.end-event.start;
                }

                if (duration < minDuration){
                    processing = true;
                    if (nextEvent && event.start + minDuration <= nextEvent.start){
                        event.end = event.start + minDuration;
                    } else if (!nextEvent){
                        event.end = event.start + minDuration;
                    } else if (prevEvent && prevEvent.end <= event.end-minDuration) {
                        event.start = event.end-minDuration;
                    } else if (!prevEvent && 0 <= event.end-minDuration){
                        event.start = event.end-minDuration;
                    } else {
                        event.end = event.start + minDuration;
                        if (fixOverlaps){
                            events[i+1].start = event.end;
                        }
                    }
                } else if (duration > maxDuration && maxDuration < idealDuration){
                    prcessing = true;
                    event.end = event.start + maxDuration;
                }
                
                events[i] = event;
            });
        } 

        /* Frame Gap Check */
        $eventGroupState[$projectState.selected].events.forEach((event, i, events) => {
            if (i < events.length - 1){
                let nextEvent = events[i+1];
                let oGap = nextEvent.start - event.end;
                if (oGap < frameGapSec){
                    let gapDelta = frameGapSec-oGap;
                    events[i+1].start =  event.end + frameGapSec;
                    events[i+1].end += gapDelta;
                }
            }
        });

        $eventGroupState = $eventGroupState;
        
        historyState.insert({
            name: "auto correct", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

    } catch (err) {
        console.log(err, err.message);
        toast.push("Failed to auto correct events based on reading rate", {classes: ["toast-danger"]});
    } finally {
      modalState.hideModal();
    }
}
</script>

<div class="modal fade {$modalState === 'autoCorrectTiming' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AutoCorrectTimingModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Auto Correct Reading Speed</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{autoCorrectTiming}">
                    <!-- CPS -->
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label" for="maxCpsInput">Max CPS (Characters/sec)</label>
                            <input class="form-control" type="number" min="1" max="100" step="1" id="maxCpsInput" bind:value={maxCps}>
                        </div>
                    </div>
                    <!-- Duration -->
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label" for="minDurationInput">Min Duration (seconds)</label>
                            <input class="form-control" type="number" min="0" max="100" id="minDurationInput" bind:value={minDuration}>
                        </div>
                        <div class="col">
                            <label class="form-label" for="maxDurationInput">Max Duration (seconds)</label>
                            <input class="form-control" type="number" min="1" max="100" id="maxDurationInput" bind:value={maxDuration}>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="minFrameGapInput">Minimum Frame Gap</label>
                        <input class="form-control" type="number" min="0" max="100" step="1" id="minFrameGapInput" bind:value={minFrameGap}>
                    </div>
                    <!-- Fix Overlaps Checkbox -->
                    <div class="form-check mb-3">
                        <input class="form-check-input" type="checkbox" id="fixOverlapCheck" bind:checked={fixOverlaps}>
                        <label class="form-check-label" for="fixOverlapCheck">
                          Fix Overlaps
                        </label>
                      </div>
                    <div class="float-end">
                        <button class="btn btn-primary" on:click="{autoCorrectTiming}" type="button">Auto Correct</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
