import _Speaker2 from "../classes/speaker.js";
import _Event2 from "../classes/event.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _tcLib from "../lib/timecode.js";
import _eol from "eol";
import _Papa from "papaparse";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const Papa = _Papa;
const eol = _eol;
const tcLib = _tcLib;
const convertToPlainText = _convertToPlainText;
const convertToHtml = _convertToHtml;
const _Event = _Event2;
const _Speaker = _Speaker2;
const resolveColors = ["Blue", "Cyan", "Green", "Yellow", "Red", "Pink", "Purple", "Fuchsia", "Rose", "Lavender", "Sky", "Mint", "Lemon", "Sand", "Cocoa", "Cream"];
export default {
  decode: async function (input, options) {
    let events = [],
      nextIndex = 1,
      ev;
    eol.split(input).forEach((edlLine, lineIndex) => {
      if (!edlLine || lineIndex < 2) {
        return;
      }
      if (edlLine.split("  ")[0] && parseInt(edlLine.split("  ")[0]) === nextIndex) {
        if (ev) {
          ev.text = convertToHtml(ev.text.trim());
          events.push(ev);
        }
        let edlParts = edlLine.split(/\s+/);
        //console.log(edlParts);
        ev = new _Event();
        ev.start = tcLib.tcToSec(edlParts[4], options.frameRate);
        ev.end = tcLib.tcToSec(edlParts[5], options.frameRate);
        nextIndex++;
      } else if (ev) {
        let regex = /\|M:(.*?)\|D:/;
        let match = edlLine.match(regex);
        if (match) {
          let selectedText = match[1];
          ev.text = selectedText + ev.text;
        }
        ev.text += "\n" + edlLine.split("|C:")[0];
        //console.log(ev.text);
      } else {
        console.log("EDL LINE:", edlLine);
      }
    });
    if (ev) {
      ev.text = convertToHtml(ev.text.trim());
      events.push(ev);
    }

    //console.log(events);
    return events;
  },
  encode: async function (eventGroup, options) {
    let speakerMapping = {};
    let speakers = [];
    eventGroup.events.forEach(ev => {
      ev.speakers.forEach(sp => {
        speakers.push(sp.id);
      });
    });
    speakers = [...new Set(speakers)];
    speakers.forEach((sp, index) => {
      speakerMapping[sp] = resolveColors[index] ? resolveColors[index] : resolveColors[index % 16];
    });
    let output = eol.after("TITLE: Marker Export");
    output += options.dropFrame ? eol.after("FCM: DROP FRAME") : eol.after("FCM: NON-DROP FRAME");
    eventGroup.events.forEach((ev, index, evs) => {
      output = eol.after(output);
      output += eol.after(`${(index + 1).toString().padStart(3, "0")}  001      V     C        ${tcLib.secToTc(ev.start, options.frameRate)} ${tcLib.secToTc(ev.end, options.frameRate)} ${tcLib.secToTc(ev.start, options.frameRate)} ${tcLib.secToTc(ev.end, options.frameRate)}  `);
      output += convertToPlainText(ev.text);
      output += eol.after(` |C:ResolveColor${ev.speakers.length > 0 ? speakerMapping[ev.speakers[0].id] : "Blue"} |M:Marker ${index + 1}|D:1`);
    });

    // console.log(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};