<script>
    export let wpm = 0;
    export let cps = 0;
    export let duration = 0;
    export let totalWords = 0;
    export let lineInfo = 0;
    export let totalChars = 0;
    export let eventGroupSettings = {};

    function getClass(cps){
        let readingRate = cps/eventGroupSettings.maxCps;
        if (readingRate < 0.8) {
            return 'text-dark';
        } else if (readingRate >= 1) {
            return 'text-danger';
        } else {
            return 'text-warning';
        }
    }
</script>

<div id="metricsContainer" role="group" aria-label="Event metrics">
    <!-- WPM Words Per Minute -->
    <p id="wpmDisplay" aria-label="Words Per Minute {wpm}" class="{wpm/eventGroupSettings.maxWpm >= 1 ? 'text-danger' : wpm/eventGroupSettings.maxWpm >= 0.9 ? 'text-warning' : 'text-dark'} m-0 text-custom-xsmall text-truncate" title="{wpm} Words Per Minute">{wpm} wpm</p>
    <!-- CPS Characters Per Second -->
    <p class="{getClass(cps)} m-0 text-custom-xsmall text-truncate" 
       style="--cps-ratio: {cps/eventGroupSettings.maxCps};"
       title="{cps} Characters Per Second">
       {cps} cps
    </p>
    <!-- Duration Seconds -->
    <p class="{duration > eventGroupSettings.maxDuration || duration < eventGroupSettings.minDuration ? 'text-danger' : 'text-dark'} m-0 text-custom-xsmall text-truncate" title="{duration} Seconds (Total Duration)">{duration} sec</p>
    <!-- Total Words -->
    <p class="text-dark m-0 text-custom-xsmall text-truncate" title="{totalWords} Total Words">{totalWords} words</p>
    <!-- Character Count Per Line -->
    <p class="{lineInfo && (lineInfo.some(el => el > eventGroupSettings.maxChars) || lineInfo.length > eventGroupSettings.maxLines) ? 'text-danger' : 'text-dark'} mb-2 text-custom-xsmall text-truncate" title="{lineInfo} = {totalChars} Total Characters">({lineInfo})={totalChars}</p>
</div>