<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {playerState} from '@app/store/playerStore.js';
import {lockState} from '@app/store/lockStore.js';
import {styleState} from '@app/store/styleStore.js';
import { tick } from 'svelte';

let eventIndex, eventListElement = document.getElementById("EventList"), clientWidth = 0, clientHeight = 0;

async function getEvent(){
    if ($playerState.playing){
        eventIndex = getEventByTime($playerState.time);

        /* Scroll to and select the event if caption lock is enabled */
        if ($lockState.caption && eventIndex !== -1 && $eventGroupState[$projectState.selected].selected[0] !== eventIndex){
            $eventGroupState[$projectState.selected].selected = [eventIndex];
            await tick();
            if (!document.getElementById("QuillEditor")){
                try {
                    eventListElement.scrollTo(0, (eventIndex-2)*230);
                } catch (err) {
                    eventListElement = document.getElementById("EventList");
                    eventListElement.scrollTo(0, (eventIndex-2)*230);
                }
            }              
        } else if ($lockState.caption && eventIndex !== -1){
            if (!document.getElementById("QuillEditor")){
                try {
                    eventListElement.scrollTo(0, (eventIndex-2)*230);
                } catch (err) {
                    eventListElement = document.getElementById("EventList");
                    eventListElement.scrollTo(0, (eventIndex-2)*230);
                }
            }              
        }
    } else {
        eventIndex = $eventGroupState[$projectState.selected].selected[0];
    }
}

function getEventByTime(sec){
    return $eventGroupState[$projectState.selected].events.findIndex(event=>{
        return event.start <= sec && event.end >= sec;
    });
}

function decToHex(value){
    return Math.floor((value/100)*255).toString(16).padStart(2, "0");
}

$: getEvent($playerState.time);
$: eventIndex = $eventGroupState[$projectState.selected].selected[0];
</script>

<!-- horizontal alignmnet: justify-content-start, justify-content-center, justify-content-end -->
<!-- vertical alignmnet: align-items-start, align-items-center, align-items-end -->

{#if $lockState.preview && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events[eventIndex]}
<div id="SubtitlePreviewWrapper" bind:clientWidth={clientWidth} bind:clientHeight={clientHeight} class="d-flex justify-content-{$eventGroupState[$projectState.selected].events[eventIndex].xPos} align-items-{$eventGroupState[$projectState.selected].events[eventIndex].yPos}" style="padding: {(clientHeight*(($styleState.mode ? $projectState.media.aspectRatio === "4:3" ? 0.095 : 0.10 : ($styleState.yPadding)/100))).toFixed(2)}px {$styleState.mode ? $projectState.media.aspectRatio === "4:3" ? 8 : 18 : $styleState.xPadding}%; {$styleState.grid ? $styleState.mode ? `background: url('./assets/img/grid.png') no-repeat; background-size: cover; background-origin: content-box;` : `background: url('./assets/img/grid-subtitle.png') no-repeat; background-size: contain; background-origin: content-box; background-size:100% 100%;` : ``}">
    <div id="SubtitlePreview" class="{$styleState.mode ? 'cc-preview-mode' : ''}"
    style="direction: {$eventGroupState[$projectState.selected].rtl ? 'rtl' : 'ltr'}; 
    top:{$eventGroupState[$projectState.selected].events[eventIndex].yOffset}px; 
    left:{$eventGroupState[$projectState.selected].events[eventIndex].xOffset}px; 
    font-family: {$styleState.mode ? 'monospace' : $styleState.fontFamily}; 
    font-size: {$styleState.mode ? `${(clientHeight/19).toFixed(2)}px` : `${$styleState.fontSize}px`}; 
    color: {$styleState.mode ? '#fff' : $styleState.color+decToHex($styleState.opacity)}; 
    line-height: {$styleState.mode ? 100 : $styleState.lineSpacing}%; 
    letter-spacing: {$styleState.mode ? `${((clientHeight/19)*0.12).toFixed(2)}px` : `${$styleState.spacing}px`}; 
    {$styleState.borderStyle == "1" && !$styleState.mode ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor+decToHex($styleState.shadowOpacity)}; 
    -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor+decToHex($styleState.outlineOpacity)}; background: none;` : `background: ${$styleState.mode ? 'none' : $styleState.outlineColor+decToHex($styleState.outlineOpacity)}; 
    padding: ${$styleState.mode ? 0 : $styleState.outline}px`}; 
    text-align: {$eventGroupState[$projectState.selected].events[eventIndex].alignment}; text-wrap: nowrap; overflow: hidden;">
      {@html $eventGroupState[$projectState.selected].events[eventIndex].text}
    </div>
</div>
{/if}
<style>
#SubtitlePreviewWrapper {
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
}

#SubtitlePreview {
  position: relative;
  user-select: none;
}
</style>