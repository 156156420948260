<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {playerState} from '@app/store/playerStore.js';
import {lockState} from '@app/store/lockStore.js';
import {styleState} from '@app/store/styleStore.js';
import { tick } from 'svelte';

let eventIndex, eventListElement = document.getElementById("EventList");

async function getEvent(){
    if ($playerState.playing){
        eventIndex = getEventByTime($playerState.time);

        /* Scroll to and select the event if caption lock is enabled */
        if ($lockState.caption && eventIndex !== -1){
            $eventGroupState[$projectState.selected].selected = [eventIndex];
            await tick();
            if (!document.getElementById("QuillEditor")){
                try {
                    eventListElement.scrollTo(0, (eventIndex-2)*230);
                } catch (err) {
                    eventListElement = document.getElementById("EventList");
                    eventListElement.scrollTo(0, (eventIndex-2)*230);
                }
            }              
        }
    } else {
        eventIndex = undefined;
    }
}

function getEventByTime(sec){
    return $eventGroupState[$projectState.selected].events.findIndex(event=>{
        return event.start <= sec && event.end >= sec;
    });
}

function decToHex(value){
    return Math.floor((value/100)*255).toString(16).padStart(2, "0");
}

$: getEvent($playerState.time);
</script>

<!-- horizontal alignmnet: justify-content-start, justify-content-center, justify-content-end -->
<!-- vertical alignmnet: align-items-start, align-items-center, align-items-end -->

{#if $lockState.preview && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events[eventIndex]}
<div id="SubtitlePreviewWrapper" class="d-flex justify-content-start align-items-end">
    <div id="SubtitlePreview"
        style="direction: {$eventGroupState[$projectState.selected].rtl ? 'rtl' : 'ltr'}; font-family: {$styleState.mode ? 'monospace' : $styleState.fontFamily}; font-size: {$styleState.mode ? '16' : $styleState.fontSize}px; color: {$styleState.mode ? '#fff' : $styleState.color+decToHex($styleState.opacity)}; line-height: {$styleState.mode ? 100 : $styleState.lineSpacing}%; letter-spacing: {$styleState.mode ? `0.57ex` : `${$styleState.spacing}px`}; {$styleState.borderStyle == "1" ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor+decToHex($styleState.shadowOpacity)}; -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor+decToHex($styleState.outlineOpacity)}; background: none;` : `background: ${$styleState.mode ? 'black' : $styleState.outlineColor+decToHex($styleState.outlineOpacity)}; 
        padding: ${$styleState.mode ? 0 : $styleState.outline}px`};">
        {@html $eventGroupState[$projectState.selected].events[eventIndex].text}
    </div>
</div>
{/if}
<style>
#SubtitlePreviewWrapper {
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  height: 100%;
  width: 100%;
  top: 0;
}

#SubtitlePreview {
  user-select: none;
}
</style>