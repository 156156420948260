<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import autoFormat from "@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js";

    /* Compressions makes the event longer which requires us to remove a word from the longest line and move it to a shorter line*/
    const autoFormatEvents = function () {
        try {
            let maxLines = $eventGroupState[$projectState.selected]
                ? $eventGroupState[$projectState.selected].maxLines
                : 2;
            let maxChars = $eventGroupState[$projectState.selected]
                ? $eventGroupState[$projectState.selected].maxChars
                : 32;
            let minDuration = $eventGroupState[$projectState.selected]
                ? $eventGroupState[$projectState.selected].minDuration
                : 1;

            let tempSelect = [
                ...$eventGroupState[$projectState.selected].selected,
            ];

            try {
                $eventGroupState[$projectState.selected] = autoFormat(
                    $eventGroupState[$projectState.selected],
                    maxLines,
                    maxChars,
                    minDuration,
                    true,
                    $eventGroupState[$projectState.selected].selected,
                );
            } catch (err) {
                console.log(err.message);
            }

            $eventGroupState[$projectState.selected].selected = [];
            setTimeout(() => {
                $eventGroupState[$projectState.selected].selected = tempSelect;
            }, 50);

            toast.push("Auto format completed", {
                classes: ["toast-success"],
            });

            historyState.insert({
                name: "auto format", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });
        } catch (err) {
            console.log(err);
            console.log(err.message);
        }
    };
</script>

<li class="nav-item" title="Auto Format Events">
    <a
        class="nav-link"
        href="#!/"
        id="auto-format-btn"
        role="button"
        aria-label="Auto format selected events"
        aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        on:click={autoFormatEvents}
        class:disabled={!$eventGroupState[$projectState.selected] ||
            $eventGroupState[$projectState.selected].selected.length === 0}
    >
    <i class="bi bi-check2-square"></i>
    </a>
</li>
