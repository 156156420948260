<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
const { webFrame } = require('electron')

let zoomLevel = webFrame.getZoomFactor() * 100;

function zoomIn() {
    zoomLevel += 10
    webFrame.setZoomFactor(zoomLevel/100);
}

function zoomOut() {
    zoomLevel -= 10
    webFrame.setZoomFactor(zoomLevel/100);
}

function updateZoomLevel() {
    webFrame.setZoomFactor(zoomLevel/100);
}

</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'zoom' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="ZoomModal">
    <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Zoom Settings</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="input-group">
                        <button on:click={zoomIn} class="btn btn-outline-dark" type="button"><i class="bi bi-plus-lg"></i></button>
                        <button on:click={zoomOut} class="btn btn-outline-dark" type="button"><i class="bi bi-dash-lg"></i></button>
                        <input type="number" step="10" min="50" max="150" class="form-control" placeholder="Zoom Value" bind:value={zoomLevel} on:blur={updateZoomLevel}>
                        <span class="input-group-text">%</span>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
