<script>
import {
  playerState
} from '@app/store/playerStore.js';
import {adrState} from '@app/store/adrStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {lockState} from '@app/store/lockStore.js';
import { clickOutside } from 'svelte-use-click-outside';
import AudioTrackControls from './AudioTrackControls.svelte';
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';

let showRateMenu = false;
let showVolumeControl = false;
let volume = 1;
let adVolume = 1;
let adPlayer = false

let tc = tcLib.secToTc($playerState.time + $projectState.incode, $projectState.frameRate, $projectState.dropFrame);

function validateTc(e) {
    tc = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);

    player.currentTime = tcLib.tcToSec(tc, $projectState.frameRate, $projectState.dropFrame) - $projectState.incode;
}

function frameByFrame(e) {
    if (e.key === "ArrowUp") {
        player.currentTime += 1 / $projectState.frameRate;
    } else if (e.key === "ArrowDown") {
        player.currentTime -= 1 / $projectState.frameRate;
    }
}

function updateTc(sec) {    
    tc = tcLib.secToTc(sec+$projectState.incode, $projectState.frameRate, $projectState.dropFrame);
}

$: if ($projectState.frameRate || $projectState.incode) {
    updateTc($playerState.time);
}

$: try {
    if (player){
        player.volume = volume;
    }  
} catch (e) {
  console.log(e.message);
}

$: try {
    if (!adPlayer){
        adPlayer = document.getElementById("adPlayer");
    }
    adPlayer.volume = adVolume;
} catch(err){
    console.log(err.message);
}

function updatePlaybackSpeed(speed) {
  player.speed = speed;
  playerState.updatePlayerSpeed(speed);
  showRateMenu = false;
}

function goToStart() {
  player.currentTime = 0;
}

function goToEnd() {
  player.currentTime = player.duration;
}

function rewind() {
  player.currentTime = $playerState.time - (1/$projectState.frameRate);
}

function fastForward() {
  player.currentTime = $playerState.time + (1/$projectState.frameRate);
}

function togglePlayback() {
  if (player.playing) {
    player.pause();
  } else {
    player.play()
  }
}

function prevEvent(){
    try {
        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0] - 1];  
        updatePlayerTime();
    } catch(err){
        console.log(err, err.message);
    }
    
}

function nextEvent(){
    try {
        if ($eventGroupState[$projectState.selected].selected[0]+1 < $eventGroupState[$projectState.selected].events.length){
            $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0] + 1];
            updatePlayerTime();
        } 
    } catch(err){
        console.log(err, err.message);
    }  
}

function updatePlayerTime(){
    let index = $eventGroupState[$projectState.selected].selected[0] || parseInt("0");
    if ($lockState.video && !isNaN($eventGroupState[$projectState.selected].events[index].start) && player){
        player.currentTime = $eventGroupState[$projectState.selected].events[index].start;
    }
}

</script>
<div class="btn-group btn-group-sm d-xl-flex justify-content-xl-center shadow" role="group" id="PlayerControls">
    {#if $projectState.media && $projectState.media.storage === "Local Storage"}
        <AudioTrackControls></AudioTrackControls>
    {/if}
    <div class="dropdown {showVolumeControl ? 'show' : ''}" use:clickOutside={() => showVolumeControl = false}>
        <button id="VolumeDropdown" class="btn btn-sm btn-light dropdown-toggle rounded-0" type="button" on:click={() => showVolumeControl = true}>
          {#if volume > 0.7}
          <i class="bi bi-volume-up"></i>
          {:else if volume === 0}
          <i class="bi bi-volume-mute"></i>
          {:else if volume < 0.3}
          <i class="bi bi-volume-off"></i>
          {:else}
          <i class="bi bi-volume-down"></i>
          {/if}
        </button>
        <div class="dropdown-menu p-2 {showVolumeControl ? 'show' : ''}">
            <i class="bi bi-music-note-beamed"></i> <input type="range"  min="0" max="1" step="0.025" bind:value={volume}>
            {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "audio description"}
                <i class="bi bi-badge-ad"></i> <input id="AdVolumeControl" type="range" min="0" max="1" step="0.025" bind:value={adVolume}>
            {/if}
        </div>
    </div>
    <div class="dropdown {showRateMenu ? 'show' : ''}" use:clickOutside={() => showRateMenu = false}>
        <button id="RateDropdown" class="btn btn-sm btn-light dropdown-toggle rounded-0" type="button" on:click={() => showRateMenu = true}>x{$playerState.speed}</button>
        <div class="dropdown-menu {showRateMenu ? 'show' : ''}">
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(0.25)}>0.25</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(0.50)}>0.50</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(0.75)}>0.75</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(1.00)}>1.00</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(1.25)}>1.25</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(1.50)}>1.50</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(1.75)}>1.75</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(2.00)}>2.00</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(4.00)}>4.00</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(8.00)}>8.00</a>
            <a class="dropdown-item" href="#!/" on:click={() => updatePlaybackSpeed(16.00)}>16.0</a>
        </div>
    </div>

    <button on:click={goToStart} class="btn btn-sm btn-light"  type="button" title="Go To Start"> <i class="bi bi-skip-start-fill" /> </button>

    <button on:click={rewind} class="btn btn-sm btn-light"  type="button" title="Frame Back"> <i class="bi bi-skip-backward-fill" /></button>
    <!-- RECORD START -->
    {#if $adrState.enable && $adrState.selectedOutput}
    <button on:click={() => $adrState.record = !$adrState.record} class="btn btn-sm {$adrState.record ? 'btn-danger' : 'btn-light '}" type="button" title="Record Enable/Disable"> 
        <i class="bi bi-record-fill"></i>
      </button>
    {/if}
    <!-- RECORD END -->
    <button on:click={togglePlayback} class="btn btn-sm {$playerState.playing ? 'btn-primary' : 'btn-light'}"  type="button" title="Play/Pause"> 
      {#if $playerState.playing}
        <i class="bi bi-pause-fill" />
      {:else}
        <i class="bi bi-play-fill" />
      {/if}
    </button>
    <button on:click={fastForward} class="btn btn-sm btn-light"  type="button" title="Frame Forward"> <i class="bi bi-skip-forward-fill" /></button>
    <button on:click={goToEnd} class="btn btn-sm btn-light"  type="button" title="Go To End"> <i class="bi bi-skip-end-fill" /> </button>

    <!-- TC Reader -->
    <input type="text" class="text-center bg-light" style="width: 12ch; font-size: 0.8vw; border: none" on:keydown={frameByFrame} bind:value={tc} on:blur={validateTc} on:keyup={e=>e.key==='Enter' && validateTc(e)} on:focus="{(e)=>{e.target.select()}}"/>

    <button class="btn btn-sm btn-light"  type="button" title="Previous Event" on:click="{prevEvent}" disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === 0}"> <i class="bi bi-chevron-left" /> </button>

    <button class="btn btn-light"  type="button" title="Next Event" on:click="{nextEvent}" disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0]+1 === $eventGroupState[$projectState.selected].events.length}"> <i class="bi bi-chevron-right" /> </button>

    <!-- LOCKS -->
    <button class="btn btn-sm {$lockState.caption ? 'btn-primary' : 'btn-light'}"  type="button" title="Lock CC or AD to Video" on:click={() => $lockState.caption = !$lockState.caption}> <i class="bi {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === 'audio description' ? 'bi-badge-ad' : 'bi-badge-cc'}" /> <sub><i class="bi {$lockState.caption ? 'bi-lock-fill' : 'bi-unlock-fill'}"/></sub></button>

    <button class="btn btn-sm {$lockState.video ? 'btn-primary' : 'btn-light'}"  type="button" title="Lock Video to CC" on:click={() => $lockState.video = !$lockState.video}> <i class="bi bi-film" /> <sub><i class="bi {$lockState.video ? 'bi-lock-fill' : 'bi-unlock-fill'}"/></sub></button>

    <button class="btn btn-sm rounded-0 {$lockState.preview ? 'btn-primary' : 'btn-light'}" type="button" title="Preview CC" on:click={() => $lockState.preview = !$lockState.preview}> <i class="bi {$lockState.preview ? 'bi-eye-fill' : 'bi-eye-slash'}" /> </button>
</div>

<style>
    .dropdown-menu{
        z-index: 9999;
    }

    i {
        pointer-events: none;
        font-size: 0.8vw;
    }
</style>
