export default {
  'zh': 'Chinese, Mandarin (Simplified, China)',
  'en-IN': 'English - India',
  'en-SG': 'English - Singapore',
  'en-GB': 'English - United Kingdom',
  'en-US': 'English - United States',
  'en-US': 'English - Global',
  'fr-FR': 'French - France',
  'de-DE': 'German - Germany',
  'it-IT': 'Italian - Italy',
  'ja-JP': 'Japanese - Japan',
  'pt-BR': 'Portuguese - Brazil',
  'ru-RU': 'Russian',
  'es-ES': 'Spanish - Spain'
};