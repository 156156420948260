<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();
let isClipboardEventJson = false;
try {
    navigator.clipboard.readText().then(text => {
        try { 
            let eventJson = JSON.parse(text);
            if (Array.isArray(eventJson) && eventJson[0].id && eventJson[0].xPos && eventJson[0].yPos){
                isClipboardEventJson = true;
            }
        } catch(err){
            // console.log(err.message);
        }
    });
} catch(err){
    console.log(err.message);
}


function openAttributesModal(){
    modalState.showModal("pasteAttributes");
    dispatch("done");
}

</script>

<a class="dropdown-item {!isClipboardEventJson ? 'disabled' : ''}" href="#!/" on:click={openAttributesModal}>Paste Attributes...</a>