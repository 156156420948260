<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import { toast } from '@zerodevx/svelte-toast';

let applyAll = false;
let style = "Pop-On";

function updateCaptionStyle(){
    console.log(applyAll);
    if (applyAll){
        $eventGroupState[$projectState.selected].events.forEach((event, index, events) =>{
            $eventGroupState[$projectState.selected].events[index].style = style;
        });
    } else {
        $eventGroupState[$projectState.selected].selected.forEach(eventIndex =>{
            $eventGroupState[$projectState.selected].events[eventIndex].style = style;
        })
    }
    
    toast.push("Caption style updated to "+style, {
        classes: ["toast-success"]
    });

    historyState.insert({
        name: "update caption style", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    modalState.hideModal();
}
</script>

<div class="modal fade {$modalState === 'captionStyle' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AutoFormatModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Caption Style</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{updateCaptionStyle}">
                    <div class="mb-3">
                        <label class="form-label" for="captionStyle">Caption Style</label>
                        <select class="form-select" bind:value="{style}">
                            <option selected="">Pop-On</option>
                            <option>Roll-Up 2</option>
                            <option>Roll-Up 3</option>
                            <option>Roll-Up 4</option>
                            <option>Paint-On</option>             
                        </select>
                    </div>                    
                    <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="applyToAll" bind:checked={applyAll}>
                        <label class="form-check-label" for="applyToAll">Apply to All <i class="bi bi-info-circle" title="Update the caption style of all events in the selected event group."></i></label>
                    </div>
                    <div class="float-end">
                        <button class="btn btn-primary" on:click="{updateCaptionStyle}" type="button">Update Style</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
