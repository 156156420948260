import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _xmlFormat from "xml-formatter";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const xmlFormat = _xmlFormat; //Encode
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;

//Region Map will be used to set the region data by taking the event yPos and xPos. e.g. regionMap[event.yPos][event.xPos]
const regionMap = {
  "start": {
    "start": "topLeft",
    "center": "topCenter",
    "end": "topRight"
  },
  "center": {
    "start": "centerLeft",
    "center": "center",
    "end": "centerRight"
  },
  "end": {
    "start": "bottomLeft",
    "center": "bottomCenter",
    "end": "bottomRight"
  }
};
export default {
  decode: function (input, options) {
    let events = [],
      regions = [],
      region;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(options);
    //console.log(JSON.stringify(fileJson, null, 4));

    /* Put the region data into an array */
    if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
      if (Array.isArray(fileJson.tt.head.layout.region)) {
        regions = fileJson.tt.head.layout.region;
      } else {
        regions = [fileJson.tt.head.layout.region];
      }
    }
    if (Array.isArray(fileJson.tt.body.div)) {
      fileJson.tt.body.div.forEach(paragraphGroup => {
        if (Array.isArray(paragraphGroup.p)) {
          paragraphGroup.p.forEach(paragraph => {
            if (paragraph["@_region"]) {
              region = regions.find(regionEl => {
                return regionEl["@_xml:id"] === paragraph["@_region"];
              });
            } else {
              region = false;
            }
            events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
          });
        } else {
          if (paragraphGroup.p["@_region"]) {
            region = regions.find(regionEl => {
              return regionEl["@_xml:id"] === paragraphGroup.p["@_region"];
            });
          } else {
            region = false;
          }
          events.push(ttmlFunc.multiLine.decodeSubtitle(paragraphGroup.p, region, options.frameRate, options.window));
        }
      });
    } else {
      if (Array.isArray(fileJson.tt.body.div.p)) {
        fileJson.tt.body.div.p.forEach(paragraph => {
          if (paragraph["@_region"]) {
            region = regions.find(regionEl => {
              return regionEl["@_xml:id"] === paragraph["@_region"];
            });
          } else {
            region = false;
          }
          events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
        });
      } else {
        let paragraph = fileJson.tt.body.div.p;
        if (paragraph["@_region"]) {
          region = regions.find(regionEl => {
            return regionEl["@_xml:id"] === paragraph["@_region"];
          });
        } else {
          region = false;
        }
        events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
      }
    }
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xmlns="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt" ttp:profile="http://www.w3.org/ns/ttml/profile/imsc1/text" xml:lang="${encodingOptions["Language Code"] || "en"}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:timeBase="media">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>
        <ttm:desc>Closed Caption Converter - TTML Export</ttm:desc>
        <smpte:information xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" mode="Preserved" m608:channel="CC1" m608:programName="Untitled" m608:captionService="F1C1CC"/>
    </metadata>`);
    output += eol.after(`<styling><style xml:id="basic" tts:color="${encodingOptions["Font Color"] || "white"}" tts:backgroundColor="${encodingOptions["Background Color"] || "black"}" tts:fontFamily="${encodingOptions["Font Family"] || "monospace"}" tts:fontSize="${encodingOptions["Font Size (%)"] || 80}%" tts:fontWeight="${encodingOptions["Font Weight"] || "normal"}" tts:fontStyle="${encodingOptions["Font Style"] || "normal"}"/></styling>`);
    output += eol.after(`<layout>
                <region xml:id="topLeft" tts:displayAlign="before" tts:origin="10% 10%" tts:extent="40% 40%" tts:textAlign="start"/>
                <region xml:id="topCenter" tts:displayAlign="before" tts:origin="10% 10%" tts:extent="80% 40%" tts:textAlign="center"/>
                <region xml:id="topRight" tts:displayAlign="before" tts:origin="50% 10%" tts:extent="40% 40%" tts:textAlign="end"/>
                <region xml:id="centerLeft" tts:displayAlign="center" tts:origin="10% 30%" tts:extent="40% 40%" tts:textAlign="start"/>
                <region xml:id="center" tts:displayAlign="center" tts:origin="10% 30%" tts:extent="80% 40%" tts:textAlign="center"/>
                <region xml:id="centerRight" tts:displayAlign="center" tts:origin="50% 30%" tts:extent="40% 40%" tts:textAlign="end"/>
                <region xml:id="bottomLeft" tts:displayAlign="after" tts:origin="10% 50%" tts:extent="40% 40%" tts:textAlign="start"/>
                <region xml:id="bottomCenter" tts:displayAlign="after" tts:origin="10% 50%" tts:extent="80% 40%" tts:textAlign="center"/>
                <region xml:id="bottomRight" tts:displayAlign="after" tts:origin="50% 50%" tts:extent="40% 40%" tts:textAlign="end"/>                
              </layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div>`);

    /* EVENTS */
    eventGroup.events.forEach((event, index, events) => {
      let start = encodingOptions["Timecode Format"] === "smpte" ? tcLib.secToTcClock(event.start, options.frameRate) : tcLib.secToTcMs(event.start);
      let end = encodingOptions["Timecode Format"] === "smpte" ? tcLib.secToTcClock(event.end, options.frameRate) : tcLib.secToTcMs(event.end);
      //console.log(event.text);
      let plainTextCustom = convertToPlainTextCustom(event.text, "\n", false, "strong", "em", "u", true);
      let plainText = convertToPlainText(event.text);
      let extents = ttmlFunc.multiLine.calcExtents(plainText, encodingOptions["Font Size (%)"]);
      let origins = ttmlFunc.multiLine.calcOrigin(plainText, event.xPos, event.xOffset, event.yPos, event.yOffset, encodingOptions["Font Size (%)"], options.window);
      //console.log(text);
      output += eol.after(`<p begin="${start}" end="${end}" style="basic" region="${regionMap[event.yPos][event.xPos]}">${ttmlFunc.multiLine.convertToTtml(plainTextCustom)}</p>`);
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += `</tt>`;
    //console.log(output);
    return xmlFormat(eol.auto(output));
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};