<script>
import {
    fade
} from "svelte/transition";
import {
    modalState
} from "@app/store/modalStore.js";
import {
    environment
} from "@app/store/envStore.js";
import {
    authState
} from "@app/store/authStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    v4 as uuidv4
} from "uuid";

import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";

/* Firebase */
import firebase from "@app/configs/firebase.js";
import db from "@app/configs/firestore.js";
import storage from "@app/configs/storage.js";

/* Language Settings */
import assemblyAiLanguages from "@app/external/cc-lib/dist/providers/assemblyAi/languages.js";
import deepgramLanguages from "@app/external/cc-lib/dist/providers/deepgram/languages.js";
import revAiLanguages from "@app/external/cc-lib/dist/providers/revAi/languages.js";
import googleLanguages from "@app/external/cc-lib/dist/providers/googleSpeechToText/languages.js";
import speechmaticsLanguages from "@app/external/cc-lib/dist/providers/speechmatics/languages.js";
import voiceGainLanguages from "@app/external/cc-lib/dist/providers/voicegain/languages.js";
import elevenLabsScribeLanguages from "@app/external/cc-lib/dist/providers/elevenLabsScribe/languages.js";

let files = [],
    provider = "ElevenLabs Scribe",
    language = "eng",
    totalFiles = 0,
    interactionType = "PROFESSIONALLY_PRODUCED",
    speakers = 2,
    processing = false,
    progress = 0,
    userId = firebase.auth().currentUser.uid,
    userEmail = firebase.auth().currentUser.email,
    teamId = $authState.team ? $authState.team.id : null,
    homeRef = $authState.team ? db.collection("teams").doc(teamId).collection("jobs") : db.collection("users").doc(userId).collection("jobs"),
    storageRef = storage.ref(),
    uploadBasePath = $authState.team ? "teams/" + teamId + "/watch/video/" : "users/" + userId + "/uploads/",
    audioUploadBasePath = $authState.team ? "teams/" + teamId + "/watch/audio/" : "users/" + userId + "/audio/";

function start() {
    processing = true;
    totalFiles = files.length;
    processJob(0);
}

async function processJob(index) {
    updateProgress(index);
    files[index].jobId = uuidv4();
    files[index].registered = 'in progress';

    if ($environment.electron) {        
        const { webUtils } = require('electron');
        files[index].path = await webUtils.getPathForFile(files[index]);
        extractAudio(index);
    } else {
        registerJob(index);
    }
}

function extractAudio(index) {
    try {
        const os = window.os;
        const path = window.path;
        const ffmpegPath = require("ffmpeg-static-electron").path;
        const ffmpeg = require("fluent-ffmpeg");

        files[index].audioPath = os.tmpdir() + path.sep + files[index].jobId + ".wav";
        ffmpeg.setFfmpegPath(
            ffmpegPath.replace("app.asar", "app.asar.unpacked"),
        );

        ffmpeg(files[index].path)
            .audioCodec("pcm_mulaw")
            .audioChannels(1)
            .audioFrequency(44100)
            .output(files[index].audioPath)
            .on("start", function(commandLine) {
                console.log("Spawned Ffmpeg with command: " + commandLine);
            })
            .on("codecData", function(data) {
                console.log(data.duration);
                files[index].duration = data.duration;
                console.log("Input is " + data.audio + " audio " + "with " + data.video + " video");
            })
            .on("progress", function(prog) {
                /* prog = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
                console.log("Scanning: " + prog.timemark + " done");
            })
            .on("end", function() {
                registerJob(index);
            })
            .on("error", function(err, stdout, stderr) {
                console.log(err, err.message);

                toast.push("Failed to extract audio from source file", {
                    classes: ["toast-danger"]
                });

                files[index].registered = 'failed';

                if (index === totalFiles - 1) {
                    end();
                } else {
                    processJob(index + 1);
                }
            })
            .run();
    } catch (err) {
        console.log(err, err.message);
    }
}

async function registerJob(index) {
    try {
        let jobInfo = {
            id: files[index].jobId,
            projectId: files[index].jobId,
            projectName: files[index].name,
            submittedBy: userEmail,
            userId: userId,
            extId: null,
            progress: 0,
            duration: files[index].duration ? tcLib.tcMsToSec(files[index].duration) : 0,
            cost: 0,
            type: "transcription",
            statusMsg: "Awaiting Media",
            status: "Submitted",
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            completedOn: null,
            deleted: false,
            config: {
                provider: provider,
                language: language,
                speakers: speakers,
                interactionType: interactionType,
            }
        };

        await homeRef.doc(jobInfo.id).set(jobInfo);
        files[index].registered = 'done';
        uploadFile(index);
    } catch (err){
        console.log(err,err.message);
        files[index].registered = 'failed';

        if (index === totalFiles - 1) {
            end();
        } else {
            processJob(index + 1);
        }
    }

}

async function uploadFile(index) {
    let uploadTask;
    if (files[index].audioPath){
        let fileResponse = await fetch(files[index].audioPath);
        let byteArrayFileRes = await fileResponse.arrayBuffer();
        uploadTask = storageRef.child(($environment.electron ? audioUploadBasePath : uploadBasePath) + files[index].jobId).put(byteArrayFileRes);
    } else {
        uploadTask = storageRef.child(($environment.electron ? audioUploadBasePath : uploadBasePath) + files[index].jobId).put(files[index]);
    }

    uploadTask.on('state_changed', (snapshot) => {
            //console.log(snapshot);
            files[index].progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2);
        },
        (error) => {
            console.log(error, error.code);
            toast.push("Failed to upload media file", {
                classes: ["toast-danger"]
            });

            /* Update Job Record */
            homeRef.doc(files[index].jobId).update({
                progress: 100,
                statusMsg: error.code,
                status: "Failed",
                completedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
            });

            files[index].registered = 'failed';

            if (index === totalFiles - 1) {
                end();
            } else {
                processJob(index + 1);
            }
        },
        () => {
            // Handle successful uploads on complete
            console.log("TRANSFER COMPLETE");
            if (index === totalFiles - 1) {
                end();
            } else {
                processJob(index + 1);
            }
        }
    );
}

function end() {
    console.log("Ending Batch Transcription");
    toast.push("Jobs submitted successfully", {
        classes: ["toast-success"]
    });
    modalState.showModal("aiTranscriptImport");
}

function resetLanguages() {
    if (provider === "Google Speech-to-Text") {
        language = "en-US";
    } else if (provider === "ElevenLabs Scribe"){
            language = "eng";
    } else {
        language = "en";
    }
}

async function updateProgress(val) {
    progress = parseInt((val / totalFiles) * 100);
}
</script>

<div transition:fade={{ duration: 100 }} 
    class="modal {$modalState === 'batchTranscription' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="batchTransModalTitle"
    aria-describedby="batchTransModalDesc"
    tabindex="-1" 
    id="batchTranscriptionModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="batchTransModalTitle">Batch Transcription</h4>
                <button type="button" class="btn-close" id="closeBatchTransModal" aria-label="Close dialog" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <p id="batchTransModalDesc" class="visually-hidden">Configure and submit batch transcription jobs</p>
                <div class="container">
                    <div class="row">
                        <div class="col-auto">
                            <form class="bg-secondary shadow p-4 rounded">
                                <div class="mb-2">
                                    <label class="form-label" for="batchFileInput">Select files to process</label>
                                    <input id="batchFileInput" class="form-control" type="file" multiple="true" 
                                        accept="video/*,audio/*" bind:files 
                                        aria-describedby="fileInputHelp" />
                                    <div id="fileInputHelp" class="form-text">Select video or audio files for transcription</div>
                                </div>
                                <div class="mb-2">
                                    <label class="form-label" for="serviceProvider">Service Provider</label>
                                    <select id="serviceProvider" class="form-select" 
                                        bind:value={provider} 
                                        on:change={resetLanguages}
                                        aria-describedby="providerHelp">
                                        <option>Deepgram</option>
                                        <option>Assembly AI</option>
                                        <option>Google Speech-to-Text</option>
                                        <option>Rev AI</option>
                                        <option>Speechmatics</option>
                                        <option>Voicegain</option>
                                        <option>ElevenLabs Scribe</option>
                                    </select>
                                    <div id="providerHelp" class="form-text">Select the transcription service to use</div>
                                </div>
                                <div class="mb-2">
                                    {#if provider === "Assembly AI"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(assemblyAiLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    {:else if provider === "Google Speech-to-Text"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(googleLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    {:else if provider === "Deepgram"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(deepgramLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    {:else if provider === "Rev AI"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(revAiLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    {:else if provider === "Speechmatics"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(speechmaticsLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    {:else if provider === "Voicegain"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(voiceGainLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    {:else if provider === "ElevenLabs Scribe"}
                                    <div class="mb-3">
                                        <label class="form-label" for="source language">Source Language</label>
                                        <select class="form-select" disabled={processing} bind:value={language} aria-describedby="languageHelp">
                                            {#each Object.entries(elevenLabsScribeLanguages) as languageOption}
                                            <option value={languageOption[0]}>{languageOption[1]}</option>
                                            {/each}
                                        </select>
                                        <div id="languageHelp" class="form-text">Select the source language for transcription</div>
                                    </div>
                                    <div class="alert alert-warning" role="alert">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>
                                        ElevenLabs Scribe only supports files up to 2 hours in length.
                                    </div>
                                    {/if}
                                </div>
                            </form>
                        </div>
                        <div class="col">
                            <div class="table-responsive">
                                <table class="table table-striped table-light table-sm">
                                    <thead>
                                        <tr>
                                            <th>Job Id</th>
                                            <th>File Name</th>
                                            <th>Registered</th>
                                            <th>Upload Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {#each files as file}
                                        <tr>
                                            <td>{file.jobId || " "}</td>
                                            <td>{file.name}</td>
                                            <td class='text-center'>
                                                {#if file.registered === "done"}
                                                <i class="bi bi-check-lg text-primary" />
                                                {:else if file.registered === "in progress"}
                                                <i class="bi bi-three-dots" />
                                                {:else if file.registered === "failed"}
                                                <i class="bi bi-x-lg text-danger" />
                                                {/if}
                                            </td>
                                            <td>
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" style="width: {file.progress || 0}%;">{file.progress || 0}%</div>
                                                </div>
                                            </td>
                                        </tr>
                                        {:else}
                                        <p class="text-warning">Please select media using the file input to the left.</p>
                                        {/each}
                                    </tbody>
                                </table>
                            </div>
                            {#if files && files.length > 0 && !processing}
                            <p class="text-warning">Please click the Submit button once you are ready</p>
                            {:else if processing}
                            <p class="text-warning">Please wait until all files are finished processing before closing this window.</p>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="progress" role="progressbar" 
                                aria-valuemin="0" 
                                aria-valuemax="100" 
                                aria-valuenow={progress}>
                                <div class="progress-bar progress-bar-striped progress-bar-animated" 
                                    style="width: {progress}%;">{progress}%</div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary ms-2" id="submitBatchBtn" type="button" 
                                disabled={processing || !files || files.length === 0} 
                                on:click={() => start()}
                                aria-busy={processing}>
                                Submit Job
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
.table-responsive {
    max-height: 50vh;
}
</style>
