export default (function removeSdhElements(text) {
  //Remove any text between [ and ] brackets including the brackets
  text = text.replace(/\[.*?\]/g, '');
  //Remove any text between { and } brackets including the brackets
  text = text.replace(/\{.*?\}/g, '');
  //Remove any text between ( and ) brackets including the brackets
  text = text.replace(/\(.*?\)/g, '');
  //Remove any text between [ and ) brackets including the brackets
  text = text.replace(/\[.*?\)/g, '');
  //Remove any text between < and > brackets including the brackets
  text = text.replace(/\<.*?\>/g, '');
  //Remove any text between * and * brackets including the brackets
  text = text.replace(/\*.*?\*/g, '');
  //Remove any text between # and # brackets including the brackets
  text = text.replace(/\#.*?\#/g, '');
  //Remove any text between ~ and ~ brackets including the brackets
  text = text.replace(/\~.*?\~/g, '');
  //Remove any text between | and | brackets including the brackets-
  text = text.replace(/\|.*?\|/g, '');
  //Remove any text between ^ and ^ brackets including the brackets
  text = text.replace(/\^.*?\^/g, '');
  //Remove any text between _ and _ brackets including the brackets
  text = text.replace(/\_.*?\_/g, '');
  //Remove any text between ♪ and ♪ brackets including the brackets
  // text = text.replace(/\♪.*?\♪/g, '');
  // //Remove any text between ♫ and ♫ brackets including the brackets
  // text = text.replace(/\♫.*?\♫/g, '');
  // //Remove any text between ♬ and ♬ brackets including the brackets
  // text = text.replace(/\♬.*?\♬/g, '');
  //Remove special characters including © and ℠
  text = text.replace(/[^\w\s\.\,\!\?\-]/g, '');
  return text;
});