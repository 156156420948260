<script>
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { editorState } from "@app/store/editorStore.js";
    import {modalState} from '@app/store/modalStore.js';
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    let selectedEvent =
        $eventGroupState[$projectState.selected].selected.length > 0
            ? $eventGroupState[$projectState.selected].selected[0] + 1
            : 0;
    let eventListElement = document.getElementById("EventList");

    function selectEvent() {
        if (
            selectedEvent <=
            $eventGroupState[$projectState.selected].events.length
        ) {
            eventListElement =
                eventListElement ?? document.getElementById("EventList");

            try {
                eventListElement.scrollTo(0, (selectedEvent - 1) * 230);
                $eventGroupState[$projectState.selected].selected = [
                    selectedEvent - 1,
                ];
            } catch (err) {
                console.log(err.message);
                console.log(err);
            }
        }
    }

    function updateSelectedEvent() {
        selectedEvent =
            $eventGroupState[$projectState.selected].selected[0] + 1;
    }

    function updateEditModeStatus(ev) {
        try {
            ev.preventDefault();
            ev.stopPropagation();
            let newState = !$editorState.editing;

            setTimeout(() => {
                $editorState.editing = newState;
                if ($editorState.editing && quillEditor) {
                //Check if quillEditor is initialized and focus on it
                    quillEditor.setSelection(quillEditor.getLength(), 0);
                } else if (window.quillEditor) {
                    //Check if quillEditor is initialized and blur it
                    quillEditor.blur();
                }
            }, 50);
            
        } catch (err) {
            console.log("Toggle Edit Mode Shortcut Error: ", err.message);
        }
    }

    $: if ($eventGroupState[$projectState.selected].selected.length > 0) {
        updateSelectedEvent();
    }
</script>

<svelte:head>
    <title>{$projectState.name}</title>
</svelte:head>
<p class="text-dark text-end m-0 p-1 pe-2 small">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <i
        class="bi bi-circle-fill float-start mx-2 {$editorState.editing
            ? 'text-primary'
            : 'text-secondary'}"
        title="Edit Mode Status"
        on:click={(ev) => updateEditModeStatus(ev)}
    ></i>
    <a class="text-dark" href="#!/" on:click={() => modalState.showModal("projectInfo")}>
        <i class="bi bi-info-circle" /> 
    </a>
    
    {$projectState.name}
    <i class="bi bi-film ms-2" title="Project Frame Rate" />
    {$projectState.frameRate}
    <i class="bi bi-card-list ms-2" title="Number of events" />

    <input
        id="EventNavSelectInput"
        aria-label="Select event number"
        type="number"
        step="1"
        min="1"
        max={$eventGroupState[$projectState.selected].events.length}
        bind:value={selectedEvent}
        on:change={() => selectEvent()}
        on:focus={(e) => {
            e.target.select();
        }}
    />
    /
    {$eventGroupState[$projectState.selected].events.length}

    <i class="bi bi-clock ms-2" title="Duration" />
    {$playerState.duration
        ? tcLib.secToTc(
              $playerState.duration,
              $projectState.frameRate,
              $projectState.dropFrame,
          )
        : ""}
</p>

<style>
    #EventNavSelectInput {
        background: none;
        border: none;
        width: 8.2ex;
        text-align: right;
    }
</style>
