import _hex2ascii from "hex2ascii";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const hex2ascii = _hex2ascii;
function isTimecode(str) {
  // Use a regular expression to match the timecode pattern HH:MM:SS:FF
  // H: 0-9 or 00-23, M: 0-9 or 00-59, S: 0-9 or 00-59, F: 00-29
  const timecodePattern = /^(?:[0-9]|0[0-9]|1[0-9]|2[0-3]):(?:[0-5][0-9]):(?:[0-5][0-9]):(?:[0-5][0-9])$/;
  return timecodePattern.test(str);
}
function parseHexTc(hexCodes) {
  let tc = "";
  hexCodes.forEach(code => {
    tc += hex2ascii(code);
  });
  return tc;
}
function extractTextFromRtf(rtfString) {
  // Remove font table, color table, and other unnecessary RTF tags
  let text = rtfString.replace(/\\fonttbl[\s\S]*?;}/g, '');
  text = text.replace(/\\fcharset0[\s\S]*?;}/g, '');
  text = text.replace(/\\colortbl[\s\S]*?;}/g, '');
  text = text.replace(/\\f[0-9]+\s*\\[a-z]+\d*;?/g, ''); // Remove font definitions
  text = text.replace(/\\[a-z]+\d*;?/g, ''); // Remove RTF control words
  text = text.replace(/\\'([0-9a-f]{2})/gi, (match, p1) => String.fromCharCode(parseInt(p1, 16))); // Decode escaped characters

  text = text.replace(/[\{\}\\]+/g, ''); // Remove braces and backslashes
  text = text.replace(/[^\S\r\n]+/g, ' '); // Replace multiple spaces with a single space
  text = text.replace(/[\x01\x07]/g, '');
  text = text.replace(/^\s+/gm, '');
  text = text.trim();
  return text;
}
export default {
  decode: function (input, options) {
    let events = [];
    let hexCodes = input.match(/(..?)/g);
    let header = hexCodes.splice(0, 128);
    //console.log(JSON.stringify(header));
    //console.log(JSON.stringify(hexCodes));
    while (hexCodes.length > 0) {
      let code = hexCodes.shift();
      if (code === "0b") {
        let tc = parseHexTc(hexCodes.splice(0, 11));
        if (tc != "00:00:00:00" && tc != "00:00:00;00" && isTimecode(tc)) {
          //console.log(tc);
          let ev = new Event({
            start: tcLib.tcToSec(tc, options.frameRate)
          });
          hexCodes.splice(0, 10);
          let italics = hexCodes.splice(0, 1)[0] === "03" ? true : false;
          hexCodes.splice(0, 2);
          /* Text Start */
          while (hexCodes[0] !== "00") {
            ev.text += hex2ascii(hexCodes.shift());
          }
          ev.text = extractTextFromRtf(ev.text);
          if (italics) {
            ev.text = "<em>" + ev.text + "</em>";
          }
          events.push(ev);
        }
      }
    }
    events.forEach((ev, index, evs) => {
      ev.end = evs[index + 1] ? evs[index + 1].start : ev.start + 2;
      ev.text = convertToHtml(ev.text);
    });

    //console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Closed Caption Converter does not support writing CaptionMaker Plus files. Please contact us to have CaptionMaker Plus support added.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};