<script>
    import {styleState} from '@app/store/styleStore.js';
    import {
        projectState
    } from '@app/store/projectStore.js';
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import {
        historyState
    } from '@app/store/historyStore.js';
    import getLineCount from '@app/external/cc-lib/dist/functions/quill/getLineCount.js';
    
    function moveDownOneLine() {
        let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
        let pxUnits = playerHeight/19;
        $eventGroupState[$projectState.selected].selected.forEach(evIndex =>{
            try {
                /* Get current Position */
                let currentPos;
                let numberOfTextLines = getLineCount($eventGroupState[$projectState.selected].events[evIndex].text);
                //Get the current position pased on origin (Start, center, end) and the offset. 
                if ($eventGroupState[$projectState.selected].events[evIndex].yPos === "start"){
                    //(pxUnits*2) is the padding or first two lines. plus the yOffset.
                    currentPos = (pxUnits*2) + $eventGroupState[$projectState.selected].events[evIndex].yOffset;
                } else if ($eventGroupState[$projectState.selected].events[evIndex].yPos === "end"){
                    //(pxUnits*17) Places us at the bottom of the screen, and then we subtract the yOffset to figure out how high up we are.
                    currentPos = (pxUnits*17) + $eventGroupState[$projectState.selected].events[evIndex].yOffset;
                } else {
                    if (numberOfTextLines%2 === 0){
                        currentPos = (pxUnits*9.5) + $eventGroupState[$projectState.selected].events[evIndex].yOffset;
                    } else {
                        currentPos = (pxUnits*9.5) + $eventGroupState[$projectState.selected].events[evIndex].yOffset + (pxUnits/2);
                    }                    
                }

                //round currentPos to the closest multiple of pxUnits
                currentPos = Math.round(currentPos/pxUnits) * pxUnits;
                let currentLine = Math.round(currentPos/pxUnits);
                // console.log(pxUnits, currentPos, currentLine);

                /* Add pxUnits for one line up */
                let line = currentLine + 1;
                // console.log("NEW POS: "+ line);

                if (line <= 8){
                    $eventGroupState[$projectState.selected].events[evIndex].yPos = "start";
                    $eventGroupState[$projectState.selected].events[evIndex].yOffset = parseInt(Math.max(0, (line-2) * pxUnits));
                } else if (line >= 13){
                    $eventGroupState[$projectState.selected].events[evIndex].yPos = "end";
                    $eventGroupState[$projectState.selected].events[evIndex].yOffset = parseInt(Math.min(0, (line-17) * pxUnits));
                } else {
                    console.log(parseInt((line-9.5) * pxUnits), Math.round((line-9.5) * pxUnits), (line-9.5) * pxUnits);                   
                    $eventGroupState[$projectState.selected].events[evIndex].yPos = "center";
                    if(numberOfTextLines%2 === 0){
                        $eventGroupState[$projectState.selected].events[evIndex].yOffset = Math.round((line-9.5) * pxUnits);
                    } else {
                        $eventGroupState[$projectState.selected].events[evIndex].yOffset = Math.round(((line-9.5) * pxUnits) - (pxUnits/2));
                    }                    
                }
            } catch(err){
                console.error("Position line down error");
                console.error(err.message);
                console.log(err);
            }

            historyState.insert({
                name: "position line down", //action name
                eventGroup: $projectState.selected,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });
        });
    }
    </script>
    {#if $styleState.mode}
    <li class="nav-item"
        role="none">
        <a class="nav-link"
           href="#!/"
           role="button"
           id="position-line-down-btn"
           aria-label="Move down one line"
           class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
           aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
           on:click={moveDownOneLine}>
            <i class="bi bi-chevron-down" aria-hidden="true"></i>
        </a>
    </li>
    {/if}