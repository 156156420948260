import _calcTimecodesByCps from "../calcTimecodesByCps.js";
const calcTimecodesByCps = _calcTimecodesByCps;
export default (async function calculateMissingTimecodes(eventGroup, driftAnalysis) {
  let processing = true;
  let loops = 0;
  while (processing && loops < 10000) {
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    /* -------------------STAGE 1------------------- */
    //Stage 1 looks at events with missing timecodes with events on either side with timecode.
    eventGroup.events.forEach((event, i) => {
      // Calculate start and end time for non-sdh events (events where plainText is not empty) and there is an event before and after. 
      if (!event.end && event.plainText && eventGroup.events[i - 1] && eventGroup.events[i - 1].end && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10001;
        let avgDuration = Math.max(0.5, event.plainText.length / 25, event.oDuration);
        let possibleDuration = Math.max(0, eventGroup.events[i + 1].start - eventGroup.events[i - 1].end);
        /* console.log("-------------")
        console.log(`Event ${i} has missing timecodes.`);
        console.log(eventGroup.events[i-1].plainText);
        console.log(event.plainText);
        console.log(eventGroup.events[i+1].plainText);
        console.log("Avg Duration: ", avgDuration);
        console.log("Possible Duration: ", possibleDuration); */

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (Math.abs(avgDuration - possibleDuration) < 1) {
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
        } else if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let lastEventGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
          let nextEventGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
          // console.log("Last Event Gap: ", lastEventGap);
          // console.log("Next Event Gap: ", nextEventGap);
          eventGroup.events[i].start = eventGroup.events[i - 1].end + lastEventGap;
          eventGroup.events[i].end = Math.min(eventGroup.events[i + 1].start, Math.max(eventGroup.events[i + 1].start - nextEventGap, eventGroup.events[i].start + avgDuration));
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = Math.min(eventGroup.events[i].start + avgDuration, eventGroup.events[i + 1].start);
        }
      }
      ;
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 2------------------- */
    //Stage 2 looks at SDH events with missing timecodes with events on either side with timecode.
    eventGroup.events.forEach((event, i) => {
      // Calculate start and end time for sdh events (events where plainText is empty) and there is an event before and after. 
      if (!event.end && eventGroup.events[i - 1] && eventGroup.events[i - 1].end && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10002;
        let avgDuration = Math.max(0.5, event.oDuration);
        let possibleDuration = Math.max(0, eventGroup.events[i + 1].start - eventGroup.events[i - 1].end);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(eventGroup.events[i-1].text);
        // console.log(event.text);
        // console.log(eventGroup.events[i+1].text);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (Math.abs(avgDuration - possibleDuration) < 1) {
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
        } else if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let lastEventGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
          let nextEventGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
          // console.log("Last Event Gap: ", lastEventGap);
          // console.log("Next Event Gap: ", nextEventGap);
          eventGroup.events[i].start = eventGroup.events[i - 1].end + lastEventGap;
          eventGroup.events[i].end = Math.min(eventGroup.events[i + 1].start, Math.max(eventGroup.events[i + 1].start - nextEventGap, eventGroup.events[i].start + avgDuration));
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = Math.min(eventGroup.events[i].start + avgDuration, eventGroup.events[i + 1].start);
        }
      }
      ;
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 3------------------- */
    //Stage 3 looks at non-sdh events with missing timecodes with an event after if the Event is a Stage 1 or 2 or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i + 1] && eventGroup.events[i + 1].start && eventGroup.events[i].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10003;
        let avgDuration = Math.max(0.5, event.plainText.length / 25, event.oDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let nextEventGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
          eventGroup.events[i].end = Math.max(1, eventGroup.events[i + 1].start - nextEventGap);
          eventGroup.events[i].start = Math.max(0, eventGroup.events[i].end - avgDuration);
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(0, eventGroup.events[i].end - avgDuration);
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 4------------------- */
    //Stage 4 looks at sdh events with missing timecodes with an event after that was a stage 1, 2, or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && eventGroup.events[i + 1] && eventGroup.events[i + 1].start && eventGroup.events[i].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10004;
        let avgDuration = Math.max(1, event.oDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let nextEventGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
          eventGroup.events[i].end = Math.max(0, eventGroup.events[i + 1].start - nextEventGap);
          eventGroup.events[i].start = Math.max(0, eventGroup.events[i].end - avgDuration);
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(eventGroup.events[i].end - avgDuration, 0);
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 5------------------- */
    //Stage 5 looks at non-sdh events with missing timecodes with an event before that was stage 1, 2, or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i - 1] && eventGroup.events[i - 1].end && eventGroup.events[i - 1].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10005;
        let avgDuration = Math.min(event.plainText.length / 25, event.oDuration);
        avgDuration = Math.max(0.5, avgDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let prevEventGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
          eventGroup.events[i].start = eventGroup.events[i - 1].end + prevEventGap;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 6------------------- */
    //Stage 6 looks at sdh events with missing timecodes with an event before that were a stage 1, 2, or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && eventGroup.events[i - 1] && eventGroup.events[i - 1].start && eventGroup.events[i - 1].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10006;
        let avgDuration = Math.min(1, event.oDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          let prevEventGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
          eventGroup.events[i].start = eventGroup.events[i - 1].end + prevEventGap;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 7------------------- */
    //Stage 7 looks at non-sdh events with missing timecodes with an event after.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10007;
        let avgDuration = Math.min(event.plainText.length / 25, event.oDuration);
        avgDuration = Math.max(0.5, avgDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let nextEventGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
          eventGroup.events[i].end = Math.max(1, eventGroup.events[i + 1].start - nextEventGap);
          eventGroup.events[i].start = Math.max(0, eventGroup.events[i].end - avgDuration);
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(0, eventGroup.events[i].end - avgDuration);
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 8------------------- */
    //Stage 8 looks at sdh events with missing timecodes with an event after.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && !event.plainText && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10008;
        let avgDuration = Math.min(1, event.oDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let nextEventGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
          eventGroup.events[i].end = Math.max(0, eventGroup.events[i + 1].start - nextEventGap);
          eventGroup.events[i].start = Math.max(0, eventGroup.events[i].end - avgDuration);
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(eventGroup.events[i].end - avgDuration, 0);
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 9------------------- */
    //Stage 9 looks at non-sdh events with missing timecodes with an event before.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i - 1] && eventGroup.events[i - 1].end) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10009;
        let avgDuration = Math.max(0.5, event.plainText.length / 25, event.oDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          //Use the original start, end, and duration values to calculate the start and end timecodes.
          let prevEventGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
          eventGroup.events[i].start = eventGroup.events[i - 1].end + prevEventGap;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 10------------------- */
    //Stage 6 looks at sdh events with missing timecodes with an event before.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && eventGroup.events[i - 1] && eventGroup.events[i - 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10010;
        let avgDuration = Math.max(1, event.oDuration);
        // console.log("-------------")
        // console.log(`Event ${i} has missing timecodes.`);
        // console.log(event.plainText);
        // console.log(eventGroup.events[i+1].plainText);
        // console.log("Avg Duration: ", avgDuration);
        // console.log("Possible Duration: ", possibleDuration);

        //If possibleDuration is within 1 second of the average duration, then set the start and end timecodes based on the previous and next event.
        if (event.oDuration) {
          //Caption File Mode        
          let prevEventGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
          eventGroup.events[i].start = eventGroup.events[i - 1].end + prevEventGap;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        } else {
          //Non Caption File Mode Use CPS to determin start and end timecodes.
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i].start + avgDuration;
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    let eventWithMissingEnd = eventGroup.events.find(event => !event.end);
    if (!eventWithMissingEnd) {
      processing = false;
    } else {
      eventGroup.events.forEach((event, i) => {
        if (!event.end) {
          console.log(`-----${i}-------`);
          console.log("Event with missing end timecode: ", event.text);
        }
      });
    }
  }
  return eventGroup;
});