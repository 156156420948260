import { writable } from 'svelte/store';
function loadEditorSettingsFromLocalStorage(){
    let editorSettings = JSON.parse(localStorage.getItem("cc-editor-defaults")) || {
        insertStartOnInsert : true,
        insertEndOnInsert : false,
        selectNextEvent : false,
        autoChain : false,
        autoLineBreak : false,
        autoInsertEvent : false,
        pauseWhileType : false,
        pauseWhileTypeDuration : 500,
        autoTrimEvent : true,
        snapToShotChanges : true,
        minFrameGap : 0,
        autoFormatText : false,
        drawEvents : true,
        timelineFontSize : 12,
        timelineFontColor : "#ffffff",
        timelineOverlapProtection : true,
        showMarkerComment : true
    };

    editorSettings.editing = false; //keep track of editing state
    return editorSettings;
}
export const editorState = writable(loadEditorSettingsFromLocalStorage());