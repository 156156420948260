<script>
import {
    fade
} from 'svelte/transition';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import supportedLanguages from '@app/external/cc-lib/dist/dict/supportedLanguages.js';
import insertEvent from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';
let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
let eventGroupType = eventGroupDefaults.eventGroupType || "subtitle",
    displayName = "",
    language = eventGroupDefaults.language || "en-US",
    rtl = eventGroupDefaults.rtl,
    overlap = eventGroupDefaults.overlap,
    maxCps = eventGroupDefaults.maxCps || 25,
    maxWpm = eventGroupDefaults.maxWpm || 250,
    maxChars = eventGroupDefaults.maxChars || 32,
    maxLines = eventGroupDefaults.maxLines || 2,
    minDuration = eventGroupDefaults.minDuration || 1,
    maxDuration = eventGroupDefaults.maxDuration || 12,
    illegalChars = eventGroupDefaults.illegalChars,
    linkedGroupId, //Translation Types only
    languages = supportedLanguages.languages;

function createEventGroup() {
    let eventGroupOptions = {
        type: eventGroupType,
        originalFontFamily: $styleState.fontFamily,
        originalRtl : rtl,
        name: displayName,
        language: language,
        linkedGroup: linkedGroupId,
        rtl: rtl,
        illegalChars : illegalChars,
        overlap: overlap,
        maxCps: maxCps || 25,
        maxWpm: maxWpm || 250,
        maxChars: maxChars || 32,
        maxLines: maxLines || 2,
        minDuration : minDuration || 1,
        maxDuration : maxDuration || 12
    }

    $eventGroupState = [
        ...$eventGroupState, new EventGroup(eventGroupOptions)
    ];

    $projectState.selected = $eventGroupState.length - 1;
    
    if (eventGroupType === 'translation') {
        let linkedGroupIndex = $eventGroupState.findIndex(evg =>{
            return evg.id === linkedGroupId;
        });

        if (linkedGroupIndex > -1){
            $eventGroupState[linkedGroupIndex].events.forEach(event => {
                $eventGroupState[$projectState.selected].events = [
                    ...$eventGroupState[$projectState.selected].events,
                    {
                        ...event,
                        original : event.text,
                        text : ""
                    }
                ];
            });

            $eventGroupState[$projectState.selected].originalRtl = $eventGroupState[$projectState.selected].rtl;
        }        
    } else if (eventGroupType !== 'subtitle' && eventGroupType !== 'translation') {
        $eventGroupState[$projectState.selected] = insertEvent($eventGroupState[$projectState.selected], {
            alignment: "left"
        });
    } else {
        $eventGroupState[$projectState.selected] = insertEvent($eventGroupState[$projectState.selected], {
            alignment: "center"
        });
    }

    localStorage.setItem("cc-event-group-defaults", JSON.stringify({
        eventGroupType: eventGroupType,
        name: displayName,
        language: language,
        overlap : overlap,
        illegalChars : illegalChars,
        maxCps : maxCps || 25,
        maxWpm : maxWpm || 250,
        maxChars : maxChars || 32,
        maxLines : maxLines || 2,  
        minDuration : minDuration >= 0 ? minDuration : 1,
        maxDuration : maxDuration || 12,
        rtl: rtl
    }));

    historyState.insert({
        name: "insert event group", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    modalState.hideModal();
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'newEventGroup' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="NewEventGroupModal">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">New Event Group</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{createEventGroup}" id="NewEventGroupForm">
                    <!-- Group Type -->
                    <div class="mb-3">
                        <label class="form-label" for="group type">Group Type</label>
                        <select class="form-select" bind:value={eventGroupType}>
                            <option value="subtitle">Subtitle</option>
                            {#if $authState.ad}
                            <option value="audio description">Audio Description</option>
                            {/if}
                            <option value="transcription">Transcription</option>
                            <option value="translation">Translation</option>
                        </select>
                    </div>
                    <!-- Name -->
                    <div class="mb-3"><label class="form-label" for="display name">Display Name</label>
                        <input class="form-control" required placeholder="Display Name" type="text" bind:value={displayName}>
                    </div>
                    <!-- Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Language">Language</label>
                        <select class="form-select" bind:value={language}>
                            {#each languages as languageOption, index(index)}
                                <option>{languageOption}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="form-check form-switch mb-2">
                        <input type="checkbox" class="form-check-input" id="rtlSwitch" bind:checked={rtl}>
                        <label class="form-check-label" for="rtlSwitch">Right-To-Left</label>
                    </div>
                    <div>
                        <hr/>
                        <p class="lead text-warning m-0">Error Detection</p>
                        <div class="row">
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum characters per second">Max Characters Per Second (CPS)</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxCps}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum words per minute">Max Words Per Minute (WPM)</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxWpm}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum characters per line">Max Characters Per Line</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxChars}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum lines per event">Max Lines Per Event</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxLines}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Minimum Event Duration">Minimum Event Duration (sec)</label>
                                <input type="number" step="0.01" min="0" max="9999" class="form-control" bind:value={minDuration}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum Event Duration">Maximum Event Duration (sec)</label>
                                <input type="number" step="0.01" min="0" max="9999" class="form-control" bind:value={maxDuration}>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" id="overlapCheck" bind:checked={overlap}>
                                    <label class="form-check-label" for="overlapCheck">Overlap Detection</label>
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" id="illegalCharsCheck" bind:checked={illegalChars}>
                                    <label class="form-check-label" for="illegalCharsCheck">Detect Illegal Chararacters (608)</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Linked Group -->
                    {#if eventGroupType === 'translation'}
                    <div transition:fade="{{duration: 100 }}">
                        <hr/>
                        <p class="lead text-warning m-0">Translation Options</p>
                        <div class="mb-3">
                            <label class="form-label" for="Linked group"><span class="badge bg-danger">Required</span> Linked Group <i class="bi bi-info-circle" title="The linked group is used to populate the source events for translation."></i></label>
                            <select class="form-select"  bind:value={linkedGroupId}>
                                {#each $eventGroupState as eventGroup}
                                <option value={eventGroup.id}>{eventGroup.name}</option>
                                {/each}
                            </select>
                        </div>
                    </div>
                    {/if}
                </form>
            </div>
            <div class="modal-footer"><button class="btn btn-primary" type="button" disabled={eventGroupType === 'translation' && linkedGroupId === undefined} on:click={createEventGroup}>Create Group</button></div>
        </div>
    </div>
</div>
