export default (function shiftWordBack(event1, event2) {
  // Create a DOM parser to work with HTML
  const parser = new DOMParser();

  // Parse both event texts into DOM
  const doc1 = parser.parseFromString(event1.text, 'text/html');
  const doc2 = parser.parseFromString(event2.text, 'text/html');

  // Get paragraphs from second event
  const secondEventParagraphs = doc2.getElementsByTagName('p');
  if (secondEventParagraphs.length === 0) {
    return {
      event1,
      event2
    }; // No paragraphs to move
  }

  // Get the first paragraph from second event
  const paragraphToMove = secondEventParagraphs[0];

  // Clone the paragraph and append to first event
  const clonedParagraph = paragraphToMove.cloneNode(true);
  doc1.body.appendChild(clonedParagraph);

  // Remove the paragraph from second event
  paragraphToMove.parentNode.removeChild(paragraphToMove);

  // Update both events with new HTML
  event1.text = doc1.body.innerHTML;
  event2.text = doc2.body.innerHTML;
  return {
    event1,
    event2
  };
});