<script>
	import { toast } from '@zerodevx/svelte-toast';
	import { projectState } from "@app/store/projectStore.js";
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import { historyState } from "@app/store/historyStore.js";
	import { styleState } from "@app/store/styleStore.js";
    import {speakerState} from "@app/store/speakerStore.js";
	import { tick } from "svelte";

	import EventGroupNavbar from "./EventGroupNavbar.svelte";
	import ProjectInfo from "./ProjectInfo.svelte";
	import SubtitleList from "./SubtitleList.svelte";
	import TranscriptList from "./TranscriptList.svelte";
	import TranslationList from "./TranslationList.svelte";
	import AdList from "./AdList.svelte";
	import ContextMenu from "./contextMenu/ContextMenu.svelte";

	/* CC LIB */
	import decode from "@app/external/cc-lib/dist/functions/decode.js";
	import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
	import formats from "@app/external/cc-lib/dist/lib/formats.js";
	import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
	import automaticOs from "@app/external/cc-lib/dist/functions/special/automaticOffset.js";
	import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
	import getAutomaticOffsetAmount from "@app/external/cc-lib/dist/functions/special/getAutomaticOffsetAmount.js";
	import bufferToHex from "@app/external/cc-lib/dist/functions/utility/bufferToHex.js";
	import fixOverlap from "@app/external/cc-lib/dist/functions/eventGroups/fixOverlap.js";
    import stripTags from "@app/external/cc-lib/dist/functions/quill/stripTags.js";
    import encodeHtml from "@app/external/cc-lib/dist/functions/eventGroups/encodeHtml.js";
	import checkForOverlaps from "@app/external/cc-lib/dist/functions/eventGroups/checkForOverlaps.js";
	import Swal from "sweetalert2";

	let scrollPosition = 0;
	let timer;
	export let editorHeight = 600;
    let showContextMenu = false;
	let ContextMenuOptions = {
		x: 0,
		y: 0,
	};

	async function openContextMenu(e) {
		// console.log(e,scrollPosition,e.screenX,e.screenY,e.pageX,e.pageY);
        e.preventDefault();
		let eventId;
		let path = e.composedPath();
		[5, 4, 3, 6, 7, 8].forEach((pathDepth) => {
			if (path[pathDepth] && /Event\d+/.test(path[pathDepth].id)) {
				eventId = parseInt(path[pathDepth].id.split("Event")[1]);
				if ($eventGroupState[$projectState.selected].selected.indexOf(eventId) === -1) {
					$eventGroupState[$projectState.selected].selected = [eventId];
				}
			} else {
				return;
			}
		});

        ContextMenuOptions = {
            x: e.clientX,
            y: e.clientY
        };

        showContextMenu = true;
	}

	/* Debounce function used to delay having to recheck the events shown */
	const updateScrollPosition = (e) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			try {
				//console.log(e.target.scrollTop);
				scrollPosition = e.target.scrollTop;
				/* Store setting in event group */
				if ($eventGroupState[$projectState.selected]) {
					$eventGroupState[$projectState.selected].scrollPosition = e.target.scrollTop;
				}
			} catch (err) {
				console.log(err.message);
			}
		}, 25);
	};

	function readSubtitleFile(ev) {
		ev.preventDefault();
		const items = Object.values(ev.dataTransfer.items);
		if (items.length <= 0) {
			return; // Achievement get: How did we get here?
		} else if (items.length == 1) {
			readFile(items[0])
		} else {
			items.forEach(item => {
				readFile(item);
			})
		}
	}

  function readFile(item) {
    try {
      if (item && item.kind === "file") {
        let subtitleFile = item.getAsFile();
        let fileName = subtitleFile.name.split(".");
        let srcFileName = fileName[0];
        let srcExt = fileName.pop().toLowerCase();
        let srcProfile = formats.srcProfiles[srcExt][0];

        if (!srcProfile) {
          throw new Error(
            "Unable to determine source profile. Please try importing file using the file importer (File -> Import...)"
          );
        }

        let fileEncoding = formats.defaultEncodingMap[srcProfile];
        let reader = new FileReader();
        reader.onload = async function (e) {
          if (srcExt.toLowerCase() === "docx") {
            let fileContents = await mammoth.extractRawText({
              arrayBuffer: e.target.result,
            });
            importSubtitles(fileContents.value, srcProfile, srcFileName);
          } else if (srcExt.toLowerCase() === "xlsx") {
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(e.target.result);
            let xlsxContent = JSON.stringify(workbook.model, null, 4);
            importSubtitles(xlsxContent, srcProfile, srcFileName);
          } else if (
            formats.binaryFormats.includes(
              formats.profileMapping[srcProfile].name
            )
          ) {
            importSubtitles(
              bufferToHex(e.target.result),
              srcProfile,
              srcFileName
            );
          } else {
            importSubtitles(e.target.result, srcProfile, srcFileName);
          }
        };

        formats.binaryFormats.includes(
          formats.profileMapping[srcProfile].name
        ) ||
        srcExt === "docx" ||
        srcExt === "xlsx"
          ? reader.readAsArrayBuffer(subtitleFile)
          : reader.readAsText(subtitleFile, fileEncoding);
      }
    } catch (err) {
      console.log(err, err.message);
    }
  }

	async function importSubtitles(fileContents, srcProfile, srcFileName) {
		try {
			toast.push("Importing subtitle events...", {classes: ['toast-warning']});

			let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
			let overlap = eventGroupDefaults.overlap,
				maxCps = eventGroupDefaults.maxCps || 9999,
				maxWpm = eventGroupDefaults.maxWpm || 9999,
				maxChars = eventGroupDefaults.maxChars || 9999,
				maxLines = eventGroupDefaults.maxLines || 9999;

			let eventGroupOptions = {
				type: "subtitle",
				name: srcFileName,
				language: "en-US",
				rtl: false,
				overlap: overlap,
				maxCps: maxCps || 9999,
				maxWpm: maxWpm || 9999,
				maxChars: maxChars || 9999,
				maxLines: maxLines || 9999,
			};

			$eventGroupState = [...$eventGroupState, new _EventGroup(eventGroupOptions)];
			$projectState.selected = $eventGroupState.length - 1;

			/* Translate for Creator */
			let playerWidth = document.getElementById("PlayerWrapper").clientWidth;
			let playerHeight = document.getElementById("PlayerWrapper").clientHeight;
			let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
            let yPadding = $styleState.mode ? 10 : $styleState.yPadding;
			
			console.log(`Importing subtitle file using ${formats.profileMapping[srcProfile].name} profile`);
			let options = new defaults.options({
				profile: formats.profileMapping[srcProfile].name,
				formatOptions: formats.profileMapping[srcProfile].options.decode,
				frameRate: $projectState.frameRate,
				dropFrame: $projectState.dropFrame,
				target_profile: "closedCaptionProject",
				incode: tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame),
				source_profile: formats.profileMapping[srcProfile].name,
				window: {
					width: playerWidth,
					height: playerHeight,
					xOffset: (xPadding / 100) * playerWidth,
					yOffset: (yPadding / 100) * playerHeight,
				},
			});

			let decodedEvGroup = await decode(fileContents, options);
            decodedEvGroup = encodeHtml(decodedEvGroup);
			let offsetAmount = getAutomaticOffsetAmount(decodedEvGroup, $projectState.frameRate, tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame));

			if (offsetAmount != 0) {
				try {
					if ($projectState.offset > 0){
						decodedEvGroup = automaticOs(decodedEvGroup, $projectState.frameRate);
					} else {
						let offsetRes = await Swal.fire({
							title: "Automatic Offset",
							text: `The subtitle file you are importing has an offset of ${tcLib.secToTc(offsetAmount, $projectState.frameRate, $projectState.dropFrame)}. Would you like to apply an automatic offset and update the project incode to match?`,
							icon: "question",
							showCancelButton: true,
							confirmButtonText: "Yes",
							cancelButtonText: "No",
							allowOutsideClick: false,
							allowEscapeKey: false,
							buttonsStyling: false,
							customClass: {
								confirmButton: "btn btn-primary me-2",
								cancelButton: "btn btn-light",
							},
						});

						if (offsetRes.isConfirmed) {
							$projectState.incode = offsetAmount;
							decodedEvGroup = automaticOs(decodedEvGroup, $projectState.frameRate);
						}
					}
					
				} catch (err) {
					console.log(err, err.message);
				}
			}

			if (decodedEvGroup.events.every(ev => ev.style === "Pop-On")) {
				decodedEvGroup.events = decodedEvGroup.events.filter((event) => {
					return stripTags(event.text) || event.notes || event.reply;
				});
                
				//Check for overlaps in the new event group. If overlaps are found, ask the user if they want them fixed using SweetAlert2
				if (checkForOverlaps(decodedEvGroup)){
					let overlapRes = await Swal.fire({
						title: "Overlapping Events",
						text: "The subtitle file you are importing has overlapping events. Would you like to fix them?",
						icon: "question",
						showCancelButton: true,
						confirmButtonText: "Yes",
						cancelButtonText: "No",
						allowOutsideClick: false,
						allowEscapeKey: false,
						buttonsStyling: false,
						customClass: {
							confirmButton: "btn btn-primary me-2",
							cancelButton: "btn btn-light",
						},
					});

					if (overlapRes.isConfirmed) {
						decodedEvGroup = fixOverlap(decodedEvGroup);
					}
				}
			}

            //Import Speakers from events
            decodedEvGroup.events.forEach(event =>{
                event.speakers.forEach(speaker =>{
                    //Check if the speaker already exists in $speakerState, otherwise add it.
                    if(!$speakerState.find(s => s.name === speaker.name)){
                        $speakerState = [...$speakerState, speaker];
                    }
                });
            });
            
			$eventGroupState[$projectState.selected].events = decodedEvGroup.events;
            
			historyState.insert({
				name: "import subtitles", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});
			toast.push("Subtitle import complete", {classes: ['toast-success']});
		} catch (err) {
			console.log(err, err.message);
			toast.push("Importing subtitle events failed: " + err.message, {classes: ['toast-danger']});			
		}
	}

	async function setScrollPosition() {
		//console.log("Selected Event Group Has Changed");
		try {
			let selected = $eventGroupState[$projectState.selected].selected;
			await tick();
			document.getElementById("EventList").scrollTop = $eventGroupState[$projectState.selected].scrollPosition || 0;
			/* Hack to make the quill editor refresh */
			$eventGroupState[$projectState.selected].selected = [];
			await tick();
			$eventGroupState[$projectState.selected].selected = selected;
		} catch (e) {
			scrollPosition = 0;
			console.log(e.message);
		}
	}

	$: setScrollPosition($projectState.selected);
</script>
{#if showContextMenu}
    <ContextMenu {...ContextMenuOptions} bind:showContextMenu></ContextMenu>
{/if}
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
	class="shadow bg-secondary bg-opacity-25 rounded p-0 align-self-start"
	id="EventEditor"
	on:contextmenu={openContextMenu}
	on:drop={readSubtitleFile}
	on:dragover={(ev) => {
		ev.preventDefault();
	}}>
	<EventGroupNavbar></EventGroupNavbar>
	<div id="EventList" style="height:{editorHeight}px" on:scroll={updateScrollPosition}>
		{#if $eventGroupState[$projectState.selected]}
			<ul id="eventsWrapper" style="height: {$eventGroupState[$projectState.selected].events.length * 230}px;">
				{#if $eventGroupState[$projectState.selected].type === "subtitle"}
					<SubtitleList {scrollPosition}></SubtitleList>
				{:else if $eventGroupState[$projectState.selected].type === "transcription"}
					<TranscriptList {scrollPosition}></TranscriptList>
				{:else if $eventGroupState[$projectState.selected].type === "translation"}
					<TranslationList {scrollPosition}></TranslationList>
				{:else}
					<AdList {scrollPosition}></AdList>
				{/if}
			</ul>
		{:else}
			<p class="lead text-warning text-center m-5">Please select an Event Group from the top navbar</p>
		{/if}
	</div>
	<div class="row">
		<div class="col">
			{#if $eventGroupState[$projectState.selected]}
				<ProjectInfo></ProjectInfo>
			{/if}
		</div>
	</div>
</div>

<style>
	#EventList {
		overflow-y: auto;
		overflow-x: hidden;
		will-change: transform;
	}

	#eventsWrapper {
		list-style: none;
		margin: 0;
		padding: 0;
	}
</style>
