<script>
import {
    historyState
} from '@app/store/historyStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {lockState} from '@app/store/lockStore.js';
import {styleState} from '@app/store/styleStore.js';
import orderByTime from '@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js';
export let eventGroupIndex;
export let eventIndex;
export let scaleMultiplier = 10;
let eventListElement = document.getElementById("EventList");
let lastStart, lastEnd, startDelta, endDelta;

async function selectEvent(clickEvent, index) {
    try {
        /* Scroll to event in event list */
        try {
            eventListElement.scrollTo(0, eventIndex * 230); 
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, eventIndex * 230);
        }

        /* Cue to time for video lock */
        if ($lockState.video && !isNaN($eventGroupState[eventGroupIndex].events[index].start)){
            player.currentTime = $eventGroupState[eventGroupIndex].events[index].start;
        }

        if (clickEvent.ctrlKey || clickEvent.metaKey) {
            if ($eventGroupState[eventGroupIndex].selected.indexOf(index) > -1) {
                $eventGroupState[eventGroupIndex].selected = $eventGroupState[eventGroupIndex].selected.filter(event => {
                    return event !== index;
                });
            } else {
                $eventGroupState[eventGroupIndex].selected = [...$eventGroupState[eventGroupIndex].selected, index];
            }
        } else if (clickEvent.shiftKey && $eventGroupState[eventGroupIndex].selected.length > 0) {
            let indexOfLastSelectedEvent = $eventGroupState[eventGroupIndex].selected[0]
            if (index > indexOfLastSelectedEvent) {
                while (indexOfLastSelectedEvent < index) {
                    $eventGroupState[eventGroupIndex].selected = [...$eventGroupState[eventGroupIndex].selected, indexOfLastSelectedEvent + 1];
                    indexOfLastSelectedEvent++
                }
            } else {
                while (indexOfLastSelectedEvent > index) {
                    $eventGroupState[eventGroupIndex].selected = [...$eventGroupState[eventGroupIndex].selected, indexOfLastSelectedEvent - 1];
                    indexOfLastSelectedEvent--;
                }
            }
        } else {
            $eventGroupState[eventGroupIndex].selected = [index];
        }

        $eventGroupState[eventGroupIndex].selected = $eventGroupState[eventGroupIndex].selected.filter((ev, index, selectedEv) => {
            return selectedEv.indexOf(ev) === index;
        });

        historyState.insert({
            name: "select event(s)", //action name
            eventGroup: eventGroupIndex,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (error) {
        console.log("Error selecting event in timeline. "+ error.message)
        console.error(error);
    }
}

function updateTc(e) {
    if (e.screenX != "0") {
        lastStart = $eventGroupState[eventGroupIndex].events[eventIndex].start;
        lastEnd = $eventGroupState[eventGroupIndex].events[eventIndex].end;
        $eventGroupState[eventGroupIndex].events[eventIndex].start += (e.offsetX / scaleMultiplier);
        $eventGroupState[eventGroupIndex].events[eventIndex].end += (e.offsetX / scaleMultiplier);

        startDelta = $eventGroupState[eventGroupIndex].events[eventIndex].start - lastStart;
        endDelta = $eventGroupState[eventGroupIndex].events[eventIndex].end - lastEnd;
        
        $eventGroupState[eventGroupIndex].selected.forEach(evIndex =>{
            if (eventIndex !== evIndex){
                $eventGroupState[eventGroupIndex].events[evIndex].start += startDelta;
                $eventGroupState[eventGroupIndex].events[evIndex].end += endDelta;
            }
        });
    }
}

function dragStart(e) {
    if ($eventGroupState[eventGroupIndex].selected.indexOf(eventIndex) === -1){
        $eventGroupState[eventGroupIndex].selected = [eventIndex];
    }

    let img = new Image();    
    e.dataTransfer.setDragImage(img, 0, 0);
}

function updateStart(e) {
    if (e.screenX != "0") {
        lastStart = $eventGroupState[eventGroupIndex].events[eventIndex].start;
        $eventGroupState[eventGroupIndex].events[eventIndex].start += (e.offsetX / scaleMultiplier);
        startDelta = $eventGroupState[eventGroupIndex].events[eventIndex].start - lastStart;

        $eventGroupState[eventGroupIndex].events[eventIndex].start < 0 ? $eventGroupState[eventGroupIndex].events[eventIndex].start = 0 : null;

        $eventGroupState[eventGroupIndex].selected.forEach(evIndex =>{
            if (eventIndex !== evIndex && $eventGroupState[eventGroupIndex].events[evIndex].end >= lastStart-0.03 && $eventGroupState[eventGroupIndex].events[evIndex].end <= lastStart+0.03){
                $eventGroupState[eventGroupIndex].events[evIndex].end += startDelta;
            }
        });
    }
}

function updateEnd(e) {
    if (e.screenX != "0") {
        isNaN($eventGroupState[eventGroupIndex].events[eventIndex].end) ? $eventGroupState[eventGroupIndex].events[eventIndex].end = $eventGroupState[eventGroupIndex].events[eventIndex].start : null;
        lastEnd = $eventGroupState[eventGroupIndex].events[eventIndex].end;
        $eventGroupState[eventGroupIndex].events[eventIndex].end += (e.offsetX / scaleMultiplier);

        $eventGroupState[eventGroupIndex].events[eventIndex].end < 0 ? $eventGroupState[eventGroupIndex].events[eventIndex].end = 0 : null;

        endDelta = $eventGroupState[eventGroupIndex].events[eventIndex].end - lastEnd;

        $eventGroupState[eventGroupIndex].selected.forEach(evIndex =>{
            if (eventIndex !== evIndex && $eventGroupState[eventGroupIndex].events[evIndex].start <= lastEnd+0.03 && $eventGroupState[eventGroupIndex].events[evIndex].start >= lastEnd-0.03){
                $eventGroupState[eventGroupIndex].events[evIndex].start += endDelta;
            }
        });
    }
}

function dragEnd(e) {
    try {
        //Store selected event ids so that we can sort the events by time and then reselect the events based on their Id.
        let selectedEvents = $eventGroupState[eventGroupIndex].events.filter((ev,index) => {
            return $eventGroupState[eventGroupIndex].selected.indexOf(index) > -1;
        });

        //Order events by time
        $eventGroupState[eventGroupIndex] = orderByTime($eventGroupState[eventGroupIndex]);

        //Re-select events based on their Ids
        $eventGroupState[eventGroupIndex].selected = $eventGroupState[eventGroupIndex].events.map((ev, index) => {
            return selectedEvents.map(selectedEvent => {
                return selectedEvent.id;
            }).indexOf(ev.id) > -1 ? index : null;
        }).filter(ev => {
            return ev !== null;
        });

        //console.log($eventGroupState[eventGroupIndex].selected);

        //Save to history
        historyState.insert({
            name: "update timecode", //action name
            eventGroup: eventGroupIndex,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (error) {
        console.log("Error dragging event in timeline. "+ error.message)
        console.error(error);
    }
}
</script>

{#if $eventGroupState[eventGroupIndex] && $eventGroupState[eventGroupIndex].events[eventIndex]}
<div 
    role="button"
    tabindex="0"
    on:click|stopPropagation="{clickEvent => selectEvent(clickEvent, eventIndex)}"
    on:keypress|stopPropagation="{e => e.key === 'Enter' && selectEvent(e, eventIndex)}"
    class="timelineEvent {$eventGroupState[eventGroupIndex].selected.indexOf(eventIndex) > -1 ? 'border border-warning bg-warning bg-opacity-25' : 'bg-info bg-opacity-25'}"
    style="left: {$eventGroupState[eventGroupIndex].events[eventIndex].start*scaleMultiplier}px; width: {($eventGroupState[eventGroupIndex].events[eventIndex].end - $eventGroupState[eventGroupIndex].events[eventIndex].start)*scaleMultiplier}px;"
    aria-label="Timeline event from {$eventGroupState[eventGroupIndex].events[eventIndex].start} to {$eventGroupState[eventGroupIndex].events[eventIndex].end}"
    aria-selected={$eventGroupState[eventGroupIndex].selected.indexOf(eventIndex) > -1}>

    <div 
        role="slider"
        tabindex="0"
        draggable=true 
        on:drag="{updateStart}" 
        on:dragstart="{dragStart}" 
        on:dragend="{dragEnd}"
        class="d-flex align-items-center float-start shadow eventHandle {$eventGroupState[eventGroupIndex].selected.indexOf(eventIndex) > -1 ? 'bg-warning' : 'bg-info'}"
        aria-label="Adjust start time"
        aria-valuemin="0"
        aria-valuenow={$eventGroupState[eventGroupIndex].events[eventIndex].start}>
        <i class="bi bi-caret-left-fill text-light" aria-hidden="true"/>
    </div>

    <div 
        role="slider"
        tabindex="0"
        draggable=true 
        on:drag="{updateEnd}" 
        on:dragstart="{dragStart}" 
        on:dragend="{dragEnd}"
        class="d-flex align-items-center float-end bg-info shadow eventHandle {$eventGroupState[eventGroupIndex].selected.indexOf(eventIndex) > -1 ? 'bg-warning' : 'bg-info'}"
        aria-label="Adjust end time"
        aria-valuemin="0"
        aria-valuenow={$eventGroupState[eventGroupIndex].events[eventIndex].end}>
        <i class="bi bi-caret-right-fill text-light" aria-hidden="true"/>
    </div>

    <div 
        class="eventTimelineText h-100" 
        style="direction: {$eventGroupState[eventGroupIndex].rtl ? 'rtl' : 'ltr'}; font-family: {$styleState.mode ? 'plexMono' : $styleState.fontFamily}; line-height: {$styleState.mode ? '100' : $styleState.lineSpacing}%;" 
        draggable=true 
        on:drag="{updateTc}" 
        on:dragstart="{dragStart}" 
        on:dragend="{dragEnd}"
        role="textbox"
        aria-readonly="true">
        {#if $eventGroupState[eventGroupIndex].events[eventIndex]}
            {@html $eventGroupState[eventGroupIndex].events[eventIndex].text}
        {/if}
    </div>
</div>
{/if}

<style>
.timelineEvent {
    position: absolute;
    height: 100%;
    font-size: 0.8vw;
    overflow : hidden;
}

.eventTimelineText {
    padding: 5px;
    overflow: hidden;
    cursor: move;
    font-size: 0.7rem;
}

.eventHandle {
    height: 100%;
    padding: 0;
    font-size: 8px;
    cursor: col-resize;
}
</style>
