<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    fade
} from 'svelte/transition';

let xOffset = 0;
let yOffset = 0;
let horzUpdate = true;
let vertUpdate = true;
let event = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]];
let aspectRatio = $projectState.media.aspectRatio.replace(":", "/");

function updateOffset() {
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        if (horzUpdate) {
            $eventGroupState[$projectState.selected].events[eventIndex].xOffset = xOffset;
        }

        if (vertUpdate) {
            $eventGroupState[$projectState.selected].events[eventIndex].yOffset = yOffset;
        }
    });
    historyState.insert({
        name: "update position", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    modalState.hideModal();
}
</script>

<div 
    transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'position' ? 'show d-block' : ''}" 
    role="dialog" 
    tabindex="-1" 
    id="PositionModal"
    aria-labelledby="positionModalTitle"
    aria-describedby="positionModalDescription"
>
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="positionModalTitle">Offset Position</h4>
                <button 
                    type="button" 
                    class="btn-close" 
                    aria-label="Close position modal" 
                    id="closePosition"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p class="text-muted" id="positionModalDescription">Update the vertical or horizontal offset of all <strong>selected</strong> events.</p>
                <div class="row">
                    <div class="col-6">
                        <form>
                            <div class="row">
                                <div class="col">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="applyHorzOnly" bind:checked={horzUpdate}/> <label class="form-check-label"
                                            for="applyHorzOnly">Update Horizontal</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-check mb-3">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="applyVertOnly" bind:checked={vertUpdate}/> <label class="form-check-label"
                                            for="applyVertOnly">Update Vertical</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    {#if horzUpdate}
                                    <div class="mb-3">
                                        <label class="form-label" for="Horizontal Offset Position Input">Horizontal Offset</label>
                                        <input class="form-control" type="number" step="1" bind:value="{xOffset}"/>
                                    </div>
                                    {/if}
                                </div>
                                <div class="col">
                                    {#if vertUpdate}
                                    <div class="mb-3">
                                        <label class="form-label" for="Vertical Offset Position Input">Vertical Offset</label>
                                        <input class="form-control" type="number" step="1" bind:value="{yOffset}"/>
                                    </div>
                                    {/if}
                                </div>
                            </div>
                            <button
                                class="btn btn-primary float-end"
                                type="button" 
                                id="updateOffsetBtn"
                                on:click="{updateOffset}" 
                                disabled="{!horzUpdate && !vertUpdate}"
                                aria-disabled="{!horzUpdate && !vertUpdate}"
                            >
                                Update Offset
                            </button>
                        </form>
                    </div>
                    <div class="col">
                        <span class="text-muted small" id="previewLabel">Preview:</span>
                        <div 
                            id="PositionPreviewStage" 
                            class="bg-secondary" 
                            style="aspect-ratio: {aspectRatio}"
                            aria-labelledby="previewLabel"
                            role="img"
                        >
                            <div id="PositionPreviewWrapper" class="d-flex justify-content-{event.xPos} align-items-{event.yPos}" style="padding: {$styleState.yPadding}% {$styleState.xPadding}%;">
                                <div id="PositionPreview" style="top:{yOffset}px; left:{xOffset}px; font-family: {$styleState.fontFamily}; line-height: {$styleState.lineSpacing}%; font-size: {$styleState.fontSize}px; padding: {$styleState.padding}px; letter-spacing: {$styleState.spacing}px; line-height: {$styleState.height}px; color: {$styleState.color+Math.ceil(($styleState.opacity/100)*255).toString(16)}; background: {$styleState.background+ Math.ceil(($styleState.bgOpacity/100)*255).toString(16)};" class="paragraphWrapper">
                                    {@html event.text}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
#PositionPreviewStage {
    position: relative;
    width: 100%;
}

#PositionPreviewWrapper {
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 2%;
}

#PositionPreview {
    position: relative;
    display: inline-block;
    user-select: none;
}
</style>
