<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import expandText from "@app/external/cc-lib/dist/functions/quill/expandText.js";

    /* Expand makes the event wider which requires us to add a word to the longest line from a shorter line*/
    const expandEvent = function () {
        if (!$eventGroupState[$projectState.selected]) {
            return;
        }

        if (
            !$eventGroupState[$projectState.selected].selected ||
            $eventGroupState[$projectState.selected].selected.length === 0
        ) {
            return;
        }

        $eventGroupState[$projectState.selected].selected.forEach(
            (eventIndex, selectedEventIndex) => {
                if (
                    !$eventGroupState[$projectState.selected].events[eventIndex]
                        .text
                ) {
                    return;
                }

                try {
                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].text = expandText(
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text, $eventGroupState[$projectState.selected].maxChars
                    );

                    if (selectedEventIndex === 0 && quillEditor) {
                        if (
                            quillEditor.getSemanticHTML() !== $eventGroupState[$projectState.selected].events[eventIndex].text
                        ) {
                            quillEditor.clipboard.dangerouslyPasteHTML(
                                $eventGroupState[$projectState.selected].events[eventIndex].text
                            );
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        );

        historyState.insert({
            name: "expand event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
    };
</script>

<li class="nav-item" title="Expand Event Text">
    <a
        class="nav-link"
        href="#!/"
        id="expand-event-btn"
        role="button"
        aria-label="Expand event text"
        aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        on:click={expandEvent}
    ><i class="bi bi-distribute-vertical"></i></a>
</li>
