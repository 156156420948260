<script>
    /* States */
    import { modalState } from "@app/store/modalStore.js";
    import { explorerState } from "@app/store/explorerStore.js";
    import { authState } from "@app/store/authStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import "firebase/functions";
    /* Components */
    import NavSidebar from "./explorer/NavSidebar.svelte";
    import Workspace from "./explorer/Workspace.svelte";
    import InfoSidebar from "./explorer/InfoSidebar.svelte";
    export let recent = false;

    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    let loading = false;

    if (recent) {
        recent = false;
        $explorerState.rootDir = "recent";
        getRecentRecords();
    } else {
        $explorerState.rootDir = "personal";
        getRecords();
    }

    /* Get records where folder Id is the records locationId */
    async function getRecords(folderId = null){
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];

            // console.log("Getting Records for Folder ID: " + folderId);
            let querySnapshot = await ($explorerState.rootDir === "personal" ? homeDbRef : teamDbRef).where("locationId", "==", folderId).orderBy("type").orderBy("name", "asc").get();

            querySnapshot.forEach((doc) => {
                $explorerState.records = [...$explorerState.records, {...(doc.data()), rootDir : $explorerState.rootDir}];
            });

            // console.log("----RECORDS----");
            // console.log($explorerState.records);
        } catch(err){
            console.error(err);
            toast.push(error.message, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }   
    }

    async function getRecentRecords(){
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];
            $explorerState.rootDir = "recent";

            // console.log("Getting Recent Records");
            let recentRecords = [];
            
            // Personal storage - fetch all record types including folders
            let personalSnapshot = await homeDbRef
                .orderBy("updatedOn", "desc")
                .limit(100)
                .get();
                
            personalSnapshot.forEach((doc) => {
                recentRecords.push({...(doc.data()), rootDir: "personal"});
            });
            
            // Team storage (if available) - fetch all record types including folders
            if (teamDbRef) {
                let teamSnapshot = await teamDbRef
                    .orderBy("updatedOn", "desc")
                    .limit(100)
                    .get();
                    
                teamSnapshot.forEach((doc) => {
                    recentRecords.push({...(doc.data()), rootDir: "team"});
                });
            }
            
            // console.log("Before sorting:", recentRecords.map(r => ({ name: r.name, type: r.type })));
            
            // First, separate folders from other records
            const folders = recentRecords.filter(record => record.type === "folder");
            const nonFolders = recentRecords.filter(record => record.type !== "folder");
            
            // Sort each group by updatedOn timestamp
            folders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            nonFolders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            // Combine: folders first, then other records
            $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
            
            // console.log("After sorting - folders:", folders.length, "non-folders:", nonFolders.length);
            // console.log("----RECENT RECORDS (WITH FOLDERS FIRST)----");
            // console.log($explorerState.records);

        } catch(err){
            console.error(err);
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }   
    }

    async function getStarredRecords(){
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];
            $explorerState.rootDir = "starred";

            // console.log("Getting Starred Records");
            let starredRecords = [];
            
            // Personal storage
            let personalSnapshot = await homeDbRef
                .where("star", "==", true)
                .limit(100)
                .get();
                
            personalSnapshot.forEach((doc) => {
                starredRecords.push({...(doc.data()), rootDir: "personal"});
            });
            
            // Team storage (if available)
            if (teamDbRef) {
                let teamSnapshot = await teamDbRef
                    .where("star", "==", true)
                    .limit(100)
                    .get();
                    
                teamSnapshot.forEach((doc) => {
                    starredRecords.push({...(doc.data()), rootDir: "team"});
                });
            }
            
            // console.log("Before sorting:", starredRecords.map(r => ({ name: r.name, type: r.type })));
            
            // First, separate folders from other records
            const folders = starredRecords.filter(record => record.type === "folder");
            const nonFolders = starredRecords.filter(record => record.type !== "folder");
            
            // Sort each group by updatedOn timestamp
            folders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            nonFolders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            // Combine: folders first, then other records
            $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
            
            // console.log("After sorting - folders:", folders.length, "non-folders:", nonFolders.length);
            // console.log("----STARRED RECORDS (WITH FOLDERS FIRST)----");
            // console.log($explorerState.records);
        } catch(err){
            console.error(err);
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
        }
    }
</script>
<div class="modal {$modalState === 'storageExplorer' || $modalState === 'recentProjects' ? 'show d-block' : ''}" role="dialog" tabindex="-1" data-bs-backdrop="static" id="modal-1">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable modal-fullscreen" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-capitalize">{$explorerState.context} Project</h4>
                <button 
                    type="button"
                    class="btn-close"
                    aria-label="Close dialog"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <NavSidebar />
                        <Workspace />
                        <InfoSidebar />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>