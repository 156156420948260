import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _flexbox from "../dict/flexbox.js";
import _quillClasses from "../dict/quillClasses.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const quillClasses = _quillClasses;
const flexbox = _flexbox;
const convertToPlainText = _convertToPlainText;
let alignmentMapping = {
  "L": "left",
  "R": "right",
  "C": "center"
};
function calculatePosition(text, alignment, v, h, win) {
  let position = {
    xPos: "center",
    yPos: "end",
    xOffset: 0,
    yOffset: win.height * -0.10
  };
  let highestEnd = text.length + v;
  if (h > 10) {
    position.yPos = "end";
    position.yOffset = (18 - (h + 2)) / -19 * win.height;
  } else if (h < 6) {
    position.yPos = "start";
    position.yOffset = (h + 2) / 19 * win.height;
  } else {
    position.yPos = "center";
    position.yOffset = (h + 2 - findCenter(19, 1)) / 19 * win.height;
  }
  if (alignment === "left") {
    if (v > 13) {
      position.xPos = "end";
      position.xOffset = (40 - (highestEnd + 5)) / 40 * win.width;
    } else {
      position.xPos = "start";
      position.xOffset = (v + 4) / 40 * win.width;
    }
  } else if (alignment === "center") {
    position.xPos = "center";
    position.xOffset = 0;
  } else {
    if (v > 13) {
      position.xPos = "end";
      position.xOffset = (40 - (highestEnd + 5)) / 40 * win.width;
    } else {
      position.xPos = "start";
      position.xOffset = (v + 5) / 40 * win.width;
    }
  }
  return position;
}
export default {
  decode: function (input, options) {
    let events = [];
    let subtitles = JSON.parse(input);
    subtitles.forEach(subtitle => {
      let ccEvent = new Event({
        alignment: alignmentMapping[subtitle.justification] || "center",
        start: tcLib.tcMsToSec(subtitle.start),
        end: tcLib.tcMsToSec(subtitle.end)
      });
      let plainText = convertToPlainText(subtitle.text);
      ccEvent.text = convertToHtml(subtitle.text, [quillClasses.align[ccEvent.alignment]]);
      let position = calculatePosition(plainText, ccEvent.alignment, parseInt(subtitle.vertical), parseInt(subtitle.horizontal), options.window);
      ccEvent.xPos = position.xPos;
      ccEvent.yPos = position.yPos;
      ccEvent.xOffset = position.xOffset;
      ccEvent.yOffset = position.yOffset;
      events.push(ccEvent);
      // console.log(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("JSON Custom 01 files are not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};