import _bidiFactory from "bidi-js";
const bidiFactory = _bidiFactory;
export default (function fixRtlText(text) {
  const bidi = bidiFactory();
  let textLines = text.split("\n");
  textLines.forEach((textLine, index, lines) => {
    let embeddingLevels = bidi.getEmbeddingLevels(textLine,
    //the input string containing mixed-direction text
    "rtl");
    let flips = bidi.getReorderSegments(textLine,
    //the full input string
    embeddingLevels //the full result object from getEmbeddingLevels
    );

    // Process all reversal sequences, in order:
    flips.forEach(range => {
      const [start, end] = range;
      textLine = reversePartsInPlace(textLine, start, end);
    });
    lines[index] = textLine;
  });
  return textLines.join("\n");
});
function reversePartsInPlace(str, start, end) {
  if (start < 0 || end >= str.length || start >= end) {
    throw new Error("Invalid start or end indices");
  }
  let charArray = str.split(""); // Convert the string to an array of characters

  while (start < end) {
    // Swap characters at start and end indices
    const temp = charArray[start];
    charArray[start] = charArray[end];
    charArray[end] = temp;
    start++;
    end--;
  }
  return charArray.join(""); // Convert the array back to a string
}