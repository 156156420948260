<script>
import {
    fontState
} from '@app/store/fontStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    v4 as uuidv4
} from 'uuid';
let fontName = "";

function addFont() {
    let newStyleTag = document.createElement('style');
    newStyleTag.appendChild(document.createTextNode(`
        @font-face {
            font-family: ${fontName};
            src: local('${fontName}');
        }`
    ));

    document.head.appendChild(newStyleTag);
    $fontState = [...$fontState, {
        id: uuidv4(),
        name: fontName
    }];

    fontName = "";
    localStorage.setItem("cc-font-defaults", JSON.stringify($fontState));

    historyState.insert({
        name: "insert font", //action name
        eventGroup: false,
        snapshots: [{
            store: "fontState",
            value: JSON.stringify($fontState)
        }]
    });
}

function removeFont(id) {
    $fontState = $fontState.filter(font => {
        return id !== font.id;
    });

    localStorage.setItem("cc-font-defaults", JSON.stringify($fontState));
    historyState.insert({
        name: "remove font", //action name
        eventGroup: false,
        snapshots: [{
            store: "fontState",
            value: JSON.stringify($fontState)
        }]
    });
}
</script>

<div class="table-responsive">
    <table class="table table-striped table-light table-sm">
        <thead class="table-light">
            <tr>
                <th scope="col"></th>
                <th scope="col">Font Name</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class='text-center'>
                    <button class="btn btn-sm btn-primary" disabled='{!fontName}' type="button" title="Add Font" on:click="{addFont}">Insert Font</button>
                </td>
                <td><input type="text" class="form-control border-0 rounded-0 bg-secondary text-dark form-control-sm" placeholder="Font Name" bind:value={fontName}></td>
                <td></td>

            </tr>
            {#each $fontState as font (font.id)}
            <tr>
                <td class='text-center'><button class="btn btn-link text-danger" type="button" title="Remove Font" on:click="{removeFont(font.id)}"><i class="bi bi-x-lg"></i></button></td>
                <td>{font.name}</td>
                <td style="font-family: {font.name};">The quick brown fox jumps over the lazy dog</td>
            </tr>
            {/each}
        </tbody>
    </table>
</div>
