<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    timelineZoomState
} from '@app/store/timelineZoomStore.js';
import TimelineEvent from './TimelineEvent.svelte';
import CompareEvent from './CompareEvent.svelte';
export let scrollPosition = 0;
export let scaleMultiplier = 5;
export let width = 1920;
export let eventGroupIndex = 0;
export let height = 40;
export let compare = false;

let events = $eventGroupState[eventGroupIndex].events.filter(event => {
    return (event.start >= ((scrollPosition / scaleMultiplier) - ($timelineZoomState / 2)) && event.start <= ((scrollPosition + width) / scaleMultiplier) + ($timelineZoomState / 2)) || (event.end >= ((scrollPosition / scaleMultiplier) - ($timelineZoomState / 2)) && event.end <= ((scrollPosition + width) / scaleMultiplier) + ($timelineZoomState / 2));
}).splice(0,250); //Splice only 250 events to show at once

let nextEventUpdatePos = scrollPosition + (width*0.25);
let prevEventUpdatePos = scrollPosition - (width*0.25);

function refreshEvents() {
    if (!$eventGroupState[eventGroupIndex]){        
        return; 
    }
    
    events = $eventGroupState[eventGroupIndex].events.filter(event => {
        return (event.start >= ((scrollPosition / scaleMultiplier) - ($timelineZoomState / 2)) && event.start <= ((scrollPosition + width) / scaleMultiplier) + ($timelineZoomState / 2)) || (event.end >= ((scrollPosition / scaleMultiplier) - ($timelineZoomState / 2)) && event.end <= ((scrollPosition + width) / scaleMultiplier) + ($timelineZoomState / 2));
    }).splice(0,250); //Splice only 250 events to show at once

    nextEventUpdatePos = scrollPosition + (width*0.25);
    prevEventUpdatePos = scrollPosition - (width*0.25);
}

$: if (scrollPosition >= nextEventUpdatePos || scrollPosition <= prevEventUpdatePos) {
    refreshEvents();
}

$: if ($eventGroupState[eventGroupIndex] && $eventGroupState[eventGroupIndex].events) {
    refreshEvents();
}

$: if (eventGroupIndex >= 0 || scaleMultiplier) {
    refreshEvents();
}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
{#if $eventGroupState[eventGroupIndex]}
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="border-bottom border-secondary m-0 p-0 TimelineTrack position-relative col-12" style="min-width: {$playerState.duration*scaleMultiplier}px; height: {height}px;" on:click="{(e) => {window.player.currentTime = e.offsetX/scaleMultiplier; playerState.updateTime(e.offsetX/scaleMultiplier)}}">
    {#each events as event (event.id)}
        {#if compare}
        <CompareEvent eventGroupIndex={eventGroupIndex} eventIndex={$eventGroupState[eventGroupIndex].events.findIndex(eventOpt => {return eventOpt.id === event.id})} scaleMultiplier={scaleMultiplier}></CompareEvent>
        {:else}
        <TimelineEvent eventGroupIndex={eventGroupIndex} eventIndex={$eventGroupState[eventGroupIndex].events.findIndex(eventOpt => {return eventOpt.id === event.id})} scaleMultiplier={scaleMultiplier}></TimelineEvent>
        {/if}
    {/each}
    <!-- {#if controls}
    <ZoomControls></ZoomControls>
    {/if} -->
</div>
{/if}