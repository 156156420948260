<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
export let eventIndex;
export let property = "rate";
export let label = "Rate";
export let minValue = 0.5;
export let maxValue = 1.5;
export let stepValue = 0.01;
export let unit = "x";

function resetSlider(){
    $eventGroupState[$projectState.selected].events[eventIndex][property] = unit === "x" ? 1.00 : 50;
}
</script>

<div class="mb-2">
    <input class="w-100" on:dblclick="{resetSlider}" type="range" bind:value={$eventGroupState[$projectState.selected].events[eventIndex][property]} min="{minValue}" max="{maxValue}" step="{stepValue}" on:change="{() => $eventGroupState[$projectState.selected].events[eventIndex].audioFile = ''}">
    <p class="text-dark small slider-label">{label} {$eventGroupState[$projectState.selected].events[eventIndex][property]}{unit}</p>
</div>

<style>
.small {
    font-size: 11px;
}

.slider-label {
    margin: -5px 0;
}
</style>
