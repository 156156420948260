import _addIncode from "./special/addIncode.js";
import _applyWindowOffsets from "./special/applyWindowOffsets.js";
import _defaults from "../lib/defaults.js";
import _encoder from "../profiles/index.js";
const encoder = _encoder;
const defaults = _defaults;
const applyWindowOffsets = _applyWindowOffsets;
const addIncode = _addIncode;
export default (async function (eventGroups, options = new defaults.options()) {
  if (encoder[options.profile]) {
    //console.log(options);
    let output;
    //Check if EventGroups is an array or single object

    // console.log(eventGroups.length);
    if (!Array.isArray(eventGroups)) {
      eventGroups = [eventGroups];
    }
    eventGroups.forEach(eventGroup => {
      eventGroup = addIncode(eventGroup, options);
      eventGroup = applyWindowOffsets(eventGroup, options.window);
      eventGroup = encoder[options.profile].preProcess.encode(eventGroup, options);
    });
    if (options.profile === "macCaptionAdvanced" || options.profile === "sceneristAdvanced" || options.profile === "vancData") {
      output = await encoder[options.profile].encode(eventGroups, options);
    } else {
      output = await encoder[options.profile].encode(eventGroups[0], options);
    }
    return encoder[options.profile].postProcess.encode(output, options);
  } else {
    throw new Error(`Target profile [${options.profile}] does not exist. Please ensure name matches a supported encoding profile. Profile names are case-sensitive.`);
  }
});