import { writable } from 'svelte/store';
export const styleState = writable({
    mode : true,
    adrPreview : false,
    grid : false,
    name : "Default",
    borderStyle : "3",
    fontFamily : "monospace",
    fontSize : 16,
    spacing: 0,
    color : "#ffffff",
    opacity : 100,
    shadow: 0,
    shadowColor : "#000000",
    shadowOpacity : 100,
    outline : 5,    
    outlineColor : "#000000",
    outlineOpacity : 100,
    windowXOffset : 25, //px
    windowYOffset : 25, //px
    xPadding : 18, //%
    yPadding : 10, //%
    lineSpacing : 100 //%
});