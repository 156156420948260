<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    fade
} from 'svelte/transition';
import {
    saveAs
} from 'file-saver';
import Swal from 'sweetalert2'
import throttle from 'just-throttle';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

/* CC LIB */
import encode from "@app/external/cc-lib/dist/functions/encode.js";
import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
import encodings from "@app/external/cc-lib/dist/dict/encodings.js";
import formats from "@app/external/cc-lib/dist/lib/formats.js";
import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import _Event from "@app/external/cc-lib/dist/classes/event.js";
import offset from "@app/external/cc-lib/dist/functions/events/tcOffset.js";
import tcMultiply from "@app/external/cc-lib/dist/functions/special/tcMultiply.js";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";
import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
import wrapStyledTextWithSpan from '@app/external/cc-lib/dist/functions/quill/wrapStyledTextWithSpan.js'; 

let eventGroup = getSelectedEventGroup(["subtitle", "translation", "audio description", "transcription"]),
    trgExt = "csv",
    trgProfile = "As-Broadcast Dialogue List CSV",
    trgFrameRate = $projectState.frameRate,
    trgDropFrame = $projectState.dropFrame ? "true" : "false",
    dropFrameConvert = false,
    eventListElement = document.getElementById("EventList"),
    tcOffset = tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame) || "00:00:00:00",
    tcOffsetType = "add",
    tcMultiplier = 1,
    showOptions = false,
    fileEncoding = defaults.encoding,
    fns = 'include',
    ignoreAll = false;

if ($authState.status === "in_trial"){
    showTrialWarning();
}

function showTrialWarning(){
    Swal.fire({
        titleText: "Trial Mode",
        text : "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Activate Account',
        denyButtonText: 'Continue Trial',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            denyButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            activateSubscription();
            return true;
        } else {
            return false;
        }
    }).then((res) => {
        if (res){
            console.log(res);
            showRestartNotification();
        }
        
        return true;
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function showRestartNotification(){
    Swal.fire({
        titleText: "Restart Required",
        text : "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
        confirmButtonText: 'Ok',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-light'
        }
    }).then(res =>{
        console.log(res);
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function getSelectedEventGroup(validTypes) {
    let evGroup = $eventGroupState[$projectState.selected];
    if (evGroup){
        if (validTypes.indexOf(evGroup.type) > -1) {
            return evGroup.id;
        } else {
            return false;
        }
    } else {
        return false;
    }    
}

function validateTc(e) {
    tcOffset = tcLib.tcValidate(e.target.value, parseFloat(trgFrameRate), trgDropFrame === "true" ? true : false);
}

function updateProfile() {
    if (trgExt === "csv"){
        trgProfile = "As-Broadcast Dialogue List CSV"
    } else {
        trgProfile = "As-Broadcast Dialogue List Word"
    }

    trgFrameRate = $projectState.frameRate;
    trgDropFrame = $projectState.dropFrame ? "true" : "false";
}

async function cancelExport(eventGroupId, eventId) {
    toast.push("Export Aborted", {
        classes: ["toast-warning"]
    });

    $projectState.selected = eventGroupId;
    $eventGroupState[$projectState.selected].selected = [eventId];
    setTimeout(() =>{
        try {
            eventListElement.scrollTo(0, eventId * 230);
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, eventId * 230);
        }
    }, 250);    
    
    modalState.hideModal();
}

function alertUser(msg) {
    let response = Swal.fire({
        titleText: "Export Pre-Checks",
        text : msg,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Abort',
        denyButtonText: 'Ignore',
        cancelButtonText: 'Ignore All',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-danger',
            denyButton: 'btn btn-light mx-2',
            cancelButton : 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
            return false;
        } else if (result.isDismissed){
            ignoreAll = true;
            return false;
        }
    });

    return response;
}

function alertUserBoolean(msg) {
    let response = Swal.fire({
        titleText: "Export Pre-Checks",
        text : msg,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-danger',
            denyButton: 'btn btn-light mx-2',
            cancelButton : 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else {
            return false;
        }
    });

    return response;
}

async function preExportCheck(events) {    
    for (let i = 0; i < events.length; i++) {
        if (!ignoreAll) {
            if (events[i].text) {
                if (isNaN(events[i].start)) {
                    if (await alertUser(`Event ${i+1} is missing a start time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (isNaN(events[i].end)) {
                    if (await alertUser(`Event ${i+1} is missing an end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start === events[i].end) {
                    if (await alertUser(`Event ${i+1} has the same start and end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start > events[i].end) {
                    if (await alertUser(`Event ${i+1} has a start time greater than its end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (i > 0 && events[i].start < events[i - 1].end) {
                    /* Adding check in case overlap is less than a frame */
                    if (events[i - 1].end-events[i].start < 1/$projectState.frameRate){
                        events[i].start = events[i - 1].end;
                    } else if (await alertUser(`Event ${i+1} overlaps with the previous event.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                }
            } else {
                if (await alertUser(`Event ${i+1} has no text.\n Would you like to ABORT your export?`)) {
                    return {
                        err: true,
                        eventId: i
                    }
                };
            }
        }
        
    }

    return {
        err: false
    }
}

async function exportDialogueList() {
    toast.push("Dialogue List Export", {
        classes: ["toast-info"]
    });

    try {
        let options,
            output,
            defaultEncoding = formats.defaultEncodingMap[formats.profileMapping[trgProfile].name],
            eventGroupIndex,
            fileName,
            fileBlob,
            copyOfEventGroup;

            trgFrameRate = parseFloat(trgFrameRate);
            trgDropFrame = trgDropFrame === "true" ? true : false;

        $eventGroupState = $eventGroupState;
        eventGroupIndex = $eventGroupState.findIndex(group => {
            return group.id === eventGroup;
        });



        fileName = $eventGroupState[eventGroupIndex].name + "." + trgExt;
        copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));

        /* FS Filtering START */
        if (fns === "exclude"){
            copyOfEventGroup.events = copyOfEventGroup.events.filter(event =>{
                return !event.forced
            });
        } else if (fns === "only"){
            copyOfEventGroup.events = copyOfEventGroup.events.filter(event =>{
                return event.forced
            });
        }
        /* FS Filtering END */

        copyOfEventGroup = orderByTime(copyOfEventGroup);
        copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);
        let preCheckResult = await preExportCheck(copyOfEventGroup.events);
        if (preCheckResult.err) {
            cancelExport(eventGroupIndex, preCheckResult.eventId)
        } else {
            /* tcOffset */
            if (tcOffset && tcOffset !== "00:00:00:00") {
                copyOfEventGroup.events.forEach((event, index, events) => {
                    events[index] = offset(event, tcLib.tcToSec(tcOffset, trgFrameRate, trgDropFrame), tcOffsetType);
                });
            }

            /* Frame Rate Convert */
            if (dropFrameConvert) {
                copyOfEventGroup = dropFrameConvert(eventGroup, {
                    frameRate: trgFrameRate,
                    dropFrame: trgDropFrame
                });
            }

            if (tcMultiplier && tcMultiplier != 0 && tcMultiplier != 1) {
                copyOfEventGroup = tcMultiply(copyOfEventGroup, tcMultiplier);
            }

            /* Translate for Creator */
            let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
            let playerHeight = document.getElementById('PlayerWrapper').clientHeight;

            options = new defaults.options({
                profile: formats.profileMapping[trgProfile].name,
                formatOptions: formats.profileMapping[trgProfile].options.encode,
                frameRate: trgFrameRate,
                dropFrame: trgDropFrame,
                target_profile : formats.profileMapping[trgProfile].name,
                source_profile : 'closedCaptionProject',
                window: {
                    width: playerWidth,
                    height: playerHeight,
                    xOffset: ($styleState.xPadding/100) * playerWidth,
                    yOffset: ($styleState.yPadding/100) * playerHeight
                }
            });

            if ($authState.status === "in_trial"){
                copyOfEventGroup = replaceTrialText(copyOfEventGroup);
            }

            copyOfEventGroup.events.forEach((event, index, events) =>{
                events[index].text = wrapStyledTextWithSpan(event.text);
            });

            output = await encode(copyOfEventGroup, options);

            if (defaultEncoding === "buffer"){
                fileBlob = output;
            } else if (defaultEncoding.toLowerCase() === "hex") {
                let byteArray = new Uint8Array(output.match(/.{2}/g).map(e => parseInt(e, 16)));
                fileBlob = new Blob([byteArray], {
                    type: "application/octet-stream"
                });
            } else if (defaultEncoding.toLowerCase() === "utf-16le") {
                fileBlob = new Blob([output], {
                    type: "text/plain;charset=utf-16le"
                });
            } else {
                fileBlob = new Blob([output], {
                    type: "text/plain;charset=" + fileEncoding
                });
            }

            saveAs(fileBlob, fileName, {
                autoBom: true
            });

            toast.push("Export Complete", {
                classes: ["toast-success"]
            });

            localStorage.setItem("cc-subtitle-export-defaults", JSON.stringify({
                trgExt : trgExt,
                trgProfile : trgProfile,
                tcOffset : tcOffset,
                tcOffsetType : tcOffsetType
            }));

            modalState.hideModal();
        }
    } catch (e) {
        console.log(e, e.message);
        toast.push("Dialogue List Export failed: " + e.message, {
            classes: ["toast-danger"]
        });
    }
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, { leading: true });
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'dialogueListExport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="dialogueListExportModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Dialogue List Export</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p class="small text-muted"><i class="bi bi-lightning-fill"></i> Powered by Closed Caption Converter | www.closedcaptionconverter.com</p>
                <form on:submit|preventDefault="{exportDialogueList}">
                    <div class="mb-3">
                        <label class="form-label" for="EventGroupSelection">Event Group</label>
                        <select class="form-select" bind:value="{eventGroup}">
                            {#each $eventGroupState as eventGroupOption}
                                <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="row">
                        <div class="mb-3 col">
                            <label class="form-label" for="targetFileExtension">Format</label>
                            <select class="form-select" bind:value="{trgExt}" on:change="{updateProfile}">
                                <option>csv</option>
                                <option>docx</option>
                            </select>
                        </div>
                    </div>
                    <button type="button" class="btn btn-outline-warning mb-2 dropdown-toggle" on:click="{()=> {showOptions = !showOptions}}">More Options</button>
                    {#if showOptions}
                    <div id="advancedOptions" class="p-3 bg-secondary shadow rounded">
                        <!-- Advanced Options -->
                        <p class="lead text-warning">General Options</p>
                        <!-- Frame Rate + Drop Frame -->
                        {#if formats.profileMapping[trgProfile].timecode !== 'ms'}
                        <div class="row">
                            <div class="mb-3 col-8">
                                <label class="form-label" for="TrgFrameRateSelect">Framerate</label>
                                <select id="TrgFrameRateSelect" class="form-select" bind:value="{trgFrameRate}">
                                    {#each frameRates.frameRates as frameRate}
                                    <option selected={frameRate == trgFrameRate}>{frameRate}</option>
                                    {/each}
                                </select>
                            </div>
                            {#if parseFloat(trgFrameRate) === 29.97 || parseFloat(trgFrameRate) === 59.94}
                            <div class="mb-3 col-4">
                                <label class="form-label" for="trgDropFrameSelect">Dropframe</label>
                                <select id="trgDropFrameSelect" class="form-select" bind:value="{trgDropFrame}">
                                    <option>true</option>
                                    <option>false</option>
                                </select>
                            </div>
                            {/if}
                        </div>
                        {/if}
                        <!-- Timecode Offset -->
                        <div class="row">
                            <div class="mb-3 col-8">
                                <label class="form-label" for="tcOffset">TC Offset</label>
                                <input type="text" class="form-control" bind:value="{tcOffset}" on:blur={validateTc} on:focus="{(e)=>{e.target.select()}}"/>
                            </div>

                            <div class="mb-3 col-4">
                                <label class="form-label" for="tcOffsetType">Offset Type</label>
                                <select class="form-select" bind:value="{tcOffsetType}">
                                    <option>add</option>
                                    <option>subtract</option>
                                </select>
                            </div>
                        </div>
                        <!-- Timecode Multiplier -->
                        <div class="row">
                            <div class="mb-3 col-8">
                                <label class="form-label" for="tcMultiplier">TC Multiplier</label>
                                <input type="number" class="form-control" bind:value="{tcMultiplier}" on:focus="{(e)=>{e.target.select()}}"/>
                            </div>
                        </div>
                        <!-- File Encoding -->
                        <div class="mb-3">
                            <label class="form-label" for="TrgFileEncoding">File Encoding</label>
                            <select class="form-select" bind:value="{fileEncoding}">
                                {#each encodings as encodingOption}
                                <option>{encodingOption}</option>
                                {/each}
                            </select>
                        </div>
                        <!-- Drop Frame Convert -->
                        <div class="mb-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="dropFrameConvert" bind:checked="{dropFrameConvert}" />
                                <label class="form-check-label" for="dropFrameConvert">
                                    Drop Frame Convert
                                    <i class="bi bi-info-circle" title="This option is valid when converting from a format that uses SMPTE timecode (e.g. SCC, MCC, etc.) to an offset timecode format (e.g. SRT, WebVTT, etc.)"></i>
                                </label>
                            </div>
                        </div>
                        <div class="mb-3">
                            <p class="text-warning mb-1">Forced Subtitles</p>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="fnsOption" id="includeFns" bind:group={fns}  value={"include"}>
                                <label class="form-check-label" for="includeFns">Include FS</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="fnsOption" id="excludeFns" bind:group={fns} value={"exclude"}>
                                <label class="form-check-label" for="excludeFns">Exclude FS</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="fnsOption" id="onlyFns" bind:group={fns} value={"only"}>
                                <label class="form-check-label" for="onlyFns">FS Only</label>
                            </div>
                        </div>
                        {#if formats.profileMapping[trgProfile].options && formats.profileMapping[trgProfile].options.encode.length > 0}
                        <div class="row border-top border-secondary">
                            <p class="col-12 lead text-warning pt-2">Encoding Options</p>
                            {#each formats.profileMapping[trgProfile].options.encode as encodeOption}
                            <div class="mb-3 col-6">
                                <label class="form-label" for="decodingOption">{encodeOption.name}</label>
                                {#if encodeOption.type==='textarea'}
                                <textarea class="form-control" rows="3" bind:value="{encodeOption.selected}"></textarea>
                                {:else if encodeOption.type==='text-input'}
                                <input class="form-control" type="text" bind:value="{encodeOption.selected}" />
                                {:else if encodeOption.type==='number-input'}
                                <input class="form-control" type="number" bind:value="{encodeOption.selected}" />
                                {:else}
                                <select class="form-select" bind:value="{encodeOption.selected}">
                                    {#each encodeOption.values as valueOption}
                                    <option>{valueOption}</option>
                                    {/each}
                                </select>
                                {/if}
                            </div>
                            {/each}
                        </div>
                        {/if}
                    </div>
                    {/if}
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" disabled="{!eventGroup}" on:click="{exportDialogueList}">Export</button></div>
        </div>
    </div>
</div>
