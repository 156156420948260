<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {playerState} from '@app/store/playerStore.js';
import {lockState} from '@app/store/lockStore.js';
import {styleState} from '@app/store/styleStore.js';

let eventListElement = document.getElementById("EventList");

function getEvent(){
    if ($playerState.playing){
        playerState.updatePreviewEvent(getEventByTime($playerState.time));

        /* Scroll to and select the event if caption lock is enabled */
        if ($lockState.caption && $playerState.previewEvent !== -1 && $eventGroupState[$projectState.selected].selected[0] !== $playerState.previewEvent){
            $eventGroupState[$projectState.selected].selected = [$playerState.previewEvent];
            if (!document.getElementById("QuillEditor")){
                eventListElement = eventListElement || document.getElementById("EventList");
                eventListElement.scrollTo(0, $playerState.previewEvent*230);
            }
        } else if ($lockState.caption && $playerState.previewEvent !== -1 && !document.getElementById("QuillEditor")){
            eventListElement = eventListElement || document.getElementById("EventList");                    
            eventListElement.scrollTo(0, $playerState.previewEvent*230);
        }
    } else {
        playerState.updatePreviewEvent($eventGroupState[$projectState.selected]?.selected[0]);
    }
}

function getEventByTime(sec){
    return $eventGroupState[$projectState.selected].events.findIndex(event=>{
        return event.start <= sec && event.end >= sec;
    });
}

function decToHex(value){
    return Math.floor((value/100)*255).toString(16).padStart(2, "0");
}

$: getEvent($playerState.time, $eventGroupState[$projectState.selected]?.selected[0]);
</script>
{#if $lockState.preview && $eventGroupState[$projectState.selected]?.events[$playerState.previewEvent]}
<div id="SubtitlePreviewWrapper" class="d-flex justify-content-start align-items-end">
    <div id="SubtitlePreview"
        style="direction: {$eventGroupState[$projectState.selected].rtl ? 'rtl' : 'ltr'}; font-family: {$styleState.mode ? 'plexMono' : $styleState.fontFamily}; font-size: {$styleState.mode ? '16' : $styleState.fontSize}px; color: {$styleState.mode ? '#fff' : $styleState.color+decToHex($styleState.opacity)}; line-height: {$styleState.mode ? 100 : $styleState.lineSpacing}%; letter-spacing: {$styleState.mode ? `0.57ex` : `${$styleState.spacing}px`}; {$styleState.borderStyle == "1" ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor+decToHex($styleState.shadowOpacity)}; -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor+decToHex($styleState.outlineOpacity)}; background: none;` : `background: ${$styleState.mode ? 'black' : $styleState.outlineColor+decToHex($styleState.outlineOpacity)}; 
        padding: ${$styleState.mode ? 0 : $styleState.outline}px`};">
        {@html $eventGroupState[$projectState.selected].events[$playerState.previewEvent].text}  
    </div>
</div>
{/if}
<style>
#SubtitlePreviewWrapper {
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
}

#SubtitlePreview {
  user-select: none;
}
</style>