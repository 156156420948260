<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';

function shiftLineForward() {
    try {
        let eventRemoved = false;
        let html = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text;
        let numberOfTotalWords = html.split(/\s+/g).length;
        let lines = html.split("</p>").filter(line => {return line});
        let lastLine = lines.pop();
        let plainTextLine = convertToPlainText(lastLine);
        let numberOfWordsInLine = plainTextLine.split(/\s+/g).length;
        let oStart = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start;
        let oEnd = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end;
        let oDuration = oEnd - oStart;

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].text = lastLine + "</p>" + $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].text;

        /* Remove first word from event */
        if (quillEditor) {
            quillEditor.deleteText(quillEditor.getLength() - plainTextLine.length-1, plainTextLine.length+1);
            if (quillEditor.getLength() === 1) {
                $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected], $eventGroupState[$projectState.selected].selected[0]);
            }
        }

        if (oDuration && oDuration > 0){
            let avgDurationOfLine = (oDuration / numberOfTotalWords) * numberOfWordsInLine;
            if (eventRemoved){
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end -= avgDurationOfLine;
            } else {
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end -= avgDurationOfLine;
                if ($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].end > 0){
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].start -= avgDurationOfLine;
                }
            }
        }

        historyState.insert({
            name: "shift line", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err) {
        console.log(err, err.message);
    }
}
</script>
<li class="nav-item" title="Shift Line Down">
    <a class="nav-link" href="#!/" class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === $eventGroupState[$projectState.selected].events.length-1} on:click="{shiftLineForward}"><i class="bi bi-arrow-bar-down"></i><sub><i class="bi bi-fonts"></i></sub></a>
</li>