<script>
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import shiftWordBack from "@app/external/cc-lib/dist/functions/quill/shiftWordBack.js";

function shiftWordToPrevious() {
    try {
        if (!$eventGroupState[$projectState.selected]) return;
        if ($eventGroupState[$projectState.selected].selected.length === 0) return;
        if ($eventGroupState[$projectState.selected].selected[0] === 0) return;

        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0]];

        let res = shiftWordBack($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1], $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]], $eventGroupState[$projectState.selected].maxChars);

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1].text = res.event1.text;

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text = res.event2.text;

        if (window.quillEditor){
            quillEditor.clipboard.dangerouslyPasteHTML(
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text
            );
        }

        historyState.insert({
            name: "shift word", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    } catch (err) {
        console.log(err, err.message);
    }
}
</script>

<li class="nav-item" title="Shift Word Up">
    <a
        class="nav-link"
        href="#!/"
        class:disabled={!$eventGroupState[$projectState.selected] ||
        $eventGroupState[$projectState.selected].selected.length === 0 ||
        $eventGroupState[$projectState.selected].selected[0] === 0}
        on:click={shiftWordToPrevious}
        ><strong>w</strong><sup><i class="bi bi-arrow-up"></i></sup></a
        >
        </li>
