<script>
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import {
        projectState
    } from '@app/store/projectStore.js';
    import {playerState} from '@app/store/playerStore.js';
    import {
        lockState
    } from '@app/store/lockStore.js';
    import {
        styleState
    } from '@app/store/styleStore.js';
    import {
        onMount,
        onDestroy
    } from 'svelte';
    import throttle from 'just-throttle';
    
    let previewEvents = [], previewEventWrapper = [], nextEvent;

    onMount(() => {
        try {
            player.on("seeked", scanForPreviewEvent);
            player.on("timeupdate", scanForPreviewEvent);
        } catch (err) {
            console.log(err, err.message);
        }
    });
    
/*     onDestroy(() => {
        player.off("seeked", loadAndPlaySource);
        player.off("timeupdate", cueAndPlayAudio);
    }); */

    const scanForPreviewEvent = throttle(() => {
        previewEvents = $eventGroupState[$projectState.selected].events.filter(event =>{
            //Return events that are within 30 seconds of the current time both before and after
            return event.start <= $playerState.time + 60 && event.end >= $playerState.time - 60;
        }).slice(0,30);

        nextEvent = previewEvents.find(event => {
            return event.start > $playerState.time;
        });
    }, 1000, {leading: true});

    function decToHex(value){
        return Math.floor((value/100)*255).toString(16).padStart(2, "0");
    }

    </script>
    {#if $lockState.preview}
    <div id="SubtitlePreviewWrapper" class="d-flex justify-content-center align-items-start">
        <div id="SubtitlePreview">
            {#if previewEvents.length > 0}
                {#each previewEvents as previewEvent, index (index)}
                    <div class="preview-event-wrapper" bind:this={previewEventWrapper[index]} style="direction: {$eventGroupState[$projectState.selected].rtl ? 'rtl' : 'ltr'}; font-family: {$styleState.fontFamily}; font-size: {$styleState.fontSize}px; color: {$styleState.color+decToHex($styleState.opacity)}; {$styleState.borderStyle == "1" ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor+decToHex($styleState.shadowOpacity)}; -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor+decToHex($styleState.outlineOpacity)}; background: none;` : `background: ${$styleState.outlineColor+decToHex($styleState.outlineOpacity)}; padding: ${$styleState.outline}px`}; left:{((previewEvent.start - $playerState.time) / (previewEvent.end-previewEvent.start)) * (previewEventWrapper[index] ? previewEventWrapper[index].clientWidth : 100)}px; top: {index%2 ? $styleState.fontSize+$styleState.outline+5 : 0}px">
                        {@html previewEvent.text}
                    </div>                
                {/each}
            {/if}
        </div>
        <div id="center-line" style="height: {($styleState.fontSize+$styleState.outline+5)*2.5}px;"></div>
        {#if previewEvents.length > 0}
            <div id="countdown-clock" style="top: {($styleState.fontSize+$styleState.outline+5)*2.8}px; width: {Math.max(0, ((nextEvent ? nextEvent.start : 0) - $playerState.time).toFixed(2)*100)}px; height: 10%;"></div>
        {/if}
    </div>
    {/if}
    
    <style>
    #SubtitlePreviewWrapper {
        position: absolute;
        pointer-events: none;
        overflow: hidden;
        height: 100%;
        width: 100%;
        top: 0;
    }
    
    #SubtitlePreview {
        position: absolute;
        left: 50%;
        margin-left: -3px;
        user-select: none;
        text-wrap: nowrap;
    }

    .preview-event-wrapper {
        position: absolute;
        will-change: left;
        transition: left 0.5s linear;
    }

    #center-line {
        border-left: 5px solid #ff000096;
        position: absolute;
        left: 50%;
        margin-left: -3px;
        top: 0;
    }

    #countdown-clock{
        background-color: red;
        position: absolute;
        will-change: width;
        left: 50%;
        transition: width 0.25s linear;
    }
    </style>
    