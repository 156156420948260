import _Event from "../../classes/event.js";
const Event = _Event;
export default (function insertEvent(eventGroup, options, index = null) {
  if (index && eventGroup.events[index]) {
    eventGroup.events.splice(index, 0, new Event(options));
  } else if (index == 0) {
    // If index is 0, insert at the beginning of the array
    eventGroup.events.unshift(new Event(options));
  } else {
    eventGroup.events.push(new Event(options));
  }
  return eventGroup;
});