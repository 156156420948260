import _convertToHtml from "../functions/quill/convertToHtml.js";
import _insertMissingOutcodes from "../functions/eventGroups/insertMissingOutcodes.js";
import _formatTags from "../dict/formatTags.js";
import _cheetahFunc from "../functions/profiles/cheetahBin.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const cheetahFunc = _cheetahFunc;
const formatTags = _formatTags;
const insertMissingOutcodes = _insertMissingOutcodes;
const convertToHtml = _convertToHtml;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent,
      char,
      hexBuffer,
      header,
      tcFlag = false,
      textFlag = false,
      formatting,
      incodeHex,
      outcodeHex,
      italicFlag = false;
    hexBuffer = input.match(/(..?)/g);
    header = hexBuffer.splice(0, 128);
    /* debug logger */
    //console.log(header.join(" ") + "\n");
    //console.log(hexBuffer.join(" "));
    while (hexBuffer.length > 0) {
      char = hexBuffer.shift();
      if (!tcFlag) {
        if ((char === "61" || char === "62" || char === "63") && parseInt(hexBuffer[0], 16) < 24) {
          tcFlag = true;
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          incodeHex = hexBuffer.splice(0, 4);
          outcodeHex = hexBuffer.splice(0, 4);

          /* Timecode Debug */
          // console.log(char);
          // console.log(incodeHex + "-->" + cheetahFunc.decodeTc(incodeHex));
          // console.log(outcodeHex + "-->" + cheetahFunc.decodeTc(outcodeHex));

          ccEvent.start = tcLib.tcToSec(cheetahFunc.decodeTc(incodeHex), options.frameRate);
          ccEvent.end = tcLib.tcToSec(cheetahFunc.decodeTc(outcodeHex), options.frameRate);
          italicFlag = false;
          let posData = hexBuffer.splice(0, 9);
          let vHex = parseInt(posData[7], 16);
          // console.log("vHex: " + vHex);
          //Convert vHex to an integer. If the integer is less than 6 than set yPos to start. If it's greater than 9 set yPos to end. Otherwise set yPos to middle.
          if (vHex < 6) {
            ccEvent.yPos = "start";
          } else if (vHex > 9) {
            ccEvent.yPos = "end";
          } else {
            ccEvent.yPos = "middle";
          }
          textFlag = true;
        } else if ((char === "40" || char === "41" || char === "42" || char === "43") && parseInt(hexBuffer[0], 16) < 24) {
          tcFlag = true;
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          incodeHex = hexBuffer.splice(0, 4);

          /* Timecode Debug */
          // console.log(char);
          // console.log(incodeHex + "-->" + cheetahFunc.decodeTc(incodeHex));

          ccEvent.start = tcLib.tcToSec(cheetahFunc.decodeTc(incodeHex), options.frameRate);
          italicFlag = false;
          if (char !== "40") {
            let posData = hexBuffer.splice(0, 9);
            let vHex = parseInt(posData[7], 16);
            // console.log("vHex: " + vHex);
            if (vHex < 6) {
              ccEvent.yPos = "start";
            } else if (vHex > 9) {
              ccEvent.yPos = "end";
            } else {
              ccEvent.yPos = "middle";
            }
            textFlag = true;
          }
        }
      } else if (!textFlag && tcFlag) {
        if (char === "22") {
          textFlag = true;
          formatting = hexBuffer.splice(0, 8);
        } else if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
          ccEvent.text += "\n";
          tcFlag = false;
        }
      } else if (textFlag) {
        if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
          ccEvent.text += "\n";
          /* Remove the rest of the null codes */
          hexBuffer.splice(0, 3);
        } else if (char === "00" && hexBuffer[0] != "00") {
          if (parseInt(hexBuffer[1], 16) > 0 && parseInt(hexBuffer[1], 16) < 16) {
            if (italicFlag) {
              ccEvent.text += "</em>";
              italicFlag = false;
            }
            ccEvent.text += "\n";
            /* Remove the rest of the null codes */
            hexBuffer.splice(0, 3);
          } else {
            tcFlag = false;
            textFlag = false;
            //console.log(ccEvent.text);
            if (italicFlag) {
              ccEvent.text += "</em>";
              italicFlag = false;
            }
            ccEvent.text = ccEvent.text.replace(/<\/em><em>/g, "<\/em> <em>");
            // console.log(ccEvent.text);
            ccEvent.text = convertToHtml(ccEvent.text.trim());
            // console.log(ccEvent.text);
            events.push(ccEvent);
            hexBuffer.splice(0, 1);
          }
        } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) <= 149) {
          /* Extended Characters */
          ccEvent.text += cheetahFunc.charLookup[char];
        } else if (char === "c0") {
          /* Open Italics */
          if (italicFlag) {
            ccEvent.text += "</em>";
            italicFlag = false;
          } else {
            ccEvent.text += "<em>";
            italicFlag = true;
          }
        } else if (char === "d0") {
          /* Close Italics */
          if (italicFlag) {
            ccEvent.text += "</em>";
            italicFlag = false;
          } else {
            ccEvent.text += "<em>";
            italicFlag = true;
          }
        } else {
          ccEvent.text += cheetahFunc.decodeChar(char, 1252);
        }
      }
    }
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Cheetah Cap binary files are not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input.toLowerCase();
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      eventGroup = insertMissingOutcodes(eventGroup);
      eventGroup.events = eventGroup.events.filter(event => {
        return event.end - event.start < 20;
      });
      return eventGroup;
    }
  }
};