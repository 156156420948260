import _convertToPlainText from "../quill/convertToPlainText.js";
import _convertToHtml from "../quill/convertToHtml.js";
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
export default (function reorderRtlText(eventGroup) {
  let startCharacters = [">", "-", "–", "—", "|"];
  let endCharacters = [".", "!", "?", ":", ";", "…"];
  eventGroup.events.forEach((event, i, events) => {
    let updateFlag = false;
    let plainText = convertToPlainText(event.text);
    let textLines = plainText.split("\n");
    // console.log("-------------")
    // console.log("Original:", textLines);
    textLines.forEach((textLine, index, lines) => {
      //replace two or more . with …
      textLine = textLine.replace(/\.{2,}/g, "…");
      let startCharacterToAdd = "",
        endCharacterToAdd = "";

      //Get the Character at the start of the line
      let firstCharacter = textLine.charAt(0);
      //Get the Character at the end of the line
      let lastCharacter = textLine.charAt(textLine.length - 1);

      // console.log("startCharacter", firstCharacter);
      // console.log("endCharacter", lastCharacter);

      //If the first character belongs at the end of the line
      if (startCharacters.includes(firstCharacter)) {
        //Save the start character to add later
        startCharacterToAdd = " " + firstCharacter;
        //remove the first character from the line and return the updated line
        textLine = textLine.slice(1);
        textLine = textLine.trim();
      }
      ;

      //If the last character belongs at the start of the line (e.g. Periods)
      if (endCharacters.includes(lastCharacter)) {
        //Save the end character to add later
        endCharacterToAdd = lastCharacter;
        //remove the end character from the line
        textLine = textLine.slice(0, textLine.length - 1);
      }

      //Insert the start Characer to the end
      if (startCharacterToAdd) {
        updateFlag = true;
        textLine += startCharacterToAdd;
      }
      if (endCharacterToAdd) {
        updateFlag = true;
        //insert the end character where the startOffset is
        textLine = endCharacterToAdd + textLine;
      }
      if (startCharacterToAdd || endCharacterToAdd) {
        lines[index] = textLine;
      }
    });

    // console.log("Original:", textLines);

    if (updateFlag) {
      events[i].text = convertToHtml(textLines.join("\n"));
    }
  });
  return eventGroup;
});