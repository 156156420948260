<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    externalDataState
} from "@app/store/externalDataStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    fade
} from "svelte/transition";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    playerState
} from "@app/store/playerStore.js";
import {
    speakerState
} from "@app/store/speakerStore.js";
import {
    issueState
} from '@app/store/issueStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    metadataState
} from "@app/store/metadataStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    onMount
} from "svelte";

/* Firebase */
import firebase from '@app/configs/firebase.js';

import _Event from "@app/external/cc-lib/dist/classes/event.js";
import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
import _Project from "@app/external/cc-lib/dist/classes/project.js";
import loadMedia from "@app/external/cc-lib/dist/functions/projects/loadMedia.js";;

let progress = 0,
    statusMsg = "Starting import...";

async function updateStatus(statusInfo) {
    progress = statusInfo.progress;
    statusMsg = statusInfo.msg;
}

onMount(async () => {
    try {

        /*
            URL Example: http://localhost:8080/emam?assetId=60450&thumnailSrc=http://assets.bdc.crossroads.ca/liveproxy/tx/1cb773d7-a54e-4a0a-a1df-af2ffb7fd0cf_0.jpg&mediaSrc=http://assets.bdc.crossroads.ca/liveproxy/tx/1cb773d7-a54e-4a0a-a1df-af2ffb7fd0cf_1.mp4&frameRate=29.97&duration=15.015&projectName=PRO%20AUX6%20TIYS%20S07%20EP24%20DARCY%20DAW%20TONIGHT%20930%20830%20PST%2015%20CC 
        */
        let projectInfo, assetId, thumbnailSrc, mediaSrc, frameRate, duration, projectName;

        await updateStatus({
            progress: 10,
            msg: "Receiving project from Emam...",
        });

        $projectState.selected = false;
        $eventGroupState = [];
        $speakerState = [];
        $issueState = [];
        $markerState = {
            selected : 0,
            lists : [
                {
                    id : '0',
                    name : "Shot Changes",
                    color : "#E74C3C",
                    markers : []
                }
            ]
        };
        $metadataState = [];
        playerState.updateDuration(false);

        //let getStorageRes = await firebase.functions().httpsCallable("v8IconikGetStorageList")();
        //let iconikStorages = JSON.parse(getStorageRes.data).objects;

        await updateStatus({
            progress: 30,
            msg: "Loading project info... please wait a moment.",
        });

        console.info("DEBUG: Parsing URL params from URL: " + window.location.href);
        let urlString = window.location.href;
        let url = new URL(urlString);
        assetId = url.searchParams.get("assetId");
        thumbnailSrc = url.searchParams.get("thumbnailSrc"); 
        mediaSrc = url.searchParams.get("mediaSrc"); 
        frameRate = url.searchParams.get("frameRate") || 29.97;
        duration = url.searchParams.get("duration");
        projectName = url.searchParams.get("projectName") || "Untitled";

        console.info("DEBUG: Asset id is set to " + assetId);
        console.info("DEBUG: Thumbnail Src " + thumbnailSrc);
        console.info("DEBUG: mediaSrc Src " + mediaSrc);
        console.info("DEBUG: frameRate " + frameRate);
        console.info("DEBUG: duration " + duration);
        console.info("DEBUG: projectName " + projectName);

        if (!assetId) {
            throw new Error("Unable to get Asset ID.");
        }

        /* let projectRes = await firebase.functions().httpsCallable("v8IconikImport")(assetId);
        console.log(projectRes);
        projectInfo = JSON.parse(projectRes.data); */

        await updateStatus({
            progress: 35,
            msg: "Creating project...",
        });

        console.info(`DEBUG: Creating new project.`);

        $externalDataState = {
            platform : "Emam",
            projectName : projectName,
            frameRate : parseFloat(frameRate),
            dropFrame : true,
            assetId : assetId,
            mediaUrl : mediaSrc,
            thumbnailUrl : thumbnailSrc
        };

        console.info("EXTERNAL DATA STORE: ", JSON.stringify($externalDataState, null, 4));

        await updateStatus({
            progress: 50,
            msg: "Loading project...",
        });

        $projectState = new _Project({
            name : projectName,
            frameRate : parseFloat(frameRate),
            dropFrame : true,
            media : {},
            eventGroups : [],
            username : firebase.auth().currentUser.email,
            ownerId : firebase.auth().currentUser.uid,
            selected : false
        });

        /* projectInfo.eventGroups.forEach(evg =>{
            $eventGroupState.push(new _EventGroup(evg));
            $eventGroupState[$eventGroupState.length-1].extId = evg.extId;
        });

        $projectState.selected = 0;    */
        
        await updateStatus({
            progress: 75,
            msg: "Loading media...",
        });

        $projectState = loadMedia($projectState, {
			projectName: $projectState.name,
			storage: "Cloud Storage",
			mediaUrl: mediaSrc,
			incode: 0,
			frameRate: $projectState.frameRate,
			dropFrame: $projectState.dropFrame,
			aspectRatio: "16:9",
		});

        let source = {
			src: $projectState.media.path,
		};

        source.src = $projectState.media.path;
        source.type = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type;

        player.source = {
            type: "video",
            sources: [source],
        };

        wsRegions.clearRegions();
        await ws.load($projectState.media.path);
        ws.setMediaElement(document.querySelector("video"));
        wsMinimap.onInit();

        await updateStatus({
            progress: 100,
            msg: "Done",
        });
        
        toast.push("Project import completed successfully", {
            classes: ["toast-success"]
        });       

    } catch (err) {
        console.info(err, err.message);
        toast.push("Project import failed", {
            classes: ["toast-danger"]
        });
    } finally { 
        modalState.hideModal();
    }
});
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'iconikImport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="LoadExternalProjectModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><img src="./assets/img/emam-icon.ico" alt="EMAM Logo Icon"/> EMAM Import</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <p><i class="bi bi-exclamation-diamond-fill" /> {statusMsg}</p>
                <div class="progress">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                </div>
            </div>
        </div>
    </div>
</div>
