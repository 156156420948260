<script>
    import { environment } from "@app/store/envStore.js";
    import { authState } from "@app/store/authStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { fade } from "svelte/transition";
    import { v4 as uuidv4 } from "uuid";

    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";

    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";

    /* Language Settings */
    import assemblyAiLanguages from "@app/external/cc-lib/dist/providers/assemblyAi/languages.js";
    import deepgramLanguages from "@app/external/cc-lib/dist/providers/deepgram/languages.js";
    import revAiLanguages from "@app/external/cc-lib/dist/providers/revAi/languages.js";
    import googleLanguages from "@app/external/cc-lib/dist/providers/googleSpeechToText/languages.js";
    import speechmaticsLanguages from "@app/external/cc-lib/dist/providers/speechmatics/languages.js";
    import voiceGainLanguages from "@app/external/cc-lib/dist/providers/voicegain/languages.js";
    import elevenLabsScribeLanguages from "@app/external/cc-lib/dist/providers/elevenLabsScribe/languages.js";

    let mediaDuration;
    let jobInfo;
    let statusMsg = "Uploading... please wait";

    let transcriptionDefaults =
        JSON.parse(localStorage.getItem("cc-transcription-defaults")) || {};

    let provider = transcriptionDefaults.provider || "ElevenLabs Scribe",
        language = transcriptionDefaults.language || "eng",
        interactionType = "PROFESSIONALLY_PRODUCED",
        speakers = 2,
        uploadTask,
        uploading = false,
        progress = 0,
        userId = firebase.auth().currentUser.uid,
        userEmail = firebase.auth().currentUser.email,
        teamId = $authState.team ? $authState.team.id : null,
        homeRef = $authState.team
            ? db.collection("teams").doc(teamId).collection("jobs")
            : db.collection("users").doc(userId).collection("jobs"),
        storageRef = storage.ref(),
        uploadBasePath = $authState.team
            ? "teams/" + teamId + "/watch/video/"
            : "users/" + userId + "/uploads/",
        audioUploadBasePath = $authState.team
            ? "teams/" + teamId + "/watch/audio/"
            : "users/" + userId + "/audio/",
        input,
        output;

    /* Speechmatics */
    let trgLanguages = [],
        domain = transcriptionDefaults.domain || "none",
        locale = transcriptionDefaults.locale || "en-US",
        enableTranslation = false;

    function extractAudio() {
        try {
            const os = window.os;
            const path = window.path;
            const ffmpegPath = require("ffmpeg-static-electron").path;
            const ffmpeg = require("fluent-ffmpeg");
            console.log("EXTRACTING AUDIO");
            uploading = true;
            progress = 0;
            input = $projectState.media.localPath;
            output = os.tmpdir() + path.sep + $projectState.id + ".wav";

            toast.push("Audio extraction in progress...", {
                classes: ["toast-info"],
            });

            ffmpeg.setFfmpegPath(
                ffmpegPath.replace("app.asar", "app.asar.unpacked"),
            );

            ffmpeg(input)
                .audioCodec("pcm_mulaw")
                .audioChannels(1)
                .audioFrequency(44100)
                .output(output)
                .on("start", function (commandLine) {
                    console.log("Spawned Ffmpeg with command: " + commandLine);
                })
                .on("codecData", function (data) {
                    console.log(data.duration);
                    mediaDuration = data.duration;
                    console.log(
                        "Input is " +
                            data.audio +
                            " audio " +
                            "with " +
                            data.video +
                            " video",
                    );
                })
                .on("progress", function (progress) {
                    /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
                    console.log("Scanning: " + progress.timemark + " done");
                })
                .on("end", function () {
                    toast.push("Audio extraction complete. Uploading...", {
                        classes: ["toast-success"],
                    });

                    submitJob();
                })
                .on("error", function (err, stdout, stderr) {
                    console.log(err, err.message);
                    toast.push(
                        "Failed to extract audio from source video. " +
                            err.message,
                        {
                            classes: ["toast-danger"],
                        },
                    );
                })
                .run();
        } catch (err) {
            console.log(err, err.message);
        }
    }

    async function submitJob() {
        /* 
        1. Register the job 
        2. Upload File
        3. If Failure - update job and clean up.
    */
        console.log("Submitting Automatic Transcription Job");

        localStorage.setItem(
            "cc-transcription-defaults",
            JSON.stringify({
                provider: provider,
                language: language,
                domain: domain,
                locale: locale,
            }),
        );

        let jobRegistration = false;
        uploading = true;
        progress = 5;

        jobInfo = {
            id: uuidv4(),
            mediaSource: $projectState.media.storage,
            projectId: $projectState.id,
            projectName: $projectState.name,
            submittedBy: userEmail,
            userId: userId,
            extId: null,
            progress: 0,
            duration: mediaDuration ? tcLib.tcMsToSec(mediaDuration) : 0,
            cost: 0,
            type: "transcription",
            statusMsg: "Awaiting Media",
            status: "Submitted",
            createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            completedOn: null,
            deleted: false,
            config: {
                provider: provider,
                language: language,
                speakers: speakers,
                interactionType: interactionType,
                domain: domain,
                locale: locale,
                enableTranslation: enableTranslation,
                trgLanguages: trgLanguages.slice(0, 5).join(),
            },
        };

        if ($projectState.media.storage === "Cloud Storage") {
            homeRef
                .doc(jobInfo.id)
                .set(jobInfo)
                .then(() => {
                    console.log("Transcribe job saved successfully", jobInfo);
                    jobRegistration = true;
                    firebase
                        .functions()
                        .httpsCallable("v8SubmitCloudTranscribeJob")({
                        jobId: jobInfo.id,
                        mediaUrl: $projectState.media.path,
                    });

                    toast.push("Transcription job was submitted successfully", {
                        classes: ["toast-success"],
                    });

                    modalState.showModal("aiTranscriptImport");
                })
                .catch((error) => {
                    console.log("Error submitting transcription job: ", error);

                    toast.push("Transcription Error: " + error.message, {
                        classes: ["toast-danger"],
                    });

                    /* Update Job Record */
                    if (jobRegistration) {
                        homeRef.doc(jobInfo.id).update({
                            progress: 100,
                            statusMsg:
                                "Failed submission process. " + error.message,
                            status: "Failed",
                            completedOn: firebase.firestore.Timestamp.fromDate(
                                new Date(),
                            ),
                            updatedOn: firebase.firestore.Timestamp.fromDate(
                                new Date(),
                            ),
                        });
                    }

                    uploading = false;
                    progress = 0;
                });
        } else if ($projectState.media.storage === "YouTube") {
            if ($environment.electron) {
                const ytdl = require("@distube/ytdl-core");
                let selectedFormatLowItag, selectedFormatLowContainer, selectedFormatMediumItag, selectedFormatMediumContainer, outputPath;
                homeRef
                .doc(jobInfo.id)
                .set(jobInfo)
                .then(() => {
                    console.log("Transcribe job saved successfully", jobInfo);
                    jobRegistration = true;
                    
                    return ytdl.getInfo($projectState.media.path);
                })
                .then((info) => {
                    console.log("YouTube Video Info:", info);
                    info.formats.forEach(format =>{
                        if (format.audioQuality === "AUDIO_QUALITY_LOW"){
                            selectedFormatLowItag = format.itag;
                            selectedFormatLowContainer = format.container;
                        } else if (format.audioQuality === "AUDIO_QUALITY_MEDIUM"){
                            selectedFormatMediumItag = format.itag;
                            selectedFormatMediumContainer = format.container;
                        }
                    });

                    let selectedItag = selectedFormatLowItag || selectedFormatMediumItag;
                    let selectedContainer = selectedFormatLowContainer || selectedFormatMediumContainer;
                    outputPath = os.tmpdir() + path.sep + $projectState.id + "." + selectedContainer;

                    console.log("Selected Format:", selectedItag, selectedContainer, outputPath);
                    statusMsg = "Localizing file... please wait. This may take a few minutes.";
                    return new Promise((resolve, reject) => {     
                        let writeStream = fsSync.createWriteStream(outputPath);   
                        let fileStream = ytdl($projectState.media.path, {
                            quality : selectedItag
                        }).pipe(writeStream);

                        fileStream.on('end', function(err) {
                            console.log("END");
                            console.error(err);
                            if (err){
                                reject(err.message);
                            }
                            resolve(outputPath);
                        });

                        fileStream.on('error', function(err) {
                            console.log("ERROR");
                            console.log(err);
                            reject(err.message);
                        });

                        fileStream.on('close', function(err) {
                            console.log("CLOSE");
                            if (err){
                                console.error(err);
                                reject(err.message);
                            }
                            resolve(outputPath);
                        });

                        fileStream.on('data', function(data) {
                            console.log("DATA");
                            console.log(data);
                        });

                        fileStream.on('info', function(data) {
                            console.log("INFO");
                            console.log(data);
                        });

                        fileStream.on('progress', function(data) {
                            console.log("PROGRESS");
                            console.log(data);
                        });

                        fileStream.on('finish', function(err) {
                            console.log("FINISH");
                            if (err){
                                console.error(err);
                                reject(err.message);
                            }
                            resolve(outputPath);
                        });

                        fileStream.on('pipe', function(data) {
                            console.log("PIPE", data);
                        });

                        fileStream.on('unpipe', function(data) {     
                            console.log("UNPIPE", data);   
                        });
                    });
                })
                .then(() => {
                    console.log("Output Path:", outputPath);
                    statusMsg = "Uploading to transcription service...";
                    return fetch(outputPath);
                })
                .then((fileResponse) => {
                    //console.log("File Response:",fileResponse);
                    return fileResponse.arrayBuffer();
                })
                .then((byteArrayFileRes) => {
                    //console.log(byteArrayFileRes);
                    statusMsg = "Upload in progress...";
                    uploadTask = storageRef
                        .child(uploadBasePath + jobInfo.id,
                        )
                        .put(byteArrayFileRes);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            //console.log(snapshot);
                            progress = (
                                (snapshot.bytesTransferred /
                                    snapshot.totalBytes) *
                                100
                            ).toFixed(2);
                        },
                        (error) => {
                            console.log(error, error.code);

                            toast.push(
                                "Transcription Error: " + error.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );

                            /* Update Job Record */
                            if (jobRegistration) {
                                homeRef.doc(jobInfo.id).update({
                                    progress: 100,
                                    statusMsg: error.code,
                                    status: "Failed",
                                    completedOn:
                                        firebase.firestore.Timestamp.fromDate(
                                            new Date(),
                                        ),
                                    updatedOn:
                                        firebase.firestore.Timestamp.fromDate(
                                            new Date(),
                                        ),
                                });
                            }

                            uploading = false;
                            progress = 0;
                            modalState.hideModal();
                        },
                        () => {
                            // Handle successful uploads on complete
                            //console.log("TRANSFER COMPLETE");
                            uploading = false;
                            progress = 100;

                            toast.push(
                                "Transcription job was submitted successfully",
                                {
                                    classes: ["toast-success"],
                                },
                            );
                            try {
                                fsSync.unlinkSync(outputPath);
                            } catch(err){
                                console.error(err, err.message);
                            }    
                            modalState.showModal("aiTranscriptImport");
                        },
                    );
                })
                .catch((error) => {
                    console.log("Error submitting transcription job: ", error);
                    if (jobRegistration) {
                        firebase
                            .functions()
                            .httpsCallable("v8SubmitYouTubeTranscribeJob")({
                            jobId: jobInfo.id,
                            mediaUrl: $projectState.media.path,
                        });

                        toast.push(
                            "Transcription job was submitted successfully",
                            {
                                classes: ["toast-success"],
                            }
                        );

                        modalState.showModal("aiTranscriptImport");
                    } else {
                        toast.push("Transcription Error: " + error.message, {
                            classes: ["toast-danger"],
                        });

                        uploading = false;
                        progress = 0;

                        modalState.hideModal();
                    }
                });
            } else {
                homeRef
                    .doc(jobInfo.id)
                    .set(jobInfo)
                    .then(() => {
                        console.log(
                            "Transcribe job saved successfully",
                            jobInfo,
                        );
                        jobRegistration = true;
                        return firebase
                            .functions()
                            .httpsCallable("v8SubmitYouTubeTranscribeJob")({
                            jobId: jobInfo.id,
                            mediaUrl: $projectState.media.path,
                        });
                    })
                    .then(() => {
                        toast.push(
                            "Transcription job was submitted successfully",
                            {
                                classes: ["toast-success"],
                            },
                        );

                        modalState.showModal("aiTranscriptImport");
                    })
                    .catch((error) => {
                        console.log(
                            "Error submitting transcription job: ",
                            error,
                        );

                        toast.push("Transcription Error: " + error.message, {
                            classes: ["toast-danger"],
                        });

                        /* Update Job Record */
                        if (jobRegistration) {
                            homeRef.doc(jobInfo.id).update({
                                progress: 100,
                                statusMsg:
                                    "Failed submission process. " +
                                    error.message,
                                status: "Failed",
                                completedOn:
                                    firebase.firestore.Timestamp.fromDate(
                                        new Date(),
                                    ),
                                updatedOn:
                                    firebase.firestore.Timestamp.fromDate(
                                        new Date(),
                                    ),
                            });
                        }

                        uploading = false;
                        progress = 0;
                    });
            }
        } else if (
            $projectState.media.storage === "Local Storage" ||
            $projectState.media.storage === "Proxy RT"
        ) {
            homeRef
                .doc(jobInfo.id)
                .set(jobInfo)
                .then(() => {
                    console.log("Transcribe job saved successfully", jobInfo);
                    jobRegistration = true;
                    if ($environment.electron) {
                        console.log(
                            "Fetching local output from audio extraction",
                        );
                        return fetch(output);
                    } else {
                        return fetch($projectState.media.path);
                    }
                })
                .then((fileResponse) => {
                    //console.log("File Response:",fileResponse);
                    return fileResponse.arrayBuffer();
                })
                .then((byteArrayFileRes) => {
                    //console.log(byteArrayFileRes);
                    uploadTask = storageRef
                        .child(
                            ($environment.electron
                                ? audioUploadBasePath
                                : uploadBasePath) + jobInfo.id,
                        )
                        .put(byteArrayFileRes);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            //console.log(snapshot);
                            progress = (
                                (snapshot.bytesTransferred /
                                    snapshot.totalBytes) *
                                100
                            ).toFixed(2);
                        },
                        (error) => {
                            console.log(error, error.code);

                            toast.push(
                                "Transcription Error: " + error.message,
                                {
                                    classes: ["toast-danger"],
                                },
                            );

                            /* Update Job Record */
                            if (jobRegistration) {
                                homeRef.doc(jobInfo.id).update({
                                    progress: 100,
                                    statusMsg: error.code,
                                    status: "Failed",
                                    completedOn:
                                        firebase.firestore.Timestamp.fromDate(
                                            new Date(),
                                        ),
                                    updatedOn:
                                        firebase.firestore.Timestamp.fromDate(
                                            new Date(),
                                        ),
                                });
                            }

                            uploading = false;
                            progress = 0;
                            modalState.hideModal();
                        },
                        () => {
                            // Handle successful uploads on complete
                            //console.log("TRANSFER COMPLETE");
                            uploading = false;
                            progress = 100;

                            toast.push(
                                "Transcription job was submitted successfully",
                                {
                                    classes: ["toast-success"],
                                },
                            );

                            modalState.showModal("aiTranscriptImport");
                        },
                    );
                })
                .catch((error) => {
                    console.log("Error submitting transcription job: ", error);

                    toast.push("Transcription Error: " + error.message, {
                        classes: ["toast-danger"],
                    });

                    /* Update Job Record */
                    if (jobRegistration) {
                        homeRef.doc(jobInfo.id).update({
                            progress: 100,
                            statusMsg:
                                "Failed submission process. " + error.message,
                            status: "Failed",
                            completedOn: firebase.firestore.Timestamp.fromDate(
                                new Date(),
                            ),
                            updatedOn: firebase.firestore.Timestamp.fromDate(
                                new Date(),
                            ),
                        });
                    }

                    uploading = false;
                    progress = 0;
                });
        } else {
            toast.push(
                "Automatic transcription failed. File must be accessible via Local Storage, Proxy RT, or Cloud URL. Vimeo and YouTube links are not supported.",
                {
                    classes: ["toast-danger"],
                },
            );
        }
    }

    function cancelJob() {
        if (uploadTask) {
            uploadTask.cancel();
        }
    }

    function resetLanguages() {
        if (provider === "Google Speech-to-Text") {
            language = "en-US";
        } else if (provider === "ElevenLabs Scribe"){
            language = "eng";
        } else {
            language = "en";
        }
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'automaticTranscription' ? 'show d-block' : ''}"
    role="dialog"
    aria-labelledby="automaticTranscriptionTitle"
    aria-describedby="automaticTranscriptionDescription"
    tabindex="-1"
    id="AutomaticTranscription"
>
    <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="automaticTranscriptionTitle">Automatic Transcription</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close automatic transcription dialog"
                    on:click={modalState.hideModal}
                    id="closeTranscriptionBtn"
                ></button>
            </div>
            <div class="modal-body">
                <form id="transcriptionForm" aria-label="Transcription settings form">
                    <div class="mb-3">
                        <label class="form-label" for="serviceProvider">Service Provider</label>
                        <select
                            class="form-select"
                            id="serviceProvider"
                            bind:value={provider}
                            on:change={resetLanguages}
                            aria-describedby="providerDescription"
                        >
                            <option>Speechmatics</option>
                            <option>Assembly AI</option>
                            {#if $projectState.media.storage === "Local Storage" || $projectState.media.storage === "Proxy RT" || $projectState.media.storage === "YouTube"}
                                <option>Google Speech-to-Text</option>
                                <option>ElevenLabs Scribe</option>
                            {/if}
                            <option>Deepgram</option>
                            <option>Voicegain</option>
                            <option>Rev AI</option>                            
                        </select>
                    </div>

                    {#if provider === "Assembly AI"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(assemblyAiLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                    {:else if provider === "Google Speech-to-Text"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(googleLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                    {:else if provider === "Deepgram"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(deepgramLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                    {:else if provider === "Rev AI"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(revAiLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                    {:else if provider === "Speechmatics"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(speechmaticsLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                        {#if language === "en" || language === "cmn"}
                            <div class="mb-3">
                                <label class="form-label" for="Output Locale"
                                    >Output Locale</label
                                >
                                <select
                                    class="form-select"
                                    aria-label="Output Locale Select"
                                    bind:value={locale}
                                >
                                    {#if language === "en"}
                                        <!-- English -->
                                        <option value="en-GB"
                                            >British English</option
                                        >
                                        <option value="en-US">US English</option
                                        >
                                        <option value="en-AU"
                                            >Australian English</option
                                        >
                                    {:else if language === "cmn"}
                                        <!-- Chinese -->
                                        <option value="cmn-Hans"
                                            >Simplified Chinese</option
                                        >
                                        <option value="cmn-Hant"
                                            >Traditional Chinese</option
                                        >
                                    {/if}
                                </select>
                            </div>
                        {/if}
                        {#if language === "es"}
                            <div class="mb-3">
                                <label class="form-label" for="domain"
                                    >Domain</label
                                >
                                <select class="form-select" bind:value={domain}>
                                    <option value="none">None</option>
                                    <option value="bilingual-en"
                                        >Bilingual English</option
                                    >
                                </select>
                            </div>
                        {/if}
                        {#if language === "en"}
                            <div class="mb-3">
                                <p class="small text-warning">
                                    Transcribe and translate in one step using
                                    Speechmatics.<br />Translation is only
                                    available when the source language is
                                    English.
                                </p>
                                <div class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="enableTranslationSpeechmaticsSwitch"
                                        bind:checked={enableTranslation}
                                    />
                                    <label
                                        class="form-check-label"
                                        for="enableTranslationSpeechmaticsSwitch"
                                        >Enable Translation</label
                                    >
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="target language"
                                    >Target Language(s)</label
                                >
                                <select
                                    class="form-select"
                                    aria-label="Target Language Select"
                                    multiple
                                    size="10"
                                    disabled={!enableTranslation ||
                                        language !== "en"}
                                    bind:value={trgLanguages}
                                >
                                    <option value="bg">Bulgarian</option>
                                    <option value="ca">Catalan</option>
                                    <option value="cmn">Mandarin</option>
                                    <option value="cs">Czech</option>
                                    <option value="da">Danish</option>
                                    <option value="de">German</option>
                                    <option value="el">Greek</option>
                                    <option value="es">Spanish</option>
                                    <option value="et">Estonian</option>
                                    <option value="fi">Finnish</option>
                                    <option value="fr">French</option>
                                    <option value="gl">Galician</option>
                                    <option value="hi">Hindi</option>
                                    <option value="hr">Croatian</option>
                                    <option value="hu">Hungarian</option>
                                    <option value="id">Indonesian</option>
                                    <option value="it">Italian</option>
                                    <option value="ja">Japanese</option>
                                    <option value="ko">Korean</option>
                                    <option value="lt">Lithuanian</option>
                                    <option value="lv">Latvian</option>
                                    <option value="ms">Malay</option>
                                    <option value="nl">Dutch</option>
                                    <option value="no">Norwegian</option>
                                    <option value="pl">Polish</option>
                                    <option value="pt">Portuguese</option>
                                    <option value="ro">Romanian</option>
                                    <option value="ru">Russian</option>
                                    <option value="sk">Slovakian</option>
                                    <option value="sl">Slovenian</option>
                                    <option value="sv">Swedish</option>
                                    <option value="tr">Turkish</option>
                                    <option value="uk">Ukrainian</option>
                                    <option value="vi">Vietnamese</option>
                                </select>
                                <p class="small text-muted">
                                    Additional Cost: ${(
                                        0.05 * trgLanguages.length
                                    ).toFixed(2)}/minute ({trgLanguages.join()})
                                </p>
                                <hr />
                            </div>
                        {/if}
                    {:else if provider === "Voicegain"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(voiceGainLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                    {:else if provider === "ElevenLabs Scribe"}
                        <div class="mb-3">
                            <label class="form-label" for="sourceLanguage">Source Language</label>
                            <select
                                class="form-select"
                                id="sourceLanguage"
                                disabled={uploading}
                                bind:value={language}
                                aria-describedby="languageDescription"
                            >
                                {#each Object.entries(elevenLabsScribeLanguages) as languageOption}
                                    <option value={languageOption[0]}>{languageOption[1]}</option>
                                {/each}
                            </select>
                        </div>
                        <div class="alert alert-warning" role="alert">
                            <i class="bi bi-exclamation-triangle-fill me-2"></i>
                            ElevenLabs Scribe only supports files up to 2 hours in length.
                        </div>
                    {/if}
                </form>
                <p class="small text-muted" id="processingNote">
                    Note: Automatic transcription processes at approximately
                    3-4x realtime. For example, a 1 hour file will process in
                    15-20 minutes.
                </p>
                {#if uploading}
                    <div class="progress float-start mt-2 w-75" role="progressbar" 
                             aria-valuemin="0" aria-valuemax="100" aria-valuenow={progress}
                             aria-label="Upload progress">
                        <div
                            class="progress-bar bg-primary progress-bar-striped progress-bar-animated"
                            style="width: {progress}%;"
                        >
                            {statusMsg} - {progress}%
                        </div>
                    </div>
                {/if}
                <button
                    class="btn btn-light float-end ms-2"
                    type="button"
                    id="cancelUploadBtn"
                    disabled={!uploading}
                    aria-label="Cancel file upload"
                    on:click={cancelJob}>Cancel</button
                >
                <button
                    class="btn btn-primary float-end"
                    type="button"
                    id="submitJobBtn"
                    disabled={uploading}
                    aria-label="Submit transcription job"
                    on:click={() =>
                        $environment.electron &&
                        ($projectState.media.storage === "Local Storage" ||
                            $projectState.media.storage === "Proxy RT")
                            ? extractAudio()
                            : submitJob()}>Submit Job</button
                >
                {#if uploading}
                    <p class="small text-muted text-center" role="status" aria-live="polite">{statusMsg}</p>
                    <p class="text-warning small" role="alert">
                        <i class="bi bi-exclamation-diamond-fill" aria-hidden="true"></i> 
                        Please leave this window open until your upload completes.
                    </p>
                {/if}
            </div>
        </div>
    </div>
</div>
