<script>
    import {
        modalState
    } from '@app/store/modalStore.js';
    import {
        cliConfigState
    } from '@app/store/cliConfigStore.js';
    import {
        createEventDispatcher
    } from 'svelte';
    
    export let currentState;
    const dispatch = createEventDispatcher();
    
    function toggleMenu() {
        dispatch('toggleMenu', {
            menu: 'cli'
        });
    }
    </script>
    
    {#if $cliConfigState.filter(cliTool => {return cliTool.options[0].selected === "true"}).length > 0}
    <li class="nav-item dropdown {currentState ? 'show' : ''}">
        <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/">CLI Tools</a>
        <div class="dropdown-menu {currentState ? 'show' : ''}">
            {#each $cliConfigState as cliType}
            <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal(cliType.modalName)}>{cliType.name}</a>
            {/each}
        </div>
    </li>
    {/if}
    