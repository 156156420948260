<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

function snapToStart() {
    if ($eventGroupState[$projectState.selected].selected[0] > 0) {
        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] - 1].end;
        historyState.insert({
            name: "snap to start", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    }
}
</script>
<li class="nav-item" title="Chain to Previous Event (Snap Start to End of Previous Event)">
    <a class="nav-link" href="#!/" class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" on:click={snapToStart}><i class="bi bi-align-start"></i></a>
</li>