import _replaceLineEndings from "../functions/quill/replaceLineEndings.js";
import _eol from "eol";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const eol = _eol;
const replaceLineEndings = _replaceLineEndings;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent;
    const tcRegex = /\{\d+\}\{\d+\}/gmi;
    let fileLines = eol.split(input).filter(fileLine => {
      return fileLine;
    });
    fileLines.forEach(fileLine => {
      if (tcRegex.test(fileLine)) {
        let ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.10
        });
        let fileParts = fileLine.split("}");
        let frameStart = fileParts.shift().replace(/\{|\}/g, "");
        let frameEnd = fileParts.shift().replace(/\{|\}/g, "");
        let text = fileParts.shift().replace(/\|/gmi, "\n");
        ccEvent.start = tcLib.framesToSec(parseInt(frameStart), options.frameRate);
        ccEvent.end = tcLib.framesToSec(parseInt(frameEnd), options.frameRate);
        ccEvent.text = convertToHtml(text);
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "";
    eventGroup.events.forEach(event => {
      output += `{${tcLib.secToFrames(event.start, options.frameRate)}}{${tcLib.secToFrames(event.end, options.frameRate)}}${eol.after(replaceLineEndings(convertToPlainText(event.text), "|"))}`;
    });
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};