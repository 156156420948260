<script>
/* HLS Transcode: 

ffmpeg -i C:\Users\natha\Videos\example02.mp4 -c:v h264 -vf scale=720:-1 -hls_list_size 0 -hls_base_url C:\Users\natha\Videos\hls-testing\ -preset fast -tune zerolatency C:\Users\natha\Videos\hls-testing\out.m3u8

HLS Manifest Example: https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8

*/
	import { modalState } from "@app/store/modalStore.js";
	import { projectState } from "@app/store/projectStore.js";
	import { playerState } from "@app/store/playerStore.js";
	import { environment } from "@app/store/envStore.js";
	import {
        uiState
    } from "@app/store/uiStore.js";
	import {
        onDestroy
    } from "svelte";
	import { toast } from '@zerodevx/svelte-toast';
	import { v4 as uuidv4 } from "uuid";
	import { fade } from "svelte/transition";
	import { Circle } from "svelte-loading-spinners";
	/* HLS */
	import Hls from "hls.js";
	import Swal from "sweetalert2";

	/* CC LIB */
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
	import loadMedia from "@app/external/cc-lib/dist/functions/projects/loadMedia.js";
	import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    
	let projectDefaults = JSON.parse(localStorage.getItem("cc-project-defaults")) || {},
		mediaSource = projectDefaults.mediaSource || "Local Storage",
		aspectRatio = projectDefaults.aspectRatio || "16:9",
		statusMsg = "starting...",
		files = [],
		mediaUrl = "",
		ffmpegRes,
		importing = false,
		manifestFileExists = false,
		audioExtractionComplete = false,
		audioExtractionWorker,
        proxyRtWorker,		
		incode = tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame),
        ffmpegPath,
        videoOutputPath,
        audioOutputPath;
		const uid = uuidv4();

onDestroy(async () => {
	if (audioExtractionWorker) {
		audioExtractionWorker.terminate();
	}
});

function closeModal(){
    console.log("Close Modal Called", audioExtractionComplete, manifestFileExists);
    if (audioExtractionComplete && manifestFileExists){
        statusMsg = "Project import complete...";
        
        setTimeout(() => {
            $uiState.timeline = true;
            modalState.hideModal();
        }, 2500);
    }
}

	function alertUserBoolean(msg) {
		let response = Swal.fire({
			titleText: "Metadata Scan",
			text: msg,
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: "Yes",
			denyButtonText: "No",
			allowOutsideClick: false,
			allowEscapeKey: false,
			buttonsStyling: false,
			customClass: {
				confirmButton: "btn btn-danger text-white",
				denyButton: "btn btn-light mx-2",
				cancelButton: "btn btn-outline-secondary",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				return true;
			} else {
				return false;
			}
		});

		return response;
	}

	async function getFileMetadata(filePath, ffmpegFullPath) {
    try {
        const exec = window.exec;
        return await exec(`"${ffmpegFullPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
    } catch (err) {
        return err.message;
    }
}

	function getMediaIncode(ffmpegOutput) {
		let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
		let mediaIncode = matches ? matches[0].replace(";", ":") : false;

		console.log("Media incode detected: " + mediaIncode);
		return mediaIncode == "null" ? false : mediaIncode;
	}

	function getMediaFrameRate(ffmpegOutput) {
		let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
		let mediaFrameRate = matches ? matches[0] : false;

		console.log("Media frame rate detected: " + mediaFrameRate);
		let fr = frameRates.frameRateMapping[mediaFrameRate];
		return fr;
	}

	async function importMedia() {
		importing = true;
		$uiState.timeline = false;
		playerState.updateDuration(false);
		$projectState = loadMedia($projectState, {
			projectName: $projectState.name,
			storage: mediaSource,
			mediaUrl: mediaUrl,
			files: files,
			frameRate: $projectState.frameRate,
			dropFrame: $projectState.dropFrame,
			aspectRatio: aspectRatio,
		});

		let source = {
			src: $projectState.media.path,
		};

		switch (mediaSource) {
            case "Vimeo":                
                source.src = $projectState.media.path;
                source.provider = "vimeo";
                player.source = {
                    type: "video",
                    sources: [source],
                };

				manifestFileExists = true;
				audioExtractionComplete = true;

                break;
            case "YouTube":
                source.src = $projectState.media.path;
                source.provider = "youtube";
                player.source = {
                    type: "video",
                    sources: [source],
                };

				manifestFileExists = true;
				audioExtractionComplete = true;

                break;
            case "HLS Manifest":
				audioExtractionComplete = true;
                loadHlsStream($projectState.media.path);
                break;
            case "Proxy RT":
                ffmpegPath = require("ffmpeg-static-electron").path;
                videoOutputPath = os.tmpdir() + path.sep + uid + ".m3u8";
                audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
    
                $projectState.media.path = videoOutputPath;

                ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                $projectState.media.info = {
                    incode: getMediaIncode(ffmpegRes),
                    frameRate: getMediaFrameRate(ffmpegRes),
                    duration : getMediaDuration(ffmpegRes)
                };

                /* -------------auto detect frame rate start------------- */
                if ($projectState.media.info.frameRate && $projectState.media.info.frameRate != $projectState.frameRate) {
                    if (await alertUserBoolean(`Would you like to update the Project frame rate to match the frame rate found in the media (${$projectState.media.info.frameRate})?`)) {
                        $projectState.frameRate = $projectState.media.info.frameRate;
                        $projectState.dropFrame = $projectState.media.info.frameRate === 29.97 || $projectState.media.info.frameRate === 59.94 ? true : false;
                    }
                }
                /* -------------auto detect frame rate end------------- */
    
                /* -------------auto detect incode start------------- */
                if ($projectState.media.info.incode && $projectState.media.info.incode != incode) {
                    if (await alertUserBoolean(`Would you like to update the Project incode to match the incode found in the media (${$projectState.media.info.incode})?`)) {
                        incode = $projectState.media.info.incode;
                        $projectState.incode = tcLib.tcToSec(incode, $projectState.frameRate, $projectState.dropFrame);
                    }
                }
                /* -------------auto detect incode end------------- */
    
                audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
                audioExtractionWorker.postMessage({
                    inputPath: $projectState.media.localPath,
                    outputPath: audioOutputPath,
                    ffmpegPath: ffmpegPath,
                    duration : $projectState.media.info.duration
                });
    
                audioExtractionWorker.onmessage = (msg) => {
                    console.log(msg);
                    if (msg.data.status === "in_progress") {
                        statusMsg = msg.data.result;
                    } else {
                        $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                        $projectState.media.useFallback = false;
                        toast.push(`${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`, {classes: ['toast-`${msg.data.error ? "danger" : "success"}`,']});
    
                        audioExtractionComplete = true;
                        closeModal();
                    }
                };
    
                proxyRtWorker = new Worker("./build/workers/proxyRt.js");
                proxyRtWorker.postMessage({
                    inputPath: $projectState.media.localPath,
                    outputPath: videoOutputPath,
                    ffmpegPath: ffmpegPath,
                    tmpDir: os.tmpdir(),
                    pathSep: path.sep,
                });
    
                proxyRtWorker.onmessage = (msg) => {
                    console.log(msg);
                    if (msg.data.status_msg) {
                        manifestFileExists = true;
                        loadHlsStream(videoOutputPath);
                    } else if (msg.data.error) {
                        toast.push(`${msg.data.error}`, {classes: ['toast-danger']});
                    }
                };
    
                break;
            case "Cloud Storage":
                /* Cloud Storage*/
                source.src = $projectState.media.path;
                if ($projectState.media.type === "video/quicktime"){
                    source.type = "video/mp4"
                } else if ($projectState.media.type === "video/x-matroska"){
                    source.type = "video/mkv"
                } else {
                    source.type = $projectState.media.type;
                }

                //Get file size of url
                let fileSize = await getFileSize($projectState.media.path);
                if (fileSize){
                    const sizeInBytes = parseInt(fileSize);
                    const sizeInKilobytes = sizeInBytes / 1024;
                    const sizeInMegabytes = sizeInKilobytes / 1024;
                    if (sizeInMegabytes < 512){
                        $projectState.media.useFallback = false;
                    }
                }

                player.source = {
                    type: "video",
                    sources: [source],
                };

				manifestFileExists = true;
				audioExtractionComplete = true;
                break;
            default:
                /* Local Storage */
                source.src = $projectState.media.path;
                source.type = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type;
				manifestFileExists = true;
				
                if ($environment.electron) {
                    ffmpegPath = require("ffmpeg-static-electron").path;
                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    //console.log(ffmpegPath);
                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    console.log("-----FILE INFO-----");
                    console.log(ffmpegRes);
                    console.log("-------------------");
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };
                    /* Auto detect frame rate */
                    if ($projectState.media.info.frameRate && $projectState.media.info.frameRate != $projectState.frameRate) {
                        if (await alertUserBoolean(`Would you like to update the Project frame rate to match the frame rate found in the media (${$projectState.media.info.frameRate})?`)) {
                            $projectState.frameRate = $projectState.media.info.frameRate;
                            $projectState.dropFrame = $projectState.media.info.frameRate === 29.97 || $projectState.media.info.frameRate === 59.94 ? true : false;
						}
                    }
                    /* -------------auto detect frame rate------------- */
    
                    /* Auto detect incode */
                    if ($projectState.media.info.incode && $projectState.media.info.incode != incode) {
                        if (await alertUserBoolean(`Would you like to update the Project incode to match the incode found in the media (${$projectState.media.info.incode})?`)) {
                            incode = $projectState.media.info.incode;
                            $projectState.incode = tcLib.tcToSec(incode, $projectState.frameRate, $projectState.dropFrame);
                        }
                    }
                    /* -------------auto detect incode------------- */
    
                    audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                        duration : $projectState.media.info.duration
                    });
    
                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            statusMsg = msg.data.result;
                        } else {
                            $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                            $projectState.media.useFallback = false;
                            player.source = {
                                type: "video",
                                sources: [source],
                            };
                            toast.push(`${msg.data.error ? msg.data.error : "Media import completed successfully."}`, {classes: [`toast-${msg.data.error ? "danger" : "success"}`]});
    
                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };
                } else {
                    if (files[0].size < 524288000) { // 500MB (524288000 bytes)
                        $projectState.media.useFallback = false;                        
                    }
                    
                    
                    player.source = {
                        type: "video",
                        sources: [source],
                    };

                    audioExtractionComplete = true;
                }
        }

		closeModal();
	}

function loadHlsStream(mediaPath){
    const hls = new Hls();
    const videoTag = document.querySelector('video');

    hls.loadSource(mediaPath);
    hls.attachMedia(videoTag);        
    
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !'); 
    });        

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log('manifest loaded, found ' + data.levels.length + ' quality level'); 
        manifestFileExists = true; 
        closeModal();
    }); 
}

function getMediaDuration(ffmpegOutput){
    /*  console.log("GETTING MEDIA DURATION");
    console.log("FFMPEG OUTPUT:");
    console.log(ffmpegOutput); */
    let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
    let mediaDuration = matches ? matches[0] : false;

    console.log(mediaDuration);
    if (mediaDuration){
        mediaDuration = tcLib.tcMsToSec(mediaDuration);
        console.log("media duration in seconds: " + mediaDuration);
    }

    return mediaDuration;
}

async function getFileSize() {
    try {
        const response = await fetch($projectState.media.path, {
            method: "HEAD",
        });
        const fileSize = response.headers.get("content-length");
        return fileSize;
    } catch (err){
        return false;
    }    
}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'mediaImport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="MediaImportModal">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Media Import</h4>
				<button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
			</div>
			<div class="modal-body">
				<form on:submit|preventDefault={importMedia}>
					<div class="mb-3">
						<label class="form-label" for="mediaSourceSelect">Media Source</label>
						<select id="mediaSourceSelect" class="form-select" bind:value={mediaSource}>
							<option value="Local Storage">Local Storage</option>
                            {#if $environment.online}
                                <option value="YouTube">YouTube</option>
                                <option value="Vimeo">Vimeo</option>
                                <option value="Cloud Storage">Cloud Storage</option>
                                <option value="HLS Manifest">HLS Manifest</option>
                            {/if}
							{#if $environment.electron}
								<option value="Proxy RT">Proxy RT</option>
							{/if}
						</select>
					</div>
					<div class="mb-3">
						{#if mediaSource === "Local Storage"}
							<p class="mb-2">Media Location</p>
							<input type="file" class="form-control" accept="video/*,audio/*" bind:files />
						{:else if mediaSource === "HLS Manifest"}
							<label class="form-label" for="HlsManifestUrl">HLS Manifest URL</label>
							<input bind:value={mediaUrl} type="url" class="form-control" id="HlsManifestUrl" placeholder="Manifest URL" />
						{:else if mediaSource === "Proxy RT"}
							<p class="mb-2">Media Location</p>
							<input type="file" class="form-control" accept=".mxf, .ixf, .aaf, .imf, .gxf, .mov, .mp4, .mpeg, .mpeg2, .mpg, .avi, .mkv, .wav, .mp3, .flac" bind:files />
						{:else if mediaSource === "YouTube"}
							<label class="form-label" for="YouTubeSourceUrl">YouTube Page URL</label>
							<input bind:value={mediaUrl} type="url" class="form-control" id="YouTubeSourceUrl" placeholder="Video URL" />
						{:else if mediaSource === "Vimeo"}
							<label class="form-label" for="VimeoSourceUrl">Vimeo Page URL</label>
							<input bind:value={mediaUrl} type="url" class="form-control" id="VimeoSourceUrl" placeholder="Video URL" />
						{:else}
							<label class="form-label" for="CloudSourceUrl">Cloud Storage URL</label>
							<input bind:value={mediaUrl} type="url" class="form-control" id="CloudSourceUrl" placeholder="Cloud URL" />
						{/if}
					</div>
					<div class="mb-3">
						<label class="form-label" for="Aspect Ratio">Aspect Ratio</label>
						<select class="form-select" bind:value={aspectRatio}>
							<option>16:9</option>
							<option>4:3</option>
						</select>
					</div>
				</form>
			</div>
			<div class="modal-footer">
				{#if importing}
					<p class="text-muted small">Loading media | {statusMsg}</p>
					<Circle size="30" color="#1eb4b2" unit="px" duration="1s" />
				{/if}
				<button class="btn btn-primary" type="button" disabled={importing || (mediaSource === "Local Storage" && files.length === 0) || (mediaSource === "Proxy RT" && files.length === 0) || (mediaSource !== "Local Storage" && mediaSource !== "Proxy RT" && mediaUrl.length === 0)} on:click={importMedia}>Import Media</button>
			</div>
		</div>
	</div>
</div>
