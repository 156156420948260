<script>
import {
    environment
} from '@app/store/envStore.js'; 
import {
    fade
} from 'svelte/transition';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    styleState
} from '@app/store/styleStore.js';
import {authState} from '@app/store/authStore.js';
import {
    saveAs
} from 'file-saver';
import Swal from 'sweetalert2'
import throttle from 'just-throttle';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';
/* CC LIB */
import encode from "@app/external/cc-lib/dist/functions/encode.js";
import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
import formats from "@app/external/cc-lib/dist/lib/formats.js";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";
import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
import wrapStyledTextWithSpan from '@app/external/cc-lib/dist/functions/quill/wrapStyledTextWithSpan.js';
import offset from "@app/external/cc-lib/dist/functions/events/tcOffset.js";

/* Translate for Creator */
let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
let tcOffset = $projectState.incode || 0;
let tcOffsetType = "add";
let targetFolder;
let selectedGroups = [], 
    fileFormats = JSON.parse(localStorage.getItem("cc-subtitle-batch-export-defaults")) || [{
        ext : "srt",
        profile : "SubRip Video Subtitle Script"
    }];

if ($authState.status === "in_trial"){
    showTrialWarning();
}

function showTrialWarning(){
    Swal.fire({
        titleText: "Trial Mode",
        text : "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Activate Account',
        denyButtonText: 'Continue Trial',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            denyButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            activateSubscription();
            return true;
        } else {
            return false;
        }
    }).then((res) => {
        if (res){
            console.log(res);
            showRestartNotification();
        }
        
        return true;
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function showRestartNotification(){
    Swal.fire({
        titleText: "Restart Required",
        text : "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
        confirmButtonText: 'Ok',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-light'
        }
    }).then(res =>{
        console.log(res);
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function insertFormat(){
    fileFormats = [...fileFormats, {
        ext : "srt",
        profile : "SubRip Video Subtitle Script"
    }]
}

function removeFormat(){
    fileFormats = fileFormats.slice(0,-1);
}

function removeAllFormats(){
    fileFormats = [{
        ext : "srt",
        profile : "SubRip Video Subtitle Script"
    }]
}

function updateProfile(index, ext) {
    fileFormats[index].profile = formats.trgProfiles[ext][0];
}

async function batchExport() {
    if (selectedGroups.length===0 || fileFormats.length === 0){
        return;
    }

    if ($environment.electron) {
        if (!targetFolder){
            targetFolder = await selectTargetFolder();
        }
    }


    selectedGroups.forEach(groupId =>{
        console.log("--------");
        console.log("GroupID: " +groupId);
        try {
            let eventGroupIndex = $eventGroupState.findIndex(evGroup => {
                return groupId === evGroup.id;
            });

            fileFormats.forEach(async function (exportFormat){
                try { 
                    console.log("Export Format: " + exportFormat.profile);
                    let fileName = $eventGroupState[eventGroupIndex].name + "." + exportFormat.ext;
                    let copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));
                    copyOfEventGroup = orderByTime(copyOfEventGroup);
                    copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);
                    let defaultEncoding = formats.defaultEncodingMap[formats.profileMapping[exportFormat.profile].name];

                    let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
                    let yPadding = $styleState.mode ? 10 : $styleState.yPadding;

                    copyOfEventGroup.events.forEach((event, index, events) => {
                        events[index] = offset(event, tcOffset, tcOffsetType);
                    });

                    let options = new defaults.options({
                        profile: formats.profileMapping[exportFormat.profile].name,
                        formatOptions: formats.profileMapping[exportFormat.profile].options.encode,
                        frameRate: $projectState.frameRate,
                        dropFrame: $projectState.dropFrame,
                        target_profile : formats.profileMapping[exportFormat.profile].name,
                        source_profile : 'closedCaptionProject',
                        window: {
                            width: playerWidth,
                            height: playerHeight,
                            xOffset: (xPadding/100) * playerWidth,
                            yOffset: (yPadding/100) * playerHeight
                        }
                    });                    

                    if ($authState.status === "in_trial"){
                        copyOfEventGroup = replaceTrialText(copyOfEventGroup);
                    }

                    copyOfEventGroup.events.forEach((event, index, events) =>{
                        events[index].text = wrapStyledTextWithSpan(event.text);
                    });

                    let output = await encode(copyOfEventGroup, options);
                    let fileBlob;
                    
                    if (defaultEncoding === "buffer"){
                        if (exportFormat.ext === "xlsx"){
                            fileBlob = new Blob([output], {
                                type: "application/octet-stream"
                            });
                        } else {
                            fileBlob = output;
                        }
                    } else if (defaultEncoding.toLowerCase() === "hex") {
                        let byteArray = new Uint8Array(output.match(/.{2}/g).map(e => parseInt(e, 16)));
                        fileBlob = new Blob([byteArray], {
                            type: "application/octet-stream"
                        });
                    } else if (defaultEncoding.toLowerCase() === "utf-16le") {
                        fileBlob = new Blob([output], {
                            type: "text/plain;charset=utf-16le"
                        });
                    } else {
                        fileBlob = new Blob([output], {
                            type: "text/plain;charset=utf-8"
                        });
                    }

                    console.log("SAVING FILE");

                    if ($environment.electron) {
                        if (!targetFolder){
                            throw new Error("No Target Folder Selected");
                        }

                        let fs = window.fs;
                        let path = window.path;
                        let filePath = path.join(targetFolder, fileName);
                        // ----
                        let fileArrayBuffer = await fileBlob.arrayBuffer();
                        const fileBuffer = Buffer.from(fileArrayBuffer);
                        await fs.writeFile(filePath, fileBuffer);
                        return;
                    } else {
                        saveAs(fileBlob, fileName, {
                            autoBom: true
                        });
                    }
                    
                    
                    
                } catch(err){
                    console.log(err, err.message);
                    toast.push("Failed to export subtitle", {classes: ["toast-warning"]});
                }
            })
        } catch(err){
            console.log(err, err.message);
            toast.push("Failed to export subtitle", {classes: ["toast-warning"]});
        }
        
    });

    toast.push("Batch Export Started", {classes: ["toast-success"]});

    localStorage.setItem("cc-subtitle-batch-export-defaults", JSON.stringify(fileFormats));
    modalState.hideModal();
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, { leading: true });

function selectTargetFolder() {
    return ipcRenderer
        .invoke("selectFolder", {
            title: "Subtitle Batch Export - Select Folder",
            properties: ["openDirectory", "createDirectory"],
        })
        .then((path) => {
            if (!path.canceled) {
                console.log(path.filePaths[0]);
                return path.filePaths[0];
            } else {
                return false;
            }
        });
}
</script>

<div 
    transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'batchSubtitleExport' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="batchExportTitle"
    aria-describedby="batchExportDescription"
    tabindex="-1" 
    id="BatchSubtitleExportModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="batchExportTitle">Batch Subtitle Export</h4>
                <button type="button" 
                    class="btn-close" 
                    aria-label="Close modal" 
                    id="closeBatchExportBtn"
                    on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{batchExport}" id="batchExportForm" aria-describedby="batchExportDescription">
                    <div id="batchExportDescription" class="visually-hidden">
                        Select event groups and export formats to batch export subtitles
                    </div>
                    
                    <!-- Event Groups -->
                    <div class="mb-3">
                        <label class="form-label" for="eventGroupSelect">Select Event Groups <span class="badge bg-danger" aria-label="Required field">Required</span></label>
                        <select 
                            multiple 
                            size="10" 
                            class="form-control" 
                            id="eventGroupSelect"
                            aria-required="true"
                            aria-label="Select event groups for export"
                            bind:value={selectedGroups}>
                            {#each $eventGroupState as eventGroup}
                                <option value="{eventGroup.id}">{eventGroup.name}</option>
                            {/each}
                        </select>
                    </div>
                    <p class="lead text-warning" id="formatSectionLabel">Export Formats
                        <span class="ms-3">
                        <div class="btn-group btn-group-sm" role="group" aria-label="Format controls">
                            <button type="button" 
                                class="btn btn-light" 
                                id="addFormatBtn"
                                aria-label="Add format"
                                on:click="{insertFormat}" 
                                title="Insert format"><i class="bi bi-plus-lg"></i></button>
                            <button type="button" 
                                class="btn btn-outline-dark" 
                                id="removeFormatBtn"
                                aria-label="Remove format"
                                on:click="{removeFormat}" 
                                title="Remove format" 
                                disabled={fileFormats.length === 0}><i class="bi bi-dash-lg"></i></button>
                        </div>
                        <button type="button" 
                            class="btn btn-sm btn-outline-danger ms-2" 
                            id="resetFormatsBtn"
                            aria-label="Reset formats"
                            on:click="{removeAllFormats}">RESET</button></span>
                    </p>
                    {#each fileFormats as fileFormat, index}
                        <div class="row">
                            <div class="input-group input-group-sm col" role="group" aria-label="Format {index + 1} settings">
                                <span class="input-group-text" id="format-{index}-label">{index+1}</span>
                                <select 
                                    class="form-select col" 
                                    id="format-{index}-ext"
                                    aria-label="File extension for format {index + 1}"
                                    aria-describedby="format-{index}-label"
                                    bind:value={fileFormat.ext} 
                                    on:change="{updateProfile(index, fileFormat.ext)}">
                                    {#each formats.trgExt.filter(trgExt=>{
                                        if ($authState.plan==='pro' || $authState.plan==='enterprise'){
                                            return true;
                                        } else if (["scc", "mcc", "stl"].indexOf(trgExt.toLowerCase()) > -1){
                                            return false;
                                        } else {
                                            return true;
                                        }
                                        }) as ext}
                                        <option>{ext}</option>
                                    {/each}
                                </select>
                                <select 
                                    class="form-select col" 
                                    id="format-{index}-profile"
                                    aria-label="Profile for format {index + 1}"
                                    aria-describedby="format-{index}-label"
                                    bind:value={fileFormat.profile}>
                                    {#each formats.trgProfiles[fileFormat.ext] as profile}
                                        <option>{profile}</option>
                                    {/each}
                                </select>
                            </div>                       
                        </div>
                    {/each}
                </form>
            </div>
            <div class="modal-footer">
                <button 
                    class="btn btn-primary" 
                    type="submit"
                    form="batchExportForm"
                    id="exportBtn"
                    aria-label="Start batch export"
                    disabled="{selectedGroups.length===0 || fileFormats.length === 0}" 
                    on:click={batchExport}>Export</button>
            </div>
        </div>
    </div>
</div>
