<script>
import { toast } from '@zerodevx/svelte-toast';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
/* 3rd Party Lib */
import {
    BarLoader
} from 'svelte-loading-spinners';

let iconikAppId = "", 
    iconikAuthToken = "",
    elevenLabsKey = "", 
    dbWork, 
    userId = firebase.auth().currentUser.uid, 
    userDbRef = db.collection("users").doc(userId);

async function updateIntegrationSettings(){
    dbWork= await userDbRef.update({
        iconik : {
            appId : iconikAppId,
            authToken : iconikAuthToken
        },
        elevenLabs : {
            key : elevenLabsKey
        }
    });

    toast.push("Settings have been saved successfully.", {classes: ['toast-success']});
}       

async function setup(){
    try {
        let userDoc = await userDbRef.get();
        if (!userDoc.exists){
            console.warn(`No user document exists for current user: ${userId}`);
            dbWork = await userDbRef.set({
                iconik : {
                    appId : "",
                    authToken : ""
                },
                elevenLabs : {
                    key : ""
                }
            });

            return;
        }

        let userInfo = userDoc.data();
        iconikAppId = userInfo.iconik ? userInfo.iconik.appId : "";
        iconikAuthToken = userInfo.iconik ? userInfo.iconik.authToken : "";
        elevenLabsKey = userInfo.elevenLabs ? userInfo.elevenLabs.key : "";
        
    } catch(err){
        console.log(err, err.message);
        return err;
    }   
}

dbWork = setup();
</script>    
    <h5>Integration Settings</h5>
    <hr/>
    {#await dbWork}
        <div class="d-flex justify-content-center align-items-center p-3">
            <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
        </div>       
    {:then}
    
    <form on:submit|preventDefault="{() => {dbWork = updateIntegrationSettings()}}">
        <img width="150px" class="my-3" src="./assets/img/logos/iconik-logo.svg" alt="iconik logo">
        <div class="mb-3">
            <label for="iconikAppId" class="form-label">Application Id</label>
            <input type="password" class="form-control" id="iconikAppId" bind:value={iconikAppId}>
          </div>
          <div>
            <label for="iconikAuthToken" class="form-label">Token</label>
            <input type="password" class="form-control" id="iconikAuthToken" bind:value={iconikAuthToken}>
          </div>
          <hr/>
        <img width="200px" class="my-3" src="./assets/img/logos/elevenLabs.png" alt="Eleven Labs logo">
        <div class="mb-3">
            <label for="elevenLabsKeyInput" class="form-label">API Key</label>
            <input type="password" class="form-control" id="elevenLabsKeyInput" bind:value={elevenLabsKey}>
          </div>
          <button type="button" class="btn btn-primary btn-lg float-end " on:click={() => updateIntegrationSettings()}>Save Settings</button>
    </form>
    {:catch error}
    <div class="d-flex justify-content-center align-items-center p-3">
        <!-- promise was rejected -->
        <p class="text-danger m-0">Error loading integration settings from database. : {error.message}. Please close the options menu and try again.</p>
    </div>	
    {/await}
    