<script>
import {modalState} from '@app/store/modalStore.js';
import {authState} from '@app/store/authStore.js';
import { projectState } from '@app/store/projectStore.js';
import { fade } from 'svelte/transition';

/* Firebase */
import db from '@app/configs/firestore.js';

let snapshots = [];
let selectedSnapshot;

let teamId = $authState.team.id;
let dbSnapshotRef = db.collection("teams").doc(teamId).collection("projects").doc($projectState.teamId).collection("snapshots");
let dbCommitRef = db.collection("teams").doc(teamId).collection("projects").doc($projectState.teamId).collection("commits");
let gettingSnapshots = loadSnapshotHistory();

function loadSnapshotHistory(){
    snapshots = [];
    selectedSnapshot = undefined;
    return dbSnapshotRef.orderBy("createdOn", "desc").get().then(snapshotRes =>{
        snapshotRes.forEach(doc =>{
            console.log(doc.data());
            snapshots = [...snapshots, doc.data()];
        });
    });
}

async function deleteCommit(){
    try {
        await dbSnapshotRef.doc(selectedSnapshot.id).delete();

        await dbCommitRef.doc(selectedSnapshot.commit).delete();

        gettingSnapshots = loadSnapshotHistory();
    } catch(err){
        console.error(err);
        console.log(err.message);
    }    
}
</script>
  
  <div 
    transition:fade="{{duration: 100}}" 
    class="modal fade {$modalState === 'snapshotHistory' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="snapshotHistoryTitle"
    aria-describedby="snapshotHistoryDescription"
    tabindex="-1" 
    id="SnapshotHistoryModal"
>
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="snapshotHistoryTitle">Commit History</h4>
          <button 
            type="button" 
            class="btn-close" 
            aria-label="Close modal" 
            id="closeSnapshotHistory"
            on:click={modalState.hideModal}
          ></button>
        </div>
        <div class="modal-body">
          <p id="snapshotHistoryDescription" class="visually-hidden">
            View and manage commit history snapshots
          </p>
          <div class="row">
            <div class="col-6">
                <button 
                  type="button" 
                  class="btn btn-light float-end mb-1" 
                  id="refreshSnapshots"
                  aria-label="Refresh commit list" 
                  on:click={loadSnapshotHistory}
                ><i class="bi bi-arrow-clockwise" aria-hidden="true"></i></button>
                <select 
                  class="form-select form-select-sm" 
                  size="15" 
                  bind:value={selectedSnapshot}
                  aria-label="Select commit snapshot"
                  id="snapshotSelect"
                >
                    {#each snapshots as snapshot (snapshot.id)}
                        <option 
                          class="text-wrap {snapshot.status === 'in progress' ? 'text-danger' : ''}" 
                          value={snapshot}
                          aria-label="Commit by {snapshot.user}: {snapshot.message || 'No Message'}"
                        >{snapshot.user} | {snapshot.message || "No Message"}</option>
                    {/each}
                </select>
            </div>
            <div class="col">
                <button 
                  type="button" 
                  class="btn btn-danger float-end mb-1" 
                  disabled={!selectedSnapshot || selectedSnapshot.id !== snapshots[0].id} 
                  on:click={deleteCommit}
                  id="deleteCommit"
                  aria-label="Delete selected commit"
                ><i class="bi bi-trash" aria-hidden="true"></i> Delete Commit</button>
                <table class="table table-light table-striped" aria-label="Commit details">
                    <tbody>
                      <tr>
                        <th scope="row">ID</th>
                        <td>{selectedSnapshot ? selectedSnapshot.commit  : ""}</td>
                      </tr>      
                      <tr>
                        <th scope="row">Created On</th>
                        <td>{selectedSnapshot ? selectedSnapshot.createdOn.toDate()  : ""}</td>
                      </tr>      
                      <tr>
                        <th scope="row">Completed</th>
                        <td>
                          <i 
                            class="bi bi-{selectedSnapshot && selectedSnapshot.status === 'complete' ? 'check-lg' : 'slash-circle'}"
                            aria-label={selectedSnapshot && selectedSnapshot.status === 'complete' ? 'Completed' : 'Not completed'}
                          ></i>
                        </td>
                      </tr>      
                      <tr>
                        <th scope="row">Username</th>
                        <td>{selectedSnapshot ? selectedSnapshot.user  : ""}</td>
                      </tr> 
                      <tr>
                        <th scope="row">Message</th>
                        <td>{selectedSnapshot && selectedSnapshot.message ? selectedSnapshot.message  : ""}</td>
                      </tr>                   
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-light" 
              on:click={modalState.hideModal}
              id="closeModalBtn"
              aria-label="Close modal"
            >Close</button>
        </div>
      </div>
    </div>
  </div>
