<script>
  import {eventGroupState} from '@app/store/eventGroupStore.js'; 
  import {modalState} from '@app/store/modalStore.js';
  import EventGroupTab from './EventGroupTab.svelte';
</script>
<ul id="EventGroups" class="nav nav-tabs">
  <li class="nav-item">
    <button type="button" class="btn btn-light rounded-bottom-0 border-0 bg-light h-100" on:click={() => modalState.showModal("newEventGroup")}><i class="bi bi-plus-circle-fill"></i></button>
  </li>
  {#if $eventGroupState.length > 0}
    {#each $eventGroupState as eventGroup, index(index)}
      <EventGroupTab 
        name={eventGroup.name}
        eventGroupIndex = {index}
        eventGroupType = {eventGroup.type}
      />
    {/each}
  {/if}
</ul>

<style>
  #EventGroups {
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap;
    display: -webkit-box;
  }
</style>