<script>
    import { eventGroupState } from '@app/store/eventGroupStore.js';
    import { projectState } from '@app/store/projectStore.js';
    import { BarLoader } from 'svelte-loading-spinners';
    import { issueState } from "@app/store/issueStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { saveAs } from 'file-saver';
    import { unparse } from 'papaparse';
    import { highlightedEventState } from '@app/store/highlightedEventStore.js';
    import { toast } from '@zerodevx/svelte-toast';
    import { lockState } from '@app/store/lockStore.js';
    import { beforeUpdate, tick } from 'svelte';
    import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
    import _Issue from "@app/external/cc-lib/dist/classes/issue.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import throttle from "just-throttle";
    import Swal from 'sweetalert2';
    import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
    
    
    //----- Variables -----
    //first event selected
    $: event = $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : false;

    //all events selected
    $: eventsSelected = (() => {
        const eventGroup = $eventGroupState[$projectState.selected];
        if (!eventGroup) return [];
        return eventGroup.selected
            .map(index => eventGroup.events[index])
            .filter(event => event !== undefined);
    })();

    $: selectedEventGroup =  $eventGroupState[$projectState.selected];

    $: filteredIssues = $issueState
            .filter(issue => {
                return selectedFilter === 'all' || issue.status === selectedFilter;
            })
            .sort((a, b) => (Number(a.priority) || 6) - (Number(b.priority) || 6));

    let idOpen = false;
    let isDropdownOpen = false;
    let selectedFilter = 'all';
    let selectedIssue;
    let eventListElement;
    let issueName;
    let issueType;
    let issueDescription;
    let issuePriority;
    let issueStatus;

    let colorMap = {
        color: {
            "technical" : "#66ccff",
            "content" : "#99ff66",
            "grammar" : "#ffff66",
            "feedback" : "#ffcc66",
            "other" : "white",
        },
        background: {
            "open" : "#006600",
            "closed" : "#333399",
            "in progress" : "#cc9900",
            "review required" : "#9933ff",
            "in review" : "#cc6600",
            "supervisor review" : "#990033",
        }
    }
    
    //----- Handlers ------
    function exportIssues() {
        try {
            let issuesToExport = [];

            filteredIssues.forEach((issue) => {
                let eventText = "";
                let matchingEventGroup = null;
                let startSmpte = "";
                let endSmpte = "";
                let startMs = "";
                let endMs = "";

                for (let i = 0; i < $eventGroupState.length; i++) {
                    if ($eventGroupState[i] && $eventGroupState[i].id === issue.eventGroupId) {
                        matchingEventGroup = $eventGroupState[i];
                        break; 
                    }
                }

                if (matchingEventGroup) {
                    issue.eventIds
                        .filter(eventId => eventId != null)
                        .forEach(eventId => {
                            const event = matchingEventGroup.events.find(event => event.id === eventId);
                            if (event) {
                                eventText += convertToPlainText(event.text) + " ";
                                startSmpte = tcLib.secToTc(event.start + $projectState.incode, $projectState.frameRate, $projectState.dropFrame);
                                endSmpte = tcLib.secToTc(event.end + $projectState.incode, $projectState.frameRate, $projectState.dropFrame);
                                startMs = tcLib.secToTcMs(event.start);
                                endMs = tcLib.secToTcMs(event.end);
                            } else {
                                console.warn(`No matching event found for eventId: ${eventId}`);
                            }
                        });
                } else {
                    console.warn(`No matching event group found for issue: ${issue.id}`);
                }

                issuesToExport.push({
                    "Name": issue.name,
                    "Type": issue.type,
                    "Description": issue.description,
                    "Priority": issue.priority,
                    "Status": issue.status,
                    "Event Text": eventText,
                    "Start SMPTE": startSmpte,
                    "End SMPTE": endSmpte,
                    "Start MS": startMs,
                    "End MS": endMs,
                });
            });

            console.log(issuesToExport);
            let csv = unparse(issuesToExport, {
                header: true,
                columns: ["Name", "Type", "Description", "Priority", "Status", "Event Text", "Start SMPTE", "End SMPTE", "Start MS", "End MS"]
            });

            let blob = new Blob([csv], {
                type: "text/csv;charset=utf-8"
            });

            Swal.fire({
                titleText: 'Export Issues',
                showCancelButton: true,
                confirmButtonText: 'Export',
                input: 'text',
                inputLabel: 'Filename',
                inputPlaceholder: 'Enter a filename here',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary me-2',
                    cancelButton: 'btn btn-outline-secondary',
                }
            }).then(res => {
                if (res.isConfirmed && res.value) {
                    saveAs(blob, res.value + ".csv");
                }
            });
        } catch (err) {
            console.error("Error exporting issues:", err);
            Swal.fire({
                icon: 'error',
                title: 'Export Failed',
                text: 'An error occurred while exporting issues. Please try again.',
            });
        }
        toggleDropdown();
    }

    function createEventGroup() {
        try {
            // Create a new Event Group using issues
            let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
            let evg = new _EventGroup();
            evg.name = `${selectedFilter} Issues EventGroup`;
            evg.maxCps = eventGroupDefaults.maxCps || 9999;
            evg.maxWpm = eventGroupDefaults.maxWpm || 9999;
            evg.maxChars = eventGroupDefaults.maxChars || 9999;
            evg.maxLines = eventGroupDefaults.maxLines || 9999;
            evg.overlap = eventGroupDefaults.overlap;
            evg.illegalChars = eventGroupDefaults.illegalChars;

            const seenIssueIds = new Set();

            filteredIssues.forEach(issue => {
                issue.eventIds.forEach(eventId =>{
                    if (!seenIssueIds.has(eventId)) {
                    const issueEvent = selectedEventGroup.events.find(event => event.id === eventId);
                        if (issueEvent) {
                            evg.events.push(issueEvent);
                            seenIssueIds.add(eventId);
                        }
                    }
                })
            });

            if (evg.events.length === 0) {
                throw new Error('No events were added to the new event group');
            }

            $eventGroupState = [...$eventGroupState, evg];

            toast.push("A new event group has been created using the selected issue list", {classes: ['toast-success']});

            /* Store in History */
            historyState.insert({
                name: "insert Event Group", //action name
                eventGroup: $projectState.selected,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });

        } catch(err) {
            console.error("Error creating event group:", err);
            toast.push(`Error creating event group: ${err.message}`, {classes: ['toast-error']});
        }
        toggleDropdown();
    }
    
    async function openIssue(issueToOpen) {
        selectedIssue = issueToOpen;
        idOpen = true;

        console.log('Opening issue:', JSON.stringify(issueToOpen, null, 2));

        // Find the event group that matches issueToOpen.eventGroupId
        let matchingEventGroup = null;
        let matchingEventGroupIndex = -1;

        for (let i = 0; i < $eventGroupState.length; i++) {
            if ($eventGroupState[i] && $eventGroupState[i].id === issueToOpen.eventGroupId) {
                matchingEventGroup = $eventGroupState[i];
                matchingEventGroupIndex = i;
                break;
            }
        }

        if (!matchingEventGroup) {
            throw new Error(`No matching event group found for eventGroupId: ${issueToOpen.eventGroupId}`);
        }

        console.log("Updating event group");
        selectedEventGroup = matchingEventGroupIndex;
        $projectState.selected = matchingEventGroupIndex;

        if (!Array.isArray(matchingEventGroup.events)) {
            throw new Error('Matching event group does not have an events array');
        }

        const eventIndices = issueToOpen.eventIds
            .filter(eventId => eventId != null)
            .map(eventId => {
                const index = matchingEventGroup.events.findIndex(event => event.id === eventId);
                if (index === -1) {
                    console.warn(`No matching event found for eventId: ${eventId}`);
                }
                return index;
            })
            .filter(index => index !== -1);

        if (eventIndices.length === 0) {
            console.warn("No matching events found for the issue");
            return;
        }

        // Select all associated events
        matchingEventGroup.selected = eventIndices;

        // Wait for the DOM to update
        await tick();

        // Scroll to the first event
        const firstEventIndex = eventIndices[0];
        try {
            const eventListElement = document.getElementById("EventList");
            if (eventListElement) {
                eventListElement.scrollTo(0, firstEventIndex*230);
            } else {
                console.warn("Event list element not found");
            }
        } catch (err) {
            console.error("Error scrolling to event:", err);
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, firstEventIndex*230);  
        }

        // Select all associated events
        $eventGroupState[$projectState.selected].selected = eventIndices;

        if ($lockState.video && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events[firstEventIndex] && $eventGroupState[$projectState.selected].events[firstEventIndex].start >= 0){
            player.currentTime = $eventGroupState[$projectState.selected].events[firstEventIndex].start;
        }

        // Update issue details
        issueName = issueToOpen.name || '';
        issueType = issueToOpen.type || '';
        issueDescription = issueToOpen.description || '';
        issuePriority = issueToOpen.priority || '';
        issueStatus = issueToOpen.status || '';
    }

    function closeIssue() {
        idOpen = false;
        selectedIssue = null;
        issueName = '';
        issueType = 'technical';
        issueDescription = '';
        issuePriority = 'P1';
        issueStatus = 'open';
    }
    
    function toggleDropdown() {
        isDropdownOpen = !isDropdownOpen;
    }

    // ----- CRUD -----
    function createIssue() {
        if(eventsSelected.length > 1){
            console.log(eventsSelected.length);
        }

        let eventIdsSelected = [];
        eventsSelected.forEach(event => {
            eventIdsSelected.push(event.id);
        })

        console.log(typeof(eventsSelected));

        console.log(eventsSelected);

        const newIssue = new _Issue({
            name: issueName || 'Untitled Issue',
            priority: issuePriority || 'P3',
            status: issueStatus || 'Open',
            type: issueType || 'Other',
            description: issueDescription || '',
            eventGroupId: selectedEventGroup.id,
            eventIds: eventIdsSelected,
        });

        issueState.update(issues => [...issues, newIssue]);

        console.log(`Creating new issue: ${newIssue.id} with events: ${eventIdsSelected}`);
        
        // Log the full issue object for debugging
        console.log('New issue object:', JSON.stringify(newIssue, null, 2));

        closeIssue();

        return newIssue;
    }

    function updateIssue() {
        console.log("Attempting to update issue:", selectedIssue);
        if (!selectedIssue || !selectedIssue.id) {
            console.error("No valid issue selected for update");
            return;
        }

        const updatedIssue = {
            ...selectedIssue,
            name: issueName || selectedIssue.name,
            priority: issuePriority || selectedIssue.priority,
            status: issueStatus || selectedIssue.status,
            type: issueType || selectedIssue.type,
            description: issueDescription || selectedIssue.description
        };

        issueState.update(issues => {
            const updatedIssues = issues.map(issue => 
                issue.id === selectedIssue.id ? updatedIssue : issue
            );
            
            if (JSON.stringify(issues) === JSON.stringify(updatedIssues)) {
                console.warn("No changes detected in the update operation");
            }
            
            return updatedIssues;
        });

        console.log("Issue updated:", updatedIssue);
        closeIssue();
    }

    function removeIssue() {
        console.log("Deleting issue:", selectedIssue);
        if (selectedIssue) {
            console.log("selected")
            issueState.update(issues => {
                const updatedIssues = issues.filter(issue => issue.id !== selectedIssue.id);
                console.log("Updated issue state after deletion:", updatedIssues);
                return updatedIssues;
            });
            closeIssue();
        } else {
            console.error("No issue selected for deletion");
        }
    }

</script>

<div class="row g-2">
    <div class="col">
        <form>
            <div class="mb-2">
                <input class="form-control form-control-sm" type="text" placeholder="Issue name" bind:value={issueName}>
            </div>
            <div class="row mb-1 g-1">
                <div class="col">
                    <select class="form-select form-select-sm" bind:value={issueType}>
                        <option value="technical">Technical</option>
                        <option value="content">Content</option>
                        <option value="grammar">Spelling/Grammar</option>
                        <option value="feedback">Feedback</option>
                        <option value="other">Other</option>
                    </select></div>
                <div class="col">
                    <select class="form-select form-select-sm" name="Priority" bind:value={issuePriority}>
                        <option value="P1">P1</option>
                        <option value="P2">P2</option>
                        <option value="P3">P3</option>
                        <option value="P4">P4</option>
                        <option value="P5">P5</option>
                    </select>
                </div>
            </div>
            <div class="mb-1">
                <textarea 
                    class="form-control form-control-sm" rows="4" placeholder="Description"
                    bind:value={issueDescription}
                ></textarea>
            </div>
            <div class="mb-1">
                <select class="form-select form-select-sm" name="Status" bind:value={issueStatus}>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="in progress">In Progress</option>
                    <option value="review required">Review Required</option>
                    <option value="in review">In Review</option>
                    <option value="supervisor review">Supervisor Review</option>
                </select>
            </div>
            {#if (!idOpen)}
                <button class="btn btn-primary w-100" type="button" on:click={() => createIssue()} disabled={!event}>Save</button>
            {:else}
                <button class="btn btn-warning w-100" type="button" on:click={() => updateIssue()}>Update</button>
            {/if}
        </form>
    </div>
    <div class="col">
        <div class="mb-1">
            <div class="input-group input-group-sm">
                <span class="input-group-text">
                    <i class="bi bi-filter"></i>
                </span>
                <select class="form-select" bind:value={selectedFilter}>
                    <option value='all'>All</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="in progress">In Progress</option>
                    <option value="review required">Review Required</option>
                    <option value="in review">In Review</option>
                    <option value="supervisor review">Supervisor Review</option>
                </select>
            </div>
        </div>
        <div>
            <select class="w-100" multiple="true" size="6" title="Status Filter">
                {#each filteredIssues as issue (issue)}
                    <option style="background-color: {colorMap.background[issue.status]}; color: {colorMap.color[issue.type]};" value="{issue.id}" on:click={() => openIssue(issue)}>
                        {issue.name || ''} | {issue.priority || ''}
                    </option>
                {/each}
            </select>
        </div>
        <div class="btn-group btn-group-sm mt-2" role="group">
            <button class="btn btn-primary" type="button" on:click={() => closeIssue()} title="add" disabled={!idOpen}>
                <i class="bi bi-plus-lg"></i>
            </button>
            <button class="btn btn-primary" type="button" title="save" disabled={!idOpen} on:click={() => removeIssue()}>
                <i class="bi bi-trash"></i>
            </button>
            <div class="dropdown btn-group show" role="group">
                <button class="btn btn-secondary dropdown-toggle" on:click={toggleDropdown} type="button" aria-expanded={isDropdownOpen}>
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <div class="dropdown-menu" class:show={isDropdownOpen} data-bs-popper="none">
                    <a class="dropdown-item"  href="#!/" on:click={()=>exportIssues()}>Export to csv...</a>
                    <a class="dropdown-item" href="#!/" on:click={()=>createEventGroup()}>Create event group</a>
                </div>
            </div>
        </div>
    </div>
</div>