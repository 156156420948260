import { writable } from 'svelte/store';
function createHistoryStore() {
    const { subscribe, update, set } = writable({
        actions : [],
        position: 0
    });

    return {
        subscribe,
        undo: () => update(historyState => {
            if (historyState.position > 1){
                historyState.position--;
            }

            return historyState;
        }),
        redo: () => update(historyState => {
            if (historyState.position < historyState.actions.length) {
                historyState.position++;
            }
            return historyState;
        }),
        insert: (action) => update(historyState => {
            /* Remove any undo actions from the history stack */
            if (historyState.position < historyState.actions.length) {
                historyState.actions = historyState.actions.slice(0, historyState.position);
            }

            /* Limit stack to 25+1 undos */
            historyState.actions = [...historyState.actions.splice(-25), action];
            historyState.position = historyState.actions.length;

            return historyState;
        }),
        reset: () => set({
            actions : [],
            position: 0
        })
    };
}

export const historyState = createHistoryStore();