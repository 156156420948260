import _nodeTc from "node-timecodes";
import _smpteTc from "smpte-timecode";
const smpteTc = _smpteTc;
const nodeTc = _nodeTc;
export default {
  parseTcToSec: function (tc, frameRate, useClockTime = false) {
    if (tc.match(/\d{2}:\d{2}:\d{2}:\d{2}|\d{2}:\d{2}:\d{2};\d{2}/)) {
      /* TC SMPTE */
      return useClockTime ? this.tcClockToSec(tc, frameRate) : this.tcToSec(tc, frameRate);
    } else if (tc.match(/\d{2}:\d{2}:\d{2}\.\d{2,3}|\d{2}:\d{2}:\d{2}\,\d{2,3}/)) {
      /* TC MS */
      return this.tcMsToSec(tc);
    } else if (tc.match(/\d+f/i)) {
      return this.framesToSec(tc.match(/\d+/)[0], frameRate);
    }
  },
  tcToSec: function (tc, frameRate, dropFrame) {
    if (dropFrame === undefined) {
      dropFrame = true;
    }
    let sec = nodeTc.toSeconds(tc.replace(/;|\./g, ":"), frameRate);
    sec = frameRate == 23.976 || frameRate == 29.97 && !dropFrame ? sec * 1.001 : sec;
    return sec;
  },
  tcClockToSec: function (tc, frameRate) {
    return nodeTc.toSeconds(tc.replace(/;|\./g, ":"), frameRate);
  },
  tcTicksToSec: function (tc) {
    let sec;
    let tcParts = tc.trim().replace(/:/gm, " ").split(" ");
    sec = tcParts[0] * 3600; //Hours
    sec += tcParts[1] * 60; //Minutes
    sec += parseInt(tcParts[2]); //Seconds

    sec += Number(Math.fround(tcParts[3] * 4 / 1000).toFixed(3)); ////HH:MM:SS:TTT A “tick” is defined as 4 msec and has a range of 0 to 249. 

    return sec;
  },
  secToTicksSec: function (sec) {
    let tcMs = this.secToTcMs(sec);
    let ms = tcMs.split(",")[1];
    let ticks = parseInt(ms / 4);
    return tcMs.split(",")[0] + ":" + ticks.toString().padStart(3, "0");
  },
  tcFractionToSec: function (tc) {
    let sec;
    let tcParts = tc.trim().replace(/:/gm, " ").split(" ");
    sec = tcParts[0] * 3600; //Hours
    sec += tcParts[1] * 60; //Minutes
    sec += parseFloat(tcParts[2]); //Seconds
    return Math.fround(sec);
  },
  secToTc: function (sec, frameRate, dropFrame) {
    if (dropFrame === undefined) {
      dropFrame = true;
    }

    //console.log("sec:", sec, "frameRate:", frameRate, "dropFrame:", dropFrame);

    let tc = nodeTc.fromSeconds(frameRate == 23.976 || frameRate == 29.97 && !dropFrame ? parseFloat(sec * 0.999001).toFixed(6) : parseFloat(sec).toFixed(6), {
      frameRate: frameRate
    });
    if (frameRate === 29.97 && dropFrame && /\d\d:[0-9][1-9]:00:0[0-1]/.test(tc)) {
      //console.log(`${tc} is incorrect. Fixing`);
      tc = tc.slice(0, -2) + "02";
    } //else if the frame rate is 25 and the timecode ends in 25 set the timecode to end in 24
    else if (frameRate === 25 && tc.slice(-2) > 24) {
      tc = tc.slice(0, -2) + "24";
    }

    //console.log(tc);
    return tc;
  },
  secToTcClock: function (sec, frameRate, dropFrame = true) {
    let tc = nodeTc.fromSeconds(parseFloat(sec).toFixed(6), {
      frameRate: frameRate
    });
    if (frameRate === 29.97 && dropFrame && /\d\d:[0-9][1-9]:00:0[0-1]/.test(tc)) {
      //console.log(`${tc} is incorrect. Fixing`);
      tc = tc.slice(0, -2) + "02";
    }
    return tc;
  },
  secToTcMs: function (sec) {
    if (sec < 0) {
      /* WebCargo Code Start */
      // sec = 0;
      /* WebCargo Code End */

      //comment out when using WebCargo:
      throw new Error(`Negative timecode detected at ${sec} seconds`);
    }
    return this.formatTcMs(nodeTc.fromSeconds(parseFloat(sec).toFixed(6), {
      ms: true
    }));
  },
  secToFrames: function (sec, frameRate) {
    return Math.round(sec * frameRate);
  },
  tcToFrames: function (tcString, frameRate, dropFrame) {
    let tc = new smpteTc(tcString, frameRate, dropFrame);
    return tc.frameCount;
  },
  frameToTc: function (frames, frameRate, dropFrame) {
    let tc = new smpteTc(frames, frameRate, dropFrame);
    return tc.toString();
  },
  tcAdd: function (tcString, frameRate, dropFrame, value) {
    let tc = new smpteTc(tcString, frameRate, dropFrame);
    tc.add(value);
    return tc.toString();
  },
  tcSubtract: function (tcString, frameRate, dropFrame, value) {
    let tc = new smpteTc(tcString, frameRate, dropFrame);
    tc.subtract(value);
    return tc.toString();
  },
  msToFrame: function (ms, frameRate) {
    return Math.floor(ms / 1000 * frameRate);
  },
  tcMsToSec: function (tcMs) {
    let sec;
    let tcParts = (tcMs + "000").substring(0, 12).trim().replace(/,|\.|;|:/gm, " ").split(" ");
    sec = tcParts[0] * 3600; //Hours
    sec += tcParts[1] * 60; //Minutes
    sec += parseInt(tcParts[2]); //Seconds
    sec += tcParts[3] / 1000; //Ms
    return sec;
  },
  //For quickTimeText format
  tcQuickTimeToSec: function (tc, timeScaleValue) {
    let sec;
    let tcParts = tc.trim().replace(/,|\.|;|:/gm, " ").split(" ");
    sec = tcParts[0] * 3600; //Hours
    sec += tcParts[1] * 60; //Minutes
    sec += parseInt(tcParts[2]); //Seconds
    sec += tcParts[3] / timeScaleValue; //timescale 
    return sec;
  },
  centisecToSeconds: function (tcFr) {
    let sec;
    let tcParts = tcFr.trim().replace(/,|\.|;|:/gm, " ").split(" ");
    sec = tcParts[0] * 3600; //Hours
    sec += tcParts[1] * 60; //Minutes
    sec += parseInt(tcParts[2]); //Seconds
    sec += parseInt(tcParts[3]) / 100; //centisec

    return sec;
  },
  framesToMs: function (frames, frameRate) {
    return Math.floor(frames / frameRate * 1000);
  },
  framesToSec: function (frames, frameRate = 29.97 /* Put in a default framerate if one is not provided*/) {
    //console.log(frames,frameRate, Math.fround(frames / frameRate));
    return Math.fround(frames / frameRate);
  },
  framesToTc: function (frames, frameRate, dropFrame = true) {
    return smpteTc(frames, frameRate, dropFrame).toString();
  },
  createTc: function (tc, frameRate, dropFrame = false) {
    try {
      // console.log(tc, frameRate, dropFrame);
      return smpteTc(tc.replace(";", ":"), frameRate, dropFrame);
    } catch (e) {
      console.log(e.message, tc, frameRate, dropFrame);
      if (dropFrame && tc.slice(-2) == "00") {
        return smpteTc(tc.slice(0, 9) + (frameRate > 30 ? "04" : "02"), frameRate, dropFrame);
      } else {
        try {
          let tcNonDrop;
          if (tc.slice(-2) == "00" && dropFrame) {
            return smpteTc(tc.slice(0, 8) + ";05", frameRate, dropFrame);
          } else if (parseFloat(frameRate) === 59.94) {
            tcNonDrop = smpteTc(tc, 60, false);
            tcNonDrop.add(4);
          } else {
            tcNonDrop = smpteTc(tc, 30, false);
            tcNonDrop.add(2);
          }
          return smpteTc(tcNonDrop.toString(), frameRate, dropFrame);
        } catch (e) {
          throw new Error("Invalid Timecode. " + e.message);
        }
      }
    }
  },
  formatTcMs: function (tcMs) {
    return tcMs.substring(0, 8) + "," + tcMs.substring(9, 12);
  },
  formatTimecodeString: function (tc, dropFrame, encodeOption = "auto") {
    if (encodeOption === "auto") {
      if (dropFrame) {
        return tc.substring(0, 8) + ";" + tc.substring(9);
      } else {
        return tc.replace(/;/g, ":");
      }
    } else if (encodeOption === "colon") {
      return tc.replace(/;/g, ":");
    } else {
      return tc.substring(0, 8) + ";" + tc.substring(9);
    }
  },
  tcValidate: function (tc, frameRate, dropFrame) {
    tc = tc.trim().replace(/;/g, ":").toUpperCase();
    try {
      if (/^\d\d:\d\d:\d\d:\d\d$/g.test(tc)) {
        return tc;
      } else if (/^\d\d\d\d\d\d\d\d$/g.test(tc)) {
        return this.createTc(tc.match(/.{1,2}/g).join(":"), frameRate, dropFrame).toString();
      } else if (/^\d+[HMSF]$/gmi.test(tc)) {
        if (tc.indexOf("H") > -1) {
          var hours = parseInt(tc.split("H")[0]);
          if (hours > 23) {
            return "23:00:00:00";
          } else if (hours < 10) {
            return "0" + hours + ":00:00:00";
          } else {
            return hours + ":00:00:00";
          }
        } else if (tc.indexOf("M") > -1) {
          var minutes = parseInt(tc.split("M")[0]);
          if (minutes > 59) {
            return "00:59:00:00";
          } else if (minutes < 10) {
            return "00:0" + minutes + ":00:00";
          } else {
            return "00:" + minutes + ":00:00";
          }
        } else if (tc.indexOf("S") > -1) {
          var seconds = parseInt(tc.split("S")[0]);
          if (seconds > 59) {
            return "00:00:59:00";
          } else if (seconds < 10) {
            return "00:00:0" + seconds + ":00";
          } else {
            return "00:00:" + seconds + ":00";
          }
        } else {
          var frames = parseInt(tc.split("F")[0]);
          if (frames > parseInt(frameRate)) {
            return "00:00:00:" + parseInt(frameRate);
          } else if (frames < 10) {
            return "00:00:00:0" + frames;
          } else {
            return "00:00:00:" + frames;
          }
        }
      } else if (/^\d+$/g.test(tc)) {
        var seconds = tc / frameRate;
        return nodeTc.fromSeconds(seconds, {
          frameRate: frameRate
        });
      } else {
        return "00:00:00:00";
      }
    } catch (e) {
      return "";
    }
  },
  //xml formats time parsing
  parseColonTimeWithFrames_: function (tc, rateInfo, regex) {
    const results = regex.exec(tc);
    const hours = Number(results[1]);
    const minutes = Number(results[2]);
    let seconds = Number(results[3]);
    let frames = Number(results[4]);
    const subframes = Number(results[5]) || 0;
    frames += subframes / rateInfo.subFrameRate;
    seconds += frames / rateInfo.frameRate;
    return seconds + minutes * 60 + hours * 3600;
  },
  parseTimeFromRegex_: function (tc, regex) {
    const results = regex.exec(tc);
    if (results == null || results[0] == '') {
      return null;
    }
    // This capture is optional, but will still be in the array as undefined,
    // in which case it is 0.
    const hours = Number(results[1]) || 0;
    const minutes = Number(results[2]) || 0;
    const seconds = Number(results[3]) || 0;
    const milliseconds = Number(results[4]) || 0;
    return milliseconds / 1000 + seconds + minutes * 60 + hours * 3600;
  },
  parseFramesTime_: function (tc, rateInfo, regex) {
    // 75f or 75.5f
    const results = regex.exec(tc);
    const frames = Number(results[1]);
    return frames / rateInfo.frameRate;
  },
  parseTickTime_: function (tc, rateInfo, regex) {
    // 50t or 50.5t
    const results = regex.exec(tc);
    const ticks = Number(results[1]);
    return ticks / rateInfo.tickRate;
  }
};