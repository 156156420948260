<script>
import {
    timelineZoomState
} from '@app/store/timelineZoomStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    audioWaveformState
} from '@app/store/audioWaveformStore.js';
import { slide } from 'svelte/transition';
import { quintOut } from 'svelte/easing';
import TrackLabels from './TrackLabels.svelte';
import TimelineTrack from './TimelineTrack.svelte';
import AudioTrack from './AudioTrack.svelte';
import TimelineMarkers from './TimelineMarkers.svelte';
import Playhead from './Playhead.svelte';
import debounce from "just-debounce-it";
export let timelineHeight = 125;

let scrollPosition = 0;
let timer;
let width = window.innerWidth;
let scaleMultiplier = width / $timelineZoomState; //1 sec = how many pixels?
let offsetStart;
let scrollStart;
let tracksWrapperElement;
let trackHeight = 95;

const updateAmplitude = debounce(async (e) => {
    //console.log("Updating Amplitude...")
    //console.log(e);
    $audioWaveformState.zoom = e.target.value;
}, 500);

const updateMultiplier = debounce(async (e) => {
    //console.log("Updating multiplier...")
    //console.log(e);
    $audioWaveformState.multiplier = e.target.value;
}, 500);

/* Debounce function used to delay having to recheck the events shown */
const updateScrollPosition = e => {
    clearTimeout(timer);
    timer = setTimeout(() => {
        scrollPosition = e.target.scrollLeft;
    }, 50);
}

function updateScrollPositionStart(e) {
    let img = new Image();
    e.dataTransfer.setDragImage(img, 0, 0);
    offsetStart = e.clientX;
    scrollStart = scrollPosition;
}

function updateScrollPositionByRuler(e) {
    if (e.screenX !== 0) {
        tracksWrapperElement.scrollTo(scrollStart - (e.clientX - offsetStart), 0);
    }
}

function recalculateTrackHeight(){
    //console.log("Recalculating Track Height...")
    trackHeight = (timelineHeight-30) / (1 + ($eventGroupState[$projectState.compare] ? 1 : 0));
}

function recalculateScaleMultiplier(w, z){
    try {
        console.log("Recalculating Scale Multiplier...");
        scaleMultiplier = w / z;
        // update player current time to selected event start;
        if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 && player && $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] && $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start > 0){
            player.currentTime = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start;
        }
    } catch (error) {
        console.error(error);
    }    
}

$: recalculateScaleMultiplier(width, $timelineZoomState);
$: recalculateTrackHeight($projectState.compare, timelineHeight);
</script>

<svelte:window bind:innerWidth={width}/>
    <div id="Timeline" style="height:{timelineHeight}px" class="container-fluid p-0 shadow" transition:slide="{{duration: 300, easing: quintOut, axis: 'y'}}">
        <div id ="TimelineToolbar" class="row bg-secondary bg-opacity-25 border-bottom border-secondary" style="height: 20px;">
            <div class="col align-items-center justify-content-start d-flex h-100">
                <button type="button" class="btn btn-link btn-sm  text-decoration-none" title="Refresh Timeline" on:click="{() => recalculateTrackHeight()}"><i class="bi bi-arrow-clockwise"></i></button>
            </div>
            <div class="col align-items-center justify-content-end d-flex h-100">
                {#if $eventGroupState[$projectState.compare]}
                    <button type="button" class="btn btn-link btn-sm text-dark text-decoration-none" title="Close Track 2" on:click="{() => $projectState.compare = false}">x Track 2</button>
                {/if}
                <button type="button" class="btn btn-link btn-sm text-{$timelineZoomState === 10 ? 'success' : 'dark'}" title="Zoom 10 seconds" on:click="{() => $timelineZoomState = 10}">10s</button>
                <button type="button" class="btn btn-link btn-sm text-{$timelineZoomState === 15 ? 'success' : 'dark'}" title="Zoom 15 seconds" on:click="{() => $timelineZoomState = 15}">15s</button>
                <button type="button" class="btn btn-link btn-sm text-{$timelineZoomState === 30 ? 'success' : 'dark'}" title="Zoom 30 seconds" on:click="{() => $timelineZoomState = 30}">30s</button>
                <button type="button" class="btn btn-link btn-sm text-{$timelineZoomState === 45 ? 'success' : 'dark'}" title="Zoom 45 seconds" on:click="{() => $timelineZoomState = 45}">45s</button>
                <button type="button" class="btn btn-link btn-sm text-{$timelineZoomState === 60 ? 'success' : 'dark'}" title="Zoom 60 seconds" on:click="{() => $timelineZoomState = 60}">60s</button>
                <span class=""><i class="bi bi-soundwave"></i></span>
                <input id="amplitudeInput" on:input={updateAmplitude} type="number" value="5" min="0.25" max="9999" title="Adjust the amplitude of waveform" class="border-0 bg-light text-dark py-0"/>

                <span class=""><i class="bi bi-arrow-bar-left"></i><i class="bi bi-arrow-bar-right"></i></span>
                <input id="multiplierInput" on:input={updateMultiplier} type="number" value="1" min="0.9700" max="1.0100" step="0.00001" title="Waveform video alignment" class="border-0 bg-light text-dark py-0"/>
            </div>
        </div>
        <div class="row g-0 TimelineWrapper">
            {#if $eventGroupState[$projectState.selected]}
                <TrackLabels height={trackHeight} showCompareTrack={$eventGroupState[$projectState.compare] ? true : false}></TrackLabels>
            {/if}
            <div class="col m-0 p-0 bg-light position-relative" id="TracksWrapper" bind:this={tracksWrapperElement} on:scroll={updateScrollPosition}>
                {#if $eventGroupState[$projectState.compare]}
                    <TimelineTrack eventGroupIndex={$projectState.compare} scaleMultiplier={scaleMultiplier} scrollPosition={scrollPosition} width="{width}" height="{trackHeight}" compare={true}></TimelineTrack>
                {/if}
                {#if $eventGroupState[$projectState.selected]}
                    <TimelineTrack eventGroupIndex={$projectState.selected} scaleMultiplier={scaleMultiplier} scrollPosition={scrollPosition} width="{width}" height="{trackHeight}"></TimelineTrack>
                {/if}
                <!-- {#if ($projectState.media.storage === "Local Storage" || $projectState.media.storage === "Cloud Storage" || $projectState.media.storage === "Proxy RT")  && $playerState.duration !== false}
                    <AudioTrack scaleMultiplier={scaleMultiplier} scrollPosition={scrollPosition} width="{width}" height="{trackHeight}" on:svgDataUpdate="{recalculateTrackHeight}"></AudioTrack>
                {/if} -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div id="TimelineRuler" style="height: 10px; width: {$playerState.duration*scaleMultiplier}px;" draggable="true" on:dragstart="{updateScrollPositionStart}" on:drag="{updateScrollPositionByRuler}">                    
                </div>
                {#if $eventGroupState[$projectState.selected]}
                        <TimelineMarkers scaleMultiplier={scaleMultiplier} scrollPosition={scrollPosition} width="{width}"></TimelineMarkers>
                {/if}
                <Playhead scaleMultiplier={scaleMultiplier}></Playhead>
            </div>
        </div>
    </div>
<style>
button, input, span {
    font-size: 0.65vw;
    padding: 1px 4px;
}

#TracksWrapper {
    overflow-y: hidden;
    overflow-x: scroll;
}

#TimelineRuler {
    min-width: 100%;
    height: 10px;
    background-image: url("../assets/img/Ruler.jpg");
    background-size: contain;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: grab;
}
</style>
