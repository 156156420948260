<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    toast
} from '@zerodevx/svelte-toast';
import {
    authState
} from '@app/store/authStore.js';
import {
    fade
} from 'svelte/transition';
import {
    saveAs
} from 'file-saver';
import Swal from 'sweetalert2';
import throttle from 'just-throttle';
import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    Table,
    TableRow,
    TableCell,
    Header,
    PageNumber,
    NumberFormat,
    HeadingLevel,
    AlignmentType,
    SectionType,
    WidthType,
    ShadingType,
    PageOrientation
} from "docx";

import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';

/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

let exporting = false,
    documentTitle = $projectState.name,
    ccslTemplate = localStorage.getItem("cc-ccsl-template") || "default",
    doc,
    dialogueEventGroupId = $eventGroupState.length > 0 ? $eventGroupState[0].id : null,
    spottingEventGroupId = $eventGroupState.length > 0 ? $eventGroupState[0].id : null;

if ($authState.status === "in_trial") {
    showTrialWarning();
}

function showTrialWarning() {
    Swal.fire({
        titleText: "Trial Mode",
        text: "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Activate Account',
        denyButtonText: 'Continue Trial',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            denyButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            activateSubscription();
            return true;
        } else {
            return false;
        }
    }).then((res) => {
        if (res) {
            console.log(res);
            showRestartNotification();
        }

        return true;
    }).catch(err => {
        console.log(err);
        console.log(err.message)
    });
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, {
    leading: true
});

function showRestartNotification() {
    Swal.fire({
        titleText: "Restart Required",
        text: "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-light'
        }
    }).then(res => {
        console.log(res);
    }).catch(err => {
        console.log(err);
        console.log(err.message)
    });
}

async function exportCcsl() {
    try {
        if (exporting) return;
        exporting = true;
        let tableRows = [];
        /* Write the Table Headers */
        if (ccslTemplate === "condensed") {
            tableRows.push(new TableRow({
                tableHeader: true,
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "SH #",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "TC",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "SCENE DESCRIPTION",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "TI #",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "IN",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "OUT",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "DURA",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "TITLES",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })],
                        shading: {
                            fill: "d1e0f0",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    })
                ]
            }));
        } else if (ccslTemplate === "style5A"){
            tableRows.push(new TableRow({
                tableHeader: true,
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "Sc. #",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.CENTER,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "TC",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.CENTER,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "SHOT DESCRIPTION",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.CENTER,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "DIALOGUE",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.CENTER,
                        })]
                    })
                ]
            }));
        } else if (ccslTemplate === "technicolor"){
            tableRows.push(new TableRow({
                tableHeader: true,
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "#",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "CONTINUITY/DIALOGUE",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "TITLE",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "TIMECODE",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })]
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children : [
                                new TextRun({
                                    text: "SPOTTING",
                                    bold: true                                
                                })
                            ],                            
                            alignment: AlignmentType.LEFT,
                        })]
                    })
                ]
            }));
        } else if (ccslTemplate === "default") {
            tableRows.push(new TableRow({
                tableHeader: true,
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            text: "COMBINED CONTINUITY & DIALOGUE",
                            alignment: AlignmentType.CENTER,
                        })],
                        columnSpan: 4,
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "SPOTTING LIST",
                            alignment: AlignmentType.CENTER,
                        })],
                        columnSpan: 4,
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    })
                ]
            }));

            tableRows.push(new TableRow({
                tableHeader: true,
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            text: "Shot #",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Shot Timing",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Shot Description",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Dialogue",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Title #",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Timecode In",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Timecode Out",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Duration",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            text: "Dialogue With Speaker Id",
                            alignment: AlignmentType.CENTER,
                        })],
                        shading: {
                            fill: "dce9f7",
                            type: ShadingType.CLEAR,
                            color: "auto",
                        }
                    })
                ]
            }));
        }

        let fileName = `${documentTitle} - CCSL.docx`;
        //Subtitle Event Group
        let dialogueEventGroup = $eventGroupState.find(eventGroup => eventGroup.id === dialogueEventGroupId);
        //Shot Change Event Group
        let spottingEventGroup = $eventGroupState.find(eventGroup => eventGroup.id === spottingEventGroupId);

        if (!dialogueEventGroup || !spottingEventGroup) {
            throw new Error("Event group not found");
        }

        dialogueEventGroup = JSON.parse(JSON.stringify(dialogueEventGroup));
        spottingEventGroup = JSON.parse(JSON.stringify(spottingEventGroup));

        if ($authState.status === "in_trial") {
            dialogueEventGroup = replaceTrialText(dialogueEventGroup);
            spottingEventGroup = replaceTrialText(spottingEventGroup);
        }

        dialogueEventGroup.events.forEach((event, index, events) => {
            let tableCells = [];
            console.log("----------------------");
            console.log(index,": ", convertToPlainText(event.text));

            // Fine all events in spotting event group that have a start time between the start and end of the current event.
            let spottingEvents = spottingEventGroup.events.filter(spottingEvent => spottingEvent.start >= event.start && spottingEvent.start <= event.end);

            console.log("Spotting Events: ", spottingEvents.length);

            if (spottingEvents.length > 0) {
                // output the spotting events index, start time, end time, duration, and text.
                if (ccslTemplate === "condensed") {
                    tableCells = [
                        new TableCell({                            
                            children: [new Paragraph({
                                children : [
                                    new TextRun({
                                        text: (index + 1).toString(),
                                        bold: true                                
                                    })
                                ],                            
                                alignment: AlignmentType.LEFT,
                            })],
                            rowSpan: spottingEvents.length,
                            shading: {
                                fill: "dce9f7",
                                type: ShadingType.CLEAR,
                                color: "auto",
                            }
                        }),
                        new TableCell({
                            children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))],
                            rowSpan: spottingEvents.length
                        }),
                        new TableCell({
                            children: [new Paragraph(event.notes), new Paragraph(" "), new Paragraph(convertToPlainText(event.text))],
                            rowSpan: spottingEvents.length
                        })
                    ];
                } else if (ccslTemplate === "style5A") {
                    //Style 5A doesn't care about spotting events but we have this here anyways.
                    tableCells = [
                        new TableCell({                            
                            children: [new Paragraph({
                                children : [
                                    new TextRun({
                                        text: (index + 1).toString()  
                                    })
                                ],                            
                                alignment: AlignmentType.LEFT,
                            })]
                        }),
                        new TableCell({
                            children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))]
                        }),
                        new TableCell({
                            children: [new Paragraph(event.notes)]
                        }),
                        new TableCell({
                            children: [new Paragraph(convertToPlainText(event.text))]
                        })
                    ];
                } else if (ccslTemplate === "default") {
                    tableCells = [
                        new TableCell({
                            children: [new Paragraph((index + 1).toString())],
                            rowSpan: spottingEvents.length,
                        }),
                        new TableCell({
                            children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))],
                            rowSpan: spottingEvents.length,
                        }),
                        new TableCell({
                            children: [new Paragraph(event.notes)],
                            rowSpan: spottingEvents.length,
                        }),
                        new TableCell({
                            children: [new Paragraph(convertToPlainText(event.text))],
                            rowSpan: spottingEvents.length,
                        })
                    ];
                } else if (ccslTemplate === "technicolor") {
                    tableRows.push(new TableRow({
                        children: [
                            //Index + 1 (ID)
                            new TableCell({
                                children: [new Paragraph((index + 1).toString())],
                            }),
                            // Start Time + Notes
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))].concat(event.notes.split("\n").map(l => {return new Paragraph(l)}))
                            }),
                            // Blank
                            new TableCell({
                                children: [new Paragraph("")],
                            }),
                            // Blank
                            new TableCell({
                                children: [new Paragraph("")],
                            }),
                            // Blank
                            new TableCell({
                                children: [new Paragraph("")],
                            })
                        ]
                    }));
                }
                
                spottingEvents.forEach((spottingEvent, spIndex) => {
                    console.log("Writing ", spIndex,": ", convertToPlainText(spottingEvent.text));
                    // Find the index of the current spotting event in the spotting event group.
                    let spottingEventIndex = spottingEventGroup.events.findIndex(spEvent => spEvent.id === spottingEvent.id);
                    //console.log(spottingEventIndex, tcLib.secToTc(spottingEvent.start, $projectState.frameRate), tcLib.secToTc(spottingEvent.end, $projectState.frameRate), tcLib.secToTc(spottingEvent.end - spottingEvent.start, $projectState.frameRate), convertToPlainText(spottingEvent.text));
                    

                    if (ccslTemplate === "condensed") {
                        tableCells = [...tableCells, ...[
                            new TableCell({
                                children: [new Paragraph((spottingEventIndex + 1).toString())],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(spottingEvent.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(spottingEvent.end) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(spottingEvent.end - spottingEvent.start, $projectState.frameRate, $projectState.dropFrame))],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(convertToPlainText(spottingEvent.text))],
                                rowSpan: 1
                            })
                        ]];
                    } else if (ccslTemplate === "style5A") {
                       /* Nothing to do here. */
                    } else if (ccslTemplate === "default"){
                        tableCells = [...tableCells, ...[
                            new TableCell({
                                children: [new Paragraph((spottingEventIndex + 1).toString())],
                                rowSpan: 1,
                                shading: {
                                    fill: "dce9f7",
                                    type: ShadingType.CLEAR,
                                    color: "auto",
                                }
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(spottingEvent.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(spottingEvent.end) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(spottingEvent.end - spottingEvent.start, $projectState.frameRate, $projectState.dropFrame))],
                                rowSpan: 1
                            }),
                            new TableCell({
                                children: [new Paragraph(convertToPlainText(spottingEvent.text))],
                                rowSpan: 1
                            })
                        ]];
                    } else if (ccslTemplate === "technicolor"){
                        //Write the spotting event to it's own table row
                        let spottingCellContents = [];
                        if (spottingEvent.speakers.length > 0 && spottingEvent.recipients && spottingEvent.recipients.length > 0){
                            spottingCellContents.push(new Paragraph({
                                text : `${spottingEvent.speakers[0].name} TO ${spottingEvent.recipients[0].name}:`,
                                spacing : {
                                    after: 200
                                }
                            })); 

                            convertToPlainText(spottingEvent.text).split("\n").forEach(l =>{
                                spottingCellContents.push(new Paragraph({
                                    text : l
                                }));
                            });
                        } else if (spottingEvent.speakers.length > 0){
                            spottingCellContents.push(new Paragraph({
                                text : `${spottingEvent.speakers[0].name}:`,
                                spacing : {
                                    after: 200
                                }
                            })); 

                            convertToPlainText(spottingEvent.text).split("\n").forEach(l =>{
                                spottingCellContents.push(new Paragraph({
                                    text : l
                                }));
                            });
                        } else {
                            convertToPlainText(spottingEvent.text).split("\n").forEach(l =>{
                                spottingCellContents.push(new Paragraph({
                                    text : l
                                }));
                            });
                        }

                        let speakerNameAndTextCell = [new Paragraph("")]
                        if (spIndex === 0){
                            speakerNameAndTextCell = (convertToPlainText(event.text)).split("\n").map(l =>{return new Paragraph(l)});
                        }

                        tableRows.push(new TableRow({
                            children: [
                                /* No ID for Spotting Events - instead it goes in the title column */
                                new TableCell({
                                    children: [new Paragraph("")]
                                }),
                                /* Speaker Names followed by the  text*/
                                new TableCell({
                                    children: speakerNameAndTextCell,
                                }),
                                /* ID */
                                new TableCell({
                                    children: [new Paragraph(`1/${spottingEventIndex + 1}.`)]
                                }),

                                /* Start Time [tab] End Time [tab] Duration */
                                new TableCell({
                                    children : [
                                        new Paragraph(tcLib.secToTc(parseFloat(spottingEvent.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame)),
                                        new Paragraph(tcLib.secToTc(parseFloat(spottingEvent.end) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame)),
                                        new Paragraph(tcLib.secToTc(spottingEvent.end - spottingEvent.start, $projectState.frameRate, $projectState.dropFrame))
                                    ]
                                }),

                                /* Speaker Name To Recipient + text */
                                new TableCell({
                                    children : spottingCellContents
                                })
                            ]
                        }));
                    }

                    if (tableCells.length > 0){
                        tableRows.push(new TableRow({
                            children: tableCells
                        }));
                    }
                    
                    tableCells = [];
                });
            } else {
                if (ccslTemplate === "condensed"){
                    tableRows.push(new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph({
                                    children : [
                                        new TextRun({
                                            text: (index + 1).toString(),
                                            bold: true                                
                                        })
                                    ],                            
                                    alignment: AlignmentType.LEFT,
                                })],
                                shading: {
                                    fill: "dce9f7",
                                    type: ShadingType.CLEAR,
                                    color: "auto",
                                }
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))]
                            }),
                            new TableCell({
                                children: [new Paragraph(event.notes), new Paragraph(" "), new Paragraph(convertToPlainText(event.text))]
                            }),                           
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            })
                        ]
                    }));
                } else if (ccslTemplate === "style5A"){
                    tableRows.push(new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph({
                                    children : [
                                        new TextRun({
                                            text: (index + 1).toString()     
                                        })
                                    ],                            
                                    alignment: AlignmentType.LEFT,
                                })]
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))]
                            }),
                            new TableCell({
                                children: [new Paragraph(event.notes)]
                            }),    
                            new TableCell({
                                children: [new Paragraph("")]
                            })
                        ]
                    }));
                } else if (ccslTemplate === "default"){
                    tableRows.push(new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph((index + 1).toString())]
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))]
                            }),
                            new TableCell({
                                children: [new Paragraph(event.notes)]
                            }),
                            new TableCell({
                                children: [new Paragraph(convertToPlainText(event.text))]
                            }),
                            new TableCell({
                                children: [new Paragraph("")],
                                shading: {
                                    fill: "dce9f7",
                                    type: ShadingType.CLEAR,
                                    color: "auto",
                                }
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            })
                        ]
                    }));
                } else if (ccslTemplate === "technicolor"){
                    tableRows.push(new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph((index + 1).toString())]
                            }),
                            new TableCell({
                                children: [new Paragraph(tcLib.secToTc(parseFloat(event.start) + parseFloat($projectState.incode), $projectState.frameRate, $projectState.dropFrame))].concat(event.notes.split("\n").map(l => {return new Paragraph(l)}))
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            }),
                            new TableCell({
                                children: [new Paragraph("")]
                            })
                        ]
                    }));
                }
            }
        });

        let headSection = {
            properties: {
                type: SectionType.CONTINUOUS,
                page: {
                    size: {
                        orientation: PageOrientation.LANDSCAPE,
                    },
                }
            },

            children: [
                new Paragraph({
                    children: [new TextRun({
                        text: documentTitle
                    })],
                    heading: HeadingLevel.TITLE,
                    alignment: AlignmentType.CENTER,
                    thematicBreak: true
                }),
                new Paragraph({
                    children: [new TextRun({
                        text: "COMBINED CONTINUITY & SPOTTING LIST"
                    })],
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.CENTER,
                    thematicBreak: true
                })
            ]
        }

        let metadataSection = {
            properties: {
                type: SectionType.CONTINUOUS,
                page: {
                    size: {
                        orientation: PageOrientation.LANDSCAPE,
                    },
                }
            },
            children: [
                new Paragraph({
                    spacing: {
                        before: 300,
                    },
                    children: [new TextRun({
                        text: `Author: ${$projectState.username}`,
                        bold: true
                    })]
                }),
                new Paragraph({
                    children: [new TextRun({
                        text: `Frame Rate: ${$projectState.frameRate}`,
                        bold: true
                    })]
                }),
                new Paragraph({
                    children: [new TextRun({
                        text: `Drop Frame: ${$projectState.dropFrame ? "Yes" : "No"}`,
                        bold: true
                    })]
                }),
                new Paragraph({
                    spacing: {
                        after: 300,
                    },
                    children: [new TextRun({
                        text: `Video Incode: ${tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame)}`,
                        bold: true
                    })]
                })
            ]
        }

        let mainSection = {
            properties: {
                type: SectionType.CONTINUOUS,
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: NumberFormat.DECIMAL,
                    },
                    size: {
                        orientation: ccslTemplate === "condensed" ?  PageOrientation.PORTRAIT : PageOrientation.LANDSCAPE,
                    }
                }
            },
            headers: ccslTemplate === "condensed" || ccslTemplate === "style5A" ? {
                default: new Header({
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    children: ["Page ", PageNumber.CURRENT, " of ", PageNumber.TOTAL_PAGES],
                                })
                            ],
                        }),
                    ],
                }),
            } : undefined,
            children: [
                new Table({
                    rows: tableRows,
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    }
                })
            ]
        }

        let footerSection = {
            properties: {
                page: {
                    size: {
                        orientation: PageOrientation.LANDSCAPE,
                    },
                }
            },
            children: [
                new Paragraph({
                    children: [new TextRun({
                        text: `Created using Closed Caption Creator 8 ${$authState.status === "in_trial" ? "TRIAL MODE" : ""}`
                    })],
                    heading: HeadingLevel.HEADING_4,
                    alignment: AlignmentType.CENTER,
                    thematicBreak: true
                })
            ]
        }

        if (ccslTemplate === "condensed" || ccslTemplate === "style5A"){
            doc = new Document({
                creator: "Closed Caption Creator 8",
                sections:  [mainSection]
            });
        } else {
            doc = new Document({
                creator: "Closed Caption Creator 8",
                sections:  [headSection, metadataSection, mainSection, footerSection]
            });
        }   
        

        let fileBlob = await Packer.toBlob(doc);
        saveAs(fileBlob, fileName, {
            autoBom: true
        });

        toast.push("Export Complete", {
            classes: ["toast-success"]
        });
    } catch (error) {
        console.log(error);
    } finally {
        //Save ccslTemplate selection to local storage
        localStorage.setItem("cc-ccsl-template", ccslTemplate);
        modalState.hideModal();
    }
}
</script>

<div 
    transition:fade={{ duration: 100 }} 
    class="modal {$modalState === 'ccslExport' ? 'show d-block' : ''}" 
    role="dialog"
    aria-labelledby="ccslExportTitle"
    aria-describedby="ccslExportDescription"
    tabindex="-1" 
    id="CcslExportModal"
>
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="ccslExportTitle">CCSL Export</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close Modal"
                    id="closeCcslExportBtn"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p id="ccslExportDescription" class="sr-only">Configure CCSL export settings</p>
                <form>
                    <div class="mb-3">
                        <label class="form-label" for="ccslDocTitle">Document Title</label>
                        <input 
                            id="ccslDocTitle"
                            class="form-control" 
                            type="text" 
                            placeholder="Document Title" 
                            bind:value={documentTitle}
                            aria-describedby="ccslDocTitleHelp"
                        >
                        <span id="ccslDocTitleHelp" class="visually-hidden">Enter the title for your CCSL document</span>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="ccslTemplate">CCSL Template</label>
                        <select 
                            id="ccslTemplate"
                            class="form-select" 
                            bind:value={ccslTemplate}
                            aria-describedby="ccslTemplateHelp"
                        >
                            <option value="default">Default</option>
                            <option value="condensed">Condensed</option>
                            <option value="style5A">Style 5A</option>
                            <option value="technicolor">Technicolor Format</option>
                        </select>
                        <span id="ccslTemplateHelp" class="visually-hidden">Select the template format for your CCSL export</span>
                    </div>

                    <p class="lead text-warning">Event Groups</p>
                    <div class="mb-3">
                        <label class="form-label" for="DialogueEventGroupSelect">Combined Continuity &amp; Dialogue Event Group <i class="bi bi-info-circle" title="The Dialogue List will normally contain shot changes and shot change descriptions in the Notes field."></i></label>
                        <select class="form-select" id="DialogueEventGroupSelect" bind:value="{dialogueEventGroupId}">
                            {#each $eventGroupState as eventGroup (eventGroup.id)}
                            <option value="{eventGroup.id}">{eventGroup.name}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="SpottingListEventGroupSelect">Spotting List Event Group <i class="bi bi-info-circle" title="The Spotting List will normally contain your subtitles with dialogue and speaker information."></i></label>
                        <select class="form-select" id="SpottingListEventGroupSelect" bind:value="{spottingEventGroupId}">
                            {#each $eventGroupState as eventGroup (eventGroup.id)}
                            <option value="{eventGroup.id}">{eventGroup.name}</option>
                            {/each}
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button 
                    class="btn btn-primary" 
                    type="button" 
                    id="exportCcslBtn"
                    aria-label="Export CCSL"
                    on:click={exportCcsl}
                >Export CCSL</button>
            </div>
        </div>
    </div>
</div>
