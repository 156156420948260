<!-- Script -->
<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    pedalState
} from "@app/store/pedalStore.js";
import {
    environment
} from "@app/store/envStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    uiState
} from "@app/store/uiStore.js";
import {
    authState
} from "@app/store/authStore.js";
import {
    autoReplaceState
} from "@app/store/autoReplaceStore.js";
import { toast } from '@zerodevx/svelte-toast';
import Swal from "sweetalert2";
import firebase from "@app/configs/firebase.js";
import "firebase/compat/functions";
import {
    replace
} from "svelte-spa-router";
import {
    Pane,
    Splitpanes
} from "svelte-splitpanes";
import ErrorBoundary from "../ErrorBoundary.svelte";
import {
    createBoundary
} from "@crownframework/svelte-error-boundary";
import ToolbarPrimary from "../components/toolbar/ToolbarPrimary.svelte";
import ToolbarSecondary from "../components/toolbar/ToolbarSecondary.svelte";
import Player from "../components/player/Player.svelte";
import QuickTools from "../components/quickTools/QuickTools.svelte";
import PlayerControls from "../components/player/PlayerControls.svelte";
import Editor from "../components/editor/Editor.svelte";
import Timeline from "../components/timeline/Timeline.svelte";
import TimelineLegacy from "../components/timeline/TimelineLegacy.svelte";
import AboutModal from "../components/modals/About.svelte";
import EventGroupOptionsModal from "../components/modals/EventGroupOptions.svelte";
import PasteAttributesModal from "../components/modals/PasteAttributes.svelte";
import UsageModal from "../components/modals/Usage.svelte";
import SnapshotHistoryModal from "../components/modals/SnapshotHistory.svelte";
import AiTranscriptImportModal from "../components/modals/AiTranscriptImport.svelte";
import AiTranslationImportModal from "../components/modals/AiTranslationImport.svelte";
import AudioDescriptionExportModal from "../components/modals/AudioDescriptionExport.svelte";
import AutomaticTranscriptionModal from "../components/modals/AutomaticTranscription.svelte";
import BatchTranscriptionModal from "../components/modals/BatchTranscription.svelte";
import AutomaticTranslationModal from "../components/modals/AutomaticTranslation.svelte";
import FileExportModal from "../components/modals/FileExport.svelte";
import FileImportModal from "../components/modals/FileImport.svelte";
import MarkerImportModal from "../components/modals/MarkerImport.svelte";
import MediaImportModal from "../components/modals/MediaImport.svelte";
import MusicNotesModal from "../components/modals/MusicNotes.svelte";
import InsertSpeakerModal from "../components/modals/InsertSpeaker.svelte";
import NewEventGroupModal from "../components/modals/NewEventGroup.svelte";
import EditEventGroupModal from "../components/modals/EditEventGroup.svelte";
import MergeEventGroupModal from "../components/modals/MergeEventGroup.svelte";
import SegmentEventGroupModal from "../components/modals/SegmentEventGroup.svelte";
import NewProjectModal from "../components/modals/NewProject.svelte";
import OptionsModal from "../components/modals/Options.svelte";
import PlainTextImportModal from "../components/modals/PlainTextImport.svelte";
import PositionModal from "../components/modals/Position.svelte";
import ProjectTimingSettingsModal from "../components/modals/ProjectTimingSettings.svelte";
import ConvertTimecodeModal from "../components/modals/ConvertTimecode.svelte";
import GoToEventModal from "../components/modals/GoToEvent.svelte";
import OfflineActivationModal from "../components/modals/OfflineActivation.svelte";
import RecentProjectsModal from "../components/modals/RecentProjects.svelte";
import ShortcutKeysModal from "../components/modals/ShortcutKeys.svelte";
import SubtitleExportModal from "../components/modals/SubtitleExport.svelte";
import CcslExportModal from "../components/modals/CcslExport.svelte";
import DialogueListExportModal from "../components/modals/DialogueListExport.svelte";
import BatchSubtitleExportModal from "../components/modals/BatchSubtitleExport.svelte";
import SubtitleImportModal from "../components/modals/SubtitleImport.svelte";
import ProjectImportModal from "../components/modals/ProjectImport.svelte";
import ProjectInfoModal from "../components/modals/ProjectInfoModal.svelte";
import TcOffsetModal from "../components/modals/TcOffset.svelte";
import BlankFramesModal from "../components/modals/BlankFrames.svelte";
import SnapToShotChangesModal from "../components/modals/SnapToShotChanges.svelte";
import TimeStretchModal from "../components/modals/TimeStretch.svelte";
import TranscriptExportModal from "../components/modals/TranscriptExport.svelte";
import VideoExportModal from "../components/modals/VideoExport.svelte";
import WelcomeModal from "../components/modals/Welcome.svelte";
import ZoomModal from "../components/modals/Zoom.svelte";
import SpeakerManager from "../components/modals/SpeakerManager.svelte";
import TagsManager from "../components/modals/TagsManager.svelte";
import RemoveSdhModal from "../components/modals/RemoveSdh.svelte";
import StorageExplorerModal from "../components/modals/StorageExplorer.svelte";
import TeamProjectImportModal from "../components/modals/TeamProjectImport.svelte";
import TeamShareModal from "../components/modals/TeamShare.svelte";
import TeamSyncModal from "../components/modals/TeamSync.svelte";
import SyncChangesModal from "../components/modals/SyncChanges.svelte";
import RenderAudioModal from "../components/modals/RenderAudio.svelte";
import AutoFormatModal from "../components/modals/AutoFormat.svelte";
import AutoCorrectTimingModal from "../components/modals/AutoCorrectTiming.svelte";
import TimingAndSyncSettingsModal from "../components/modals/TimingAndSyncSettings.svelte";
import CaptionStyleModal from "../components/modals/CaptionStyle.svelte";
import { SvelteToast } from '@zerodevx/svelte-toast';
import DrasticTechModal from "../components/modals/DrasticTech.svelte";
import AutomaticSaveModal from "../components/modals/AutomaticSave.svelte";
import CopyTcFromGroupModal from "../components/modals/CopyTcFromGroup.svelte";
import TipsModal from "../components/modals/Tips.svelte";
import VoiceManagerModal from "../components/modals/VoiceManager.svelte";
import SceneChangeDetectionModal from "../components/modals/SceneChangeDetection.svelte";
import LoadProjectFileModal from "../components/modals/LoadProjectFile.svelte";
import AutoReplaceModal from "../components/modals/AutoReplace.svelte";
import TeamManagerModal from "../components/modals/TeamManager.svelte";
import IconikGetProxyModal from "../components/modals/IconikGetProxy.svelte";
import IconikImportModal from "../components/modals/IconikImport.svelte";
import IconikPublishModal from "../components/modals/IconikPublish.svelte";
import AteliereImportModal from "../components/modals/AteliereImport.svelte";
import AtelierePublishModal from "../components/modals/AtelierePublish.svelte";
import WorkOrderImportModal from "../components/modals/WorkOrderImport.svelte";
import WorkOrderPublishModal from "../components/modals/WorkOrderPublish.svelte";
import EventGroupMetadataModal from "../components/modals/EventGroupMetadata.svelte";
import StyleGuideManager from "../components/modals/StyleGuideManager.svelte";
import * as Sentry from "@sentry/browser";

const Boundary = createBoundary(ErrorBoundary);
const ipcRenderer = window.ipcRenderer;
let userId, email;

if ($environment.online) {
    userId = firebase.auth().currentUser.uid;
    email = firebase.auth().currentUser.email;
    // console.log("Current User: " + email + " (" + userId + ")");
} else if ($environment.iframe){
    console.log("IFrame Mode Enabled");
} else {
    console.log("Offline Mode Enabled");
}

let projectFilePath,
    hideTips = true,
    timelineHeight = 110,
    editorHeight = 820;

async function setup() {
    try {
        if ($environment.iframe && !$environment.online) {
            modalState.showModal("ateliereImport");
            return;
        } else if ($environment.electron) {
            if (window.process && window.process.argv) {
                console.log(window.process.argv);
                projectFilePath = window.process.argv.find((arg) => {
                    return /\.ccprj/.test(arg);
                });
            }

            if (!projectFilePath) {
                projectFilePath = await ipcRenderer.invoke("getProjectFilePath");
            }

            if (projectFilePath) {
                console.log("Project File Path: " + projectFilePath);
                modalState.showModal("loadProjectFile");
            } else {
                hideTips = localStorage.getItem("cc-hide-v29");
                if (hideTips && (hideTips === "true" || hideTips === true)) {
                    modalState.showModal("welcome");
                } else {
                    modalState.showModal("tips");
                }
            }
        } else {
            if (!window.LiveReload) {
                //when the user closes the tab or goes to another page we need to ask them if they're sure they want to leave using an alert dialogue.
                window.onbeforeunload = function () {
                    return "Are you sure you want to leave?";
                };
            }

            console.info("DEBUG: Parsing URL params from URL: " + window.location.href);
            let urlString = window.location.href;
            let url = new URL(urlString);

            if (url.pathname && url.pathname.includes("iconik")) {
                modalState.showModal("iconikImport");
                return;
            } else if (url.pathname && url.pathname.includes("workorder")) {
                modalState.showModal("workOrderImport");
                return;
            } else if (url.pathname && url.pathname.includes("share")) {
                modalState.showModal("teamProjectImport");
                return;
            }

            hideTips = localStorage.getItem("cc-hide-v29");
            if (hideTips && (hideTips === "true" || hideTips === true)) {
                modalState.showModal("welcome");
            } else {
                modalState.showModal("tips");
            }
        }
    } catch (err) {
        console.log("Setup Script Failed");
        console.log(err, err.message);
    }
}

/* Init Foot Pedal and other Device Controllers */
async function initExtControl() {
    let deviceDefaults = JSON.parse(localStorage.getItem("cc-device-defaults")) || {};
    if (deviceDefaults.enable) {
        await pedalState.togglePedalControl();
        pedalState.selectDeviceByName(deviceDefaults.deviceName);
    }

    $pedalState.functionMapping = deviceDefaults.functionMapping || {
        0: "pause",
        1: "fastForward",
        2: "play",
        3: "none",
        4: "rewind",
    };
}

async function authCheck() {
    try {
        let subscription, ad = false;
        if ($environment.iframe && !$environment.online) {
            console.log("AUTH CHECK:");
            console.log(window.top);
            return true;
        } else if ($environment.online) {
            let getAuthInfoRes = await firebase.functions().httpsCallable("v8UserAuthV3")(email);

            /* Debug */
            //console.log("AUTH RES:");
            //console.log(JSON.stringify(getAuthInfoRes, null, 4));

            let subscriptionList = getAuthInfoRes.data.subscriptions.list;
            if (subscriptionList.length === 0) {
                throw new Error("Subscription does not exist");
            }

            //console.log(JSON.stringify(subscriptionList, null, 4));
            subscriptionList.forEach((subItem) => {
                //console.log(JSON.stringify(subItem, null, 4));
                if (/unlimited/.test(subItem.subscription.plan_id)) {
                    subscription = {
                        subId: subItem.subscription.id,
                        uid: subItem.customer.id,
                        plan: "pro",
                        type: "unlimited",
                        status: subItem.subscription.status,
                        ad: false,
                    };
                } else if (/pro/.test(subItem.subscription.plan_id)) {
                    subscription = {
                        subId: subItem.subscription.id,
                        uid: subItem.customer.id,
                        plan: "pro",
                        type: "default",
                        status: subItem.subscription.status,
                        ad: false,
                    };
                } else if (/enterprise/.test(subItem.subscription.plan_id)) {
                    subscription = {
                        subId: subItem.subscription.id,
                        uid: subItem.customer.id,
                        plan: "enterprise",
                        type: "default",
                        status: subItem.subscription.status,
                        ad: false,
                    };
                } else if (!subscription && /starter/.test(subItem.subscription.plan_id)) {
                    subscription = {
                        subId: subItem.subscription.id,
                        uid: subItem.customer.id,
                        plan: "starter",
                        type: "default",
                        status: subItem.subscription.status,
                        ad: false,
                    };
                }
                if (subItem.subscription.addons) {
                    subItem.subscription.addons.forEach((addOn) => {
                        if (/ad/.test(addOn.id)) {
                            ad = true;
                        }
                    });
                }
            });

            if (subscription) {
                subscription.ad = ad;
                subscription.owner = getAuthInfoRes.data.owner;
                subscription.ownerId = getAuthInfoRes.data.ownerId;
                subscription.team = getAuthInfoRes.data.team;
                subscription.user = getAuthInfoRes.data.user;
                authState.setAuth(subscription);
                autoReplaceState.loadListFromDb(userId);
                // console.log($authState);

                Sentry.setUser({
                    id: userId,
                    email: email
                });
            } else {
                throw new Error("Subscription does not exist");
            }
        } else {
            let activationCode = localStorage.getItem("cc-offline-license");
            let licenseInfo = await ipcRenderer.invoke("licenseCheck", activationCode);
            if (licenseInfo.active) {
                subscription = {
                    user: "Offline",
                    owner: "Offline",
                    ownerId: "Offline",
                    team: "Offline",
                    subId: "Offline",
                    uid: "Offline",
                    plan: licenseInfo.plan,
                    type: "default",
                    status: "active",
                    ad: licenseInfo.ad,
                };

                authState.setAuth(subscription);
                autoReplaceState.loadListFromDb();
                // console.log($authState);
            } else {
                toast.push(`Offline license is expired or invalid`, {classes: ['toast-`danger`']});

                localStorage.removeItem("cc-offline-license");
                replace("/loginOffline");
            }
        }

        return true;
    } catch (err) {
        console.error(err, err.message);
        if ($environment.online) {
            setTimeout(() => {
                if ($environment.electron) {
                    firebase.auth().signOut();
                    ipcRenderer.invoke("quit");
                } else {
                    firebase.auth().signOut();
                    replace("/login");
                }
            }, 5000);

            Swal.fire({
                titleText: "Authorization Error",
                text: "There was an issue finding your subscription: " + err.message + "\nPlease contact support for more help: support@closedcaptioncreator.com",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-light",
                },
            }).then((result) => {
                // console.log(result);
                if ($environment.electron) {
                    firebase.auth().signOut();
                    ipcRenderer.invoke("quit");
                } else {
                    firebase.auth().signOut();
                    replace("/login");
                }
            });
        } else {
            /* Offline code here */
            setTimeout(() => {
                replace("/loginOffline");
            }, 5000);

            Swal.fire({
                titleText: "Authorization Error",
                text: "There was an issue verifying your license: " + err.message + "\nPlease contact support for more help: support@closedcaptioncreator.com",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-light",
                },
            }).then((result) => {
                // console.log(result);
                replace("/loginOffline");
            });
        }
    }
}

function handleResize(ev) {
    let totalHeight = window.innerHeight * 0.94;

    /* Ready */
    if (!ev.detail) {
        if ($uiState.layout === "default" || $uiState.layout === "reverse" || $uiState.layout === "ultrawide") {
            editorHeight = totalHeight * (84.5 / 100) - 90;
            timelineHeight = totalHeight * (15 / 100);
        } else if ($uiState.layout === "vertical") {
            editorHeight = totalHeight * (39.5 / 100) - 90;
            timelineHeight = totalHeight * (20 / 100);
        }

        return;
    }

    /* Resize */
    if ($uiState.layout === "default" || $uiState.layout === "reverse" || $uiState.layout === "ultrawide") {
        if ($uiState.timeline) {
            editorHeight = totalHeight * ((ev.detail[0].size - 0.5) / 100) - 90;
            timelineHeight = totalHeight * (ev.detail[1].size / 100);
        } else {
            editorHeight = totalHeight * ((ev.detail[0].size - 0.5) / 100) - 90;
            timelineHeight = 0;
        }
    } else if ($uiState.layout === "vertical") {
        editorHeight = totalHeight * ((ev.detail[1].size - 0.5) / 100) - 90;
        if ($uiState.timeline) {
            timelineHeight = totalHeight * (ev.detail[2].size / 100);
        }
    }
}

setup();
authCheck();
initExtControl();
</script>

<!-- HTML TEMPLATE -->
<SvelteToast  options={{
    duration: 1500,
    position: 'top-right',
    showProgress: true,
    pauseOnHover: true,
    closeOnClick: true,
    transition: 'SlideInRight'
}}/>
<Boundary onError={console.error}>

<ToolbarPrimary />
</Boundary>
<Boundary onError={console.error}>

<ToolbarSecondary />
</Boundary>

<!-- DEFAULT LAYOUT -->
{#if $uiState.layout === "default"}

<Splitpanes horizontal={true} style="height: 94vh;" on:resized={handleResize} on:ready={handleResize}>

<Pane>

<Splitpanes>

<Pane class={"p-2"} size={40}>

<Boundary onError={console.error}>

<Player />

</Boundary>

<Boundary onError={console.error}>

<PlayerControls />

</Boundary>

<Boundary onError={console.error}>

<QuickTools />

</Boundary>

</Pane>

<Pane class={"p-2"}>

<Boundary onError={console.error}>

<Editor {editorHeight} />

</Boundary>

</Pane>

</Splitpanes>

</Pane>

{#if $uiState.timeline}

<Pane size={15}>

<Boundary onError={console.error}>
{#if $projectState.media && $projectState.media.useFallback && ["Local Storage", "Cloud Storage"].indexOf($projectState.media.storage) === -1}
<TimelineLegacy {timelineHeight} />
{:else}
<Timeline {timelineHeight} />
{/if}
</Boundary>

</Pane>

{/if}

</Splitpanes>

<!-- DEFAULT REVERSE LAYOUT -->
{:else if $uiState.layout === "reverse"}

<Splitpanes horizontal={true} style="height: 94vh;" on:resized={handleResize} on:ready={handleResize}>

<Pane>

<Splitpanes>

<Pane class={"p-2"}>

<Boundary onError={console.error}>

<Editor {editorHeight} />

</Boundary>

</Pane>

<Pane class={"p-2"} size={40}>

<Boundary onError={console.error}>

<Player />

</Boundary>

<Boundary onError={console.error}>

<PlayerControls />

</Boundary>

<Boundary onError={console.error}>

<QuickTools />

</Boundary>

</Pane>

</Splitpanes>

</Pane>

{#if $uiState.timeline}

<Pane size={15}>

<Boundary onError={console.error}>
    {#if $projectState.media && $projectState.media.useFallback && ["Local Storage", "Cloud Storage"].indexOf($projectState.media.storage) === -1}
    <TimelineLegacy {timelineHeight} />
    {:else}
    <Timeline {timelineHeight} />
    {/if}
</Boundary>

</Pane>

{/if}

</Splitpanes>

<!-- ULTRAWIDE LAYOUT -->
{:else if $uiState.layout === "ultrawide"}
<Splitpanes horizontal={true} style="height: 94vh;" on:resized={handleResize} on:ready={handleResize}>
    <Pane>
        <Splitpanes>            
            <Pane class={"p-2"} size={40}>
                <Boundary onError={console.error}>
                    <Player />
                </Boundary>
                <Boundary onError={console.error}>
                    <PlayerControls />
                </Boundary>
            </Pane>            
            <Pane class={"p-2"}>
                <Boundary onError={console.error}>
                    <Editor {editorHeight} />
                </Boundary>
            </Pane>
            <Pane class={"p-2"} size={40}>                
                <Boundary onError={console.error}>
                    <QuickTools />
                </Boundary>
            </Pane>
        </Splitpanes>
    </Pane>
{#if $uiState.timeline}
    <Pane size={15}>
        <Boundary onError={console.error}>
            {#if $projectState.media && $projectState.media.useFallback && ["Local Storage", "Cloud Storage"].indexOf($projectState.media.storage) === -1}
            <TimelineLegacy {timelineHeight} />
            {:else}
            <Timeline {timelineHeight} />
            {/if}
        </Boundary>
    </Pane>
{/if}
</Splitpanes>

<!-- Vertical LAYOUT -->
{:else if $uiState.layout === "vertical"}

<Splitpanes id={"mainPane"} horizontal={true} style="height: 94vh;" on:resized={handleResize} on:ready={handleResize}>

<Pane class={"p-2"} size={40}>

<Splitpanes>

<Pane class={"p-2"}>

<Boundary onError={console.error}>

<Player />

</Boundary>

<Boundary onError={console.error}>

<PlayerControls />

</Boundary>

</Pane>

<Pane class={"p-2"}>

<Boundary onError={console.error}>

<QuickTools />

</Boundary>

</Pane>

</Splitpanes>

</Pane>

<Pane class={"p-2"} size={40}>

<Boundary onError={console.error}>

<Editor {editorHeight} />

</Boundary>

</Pane>

{#if $uiState.timeline}

<Pane size={20}>

<Boundary onError={console.error}>

    {#if $projectState.media && $projectState.media.useFallback && ["Local Storage", "Cloud Storage"].indexOf($projectState.media.storage) === -1}
    <TimelineLegacy {timelineHeight} />
    {:else}
    <Timeline {timelineHeight} />
    {/if}

</Boundary>

</Pane>

{/if}

</Splitpanes>
{/if}
<!-- MODALS -->
<!-- Always load the shortcut keys and auto save modal -->
<Boundary onError={console.error}>
<ShortcutKeysModal />
</Boundary>

<Boundary onError={console.error}>
    <AutomaticSaveModal />
</Boundary>
{#if $modalState === "about"}

<Boundary onError={console.error}>

<AboutModal />

</Boundary>
{:else if $modalState === "zoom"}

<Boundary onError={console.error}>

<ZoomModal />

</Boundary>
{:else if $modalState === "eventGroupOptions"}

<Boundary onError={console.error}>

<EventGroupOptionsModal />

</Boundary>
{:else if $modalState === "pasteAttributes"}

<Boundary onError={console.error}>

<PasteAttributesModal />

</Boundary>
{:else if $modalState === "usage" && $environment.online}

<Boundary onError={console.error}>

<UsageModal />

</Boundary>
{:else if $modalState === "snapshotHistory" && $environment.online}

<Boundary onError={console.error}>

<SnapshotHistoryModal />

</Boundary>
{:else if $modalState === "aiTranscriptImport" && $environment.online}

<Boundary onError={console.error}>

<AiTranscriptImportModal />

</Boundary>
{:else if $modalState === "aiTranslationImport" && $environment.online}

<Boundary onError={console.error}>

<AiTranslationImportModal />

</Boundary>
{:else if $modalState === "audioDescriptionExport" && $authState.ad && $environment.online}

<Boundary onError={console.error}>

<AudioDescriptionExportModal />

</Boundary>
{:else if $modalState === "batchTranscription" && $environment.online}

<Boundary onError={console.error}>

<BatchTranscriptionModal />

</Boundary>
{:else if $modalState === "automaticTranscription" && $environment.online}

<Boundary onError={console.error}>

<AutomaticTranscriptionModal />

</Boundary>
{:else if $modalState === "automaticTranslation" && ($authState.plan === "pro" || $authState.plan === "enterprise") && $environment.online}

<Boundary onError={console.error}>

<AutomaticTranslationModal />

</Boundary>
{:else if $modalState === "fileExport"}

<Boundary onError={console.error}>

<FileExportModal />

</Boundary>
{:else if $modalState === "fileImport"}

<Boundary onError={console.error}>

<FileImportModal />

</Boundary>
{:else if $modalState === "markerImport"}

<Boundary onError={console.error}>

<MarkerImportModal />

</Boundary>
{:else if $modalState === "mediaImport"}

<Boundary onError={console.error}>

<MediaImportModal />

</Boundary>
{:else if $modalState === "musicNotes"}

<Boundary onError={console.error}>

<MusicNotesModal />

</Boundary>
{:else if $modalState === "insertSpeaker"}

<Boundary onError={console.error}>

<InsertSpeakerModal />

</Boundary>
{:else if $modalState === "newEventGroup"}

<Boundary onError={console.error}>

<NewEventGroupModal />

</Boundary>
{:else if $modalState === "editEventGroup"}

<Boundary onError={console.error}>

<EditEventGroupModal />

</Boundary>
{:else if $modalState === "mergeEventGroup"}

<Boundary onError={console.error}>

<MergeEventGroupModal />

</Boundary>
{:else if $modalState === "segmentEventGroup"}

<Boundary onError={console.error}>

<SegmentEventGroupModal />

</Boundary>
{:else if $modalState === "newProject"}

<Boundary onError={console.error}>

<NewProjectModal />

</Boundary>
{:else if $modalState === "options"}

<Boundary onError={console.error}>

<OptionsModal />

</Boundary>
{:else if $modalState === "plainTextImport"}

<Boundary onError={console.error}>

<PlainTextImportModal />

</Boundary>
{:else if $modalState === "position"}

<Boundary onError={console.error}>

<PositionModal />

</Boundary>
{:else if $modalState === "goToEvent"}

<Boundary onError={console.error}>

<GoToEventModal />

</Boundary>
{:else if $modalState === "projectTimingSettings"}

<Boundary onError={console.error}>

<ProjectTimingSettingsModal />

</Boundary>
{:else if $modalState === "convertTimecode"}
<Boundary onError={console.error}>
    <ConvertTimecodeModal />
</Boundary>

{:else if $modalState === "offlineActivation" && $environment.online}
<Boundary onError={console.error}>
    <OfflineActivationModal />
</Boundary>
{:else if $modalState === "recentProjects"}

<Boundary onError={console.error}>

<RecentProjectsModal />

</Boundary>
{:else if $modalState === "batchSubtitleExport"}

<Boundary onError={console.error}>

<BatchSubtitleExportModal />

</Boundary>
{:else if $modalState === "dialogueListExport"}

<Boundary onError={console.error}>

<DialogueListExportModal />

</Boundary>
{:else if $modalState === "subtitleExport"}

<Boundary onError={console.error}>

<SubtitleExportModal />

</Boundary>
{:else if $modalState === "ccslExport"}
    <Boundary onError={console.error}>
        <CcslExportModal />
    </Boundary>
{:else if $modalState === "subtitleImport"}

<Boundary onError={console.error}>

<SubtitleImportModal />

</Boundary>
{:else if $modalState === "tcOffset"}

<Boundary onError={console.error}>

<TcOffsetModal />

</Boundary>
{:else if $modalState === "blankFrames"}

<Boundary onError={console.error}>

<BlankFramesModal />

</Boundary>
{:else if $modalState === "snapToShotChanges"}

<Boundary onError={console.error}>

<SnapToShotChangesModal />

</Boundary>
{:else if $modalState === "timeStretch"}

<Boundary onError={console.error}>

<TimeStretchModal />

</Boundary>
{:else if $modalState === "transcriptExport"}

<Boundary onError={console.error}>

<TranscriptExportModal />

</Boundary>
{:else if $modalState === "teamProjectImport" && $environment.online}

<Boundary onError={console.error}>

<TeamProjectImportModal />

</Boundary>
{:else if $modalState === "teamShare" && $environment.online}

<Boundary onError={console.error}>

<TeamShareModal />

</Boundary>
{:else if $modalState === "teamSync" && $environment.online}

<Boundary onError={console.error}>

<TeamSyncModal />

</Boundary>
{:else if $modalState === "syncChanges" && $environment.online}

<Boundary onError={console.error}>

<SyncChangesModal />

</Boundary>
{:else if $modalState === "renderAudio" && $authState.ad && $environment.online}

<Boundary onError={console.error}>

<RenderAudioModal />

</Boundary>
{:else if $modalState === "videoExport"}

<Boundary onError={console.error}>

<VideoExportModal />

</Boundary>
{:else if $modalState === "welcome"}

<Boundary onError={console.error}>

<WelcomeModal />

</Boundary>
{:else if $modalState === "speakerManager"}

<Boundary onError={console.error}>

<SpeakerManager />

</Boundary>
{:else if $modalState === "tagsManager"}

<Boundary onError={console.error}>

<TagsManager />

</Boundary>
{:else if $modalState === "styleGuideManager"}

<Boundary onError={console.error}>

<StyleGuideManager />

</Boundary>
{:else if $modalState === "removeSdh"}

<Boundary onError={console.error}>

<RemoveSdhModal />

</Boundary>
{:else if $modalState === "autoFormat"}

<Boundary onError={console.error}>

<AutoFormatModal />

</Boundary>
{:else if $modalState === "autoCorrectTiming"}

<Boundary onError={console.error}>

<AutoCorrectTimingModal />

</Boundary>
{:else if $modalState === "timingAndSyncSettings"}
    <Boundary onError={console.error}>
        <TimingAndSyncSettingsModal />
    </Boundary>
{:else if $modalState === "captionStyle"}

<Boundary onError={console.error}>

<CaptionStyleModal />

</Boundary>
{:else if $modalState === "copyTcFromGroup"}

<Boundary onError={console.error}>

<CopyTcFromGroupModal />

</Boundary>
{:else if $modalState === "storageExplorer" && $environment.online}

<Boundary onError={console.error}>

<StorageExplorerModal />

</Boundary>
{:else if $modalState === "projectImport"}

<Boundary onError={console.error}>

<ProjectImportModal />

</Boundary>
{:else if $modalState === "autoReplace"}

<Boundary onError={console.error}>

<AutoReplaceModal />

</Boundary>
{:else if $modalState === "teamManager" && $environment.online}

<Boundary onError={console.error}>

<TeamManagerModal />

</Boundary>
{:else if $modalState === "tips"}

<Boundary onError={console.error}>

<TipsModal />

</Boundary>
{:else if $modalState === "voiceManager" && $authState.ad && $environment.online}

<Boundary onError={console.error}>

<VoiceManagerModal />

</Boundary>
{:else if $modalState === "eventGroupMetadata"}

<Boundary onError={console.error}>

<EventGroupMetadataModal />

</Boundary>
{:else if $modalState === "sceneChangeDetection" && $environment.electron}

<Boundary onError={console.error}>

<SceneChangeDetectionModal />

</Boundary>
{:else if $modalState === "iconikImport" && $environment.online}

<Boundary onError={console.error}>

<IconikImportModal />

</Boundary>
{:else if $modalState === "iconikGetProxy" && $environment.online}
    <Boundary onError={console.error}>
        <IconikGetProxyModal />
    </Boundary>
{:else if $modalState === "iconikPublish" && $environment.online}
    <Boundary onError={console.error}>
        <IconikPublishModal />
    </Boundary>
<!-- Ateliere Import and Publish Modals -->
{:else if $modalState === "ateliereImport" && $environment.iframe && !$environment.online}
    <Boundary onError={console.error}>
        <AteliereImportModal />
    </Boundary>
{:else if $modalState === "atelierePublish" && $environment.iframe && !$environment.online}
<Boundary onError={console.error}>
    <AtelierePublishModal />
</Boundary>
{:else if $modalState === "workOrderImport" && $environment.online}

<Boundary onError={console.error}>

<WorkOrderImportModal />

</Boundary>
{:else if $modalState === "workOrderPublish" && $environment.online}

<Boundary onError={console.error}>

<WorkOrderPublishModal />

</Boundary>
{:else if $modalState === "loadProjectFile"}

<Boundary onError={console.error}>

<LoadProjectFileModal {projectFilePath} />

</Boundary>
{:else if $modalState === "drasticTech" && $environment.electron}

<Boundary onError={console.error}>

<DrasticTechModal />

</Boundary>
{:else if $modalState === "projectInfo"}
<Boundary onError={console.error}>
    <ProjectInfoModal />
</Boundary>
{/if}
