import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _quillClasses from "../dict/quillClasses.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _stlFunc from "../functions/profiles/ebuStl.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const stlFunc = _stlFunc;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const quillClasses = _quillClasses;
const convertToPlainText = _convertToPlainText;
export default {
  decode: function (input, options) {
    let events = [],
      ttiInfo,
      ccEvent,
      colorTag = "",
      colorOpen = false,
      boldOpen = false,
      italicOpen = false,
      underlineOpen = false,
      decodingOptions = getFormatOptions(options.formatOptions),
      accentInfo = {
        set: false,
        value: ""
      };
    let gsi = stlFunc.decodeGsiBlock(input.substring(0, 2048));
    let maxRows = parseInt(gsi.maxLines) || 23;
    let maxCols = parseInt(gsi.maxCharPerLine) || 40;
    let ttiBlocks = input.substring(2048, input.length).match(/.{1,256}/g);
    let totalWindowHeight = options.window.height - options.window.height * 0.20; //80% of the window height
    //console.log(decodingOptions);

    ttiBlocks.forEach(ttiBlock => {
      ttiInfo = stlFunc.decodeTtiBlock(ttiBlock);
      //console.log(ttiInfo);
      if (ttiInfo.commentFlag === "00") {
        ccEvent = new Event();
        ccEvent.start = tcLib.tcToSec(stlFunc.decodeTc(ttiInfo.incode), gsi.frameRate);
        ccEvent.end = tcLib.tcToSec(stlFunc.decodeTc(ttiInfo.outcode), gsi.frameRate);
        ttiInfo.text.match(/.{1,2}/g).forEach(char => {
          if (char === "f8") {
            /* Control Code */
          } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
            /* TELETEXT CONTROL CODES */
            if (stlFunc.cntrlCodes[char]) {
              ccEvent.colors += 1;
              if (decodingOptions['Decode Speakers By Color'] && decodingOptions['Decode Speakers By Color'] !== "false") {
                if (decodingOptions['Insert Speaker Identifier'] && decodingOptions['Insert Speaker Identifier'] !== "false" && ccEvent.color !== stlFunc.cntrlCodes[char] && ccEvent.colors > decodingOptions['Speaker Is Greater Than']) {
                  ccEvent.text = decodingOptions['Speaker Identifier'] + ccEvent.text.trim();
                  ccEvent.text += "\n" + decodingOptions['Speaker Identifier'];
                }
                ccEvent.color = stlFunc.cntrlCodes[char];
              } else {
                //console.log("COLOR:", stlFunc.cntrlCodes[char]);
                if (colorOpen) {
                  ccEvent.text += `</${colorTag}>`;
                }
                ccEvent.text += `<${stlFunc.cntrlCodes[char]}>`;
                colorTag = stlFunc.cntrlCodes[char];
                colorOpen = true;
              }
            }
          } else if (parseInt(char, 16) >= 32 && parseInt(char, 16) <= 127 || parseInt(char, 16) >= 161 && parseInt(char, 16) <= 255) {
            if (gsi.alphabet.toLowerCase() === 'latin' && parseInt(char, 16) !== 201 && parseInt(char, 16) !== 204 && parseInt(char, 16) >= 193 && parseInt(char, 16) <= 207) {
              /* EBU STL uses a floating accent principle so we need to store the accents as they come in and apply them on the next character. See document for more info. */
              accentInfo = {
                set: true,
                value: parseInt(char, 16)
              };
              //console.log(accentInfo);
            } else {
              if (accentInfo.set) {
                //console.log(char.toLowerCase(), accentInfo.value)
                if (stlFunc.accentMapping[char.toLowerCase()] && stlFunc.accentMapping[char.toLowerCase()][accentInfo.value]) {
                  ccEvent.text += stlFunc.accentMapping[char.toLowerCase()][accentInfo.value];
                } else {
                  ccEvent.text += "*";
                }
                accentInfo.set = false;
              } else if (stlFunc.specialChars[char]) {
                //console.log(parseInt(char, 16) + " : " + stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]));
                ccEvent.text += stlFunc.specialChars[char];
              } else {
                //console.log(parseInt(char, 16) + " : " + stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]));
                ccEvent.text += stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]);
              }
            }
          } else if (parseInt(char, 16) >= 128 && parseInt(char, 16) <= 133) {
            /* Format Codes */
            if (stlFunc.controlCodeMapping[char]) {
              if (stlFunc.controlCodeMapping[char] === "<i>") {
                if (!italicOpen) {
                  ccEvent.text += stlFunc.controlCodeMapping[char];
                  italicOpen = true;
                }
              } else if (stlFunc.controlCodeMapping[char] === "<u>") {
                if (!underlineOpen) {
                  ccEvent.text += stlFunc.controlCodeMapping[char];
                  underlineOpen = true;
                }
              } else if (stlFunc.controlCodeMapping[char] === "<b>") {
                if (!boldOpen) {
                  ccEvent.text += stlFunc.controlCodeMapping[char];
                  boldOpen = true;
                }
              } else if (stlFunc.controlCodeMapping[char] === "</i>") {
                if (italicOpen) {
                  ccEvent.text += stlFunc.controlCodeMapping[char];
                  italicOpen = false;
                }
              } else if (stlFunc.controlCodeMapping[char] === "</u>") {
                if (underlineOpen) {
                  ccEvent.text += stlFunc.controlCodeMapping[char];
                  underlineOpen = false;
                }
              } else if (stlFunc.controlCodeMapping[char] === "</b>") {
                if (boldOpen) {
                  ccEvent.text += stlFunc.controlCodeMapping[char];
                  boldOpen = false;
                }
              }
            }
          } else if (parseInt(char, 16) === 138 /* 8A */) {
            /* Insert New Line */
            if (colorOpen) {
              ccEvent.text += `</${colorTag}>`;
              colorTag = "";
              colorOpen = false;
            }
            ccEvent.text = ccEvent.text.trim() + "\n";
          }
        });
        if (boldOpen) {
          ccEvent.text += stlFunc.controlCodeMapping["85"];
          boldOpen = false;
        }
        if (underlineOpen) {
          ccEvent.text += stlFunc.controlCodeMapping["83"];
          underlineOpen = false;
        }
        if (italicOpen) {
          ccEvent.text += stlFunc.controlCodeMapping["81"];
          italicOpen = false;
        }
        if (colorOpen) {
          ccEvent.text += `</${colorTag}>`;
          colorTag = "";
          colorOpen = false;
        }
        accentInfo.set = false;
        let plainText = convertToPlainText(ccEvent.text);
        let lines = plainText.split("\n");

        /* Set alignment and xPos */
        if (ttiInfo.xPos === "start") {
          ccEvent.alignment = "left";
          ccEvent.xPos = "start";
          ccEvent.xOffset = options.window.xOffset;
        } else if (ttiInfo.xPos === "end") {
          ccEvent.alignment = "right";
          ccEvent.xPos = "end";
          ccEvent.xOffset = options.window.xOffset * -1;
        } else {
          ccEvent.alignment = "center";
          ccEvent.xPos = "center";
        }

        /* Set yPos */
        let yPosPercent = Math.abs((ttiInfo.yPos - 1) / maxRows * 100);
        let yRow = Math.floor(15 * yPosPercent / 100);
        //calculate yPos and yOffset based on yRow being between (0 and 15)
        if (yRow > 10) {
          ccEvent.yPos = "end";
          ccEvent.yOffset = (15 - (yRow + lines.length)) / -15 * options.window.height;
        } else if (yRow < 6) {
          ccEvent.yPos = "start";
          ccEvent.yOffset = yRow / 15 * options.window.height + options.window.yOffset;
        } else {
          ccEvent.yPos = "center";
          ccEvent.yOffset = 0;
        }
        ccEvent.text = convertToHtml(ccEvent.text.replace(/ +/gmi, " ").replace(/^ +/gmi, ""), [quillClasses.align[ccEvent.alignment]]);
        //console.log(ttiInfo.yPos,"-->", ccEvent.yOffset, options.window.height);
        //console.log(ccEvent.text)
        // console.log("------------");
        // console.log(ccEvent.text, ccEvent.yPos, ccEvent.yOffset);
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "",
      gsi,
      encodingOptions = getFormatOptions(options.formatOptions),
      tti;
    if (encodingOptions["Include Blank Subtitle At Start"] && encodingOptions["Include Blank Subtitle At Start"] === "yes") {
      let som = encodingOptions["SOM"] || "00:00:00:00";
      let blankEvent = new Event({
        start: tcLib.tcToSec(som, options.frameRate),
        end: tcLib.tcToSec(som, options.frameRate) + 5 / options.frameRate,
        text: convertToHtml("", [quillClasses.align.center])
      });
      eventGroup.events.unshift(blankEvent);
    }
    gsi = stlFunc.encodeGsiBlock(eventGroup.events, encodingOptions, options);
    //console.log(gsi);
    output += gsi.concat();
    eventGroup.events.forEach(function (event, index) {
      // console.log("----------------");
      // console.log(event.text);
      tti = stlFunc.encodeTtiBlock(event, index, encodingOptions, options);
      output += tti.concat();
    });
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      eventGroup.events = eventGroup.events.filter((event, index, events) => {
        if (events[index + 1]) {
          if (event.start === events[index + 1].start && event.end === events[index + 1].end) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });

      //console.log(JSON.stringify(eventGroup, null, 4));
      return eventGroup;
    }
  }
};