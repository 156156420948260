<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    authState
} from '@app/store/authStore.js';
import {
    externalDataState
} from "@app/store/externalDataStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    fade
} from "svelte/transition";
import Swal from 'sweetalert2';
import throttle from 'just-throttle';
import axios from 'axios';

import encode from "@app/external/cc-lib/dist/functions/encode.js";
import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import _Event from "@app/external/cc-lib/dist/classes/event.js";
import offset from "@app/external/cc-lib/dist/functions/events/tcOffset.js";
import tcMultiply from "@app/external/cc-lib/dist/functions/special/tcMultiply.js";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";
import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
import wrapStyledTextWithSpan from '@app/external/cc-lib/dist/functions/quill/wrapStyledTextWithSpan.js'; 

let progress = 0,
    statusMsg = "Starting publish...";
let description = JSON.parse($externalDataState.rawDescription);
let task = description.task || "edit";
let username = '',
    password = '',
    authLoginFlag = false;

let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
let eventListElement = document.getElementById("EventList");
let ignoreAll = false;
console.info(description);

if ($authState.status === "in_trial") {
    showTrialWarning();
}

function showTrialWarning() {
    Swal.fire({
        titleText: "Trial Mode",
        text: "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Activate Account',
        denyButtonText: 'Continue Trial',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            denyButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            activateSubscription();
            return true;
        } else {
            return false;
        }
    }).then((res) => {
        if (res) {
            console.log(res);
            showRestartNotification();
        }

        return true;
    }).catch(err => {
        console.log(err);
        console.log(err.message)
    });
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, { leading: true });

function showRestartNotification() {
    Swal.fire({
        titleText: "Restart Required",
        text: "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-light'
        }
    }).then(res => {
        console.log(res);
    }).catch(err => {
        console.log(err);
        console.log(err.message)
    });
}

async function updateStatus(statusInfo) {
    progress = statusInfo.progress;
    statusMsg = statusInfo.msg;
}

async function cancelExport(eventGroupId, eventId) {
    toast.push("Subtitle export was aborted", {classes: ['toast-warning']});

    $projectState.selected = eventGroupId;
    $eventGroupState[$projectState.selected].selected = [eventId];
    setTimeout(() => {
        try {
            eventListElement.scrollTo(0, eventId * 230);
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, eventId * 230);
        }
    }, 250);

    modalState.hideModal();
}
function alertUser(msg) {
    let response = Swal.fire({
        titleText: "Export Pre-Checks",
        text : msg,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Abort',
        denyButtonText: 'Ignore',
        cancelButtonText: 'Ignore All',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-danger',
            denyButton: 'btn btn-light mx-2',
            cancelButton : 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
            return false;
        } else if (result.isDismissed){
            ignoreAll = true;
            return false;
        }
    });

    return response;
}

async function preExportCheck(events) {
    for (let i = 0; i < events.length; i++) {
        if (!ignoreAll) {
            if (events[i].text) {
                if (isNaN(events[i].start)) {
                    if (await alertUser(`Event ${i+1} is missing a start time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (isNaN(events[i].end)) {
                    if (await alertUser(`Event ${i+1} is missing an end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start === events[i].end) {
                    if (await alertUser(`Event ${i+1} has the same start and end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start > events[i].end) {
                    if (await alertUser(`Event ${i+1} has a start time greater than its end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (i > 0 && events[i].start < events[i - 1].end) {
                    /* Adding check in case overlap is less than a frame */
                    if (events[i - 1].end - events[i].start < 1 / $projectState.frameRate) {
                        events[i].start = events[i - 1].end;
                    } else if (await alertUser(`Event ${i+1} overlaps with the previous event.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                }
            } else {
                if (await alertUser(`Event ${i+1} has no text.\n Would you like to ABORT your export?`)) {
                    return {
                        err: true,
                        eventId: i
                    }
                };
            }
        }

    }

    return {
        err: false
    }
}

async function getUserAuth() {
    console.log("Get User Authentication");
    let usernameInput;
    let passwordInput;
    let swalRes = await Swal.fire({
        title: "Publish Authentication",
        html: `
                            <div class="mb-3">
                                <label for="username" class="form-label">Email address</label>
                                <input type="text" id="username" class="form-control" placeholder="Username">                                
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">Password</label>
                                <input type="password" id="password" class="form-control" placeholder="Password">                                
                            </div>`,
        confirmButtonText: 'Ok',
        focusConfirm: false,
        didOpen: () => {
            const popup = Swal.getPopup();
            usernameInput = popup.querySelector('#username');
            passwordInput = popup.querySelector('#password');
            usernameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
            passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm();
        },
        preConfirm: () => {
            const username = usernameInput.value
            const password = passwordInput.value
            if (!username || !password) {
                Swal.showValidationMessage(`Please enter username and password`)
            }
            return {
                username,
                password
            }
        }
    });

    username = swalRes.value.username;
    password = swalRes.value.password;
    authLoginFlag = true;
    return;
}

async function publish() {
    try {
        await updateStatus({
            progress: 5,
            statusMsg: "Starting Publish"
        });

        if (task === "review") {
            console.log("REVIEW TASK");
            await updateStatus({
                progress: 10,
                statusMsg: "Publishing review results for individual tracks."
            });
            for (let track of description.tracks) {
                console.log(`Publishing track:`, track);
                if (track.publishOptions && track.publishOptions.length > 0) {
                    for (let publishOption of track.publishOptions) {
                        console.log("actioning publish:", publishOption);
                        if (publishOption.url) {
                            console.log("Publish URL:", publishOption.url);
                            if (publishOption.auth && !authLoginFlag) {
                                console.log("Auth Login Flag:", authLoginFlag);
                                await getUserAuth();
                            }

                            let res = await axios.post(publishOption.url, track, {
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                auth: {
                                    username: username,
                                    password: password
                                }
                            });

                            console.log(res);
                        }
                    }
                }
            }

            await updateStatus({
                progress: 60,
                statusMsg: "Publishing review results for all tracks."
            });

            if (description.publishOptions && description.publishOptions.length > 0) {
                for (let publishOption of description.publishOptions) {
                    if (publishOption.url) {
                        if (publishOption.auth && !authLoginFlag) {
                            await getUserAuth();
                        }

                        await axios.post(publishOption.url, {
                            tracks: description.tracks
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            auth: {
                                username: username,
                                password: password
                            }
                        });
                    }
                }
            }
        } else {
            console.log("Edit Work Order");
            await updateStatus({
                progress: 10,
                statusMsg: "Publishing edit results for individual tracks."
            });
            for (let track of description.tracks) {
                console.log(`Publishing track:`, track);
                if (track.publishOptions && track.publishOptions.length > 0 && track.status === "Publish") {
                    await updateStatus({
                        progress: progress + 5,
                        statusMsg: "Publishing edit results for individual tracks."
                    });
                    for (let publishOption of track.publishOptions) {
                        console.log("actioning publish:", publishOption);
                        if (publishOption.url) {
                            console.log("Publish URL:", publishOption.url);
                            if (publishOption.auth && !authLoginFlag) {
                                console.log("Auth Login Flag:", authLoginFlag);
                                await getUserAuth();
                            }

                            /* Find the Index of the Event Group to Publish */
                            let eventGroupIndex = $eventGroupState.findIndex(group => {
                                return group.id === track.id;
                            });

                            if (eventGroupIndex === -1) {
                                throw new Error("Event Group to publish has been removed");
                            }

                            let copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));
                            copyOfEventGroup = orderByTime(copyOfEventGroup);
                            copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);
                            let preCheckResult = await preExportCheck(copyOfEventGroup.events);

                            if (preCheckResult.err) {
                                cancelExport(eventGroupIndex, preCheckResult.eventId)
                            } else {
                                if (publishOption.offset) {
                                    copyOfEventGroup.events.forEach((event, index, events) => {
                                        events[index] = offset(event, tcLib.tcToSec(publishOption.offset, publishOption.frameRate, publishOption.dropFrame), publishOption.offsetType || "add");
                                    });
                                }

                                if (publishOption.tcMultiplier && publishOption.tcMultiplier != 0 && publishOption.tcMultiplier != 1) {
                                    copyOfEventGroup = tcMultiply(copyOfEventGroup, publishOption.tcMultiplier);
                                }

                                let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
                                let yPadding = $styleState.mode ? 10 : $styleState.yPadding;

                                let exportOptions = new defaults.options({
                                    profile: publishOption.profile,
                                    formatOptions: publishOption.options,
                                    frameRate: publishOption.frameRate,
                                    dropFrame: publishOption.dropFrame,
                                    target_profile: publishOption.profile,
                                    source_profile: 'closedCaptionProject',
                                    window: {
                                        width: playerWidth,
                                        height: playerHeight,
                                        xOffset: (xPadding / 100) * playerWidth,
                                        yOffset: (yPadding / 100) * playerHeight
                                    }
                                });

                                if ($authState.status === "in_trial") {
                                    copyOfEventGroup = replaceTrialText(copyOfEventGroup);
                                }

                                copyOfEventGroup.events.forEach((event, index, events) =>{
                                    events[index].text = wrapStyledTextWithSpan(event.text);
                                });

                                track.output = await encode(copyOfEventGroup, exportOptions);

                                let res = await axios.post(publishOption.url, track, {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    auth: {
                                        username: username,
                                        password: password
                                    }
                                });

                                console.log(res);
                            }
                        }
                    }
                }
            }

            await updateStatus({
                progress: progress + 5,
                statusMsg: "Publishing edit results for all tracks."
            });

            if (description.publishOptions && description.publishOptions.length > 0) {
                for (let publishOption of description.publishOptions) {
                    if (publishOption.url) {
                        if (publishOption.auth && !authLoginFlag) {
                            await getUserAuth();
                        }

                        for (let track of description.tracks) {
                            if (track.status === "Publish") {
                                /* Find the Index of the Event Group to Publish */
                                let eventGroupIndex = $eventGroupState.findIndex(group => {
                                    return group.id === track.id;
                                });

                                if (eventGroupIndex === -1) {
                                    throw new Error("Event Group to publish has been removed");
                                }

                                let copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));
                                copyOfEventGroup = orderByTime(copyOfEventGroup);
                                copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);
                                let preCheckResult = await preExportCheck(copyOfEventGroup.events);

                                if (preCheckResult.err) {
                                    cancelExport(eventGroupIndex, preCheckResult.eventId)
                                } else {
                                    if (publishOption.offset) {
                                        copyOfEventGroup.events.forEach((event, index, events) => {
                                            events[index] = offset(event, tcLib.tcToSec(publishOption.offset, publishOption.frameRate, publishOption.dropFrame), publishOption.offsetType || "add");
                                        });
                                    }

                                    if (publishOption.tcMultiplier && publishOption.tcMultiplier != 0 && publishOption.tcMultiplier != 1) {
                                        copyOfEventGroup = tcMultiply(copyOfEventGroup, publishOption.tcMultiplier);
                                    }

                                    let exportOptions = new defaults.options({
                                        profile: publishOption.profile,
                                        formatOptions: publishOption.options,
                                        frameRate: publishOption.frameRate,
                                        dropFrame: publishOption.dropFrame,
                                        target_profile: publishOption.profile,
                                        source_profile: 'closedCaptionProject',
                                        window: {
                                            width: playerWidth,
                                            height: playerHeight,
                                            xOffset: ($styleState.xPadding / 100) * playerWidth,
                                            yOffset: ($styleState.yPadding / 100) * playerHeight
                                        }
                                    });

                                    if ($authState.status === "in_trial") {
                                        copyOfEventGroup = replaceTrialText(copyOfEventGroup);
                                    }

                                    copyOfEventGroup.events.forEach((event, index, events) =>{
                                        events[index].text = wrapStyledTextWithSpan(event.text);
                                    });

                                    track.output = await encode(copyOfEventGroup, exportOptions);
                                }
                            }
                        }

                        await axios.post(publishOption.url, {
                            tracks: description.tracks.filter(track =>{return track.status === "Publish"})
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            auth: {
                                username: username,
                                password: password
                            }
                        });
                    }
                }
            }

        }

        await updateStatus({
            progress: 80,
            statusMsg: "Publishing complete. Finalizing publish workflow..."
        });

        if (description.publishAlertSuccess) {
            await Swal.fire(description.publishAlertSuccess);
        }

        toast.push(`External work order publish was successful.`, {classes: ['toast-success']});

        await updateStatus({
            progress: 100,
            statusMsg: "Publishing Workflow Complete"
        });

        /* Redirect */
        if (description.redirectUrl) {
            //window.location.replace(description.redirectUrl);
        }
    } catch (err) {
        console.info(err, err.message);
        if (description.publishAlertError) {
            await Swal.fire(description.publishAlertError);
        }

        toast.push(`External Work Order Publish Failed. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        modalState.hideModal();
    }
}
</script>

<div 
    transition:fade={{ duration: 100 }} 
    class="modal {$modalState === 'workOrderPublish' ? 'show d-block' : ''}" 
    role="dialog" 
    tabindex="-1" 
    id="PublishExternalProjectModal"
    aria-labelledby="publishModalTitle"
    aria-describedby="publishModalDescription"
>
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="publishModalTitle">Work Order Publish</h4>
                <button type="button" class="btn-close" aria-label="Close" id="publishModalClose" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <p id="publishModalDescription">{description.publishText}</p>
                <table class="table" aria-label="Track Status Table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {#each description.tracks as track, index}
                        <tr>
                            <td>{track.displayName}</td>
                            <td>{track.type}</td>
                            <td>
                                <select 
                                    class="form-select" 
                                    bind:value="{track.status}"
                                    id="trackStatus{index}"
                                    aria-label="Status for {track.displayName}"
                                >
                                    {#if task === "review"}
                                    <option>Pass</option>
                                    <option>Fail</option>
                                    <option>Edit Required</option>
                                    <option>Refer to Supervisor</option>
                                    {:else}
                                    <option>Publish</option>
                                    <option>Do Not Publish</option>
                                    {/if}
                                </select>
                            </td>
                        </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" id="publishModalCancel" class="btn btn-light" disabled="{progress > 0}" on:click={modalState.hideModal}>Cancel</button>
                <button type="button" id="publishModalPublish" class="btn btn-primary" disabled="{progress > 0}" on:click={() => publish()}>Publish</button>
            </div>
        </div>
    </div>
</div>
