import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";
import online from "./configs/status.js";
document.body.innerHTML = "";

if (online) {
	Sentry.init({
		release: "cc-creator@1.8.31b",
		dsn: "https://3f1c753881664260b17e3454253bd5b1@o221056.ingest.sentry.io/1367141",
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1,
        // tracesSampleRate: 0.25,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		//tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.20,
		replaysOnErrorSampleRate: 1.0,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				// Additional SDK configuration goes in here, for example:
				maskAllText: false,
				blockAllMedia: false,
				maskAllInputs: false,
			}),
		],
	});
}

const app = new App({
	target: document.body,
});

export default app;
