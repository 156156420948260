import _convertWordMapToSubtitles from "../functions/special/convertWordMapToSubtitles.js";
import _convertWordMapToTranscript from "../functions/special/convertWordMapToTranscript.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _Event from "../classes/event.js";
const Event = _Event;
const getFormatOptions = _getFormatOptions;
const convertWordMapToTranscript = _convertWordMapToTranscript;
const convertWordMapToSubtitles = _convertWordMapToSubtitles;
export default {
  decode: function (input, options) {
    let events = [],
      wordMap = [],
      decodingOptions = getFormatOptions(options.formatOptions);
    let fileJson = JSON.parse(input);
    let importType = decodingOptions["Import Type"] || "subtitle";
    let maxLines = decodingOptions["Max Lines"] || 2;
    let maxChars = decodingOptions["Max Characters"] || 32;
    let importTarget = decodingOptions["Import Target"] || "transcript";
    if (importTarget.toLowerCase() === "transcript") {
      /* Generate Word Map */
      if (fileJson.words && Array.isArray(fileJson.words)) {
        fileJson.words.forEach(word => {
          // Include only words, skip spacing and audio_events
          if (word.type === "word") {
            wordMap.push(new Event({
              start: parseFloat(word.start),
              end: parseFloat(word.end),
              text: word.text,
              speakers: word.speaker_id ? [word.speaker_id] : []
            }));
          }
        });
      }
      if (importType === "word map") {
        events = wordMap;
      } else if (importType === "transcription") {
        events = convertWordMapToTranscript(wordMap, maxLines, maxChars, options.window);
      } else {
        events = convertWordMapToSubtitles(wordMap, maxLines, maxChars, options.window);
      }
    } else if (importTarget.toLowerCase() === "audio events") {
      if (fileJson.words && Array.isArray(fileJson.words)) {
        fileJson.words.forEach(word => {
          // Include only audio_events
          if (word.type === "audio_event") {
            events.push(new Event({
              start: parseFloat(word.start),
              end: parseFloat(word.end),
              text: word.text,
              yOffset: options.window.height * -0.10
            }));
          }
        });
      }
    }
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("ElevenLabs Scribe files are not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      eventGroup.events = eventGroup.events.filter((event, index, events) => {
        if (index !== 0) {
          return event.start !== events[index - 1].start;
        } else {
          return true;
        }
      });
      return eventGroup;
    }
  }
};