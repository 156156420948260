<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

let open = false;

const updatePosition = function(posX, posY) {
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        switch (posY) {
            case "top":
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = "start"
                break;
            case "center":
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = "center"
                break;
            default:
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = "end"
        }

        switch (posX) {
            case "left":
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = "start";
                break;
            case "center":
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = "center";
                break;
            default:
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = "end";
        }

        $eventGroupState[$projectState.selected].events[eventIndex].xOffset = 0;
        $eventGroupState[$projectState.selected].events[eventIndex].yOffset = 0;
    });

    historyState.insert({
        name: "position event(s)", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    /* Close Menu */
    open = !open;
}
</script>

<li class="nav-item dropdown {open ? 'show' : ''}" role="presentation" title="Event Position">
    <a 
        id="position-dropdown"
        class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}"
        aria-disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}"
        on:click="{()=>{open = !open}}" 
        class="dropdown-toggle nav-link" 
        href="#!/"
        aria-expanded={open}
        aria-label="Event Position"
        role="button"><i class="bi bi-grid-3x3" aria-hidden="true" /></a>
    <div id="PositionMenu" class="dropdown-menu {open ? 'show' : ''}" role="menu" aria-labelledby="position-dropdown">
        <div class="btn-toolbar" role="toolbar" aria-label="Position controls">
            <div class="btn-group" role="group" aria-label="Top row positions">
                <button id="pos-top-left" class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition('left', 'top')}}" aria-label="Top left"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
                <button class="btn btn-light btn-sm" type="button" on:click="{()=>{updatePosition('center', 'top')}}" aria-label="Top center"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition('right', 'top')}}" aria-label="Top right"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
            </div>
            <div class="btn-group" role="group" aria-label="Center row positions">
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition('left', 'center')}}" aria-label="Center left"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
                <button class="btn btn-light btn-sm" type="button" on:click="{()=>{updatePosition('center', 'center')}}" aria-label="Center center"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition('right', 'center')}}" aria-label="Center right"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
            </div>
            <div class="btn-group" role="group" aria-label="Bottom row positions">
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition('left', 'bottom')}}" aria-label="Bottom left"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
                <button class="btn btn-light btn-sm" type="button" on:click="{()=>{updatePosition('center', 'bottom')}}" aria-label="Bottom center"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition('right', 'bottom')}}" aria-label="Bottom right"> 
                    <i class="bi bi-circle" aria-hidden="true" /> 
                </button>
            </div>
        </div>
    </div>
</li>
<style>
#PositionMenu {
    background: transparent;
    border: none;
    position: absolute;
    top: 30px;
}
</style>
