import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import { decode as _decodeHtml } from "html-entities";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const decodeHtml = _decodeHtml;
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
export default {
  decode: function (input, options) {
    let events = [],
      displays = [],
      regions = {},
      paragraphs = [];
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);
    //console.log(JSON.stringify(fileJson, null, 4));
    try {
      if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
        fileJson.tt.head.layout.region.forEach(region => {
          regions[region['@_xml:id']] = region;
        });
      }
    } catch (err) {
      console.log("Unable to decode region data");
    }
    if (Array.isArray(fileJson.tt.body.div)) {
      fileJson.tt.body.div.forEach(paragraphGroup => {
        if (Array.isArray(paragraphGroup.p)) {
          paragraphGroup.p.forEach(paragraph => {
            paragraphs.push(paragraph);
          });
        } else {
          paragraphs.push(paragraphGroup.p);
        }
      });
    } else {
      if (Array.isArray(fileJson.tt.body.div.p)) {
        fileJson.tt.body.div.p.forEach(paragraph => {
          paragraphs.push(paragraph);
        });
      } else {
        paragraphs.push(fileJson.tt.body.div.p);
      }
    }
    paragraphs.forEach(paragraph => {
      let ccDisplay = new ttmlFunc.singleLine.Display();
      let start = tcLib.parseTcToSec(paragraph["@_begin"], options.frameRate, true);
      let end = tcLib.parseTcToSec(paragraph["@_end"], options.frameRate, true);
      //console.log(start,end);

      let original = decodeHtml(paragraph["#text"]);
      //console.log("------")
      //console.log("Original:",original);
      let text = convertToPlainText(original);
      //console.log("Text:",text);
      let html = ttmlFunc.multiLine.formatText(original);
      //console.log("html:",html);
      let originX, originY, extentX, extentY, region;
      if (paragraph["@_tts:origin"]) {
        originX = parseFloat(paragraph["@_tts:origin"].split(" ")[0].replace("%", ""));
        originY = parseFloat(paragraph["@_tts:origin"].split(" ")[1].replace("%", ""));
      }
      if (paragraph["@_tts:extent"]) {
        extentX = parseFloat(paragraph["@_tts:extent"].split(" ")[0].replace("%", ""));
        extentY = parseFloat(paragraph["@_tts:extent"].split(" ")[1].replace("%", ""));
      }
      if (paragraph["@_region"] && regions) {
        region = regions[paragraph["@_region"]];
      }
      if (displays.length > 0 && displays[displays.length - 1].start === start) {
        displays[displays.length - 1].insertLine({
          text: text,
          html: html,
          original: original,
          extentX: extentX,
          extentY: extentY,
          originX: originX,
          originY: originY,
          region: region
        });
      } else {
        ccDisplay.start = start;
        ccDisplay.end = end;
        ccDisplay.insertLine({
          text: text,
          html: html,
          original: original,
          extentX: extentX,
          extentY: extentY,
          originX: originX,
          originY: originY,
          region: region
        });
        displays.push(ccDisplay);
      }
    });

    //console.log(JSON.stringify(displays, null, 4));
    displays.forEach(display => {
      events.push(ttmlFunc.singleLine.decodeDisplay(display, options.window));
    });

    //console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let title = encodingOptions["Title"] || "Unknown";
    let languageCode = encodingOptions["Language Code"] || "en-US";
    let fontFamily = encodingOptions["Font Family"] || "Arial";
    let fontSize = encodingOptions["Font Size"] || 100;
    let fontColor = encodingOptions["Font Color"] || "#ffffff";
    let tcFormat = encodingOptions["Timecode Format"] || "ms";
    let timeBase = encodingOptions["Time Base"] || "media";
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xmlns="http://www.w3.org/ns/ttml" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" ttp:timeBase="${timeBase}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" xml:lang="${languageCode}" ttp:frameRateMultiplier="${frameRateMultiplier}">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>`);
    output += eol.after(`<ttm:title>${title}</ttm:title>`);
    output += eol.after(`</metadata>`);
    output += eol.after(`<styling>
        <style xml:id="s1" tts:textAlign="center" tts:fontFamily="${fontFamily}" tts:fontSize="${fontSize}%" />
       </styling>`);
    output += eol.after(`<layout>
        <region xml:id="bottom" tts:displayAlign="after" tts:origin="10% 50%" tts:extent="80% 40%" />
        <region xml:id="top" tts:displayAlign="before" tts:origin="10% 10%" tts:extent="80% 40%" />
      </layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body region="bottom" style="s1">`);
    output += eol.after(`<div>`);
    eventGroup.events.forEach((event, index) => {
      let start, end;
      if (tcFormat.toLowerCase() === "smpte") {
        start = tcLib.secToTcClock(event.start, options.frameRate);
        end = tcLib.secToTcClock(event.end, options.frameRate);
      } else {
        start = tcLib.secToTcMs(event.start);
        end = tcLib.secToTcMs(event.end);
      }
      let plainTextCustom = convertToPlainTextCustom(event.text);
      output += `<p xml:id="subtitle${index + 1}" begin="${start}" end="${end}" style="s1" region="${event.yPos === "start" ? "top" : "bottom"}">${ttmlFunc.multiLine.convertToTtml(plainTextCustom)}</p>`;
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += eol.after(`</tt>`);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};