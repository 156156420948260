<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

const alignCenter = () => {
    if (!quillEditor){
        return;
    }
    
    quillEditor.setSelection(0, quillEditor.getLength());
    quillEditor.format('align', 'center');
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        $eventGroupState[$projectState.selected].events[eventIndex].alignment = "center";
    });

    historyState.insert({
        name: "align center", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}
</script>
<li class="nav-item" title="Align Center">
    <a 
        class="nav-link" 
        href="#!/" 
        id="align-center-btn"
        role="button"
        aria-label="Align text center"
        aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        on:click={alignCenter} 
        class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
    ><i class="bi bi-text-center" /></a>
</li>
