<script>
import {
    fade
} from "svelte/transition";
import {
    modalState
} from "@app/store/modalStore.js";
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    environment
} from '@app/store/envStore.js';
import {
    toast } from '@zerodevx/svelte-toast';
import {
    authState
} from '@app/store/authStore.js';
import {
    v4 as uuidv4
} from 'uuid';

import firebase from "@app/configs/firebase.js";
import db from "@app/configs/firestore.js";
import _Tag from "@app/external/cc-lib/dist/classes/tag.js";

let selectedTagSet,
    selectedTag,
    tagSets = [],
    activeSets = [],
    tagTermInput = "",
    tagDefinitionInput = "",
    tagTypeInput = "Character",
    userId = $environment.online ? firebase.auth().currentUser.uid : null,
    teamId = $environment.online && $authState.team ? $authState.team.id : null,
    homeRef,
    userRef;

if ($environment.online) {
    homeRef = $authState.team ? db.collection("teams").doc(teamId).collection("tags") : db.collection("users").doc(userId).collection("tags");
    userRef = db.collection("users").doc(userId);
}

let gettingTagSets = getTagSets();
getUserSettings();

const tagColorMapping = {
    Character: "info",
    Location: "light",
    Organization: "warning",
    Phrase: "success",
    Other: "secondary"
}

function getUserSettings() {
    activeSets = [];

    if ($environment.online) {
        return userRef
            .get()
            .then((userDoc) => {
                let userInfo = userDoc.data();
                activeSets = userInfo.tags || [];
            }).catch((error) => {
                console.log("Error getting user tag sets: ", error);
                toast.push(error.message, {classes: ['toast-danger']});
            });
    } else {
        let savedActiveSets = JSON.parse(localStorage.getItem("cc-active-tag-sets")) || [];
        activeSets = savedActiveSets;
    }
}

function getTagSets() {
    tagSets = [];

    // Refactor to get all tag sets for a user depending on if environment.online is set to true or false. If false, get from local storage.
    if ($environment.online) {
        return homeRef
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    tagSets = [...tagSets, doc.data()];
                });

                selectedTagSet = tagSets[0];
            }).catch((error) => {
                console.log("Error getting tag sets: ", error);
                toast.push(error.message, {classes: ['toast-danger']});
            });
    } else {
        let savedTagSets = JSON.parse(localStorage.getItem("cc-tag-sets")) || [];
        tagSets = savedTagSets;
        selectedTagSet = tagSets[0];
    }
}

function createTagSet() {
    let newTagSet = {
        id: uuidv4(),
        name: "Untitled",
        tags: []
    };

    if ($environment.online) {
        homeRef
            .doc(newTagSet.id)
            .set(newTagSet)
            .then(() => {
                toast.push("New tag set created", {classes: ['toast-success']});

                tagSets = [...tagSets, newTagSet];
                selectedTagSet = tagSets[tagSets.length - 1];
            })
            .catch((error) => {
                console.log("Error writing new set: ", error);
                toast.push(error.message, {classes: ['toast-danger']});
            });
    } else {
        tagSets = [...tagSets, newTagSet];
        localStorage.setItem("cc-tag-sets", JSON.stringify(tagSets));
    }

}

function removeTagSet(id) {
    if ($environment.online) {
        homeRef.doc(id).delete().then(() => {
            toast.push("Tag set removed successfully", {classes: ['toast-success']});
        }).catch((e) => {
            console.log(e.message, e);
            toast.push("Failed to delete tag set: " + e.message, {classes: ['toast-danger']});
        }).finally(() => {
            gettingTagSets = getTagSets();
        });
    } else {
        tagSets = tagSets.filter(set => {
            return set.id !== id;
        });

        localStorage.setItem("cc-tag-sets", JSON.stringify(tagSets));
    }

}

function selectTagSet(set) {
    selectedTagSet = set;
    // console.log(selectedTagSet);
}

function toggleSet(set) {
    if (activeSets.indexOf(set.id) > -1) {
        activeSets = activeSets.filter(s => {
            return s !== set.id;
        });

    } else {
        activeSets = [...activeSets, set.id];
    }

    saveActiveSets();
}

function saveActiveSets() {
    if ($environment.online){
        userRef.update({
            tags: activeSets
        });
    } else {
        localStorage.setItem("cc-active-tag-sets", JSON.stringify(activeSets));
    }
    
}

function saveSet(set) {
    if (!set) {
        return;
    }

    let tagSetObject = JSON.parse(JSON.stringify(set));
    if ($environment.online){
        homeRef
        .doc(set.id)
        .update(tagSetObject)
        .then(() => {
            let index = tagSets.findIndex(tagSet => {
                return tagSet.id === set.id
            });

            tagSets[index] = set;

            toast.push("Tag set changes saved", {classes: ['toast-success']});
        })
        .catch((error) => {
            console.log("Error saving set: ", error);
            toast.push(error.message, {classes: ['toast-danger']});

            gettingTagSets = getTagSets();
        });
    } else{
        let index = tagSets.findIndex(tagSet => {
            return tagSet.id === set.id
        });

        tagSets[index] = set;

        localStorage.setItem("cc-tag-sets", JSON.stringify(tagSets));
        toast.push("Tag set changes saved", {classes: ['toast-success']});
    }    
}

function editTagInSet(tag) {
    selectedTag = tag;
    tagTermInput = tag.term;
    tagTypeInput = tag.type;
    tagDefinitionInput = tag.definition;
}

function removeTagFromSet(id) {
    selectedTagSet.tags = selectedTagSet.tags.filter(tag => {
        return tag.id !== id;
    });

    /* Update Event Tags */
    $eventGroupState.forEach(eventGroup => {
        eventGroup.events.forEach((event, i, events) => {
            if (event.tags) {
                events[i].tags = event.tags.filter(t => {
                    return t.id !== id;
                })
            }
        })
    });

    $eventGroupState = $eventGroupState;

    saveSet(selectedTagSet);
    resetForm();
}

function updateTag() {
    let tagIndex = selectedTagSet.tags.findIndex(tag => {
        return tag.id === selectedTag.id;
    });

    if (tagIndex > -1 && tagTermInput) {
        selectedTagSet.tags[tagIndex].term = tagTermInput;
        selectedTagSet.tags[tagIndex].type = tagTypeInput;
        selectedTagSet.tags[tagIndex].definition = tagDefinitionInput;
        selectedTagSet.tags[tagIndex].color = tagColorMapping[tagTypeInput];

        /* Update Event Tags */
        $eventGroupState.forEach(eventGroup => {
            eventGroup.events.forEach((event, i, events) => {
                if (event.tags) {
                    let eventTagIndex = event.tags.findIndex(tgs => {
                        return tgs.id === selectedTagSet.tags[tagIndex].id
                    });

                    if (eventTagIndex > -1) {
                        events[i].tags[eventTagIndex].term = selectedTagSet.tags[tagIndex].term;
                        events[i].tags[eventTagIndex].type = selectedTagSet.tags[tagIndex].type;
                        events[i].tags[eventTagIndex].definition = selectedTagSet.tags[tagIndex].definition;
                    }
                }
            })
        });

        $eventGroupState = $eventGroupState;

        saveSet(selectedTagSet);
        resetForm();
    }
}

function insertTag() {
    let tag = new _Tag({
        term: tagTermInput,
        type: tagTypeInput, //Character, Location, Organization, Phrase, Other
        definition: tagDefinitionInput,
        color: tagColorMapping[tagTypeInput],
    });

    selectedTagSet.tags = [...selectedTagSet.tags, tag];
    console.log(selectedTagSet);

    saveSet(selectedTagSet);
    resetForm();
}

function resetForm() {
    selectedTag = undefined;
    tagTermInput = "";
    tagDefinitionInput = "";
    tagTypeInput = "Character";
}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'tagsManager' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TagSetManageModal">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Custom Tag Manager</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 border-end border-secondary">
                        <div class="d-flex justify-content-end mb-2">
                            <button type="button" class="btn btn-primary btn-sm me-2" title="New Set" on:click={createTagSet}><i class="bi bi-plus-lg" /> New</button>

                            <button type="button" class="btn btn-danger btn-sm {selectedTagSet ? 'text-white' : 'disabled'}" title="Remove Set" on:click={() => removeTagSet(selectedTagSet.id)}><i class="bi bi-x-lg" /> Remove</button>
                        </div>
                        <ul class="nav nav-pills flex-column">
                            {#await gettingTagSets}
                            <p>Loading sets... please wait...</p>
                            {:then}
                            {#each tagSets as set (set.id)}
                            <li class="nav-item">
                                <a class="nav-link {selectedTagSet && selectedTagSet.id === set.id ? 'active' : ''}" href="#!/" on:click={() => selectTagSet(set)}>{set.name} <input class="form-check-input float-end" type="checkbox" checked={activeSets.indexOf(set.id) > -1} on:change={toggleSet(set)} title="Enable/Disable" /></a>
                            </li>
                            {:else}
                            <p class="text-muted small text-center my-5">Please create a new set to start</p>
                            {/each}
                            {/await}
                        </ul>
                    </div>
                    <div class="col">
                        {#if selectedTagSet}
                        <form on:submit|preventDefault={saveSet(selectedTagSet)}>
                            <div class="row mb-2">
                                <div class="col-12 mb-4">
                                    <label class="form-label text-warning fw-bold fs-5" for="TagTerm">Set Name</label>
                                    <input type="text" class="form-control" id="TagTermInput" bind:value={selectedTagSet.name}>
                                </div>
                                <p class="text-warning fw-bold fs-5">Tag Edit Form</p>
                                <div class="col-8">
                                    <label class="form-label" for="TagTerm">Tag Term</label>
                                    <input type="text" class="form-control form-control-sm" id="TagTermInput" bind:value={tagTermInput}>
                                </div>
                                <div class="col-4">
                                    <label class="form-label" for="TagTypeInput">Type</label>
                                    <select class="form-select form-select-sm" id="TagTypeInput" bind:value="{tagTypeInput}">
                                        <option>Character</option>
                                        <option>Location</option>
                                        <option>Organization</option>
                                        <option>Phrase</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                                <div class="col-12 my-2">
                                    <label class="form-label" for="tagDefinitionInput">Definition</label>
                                    <textarea class="form-control" id="tagDefinitionInput" rows="3" bind:value="{tagDefinitionInput}"></textarea>
                                </div>
                            </div>
                            <div class="mb-3 text-end">
                                {#if selectedTag}
                                <button type="button" class="btn btn-warning" disabled={!tagTermInput} on:click="{updateTag}">Update</button>
                                {:else}
                                <button type="button" class="btn btn-primary" disabled={!tagTermInput} on:click="{insertTag}">Insert Tag</button>
                                {/if}
                                <button type="button" class="btn btn-outline-dark" on:click="{resetForm}">Reset</button>
                            </div>
                        </form>
                        <div id="TagTableWrapper" class="table-responsive">
                            <table class="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Term</th>
                                        <th scope="col">Type</th>
                                        <th style="width:125px;" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each selectedTagSet.tags as tag, index (tag.id)}
                                    <tr>
                                        <td>{index+1}</td>
                                        <td><p class="m-0">{tag.term}</p><p class="small text-muted">{tag.definition && tag.definition.length > 50 ? tag.definition.substring(0,50)+"..." : tag.definition}</p></td>
                                        <td><span class="badge bg-{tag.color}">{tag.type}</span></td>
                                        <td class='text-center'>
                                            <button class="btn btn-sm btn-info" type="button" title="Edit Tag" on:click="{editTagInSet(tag)}"><i class="bi bi-pencil-square"></i></button>
                                            <button class="btn btn-sm btn-danger" type="button" title="Remove Tag" on:click="{removeTagFromSet(tag.id)}"><i class="bi bi-trash"></i></button>
                                        </td>
                                    </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                        {:else}
                        <p class="text-center text-muted lead my-5">Please select a Tag Set from the menu on the left</p>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                {#if selectedTagSet}
                <button type="button" class="btn btn-primary" on:click={saveSet(selectedTagSet)} disabled={!selectedTagSet.name || selectedTagSet.name === "none"}>Save Changes</button>
                {:else}
                <button type="button" class="btn btn-light" on:click={modalState.hideModal}>Close</button>
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
#TagTableWrapper {
    max-height: 30vh;
    overflow-y: auto;
}
</style>
