<script>
    import { environment } from "@app/store/envStore.js";
    import { authState } from "@app/store/authStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { toast } from "@zerodevx/svelte-toast";

    let search = $uiState.quickTools.search,
        spellCheck = $uiState.quickTools.spellCheck,
        timing = $uiState.quickTools.timing,
        qc = $uiState.quickTools.qc,
        errorNav = $uiState.quickTools.errorNav,
        manualQc = $uiState.quickTools.manualQc,
        templates = $uiState.quickTools.templates,
        voices = $uiState.quickTools.voices,
        speakers = $uiState.quickTools.speakers,
        tags = $uiState.quickTools.tags,
        markers = $uiState.quickTools.markers,
        videoFilters = $uiState.quickTools.videoFilters,
        notes = $uiState.quickTools.notes;

    function updateUiSettings() {
        $uiState.quickTools = {
            search: search,
            spellCheck: spellCheck,
            timing: timing,
            qc: qc,
            manualQc: manualQc,
            templates: templates,
            voices: voices,
            speakers: speakers,
            tags: tags,
            notes: notes,
            markers: markers,
            errorNav: errorNav,
            videoFilters: videoFilters,
        };

        localStorage.setItem("cc-ui-settings", JSON.stringify($uiState));

        toast.push("Settings have been saved successfully.", {
            classes: ["toast-success"],
        });
    }
</script>

<h5>QuickTools Settings</h5>
<p class="small text-muted">
    Customize the visible tools found in the QuickTools drawer located below the
    media player.
</p>
<form on:submit|preventDefault={updateUiSettings}>
    <div class="row">
        <div class="col-6">
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="searchCheck"
                    bind:checked={search}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="searchCheck"
                    >Search & Replace</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="spellCheckCheck"
                    bind:checked={spellCheck}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="spellCheckCheck"
                    >Spell Check</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="timingCheck"
                    bind:checked={timing}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="timingCheck"
                    >Timing & Sync</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="qcCheck"
                    bind:checked={qc}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="cCheck">QC & Review</label>
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="manualQcCheck"
                    bind:checked={manualQc}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="manualQcCheck"
                    >Manual QC</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="errorNavCheck"
                    bind:checked={errorNav}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="errorNavCheck"
                    >Errors</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="templatesCheck"
                    bind:checked={templates}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="templatesCheck"
                    >Event Templates</label
                >
            </div>
        </div>
        <div class="col-6">
            {#if $authState.ad && $environment.online}
                <div class="mb-3 form-check form-switch">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="voicesCheck"
                        bind:checked={voices}
                        on:change={updateUiSettings}
                    />
                    <label class="form-check-label" for="voicesCheck"
                        >Voices</label
                    >
                </div>
            {/if}
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="speakersCheck"
                    bind:checked={speakers}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="speakersCheck"
                    >Speakers</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="tagsCheck"
                    bind:checked={tags}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="tagsCheck">Tags</label>
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="markersCheck"
                    bind:checked={markers}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="markersCheck"
                    >Markers</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="videoFiltersCheck"
                    bind:checked={videoFilters}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="videoFiltersCheck"
                    >Video Filters</label
                >
            </div>
            <div class="mb-3 form-check form-switch">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="notesCheck"
                    bind:checked={notes}
                    on:change={updateUiSettings}
                />
                <label class="form-check-label" for="notesCheck">Notes</label>
            </div>
        </div>
    </div>
</form>
