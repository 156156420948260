<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';
import { createEventDispatcher} from 'svelte';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';
import Swal from 'sweetalert2';

const dispatch = createEventDispatcher();
async function deleteEvents() {
    try {
        const eventsToDelete = $eventGroupState[$projectState.selected].selected.length;
        
        // Show confirmation dialog
        const result = await Swal.fire({
            title: 'Confirm Deletion',
            text: `Are you sure you want to delete ${eventsToDelete} event${eventsToDelete !== 1 ? 's' : ''}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'Cancel',
            customClass : {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary ms-2'
            }
        });
        
        // If user cancels, return early
        if (!result.isConfirmed) {
            return;
        }
        
        // Continue with deletion if confirmed
        $eventGroupState[$projectState.selected].selected.sort((a, b) => {
            return b - a;
        });

        let lastEvent = $eventGroupState[$projectState.selected].selected[$eventGroupState[$projectState.selected].selected.length-1]-1;

        $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
            $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected], eventIndex);
        });

        
        $eventGroupState[$projectState.selected].selected = lastEvent >= 0 ? [lastEvent] : [];

        toast.push("Events deleted from group successfully", {classes: ["toast-success"]});

        historyState.insert({
            name: "remove event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }
}
</script>

<a id="btnDeleteEvents"
   role="menuitem"
   aria-label="Delete selected events"
   class="dropdown-item" href="#!/"
   on:click={deleteEvents}>
   Delete...
</a>
