/* 

FFMPEG Commands
---------------
Facebook 720p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1280:720 -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

Facebook 1080p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1920:1080 -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

Facebook 4k
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=3840:2160 -codec:v libx264 -b 24000000 C:\Users\natha\Videos\Testing\output.mp4

Instagram 1080x1080
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:1080,crop=in_h:in_h" -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

Instagram 1080x1350
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:1350,crop=1080:1350" -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

LinkedIn Mobile
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:1350,crop=1080:1350" -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

LinkedIn Company Page
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:1080,crop=in_h:in_h" -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

LinkedIn 720p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1280:720 -codec:v libx264 -b 15000000 C:\Users\natha\Videos\Testing\output.mp4

Linked 1080p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1920:1080 -codec:v libx264 -b 15000000 C:\Users\natha\Videos\Testing\output.mp4

Proxy 720p
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1280:720 -codec:v libx264 -b 6000000 -preset fast C:\Users\natha\Videos\Testing\output.mp4

Proxy 1080p
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1920:1080 -codec:v libx264 -b 6000000 -preset fast C:\Users\natha\Videos\Testing\output.mp4

Mobile 720p
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:1280,crop=720:1280" -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

Mobile 1080p
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:1920,crop=1080:1920" -codec:v libx264 -b 12000000 C:\Users\natha\Videos\Testing\output.mp4

Twitter 640x640
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf "scale=-1:640,crop=in_h:in_h" -codec:v libx264 -b 1000000 C:\Users\natha\Videos\Testing\output.mp4

Twitter 720p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1280:720 -codec:v libx264 -b 2000000 C:\Users\natha\Videos\Testing\output.mp4

Vimeo 480p Wide
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=854:480 -codec:v libx264 -b 4000000 C:\Users\natha\Videos\Testing\output.mp4

Vimeo 480p SD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=640:480 -codec:v libx264 -b 4000000 C:\Users\natha\Videos\Testing\output.mp4

Vimeo 720p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1280:720 -codec:v libx264 -b 8000000 C:\Users\natha\Videos\Testing\output.mp4

Vimeo 1080p Full HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1920:1080 -codec:v libx264 -b 16000000 C:\Users\natha\Videos\Testing\output.mp4

Vimeo 2160p 4K
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=3840:2160 -codec:v libx264 -b 48000000 C:\Users\natha\Videos\Testing\output.mp4

YouTube 480p Wide
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=854:480 -codec:v libx264 -b 8000000 C:\Users\natha\Videos\Testing\output.mp4

YouTube 480p SD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=640:480 -codec:v libx264 -b 8000000 C:\Users\natha\Videos\Testing\output.mp4

YouTube 720p HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1280:720 -codec:v libx264 -b 16000000 C:\Users\natha\Videos\Testing\output.mp4

YouTube 1080p Full HD
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=1920:1080 -codec:v libx264 -b 16000000 C:\Users\natha\Videos\Testing\output.mp4

YouTube 2160p 4K
C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\Testing\trailer.mp4 -vf scale=3840:2160 -codec:v libx264 -b 40000000 C:\Users\natha\Videos\Testing\output.mp4

*/
export default {
  presets: [{
    id: "fb720",
    displayName: "Facebook 720p HD"
  }, {
    id: "fb1080",
    displayName: "Facebook 1080p Full HD"
  }, {
    id: "fb4k",
    displayName: "Facebook 4K"
  }, {
    id: "instagram1080",
    displayName: "Instagram 1080x1080"
  }, {
    id: "instagram1350",
    displayName: "Instagram 1080x1350"
  }, {
    id: "linkedinmobile",
    displayName: "LinkedIn Mobile"
  }, {
    id: "linkedincompany",
    displayName: "LinkedIn Company Page"
  }, {
    id: "linkedin720",
    displayName: "LinkedIn 720p HD"
  }, {
    id: "linkedin1080",
    displayName: "LinkedIn 1080p Full HD"
  }, {
    id: "proxy480",
    displayName: "Proxy 480p"
  }, {
    id: "proxy720",
    displayName: "Proxy 720p"
  }, {
    id: "proxy1080",
    displayName: "Proxy 1080p"
  }, {
    id: "mobile720",
    displayName: "Mobile 9x16 720p"
  }, {
    id: "mobile1080",
    displayName: "Mobile 9x16 1080p"
  }, {
    id: "screener480",
    displayName: "Screener 480p"
  }, {
    id: "screener960",
    displayName: "Screener 960p"
  }, {
    id: "screener1440",
    displayName: "Screener 1440p"
  }, {
    id: "twitter640",
    displayName: "Twitter 640x640"
  }, {
    id: "twitter720",
    displayName: "Twitter 720p"
  }, {
    id: "vimeo480wide",
    displayName: "Vimeo 480p Wide"
  }, {
    id: "vimeo480sd",
    displayName: "Vimeo 480p SD"
  }, {
    id: "vimeo720",
    displayName: "Vimeo 720p HD"
  }, {
    id: "vimeo1080p",
    displayName: "Vimeo 1080p Full HD"
  }, {
    id: "vimeo4k",
    displayName: "Vimeo 2160p 4K"
  }, {
    id: "youtube480",
    displayName: "YouTube 480p SD"
  }, {
    id: "youtube720",
    displayName: "YouTube 720p HD"
  }, {
    id: "youtube1080",
    displayName: "YouTube 1080p Full HD"
  }, {
    id: "youtube4k",
    displayName: "YouTube 2160p 4K"
  }, {
    id: "proresProxy",
    displayName: "ProRes Proxy"
  }, {
    id: "proresLt",
    displayName: "ProRes LT"
  }, {
    id: "proresSq",
    displayName: "ProRes SQ"
  }, {
    id: "proresHq",
    displayName: "ProRes HQ"
  }],
  profiles: {
    "screener480": {
      scale: {
        h: 480,
        v: 270
      },
      codec: "libx264",
      bitrate: 2000000,
      ext: "mp4"
    },
    "screener960": {
      scale: {
        h: 960,
        v: 540
      },
      codec: "libx264",
      bitrate: 4000000,
      ext: "mp4"
    },
    "screener1440": {
      scale: {
        h: 1440,
        v: 810
      },
      codec: "libx264",
      bitrate: 8000000,
      ext: "mp4"
    },
    "fb720": {
      scale: {
        h: 1280,
        v: 720
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4"
    },
    "fb1080": {
      scale: {
        h: 1920,
        v: 1080
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4"
    },
    "fb4k": {
      scale: {
        h: 3840,
        v: 2160
      },
      codec: "libx264",
      bitrate: 24000000,
      ext: "mp4"
    },
    "instagram1080": {
      scale: {
        h: -1,
        v: 1080
      },
      crop: {
        h: '1080',
        v: '1080'
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4",
      pixelFormat: 'yuv420p'
    },
    "instagram1350": {
      scale: {
        h: -1,
        v: 1350
      },
      crop: {
        h: 1080,
        v: 1350
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4",
      pixelFormat: 'yuv420p'
    },
    "linkedinmobile": {
      scale: {
        h: -1,
        v: 1350
      },
      crop: {
        h: 1080,
        v: 1350
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4"
    },
    "linkedincompany": {
      scale: {
        h: -1,
        v: 1080
      },
      crop: {
        h: 1080,
        v: 1080
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4"
    },
    "linkedin720": {
      scale: {
        h: 1280,
        v: 720
      },
      codec: "libx264",
      bitrate: 15000000,
      ext: "mp4"
    },
    "linkedin1080": {
      scale: {
        h: 1920,
        v: 1080
      },
      codec: "libx264",
      bitrate: 15000000,
      ext: "mp4"
    },
    "proxy480": {
      scale: {
        h: 640,
        v: 480
      },
      codec: "libx264",
      bitrate: 2000000,
      ext: "mp4",
      proxy: true
    },
    "proxy720": {
      scale: {
        h: 1280,
        v: 720
      },
      codec: "libx264",
      bitrate: 6000000,
      ext: "mp4",
      proxy: true
    },
    "proxy1080": {
      scale: {
        h: 1920,
        v: 1080
      },
      codec: "libx264",
      bitrate: 6000000,
      ext: "mp4",
      proxy: true
    },
    "mobile720": {
      scale: {
        h: -1,
        v: 1280
      },
      crop: {
        h: 720,
        v: 1280
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4"
    },
    "mobile1080": {
      scale: {
        h: -1,
        v: 1920
      },
      crop: {
        h: 1080,
        v: 1920
      },
      codec: "libx264",
      bitrate: 12000000,
      ext: "mp4"
    },
    "twitter640": {
      scale: {
        h: -1,
        v: 640
      },
      crop: {
        h: 640,
        v: 640
      },
      codec: "libx264",
      bitrate: 1000000,
      ext: "mp4"
    },
    "twitter720": {
      scale: {
        h: 1280,
        v: 720
      },
      codec: "libx264",
      bitrate: 2000000,
      ext: "mp4"
    },
    "vimeo480wide": {
      scale: {
        h: 854,
        v: 480
      },
      codec: "libx264",
      bitrate: 4000000,
      ext: "mp4"
    },
    "vimeo480sd": {
      scale: {
        h: 640,
        v: 480
      },
      codec: "libx264",
      bitrate: 4000000,
      ext: "mp4"
    },
    "vimeo720": {
      scale: {
        h: 1280,
        v: 720
      },
      codec: "libx264",
      bitrate: 8000000,
      ext: "mp4"
    },
    "vimeo1080p": {
      scale: {
        h: 1920,
        v: 1080
      },
      codec: "libx264",
      bitrate: 16000000,
      ext: "mp4"
    },
    "vimeo4k": {
      scale: {
        h: 3840,
        v: 2160
      },
      codec: "libx264",
      bitrate: 48000000,
      ext: "mp4"
    },
    "youtube480": {
      scale: {
        h: 640,
        v: 480
      },
      codec: "libx264",
      bitrate: 8000000,
      ext: "mp4"
    },
    "youtube720": {
      scale: {
        h: 1280,
        v: 720
      },
      codec: "libx264",
      bitrate: 16000000,
      ext: "mp4"
    },
    "youtube1080": {
      scale: {
        h: 1920,
        v: 1080
      },
      codec: "libx264",
      bitrate: 16000000,
      ext: "mp4"
    },
    "youtube4k": {
      scale: {
        h: 3840,
        v: 2160
      },
      codec: "libx264",
      bitrate: 40000000,
      ext: "mp4"
    },
    "proresProxy": {
      codec: "prores_ks",
      profile: 0,
      scale: {
        h: 1920,
        v: 1080
      },
      ext: "mov"
    },
    "proresLt": {
      codec: "prores_ks",
      profile: 1,
      scale: {
        h: 1920,
        v: 1080
      },
      ext: "mov"
    },
    "proresSq": {
      codec: "prores_ks",
      aCodec: "pcm_s16le",
      profile: 2,
      scale: {
        h: 1920,
        v: 1080
      },
      ext: "mov"
    },
    "proresHq": {
      codec: "prores_ks",
      aCodec: "pcm_s16le",
      profile: 3,
      scale: {
        h: 1920,
        v: 1080
      },
      ext: "mov"
    }
  }
};