<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import { tick } from 'svelte';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';
async function cutEvents(){
    if ($eventGroupState[$projectState.selected].selected.length > 0) {
        let textToCopy, range = quillEditor.getSelection();
        if (range && range.length !== 0 && $eventGroupState[$projectState.selected].selected.length === 1) {
            textToCopy = quillEditor.getText(range.index, range.length);
            quillEditor.deleteText(range.index, range.length);
        } else {
            let eventsToCut = [];
            let selectedEvents = $eventGroupState[$projectState.selected].selected.map(selectedEvent => {return selectedEvent});

            $eventGroupState[$projectState.selected].selected = [];
            /* Important! */
            await tick();
            selectedEvents.sort((a, b) => {
                return b - a;
            }).forEach(eventIndex => {
                eventsToCut.push($eventGroupState[$projectState.selected].events[eventIndex]);
                $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected],eventIndex);
            });
            
            textToCopy = JSON.stringify(eventsToCut);
        }

        navigator.clipboard.writeText(textToCopy);

        historyState.insert({
            name: "cut event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    }
}
</script>

<li class="nav-item" title="Cut Event/Text">
    <a class="nav-link" href="#!/" class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" on:click="{cutEvents}"><i class="bi bi-scissors" /></a>
</li>
