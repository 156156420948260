<script>
/* 
    Based on: https://itectec.com/superuser/ffmpeg-merge-two-audio-file-with-defined-overlapping-time/
    Generate Voice Over:
    C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -y -i C:\Users\natha\Videos\fcf0ef1c-c17e-4cf9-b9c9-73a3e5f2deff.mp3 -i C:\Users\natha\Videos\6f0f6133-c5d1-49a1-b992-f26e2dbf35ad.mp3 -filter_complex "[0]adelay=delays=5s:all=1[a1];[1]adelay=delays=2s:all=1[a2];[a1][a2]amix=inputs=2[dv];[dv]speechnorm[master]" -map "[master]" C:\Users\natha\Videos\output.mp3

    C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -y -i C:\Users\natha\Videos\trailer.mp4 -i C:\Users\natha\Videos\fcf0ef1c-c17e-4cf9-b9c9-73a3e5f2deff.mp3 -i C:\Users\natha\Videos\6f0f6133-c5d1-49a1-b992-f26e2dbf35ad.mp3 -filter_complex "[1]adelay=10s|0[a1];[2]adelay=6s|0[a2];[0:a][a1][a2]amix=inputs=3[a]" -map "[a]"  C:\Users\natha\Videos\output.mp3

    HOW TO MEASURE LOUDNESS:
    ------------------------
    ffmpeg.exe -nostats -i "C:\Users\natha\Videos\DV\AD Testing 07-04_MIXDOWN.wav" -filter_complex ebur128 -f null -
*/

import {
    modalState
} from "@app/store/modalStore.js";
import {
    fade
} from "svelte/transition";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    playerState
} from "@app/store/playerStore.js";
import {
    authState
} from "@app/store/authStore.js";

import Swal from "sweetalert2";
import throttle from 'just-throttle';
/* Firebase */
import firebase from "@app/configs/firebase.js";
import "firebase/compat/functions";

/* CC LIB */
import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";

import {
    v4 as uuidv4
} from "uuid";

/* Electron/FFMPEG */
const {
    shell
} = require("electron");
const ipcRenderer = window.ipcRenderer;
const ffmpegPath = require("ffmpeg-static-electron").path;
const ffmpeg = require("fluent-ffmpeg");
const fs = window.fs;
const fsSync = window.fsSync;
const path = window.path;
const exec = window.exec;

import { WaveFile } from 'wavefile';

if ($authState.status === "in_trial") {
    showTrialWarning();
}

ffmpeg.setFfmpegPath(ffmpegPath.replace("app.asar", "app.asar.unpacked"));
let loudnessPresets = [{
        displayName: "-13 LUFS",
        target: -13,
        range: 7,
        peak: -2,
    },
    {
        displayName: "-16 LUFS",
        target: -16,
        range: 7,
        peak: -2,
    },
    {
        displayName: "-23 LUFS, EBU R 128",
        target: -23,
        range: 7,
        peak: -2,
    },
    {
        displayName: "-24 LUFS, ATSC A/85",
        target: -24,
        range: 7,
        peak: -2,
    },
];

let audioExportProfiles = [{
        displayName: "FLAC",
        format: "flac",
        samples: 44100,
        resolution: 24,
        bitrate: 1411000,
        codec: "flac",
    },
    {
        displayName: "MP3 128kbs",
        format: "mp3",
        samples: 48000,
        resolution: 24,
        bitrate: 128000,
        codec: "libmp3lame",
    },
    {
        displayName: "MP3 192kbs",
        format: "mp3",
        samples: 48000,
        resolution: 24,
        bitrate: 192000,
        codec: "libmp3lame",
    },
    {
        displayName: "MP3 256kbs",
        format: "mp3",
        samples: 48000,
        resolution: 24,
        bitrate: 256000,
        codec: "libmp3lame",
    },
    {
        displayName: "MP3 320kbs",
        format: "mp3",
        samples: 48000,
        resolution: 24,
        bitrate: 320000,
        codec: "libmp3lame",
    },
    {
        displayName: "WAV PCM 48k/24Bit",
        format: "wav",
        samples: 48000,
        resolution: 24,
        codec: "pcm_s24le",
        bitrate: 2822000,
    },
    {
        displayName: "WAV PCM 44.1k/16Bit",
        format: "wav",
        samples: 44100,
        resolution: 16,
        codec: "pcm_s16le",
        bitrate: 1411000,
    },
];

let autoMixPresets = [
    {   
        displayName: "Default",
        ratio: 4,
        threshold: 0.015,
        release: 850, //0.01-9000
        attack: 200, //0.01-2000
        knee: 4,
        mix: 0.7,
    },
    {   
        displayName: "Default - High Ratio",
        ratio: 6,
        threshold: 0.015,
        release: 850, //0.01-9000
        attack: 200, //0.01-2000
        knee: 4,
        mix: 0.7
    },
    {
        displayName: "Action/Adventure",
        ratio: 4,
        threshold: 0.04,
        release: 850, //0.01-9000
        attack: 200, //0.01-2000
        knee: 2.5,
        mix: 0.8,
    },
    {
        displayName: "Cartoon",
        ratio: 3,
        threshold: 0.02,
        release: 1000,
        attack: 350,
        knee: 5,
        mix: 0.7,
    },
    {
        displayName: "Cartoon - High Ratio",
        ratio: 5,
        threshold: 0.01,
        release: 1200,
        attack: 350,
        knee: 3,
        mix: 0.7,
    },
    {
        displayName: "Commercial",
        ratio: 2,
        threshold: 0.02,
        release: 800,
        attack: 200,
        knee: 4,
        mix: 0.8,
    },
    {
        displayName: "Commercial - High Ratio",
        ratio: 5,
        threshold: 0.013,
        release: 800,
        attack: 200,
        knee: 4,
        mix: 0.8,
    },
    {
        displayName: "Documentary",
        ratio: 4,
        threshold: 0.025,
        release: 1000,
        attack: 400,
        knee: 4,
        mix: 0.8,
    },
    {
        displayName: "Drama",
        ratio: 4,
        threshold: 0.015,
        release: 750,
        attack: 200,
        knee: 4,
        mix: 0.8,
    },
    {
        displayName: "Music",
        ratio: 3,
        threshold: 0.025,
        release: 500,
        attack: 300,
        knee: 4,
        mix: 0.5,
    },
    {
        displayName: "Talkshow",
        ratio: 5,
        threshold: 0.015,
        release: 500,
        attack: 250,
        knee: 2,
        mix: 0.75,
    },
    {
        displayName: "Custom",
        ratio: 5,
        threshold: 0.015,
        release: 1000,
        attack: 300,
        knee: 2,
        mix: 0.75,
    },
];

$eventGroupState[$projectState.selected].selected.sort();

let adExportDefaults = JSON.parse(localStorage.getItem("cc-ad-export")) || {},
    exporting = false,    
    targetFolderPath = sessionStorage.getItem("audioExportPath"),
    selection = false,
    selectionStart = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0]+1 : 1,
    selectionEnd = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[$eventGroupState[$projectState.selected].selected.length - 1]+1 : 1,
    renderQueueSize = 10,
    stitchQueueSize = 150,
    loops = 0,
    progress = 0,
    count = 0,
    eventQueue,
    retryQueue = [],
    retryFlag = false,
    totalEvents,
    voAudioFiles = [],
    statusMsg = "",
    ignoreAll = false,
    copyOfEventGroup,
    eventListElement = document.getElementById("EventList"),
    eventGroupId,
    loudnessPreset,
    loudnessVo = adExportDefaults.loudnessVo,
    loudnessProgram = adExportDefaults.loudnessProgram,
    loudnessMixdown = adExportDefaults.loudnessMixdown,
    keepVoTracks = adExportDefaults.keepVoTracks ?? true,
    audioPreset,
    duration = false,
    showAutomixOptions = false,
    showLoudnessOptions = false,
    voAudioPath,
    voAudioPathB,
    voAudioPathFinal,
    mixdownAudioPath,
    eventGroupIndex,
    extension,
    autoMixPreset = autoMixPresets[0],
    teamId = $authState.team ? $authState.team.id : null,
    teamOwner = $authState.team ? $authState.team.owner : null,
    teamOwnerId = $authState.team ? $authState.team.ownerId : null,
    userId = firebase.auth().currentUser.uid,
    userEmail = firebase.auth().currentUser.email,
    jobInfo = {
        id: uuidv4(),
        teamId: teamId,
        owner: teamOwner,
        ownerId: teamOwnerId,
        projectId: $projectState.id,
        projectName: $projectState.name,
        submittedBy: userEmail,
        userId: userId,
        progress: 100,
        chars: 0, //characters
        cost: 0,
        type: "text to speech",
        statusMsg: "Job Completed",
        status: "Passed",
        createdOn: null,
        updatedOn: null,
        completedOn: null,
        deleted: false,
        config: {
            preset: false,
            loudness: false,
            format: false,
        },
    };

let presetIndex = autoMixPresets.findIndex((preset) => {
    return preset.displayName === adExportDefaults.autoMixPresetName;
});

if (presetIndex > -1) {
    autoMixPreset = autoMixPresets[presetIndex];
}

if (autoMixPreset.displayName === "Custom") {
    autoMixPreset.ratio = adExportDefaults.ratio;
    autoMixPreset.threshold = adExportDefaults.threshold;
    autoMixPreset.release = adExportDefaults.release;
    autoMixPreset.attack = adExportDefaults.attack;
    autoMixPreset.knee = adExportDefaults.knee;
    autoMixPreset.mix = adExportDefaults.mix;
}

if (adExportDefaults.loudnessPreset) {
    loudnessPreset = loudnessPresets.find((preset) => {
        return preset.displayName === adExportDefaults.loudnessPreset.displayName;
    });
} else {
    loudnessPreset = loudnessPresets[0];
}

if (adExportDefaults.audioPreset && adExportDefaults.audioPreset.displayName) {
    audioPreset = audioExportProfiles.find((preset) => {
        return preset.displayName === adExportDefaults.audioPreset.displayName;
    });
}

if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "audio description") {
    eventGroupId = $eventGroupState[$projectState.selected].id;
}

/* Audio Description Export Functions: */
/* 
    1. Begin the export and set all of the global variables for each function
    2. Get the duration of the media (we need this to know how long to make the VO track)
    3. Render each event's audio again
    4. Export VO track
    5. Export mixdown file
    6. Done!

*/
async function updateProgress(percent, msg) {
    console.log("Updating Job:", percent, msg);
    progress = parseFloat(percent).toFixed(2);
    statusMsg = msg;
}

function selectTargetFolder() {
    ipcRenderer
        .invoke("selectFolder", {
            title: "Export Audio Description - Select Folder",
            properties: ["openDirectory", "createDirectory"],
        })
        .then((path) => {
            if (!path.canceled) {
                console.log(path.filePaths[0]);
                targetFolderPath = path.filePaths[0];
                sessionStorage.setItem("audioExportPath", targetFolderPath);
            }
        });
}

function cancelJobWithError(errMessage) {
    toast.push("Error exporting audio description", {classes: ["toast-danger"]});

    modalState.hideModal();
}

async function beginExport() {
    exporting = true;
    updateProgress(0, "Starting Export...");
    toast.push("Exporting Audio Description...", {classes: ["toast-info"]});

    /* Update Job Config */
    jobInfo.createdOn = firebase.firestore.Timestamp.fromDate(new Date());
    jobInfo.config = {
        autoMix: autoMixPreset.displayName,
        loudness: loudnessPreset.displayName,
        format: audioPreset.displayName,
    };
    /* Done updating job config */

    extension = audioPreset.format;
    voAudioPath = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), $projectState.name + "_VO_A." + extension);
    mixdownAudioPath = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), $projectState.name + "_MIXDOWN." + extension);
    voAudioPathB = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), $projectState.name + "_VO_B." + extension);
    voAudioPathFinal = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), $projectState.name + "_VO." + extension);

    /* Get index of the event group to export */
    eventGroupIndex = $eventGroupState.findIndex((evGroup) => {
        return evGroup.id === eventGroupId;
    });

    $eventGroupState[eventGroupIndex] = orderByTime($eventGroupState[eventGroupIndex]);
    copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));
    copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);

    let preCheckResult = await preExportCheck(copyOfEventGroup.events);

    if (preCheckResult.err) {
        cancelExport(eventGroupIndex, preCheckResult.eventId);
    } else {
        getDuration();
    }
}

async function getDuration() {
    if ($playerState.duration) {
        duration = $playerState.duration;
    } else {
        console.info("Media Duration not set. Interrogating media file for duration...");
        /* THERE IS AN ERROR HERE */
        var matches;
        try {
            let ffmpegRes = await exec(`${ffmpegPath.replace("app.asar", "app.asar.unpacked")} -y -i "${$projectState.media.localPath}"`);
            matches = ffmpegRes.match(/(?<=duration\:\s)\d\d:\d\d:\d\d\.\d\d/im);
        } catch (err) {
            matches = err.message.match(/(?<=duration\:\s)\d\d:\d\d:\d\d\.\d\d/im);
            //console.log(matches);
        }

        if (matches) {
            duration = tcLib.tcMsToSec(matches[0]);
            console.log("Duration detected from file:", duration);
        }
    }

    if (duration) {
        await updateProgress(5, "Rendering Event Audio");
        let eventLongerThanDuration = $eventGroupState[$projectState.selected].events.findIndex((event, index) => {
            if (selection) {
                return (event.start > duration || event.end > duration) && (index+1 >= selectionStart && index+1 <= selectionEnd);
            } else {
                return event.start > duration || event.end > duration;
            }
            
        });

        if (eventLongerThanDuration !== -1) {
            console.error(`Event ${eventLongerThanDuration + 1} has a start or end time that exceeds the duration (${duration}s) of the source media file.`);
            cancelJobWithError(`Event ${eventLongerThanDuration + 1} has a start or end time that exceeds the duration (${duration}s) of the source media file.`);
        } else {
            startRenderingEvents();
        }
    } else {
        cancelJobWithError("Unable to determine the duration of the source media file.");
    }
}

function startRenderingEvents() {
    try {
        if ($authState.status === "in_trial") {
            // Only include the first 10 events in the export
            copyOfEventGroup.events = copyOfEventGroup.events.slice(0, 10);
        }


        // if selection is true then only export the events between the selection start and selection end
        if (selection) {
            eventQueue = copyOfEventGroup.events.filter((event, index) => {
                return index+1 >= selectionStart && index+1 <= selectionEnd;
            });
        } else {
            eventQueue = JSON.parse(JSON.stringify(copyOfEventGroup.events));
        }
        
        totalEvents = eventQueue.length;

        let elevenLabsEventIndex = eventQueue.findIndex((evt) => {
            return evt.voice.provider === "ElevenLabs";
        });

        if (elevenLabsEventIndex > -1) {
            renderAudio(eventQueue.splice(0, eventQueue.length > 2 ? 2 : eventQueue.length));
        } else {
            renderAudio(eventQueue.splice(0, eventQueue.length > renderQueueSize ? renderQueueSize : eventQueue.length));
        }
    } catch (err) {
        cancelJobWithError(err.message);
    }
}

function renderAudio(queue) {
    try {
        updateProgress((((totalEvents - eventQueue.length) / totalEvents) * 50).toFixed(2), `Rendering Event Audio (${totalEvents - eventQueue.length}-${Math.min(totalEvents - eventQueue.length + renderQueueSize - 1, totalEvents)} of ${totalEvents})`);
        
        let renderTasks = [];
        queue.forEach(async (event) => {
            let plainText = convertToPlainText(event.text, " ");
            /* update the job config */
            jobInfo.chars += plainText.length;

            if (plainText) {
                try {
                    //Check if event has an audio file path and if that file exists. If it exists, copy it to the target folder, otherwise render it.
                    let fileExists = true;
                    try {
                        if (event.audioFile && !event.audioFile.startsWith(`https://`)){
                            fsSync.accessSync(event.audioFile);
                            console.log("FILE EXISTS ALREADY");
                        }                  
                    } catch (err) {
                        console.log("FILE DOES NOT EXISTS");
                        console.log(err, err.message);
                        fileExists = false;
                    }

                    if (fileExists && event.voice && event.voice.provider !== "google") {
                        //copy file from event.audioFile to targetFolderPath
                        if (event.audioFile.startsWith(`https://`)){
                            renderTasks.push(downloadPromiseInsteadOfRender({
                                event: event,
                                eventGroupId: $eventGroupState[eventGroupIndex].id,
                                eventId: event.id,
                            }));  
                        } else {
                            renderTasks.push(copyPromiseInsteadOfRender({
                                event: event,
                                eventGroupId: $eventGroupState[eventGroupIndex].id,
                                eventId: event.id,
                            }));   
                        }                                             
                    } else {
                        renderTasks.push(
                            firebase.functions().httpsCallable("v8TextToSpeechProviderV4")({
                                provider: event.voice.provider,
                                language: event.voice.language,
                                voice: event.voice.name,
                                voice_id: event.voice.id,
                                stability: event.voiceStability / 100,
                                similarity: event.voiceSimilarity / 100,
                                speed: parseFloat(event.rate),
                                style: event.speakingStyle,
                                audioEncoding: extension === "mp3" ? "mp3" : "wav",
                                text: plainText,
                                eventGroupId: $eventGroupState[eventGroupIndex].id,
                                eventId: event.id,
                            })
                        );
                    } 
                } catch (err) {
                    console.log("ERROR - FAILED CALL TO RENDER CLOUD");
                    console.log(err, err.message);
                    if (!retryFlag) {
                        console.log("FAILED... Adding event to retry queue!");
                        retryQueue.push(event);
                    } else {
                        console.log(err, err.message);
                        cancelJobWithError(err.message);
                    }
                }
            }
        });

        Promise.allSettled(renderTasks)
            .then(async (results) => {
                let eventIndex, audioFilePath;
                console.log(results);
                for (let i = 0; i < results.length; i++) {
                    if (results[i].status === "rejected") {
                        console.log("Text to speech process failed:", results[i]);
                        throw new Error("Rendering Event audio failed. "+results[i].reason.code);
                    } else if (results[i].value.data) {
                        eventIndex = $eventGroupState[eventGroupIndex].events.findIndex((event) => {
                            return event.id === results[i].value.data.eventId;
                        });

                        audioFilePath = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), eventIndex + "." + extension);

                        if (results[i].value.data.copy) {
                            //copy file from event.audioFile to targetFolderPath
                            console.log("Copying file from", results[i].value.data.audio, "to", audioFilePath);
                            if (results[i].value.data.audio !== audioFilePath){
                                await fs.copyFile(results[i].value.data.audio, audioFilePath);
                            }
                        } else {
                            await writeFileStream(results[i].value.data.audio, audioFilePath);
                        }

                        voAudioFiles.push({
                            eventIndex : eventIndex,
                            audioFilePath : audioFilePath,
                        });
                    }
                }

                if (eventQueue.length > 0) {
                    let elevenLabsEventIndex = eventQueue.findIndex((evt) => {
                        return evt.voice.provider === "ElevenLabs";
                    });

                    if (elevenLabsEventIndex > -1) {
                        renderAudio(eventQueue.splice(0, eventQueue.length > 2 ? 2 : eventQueue.length));
                    } else {
                        renderAudio(eventQueue.splice(0, eventQueue.length > renderQueueSize ? renderQueueSize : eventQueue.length));
                    }
                } else if (retryQueue.length > 0) {
                    console.log("RUNNING THE RETRY QUEUE!");
                    retryFlag = true;
                    let elevenLabsEventIndex = retryQueue.findIndex((evt) => {
                        return evt.voice.provider === "ElevenLabs";
                    });

                    if (elevenLabsEventIndex > -1) {
                        renderAudio(retryQueue.splice(0, retryQueue.length > 2 ? 2 : retryQueue.length));
                    } else {
                        renderAudio(retryQueue.splice(0, retryQueue.length > renderQueueSize ? renderQueueSize : retryQueue.length));
                    }
                } else {
                    //exportVoTrack();
                    console.log("Starting audio stitch process");
                    stitchAudioFiles();
                }
            })
            .catch((err) => {
                console.log(err, err.message);
                cancelJobWithError(err.message);
            });
    } catch (err) {
        console.log(err, err.message);
        cancelJobWithError(err.message);
    }
}

function downloadPromiseInsteadOfRender(data){
    return new Promise((resolve, reject) => {
        resolve({
            data: {
                eventGroupId: data.eventGroupId,
                eventId: data.eventId,
                audio: data.event.audioFile,
                duration: data.event.audioFileDuration,
                copy: false
            }
        });
    });
}

function copyPromiseInsteadOfRender(data) {
    return new Promise((resolve, reject) => {
        resolve({
            data: {
                eventGroupId: data.eventGroupId,
                eventId: data.eventId,
                audio: data.event.audioFile,
                duration: data.event.audioFileDuration,
                copy: true
            }
        });
    });
}

function stitchAudioFiles() {
    let ffmpegCmd = ffmpeg(),
        filterString = "",
        audioTracks = [],
        i = 0;

    loops++;

    if (loops > 1) {
        ffmpegCmd.addInput(loops % 2 === 0 ? voAudioPath : voAudioPathB);
        filterString = `[0]adelay=0|0[a0];`;
        audioTracks.push(`[a0]`);
        i++;
    }

    for (i; i < stitchQueueSize; i++) {
        if (count < totalEvents) {
            console.log(`Stitching Event ${count} of ${totalEvents}`);
            console.log(voAudioFiles[count], voAudioFiles[count].audioFilePath, voAudioFiles[count].eventIndex);
            console.log(copyOfEventGroup.events[voAudioFiles[count].eventIndex].start);
            ffmpegCmd.addInput(voAudioFiles[count].audioFilePath);
            filterString += `[${i}]adelay=${Math.max(0, parseInt(copyOfEventGroup.events[voAudioFiles[count].eventIndex].start * 1000))}|${Math.max(0, parseInt(copyOfEventGroup.events[voAudioFiles[count].eventIndex].start * 1000))}[a${i}];`;
            audioTracks.push(`[a${i}]`);
            count++;
        }
    }

    filterString += `${audioTracks.join("")}amix=inputs=${audioTracks.length}:normalize=disabled[master]`;

    ffmpegCmd.audioCodec(audioPreset.codec);
    ffmpegCmd.audioChannels(1);

    if (audioPreset.format === "mp3") {
        ffmpegCmd.audioBitrate(audioPreset.bitrate);
    } else {
        ffmpegCmd.audioFrequency(audioPreset.samples);
    }

    ffmpegCmd
        .complexFilter(`${filterString}`)
        .outputOptions(`-map [master]`)
        .output(loops % 2 === 0 ? voAudioPathB : voAudioPath)
        .on("start", function(commandLine) {
            console.log("Spawned Ffmpeg with command: " + commandLine);
            updateProgress(60, `Stitching VO Track... ${count}/${totalEvents} | 00:00:00.00`);
        })
        .on("progress", function(progress) {
            /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
            console.log("Exporting", progress.timemark);
            updateProgress(65, `Stitching VO Track... ${count}/${totalEvents} | ${progress.timemark}`);
        })
        .on("end", function() {
            console.log("Finished Exporting");
            if (count < voAudioFiles.length) {
                stitchAudioFiles();
            } else {
                exportVoTrack();
            }
        })
        .on("error", function(err, stdout, stderr) {
            console.error(`Error exporting VO Track ${count}. ` + err.message, err);
            updateProgress(100, `Failed to export audio VO at event index ${count}`, true);
            console.error(stderr);
            console.error(stdout);
            toast.push("Error exporting VO Track", {classes: ["toast-danger"]});

            cancelJobWithError(err.message);
        })
        .run();
}

async function exportVoTrack() {
    /* ffmpeg -i C:\Users\natha\Videos\DVTEST\0.mp3 -i C:\Users\natha\Videos\DVTEST\1.mp3 -i C:\Users\natha\Videos\DVTEST\2.mp3 -y -filter_complex [0]adelay=1515|1515[a0];[1]adelay=6734|6734[a1];[2]adelay=16140|16140[a2];[a0][a1][a2]amix=inputs=3:normalize=disabled[dv];[dv]apad=whole_dur=52209ms[master] -acodec pcm_s24le -ac 2 -ar 48000 -map [master] C:\Users\natha\Videos\DVTEST\Audio Description Project_VO.wav */

    console.log("Exporting VO Track");
    let ffmpegCmd = ffmpeg(),
        filterString = "";

    console.log("Using VO Track:", loops % 2 === 0 ? voAudioPathB : voAudioPath);

    ffmpegCmd.addInput(loops % 2 === 0 ? voAudioPathB : voAudioPath);

    filterString += `apad=whole_dur=${parseInt(duration * 1000)}ms[master]${loudnessVo ? ";[master]loudnorm=I=" + loudnessPreset.target + ":dual_mono=true:LRA=" + loudnessPreset.range + ":TP=" + loudnessPreset.peak + "[master2]" : ""}`;

    ffmpegCmd.audioCodec(audioPreset.codec);
    ffmpegCmd.audioChannels(1);

    if (audioPreset.format === "mp3") {
        ffmpegCmd.audioBitrate(audioPreset.bitrate);
    } else {
        ffmpegCmd.audioFrequency(audioPreset.samples);
    }

    ffmpegCmd
        .complexFilter(`${filterString}`)
        .outputOptions(`-map [master${loudnessVo ? "2" : ""}]`)
        .output(loops % 2 === 0 ? voAudioPath : voAudioPathB)
        .on("start", function(commandLine) {
            console.log("Spawned Ffmpeg with command: " + commandLine);
            updateProgress(70, "Exporting Audio VO...");
        })
        .on("progress", function(progress) {
            /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
            console.log("Exporting", progress.timemark);
            updateProgress(75, `VO Export in progress... ${progress.timemark}`);
        })
        .on("end", function() {
            updateProgress(85, "Exporting Audio VO complete...");
            console.log("Finished Exporting");
            exportMixdownFile();
        })
        .on("error", function(err, stdout, stderr) {
            console.error("Error exporting VO Track. " + err.message, err);
            updateProgress(100, "Failed to export audio VO", true);
            console.error(stderr);
            console.error(stdout);
            toast.push("Error exporting VO Track", {classes: ["toast-danger"]});

            cancelJobWithError(err.message);
        })
        .run();
}

async function exportMixdownFile() {
    try {
        let ffmpegCmd = ffmpeg();
        ffmpegCmd.audioCodec(audioPreset.codec);
        ffmpegCmd.audioChannels(2);

        if (audioPreset.format === "mp3") {
            ffmpegCmd.audioBitrate(audioPreset.bitrate);
        } else {
            ffmpegCmd.audioFrequency(audioPreset.samples);
        }

        if (loops % 2 === 0) {
            await fs.rename(voAudioPath, voAudioPathFinal);
            await fs.unlink(voAudioPathB);
        } else {
            await fs.rename(voAudioPathB, voAudioPathFinal);
            await fs.unlink(voAudioPath);
        }

        await updateProgress(90, "Exporting audio mixdown...");

        ffmpegCmd
            .addInput($projectState.media.localPath)
            .addInput(voAudioPathFinal)
            .complexFilter(`[1:a]asplit=2[sc][mix];[0:a][sc]sidechaincompress=ratio=${autoMixPreset.ratio}:threshold=${autoMixPreset.threshold}:release=${autoMixPreset.release}:attack=${autoMixPreset.attack}:knee=${autoMixPreset.knee}[compr];[compr][mix]amix=inputs=2:normalize=disabled[master]${loudnessMixdown ? ";[master]loudnorm=I=" + loudnessPreset.target + ":LRA=" + loudnessPreset.range + ":TP=" + loudnessPreset.peak + "[master2]" : ""}`)
            .outputOptions(`-map [master${loudnessMixdown ? "2" : ""}]`);

        ffmpegCmd
            .output(mixdownAudioPath)
            .on("start", function(commandLine) {
                console.log("Spawned Ffmpeg with command: " + commandLine);
                updateProgress(95, "Audio mixdown starting...");
            })
            .on("progress", function(progress) {
                /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
                console.log("Exporting", progress.timemark);
                updateProgress(98, `Audio mixdown started... ${progress.timemark}`);
            })
            .on("end", function() {
                updateProgress(100, "Audio mixdown complete...");
                console.log("Finished Exporting");
                toast.push("Audio Description Export", {classes: ["toast-success"]});

                if (extension === 'wav' && bwfMetadata.enable) {
                    embedBwfMetadata(mixdownAudioPath);
                }

                jobInfo.cost = jobInfo.chars * 0.00005;
                jobInfo.updatedOn = firebase.firestore.Timestamp.fromDate(new Date());
                jobInfo.completedOn = firebase.firestore.Timestamp.fromDate(new Date());
                firebase.functions().httpsCallable("v8FinalizeAudioJob")(jobInfo);
                if (!keepVoTracks) {
                    for (let voTrack = 0; voTrack < voAudioFiles.length; voTrack++) {
                        try {
                            fs.unlink(voAudioFiles[voTrack].audioFilePath, (err) => {
                                if (err) {
                                    console.error(err);
                                }
                            });
                        } catch(err){
                            console.log("Error deleting vo track file", voAudioFiles[voTrack]);
                            console.log(err.message);
                        }                        
                    }
                }

                exporting = false;
                localStorage.setItem(
                    "cc-ad-export",
                    JSON.stringify({
                        autoMixPresetName: autoMixPreset.displayName,
                        keepVoTracks : keepVoTracks,
                        ratio: autoMixPreset.ratio,
                        threshold: autoMixPreset.threshold,
                        release: autoMixPreset.release,
                        attack: autoMixPreset.attack,
                        knee: autoMixPreset.knee,
                        mix: autoMixPreset.mix,
                        loudnessPreset: loudnessPreset,
                        audioPreset: audioPreset,
                        loudnessVo: loudnessVo,
                        loudnessProgram: loudnessProgram,
                        loudnessMixdown: loudnessMixdown,
                    })
                );

                shell.showItemInFolder(mixdownAudioPath);
                modalState.hideModal();
            })
            .on("error", function(err, stdout, stderr) {
                console.log("Error exporting VO Track. " + err.message);
                cancelJobWithError(err.message);
            })
            .run();
    } catch (err) {
        console.log(err, err.message);
        toast.push("Error exporting audio description", {classes: ["toast-danger"]});

        cancelJobWithError(err.message);
    }
}

function embedBwfMetadata(filePath) {
    try {
        let wav = new WaveFile(fsSync.readFileSync(filePath));
        /* wav.bext = {
            description: bwfMetadata.description,
            originator: "Closed Caption Creator",
            originatorReference: jobInfo.id,
            originationDate: new Date().toISOString().split('T')[0],
            originationTime: new Date().toISOString().split('T')[1].split('.')[0],
            timeReference: tcLib.tcToSec(bwfMetadata.referenceTime, $projectState.frameRate, $projectState.dropFrame) * audioPreset.samples,
            version: 1,
            UMID: "",
            codingHistory: ""
        }; */

        wav.bext.originator = "Closed Caption Creator";
        wav.bext.description = bwfMetadata.description || "Created by Closed Caption Creator";
        try {
            if (bwfMetadata.referenceTime) {
                let incodeSec = tcLib.tcToSec(bwfMetadata.referenceTime, $projectState.frameRate, $projectState.dropFrame);
                wav.bext.timeReference = secondsToBWFTimeReference(incodeSec, audioPreset.samples);
            } else {
                wav.bext.timeReference = [0, 0];
            }
        } catch(err){
            console.log("Error setting time reference in BWF metadata");
            console.log(err.message);
            wav.bext.timeReference = [0, 0];
        }

        fsSync.writeFileSync(filePath, wav.toBuffer());
        console.log("BWF Metadata embedded successfully.");
        return;
    } catch (err) {
        console.error("Error embedding BWF Metadata: ", err.message);
    }
}

function secondsToBWFTimeReference(seconds, sampleRate) {
    // Convert seconds to samples
    const totalSamples = Math.floor(seconds * sampleRate);
    
    // Convert to BigInt to handle 64-bit arithmetic
    const samplesBI = BigInt(totalSamples);
    
    // Create a mask for 32 bits
    const mask32 = BigInt(0xFFFFFFFF);
    
    // Get high and low 32-bit values
    const timeReferenceLow = Number(samplesBI & mask32);
    const timeReferenceHigh = Number(samplesBI >> BigInt(32));
    
    return [timeReferenceLow, timeReferenceHigh];
}

async function writeFileStream(fileUrl, filePath) {
    try {
        await ipcRenderer.invoke("downloadRequest", {
            fileUrl: fileUrl,
            filePath: filePath,
        });

        return filePath;
    } catch (err) {
        console.log(err);
        console.log(err.message);
        throw new Error("There was an error receiving the audio stream.");
    }
}

/* Pre-Checks */
function showTrialWarning() {
    Swal.fire({
            titleText: "Trial Mode",
            text: "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. Only the first 10 Events will be exported. Would you like to activate your account in order to remove this restriction?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Activate Account",
            denyButtonText: "Continue Trial",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-primary me-2",
                denyButton: "btn btn-outline-secondary",
            },
        })
        .then((result) => {
            if (result.isConfirmed) {
                activateSubscription();
                return true;
            } else {
                return false;
            }
        })
        .then((res) => {
            if (res) {
                console.log(res);
                showRestartNotification();
            }

            return true;
        })
        .catch((err) => {
            console.log(err);
            console.log(err.message);
        });
}

function showRestartNotification() {
    Swal.fire({
            titleText: "Restart Required",
            text: "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-light",
            },
        })
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
            console.log(err.message);
        });
}

async function cancelExport(eventGroupId, eventId) {
    toast.push("Export Aborted", {classes: ["toast-warning"]})

    $projectState.selected = eventGroupId;
    $eventGroupState[$projectState.selected].selected = [eventId];
    setTimeout(() => {
        try {
            eventListElement.scrollTo(0, eventId * 230);
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, eventId * 230);
        }        
    }, 250);

    modalState.hideModal();
}

function alertUser(msg) {
    let response = Swal.fire({
        titleText: "Export Pre-Checks",
        text: msg,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Abort",
        denyButtonText: "Ignore",
        cancelButtonText: "Ignore All",
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-danger",
            denyButton: "btn btn-light mx-2",
            cancelButton: "btn btn-outline-secondary",
        },
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
            return false;
        } else if (result.isDismissed) {
            ignoreAll = true;
            return false;
        }
    });

    return response;
}

async function preExportCheck(events) {
    for (let i = 0; i < events.length; i++) {
        if (!ignoreAll) {
            if (events[i].text) {
                if (isNaN(events[i].start)) {
                    if (await alertUser(`Event ${i + 1} is missing a start time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i,
                        };
                    }
                } else if (isNaN(events[i].end)) {
                    if (await alertUser(`Event ${i + 1} is missing an end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i,
                        };
                    }
                } else if (events[i].start === events[i].end) {
                    if (await alertUser(`Event ${i + 1} has the same start and end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i,
                        };
                    }
                } else if (events[i].start > events[i].end) {
                    if (await alertUser(`Event ${i + 1} has a start time greater than its end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i,
                        };
                    }
                } else if (i > 0 && events[i].start < events[i - 1].end) {
                    /* Adding check in case overlap is less than a frame */
                    if (events[i - 1].end - events[i].start < 1 / $projectState.frameRate) {
                        events[i].start = events[i - 1].end;
                    } else if (await alertUser(`Event ${i + 1} overlaps with the previous event.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i,
                        };
                    }
                }
            } else {
                if (await alertUser(`Event ${i + 1} has no text.\n Would you like to ABORT your export?`)) {
                    return {
                        err: true,
                        eventId: i,
                    };
                }
            }
        }
    }

    return {
        err: false,
    };
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, { leading: true });

let showBwfMetadataOptions = false;
let bwfMetadata = {
    enable: false,
    title: $projectState.name || "Untitled",
    description: $projectState.description || " ",
    referenceTime: tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame),
};
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'audioDescriptionExport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AudioDescriptionExportModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Audio Description Export</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                {#if exporting}
                <p>
                    <i class="bi bi-exclamation-diamond-fill" />
                    Please leave this window open until your export completes
                </p>
                {/if}
                <form on:submit|preventDefault={beginExport}>
                    <div id="targetFolderInputGroup" class="mb-3 p-3 border border-secondary rounded">
                        <button type="button" class="btn btn-info text-white" on:click={selectTargetFolder} disabled={exporting}><i class="bi bi-folder" /> Export Folder</button>
                        <span class="ms-2 font-italic text-truncate">{targetFolderPath || ""}</span>
                        {#if !targetFolderPath}
                        <small class="form-text text-muted">Select an export location for your audio description files</small>
                        {/if}
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="EventGroupSelection">Event Group</label>
                        <select class="form-select" bind:value={eventGroupId} disabled={exporting}>
                            {#each $eventGroupState.filter((group) => {
                            return group.type === "audio description";
                            }) as eventGroupOption}
                            <option value={eventGroupOption.id} selected={eventGroupId === eventGroupOption.id}>{eventGroupOption.name}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="AudioPreset">Export Profile</label>
                        <select class="form-select" bind:value={audioPreset} disabled={exporting}>
                            {#each audioExportProfiles as audioProfile}
                                <option value={audioProfile}>{audioProfile.displayName}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="row mb-3 align-items-center">
                        <div class="col-6">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="selectionToggle" bind:checked={selection}>
                                <label class="form-check-label" for="selectionToggle">Export Event Selection</label>
                            </div>
                        </div>
                        {#if selection}
                        <div class="col-3">
                            <div class="mb-3">
                                <label for="numericInput1" class="form-label">First Event</label>
                                <input type="number" class="form-control" id="numericInput1" bind:value={selectionStart} min="1" step="1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="mb-3">
                                <label for="numericInput2" class="form-label">Last Event</label>
                                <input type="number" class="form-control" id="numericInput2" bind:value={selectionEnd} min="1" step="1">
                            </div>
                        </div>
                        {/if}
                        <div class="col-6">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="keepVoTracksToggle" bind:checked={keepVoTracks}>
                                <label class="form-check-label" for="keepVoTracksToggle">Keep VO Track Files</label>
                            </div>
                        </div>
                    </div>
                    <p class="lead">
                        <a href="#!/" class="dropdown-toggle" on:click={() => (showAutomixOptions = !showAutomixOptions)}>Automix Settings</a>
                    </p>
                    {#if showAutomixOptions}
                    <div class="mb-3">
                        <label class="form-label fw-bold" for="automixPreset">Mix Preset</label>
                        <select class="form-select" bind:value={autoMixPreset} disabled={exporting}>
                            {#each autoMixPresets as preset}
                            <option value={preset}>{preset.displayName}</option>
                            {/each}
                        </select>
                    </div>
                    {#if autoMixPreset.displayName === "Custom"}
                    <div class="mb-3">
                        <label class="form-label" for="ratioSlider">Ratio</label>
                        <input type="range" bind:value={autoMixPreset.ratio} class="form-range" min="1" max="20" step="0.01" id="ratioSlider" disabled={exporting} />
                        <p class="small text-muted">1:{autoMixPreset.ratio}</p>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="thresholdSlider">Threshold</label>
                        <input type="range" bind:value={autoMixPreset.threshold} class="form-range" min="0.00098000" max="1" step="0.00001" id="thresholdSlider" disabled={exporting} />
                        <p class="small text-muted">{autoMixPreset.threshold}dB</p>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="releaseSlider">Release</label>
                        <input type="range" bind:value={autoMixPreset.release} class="form-range" min="0.01" max="9000" step="1" id="releaseSlider" disabled={exporting} />
                        <p class="small text-muted">{autoMixPreset.release}ms</p>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="attackSlider">Attack</label>
                        <input type="range" bind:value={autoMixPreset.attack} class="form-range" min="0.01" max="2000" step="1" id="attackSlider" disabled={exporting} />
                        <p class="small text-muted">{autoMixPreset.attack}ms</p>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="kneeSlider">Knee</label>
                        <input type="range" bind:value={autoMixPreset.knee} class="form-range" min="1" max="8" step="0.01" id="kneeSlider" disabled={exporting} />
                        <p class="small text-muted">{autoMixPreset.knee}</p>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="mixSlider">Mix</label>
                        <input type="range" bind:value={autoMixPreset.mix} class="form-range" min="0" max="1" step="0.01" id="mixSlider" disabled={exporting} />
                        <p class="small text-muted">{autoMixPreset.mix}</p>
                    </div>
                    {/if}
                    {/if}
                    <p class="lead">
                        <a href="#!/" class="dropdown-toggle" on:click={() => (showLoudnessOptions = !showLoudnessOptions)}>Loudness Processing</a>
                    </p>
                    {#if showLoudnessOptions}
                    <div class="mb-3">
                        <label class="form-label" for="loudnessProfile">Loudness Profile</label>
                        <select class="form-select" bind:value={loudnessPreset} disabled={exporting}>
                            {#each loudnessPresets as preset}
                            <option value={preset}>{preset.displayName}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="voiceOverCheck" bind:checked={loudnessVo} />
                                <label class="form-check-label" for="voiceOverCheck">Voice Over</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="programCheck" bind:checked={loudnessProgram} />
                                <label class="form-check-label" for="programCheck">Program Audio</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="mixdownCheck" bind:checked={loudnessMixdown} />
                                <label class="form-check-label" for="mixdownCheck">Mixdown</label>
                            </div>
                        </div>
                    </div>
                    {/if}
                    {#if audioPreset && audioPreset.format === 'wav'}
                    <p class="lead">
                        <a href="#!/" class="dropdown-toggle" on:click={() => (showBwfMetadataOptions = !showBwfMetadataOptions)}>BWF Metadata</a>
                    </p>
                        {#if showBwfMetadataOptions}
                        <div class="mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="bwfEnable" bind:checked={bwfMetadata.enable} />
                                <label class="form-check-label" for="bwfEnable">Enable</label>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="bwfTitle">Title</label>
                            <input type="text" class="form-control" id="bwfTitle" bind:value={bwfMetadata.title} disabled={!bwfMetadata.enable} />
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="bwfDescription">Description</label>
                            <textarea class="form-control" id="bwfDescription" bind:value={bwfMetadata.description} disabled={!bwfMetadata.enable}></textarea>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="bwfReferenceTime">Reference Time</label>
                            <input type="text" class="form-control" id="bwfReferenceTime" bind:value={bwfMetadata.referenceTime} disabled={!bwfMetadata.enable} />
                        </div>
                        {/if}
                    {/if}
                </form>
            </div>
            <div class="modal-footer">
                {#if exporting}
                <div class="progress w-100 mt-2">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">Exporting - {progress}%</div>
                </div>
                <br />
                <p class="text-muted"><i class="bi bi-info-circle" /> {statusMsg}</p>
                {/if}
                <button type="button" disabled={!targetFolderPath || exporting || !eventGroupId} class="btn btn-primary" on:click={beginExport}> Export Audio </button>
            </div>
        </div>
    </div>
</div>
