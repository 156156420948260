import _tcLib from "../../lib/timecode.js";
import _orderByTime from "../eventGroups/orderByTime.js";
const orderByTime = _orderByTime;
const tcLib = _tcLib;
export default (function getAutomaticOffsetAmount(eventGroup, frameRate, incode = "00:00:00:00") {
  orderByTime(eventGroup);
  let tcStart = tcLib.secToTc(eventGroup.events[0].start, frameRate);
  let subtitleHours = tcStart.split(":")[0];
  let incodeHours = incode.split(":")[0];
  let offsetAmount = incodeHours - subtitleHours;
  return tcLib.tcToSec(Math.abs(offsetAmount).toString().padStart(2, '0') + ":00:00:00", frameRate);
});