import { writable } from 'svelte/store';

function loadFontsFromLocalStorage() {
    let fonts = JSON.parse(localStorage.getItem("cc-font-defaults")) || [];
    let newStyleTag = document.createElement('style');
    fonts.forEach(fontName => {
        
        newStyleTag.appendChild(document.createTextNode(`
        @font-face {
            font-family: ${fontName.name};
            src: local('${fontName.name}');
        }`
        ));
        //console.log(`Loading font: ${fontName.name}`);
    });

    document.head.appendChild(newStyleTag);
    return fonts;
}

export const fontState = writable(loadFontsFromLocalStorage());

