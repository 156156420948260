<script>
import {environment} from '@app/store/envStore.js';
import {
    projectState
} from '@app/store/projectStore.js'

import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {authState} from '@app/store/authStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';

import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';

import {
    saveAs
} from 'file-saver';
import { ccprjFunc } from '@app/lib/ccprj.js';
//Get file File type from local storage or default to subtitleExport
let fileType = localStorage.getItem("cc-default-export-settings") || "subtitleExport";

function exportProject() {
    let projectJson = ccprjFunc.createCcprj({
        project : JSON.parse(JSON.stringify($projectState)),
        metadata : JSON.parse(JSON.stringify($metadataState)),
        speakers : JSON.parse(JSON.stringify($speakerState)),
        issues : JSON.parse(JSON.stringify($issueState)),
        markers : JSON.parse(JSON.stringify($markerState)),
        eventGroups : JSON.parse(JSON.stringify($eventGroupState)),
        style : JSON.parse(JSON.stringify($styleState))
    });

    let fileBlob = new Blob([JSON.stringify(projectJson, null, 4)], {
        type: "text/plain;charset=utf-8"
    });

    saveAs(fileBlob, $projectState.name + ".ccprj", {
        autoBom: true
    });

    modalState.hideModal();
}

function saveDefaultSettings(){
    //Save the current settings as the default settings in local storage using the key cc-default-export-settings
    localStorage.setItem("cc-default-export-settings", fileType);
}
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'fileExport' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="exportTitle"
    aria-modal="true"
    tabindex="-1" 
    id="ExportModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exportTitle">File Export</h4>
                <button type="button" 
                    class="btn-close" 
                    aria-label="Close dialog" 
                    on:click={modalState.hideModal}>
                </button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{() => modalState.showModal(fileType)}"
                    aria-label="Export options">
                    {#if $environment.electron}
                        <div class="form-check">
                            <input class="form-check-input" 
                                type="radio" 
                                id="mediaFileCheck"
                                bind:group={fileType} 
                                name="fileType" 
                                value={"videoExport"}
                                on:change={() => saveDefaultSettings()}>
                            <label class="form-check-label" for="mediaFileCheck">Video File</label>
                        </div>
                        {#if $authState.ad}
                            <div class="form-check">
                                <input class="form-check-input" 
                                    type="radio" 
                                    id="audioFileCheck"
                                    bind:group={fileType} 
                                    name="fileType" 
                                    value={"audioDescriptionExport"}
                                    on:change={() => saveDefaultSettings()}>
                                <label class="form-check-label" for="audioFileCheck">Audio Description</label>
                            </div>
                        {/if}
                    {/if}
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="subtitleFileCheck" bind:group={fileType} name="fileType" value={"subtitleExport"} on:change={() => saveDefaultSettings()}>
                        <label class="form-check-label" for="subtitleFileCheck">Subtitle File</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="plainTextFileCheck" bind:group={fileType} name="fileType" value={"transcriptExport"} on:change={() => saveDefaultSettings()}>
                        <label class="form-check-label" for="plainTextFileCheck">Transcript File (.txt, .docx, .csv)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="dialogueListCheck" bind:group={fileType} name="fileType" value={"dialogueListExport"} on:change={() => saveDefaultSettings()}>
                        <label class="form-check-label" for="dialogueListCheck">As-Broadcast Dialogue List (.docx, .csv)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="ccslCheck" bind:group={fileType} name="fileType" value={"ccslExport"} on:change={() => saveDefaultSettings()}>
                        <label class="form-check-label" for="ccslCheck">Combined Continuity &amp; Spotting List (CCSL)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="projectFileCheck" bind:group={fileType} name="fileType" value={"projectExport"} on:change={() => saveDefaultSettings()}>
                        <label class="form-check-label" for="projectFileCheck">Project File</label>
                    </div>
                </form>
            </div>
            {#if fileType === "projectExport"}
            <div class="modal-footer">
                <button class="btn btn-primary" 
                    type="button" 
                    id="exportProjectBtn"
                    aria-label="Export project file"
                    on:click="{exportProject}">Export</button>
            </div>
            {:else}
            <div class="modal-footer">
                <button class="btn btn-primary" 
                    type="button"
                    id="nextBtn"
                    aria-label="Proceed to next step"
                    on:click="{() => modalState.showModal(fileType)}">Next</button>
            </div>
            {/if}
        </div>
    </div>
</div>
