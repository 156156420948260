<script>
import {
    toast } from '@zerodevx/svelte-toast';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
import {
    Circle
} from 'svelte-loading-spinners';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

let machineId = '';
let activationCode = '';
let loading = false;
let errMsg;

function copyActivationCode(){
    navigator.clipboard.writeText(activationCode);
    /* Notification */
    toast.push( `Activation code copied to clipboard successfully.`, {classes: ['toast-`success`']});
}

async function generateActivationCode() {
    try {
        loading = true;
        errMsg = "";
        if (!machineId) throw new Error("Please enter a machine id.");
        let res = await firebase.functions().httpsCallable('v8GenerateOfflineActivationCode')(machineId);
        if (res.data.error) throw new Error(res.data.error);
        activationCode = res.data.activationCode;
    } catch (err) {
        console.log(err, err.message);
        errMsg = err.message;
        /* Notification */
        toast.push(`Failed to generate offline activation code. ${err.message}`, {classes: ['toast-`danger`']});
    } finally {
        loading = false;
    }
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'offlineActivation' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="OfflineActivationModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Offline Activation</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p>Enter the Machine Id from your offline system and click Activate to generate an Activation Code</p>
                <form on:submit|preventDefault="{()=> generateActivationCode()}">
                    <div class="mb-3">
                        <label for="machineIdInput" class="form-label">Machine Id</label>
                        <input placeholder="Offline Machine Id" type="text" class="form-control" id="machineIdInput" bind:value={machineId} disabled={loading}/>
                    </div>
                    <div class="mb-3">
                        <label for="activationCodeInput" class="form-label">Activation Code</label>
                        <input placeholder="Activation Code" type="text" class="form-control" id="activationCodeInput" bind:value={activationCode} on:focus="{(e)=>{e.target.select()}}" disabled={loading} readonly/>
                    </div>
                    {#if loading}
                        <Circle size="30" color="#1eb4b2" unit="px" duration="1s"></Circle>
                        <p>Generating Code... please wait.</p>
                    {/if}
                    {#if errMsg}
                        <p class="m-0 text-danger float-start">{errMsg}</p>
                    {/if}
                    
                </form>
            </div>
            <div class="modal-footer">
                <button disabled={loading || !activationCode} class="btn btn-dark" type="button" on:click="{() => copyActivationCode()}"><i class="bi bi-files"></i> Copy Code</button>
                <button class="btn btn-primary" type="button" on:click={generateActivationCode} disabled={loading}>Generate Code</button>
            </div>
        </div>
    </div>
</div>
