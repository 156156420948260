<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    cliConfigState
} from '@app/store/cliConfigStore.js';
import {
    fade
} from 'svelte/transition';
import { onMount } from 'svelte';
import {
    tick
} from 'svelte';
import {
    BarLoader
} from 'svelte-loading-spinners'
import {
    saveAs
} from 'file-saver';

/* CC LIB */
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import encode from "@app/external/cc-lib/dist/functions/encode.js";
import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";
import encodeHtml from "@app/external/cc-lib/dist/functions/eventGroups/encodeHtml.js";
import offset from "@app/external/cc-lib/dist/functions/events/tcOffset.js";
import formats from "@app/external/cc-lib/dist/lib/formats.js";
import decode from "@app/external/cc-lib/dist/functions/decode.js";
import removeInvalidEvents from "@app/external/cc-lib/dist/functions/eventGroups/removeInvalidEvents.js";
import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import automaticOs from '@app/external/cc-lib/dist/functions/special/automaticOffset.js';
import wrapStyledTextWithSpan from '@app/external/cc-lib/dist/functions/quill/wrapStyledTextWithSpan.js'; 
const { webUtils } = require('electron');
let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};

let language = eventGroupDefaults.language || "en-US",
    rtl = eventGroupDefaults.rtl,
    captionFormat = "mcc";

const profiles = [
    "copy",
    "wrap",
    "mp4faststart",
    "picon",
    "mov-YCbCr8Bit",
    "mov-dvcprohd",
    "mp4-h264",
    "mxf-xdcam-720p",
    "mxf-dvcprohd-720p",
    "mxf-xdcam-1080i",
    "mxf-dvcprohd-1080i",
    "mov-prores422",
    "mov-proreshq",
    "mxf-OP1a-MPEG",
    "mxf-OP1a-h264",
    "mxf-OP1a-HDF",
    "mxf-as-11-hd-dpp",
    "scaledown2000k",
    "scaledown500k",
    "mxf-as-11-sd-pal-dpp",
    "mxf-as-11-sd-ntsc-dpp",
    "hd1080-5000kbs",
    "hd720-2500kbs",
    "hd360-1250kbs",
    "h264-7500kbs",
    "Proxy-h264-5000kbs",
    "LBR-h264-10000kbs",
    "mxf-OP1a-JPEG2K",
    "mxf-AS-02-h264-10",
    "DASH-MP4-Multibitrate",
    "HLS-TS-Multibitrate",
    "TS-TR-01-JPEG-2000",
    "mxf-OP1a_HBR_50",
    "mp3-128kbps",
    "mp4-XAVC-S_4_2_0",
    "mp4-XAVC-S_4_2_2",
    "mov-prores444",
    "mov-proresxq",
    "aces",
    "dnxhd-mxf-720p",
    "dnxhd-mxf-1080p",
    "dnxhd-mxf-1080i",
    "dnxhr-mxf-10-hq",
    "dnxhr-mxf-sq",
    "dnxhr-mxf-lq",
    "TS-MPEG2",
    "TS-h264",
    "wave",
    "MXF-RDD-25",
    "amt3-LowQuality",
    "amt3-StandardQuality",
    "amt3-HiQuality",
    "amt3-HQX_10",
    "amt3-DNxHD36",
    "MP4-MultiOutput",
    "HEVC-h265-10000kbs",
    "mov-xdcam",
    "mkv-ffv1",
    "dnxhr-mxf-hq",
    "mov-proreslt",
    "mov-proresproxy",
    "mp4-vtt-h264",
    "kipro-prores-lt",
    "amt3-XDCam",
    "amt3-Proxy",
    "amt3-Uncompressed",
    "transport_stream",
    "mxf-xdcam35-1080i",
    "mxf-xdcam35-1080p",
    "mxf-xdcam35-720p",
    "mxf-avid-alpha",
    "mxf-jpegxs-main10",
    "mxf-jpegxs-light8",
    "ts-jpegxs-main10",
    "ts-jpegxs-light8",
    "mxf-avci-x00",
    "fmp4-h264",
    "ts-hevc",
    "ts-10bit",
    "mxf-op1a",
    "mxf-sonyhd",
    "mxf-as02",
    "mxf-avid",
    "mxf-amt",
    "mxf-amt-op1a",
    "mp4-fmp4",
    "mp4",
    "mov",
    "ts"
];

/* Electron */
const {
    shell
} = require('electron');
const ipcRenderer = window.ipcRenderer; 
const fs = window.fs;
let path = window.path;
const {
    spawn
} = require('child_process');

let ccEmbedPath = $cliConfigState[0].options[1].selected;
let ccExtractPath = $cliConfigState[0].options[2].selected;
let loggerEl;

onMount(async () => {
    loggerEl = document.getElementById('loggerOutput');
});

let mode = "embed",
    running = false,
    targetFolderPath = "",
    logs = "",
    profile = "wrap",
    files,
    fileArray,
    ccFilePath,
    enableBitRate = false,
    bitRate = 10000,
    tcOffset = "00:00:00:00",
    eventGroup = getSelectedEventGroup(["subtitle", "translation"]);

async function writeToLog(message) {
    logs += message + "\n";
    await tick();
    loggerEl.scrollTop = loggerEl.scrollHeight;
    await tick();
}

async function runProcess() {
    try {
        running = true;
        if (mode === "embed") {
            ccFilePath = await exportSubtitles();
        } else {
            await writeToLog("Starting Extraction process...");
        }

        for (let i = 0; i < files.length; i++) {
            files[i].path = await webUtils.getPathForFile(files[i]);
        }

        fileArray = Array.from(files);

        if (mode === "embed") {
            embedNextFileOrEnd();
        } else {
            extractNextFileOrEnd();
        }

    } catch (err) {
        console.log(err);
        console.log(err.message);
        await writeToLog(err.message);
    }
}

async function embedNextFileOrEnd(){
    if (fileArray.length > 0){
        let nextSrcFile = fileArray.shift();

        await writeToLog(`-----Embedding Closed Captioning into ${nextSrcFile.name}-----`);
        embedCaptions(nextSrcFile, ccFilePath);
    } else {
        await writeToLog("-----Embedding Closed Caption Process Complete-----");     
        await writeToLog("### You can close this window now. ###");     
        shell.showItemInFolder(ccFilePath);
        running = false;
    }

    return;
}

async function embedCaptions(srcFile, ccFile) {
    let targetFile = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), srcFile.name);

    await writeToLog("[CREATOR] Command: " + `"${ccEmbedPath}" -s ${srcFile.path} -t ${targetFile} -cc ${ccFile} -p ${profile} ${(enableBitRate ? "-kilobitrate" : "")} ${(enableBitRate ? bitRate : "")}`);

    let embedProcess = spawn(`${ccEmbedPath}`, ["-s", `${srcFile.path}`, "-t", `${targetFile}`, "-cc", `${ccFile}`, "-p", `${profile}`, (enableBitRate ? "-kilobitrate" : ""), (enableBitRate ? bitRate : "")]);

    embedProcess.stdout.on('data', async (data) => {
        console.log(`stdout: ${data}`);
        await writeToLog(`${data}`);
    });

    embedProcess.stderr.on('data', async (data) => {
        console.error(`stderr: ${data}`);
        await writeToLog(`[DRASTIC stderr]: ${data}`);
    });

    embedProcess.on('close', async (code) => {
        console.log(`child process exited with code ${code}`);
        await writeToLog("[DRASTIC close]: Process closed with code " + code);
        embedNextFileOrEnd();
    });

    embedProcess.on('error', async function (err){
        console.error(err);
        console.error(`[DRASTIc error]: ${err.message}`);
        await writeToLog(`${err.message}`);
    });

    return;
}

async function extractNextFileOrEnd(){
    if (fileArray.length > 0){
        let nextSrcFile = fileArray.shift();
        await writeToLog(`-----Extracting Closed Captioning from ${nextSrcFile.name}-----`);
        extractCaptions(nextSrcFile);
    } else {
        await writeToLog("-----Extracting Closed Caption Process Complete-----");
        await writeToLog("### You can close this window now. ###");     
        running = false;
    }

    return;
}

async function extractCaptions(srcFile) {
    let targetFile = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), srcFile.name + "." + captionFormat);
    await writeToLog("Command: " + `"${ccExtractPath}" -s ${srcFile.path} -t ${targetFile} -c 0`);

    let extractProcess = spawn(`${ccExtractPath}`, ["-i", `${srcFile.path}`, "-t", `${targetFile}`, "-c", `0`]);

    extractProcess.stdout.on('data', async (data) => {
        console.log(`stdout: ${data}`);
        await writeToLog(`[DRASTIC stdout]: ${data}`);
    });

    extractProcess.stderr.on('data', async (data) => {
        console.error(`stderr: ${data}`);
        await writeToLog(`[DRASTIC stderr]: ${data}`);
    });

    extractProcess.on('close', async (code) => {
        console.log(`child process exited with code ${code}`);
        await writeToLog("[DRASTIC close]: Closed Caption Extraction Process Closed");

        await importSubtitleFile(targetFile, srcFile.name);
        extractNextFileOrEnd();
    });

    extractProcess.on('error', async function (err){
        console.error(`error: ${err.message}`);
        await writeToLog(`${err.message}`);
    });

    return;
}

async function importSubtitleFile(ccFilePath, eventGroupName){
    try {
        await writeToLog(`Importing ${captionFormat} File...`);
        let eventGroupOptions = {
                type: "subtitle",
                name: eventGroupName,
                language: language,
                rtl: rtl
            }

        $eventGroupState = [...$eventGroupState, new _EventGroup(eventGroupOptions)];
        $projectState.selected = $eventGroupState.length - 1;

        /* Translate for Creator */
        let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
        let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
        let options = new defaults.options({
            profile: captionFormat === "scc" ? "scenerist" : "macCaption",
            formatOptions: formats.profileMapping[(captionFormat === "scc" ? "Scenarist V1.0" : "MacCaption 608/708")].options.decode,
            frameRate: $projectState.frameRate,
            dropFrame: $projectState.dropFrame,
            window : {
                width: playerWidth,
                height: playerHeight,
                xOffset: ($styleState.xPadding/100) * playerWidth,
                yOffset: ($styleState.yPadding/100) * playerHeight
            }
        });

        let fileContents = await fs.readFile(ccFilePath, 'utf8');
        let decodedEvGroup = await decode(fileContents, options);
        decodedEvGroup = encodeHtml(decodedEvGroup);
        decodedEvGroup = removeInvalidEvents(decodedEvGroup);
        decodedEvGroup = automaticOs(decodedEvGroup, $projectState.frameRate);

        $eventGroupState[$projectState.selected].events = decodedEvGroup.events;

        historyState.insert({
            name: "import subtitles", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        await writeToLog(`${captionFormat} File Imported into ${eventGroupName} Event Group.`);
    } catch(err){
        console.log(err, err.message);
        await writeToLog(err.message);
    } finally {
        return;
    }
}

async function exportSubtitles() {
    await writeToLog(`Exporting ${captionFormat} File...`);
    let eventGroupIndex = $eventGroupState.findIndex(group => {
        return group.id === eventGroup;
    });

    let captionFileName = $eventGroupState[eventGroupIndex].name + "." + captionFormat;
    let copyOfEventGroup = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex]));

    copyOfEventGroup = orderByTime(copyOfEventGroup);
    copyOfEventGroup = removeHtmlEntities(copyOfEventGroup);

    /* tcOffset */
    if (tcOffset && tcOffset !== "00:00:00:00") {
        copyOfEventGroup.events.forEach((event, index, events) => {
            copyOfEventGroup.events[index] = offset(event, tcLib.tcToSec(tcOffset, $projectState.frameRate, $projectState.dropFrame), "add");
        });
    }

    /* Translate for Creator */
    let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
    let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
    let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
    let yPadding = $styleState.mode ? 10 : $styleState.yPadding;

    let options = new defaults.options({
        profile: captionFormat === "scc" ? "scenerist" : "macCaption",
        formatOptions: formats.profileMapping[(captionFormat === "scc" ? "Scenarist V1.0" : "MacCaption 608/708")].options.encode,
        frameRate: $projectState.frameRate,
        dropFrame: $projectState.dropFrame,
        window: {
            width: playerWidth,
            height: playerHeight,
            xOffset: (xPadding/100) * playerWidth,
            yOffset: (yPadding/100) * playerHeight
        }
    });

    copyOfEventGroup.events.forEach((event, index, events) =>{
        events[index].text = wrapStyledTextWithSpan(event.text);
    });

    let output = await encode(copyOfEventGroup, options);
    let outputFilePath = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), captionFileName);

    await fs.writeFile(outputFilePath, output);
    await writeToLog(`${captionFormat} File Created at ${outputFilePath}`);
    return outputFilePath;
}

function clearLogs() {
    logs = "";
}

function exportLogs() {
    let d = new Date();
    let fileBlob = new Blob([logs], {
        type: "text/plain;charset=utf-8"
    });

    saveAs(fileBlob, `drastic_logs_${d.getTime()}.txt`);
}

function selectTargetFolder() {
    ipcRenderer.invoke('selectFolder', {
        title: "Drastic Technologies - Select Folder",
        properties: ["openDirectory", "createDirectory"]
    }).then((path) => {
        if (!path.canceled) {
            targetFolderPath = path.filePaths[0];
            sessionStorage.setItem("drasticDirPath", targetFolderPath);
        }
    });
}

function getSelectedEventGroup(validTypes) {
    let evGroup = $eventGroupState[$projectState.selected];
    if (evGroup) {
        if (validTypes.indexOf(evGroup.type) > -1) {
            return evGroup.id;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function validateTc(e) {
    tcOffset = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
}

</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'drasticTech' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="drasticTechModalTitle"
    aria-describedby="drasticTechModalDesc"
    tabindex="-1" 
    id="DrasticTechModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="drasticTechModalTitle">Drastic Technologies</h4>
                <button type="button" class="btn-close" aria-label="Close Dialog" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-4">
                        <form id="drasticTechForm" aria-label="Drastic Tech Export Options">
                            <div class="mb-3 mb-4">
                                <label class="form-label" for="drasticMode">Mode</label>
                                <select class="form-select" id="drasticMode" bind:value="{mode}" disabled="{running}" aria-disabled="{running}">
                                    <option value="embed">Embed Captions</option>
                                    <option value="extract">Extract Captions</option>
                                </select>
                            </div>

                            <div id="targetFolderInputGroup" class="mb-3 p-3 border border-secondary rounded">
                                <button type="button" id="selectTargetBtn" class="btn btn-info text-white" on:click="{selectTargetFolder}" aria-label="Select Target Folder">
                                    <i class="bi bi-folder" aria-hidden="true"></i> Target Folder
                                </button>
                                <p class="form-text text-muted text-truncate mt-2 mb-0" aria-live="polite">
                                    {targetFolderPath || "No folder selected"}
                                </p>
                                {#if !targetFolderPath}
                                    <p class="form-text text-muted">Select a target location that is used for the final outputs.</p>
                                {/if}
                            </div>

                            {#if mode === "embed"}
                            <div class="mb-3">
                                <label class="form-label" for="eventGroupSelect">Event Group</label>
                                <select id="eventGroupSelect" disabled="{running}" class="form-select" bind:value="{eventGroup}" aria-disabled="{running}">
                                    {#each $eventGroupState.filter(group=>{return group.type === "subtitle" || group.type==="translation"}) as eventGroupOption}
                                    <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                                    {/each}
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="EventGroupSelection">Intermediary Format</label>
                                <select disabled="{running}" class="form-select" bind:value="{captionFormat}">
                                    <option>scc</option>
                                    <option>mcc</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" bind:checked={enableBitRate} id="enableBitRateCheck">
                                    <label class="form-check-label" for="enableBitRateCheck">
                                      Override Target Bitrate
                                    </label>
                                  </div>
                            </div>
                            <div class="mb-3">
                                <!-- Number input for bitrate in kilobytes -->
                                <label for="overrideTargetBitRate" class="form-label">Target Bitrate (kilobits)</label>
                                <input type="number" step="0" min="1" class="form-control" id="overrideTargetBitRate" bind:value={bitRate} disabled={!enableBitRate}>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="DrasticProfileSelection">Profile</label>
                                <select disabled="{running}" class="form-select" bind:value="{profile}">
                                    {#each profiles as profileOption}
                                        <option value="{profileOption}">{profileOption}</option>
                                    {/each}                                    
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="Media Source Files">Source Media File(s)</label>
                                <input type="file" accept=".mxf, .gxf, .mov, .mpg, .mpeg, .mp4" multiple class="form-control" bind:files />
                            </div>
                            {#if files}
                            <div class="mb-3">
                                <select disabled multiple class="form-control">
                                    {#each Array.from(files) as file}
                                        <option>{file.name}</option>
                                    {/each}
                                </select>
                            </div>
                            {/if}

                            <div class="mb-3">
                                <label class="form-label" for="tcOffset">TC Offset</label>
                                <input type="text" class="form-control" bind:value="{tcOffset}" on:blur={validateTc} on:focus="{(e)=>{e.target.select()}}"/>
                            </div>
                            {:else}
                            <div class="mb-3">
                                <label class="form-label" for="MXF Source Files">Source Media File(s)</label>
                                <input type="file" accept=".mxf, .gxf, .mov" multiple class="form-control" bind:files />
                            </div>
                            {#if files}
                            <div class="mb-3">
                                <select multiple class="form-control">
                                    {#each Array.from(files) as file}
                                    <option>{file.name}</option>
                                    {/each}
                                </select>
                            </div>
                            {/if}
                            {/if}

                            <button type="button" class="btn btn-primary" disabled="{!targetFolderPath || running || !files}" on:click="{runProcess}">Start Process</button>
                        </form>
                    </div>
                    <div class="col">
                        <form id="loggerForm" aria-label="Debug Logging">
                            <div class="mb-3">
                                <label class="form-label" for="loggerOutput">Debug Logging</label>
                                <textarea 
                                    id="loggerOutput" 
                                    bind:value="{logs}" 
                                    placeholder="Console logging will appear here to help when debugging" 
                                    disabled 
                                    class="form-control" 
                                    rows="25"
                                    aria-live="polite"
                                    aria-readonly="true"></textarea>
                            </div>
                            <button type="button" id="exportLogsBtn" class="btn btn-primary" on:click="{exportLogs}" aria-label="Export Logs">Export Logs...</button>
                            <button type="button" id="clearLogsBtn" class="btn btn-outline-dark" on:click="{clearLogs}" aria-label="Clear Logs">Clear</button>
                        </form>
                        {#if running}
                        <div style="margin: 5% 0%" role="status" aria-label="Processing">
                            <BarLoader size="250" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                            <p aria-live="polite">Processing... please wait.</p>
                        </div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
textarea {
    font-family: plexMono;
}
</style>
