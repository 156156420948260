<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

function snapToEnd() {
    if ($eventGroupState[$projectState.selected].selected[0] < $eventGroupState[$projectState.selected].events.length - 1) {
        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].start;
        historyState.insert({
            name: "snap to end", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    }
}
</script>
<li class="nav-item" title="Chain to Next Event (Snap End to Start of Next Event)">
    <a class="nav-link" href="#!/" class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" on:click={snapToEnd}><i class="bi bi-align-end"></i></a>
</li>
