<script>
import {
    uiState
} from '@app/store/uiStore.js'
import {
    createEventDispatcher
} from 'svelte';
export let currentState;
const dispatch = createEventDispatcher();

function toggleMenu() {
    dispatch('toggleMenu', {
        menu: 'workspaces'
    });
}

function updateLayout(layout) {
    localStorage.setItem("cc-workspace-default", layout);
    $uiState.timeline = false;
    let source = {};
    if (player && player.source) {
        source = {
            src: player.source
        }

        if (player.isVimeo) {
            source.provider = "vimeo";
        } else if (player.isYouTube) {
            source.provider = "youtube";
        } else {
            source.type = "video/mp4"
        }
    }

    $uiState.layout = layout;

    setTimeout(() => {
        $uiState.timeline = true;
    }, 2000);
    
    if (source.src) {
        setTimeout(() => {
            player.source = {
                type: "video",
                sources: [source]
            };
        }, 100);
    }
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}">
    <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/">Workspaces</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}">
        <a class="dropdown-item" href="#!/" on:click="{() => updateLayout('default')}">Default {#if $uiState.layout === 'default'}<i class="bi bi-check-lg"></i>{/if}</a>
        <a class="dropdown-item" href="#!/" on:click="{() => updateLayout('reverse')}">Default Reverse {#if $uiState.layout === 'reverse'}<i class="bi bi-check-lg"></i>{/if}</a>
        <a class="dropdown-item" href="#!/" on:click="{() => updateLayout('vertical')}">Vertical Stack {#if $uiState.layout === 'vertical'}<i class="bi bi-check-lg"></i>{/if}</a>
        <a class="dropdown-item" href="#!/" on:click="{() => updateLayout('ultrawide')}">Ultrawide Display {#if $uiState.layout === 'ultrawide'}<i class="bi bi-check-lg"></i>{/if}</a>
    </div>
</li>
