import _convertToHtml from "../quill/convertToHtml.js";
import _Event from "../../classes/event.js";
const Event = _Event;
const convertToHtml = _convertToHtml;
export default (function convertWordMapToTranscript(wordMap, maxLines, maxChars, window) {
  let ccEvent = new Event({
      alignment: "left",
      xPos: "start"
    }),
    events = [],
    sentences = [],
    sentence = "";
  //console.log(wordMap);
  wordMap.forEach(word => {
    if (!ccEvent.start && isNaN(ccEvent.start)) {
      ccEvent.start = word.start;
      ccEvent.end = word.end;
      ccEvent.speakers = word.speakers;
      ccEvent.confidence = [word.confidence];
      ccEvent.alignment = "left";
      ccEvent.xPos = "start";
      sentence = word.text;
      return;
    }

    /* Check if Speaker has changed */
    if (word.speakers[0] !== ccEvent.speakers[0]) {
      if (sentence.length > 0) {
        sentences.push(sentence);
      }
      ccEvent.text = convertToHtml(sentences.join("\n"));
      ccEvent.confidence = ccEvent.confidence ? ccEvent.confidence.reduce(function (p, c, i) {
        return p + (c - p) / (i + 1);
      }, 0) : 0; //Calculate Average Confidence
      events.push(ccEvent);
      ccEvent = new Event({
        start: word.start,
        end: word.end,
        speakers: word.speakers,
        confidence: [word.confidence],
        alignment: "left",
        xPos: "start"
      });
      sentences = [];
      sentence = word.text;
      return;
    }

    /* Close Event if there is a gap longer than 6 */
    if (word.start - ccEvent.end > 6) {
      if (sentence.length > 0) {
        sentences.push(sentence);
      }
      ccEvent.text = convertToHtml(sentences.join("\n"));
      ccEvent.confidence = ccEvent.confidence ? ccEvent.confidence.reduce(function (p, c, i) {
        return p + (c - p) / (i + 1);
      }, 0) : 0; //Calculate Average Confidence
      events.push(ccEvent);
      ccEvent = new Event({
        start: word.start,
        end: word.end,
        speakers: word.speakers,
        confidence: [word.confidence],
        alignment: "left",
        xPos: "start"
      });
      sentences = [];
      sentence = word.text;
      return;
    }
    if (sentence.length + word.text.length + 1 > maxChars) {
      sentences.push(sentence);
      if (sentences.length === maxLines) {
        ccEvent.text = convertToHtml(sentences.join("\n"));
        ccEvent.confidence = ccEvent.confidence ? ccEvent.confidence.reduce(function (p, c, i) {
          return p + (c - p) / (i + 1);
        }, 0) : 0; //Calculate Average Confidence
        events.push(ccEvent);
        ccEvent = new Event({
          start: word.start,
          end: word.end,
          speakers: word.speakers,
          confidence: [word.confidence],
          alignment: "left",
          xPos: "start"
        });
        sentences = [];
        sentence = word.text;
        return;
      } else {
        sentence = word.text;
        ccEvent.end = word.end;
      }
    } else {
      sentence += " " + word.text;
      ccEvent.end = word.end;
      ccEvent.confidence && Array.isArray(ccEvent.confidence) ? ccEvent.confidence.push(word.confidence) : ccEvent.confidence = [word.confidence];
    }
  });
  if (sentence.length > 0) {
    sentences.push(sentence);
  }
  ccEvent.text = convertToHtml(sentences.join("\n"));
  ccEvent.confidence = ccEvent.confidence ? ccEvent.confidence.reduce(function (p, c, i) {
    return p + (c - p) / (i + 1);
  }, 0) : 0; //Calculate Average Confidence
  events.push(ccEvent);
  return events;
});