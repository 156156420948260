import { XMLParser as _XMLParser } from "fast-xml-parser";
import _format from "xml-formatter";
import _eol from "eol";
import _formatTags from "../dict/formatTags.js";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const formatTags = _formatTags;
const eol = _eol;
const format = _format; //Encode
const XMLParser = _XMLParser; //Decode
export default {
  decode: function (input, options) {
    let events = [],
      tcInfo;
    let subtitleBlocks = input.split("\n\n");
    subtitleBlocks.forEach(block => {
      let ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.10
      });
      let blockInfo = block.split("\n").filter(blockLine => {
        return blockLine !== "";
      });
      if (blockInfo.length > 1) {
        tcInfo = blockInfo.shift();
        ccEvent.start = tcLib.tcToSec(tcInfo.split("\t")[0].replace(".", ":"), options.frameRate);
        ccEvent.end = tcLib.tcToSec(tcInfo.split("\t")[1].replace(".", ":"), options.frameRate);
        ccEvent.text = convertToHtml(blockInfo.join("\n"));
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "",
      encodingOptions = getFormatOptions(options.formatOptions);
    eventGroup.events.forEach(event => {
      let start = tcLib.secToTc(event.start, options.frameRate);
      let end = tcLib.secToTc(event.end, options.frameRate);
      let text = convertToPlainText(event.text);
      output += start.substring(0, 8) + "." + start.substring(9) + "\t" + end.substring(0, 8) + "." + end.substring(9) + "\n";
      output += text + "\n\n";
    });
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input).trim().replace(/'(\n){3,}'/gim, "\n\n");
    }
  },
  postProcess: {
    encode: function (output) {
      return output.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};