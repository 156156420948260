<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {environment} from '@app/store/envStore.js';
import {authState} from '@app/store/authStore.js';
import {
    fade
} from 'svelte/transition';
import General from './options/General.svelte';
import Editor from './options/Editor.svelte';
import Metadata from './options/Metadata.svelte';
import Fonts from './options/Fonts.svelte';
import CliTools from './options/CliTools.svelte';
import QuickTools from './options/QuickTools.svelte';
import Notifications from './options/Notifications.svelte';
import Integrations from './options/Integrations.svelte';
import Adr from './options/Adr.svelte';
import FootPedal from './options/FootPedal.svelte';
import ApiKeyManager from './options/ApiKeyManager.svelte';
import AutomaticBackup from './options/AutomaticBackup.svelte';


let selectedOption = "general";
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'options' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="optionsModalTitle"
    aria-describedby="optionsModalDescription"
    tabindex="-1" 
    id="OptionsModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="optionsModalTitle">Options</h4>
                <button type="button" class="btn-close" aria-label="Close options modal" id="optionsCloseBtn" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body" id="optionsModalDescription">
                <div class="row">
                    <div class="col-3">
                        <nav>
                            <ul class="nav nav-pills flex-column" role="tablist" aria-label="Options navigation">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'general' ? 'active' : ''}" 
                                       id="general-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'general'}
                                       aria-controls="general-panel"
                                       on:click={()=>{selectedOption = 'general'}}>General</a>
                                </li>
                                {#if $environment.online}
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'notifications' ? 'active' : ''}" 
                                       id="notifications-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'notifications'}
                                       aria-controls="notifications-panel"
                                       on:click={()=>{selectedOption = 'notifications'}}>Notifications</a>
                                </li>
                                {/if}
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'editor' ? 'active' : ''}" 
                                       id="editor-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'editor'}
                                       aria-controls="editor-panel"
                                       on:click={()=>{selectedOption = 'editor'}}>Editor</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'quickTools' ? 'active' : ''}" 
                                       id="quickTools-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'quickTools'}
                                       aria-controls="quickTools-panel"
                                       on:click={()=>{selectedOption = 'quickTools'}}>QuickTools</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'metadata' ? 'active' : ''}" 
                                       id="metadata-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'metadata'}
                                       aria-controls="metadata-panel"
                                       on:click={()=>{selectedOption = 'metadata'}}>Project Metadata</a>
                                </li>           
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'fonts' ? 'active' : ''}" 
                                       id="fonts-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'fonts'}
                                       aria-controls="fonts-panel"
                                       on:click={()=>{selectedOption = 'fonts'}}>Custom Fonts</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'footPedal' ? 'active' : ''}" 
                                       id="footPedal-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'footPedal'}
                                       aria-controls="footPedal-panel"
                                       on:click={()=>{selectedOption = 'footPedal'}}>Foot Pedal Setup</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'automaticBackup' ? 'active' : ''}" 
                                       id="automaticBackup-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'automaticBackup'}
                                       aria-controls="automaticBackup-panel"
                                       on:click={()=>{selectedOption = 'automaticBackup'}}>Automatic Backup & Save</a>
                                </li>
                                {#if $environment.online && ($authState.plan === 'pro' || $authState.plan === 'enterprise')}
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'apiKeyManager' ? 'active' : ''}" 
                                       id="apiKeyManager-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'apiKeyManager'}
                                       aria-controls="apiKeyManager-panel"
                                       on:click={()=>{selectedOption = 'apiKeyManager'}}>API Key Manager <span class="badge bg-secondary">BETA</span></a>
                                </li>
                                {/if}
                                {#if $authState.plan === 'pro' || $authState.plan === 'enterprise'}
                                    {#if $environment.online}
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link {selectedOption === 'integrations' ? 'active' : ''}" 
                                           id="integrations-tab"
                                           href="#!/" 
                                           role="tab"
                                           aria-selected={selectedOption === 'integrations'}
                                           aria-controls="integrations-panel"
                                           on:click={()=>{selectedOption = 'integrations'}}>Integrations <span class="badge bg-secondary">BETA</span></a>
                                    </li>
                                    {/if}       
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'adr' ? 'active' : ''}" 
                                       id="adr-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'adr'}
                                       aria-controls="adr-panel"
                                       on:click={()=>{selectedOption = 'adr'}}>ADR Setup <span class="badge bg-secondary">BETA</span></a>
                                </li>       
                                {/if}              
                                {#if $environment.electron}
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link {selectedOption === 'cliTools' ? 'active' : ''}" 
                                       id="cliTools-tab"
                                       href="#!/" 
                                       role="tab"
                                       aria-selected={selectedOption === 'cliTools'}
                                       aria-controls="cliTools-panel"
                                       on:click={()=>{selectedOption = 'cliTools'}}>CLI Tools</a>
                                </li>
                                {/if}
                            </ul>
                        </nav>
                    </div>
                    <div class="col-9">
                        <div role="tabpanel" aria-labelledby="general-tab" id="general-panel" class:d-none={selectedOption !== "general"}>
                            {#if selectedOption === "general"}
                            <General></General>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="notifications-tab" id="notifications-panel" class:d-none={selectedOption !== "notifications"}>
                            {#if selectedOption === "notifications"}
                            <Notifications></Notifications>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="editor-tab" id="editor-panel" class:d-none={selectedOption !== "editor"}>
                            {#if selectedOption === "editor"}
                            <Editor></Editor>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="quickTools-tab" id="quickTools-panel" class:d-none={selectedOption !== "quickTools"}>
                            {#if selectedOption === "quickTools"}
                            <QuickTools></QuickTools>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="metadata-tab" id="metadata-panel" class:d-none={selectedOption !== "metadata"}>
                            {#if selectedOption === "metadata"}
                            <Metadata></Metadata>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="fonts-tab" id="fonts-panel" class:d-none={selectedOption !== "fonts"}>
                            {#if selectedOption === "fonts"}
                            <Fonts></Fonts>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="footPedal-tab" id="footPedal-panel" class:d-none={selectedOption !== "footPedal"}>
                            {#if selectedOption === "footPedal"}
                            <FootPedal></FootPedal>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="automaticBackup-tab" id="automaticBackup-panel" class:d-none={selectedOption !== "automaticBackup"}>
                            {#if selectedOption === "automaticBackup"}
                            <AutomaticBackup></AutomaticBackup>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="apiKeyManager-tab" id="apiKeyManager-panel" class:d-none={selectedOption !== "apiKeyManager"}>
                            {#if selectedOption === "apiKeyManager"}
                            <ApiKeyManager></ApiKeyManager>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="integrations-tab" id="integrations-panel" class:d-none={selectedOption !== "integrations"}>
                            {#if selectedOption === "integrations"}
                            <Integrations></Integrations>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="adr-tab" id="adr-panel" class:d-none={selectedOption !== "adr"}>
                            {#if selectedOption === "adr"}
                            <Adr></Adr>
                            {/if}
                        </div>
                        <div role="tabpanel" aria-labelledby="cliTools-tab" id="cliTools-panel" class:d-none={selectedOption !== "cliTools"}>
                            {#if selectedOption === "cliTools"}
                            <CliTools></CliTools>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
.modal-body {
    min-height: 85vh;
}
</style>
