export default (function convertRegionsToPositionMap(regions, window) {
  let positionMap = {};
  regions.forEach(region => {
    try {
      let startingXPos = "start",
        endingXPos = "start",
        startingYPos = "start",
        endingYPos = "start",
        xPos = "center",
        yPos = "end",
        xOffset = 0,
        yOffset = 0;
      let regionId = region['@_xml:id'];
      if (!regionId) {
        return;
      }
      let regionExtent = region['@_tts:extent'];
      let regionOrigin = region['@_tts:origin'];
      let regionDisplayAlign = region['@_tts:displayAlign'];
      let regionTextAlign = region['@_tts:textAlign'] || "center";
      let originX = parseFloat(regionOrigin ? regionOrigin.split(' ')[0].replace("%", "") : 10);
      let originY = parseFloat(regionOrigin ? regionOrigin.split(' ')[1].replace("%", "") : 10);
      let extentX = parseFloat(regionExtent ? regionExtent.split(' ')[0].replace("%", "") : 80);
      let extentY = parseFloat(regionExtent ? regionExtent.split(' ')[1].replace("%", "") : 80);
      if (originY < 50) {
        startingYPos = "start";
      } else {
        startingYPos = "end";
      }
      if (originX < 40) {
        startingXPos = "start";
      } else if (originX < 60) {
        startingXPos = "center";
      } else {
        startingXPos = "end";
      }
      if (originY + extentY <= 50) {
        endingYPos = "start";
      } else {
        endingYPos = "end";
      }
      if (regionDisplayAlign === "before") {
        yPos = startingYPos;
      } else {
        yPos = endingYPos;
      }
      positionMap[regionId] = {
        xPos: xPos || "center",
        yPos: yPos || "end",
        xOffset: xOffset || 0,
        yOffset: yOffset || window.height * (yPos === "end" ? -0.1 : 0.1),
        alignment: regionTextAlign || "center"
      };
    } catch (err) {
      //console.log(err);
    }
  });
  return positionMap;
});