<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    fade
} from 'svelte/transition';

import tcLib from '@app/external/cc-lib/dist/lib/timecode.js'
$eventGroupState[$projectState.selected].selected.sort(); //Sort just in case
let tc = "00:00:00:00";
let offsetType = "add";
let startingIndex = $eventGroupState[$projectState.selected].selected.length > 0 ? parseInt($eventGroupState[$projectState.selected].selected[0])+1 : 1;
let endingIndex = $eventGroupState[$projectState.selected].selected.length > 1 ? parseInt($eventGroupState[$projectState.selected].selected[$eventGroupState[$projectState.selected].selected.length - 1])+1 : $eventGroupState[$projectState.selected].events.length;

if (player){
    let evStartTime = $eventGroupState[$projectState.selected].events[startingIndex-1].start || 0;
    let offsetSec = player.currentTime - evStartTime;

    if (offsetSec < 0){
        offsetType = "subtract"
    }

    tc = tcLib.secToTc(Math.abs(offsetSec), $projectState.frameRate, $projectState.dropFrame);
}

function applyOffset() {
    let eventIndex = parseInt(startingIndex) - 1;
    let offsetSec = tcLib.tcToSec(tc, $projectState.frameRate, $projectState.dropFrame);

    while (eventIndex < parseInt(endingIndex)){
        if (offsetType === "add"){
            !isNaN($eventGroupState[$projectState.selected].events[eventIndex].start) ? $eventGroupState[$projectState.selected].events[eventIndex].start += offsetSec : null;
            
            !isNaN($eventGroupState[$projectState.selected].events[eventIndex].end) ? $eventGroupState[$projectState.selected].events[eventIndex].end += offsetSec : null;
        } else {
            !isNaN($eventGroupState[$projectState.selected].events[eventIndex].start) ? $eventGroupState[$projectState.selected].events[eventIndex].start -= offsetSec : null;
            
            !isNaN($eventGroupState[$projectState.selected].events[eventIndex].end) ? $eventGroupState[$projectState.selected].events[eventIndex].end -= offsetSec : null;
        }

        eventIndex++;
    }
    
    historyState.insert({
        name : "apply offset", //action name
        eventGroup : $projectState.selected,
        snapshots : [
            {
                store : "eventGroupState",
                value : JSON.stringify($eventGroupState)
            }
        ]
    });
    modalState.hideModal();
}

function validateTc(e) {
    tc = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
}

function validateStartIndex(){
    if (startingIndex > $eventGroupState[$projectState.selected].events.length){
        startingIndex = $eventGroupState[$projectState.selected].events.length;
    }

    if (startingIndex < 1){
        startingIndex = 1;
    }    
}

function validateEndIndex(){
    if (endingIndex > $eventGroupState[$projectState.selected].events.length){
        endingIndex = $eventGroupState[$projectState.selected].events.length;
    }

    if (endingIndex < 1){
        endingIndex = 1;
    }
}

function frameByFrame(e){
    if (e.key === "ArrowUp"){
        try {
            let smpteTc = tcLib.createTc(tc, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.add(1);
            tc = smpteTc.toString();
        } catch(e){
            console.log(e, e.message);
        }        
    } else if (e.key === "ArrowDown"){
        try {
            let smpteTc = tcLib.createTc(tc, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.subtract(1);
            tc = smpteTc.toString();
        } catch(e){
            console.log(e, e.message);
        }
    }
}


</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'tcOffset' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="tcOffsetTitle"
    aria-modal="true"
    tabindex="-1" 
    id="TcOffsetModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="tcOffsetTitle">Timecode Offset</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{applyOffset}" aria-label="Timecode offset form">
                    <div class="row">
                        <div class="col-4">
                            <div class="mb-3">
                                <label class="form-label" id="offsetTypeLabel" for="offsetTypeSelect">Offset Type</label>
                                <select class="form-select" id="offsetTypeSelect" bind:value="{offsetType}" aria-labelledby="offsetTypeLabel">
                                    <option value="add">Add</option>
                                    <option value="subtract">Subtract</option>
                                </select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label class="form-label" id="offsetLabel" for="offsetInput">Offset</label>
                                <input class="form-control" 
                                    id="offsetInput"
                                    type="text" 
                                    placeholder="SMPTE Format (e.g. hh:mm:ss:ff)" 
                                    bind:value="{tc}" 
                                    on:blur={validateTc} 
                                    on:keydown={frameByFrame} 
                                    on:focus="{(e)=>{e.target.select()}}"
                                    aria-labelledby="offsetLabel">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label class="form-label" for="startingEventId">Starting Event Id</label>
                                <input class="form-control" id="startingEventId" type="number" min="1" step="1" max="{$eventGroupState[$projectState.selected].events.length}" placeholder="Starting Event Id" bind:value="{startingIndex}" on:blur={validateStartIndex}>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label class="form-label" for="endingEventId">Ending Event Id</label>
                                <input class="form-control" id="endingEventId" type="number" min="1" step="1" max="{$eventGroupState[$projectState.selected].events.length}" placeholder="Ending Event Id" bind:value="{endingIndex}" on:blur={validateEndIndex}>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <button class="btn btn-primary float-end" 
                        id="applyOffsetBtn"
                        type="button" 
                        on:click="{applyOffset}"
                        aria-label="Apply timecode offset">Apply Offset</button>
                </form>
            </div>
        </div>
    </div>
</div>
