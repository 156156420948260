import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToPlainText = _convertToPlainText;
export default {
  decode: function (input, options) {
    throw new Error("JSON source files are not supported");
  },
  encode: function (eventGroup, options) {
    let events = [];
    eventGroup.events.forEach(event => {
      events.push({
        startSec: event.start,
        endSec: event.end,
        startSmpte: tcLib.secToTc(event.start, options.frameRate),
        endSmpte: tcLib.secToTc(event.end, options.frameRate),
        frameRate: options.frameRate,
        text: convertToPlainText(event.text)
      });
    });
    return JSON.stringify(events, null, 4);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};