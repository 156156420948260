import _orderByTime from "../eventGroups/orderByTime.js";
import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
const orderByTime = _orderByTime;
export default (function addIncode(eventGroup, options) {
  if (!options.incode) {
    return eventGroup;
  }
  if (options.incode === "00:00:00:00") {
    return eventGroup;
  }
  eventGroup.events.forEach((event, index, events) => {
    //console.log(events[index]);
    let tcStart = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame);
    let tcEnd = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame);
    //console.log(tcStart.toString(), tcEnd.toString());
    tcStart = tcStart.add(options.incode);
    tcEnd = tcEnd.add(options.incode);

    //console.log(tcStart.toString(), tcEnd.toString());
    events[index].start = tcLib.tcToSec(tcStart.toString(), options.frameRate);
    events[index].end = tcLib.tcToSec(tcEnd.toString(), options.frameRate);
    //console.log("AFTER:",events[index].start);
  });
  return eventGroup;
});