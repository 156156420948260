<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import TrackLabel from './TrackLabel.svelte';
import AudioTrackLabel from './AudioTrackLabel.svelte';
export let height = 40;
export let showCompareTrack = false;
</script>

<div 
    class="col-auto bg-secondary bg-opacity-25" 
    id="TrackLabelsWrapper"
    role="complementary"
    aria-label="Track Labels Section">
    {#if showCompareTrack}
        <TrackLabel 
            eventGroupIndex={$projectState.compare} 
            height={height}
            labelId="compare-track"
            aria-label="Compare Track">
        </TrackLabel>    
    {/if}
        
    <TrackLabel 
        eventGroupIndex={$projectState.selected} 
        height={height}
        labelId="main-track"
        aria-label="Main Track">
    </TrackLabel>
    <!-- {#if ($projectState.media.storage === "Local Storage" || $projectState.media.storage === "Cloud Storage" || $projectState.media.storage === "Proxy RT") && $playerState.duration !== false}
        <AudioTrackLabel height="{height}"></AudioTrackLabel>
    {/if} -->
    <div class="col-12 p-0 m-0" style="height: 10px;"></div>
</div>
