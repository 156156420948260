import { decode as _decodeHtml } from "html-entities";
import _replaceUnderlineTags from "./replaceUnderlineTags.js";
import _replaceItalicTags from "./replaceItalicTags.js";
import _replaceBoldTags from "./replaceBoldTags.js";
import _stripTags from "./stripTags.js";
const stripTags = _stripTags;
const replaceBoldTags = _replaceBoldTags;
const replaceItalicTags = _replaceItalicTags;
const replaceUnderlineTags = _replaceUnderlineTags;
const decodeHtml = _decodeHtml;
export default (function convertToPlainTextCustom(text, divider = "\n", removeTags = false, boldTag = "strong", italicTag = "em", underlineTag = "u", acceptColorInfo = false) {
  //remove &nbsp;
  text = text.toString().replace(/&nbsp;/g, " ");
  text = text.replace(/<p\b[^>]*>/gmi, "").replace(/<\/p>/gmi, divider);
  text = replaceBoldTags(text, "<" + boldTag + ">", "</" + boldTag + ">");
  text = replaceItalicTags(text, "<" + italicTag + ">", "</" + italicTag + ">");
  text = replaceUnderlineTags(text, "<" + underlineTag + ">", "</" + underlineTag + ">");
  if (acceptColorInfo) {
    text = text.replace(/<span class=\"ql-cursor\">.+<\/span>/g, "");
  } else {
    text = text.replace(/<span(?:\s+[^>]*)?>|<\/span\s*>/gi, '');
  }

  //replace one or more spaces with a one space
  text = text.replace(/ +/g, " ");
  //replace spaces before punctuation
  text = text.replace(/ ([,.!?])/g, "$1");
  if (removeTags) {
    return decodeHtml(stripTags(text.trim()));
  } else {
    return decodeHtml(text.trim());
  }
});