<script>
    export let event = {};

    function approveEvent() {
        if (event.approved === undefined){
            event.approved = true;
        } else if (event.approved === true){
            event.approved = false;
        } else if (event.approved === false){
            event.approved = undefined;
        }
    }

    </script>
<button type="button" class="btn btn-link text-custom-medium {event.approved === false ? 'text-danger' : event.approved === true ? 'text-primary' : 'text-dark'}" on:click={()=> approveEvent()}>
    <i class="bi bi-{event.approved === false ? 'x' : 'check'}-circle" title="Approval"></i>
</button>

<style>
    button {
        padding : 2px;
        margin: 0;
    }
</style>