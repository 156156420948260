<script>
import {
    onMount
} from 'svelte';
import {
    onDestroy
} from 'svelte';
import {
    environment
} from '@app/store/envStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
import {
    v4 as uuidv4
} from 'uuid';
import {
    Circle
} from 'svelte-loading-spinners'
import {
    BarLoader
} from 'svelte-loading-spinners'

/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import storage from '@app/configs/storage.js';

import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import insertEvent from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';

let selectedRecord,
    autoSaveInterval,
    backupDestination = $environment.online ? "Cloud" : "Local",
    targetFolder,
    numberOfBackups = 5,
    backupInterval = 15,
    gettingRecords,
    loading = false,
    records = [],
    backupCount = 1,
    userId = $environment.online ? firebase.auth().currentUser.uid : null,
    homeRef = $environment.online ? db.collection("users").doc(userId).collection("autosave") : null,
    storageRef = $environment.online ? storage.ref() : null,
    uploadBasePath = $environment.online ? "users/" + userId + "/autosave/" : null;

onMount(() => {
    //console.log("Starting Automatic Saving");
    //Get Automatic backup settings from local storage
    let settings = JSON.parse(localStorage.getItem("cc-automatic-backup-settings"));
    if (settings) {
        backupDestination = settings.backupDestination;
        targetFolder = settings.targetFolder;
        numberOfBackups = settings.numberOfBackups > 0 ?  settings.numberOfBackups : 5;
        backupInterval = Math.max(15,settings.backupInterval);
        autoSaveInterval = setInterval(autoSave, settings.backupInterval * 60000);
    } else {
        autoSaveInterval = setInterval(autoSave, 900000); //300,000 = 5 Minutes, 900000 = 15 minutes
    }
});

/* Destroy Auto Save Interval */
onDestroy(() => {
    clearInterval(autoSaveInterval);
});

function selectRecord(record) {
    selectedRecord = record;
    //console.log(record);
}

function refreshRecords() {
    gettingRecords = getRecords();
}

function getRecords() {
    selectedRecord = undefined;
    records = [];
    if (!$environment.online) {
        toast.push("Automatic backup is not available in offline mode. Please configure Automatic Backup for local by going to Edit -> Options -> Automatic Backup", {
            classes: ["toast-warning"]
        });
        return;
    }
    let twoWeeksAgo = new Date();
    twoWeeksAgo.setHours(twoWeeksAgo.getHours() - 336);
    return homeRef.where("createdOn", ">=", twoWeeksAgo).orderBy('createdOn', 'desc').limit(100).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            records = [...records, doc.data()];
        });
    }).catch((error) => {
        console.log("Auto Save: error getting documents: ", error, error.message);
    });
}

async function autoSave() {
    if (backupDestination === "Cloud") {
        autoSaveEventGroups();
    } else {
        autoSaveProject();
    }
}
async function autoSaveProject() {
    try {
        const fs = window.fs;
        const path = window.path;
        if (targetFolder) {
            let projectJson = JSON.parse(JSON.stringify($projectState));
            projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
            projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
            projectJson.issueState = JSON.parse(JSON.stringify($issueState));
            projectJson.markers = JSON.parse(JSON.stringify($markerState));
            projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
            projectJson.style = JSON.parse(JSON.stringify($styleState));
            await fs.writeFile(path.join(targetFolder,$projectState.name+"_BACKUP_"+backupCount+".ccprj"), JSON.stringify(projectJson, null, 4));
            backupCount++;
            
            if (backupCount > numberOfBackups){
                backupCount = 1;
            }

            toast.push("Automatic backup saved successfully", {
                classes: ["toast-success"]
            });
        } else {
            toast.push("Automatic backup not configured or there were issues saving your backup project.", {
                classes: ["toast-warning"]
            });
        }
    } catch(err){
        toast.push("Project saved failed. "+err.message, {
            classes: ["toast-danger"]
        });
    }    
}

async function autoSaveEventGroups() {
    selectedRecord = undefined;
    let recordId = uuidv4();
    let totalEventCount = 0;
    $eventGroupState.forEach(eventGroup => {
        totalEventCount += eventGroup.events.length;
    });

    if (totalEventCount < 5) {
        return;
    }

    let recordInfo = {
        id: recordId,
        name: $projectState.name,
        type: $projectState.type === "team" ? "team project" : "project",
        createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
        eventGroups: $eventGroupState.length,
        events: totalEventCount
    }

    try {
        await storageRef.child(uploadBasePath + recordId).putString(JSON.stringify($eventGroupState));
        //console.log("Event Groups saved to storage");
        await homeRef.doc(recordId).set(recordInfo);
        //console.log("Autosave Record Stored");
        toast.push("Restore point created successfully", {
            classes: ["toast-info"]
        });
    } catch (err) {
        console.log("Error saving project: ", err);
        toast.push("Failed to create a restore point. " + err.message, {
            classes: ["toast-danger"]
        });
    }
}

function restoreEventGroups() {
    loading = true;
    storageRef.child(uploadBasePath + selectedRecord.id).getDownloadURL().then((url) => {
        return fetch(url);
    }).then(res => {
        return res.json();
    }).then((resJson) => {
        //console.log(resJson);
        let numberOfEventGroups = $eventGroupState.length;
        resJson.forEach((eventGroup, eventGroupIndex) => {
            $eventGroupState = [...$eventGroupState, new _EventGroup({
                ...eventGroup,
                ...{
                    events: []
                }
            })];

            eventGroup.events.forEach(event => {
                $eventGroupState[eventGroupIndex + numberOfEventGroups] = insertEvent($eventGroupState[eventGroupIndex + numberOfEventGroups], event);
            });
        });

        toast.push("Event groups restored successfully", {
            classes: ["toast-success"]
        });

        modalState.hideModal();

    }).catch(error => {
        //console.log(error, error.message);
        toast.push("Event groups failed to restore. " + error.message, {
            classes: ["toast-danger"]
        });
        console.log(error, error.message);
    }).finally(() => {
        loading = false;
    });
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'autoSave' ? 'show d-block' : ''}" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-capitalize">Restore Event Groups</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}>

                </button>
            </div>
            <div class="modal-body">
                <div class="mx-3 my-1">
                    <button type="button" class="btn btn-info text-white" title="Reload Records" on:click="{() => refreshRecords()}" disabled="{loading}"><i class="bi bi-arrow-clockwise"></i> Reload Records</button>
                </div>
                <div class="m-3 rounded-lg shadow border border-secondary">
                    <div class="table-responsive">
                        <table class="table table-hover table-light">
                            <thead class="table-light">
                                <tr>
                                    <th>Project Name</th>
                                    <th>Created On</th>
                                    <th>Total Event Groups</th>
                                    <th>Total Events</th>
                                </tr>
                            </thead>
                            <tbody>
                                {#await gettingRecords}
                                <div style="margin: 5% 35%">
                                    <BarLoader size="160" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                                </div>

                                {:then}
                                {#each records as record (record.id)}
                                <tr transition:fade="{{duration: 100}}" on:dblclick={restoreEventGroups} on:click="{selectRecord(record)}" class="{selectedRecord && record.id === selectedRecord.id ? 'table-warning' : ''}" >
                                    <td class="text-truncate" title="{record.name}">{record.name}
                                    </td>
                                    <td class="text-truncate" title="{record.createdOn.toDate()}">{record.createdOn.toDate()}</td>
                                    <td class="text-truncate" title="{record.eventGroups}">{record.eventGroups}</td>
                                    <td class="text-truncate" title="{record.events}">{record.events}</td>
                                </tr>
                                {:else}
                                <tr>
                                    <td>Please reload records to start</td>
                                </tr>
                                {/each}
                                {/await}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                {#if loading}
                <p class="text-muted small">Importing Event Groups...</p>
                <Circle size="30" color="#1eb4b2" unit="px" duration="1s"></Circle>
                {/if}
                <button type="button" class="btn btn-primary" disabled="{!selectedRecord || loading}" on:click="{restoreEventGroups}"><i class="bi bi-download"></i> Restore</button>
            </div>
        </div>
    </div>
</div>

    <style>
table {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 60vh;
}

thead {
    flex: 0 0 auto;
}

tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}
</style>
