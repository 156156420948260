<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

let open = false;

const updatePosition = function(posX, posY) {
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        switch (posY) {
            case "top":
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = "start"
                break;
            case "center":
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = "center"
                break;
            default:
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = "end"
        }

        switch (posX) {
            case "left":
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = "start";
                break;
            case "center":
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = "center";
                break;
            default:
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = "end";
        }

        $eventGroupState[$projectState.selected].events[eventIndex].xOffset = 0;
        $eventGroupState[$projectState.selected].events[eventIndex].yOffset = 0;
    });

    historyState.insert({
        name: "position event(s)", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    /* Close Menu */
    open = !open;
}
</script>

<li class="nav-item dropdown {open ? 'show' : ''}" title="Event Position">
    <a class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" on:click="{()=>{open = !open}}" class="dropdown-toggle nav-link" href="#!/"><i class="bi bi-grid-3x3" /></a>
    <div id="PositionMenu" class="dropdown-menu {open ? 'show' : ''}">
        <div class="btn-toolbar">
            <div class="btn-group" role="group">
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition("left", "top")}}"> <i class="bi bi-circle" /> </button>
                <button class="btn btn-light btn-sm" type="button" on:click="{()=>{updatePosition("center", "top")}}"> <i class="bi bi-circle" /> </button>
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition("right", "top")}}"> <i class="bi bi-circle" /> </button>
            </div>
            <div class="btn-group" role="group">
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition("left", "center")}}"> <i class="bi bi-circle" /> </button>
                <button class="btn btn-light btn-sm" type="button" on:click="{()=>{updatePosition("center", "center")}}"> <i class="bi bi-circle" /> </button>
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition("right", "center")}}"> <i class="bi bi-circle" /> </button>

            </div>
            <div class="btn-group" role="group">
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition("left", "bottom")}}"> <i class="bi bi-circle" /> </button>
                <button class="btn btn-light btn-sm" type="button" on:click="{()=>{updatePosition("center", "bottom")}}"> <i class="bi bi-circle" /> </button>
                <button class="btn btn-light btn-sm rounded-0" type="button" on:click="{()=>{updatePosition("right", "bottom")}}"> <i class="bi bi-circle" /> </button>

            </div>
        </div>
    </div>
</li>
<style>
#PositionMenu {
    background: transparent;
    border: none;
    position: absolute;
    top: 30px;
}
</style>
