export default {
  bold: {
    open: "<strong>",
    close: "</strong>"
  },
  italics: {
    open: "<em>",
    close: "</em>"
  },
  underline: {
    open: "<u>",
    close: "</u>"
  },
  paragraph: {
    open: "<p>",
    close: "</p>"
  },
  black: {
    open: "<span style='color: black;'>",
    close: "</span>"
  },
  bgBlack: {
    open: "<span style='background-color: black;'>",
    close: "</span>"
  },
  red: {
    open: "<span style='color: red;'>",
    close: "</span>"
  },
  bgRed: {
    open: "<span style='background-color: red;'>",
    close: "</span>"
  },
  green: {
    open: "<span style='color: green;'>",
    close: "</span>"
  },
  bgGreen: {
    open: "<span style='background-color: green;'>",
    close: "</span>"
  },
  yellow: {
    open: "<span style='color: yellow;'>",
    close: "</span>"
  },
  bgYellow: {
    open: "<span style='background-color: yellow;'>",
    close: "</span>"
  },
  blue: {
    open: "<span style='color: blue;'>",
    close: "</span>"
  },
  bgBlue: {
    open: "<span style='background-color: blue;'>",
    close: "</span>"
  },
  magenta: {
    open: "<span style='color: magenta;'>",
    close: "</span>"
  },
  bgMagenta: {
    open: "<span style='background-color: magenta;'>",
    close: "</span>"
  },
  cyan: {
    open: "<span style='color: cyan;'>",
    close: "</span>"
  },
  bgCyan: {
    open: "<span style='background-color: cyan;'>",
    close: "</span>"
  },
  white: {
    open: "<span style='color: white;'>",
    close: "</span>"
  },
  bgWhite: {
    open: "<span style='background-color: white;'>",
    close: "</span>"
  }
};