<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    tick, createEventDispatcher
} from 'svelte';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';

const dispatch = createEventDispatcher();
async function deleteEvents() {
    try {
        let eventsToDelete = $eventGroupState[$projectState.selected].selected.map(eventIndex => {return eventIndex});
        $eventGroupState[$projectState.selected].selected = [];
        /* Important! */
        await tick();
        eventsToDelete.sort((a, b) => {
            return b - a;
        }).forEach(eventIndex => {
            $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected],eventIndex);
        });

        toast.push("Events deleted from group successfully", {classes: ["toast-success"]});

        historyState.insert({
            name: "remove event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }
}
</script>

<a class="dropdown-item" href="#!/" on:click={deleteEvents}>Delete...</a>
