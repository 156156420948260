<script>
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    v4 as uuidv4
} from 'uuid';
let metadataType;
let metadataValue;

function addMetadata() {
    $metadataState = [...$metadataState, {
        id: uuidv4(),
        type: metadataType,
        value: metadataValue,
    }]

    metadataType = "";
    metadataValue = "";

    historyState.insert({
        name: "insert metadata", //action name
        eventGroup: false,
        snapshots: [{
            store: "metadataState",
            value: JSON.stringify($metadataState)
        }]
    });
}

function removeMetadata(id) {
    $metadataState = $metadataState.filter(metadata => {
        return id !== metadata.id;
    });

    historyState.insert({
        name: "remove metadata", //action name
        eventGroup: false,
        snapshots: [{
            store: "metadataState",
            value: JSON.stringify($metadataState)
        }]
    });
}
</script>

<div class="table-responsive">
    <table class="table table-striped table-light table-sm">
        <thead class="table-light">
            <tr>
                <th scope="col"></th>
                <th scope="col">Metadata Type</th>
                <th scope="col">Metadata Value</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class='text-center'>
                    <button class="btn btn-sm btn-primary" disabled='{!metadataType || !metadataValue}' type="button" title="Add Metadata" on:click="{addMetadata}" id="addMetadataButton">Insert</button>
                </td>
                <td><input type="text" class="form-control border-0 rounded-0 bg-secondary text-dark form-control-sm" placeholder="Metadata Type" bind:value={metadataType} id="metadataTypeInput"></td>
                <td><input type="text" class="form-control border-0 rounded-0 bg-secondary text-dark form-control-sm" placeholder="Metadata Value" bind:value={metadataValue} id="metadataValueInput"></td>

            </tr>
            {#each $metadataState as metadata (metadata.id)}
            <tr>
                <td class='text-center'><button class="btn btn-link text-danger" type="button" title="Delete Metadata" on:click="{removeMetadata(metadata.id)}" id="deleteMetadataButton-{metadata.id}"><i class="bi bi-x-lg"></i></button></td>
                <td>{metadata.type}</td>
                <td>{metadata.value}</td>
            </tr>
            {/each}
        </tbody>
    </table>
</div>
