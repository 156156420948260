export default {
  "ar": "Arabic",
  "ba": "Bashkir",
  "eu": "Basque",
  "be": "Belarusian",
  "bg": "Bulgarian",
  "yue": "Cantonese",
  "ca": "Catalan",
  "hr": "Croatian",
  "cs": "Czech",
  "da": "Danish",
  "nl": "Dutch",
  "en": "English - Global",
  "eo": "Esperanto",
  "et": "Estonian",
  "fi": "Finnish",
  "fr": "French",
  "gl": "Galician",
  "de": "German",
  "el": "Greek",
  "hi": "Hindi",
  "hu": "Hungarian",
  "ia": "Interlingua",
  "it": "Italian",
  "id": "Indonesian",
  "ja": "Japanese",
  "ko": "Korean",
  "lv": "Latvian",
  "lt": "Lithuanian",
  "ms": "Malay",
  "cmn": "Mandarin",
  "mr": "Marathi",
  "mn": "Mongolian",
  "no": "Norwegian",
  "fa": "Persion",
  "pl": "Polish",
  "pt": "Portuguese",
  "ro": "Romanian",
  "ru": "Russian",
  "sk": "Slovakian",
  "sl": "Slovenian",
  "es": "Spanish - Global",
  "sv": "Swedish",
  "ta": "Tamil",
  "th": "Thai",
  "tr": "Turkish",
  "ug": "Uyghur",
  "uk": "Ukrainian",
  "vi": "Vietnamese",
  "cy": "Welsh"
};