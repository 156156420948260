module.exports = function closeHtmlTags(htmlString) {
    // console.log("-----------------");
    // console.log(htmlString);
    var output = '';
    var openTagsStack = [];
    var index = 0;
    var input = htmlString;
    var tagsToProcess = ['p', 'span', 'u', 'strong', 'em', 'b', 'black', 'red', 'yellow', 'green', 'blue', 'magenta', 'cyan', 'white', 'bgblack', 'bgred', 'bgyellow', 'bggreen', 'bgblue', 'bgmagenta', 'bgcyan', 'bgwhite'];

    let interval = 0
    while (index < input.length && interval < 1000) {
        interval++;
        // Check for tag starting at current index
        // Regex to match opening and closing tags, including those with attributes
        var tagRegex = /^<\/?\s*([a-zA-Z]+)(?:\s+[^>]*)?>/;
        var restOfInput = input.substring(index);
        var tagMatch = restOfInput.match(tagRegex);

        if (tagMatch) {
            // It's a tag
            var fullTag = tagMatch[0];
            var tagName = tagMatch[1];
            var isClosingTag = fullTag.startsWith('</');

            // Only process specific tags
            if (tagsToProcess.includes(tagName.toLowerCase())) {
                if (isClosingTag) {
                    // It's a closing tag
                    if (openTagsStack.length > 0 && openTagsStack[openTagsStack.length - 1].tagName.toLowerCase() === tagName.toLowerCase()) {
                        // Matching opening tag
                        var topTag = openTagsStack.pop();
                        if (topTag.contentAdded) {
                            // Add the closing tag to output
                            output += fullTag;
                        } else {
                            // Remove the opening tag from output
                            output = output.substring(0, topTag.startIndex);
                        }
                    } else {
                        // Closing tag without matching opening tag, do not add to output
                        // Do nothing
                    }
                } else {
                    // It's an opening tag
                    // Push to stack
                    openTagsStack.push({
                        tagName: tagName,
                        startIndex: output.length,
                        contentAdded: false
                    });
                    // Add the tag to output
                    output += fullTag;
                }
            } else {
                // Tag not in our list, add as is
                output += fullTag;
            }
            index += fullTag.length;
        } else {
            // Not a tag, text content
            // Find the next tag or end of string
            var nextTagIndex = input.indexOf('<', index);
            if (nextTagIndex === -1) {
                nextTagIndex = input.length;
            }
            var textContent = input.substring(index, nextTagIndex);
            output += textContent;

            if (textContent.trim().length > 0) {
                // Set contentAdded = true for all open tags
                for (var i = 0; i < openTagsStack.length; i++) {
                    openTagsStack[i].contentAdded = true;
                }
            }
            index = nextTagIndex;
        }
    }

    if (openTagsStack.length === 0 && output === ""){
        output = htmlString;
    }

    // After processing, close any remaining open tags
    while (openTagsStack.length > 0) {
        var topTag = openTagsStack.pop();
        if (topTag.contentAdded) {
            // Add closing tag
            output += '</' + topTag.tagName + '>';
        } else {
            // Remove opening tag from output
            output = output.substring(0, topTag.startIndex);
        }
    }

    // console.log(output);    
    return output;
}