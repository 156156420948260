<script>
import {
    onMount
} from "svelte";
let showAudioControls = false;
let selectedTrack = 0;
let audioTracks;


onMount(() => {
    player.on("loadedmetadata", updateTrackCount);
});

function updateTrackCount(event) {
    try {
        console.log("Update Audio Track Count");
        audioTracks = document.querySelector("video").audioTracks;
        selectedTrack = 0;
    } catch(err){
        selectedTrack = 0;
        audioTracks = false;
    }    
}

function updateSelectedTrack(trackId) {
    for (let i = 0; i < audioTracks.length; i += 1) {
        if (i === trackId) {
           audioTracks[i].enabled = true;
        } else {
            audioTracks[i].enabled = false;
        }
    }
    player.currentTime = player.currentTime - 2;
    selectedTrack = trackId;
    showAudioControls = false;
}

</script>

{#if audioTracks && audioTracks.length > 1}
<div class="dropdown {showAudioControls ? 'show' : ''}">
    <button id="RateDropdown" class="btn btn-sm btn-light dropdown-toggle rounded-0" type="button" on:click={() => (showAudioControls = !showAudioControls)}><i class="bi bi-music-note-list" /></button>
    <div class="dropdown-menu {showAudioControls ? 'show' : ''}">
        {#each audioTracks as track, index}
            <a class="dropdown-item {selectedTrack === index ? 'text-primary' : ''}" href="#!/" on:click={() => updateSelectedTrack(index)}>Track {index}</a>
        {/each}
    </div>
</div>
{/if}

<style>
    i {
        pointer-events: none;
        font-size: 0.8vw;
    }
</style>