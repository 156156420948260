<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import compressText from "@app/external/cc-lib/dist/functions/quill/compressText.js";

    /* Compressions makes the event longer which requires us to remove a word from the longest line and move it to a shorter line*/
    const compressEvent = function () {
        if (!$eventGroupState[$projectState.selected]) {
            return;
        }

        if (
            !$eventGroupState[$projectState.selected].selected ||
            $eventGroupState[$projectState.selected].selected.length === 0
        ) {
            return;
        }

        $eventGroupState[$projectState.selected].selected.forEach(
            (eventIndex, selectedEventIndex) => {
                try {
                    if (
                        !$eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text
                    ) {
                        return;
                    }

                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].text = compressText(
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text,
                        $eventGroupState[$projectState.selected].maxChars,
                        $eventGroupState[$projectState.selected].maxLines
                    );

                    if (selectedEventIndex === 0 && quillEditor) {
                        if (
                            quillEditor.getSemanticHTML() !== $eventGroupState[$projectState.selected].events[eventIndex].text
                        ) {
                            quillEditor.clipboard.dangerouslyPasteHTML(
                                $eventGroupState[$projectState.selected].events[eventIndex].text
                            );

                            quillEditor.formatLine(0, 9999,"align", $eventGroupState[$projectState.selected].events[eventIndex].alignment);
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            },
        );

        historyState.insert({
            name: "compress event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
    };
</script>

<li class="nav-item" title="Compress Text">
    <a
        class="nav-link"
        href="#!/"
        on:click={compressEvent}
        class:disabled={!$eventGroupState[$projectState.selected] ||
            $eventGroupState[$projectState.selected].selected.length === 0}
        ><i class="bi bi-distribute-horizontal"></i></a
    >
</li>
