<script>
import {
    toast } from '@zerodevx/svelte-toast';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
import {
    Circle
} from 'svelte-loading-spinners';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

let machineId = '';
let activationCode = '';
let loading = false;
let errMsg;

function copyActivationCode(){
    navigator.clipboard.writeText(activationCode);
    /* Notification */
    toast.push( `Activation code copied to clipboard successfully.`, {classes: ['toast-`success`']});
}

async function generateActivationCode() {
    try {
        loading = true;
        errMsg = "";
        if (!machineId) throw new Error("Please enter a machine id.");
        let res = await firebase.functions().httpsCallable('v8GenerateOfflineActivationCode')(machineId);
        if (res.data.error) throw new Error(res.data.error);
        activationCode = res.data.activationCode;
    } catch (err) {
        console.log(err, err.message);
        errMsg = err.message;
        /* Notification */
        toast.push(`Failed to generate offline activation code. ${err.message}`, {classes: ['toast-`danger`']});
    } finally {
        loading = false;
    }
}
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'offlineActivation' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="offlineActivationTitle"
    aria-describedby="offlineActivationDescription"
    tabindex="-1" 
    id="OfflineActivationModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="offlineActivationTitle">Offline Activation</h4>
                <button type="button" class="btn-close" id="offlineActivationCloseBtn" aria-label="Close offline activation modal" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p id="offlineActivationDescription">Enter the Machine Id from your offline system and click Activate to generate an Activation Code</p>
                <form on:submit|preventDefault="{()=> generateActivationCode()}" aria-describedby="offlineActivationDescription">
                    <div class="mb-3">
                        <label for="machineIdInput" class="form-label">Machine Id</label>
                        <input placeholder="Offline Machine Id" 
                               type="text" 
                               class="form-control" 
                               id="machineIdInput" 
                               bind:value={machineId} 
                               disabled={loading}
                               aria-required="true"
                               aria-invalid={!machineId}/>
                    </div>
                    <div class="mb-3">
                        <label for="activationCodeInput" class="form-label">Activation Code</label>
                        <input placeholder="Activation Code" 
                               type="text" 
                               class="form-control" 
                               id="activationCodeInput" 
                               bind:value={activationCode} 
                               on:focus="{(e)=>{e.target.select()}}" 
                               disabled={loading} 
                               readonly
                               aria-label="Generated activation code"/>
                    </div>
                    {#if loading}
                        <div aria-live="polite">
                            <Circle size="30" color="#1eb4b2" unit="px" duration="1s"></Circle>
                            <p>Generating Code... please wait.</p>
                        </div>
                    {/if}
                    {#if errMsg}
                        <p class="m-0 text-danger float-start" role="alert">{errMsg}</p>
                    {/if}
                </form>
            </div>
            <div class="modal-footer">
                <button id="copyCodeBtn"
                        disabled={loading || !activationCode} 
                        class="btn btn-dark" 
                        type="button" 
                        on:click="{() => copyActivationCode()}"
                        aria-label="Copy activation code">
                    <i class="bi bi-files" aria-hidden="true"></i> Copy Code
                </button>
                <button id="generateCodeBtn"
                        class="btn btn-primary" 
                        type="button" 
                        on:click={generateActivationCode} 
                        disabled={loading}
                        aria-label="Generate activation code">
                    Generate Code
                </button>
            </div>
        </div>
    </div>
</div>
