import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _stripTags from "../functions/quill/stripTags.js";
import _Tag from "../classes/tag.js";
import _Speaker from "../classes/speaker.js";
import _Event from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const Speaker = _Speaker;
const Tag = _Tag;
const stripTags = _stripTags;
const convertToHtml = _convertToHtml;
const removeInvalidEvents = _removeInvalidEvents;
import ExcelJs from 'exceljs';
export default {
  decode: async function (input, options) {
    let events = [],
      speakers = [],
      tags = [];
    let inputJson = JSON.parse(input);
    inputJson.sheets.forEach(sheet => {
      sheet.rows.forEach(row => {
        let ccEvent = new Event();
        row.cells.forEach((cell, index) => {
          try {
            //Map cell.value to ccEvent properties based on index. 1 = start, 2 = end, 3 = speakers, 4 = text, 5 = notes
            switch (index) {
              case 1:
                ccEvent.start = tcLib.tcToSec(cell.value.replace(".", ":"), options.frameRate);
                break;
              case 2:
                ccEvent.end = tcLib.tcToSec(cell.value.replace(".", ":"), options.frameRate);
                break;
              case 3:
                let speakerArray = cell.value.split(',');
                speakerArray.forEach(speaker => {
                  //Check if speaker already exists in speakers array. If it doesn't exist, add it as a new speaker and push it into ccEvent.speakers. If it does exist, then just push the current version from speakers into ccEvent.speakers.
                  let existingSpeaker = speakers.find(s => s.name === speaker);
                  if (existingSpeaker) {
                    ccEvent.speakers.push(existingSpeaker);
                  } else {
                    let newSpeaker = new Speaker({
                      name: speaker
                    });
                    speakers.push(newSpeaker);
                    ccEvent.speakers.push(newSpeaker);
                  }
                });
                break;
              case 4:
                ccEvent.text = cell.value;
                break;
              case 5:
                ccEvent.notes = cell.value;
                break;
              default:
                break;
            }
          } catch (err) {
            console.log(err.message);
          }
        });
        if (ccEvent.start) {
          ccEvent.text = convertToHtml(ccEvent.text || "");
          events.push(ccEvent);
        }
      });
    });
    return events;
  },
  encode: async function (eventGroup, options) {
    let output;

    //CSV Headers: EVENT NUMBER | TC IN | TC OUT | CHARACTER | SCRIPT EVENT | NOTE
    let rows = [["Event", "TimeCode In", "TimeCode Out", "Character", "Event", "On-Screen Text"]];
    eventGroup.events.forEach((event, index) => {
      rows.push([index + 1, tcLib.secToTc(Math.max(0, event.start), options.frameRate).replace(/:([^:]*)$/, ".$1"), tcLib.secToTc(Math.max(0, event.end), options.frameRate).replace(/:([^:]*)$/, ".$1"), event.speakers.map(sp => {
        return sp.name;
      }).join(","), stripTags(event.text), event.notes]);
    });
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet("DVW QC Script");
    worksheet.addRows(rows);
    output = await workbook.xlsx.writeBuffer();
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup, true);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};