<script>
    import {projectState} from '@app/store/projectStore.js'; 
    import {eventGroupState} from '@app/store/eventGroupStore.js'; 
    import {modalState} from '@app/store/modalStore.js';
    import EventGroupTab from './EventGroupTab.svelte';
  
    let draggedIndex = null;
  
    function handleDragStart(e, index) {
      draggedIndex = index;
    }
    function handleDragOver(e) {
      e.preventDefault();
    }
    function handleDrop(e, dropIndex) {
      e.preventDefault();
      let compareEventGroupId = $projectState.compare !== false ? $eventGroupState[$projectState.compare]?.id : false;

      const groups = [...$eventGroupState];
      const [moved] = groups.splice(draggedIndex, 1);
      groups.splice(dropIndex, 0, moved);
      eventGroupState.set(groups);
      $projectState.selected = dropIndex;
      if (compareEventGroupId !== false && compareEventGroupId !== undefined && compareEventGroupId !== null) {
        //Find the new index of the compare group based on its ID:
        let compareIndex = groups.findIndex(group => group.id === compareEventGroupId);
        $projectState.compare = compareIndex;
      }
    }
  </script>
  <ul id="EventGroups" class="nav nav-tabs">
    <li class="nav-item">
      <button type="button" class="btn btn-light rounded-bottom-0 border-0 bg-light h-100" on:click={() => modalState.showModal("newEventGroup")}>
        <i class="bi bi-plus-circle-fill"></i>
      </button>
    </li>
    {#if $eventGroupState.length > 0}
      {#each $eventGroupState as eventGroup, index(index)}
        <li
          class="nav-item"
          draggable="true"
          on:dragstart={(e)=>handleDragStart(e, index)}
          on:dragover={handleDragOver}
          on:drop={(e)=>handleDrop(e, index)}>
          <EventGroupTab 
            name={eventGroup.name}
            eventGroupIndex={index}
            eventGroupType={eventGroup.type}
          />
        </li>
      {/each}
    {/if}
  </ul>
  
  <style>
    #EventGroups {
      overflow-x: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
      display: -webkit-box;
    }
  </style>