import _stripTags from "../functions/quill/stripTags.js";
import _autoFormatSimple from "../functions/utility/autoFormatSimple.js";
import _sccLookup from "../dict/608.js";
import _sccFunc from "../functions/profiles/scenerist.js";
import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
/* 
    Title Safe Info:
    http://www.indefilms.net/html/title_safe_area.html
    SCC INFO:
    -We work on a grid that is 0-31 across and 0-14 down (32x15)
    -There is a fixed offset of 10% (x) and 10% (y). This is due to the caption area being 90% of the frames width and 90% of the frames height.
    -The fixed offset is only applied to decode. For encode we try and fit it to the best location.
    -The fixed offset is applied during post-process decode.
*/
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const eol = _eol;
const sccFunc = _sccFunc;
const sccLookup = _sccLookup;
const autoFormatSimple = _autoFormatSimple;
const stripTags = _stripTags;
export default {
  decode: function (input, options) {
    throw new Error("Please use the default Scenerist Profile for decoding SCC files.");
  },
  encode: function (eventGroups, options) {
    let output = "Scenarist_SCC V1.0",
      encodingOptions = getFormatOptions(options.formatOptions),
      channels = [],
      processing = true,
      eventGroupChannelMappings,
      timecodeOption = "auto",
      vChipCmds,
      contentAdvisoryCmds,
      programNameCmds,
      programLengthCmds,
      clock;

    //The ccChannelStatus manages the status of each channel so that we know when clear/and start commands need to be sent/updated.
    let ccChannelStatus = [{
      id: "ch01",
      screen: false,
      buffer: false
    }, {
      id: "ch02",
      screen: false,
      buffer: false
    }, {
      id: "ch03",
      screen: false,
      buffer: false
    }, {
      id: "ch04",
      screen: false,
      buffer: false
    }];

    /* console.log(JSON.stringify(eventGroups, null, 4));
    console.log(JSON.stringify(options, null, 4)); */

    if (encodingOptions["Program Information"] && encodingOptions["Program Information"].enable) {
      if (encodingOptions["Program Information"].programName) {
        //Program Name & maybe description later on
        programNameCmds = sccFunc.encodeProgramName(encodingOptions["Program Information"].programName);
        programNameCmds = sccFunc.formatEncodedCmds(programNameCmds);
        /* Program Length */
        programLengthCmds = sccFunc.encodeProgramLength(encodingOptions["Program Information"].programLengthHours, encodingOptions["Program Information"].programLengthMinutes);
        programLengthCmds = sccFunc.formatEncodedCmds(programLengthCmds);
        /* Keyword Groups */
        contentAdvisoryCmds = sccFunc.encodeProgramType([encodingOptions["Program Information"].basicKeywordGroup, ...encodingOptions["Program Information"].detailKeywordGroup]);
        contentAdvisoryCmds = sccFunc.formatEncodedCmds(contentAdvisoryCmds);

        //console.log("Program Name Commands:", programNameCmds);
        //console.log("Porprogram Length Commands:", programLengthCmds);
        //console.log("Content Advisory Commands:", contentAdvisoryCmds); 
      }
    }
    if (encodingOptions["V-Chip Information"] && encodingOptions["V-Chip Information"].enable) {
      vChipCmds = sccFunc.encodeVChipInfo(encodingOptions["V-Chip Information"].type, encodingOptions["V-Chip Information"].rating, encodingOptions["V-Chip Information"].content);
      vChipCmds = sccFunc.formatEncodedCmds(vChipCmds);
      //console.log("VChip Commands:", vChipCmds);
    }
    if (encodingOptions["Event Group Channel Mappings"]) {
      eventGroupChannelMappings = encodingOptions["Event Group Channel Mappings"];
      eventGroupChannelMappings = {
        "cc1": eventGroups[eventGroupChannelMappings["cc1"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc1"]])) : undefined,
        "cc2": eventGroups[eventGroupChannelMappings["cc2"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc2"]])) : undefined,
        "cc3": eventGroups[eventGroupChannelMappings["cc3"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc3"]])) : undefined,
        "cc4": eventGroups[eventGroupChannelMappings["cc4"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc4"]])) : undefined
      };
    } else {
      eventGroupChannelMappings = {
        "cc1": JSON.parse(JSON.stringify(eventGroups[0]))
      };
    }
    if (encodingOptions["Timecode Format"]) {
      timecodeOption = encodingOptions["Timecode Format"].toLowerCase();
    }

    // console.log(options.incode);
    if (options.incode && options.incode !== "00:00:00:00" && options.incode !== "00:00:00;00") {
      try {
        clock = tcLib.createTc(options.incode, options.frameRate, options.dropFrame);
      } catch (err) {
        // console.log(err, err.message);
        throw new Error(err.message);
      }
    } else {
      clock = tcLib.createTc(tcLib.secToTc(eventGroups[0].events[0].start, options.frameRate), options.frameRate, options.dropFrame);
      try {
        clock.subtract(300);
      } catch (e) {
        // console.log(e);
        // console.log(e.message);
        clock = tcLib.createTc("00:00:00:01", options.frameRate, options.dropFrame);
      }
    }

    /* Do some simple math to figure out when we need to start encoding an event and when we need to play the Event */
    Object.keys(eventGroupChannelMappings).forEach(ch => {
      if (!eventGroupChannelMappings[ch]) {
        return;
      }
      let channel = ch.replace("cc", "ch0");
      channels.push(channel);
      eventGroupChannelMappings[ch].events.forEach((event, index, events) => {
        events[index].processed = false;
        events[index].channel = channel;
        events[index].startFrame = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].endFrame = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].eventDetails = sccFunc.getEventDetails(event, options.window);
        events[index].encodedText = sccFunc.encodeEvent(events[index].eventDetails, channel, options.window);
        events[index].encodedTextString = sccFunc.formatEncodedCmds(events[index].encodedText);
        events[index].encodeTime = sccFunc.calculateEncodeTime(events[index].encodedTextString);
        events[index].encodeStartTime = event.style === "Pop-On" ? events[index].startFrame - (events[index].encodeTime + 75) : events[index].startFrame;
      });
    });

    //console.log(JSON.stringify(eventGroupChannelMappings, null, 4));

    output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t";
    channels.forEach(ch => {
      output += sccFunc.getCodeByCmd(sccLookup[ch], "{CLEAR DISPLAY}") + " " + sccFunc.getCodeByCmd(sccLookup[ch], "{CLEAR BUFFER}") + " ";
      clock.add(2);
    });

    /* Do we really want to start encoding XDS data at the beginning of the file or can we wait until we know we have time? */
    /*      if (vChipCmds) {
                output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" +  vChipCmds;
                clock.add(vChipCmds.split(" ").length+2);
            }
            
            if (contentAdvisoryCmds){
                output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" +  contentAdvisoryCmds;
                clock.add(contentAdvisoryCmds.split(" ").length+2);
            }
     */
    let eventsToProcess, displayOrClearCommands;
    while (processing) {
      //Get display or clear commands based on the ccChannelStatus and the current clock time.
      displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
      if (displayOrClearCommands.scc.length > 0) {
        output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
        clock.add(4);
      }
      eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, clock.frameCount);
      if (eventsToProcess.length > 0) {
        let increaseClock = true;
        eventsToProcess.forEach((event, index, events) => {
          let channelIndex = parseInt(event.channel.charAt(event.channel.length - 1)) - 1;
          //check to see if there is an event in the buffer for this channel already. And if so, return.
          if (event.style === "Pop-On" && ccChannelStatus[channelIndex].buffer) {
            return;
          } else {
            increaseClock = false;
          }

          /* Encode VCHIP Before EACH EVENT */
          if (vChipCmds && channelIndex === 0 && event.style === "Pop-On") {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + vChipCmds;
            clock.add(vChipCmds.split(" ").length + 2);
          }

          //console.log("writing to channel: ", event.channel, "("+channelIndex+") at time: ", tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption));
          output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t";
          event.encodedTextString.split(" ").forEach((cmd, index, cmds) => {
            output += cmd + " ";
            clock.add(1);

            /* Do we want to interrupt encoding an Event to show or hide what's on screen? */
            /* I don't think we ever want to do this. MacCaption always just finishes writing the cmds before clearing anything */
            /* displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
            if (displayOrClearCommands.scc.length > 0) {
                output += displayOrClearCommands.scc.join(" ") + " " + cmds[0] + " ";
                clock.add(displayOrClearCommands.scc.length + 2);
            } */
          });
          if (event.style === "Pop-On" && event.startFrame <= clock.frameCount) {
            output += sccFunc.getCodeByCmd(sccLookup[event.channel], "{DISPLAY BUFFER}") + " " + sccFunc.getCodeByCmd(sccLookup[event.channel], "{CLEAR BUFFER}") + " ";
            clock.add(4);
            //console.log("Adding Event to Screen");
            ccChannelStatus[channelIndex].screen = event;
          } else if (event.style === "Pop-On") {
            //console.log("adding event to buffer");
            ccChannelStatus[channelIndex].buffer = event;
          } else {
            ccChannelStatus[channelIndex].screen = event;
          }
          events[index].processed = true;
        });
        if (increaseClock) {
          clock.add(1);
        }
      } else {
        if (contentAdvisoryCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + contentAdvisoryCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + contentAdvisoryCmds;
            clock.add(contentAdvisoryCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }

        //Insert XDS Data if possible:
        if (vChipCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + vChipCmds.split(" ").length + 2);
          //console.log(eventsToProcess.length);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            //console.log("EMBEDDING VCHIP INFO");
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + vChipCmds;
            clock.add(vChipCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        if (programNameCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programNameCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + programNameCmds;
            clock.add(programNameCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        if (programLengthCmds) {
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programLengthCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + programLengthCmds;
            clock.add(programLengthCmds.split(" ").length + 2);
          }
          displayOrClearCommands = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);
          if (displayOrClearCommands.scc.length > 0) {
            output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t" + displayOrClearCommands.scc.join(" ") + " ";
            clock.add(4);
          }
        }
        clock.add(1);
      }

      /* Figure out if we need to close the loop */
      let eventsToStillProcess = sccFunc.getNumberOfEventsToProcess(eventGroupChannelMappings);
      if (eventsToStillProcess === 0) {
        if (sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
          processing = true;
        } else {
          processing = false;
        }
      }
    }
    if (encodingOptions["Line Endings"]) {
      if (encodingOptions["Line Endings"].toLowerCase() === "windows") {
        output = eol.crlf(output);
      } else if (encodingOptions["Line Endings"].toLowerCase() === "macintosh") {
        output = eol.cr(output);
      }
    }
    return output;
  },
  preProcess: {
    encode: function (eventGroup, options) {
      eventGroup.events.forEach((event, index, events) => {
        /* console.log("---------");
        console.log("BEFORE:");
        console.log(event.text);  */
        if (!sccFunc.verifyFormatting(event, options.window)) {
          /* Debug */
          //console.log("--------------");
          //console.log(event.text);
          events[index].text = autoFormatSimple(event.text);
          events[index].xPos = "center";
          events[index].yPos = "end";
          events[index].xOffset = options.window.xOffset;
          events[index].yOffset = options.window.yOffset;
        }
        sccLookup.specialCharsFilter.forEach(specialChar => {
          let regexPattern = new RegExp(`\\` + specialChar, "g");
          events[index].text = event.text.replace(regexPattern, sccLookup.specialCharacterReplacement[specialChar] + specialChar);
        });
        events[index].text = sccFunc.duplicateMusicNotes(events[index].text);

        /* console.log("AFTER:");
        console.log(events[index].text);  */
      });
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.toLowerCase().replace(/ +/g, " ").trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      /* 
          We decoded the SCC using the 32x15 grid but now we need to center that grid over the window. There is a fixed offset of 30% (x) and 10% (y). This is due to the caption area being 70% of the frames width and 90% of the frames height. 
      */
      /* if (options.jobInfo.target_profile !== "scenerist" && options.jobInfo.target_profile !== "closedCaptionProject") {
          eventGroup = convertToPopOn(eventGroup, 2, 32);
      } */

      if (eventGroup.events.length > 0 && eventGroup.events[0].style === "Pop-On" && stripTags(eventGroup.events[0].text).trim() == "") {
        eventGroup.events.shift();
      }
      eventGroup.events.forEach((event, index, events) => {
        // console.log("---------");
        // console.log("BEFORE:");
        // console.log(event.text);        
        sccLookup.specialCharsFilter.forEach(specialChar => {
          let regexPattern = new RegExp(`(.){1}\\` + specialChar, "g");
          events[index].text = event.text.replace(regexPattern, specialChar);
        });
        events[index].text = sccFunc.replaceMusicNotes(events[index].text);

        // console.log("AFTER:");
        // console.log(events[index].text); 
      });
      //console.log(JSON.stringify(eventGroup, null, 4));
      return eventGroup;
    }
  }
};