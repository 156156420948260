<script>
import { onMount } from 'svelte';
import {playerState} from '@app/store/playerStore.js';
export let scaleMultiplier = 10;
let playheadElement;

onMount(() => {
    playheadElement = document.getElementById("playhead");
});

$: if (playheadElement && $playerState.time){
    setTimeout(function(){
        playheadElement.scrollIntoViewIfNeeded(true);
        /* playheadElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        }); */
    }, 750);    
}
 </script>
<div id="playhead" class="d-flex align-items-end" style="left: {$playerState.time*scaleMultiplier}px;"><i class="bi bi-caret-up-fill"></i></div> 
<style>
#playhead {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #ffd700;
  bottom: 0px;
  transition: left 0.75s ease;
}

.bi-caret-up-fill {
    transform: translateX(-7.25px);
    color: #ffd700;
    height:15px;
}

</style>