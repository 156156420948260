<script>
export let type;
</script>

{#if type==='subtitle'}
<i class="bi bi-badge-cc"></i>
{:else if type ==='transcription'}
<i class="bi bi-card-text"></i>
{:else if type ==='audio description'}
<i class="bi bi-badge-ad"></i>
{:else}
<i class="bi bi-translate"></i>
{/if}
