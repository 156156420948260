export default (async function analyzeDrift(events) {
  let maxDrift = 0;
  let minDrift = 99999999999999;
  let avgDrift = 0;
  let totalEvents = 0;
  let totalDriftMultiplier = [];
  events.forEach((event, index, allEvents) => {
    if (event.start && event.oStart) {
      let drift = Math.abs(event.start - event.oStart);
      if (drift > maxDrift) maxDrift = drift;
      if (drift < minDrift) minDrift = drift;
      totalDriftMultiplier.push(event.start / event.oStart);
      avgDrift += drift;
      totalEvents++;
    }
  });
  return {
    maxDrift: maxDrift,
    minDrift: minDrift,
    rangeDrift: maxDrift - minDrift,
    avgDrift: avgDrift / totalEvents,
    avgDriftMultiplier: totalDriftMultiplier.reduce((a, b) => a + b, 0) / totalEvents
  };
});