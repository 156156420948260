<script>
    import { explorerState } from "@app/store/explorerStore.js";
    import { authState } from "@app/store/authStore.js";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    import "firebase/functions";

    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    let storageRef = storage.ref();
    let homeStorageBasePath = `users/${userId}/projects`;
    let teamStorageBasePath = "teams/" + teamId + "/storage/";
    let loading = false;

    /* Get records where folder Id is the records locationId */
    async function getRecords(folderId = null){
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];

            // console.log("Getting Records for Folder ID: " + folderId);
            let querySnapshot = await ($explorerState.rootDir === "personal" ? homeDbRef : teamDbRef).where("locationId", "==", folderId).orderBy("type").orderBy("name", "asc").get();

            querySnapshot.forEach((doc) => {
                $explorerState.records = [...$explorerState.records, {...(doc.data()), rootDir : $explorerState.rootDir}];
            });

            // console.log("----RECORDS----");
            // console.log($explorerState.records);
        } catch(err){
            console.error(err);
            toast.push(error.message, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }   
    }

    function navigateHistory(index) {
        if(index === null){
            $explorerState.navHistory = [];
            getRecords();
        } else {
            let record = $explorerState.navHistory[index];
            $explorerState.navHistory = $explorerState.navHistory.slice(0, index+1);
            getRecords(record.id);
        }
    }
</script>
<div class="col-4">
    <ol class="breadcrumb">        
        {#if $explorerState.navHistory.length === 0}
            <!-- No history just show the current folder we're in -->
            <li class="breadcrumb-item text-capitalize" aria-current="page"><span>{$explorerState.rootDir}</span></li>
        {:else if $explorerState.navHistory.length > 2}
            <!-- History more than 2 we show an ellipses to go back and then the last 2 -->
            <li class="breadcrumb-item text-capitalize"><a href="#!/" on:click={() => navigateHistory($explorerState.navHistory.length - 3)}><span>...</span></a></li>
            {#each $explorerState.navHistory.slice($explorerState.navHistory.length - 2, $explorerState.navHistory.length) as record, i}
                {@const actualIndex = $explorerState.navHistory.length - 2 + i}
                {#if i === 1} <!-- Last item -->
                    <li class="breadcrumb-item text-capitalize" aria-current="page"><span>{record.name}</span></li>
                {:else}
                    <li class="breadcrumb-item text-capitalize"><a href="#!/" on:click={() => navigateHistory(actualIndex)}><span>{record.name}</span></a></li>
                {/if}
            {/each}
        {:else}
            <!-- Show all the history -->
            <li class="breadcrumb-item text-capitalize"><a href="#!/" on:click={() => navigateHistory(null)}><span>{$explorerState.rootDir}</span></a></li>
            {#each $explorerState.navHistory as record, index}
                {#if index === $explorerState.navHistory.length - 1}
                    <li class="breadcrumb-item text-capitalize" aria-current="page"><span>{record.name}</span></li>
                {:else}
                    <li class="breadcrumb-item text-capitalize"><a href="#!/" on:click={() => navigateHistory(index)}><span>{record.name}</span></a></li>
                {/if}
            {/each}
        {/if}
    </ol>
</div>