<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

const alignRight = () => {
    if (!quillEditor){
        return;
    }
    
    quillEditor.setSelection(0, quillEditor.getLength());
    quillEditor.format('align', 'right');
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        $eventGroupState[$projectState.selected].events[eventIndex].alignment = "right";
    });

    historyState.insert({
        name: "align right", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}
</script>
<li class="nav-item" title="Align Right">
    <a class="nav-link" href="#!/" on:click={alignRight} class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}"><i class="bi bi-text-right" /></a>
</li>