import _quillClasses from "../dict/quillClasses.js";
import _eol from "eol";
import _flexbox from "../dict/flexbox.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const flexbox = _flexbox;
const eol = _eol;
const quillClasses = _quillClasses;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent,
      horzAlign = "center",
      vertAlign = "bottom",
      xOffset = 0,
      yOffset = 0,
      tcIn,
      tcOut,
      text,
      italics = false,
      bold = false,
      underline = false;
    const tcRegex = /\d\d:\d\d:\d\d:\d\d/g;
    const fileLines = eol.split(input).filter(fileLine => {
      return fileLine;
    });
    fileLines.forEach(fileLine => {
      let lineParts = fileLine.split(" ");
      if (lineParts[0] === "$HorzAlign") {
        horzAlign = flexbox.alignmentMap[lineParts[2].toLowerCase()];
      } else if (lineParts[0] === "$XOffset") {
        xOffset = parseFloat(lineParts[2]) / 480 * options.window.width;
      } else if (lineParts[0] === "$VertAlign") {
        vertAlign = flexbox.alignmentMap[lineParts[2].toLowerCase()];
      } else if (lineParts[0] === "$YOffset") {
        xOffset = parseFloat(lineParts[2]) / 720 * options.window.height;
      } else if (lineParts[0] === "$Italic") {
        italics = lineParts[2] && lineParts[2].toLowerCase() === "true" ? true : false;
      } else if (lineParts[0] === "$Bold") {
        bold = lineParts[2] && lineParts[2].toLowerCase() === "true" ? true : false;
      } else if (lineParts[0] === "$Underline") {
        underline = lineParts[2] && lineParts[2].toLowerCase() === "true" ? true : false;
      } else if (fileLine.match(/^\d\d:\d\d:\d\d:\d\d/g)) {
        let matches = fileLine.match(tcRegex);
        tcIn = matches[0];
        tcOut = matches[1];
        let textLine = fileLine.split(",");
        textLine.shift();
        textLine.shift();
        text = textLine.join(",").replace(/\^[BUI]/g, "").replace(/\s*\|\s*/g, "\n");
        if (bold) {
          text = `<b>${text}</b>`;
        }
        if (italics) {
          text = `<i>${text}</i>`;
        }
        if (underline) {
          text = `<u>${text}</u>`;
        }
        ccEvent = new Event();
        ccEvent.start = tcLib.tcToSec(tcIn, options.frameRate);
        ccEvent.end = tcLib.tcToSec(tcOut, options.frameRate);
        ccEvent.text = convertToHtml(text, [quillClasses.align[horzAlign]]);
        ccEvent.xPos = horzAlign;
        ccEvent.yPos = vertAlign;
        ccEvent.xOffset = xOffset;
        ccEvent.yOffset = yOffset;
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = eol.after(`$FontName           = Courier New
$FontSize           = 9

$Bold               = FALSE
$Underlined         = FALSE
$Italic             = FALSE

$HorzAlign          = Center
$VertAlign          = Bottom
$XOffset            = 0
$YOffset            = -48


//---------------------------------------------------------------
// Uncomment the following lines to set color contrast settings
//---------------------------------------------------------------
// $TextContrast       = 15
// $Outline1Contrast   = 15
// $Outline2Contrast   = 15
// $BackgroundContrast = 0
`);
    let horzAlign = "center",
      vertAlign = "bottom";
    eventGroup.events.forEach(event => {
      if (flexbox.flexMap.horizontal[event.xPos] !== horzAlign) {
        horzAlign = flexbox.flexMap.horizontal[event.xPos];
        output += eol.after(`$HorzAlign = ${horzAlign.toUpperCase()}`);
      }
      if (flexbox.flexMap.vertical[event.yPos] !== vertAlign) {
        vertAlign = flexbox.flexMap.vertical[event.yPos];
        output += eol.after(`$VertAlign = ${vertAlign.toUpperCase()}`);
      }
      let text = event.text.replace(/<strong>/gmi, "^B").replace(/<em>/gmi, "^I").replace(/<u>/gmi, "^U");
      output += eol.after(`${tcLib.secToTc(event.start, options.frameRate)},${tcLib.secToTc(event.end, options.frameRate)},${convertToPlainText(text, "|").replace(/\|$/gmi, "")}`);
    });
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input.trim();
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};