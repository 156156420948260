<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import {
    fade
} from "svelte/transition";
import {
    v4 as uuidv4
} from 'uuid';

let selectedMetadata, metadataProp = "",
    metadataValue = "";

function reset(){
    selectedMetadata = undefined;
    metadataProp = "";
    metadataValue = "";
}

function selectMetadata(m){
    selectedMetadata = m;
    metadataProp = m.prop;
    metadataValue = m.value;
}

function addMetadata() {
    if (!metadataProp || !metadataValue){
        return;
    }

    $eventGroupState[$projectState.selected].metadata = [
        ...$eventGroupState[$projectState.selected].metadata,
        {
            id: uuidv4(),
            prop : metadataProp,
            value: metadataValue
        }
    ]

    historyState.insert({
        name: "insert metadata", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    reset();
}

function editMetadata() {
    let index = $eventGroupState[$projectState.selected].metadata.findIndex(m =>{
        return m.id === selectedMetadata.id;
    });

    if (index > -1 && metadataProp && metadataValue){
        $eventGroupState[$projectState.selected].metadata[index].prop = metadataProp;
        $eventGroupState[$projectState.selected].metadata[index].value = metadataValue;
    }

    $eventGroupState = $eventGroupState;

    historyState.insert({
        name: "update metadata", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    reset();
}

function removeMetadata(id) {
    $eventGroupState[$projectState.selected].metadata = $eventGroupState[$projectState.selected].metadata.filter(m =>{
        return m.id !== id
    });

    $eventGroupState = $eventGroupState;

    historyState.insert({
        name: "remove metadata", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    reset();
}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'eventGroupMetadata' ? 'show d-block' : ''}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Event Group Metadata</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <form on:submit|preventDefault="{()=>{!selectedMetadata ? addMetadata() : editMetadata()}}">
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3">
                                            <label class="form-label" for="MetadataPropertyInput">Metadata Property</label>
                                            <input class="form-control" type="text" bind:value={metadataProp}>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="mb-3">
                                            <label class="form-label" for="MetadataValueInput">Metadata Value</label>
                                            <input class="form-control" type="text" bind:value={metadataValue}>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-xxl-flex justify-content-xxl-end mb-3">
                                    <div class="btn-group" role="group">
                                        {#if selectedMetadata}
                                        <button class="btn btn-info text-white" type="button" on:click={() => editMetadata()}>Edit Metadata</button>
                                        {:else}
                                        <button class="btn btn-primary" type="button" on:click={() => addMetadata()}>Save Metadata</button>
                                        {/if}
                                        <button class="btn btn-light" type="button" on:click={() => reset()}>Reset Form</button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Metadata Property</th>
                                            <th>Metadata Value</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {#each $eventGroupState[$projectState.selected].metadata as meta, index (meta.id)}
                                        <tr>
                                            <td>{meta.prop}</td>
                                            <td>{meta.value}</td>
                                            <td>
                                                <div class="btn-group btn-group-sm" role="group">
                                                    <button class="btn btn-primary" on:click={() => selectMetadata(meta)} type="button"><i class="bi bi-pencil-square"></i></button>
                                                    <button class="btn btn-danger text-white" on:click={() => removeMetadata(meta.id)} type="button"><i class="bi bi-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                        {/each}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
