import _Event2 from "../classes/event.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _tcLib from "../lib/timecode.js";
import _eol from "eol";
import _Papa from "papaparse";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const Papa = _Papa;
const eol = _eol;
const tcLib = _tcLib;
const convertToPlainText = _convertToPlainText;
const convertToHtml = _convertToHtml;
const _Event = _Event2;
const resolveColors = ["Blue", "Cyan", "Green", "Yellow", "Red", "Pink", "Purple", "Fuchsia", "Rose", "Lavender", "Sky", "Mint", "Lemon", "Sand", "Cocoa", "Cream"];
export default {
  decode: async function (input, options) {
    let events = [];
    input = eol.lf(input).replace(/(?:\r\n|\r|\n)+/g, "\n");
    let inputJson = Papa.parse(input);
    inputJson.data.shift(); //Remove Headers
    //console.log(inputJson);
    inputJson.data.forEach(row => {
      try {
        if (row[0]) {
          let ev = new _Event();
          ev.start = tcLib.tcToSec(row[6], options.frameRate);
          ev.end = tcLib.tcToSec(row[7], options.frameRate);
          ev.text = convertToHtml(row[20]);
          events.push(ev);
        }
      } catch (err) {
        console.log(err.message);
      }
    });

    //console.log(JSON.stringify(events));
    return events;
  },
  encode: async function (eventGroup, options) {
    let speakerMapping = {};
    let speakers = [];
    eventGroup.events.forEach(ev => {
      ev.speakers.forEach(sp => {
        speakers.push(sp.id);
      });
    });
    speakers = [...new Set(speakers)];
    speakers.forEach((sp, index) => {
      speakerMapping[sp] = resolveColors[index] ? resolveColors[index] : resolveColors[index % 16];
    });
    let formattedEvents = eventGroup.events.map((event, index) => {
      return {
        "#": index + 1,
        "Reel": "",
        "Match": "",
        "V": "",
        "C": "C",
        "Dur": "",
        "Source In": tcLib.secToTc(event.start, options.frameRate, options.dropFrame),
        "Source Out": tcLib.secToTc(event.end, options.frameRate, options.dropFrame),
        "Record Duration": tcLib.secToTc(event.end - event.start, options.frameRate, options.dropFrame),
        "Record In": tcLib.secToTc(event.start, options.frameRate, options.dropFrame),
        "Record Out": tcLib.secToTc(event.end, options.frameRate, options.dropFrame),
        "Name": `Marker ${index + 1}`,
        "Comments": event.notes,
        "Source Start": "",
        "Source End": "",
        "Source Duration": "",
        "Codec": "",
        "Source FPS": "0.000",
        "Resolution": "",
        "Color": event.speakers.length > 0 ? speakerMapping[event.speakers[0].id] : "Blue",
        "Notes": convertToPlainText(event.text),
        "EDL Clip Name": "",
        "Marker Keywords": ""
      };
    });
    // console.log(formattedEvents);
    let output = Papa.unparse(JSON.stringify(formattedEvents), {
      header: true,
      quotes: true,
      newline: "\r\n"
    });
    // console.log(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};