import _Speaker2 from "../classes/speaker.js";
import _Event2 from "../classes/event.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _tcLib from "../lib/timecode.js";
import _eol from "eol";
import _Papa from "papaparse";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const Papa = _Papa;
const eol = _eol;
const tcLib = _tcLib;
const convertToPlainText = _convertToPlainText;
const convertToHtml = _convertToHtml;
const _Event = _Event2;
const _Speaker = _Speaker2;
export default {
  decode: async function (input, options) {
    let speakers = [],
      events = [];
    input = eol.lf(input).replace(/(?:\r\n|\r|\n)+/g, "\n");
    let inputJson = Papa.parse(input);
    //console.log(inputJson);  

    inputJson.data.forEach(row => {
      try {
        if (row[0]) {
          let ev = new _Event();
          ev.start = tcLib.tcToSec(row[1], options.frameRate);
          ev.end = tcLib.tcToSec(row[2], options.frameRate);
          ev.text = convertToHtml(row[4].replace(/<br>/gmi, "\n"));
          let speakerIndex = speakers.findIndex(sp => {
            sp.name === row[3];
          });
          if (speakerIndex > -1) {
            ev.speakers = [speakers[speakerIndex]];
          } else {
            speakers.push(new _Speaker({
              name: row[3]
            }));
            ev.speakers = [speakers[speakers.length - 1]];
          }
          events.push(ev);
        }
      } catch (err) {
        console.log(err.message);
      }
    });

    //console.log(JSON.stringify(events));
    return events;
  },
  encode: async function (eventGroup, options) {
    let formattedEvents = eventGroup.events.map((event, index) => {
      return {
        index: index + 1,
        start: tcLib.secToTc(event.start, options.frameRate, options.dropFrame),
        end: tcLib.secToTc(event.end, options.frameRate, options.dropFrame),
        speaker: event.speakers.length > 0 && event.speakers[0].name ? event.speakers[0].name : "",
        text: convertToPlainText(event.text).replace(/(?:\r\n|\r|\n)/g, '<br>'),
        done: "False"
      };
    });
    //console.log(formattedEvents);
    let output = Papa.unparse(JSON.stringify(formattedEvents), {
      header: false,
      quotes: true,
      newline: "\r\n\r\n"
    });
    //console.log(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};