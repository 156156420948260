export default {
  alignmentMap: {
    "left": "start",
    "center": "center",
    "right": "end",
    "top": "start",
    "bottom": "end"
  },
  alignmentNormalize: {
    "left": "left",
    "center": "center",
    "middle": "center",
    "right": "right",
    "start": "left",
    "end": "right"
  },
  positionNormalize: {
    "left": "start",
    "center": "center",
    "middle": "center",
    "right": "end",
    "start": "start",
    "end": "end",
    "top": "start",
    "bottom": "end"
  },
  flexMap: {
    vertical: {
      "start": "top",
      "center": "center",
      "end": "bottom"
    },
    horizontal: {
      "start": "left",
      "center": "center",
      "end": "right"
    }
  }
};