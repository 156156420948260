<script>
function exit() {
    const ipcRenderer = window.ipcRenderer; 
    ipcRenderer.invoke('quit');
}
</script>
<a class="dropdown-item" 
   href="#!/" 
   id="exitMenuItem"
   role="menuitem"
   aria-label="Exit application"
   on:click={exit}>Exit</a>