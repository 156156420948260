import _quillClasses from "../dict/quillClasses.js";
import _flexbox from "../dict/flexbox.js";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import { decode as _decodeHtml } from "html-entities";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const decodeHtml = _decodeHtml; //Encode
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
const flexbox = _flexbox;
const quillClasses = _quillClasses;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(JSON.stringify(fileJson, null, 4));
    fileJson.tt.body.div.p.forEach(paragraph => {
      ccEvent = new Event();
      ccEvent.start = tcLib.tcClockToSec(paragraph["@_begin"], options.frameRate);
      ccEvent.end = tcLib.tcClockToSec(paragraph["@_end"], options.frameRate);

      /* Positioning */
      ccEvent.alignment = paragraph["@_tts:textAlign"] || "center";
      if (paragraph["@_tts:textAlign"]) {
        ccEvent.yPos = flexbox.alignmentMap[paragraph["@_tts:textAlign"].toLowerCase()];
      }
      ccEvent.text = decodeHtml(convertToHtml(ttmlFunc.multiLine.formatText(paragraph["#text"]), [quillClasses.align[ccEvent.alignment]]));
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = `<?xml version="1.0" encoding="UTF-8"?>`;
    output += eol.after(`<tt xml:lang="en"
        xmlns="http://www.w3.org/ns/ttml"
        xmlns:tts="http://www.w3.org/ns/ttml#styling"
        xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        xmlns:ttp="http://www.w3.org/ns/ttml#parameter"
        ttp:profile="http://www.netflix.com/ns/ttml/profile/NFTT-SDH"
        ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}">`);
    output += eol.after(`<head>
        <metadata>
         <ttm:title>Untitled</ttm:title>
         <ttm:desc>Closed Caption Converter - Netflix TT Subtitles</ttm:desc>
         <smpte:information
          xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
          origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
          mode="Preserved" m608:channel="CC1" m608:programName="Untitled" m608:captionService="F1C1CC"
         />
        </metadata>
        <styling>
         <style xml:id='basic' tts:color='white' tts:backgroundColor='transparent' tts:fontSize='100%' tts:fontWeight='normal' tts:fontStyle='normal'/>
        </styling>
        <layout>
         <region xml:id='top' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='before'></region>
         <region xml:id='bottom' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='after'></region>
         <region xml:id='center' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='after'></region>
        </layout>
       </head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div>`);
    eventGroup.events.forEach(event => {
      let start = tcLib.secToTcClock(event.start, options.frameRate);
      let end = tcLib.secToTcClock(event.end, options.frameRate);
      let region = flexbox.flexMap.vertical[event.yPos];
      let text = ttmlFunc.multiLine.convertToTtml(convertToPlainTextCustom(event.text));
      output += eol.after(`<p begin='${start}' end='${end}' region='${region}' style='basic' tts:textAlign='${event.alignment}'>${text}</p>`);
    });
    output += eol.after(`</body>`);
    output += eol.after(`</div>`);
    output += eol.after(`</tt>`);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};