<script>
import Router from "svelte-spa-router";
import {
    environment
} from "@app/store/envStore.js";
import {
    replace
} from "svelte-spa-router";
// Import the list of routes
import routes from "./routes.js";

function conditionsFailed(event) {
    // Perform any action, for example replacing the current route
    if ($environment.iframe && !$environment.online){
        replace("/");
    } else if ($environment.online){
        if (event.detail.location === "/") {
            replace("/login");
        } else if (event.detail.location === "/login") {
            replace("/");
        }
    } else {
        if (event.detail.location === "/") {
            replace("/loginOffline");
        } else if (event.detail.location === "/loginOffline") {
            replace("/");
        }
    }
}
</script>

<div>
    <Router
        {routes}
        on:conditionsFailed={conditionsFailed}
        />
</div>
