const quillClasses = require("../../dict/quillClasses.js");
const formatTags = require("../../dict/formatTags.js");
const combineSpanStyles = require("./combineSpanStyles.js");
const closeHtmlTags = require("./closeHtmlTags.js");
const eol = require ('eol');
module.exports = function convertToHtml(text, classNames = null, boldTag = "b", italicTag = "i", underLineTag = "u", colorBlackTag = "black", colorRedTag = "red", colorYellowTag = "yellow", colorGreenTag = "green", colorBlueTag = "blue", colorMagentaTag = "magenta", colorCyanTag = "cyan", colorWhiteTag = "white", colorBgBlackTag = "bgBlack", colorBgRedTag = "bgRed", colorBgYellowTag = "bgYellow", colorBgGreenTag = "bgGreen", colorBgBlueTag = "bgBlue", colorBgMagentaTag = "bgMagenta", colorBgCyanTag = "bgCyan", colorBgWhiteTag = "bgWhite"){
    let boldOpenPattern = new RegExp("<"+boldTag+">", "gmi"),
        boldClosePattern = new RegExp("</"+boldTag+">", "gmi"),
        italicOpenPattern = new RegExp("<"+italicTag+">", "gmi"),
        italicClosePattern = new RegExp("</"+italicTag+">", "gmi"),
        underlineOpenPattern = new RegExp("<"+underLineTag+">", "gmi"),
        underlineClosePattern = new RegExp("</"+underLineTag+">", "gmi"),
        // Text Color
        blackOpenPattern = new RegExp("<"+colorBlackTag+">", "gmi"),
        blackClosePattern = new RegExp("</"+colorBlackTag+">", "gmi"),
        redOpenPattern = new RegExp("<"+colorRedTag+">", "gmi"),
        redClosePattern = new RegExp("</"+colorRedTag+">", "gmi"),
        yellowOpenPattern = new RegExp("<"+colorYellowTag+">", "gmi"),
        yellowClosePattern = new RegExp("</"+colorYellowTag+">", "gmi"),
        greenOpenPattern = new RegExp("<"+colorGreenTag+">", "gmi"),
        greenClosePattern = new RegExp("</"+colorGreenTag+">", "gmi"),
        blueOpenPattern = new RegExp("<"+colorBlueTag+">", "gmi"),
        blueClosePattern = new RegExp("</"+colorBlueTag+">", "gmi"),
        magentaOpenPattern = new RegExp("<"+colorMagentaTag+">", "gmi"),
        magentaClosePattern = new RegExp("</"+colorMagentaTag+">", "gmi"),
        cyanOpenPattern = new RegExp("<"+colorCyanTag+">", "gmi"),
        cyankClosePattern = new RegExp("</"+colorCyanTag+">", "gmi"),
        whiteOpenPattern = new RegExp("<"+colorWhiteTag+">", "gmi"),
        whiteClosePattern = new RegExp("</"+colorWhiteTag+">", "gmi"),
        // Background Color
        blackBgOpenPattern = new RegExp("<"+colorBgBlackTag+">", "gmi"),
        blackBgClosePattern = new RegExp("</"+colorBgBlackTag+">", "gmi"),
        redBgOpenPattern = new RegExp("<"+colorBgRedTag+">", "gmi"),
        redBgClosePattern = new RegExp("</"+colorBgRedTag+">", "gmi"),
        yellowBgOpenPattern = new RegExp("<"+colorBgYellowTag+">", "gmi"),
        yellowBgClosePattern = new RegExp("</"+colorBgYellowTag+">", "gmi"),
        greenBgOpenPattern = new RegExp("<"+colorBgGreenTag+">", "gmi"),
        greenBgClosePattern = new RegExp("</"+colorBgGreenTag+">", "gmi"),
        blueBgOpenPattern = new RegExp("<"+colorBgBlueTag+">", "gmi"),
        blueBgClosePattern = new RegExp("</"+colorBgBlueTag+">", "gmi"),
        magentaBgOpenPattern = new RegExp("<"+colorBgMagentaTag+">", "gmi"),
        magentaBgClosePattern = new RegExp("</"+colorBgMagentaTag+">", "gmi"),
        cyanBgOpenPattern = new RegExp("<"+colorBgCyanTag+">", "gmi"),
        cyankBgClosePattern = new RegExp("</"+colorBgCyanTag+">", "gmi"),
        whiteBgOpenPattern = new RegExp("<"+colorBgWhiteTag+">", "gmi"),
        whiteBgClosePattern = new RegExp("</"+colorBgWhiteTag+">", "gmi");

    text = eol.split(text).map((line) => {
        return closeHtmlTags(line);
    }).join("\n");

    text = text.replace(boldOpenPattern, formatTags.bold.open)
            .replace(boldClosePattern, formatTags.bold.close)
            .replace(italicOpenPattern, formatTags.italics.open)
            .replace(italicClosePattern, formatTags.italics.close)
            .replace(underlineOpenPattern, formatTags.underline.open)
            .replace(underlineClosePattern, formatTags.underline.close)
            .replace(blackOpenPattern, formatTags.black.open)
            .replace(blackClosePattern, formatTags.black.close)
            .replace(redOpenPattern, formatTags.red.open)
            .replace(redClosePattern, formatTags.red.close)
            .replace(yellowOpenPattern, formatTags.yellow.open)
            .replace(yellowClosePattern, formatTags.yellow.close)
            .replace(greenOpenPattern, formatTags.green.open)
            .replace(greenClosePattern, formatTags.green.close)
            .replace(blueOpenPattern, formatTags.blue.open)
            .replace(blueClosePattern, formatTags.blue.close)
            .replace(magentaOpenPattern, formatTags.magenta.open)
            .replace(magentaClosePattern, formatTags.magenta.close)
            .replace(cyanOpenPattern, formatTags.cyan.open)
            .replace(cyankClosePattern, formatTags.cyan.close)
            .replace(whiteOpenPattern, formatTags.white.open)
            .replace(whiteClosePattern, formatTags.white.close)
            /* Background Colors */
            .replace(blackBgOpenPattern, formatTags.bgBlack.open)
            .replace(blackBgClosePattern, formatTags.bgBlack.close)
            .replace(redBgOpenPattern, formatTags.bgRed.open)
            .replace(redBgClosePattern, formatTags.bgRed.close)
            .replace(yellowBgOpenPattern, formatTags.bgYellow.open)
            .replace(yellowBgClosePattern, formatTags.bgYellow.close)
            .replace(greenBgOpenPattern, formatTags.bgGreen.open)
            .replace(greenBgClosePattern, formatTags.bgGreen.close)
            .replace(blueBgOpenPattern, formatTags.bgBlue.open)
            .replace(blueBgClosePattern, formatTags.bgBlue.close)
            .replace(magentaBgOpenPattern, formatTags.bgMagenta.open)
            .replace(magentaBgClosePattern, formatTags.bgMagenta.close)
            .replace(cyanBgOpenPattern, formatTags.bgCyan.open)
            .replace(cyankBgClosePattern, formatTags.bgCyan.close)
            .replace(whiteBgOpenPattern, formatTags.bgWhite.open)
            .replace(whiteBgClosePattern, formatTags.bgWhite.close);

    let textLines = text.split("\n").filter(line => {return line});
    textLines.forEach((line,index,lines) => {
        lines[index] = line.trim();
        line = line.trim();
        if (line.match(/<u>/) && (line.match(/<u>/g)  || []).length !== (line.match(/<\/u>/g) || []).length) {
            lines[index] += "</u>";
        }

        if (line.match(/<em>/) && (line.match(/<em>/g)  || []).length !== (line.match(/<\/em>/g) || []).length) {
            lines[index] += "</em>";
        }            

        if (line.match(/<strong>/) && (line.match(/<strong>/g)  || []).length !== (line.match(/<\/strong>/g) || []).length) {
            lines[index] += "</strong>";
        }

        if (line.match(/<\/u>/) && (line.match(/<u>/g)  || []).length !== (line.match(/<\/u>/g) || []).length) {
            lines[index] = "<u>" + lines[index];
        }

        if (line.match(/<\/em>/) && (line.match(/<em>/g)  || []).length !== (line.match(/<\/em>/g) || []).length) {
            lines[index] = "<em>" + lines[index];
        }

        if (line.match(/<\/strong>/) && (line.match(/<strong>/g)  || []).length !== (line.match(/<\/strong>/g) || []).length) {
            lines[index] = "<strong>" + lines[index];
        }        
    });

    let cleanedText = textLines.filter(text => {return text}).join(`</p><p class="${classNames ? classNames.join(" ") : quillClasses.align.center}">`).trim();

    cleanedText = combineSpanStyles(`<p class="${classNames ? classNames.join(" ") : quillClasses.align.center}">${cleanedText}</p>`);
    
    return cleanedText;
}