<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    editorState
} from "@app/store/editorStore.js";
import shiftLineBack from "@app/external/cc-lib/dist/functions/quill/shiftLineBack.js";
import getAvgTimePerWord from "@app/external/cc-lib/dist/functions/events/getAvgTimePerWord.js";

function shiftLineToPreviousEvent() {
    try {
        if (!$eventGroupState[$projectState.selected]) return;
        if ($eventGroupState[$projectState.selected].selected.length === 0) return;
        if ($eventGroupState[$projectState.selected].selected[0] === 0) return;

        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0]];

        let res = shiftLineBack($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1], $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]]);

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1].text = res.event1.text;

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text = res.event2.text;

        if (window.quillEditor){
            quillEditor.clipboard.dangerouslyPasteHTML(
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text
            );
        }

        if ($editorState.updateTimeOnEdit){
            let currentEvent = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]];
            let lastEvent = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1];

            if (currentEvent.start && currentEvent.end && lastEvent.start && lastEvent.end){
                let avgTimePerWord = getAvgTimePerWord(currentEvent);
                let numberOfWordsInLastLineOfLastEvent = quillEditor.getText(0).split("\n").pop().split(" ").length;
                if (avgTimePerWord > 0){
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1].end += avgTimePerWord*numberOfWordsInLastLineOfLastEvent;
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start += avgTimePerWord*numberOfWordsInLastLineOfLastEvent;
                }                                
            }
        }
        
        historyState.insert({
            name: "shift line", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err){
        console.log(err, err.message);
    }
}
</script>
<li class="nav-item" 
    role="none">
    <a class="nav-link"
       href="#!/"
       role="button"
       id="shift-line-back-btn"
       aria-label="Shift Line Up"
       class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === 0}
       aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === 0}
       on:click={shiftLineToPreviousEvent}>
        <i class="bi bi-arrow-bar-up" aria-hidden="true"></i>
        <sub aria-hidden="true"><i class="bi bi-fonts"></i></sub>
    </a>
</li>
