<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
let selectedEventId = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0]+1 : 0;
let totalEvents = $eventGroupState[$projectState.selected].events.length;

function selectEvent() {
    //console.log("Going to "+selectedEventId);
    if (selectedEventId <= $eventGroupState[$projectState.selected].events.length){
        try {
            $eventGroupState[$projectState.selected].selected = [selectedEventId-1];
            let eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, (selectedEventId-1)*230);  
            $eventGroupState[$projectState.selected].selected = [selectedEventId-1];
        } catch (err) {
            console.log(err.message);
            console.log(err);
        }
    }

    modalState.hideModal();
} 

function init(el){
    el.focus()
  }
</script>


<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'goToEvent' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="goToEventTitle"
    aria-modal="true"
    tabindex="-1" 
    id="ProjectIncodeModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="goToEventTitle">Go To Event</h4>
                <button type="button" class="btn-close" aria-label="Close dialog" id="goToEventClose" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault={selectEvent}>
                    <label for="eventIdInput" class="form-label">Event Id</label>
                    <div class="input-group mb-3" role="group" aria-label="Event selection">
                        <input type="number" 
                            id="eventIdInput"
                            class="form-control" 
                            placeholder="EventId" 
                            aria-label="Event number"
                            aria-describedby="eventIdRange" 
                            step="1" 
                            min="1" 
                            max="{totalEvents}" 
                            bind:value={selectedEventId} 
                            on:focus="{(e)=>{e.target.select()}}" 
                            use:init>

                        <span class="input-group-text" id="eventIdRange">/</span>

                        <input type="number" 
                            id="totalEventsInput"
                            class="form-control" 
                            placeholder="Total Events" 
                            aria-label="Total events"
                            disabled 
                            bind:value={totalEvents}>
                    </div>
                    <button class="btn btn-primary float-end" 
                        id="selectEventBtn"
                        type="submit" 
                        aria-label="Select event">Select Event</button>
                </form>
            </div>
        </div>
    </div>
</div>
