<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    timingAndSyncSettingsState
} from '@app/store/timingAndSyncSettingsStore.js';

function updateShortcut(e, name){
    e.preventDefault();
    
    let keyCmd = [];
    if (e.shiftKey) {
        keyCmd.push("Shift");
    }

    if (e.ctrlKey) {
        keyCmd.push("Ctrl");
    }

    if (e.altKey) {
        keyCmd.push("Alt");
    }

    if (e.metaKey) {
        keyCmd.push("Meta(CMD)");
    }

    keyCmd.push(e.code || e.key);
    keyCmd = keyCmd.join("+");

    //Check other shortcuts to see if they have the same key command, and then remove it
    Object.keys($timingAndSyncSettingsState.shortcuts).forEach((key) => {
        if ($timingAndSyncSettingsState.shortcuts[key] === keyCmd){
            $timingAndSyncSettingsState.shortcuts[key] = "";
        }
    });

    $timingAndSyncSettingsState.shortcuts[name] = keyCmd;

    saveSettingsToLocalStorage();
}

function saveSettingsToLocalStorage(){
    localStorage.setItem("cc-timing-and-sync-settings", JSON.stringify($timingAndSyncSettingsState));
}

function saveSettings(){
    saveSettingsToLocalStorage();
    modalState.hideModal();
}
</script>

<div class="modal fade {$modalState === 'timingAndSyncSettings' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TimingAndSyncModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Timing &amp; Sync Settings</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <div class="modal-body">
                    <form>
                        <p class="lead text-warning mb-2">Timing Mode</p>
                        <div class="mb-3">
                            <select class="form-select form-select-sm" bind:value={$timingAndSyncSettingsState.timingMode}>
                                <option value="long" selected="">Long Press</option>
                                <option value="dual">Dual Key</option>
                            </select>
                        </div>
                        <p class="lead text-warning mb-2">Timing Mode Compensation</p>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <input class="form-control form-control-sm" type="number" min="0" step="1" placeholder="Number of Frames" bind:value={$timingAndSyncSettingsState.compensation}>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="startTimeOnlyCheckbox" bind:checked={$timingAndSyncSettingsState.startTimeOnly}>
                                        <label class="form-check-label" for="startTimeOnlyCheckbox">Start Time Only</label></div>
                                </div>
                            </div>
                        </div>                        
                    </form>
                    <p class="lead text-warning mb-2">Shortcut Keys</p>
                    <div class="row">
                        <div class="col">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>Show Event</td>
                                            <td><input class="form-control" type="text" on:keydown={(e) => updateShortcut(e, 'showEvent')}
                                                on:focus={(e) => {
                                            e.target.select();
                                            }}
                                            value={$timingAndSyncSettingsState.shortcuts.showEvent || ""} /></td>
                                        </tr>
                                        <tr>
                                            <td>Hide Event</td>
                                            <td><input class="form-control" type="text" on:keydown={(e) => updateShortcut(e, 'hideEvent')}
                                                on:focus={(e) => {
                                            e.target.select();
                                            }}
                                            value={$timingAndSyncSettingsState.shortcuts.hideEvent || ""} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <p class="text-danger small">We suggest adding a key modifier (such as Ctrl, Shift, or Command) when configuring your keyboard shortcuts.</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" on:click={saveSettings}>Save Settings</button>
                </div>
            </div>
        </div>
    </div>
</div>
