<script>
    import { v4 as uuidv4 } from "uuid";
    import { modalState } from "@app/store/modalStore.js";
    import { environment } from "@app/store/envStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { fade } from "svelte/transition";
    import { tick, onDestroy, onMount } from "svelte";
    import { BarLoader } from "svelte-loading-spinners";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";

    /* CC LIB */
    import _Project from "@app/external/cc-lib/dist/classes/project.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";

    let shareCode = "",
        syncing = false,
        statusMsg = "Pulling latest commit...",
        audioExtractionComplete = false,
        manifestFileExists = false,
        audioExtractionWorker,
        proxyRtWorker,
        ffmpegPath,
        audioWaveformPath,
        videoOutputPath,
        audioOutputPath,
        ffmpegRes;

    onMount(async () =>{
        console.info("DEBUG: Parsing URL params from URL: " + window.location.href);
        let urlString = window.location.href;
        let url = new URL(urlString);
        shareCode = url.searchParams.get("id") || "";
        console.info("DEBUG: Share code from URL: " + shareCode);
        if (shareCode){
            importProject();
        }
    });

    onDestroy(async () => {
        if (audioExtractionWorker) {
            audioExtractionWorker.terminate();
        }
    });

    async function getFileMetadata(filePath, ffmpegPath) {
        try {
            const exec = window.exec;
            return await exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
        } catch (err) {
            return err.message;
        }
    }
    
    function getMediaIncode(ffmpegOutput) {
        let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
        let mediaIncode = matches ? matches[0].replace(";", ":") : false;
    
        console.log("Media incode detected: " + mediaIncode);
        return mediaIncode == "null" ? false : mediaIncode;
    }
    
    function getMediaFrameRate(ffmpegOutput) {
        // console.log("GETTING MEDIA FRAME RATE");
        //console.log("FFMPEG OUTPUT:");
        //console.log(ffmpegOutput);
    
        let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
        let mediaFrameRate = matches ? matches[0] : false;
    
        console.log("Media frame rate detected: " + mediaFrameRate);
        let fr = frameRates.frameRateMapping[mediaFrameRate];
        return fr;
    }

    function getMediaDuration(ffmpegOutput){
       /*  console.log("GETTING MEDIA DURATION");
        console.log("FFMPEG OUTPUT:");
        console.log(ffmpegOutput); */
        let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
        let mediaDuration = matches ? matches[0] : false;

        console.log(mediaDuration);
        if (mediaDuration){
            mediaDuration = tcLib.tcMsToSec(mediaDuration);
            console.log("media duration in seconds: " + mediaDuration);
        }

        return mediaDuration;
    }

    function checkFileExistsSync(filePath) {
        try {
            fsSync.accessSync(filePath);
            return true;
        } catch (error) {
            return false;
        }
    }

    async function importProject() {
        /* rkeMvkZOeDPNZrlyjdEIrJeEs0P2-06e590bf-22ce-4902-9bbf-d0e100dd2208 */
        toast.push("Importing team project... please wait.", {
            classes: ["toast-info"],
        });

        try {
            if (!shareCode) {
                throw new Error("Share code can't be blank");
            }

            syncing = true;
            let commitFileUrl = await firebase
                .functions()
                .httpsCallable("v8ImportTeamProjectV2")(shareCode.trim());
            console.log(commitFileUrl);
            let commitFileRes = await fetch(commitFileUrl.data[0]);
            let commitFileJson = await commitFileRes.json();
            console.log("COMMIT FILE JSON:", commitFileJson);

            statusMsg = "Loading commit...";

            /* Clear and wait for update... */
            $projectState.selected = false;
            await tick();
            $eventGroupState = [];
            await tick();

            $projectState.commit = commitFileJson.id;
            $projectState.ownerId = commitFileJson.ownerId;
            $projectState.type = "team";
            $projectState.teamId = commitFileJson.teamId;
            $projectState.name = commitFileJson.name || "Untitled";
            $projectState.frameRate = parseFloat(commitFileJson.frameRate);
            $projectState.dropFrame = parseFloat(commitFileJson.dropFrame);
            $projectState.username = firebase.auth().currentUser.email;
            $projectState.media = commitFileJson.media;

            commitFileJson.eventGroups.forEach((eventGroup) => {
                if (eventGroup.state !== "deleted") {
                    let teamEvGroup = new _EventGroup({
                        ...eventGroup,
                        events: [],
                    });

                    teamEvGroup.id = eventGroup.id;

                    eventGroup.events.forEach((event) => {
                        if (event.state !== "deleted") {
                            let teamEv = new _Event(event);
                            teamEv.id = event.id;
                            teamEvGroup.events.push(teamEv);
                        }
                    });

                    $eventGroupState = [...$eventGroupState, teamEvGroup];
                }
            });

            if ($eventGroupState.length > 0) {
                $projectState.selected = 0;
            }

            historyState.reset();
            historyState.insert({
                name: "import project", //action name
                eventGroup: false,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            statusMsg = "Done";
            toast.push("Importing team project complete", {
                classes: ["toast-success"],
            });

            if (
                ((commitFileJson.media.storage === "Local Storage" ||
                    commitFileJson.media.storage === "Proxy RT") &&
                    !$environment.electron) ||
                commitFileJson.media.localPath === ""
            ) {
                modalState.showModal("mediaImport");
            } else {
                importMedia();
            }
        } catch (err) {
            console.log(err, err.message);
            toast.push("Failed to import team project. " + err.message, {
                classes: ["toast-danger"],
            });
            modalState.hideModal();
        }
    }

    async function importMedia() {
        try {
            playerState.updateDuration(false);
            $uiState.timeline = false;
            let mediaType = "video/mp4"; 
            switch ($projectState.media.storage) {
                case "Vimeo":
                    player.src = $projectState.media.path;
                    manifestFileExists = true;
                    audioExtractionComplete = true;
                    $projectState.media.useFallback = true;
                    break;
                case "YouTube":
                    player.src = $projectState.media.path;
                    manifestFileExists = true;
                    audioExtractionComplete = true;
                    
                    $projectState.media.useFallback = true;
                    break;
                case "HLS Manifest":
                    audioExtractionComplete = true;
                    player.src = $projectState.media.path;
                    $projectState.media.useFallback = true;
                    break;
                case "Proxy RT":
                    if (!checkFileExistsSync($projectState.media.localPath)){
                        toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                        modalState.showModal('mediaImport');
                        return;
                    }

                    ffmpegPath = require("ffmpeg-static-electron").path;
                    videoOutputPath = os.tmpdir() + path.sep + uid + ".m3u8";
                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    $projectState.media.path = videoOutputPath;

                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };

                    audioExtractionWorker = new Worker(
                        "./build/workers/audioExtraction.js",
                    );

                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                        duration: $projectState.media.info.duration,
                    });

                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            console.log(msg.data.result);
                        } else {
                            $projectState.media.peaksPath = msg.data.error
                                ? ""
                                : audioOutputPath;
                            $projectState.media.useFallback = false;

                            toast.push(
                                `${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`,
                                {
                                    classes: [
                                        'toast-`${msg.data.error ? "danger" : "success"}`,',
                                    ],
                                },
                            );

                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };

                    proxyRtWorker = new Worker("./build/workers/proxyRt.js");
                    proxyRtWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: videoOutputPath,
                        ffmpegPath: ffmpegPath,
                        tmpDir: os.tmpdir(),
                        pathSep: path.sep,
                    });

                    proxyRtWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status_msg) {
                            manifestFileExists = true;
                            player.src = $projectState.media.path;
                            closeModal();
                        } else if (msg.data.error) {
                            manifestFileExists = true;
                            toast.push(`${msg.data.error}`, {
                                classes: ["toast-danger"],
                            });
                            closeModal();
                        }
                    };

                    break;
                case "Cloud Storage":
                    /* Cloud Storage*/          
                    if ($projectState.media.type === "video/quicktime") {
                        mediaType = "video/mp4";
                    } else if ($projectState.media.type === "video/x-matroska") {
                        mediaType = "video/mkv";
                    } else {
                        mediaType = $projectState.media.type;
                    }

                    //Get file size of url
                    let fileSize = await getFileSize($projectState.media.path);
                    if (fileSize) {
                        const sizeInBytes = parseInt(fileSize);
                        const sizeInKilobytes = sizeInBytes / 1024;
                        const sizeInMegabytes = sizeInKilobytes / 1024;
                        if (sizeInMegabytes < 512) {
                            $projectState.media.useFallback = false;
                        }
                    }

                    player.src = {src: $projectState.media.path, type: mediaType};

                    /* Update Flags */
                    manifestFileExists = true;
                    audioExtractionComplete = true;

                    break;
                default:
                    /* Local Storage */
                    if (!checkFileExistsSync($projectState.media.localPath)){
                        toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                        modalState.showModal('mediaImport');
                        return;
                    }
                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    ffmpegPath = require("ffmpeg-static-electron").path;
                    audioWaveformPath = replaceFilename(
                        ffmpegPath,
                        $environment.windows
                            ? "audiowaveform.exe"
                            : "audiowaveform",
                    );

                    manifestFileExists = true;
                    mediaType = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type;
                    player.src = { src: $projectState.media.localPath, type: mediaType};  

                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };

                    audioExtractionWorker = new Worker(
                        "./build/workers/audioExtraction.js",
                    );

                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                        duration: $projectState.media.info.duration,
                    });

                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            //statusMsg = msg.data.result;
                        } else {
                            $projectState.media.peaksPath = msg.data.error
                                ? ""
                                : audioOutputPath;
                            $projectState.media.useFallback = false;

                            player.source = {
                                type: "video",
                                sources: [source],
                            };

                            toast.push(
                                `${msg.data.error ? msg.data.error : "Media import completed successfully."}`,
                                {
                                    classes: [
                                        `toast-${msg.data.error ? "danger" : "success"}`,
                                    ],
                                },
                            );

                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };
            }

            closeModal();
        } catch (err) {
            toast.push("Error - Media import failed", {
                classes: ["toast-danger"],
            });
            importing = false;
        }
    }

    function closeModal() {
        console.log(
            "Close Modal Called",
            audioExtractionComplete,
            manifestFileExists,
        );
        if (audioExtractionComplete && manifestFileExists) {
            statusMsg = "Project import complete...";

            setTimeout(() => {
                $uiState.timeline = true;
                modalState.hideModal();
            }, 2500);
        }
    }

    async function getFileSize() {
        try {
            const response = await fetch($projectState.media.path, {
                method: "HEAD",
            });
            const fileSize = response.headers.get("content-length");
            return fileSize;
        } catch (err){
            return false;
        }    
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'teamProjectImport' ? 'show d-block' : ''}"
    role="dialog"
    aria-labelledby="teamProjectImportTitle"
    aria-modal="true"
    tabindex="-1"
    id="TeamProjectImport">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="teamProjectImportTitle">Import Team Project</h4>
                <button type="button" class="btn-close" aria-label="Close dialog" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label class="form-label" for="shareCodeInput">Project Share Code</label>
                        <input
                            type="text"
                            class="form-control"
                            id="shareCodeInput"
                            bind:value={shareCode}
                            aria-describedby="shareCodeHelp"
                        />
                        <small id="shareCodeHelp" class="form-text text-muted">e.g. 32a9599f-9670-47a1-8746-1cd0591f3262</small>
                    </div>
                    {#if syncing}
                        <div aria-live="polite" role="status">
                            <BarLoader size="160" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                            <p class="mt-1 small">{statusMsg}</p>
                        </div>
                    {/if}
                    <button
                        type="button"
                        class="btn btn-primary float-end"
                        id="importProjectBtn"
                        on:click={importProject}
                        disabled={!shareCode || syncing}
                        aria-label="Start project import">Import Project</button>
                </form>
            </div>
        </div>
    </div>
</div>
