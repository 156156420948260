<script>
    /* hello world */
    import {
        cliConfigState
    } from '@app/store/cliConfigStore.js';
    import { toast } from '@zerodevx/svelte-toast';
    const ipcRenderer = window.ipcRenderer;
    let cliType = 0;
    function saveSettings() {
        console.log($cliConfigState);
        $cliConfigState = $cliConfigState;
        localStorage.setItem("cc-cli-configs", JSON.stringify($cliConfigState));
        toast.push("Settings have been saved successfully.", {classes: ['toast-success']});
    }

function selectFileOption(cliOption){
    // console.log(cliOption);
    ipcRenderer.invoke('selectFile', {
        title: `Select ${cliOption.name} location`,
        properties: ["showHiddenFiles","treatPackageAsDirectory","dontAddToRecent","openFile"]
    }).then((path) => {
        if (!path.canceled) {
            cliOption.selected = path.filePaths[0];
            $cliConfigState = $cliConfigState;
        }
    });
}

</script>
    
    <form>
        <div class="mb-3">
            <label class="form-label" for="cliTypeSelect">Developer</label>
            <select class="form-select" id="cliTypeSelect" bind:value="{cliType}">
                {#each $cliConfigState as cliDeveloper, i}
                    <option value="{i}">{cliDeveloper.name}</option>
                {/each}
            </select>
        </div>
    </form>
    <form on:submit|preventDefault="{saveSettings}">
        {#each $cliConfigState[cliType].options as cliOption, optionIndex}
            {#if cliOption.type === "file-input"}
            <div class="mb-3">
                <p class="mb-2">{cliOption.name}</p>
                <button type="button" class="btn btn-light btn-sm" on:click="{() => {selectFileOption(cliOption)}}">Choose File</button>
                <span class="small">{cliOption.selected}</span>
            </div>
            {:else if cliOption.type === "list-input"}
            <div class="mb-3">
                <label class="form-label" for="List Options">{cliOption.name}</label>
                <select class="form-select" bind:value="{cliOption.selected}">
                    {#each cliOption.values as optionValue}
                        <option>{optionValue}</option>
                    {/each}
                </select>
            </div>
            {/if}
        {/each}
        <button class="btn btn-primary" type="button" on:click="{saveSettings}">Save Settings</button>
    </form>
    <hr/>
    <div class="table-responsive">
        <table class="table table-striped table-light table-sm">
            <thead class="table-light">
                <tr>
                    <th scope="col">Developer</th>
                    <th scope="col">Option</th>
                    <th scope="col">Value</th>
                </tr>
            </thead>
            <tbody>
                {#each $cliConfigState as cliDeveloper, i}
                    {#each cliDeveloper.options as cliOption}
                        <tr>
                            <td>{cliDeveloper.name}</td>
                            <td>{cliOption.name}</td>
                            <td>{cliOption.selected}</td>
                        </tr>
                    {/each}
                {/each}
                
            </tbody>
        </table>
    </div>
    