<script>
	import { modalState } from "@app/store/modalStore.js";
	import { styleState } from "@app/store/styleStore.js";
	import { historyState } from "@app/store/historyStore.js";
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import { projectState } from "@app/store/projectStore.js";
    import {speakerState} from "@app/store/speakerStore.js";
	import { toast } from '@zerodevx/svelte-toast';
	import { fade } from "svelte/transition";

	/* CC LIB */
	import decode from "@app/external/cc-lib/dist/functions/decode.js";
	import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
	import encodings from "@app/external/cc-lib/dist/dict/encodings.js";
	import formats from "@app/external/cc-lib/dist/lib/formats.js";
	import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
	import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
	import supportedLanguages from "@app/external/cc-lib/dist/dict/supportedLanguages.js";
	import dropFrameCv from "@app/external/cc-lib/dist/functions/special/dropFrameConvert.js";
	import bufferToHex from "@app/external/cc-lib/dist/functions/utility/bufferToHex.js";
	import fixOverlap from "@app/external/cc-lib/dist/functions/eventGroups/fixOverlap.js";
	import checkForOverlaps from "@app/external/cc-lib/dist/functions/eventGroups/checkForOverlaps.js";
	import automaticOs from "@app/external/cc-lib/dist/functions/special/automaticOffset.js";
	import getAutomaticOffsetAmount from "@app/external/cc-lib/dist/functions/special/getAutomaticOffsetAmount.js";
    import stripTags from "@app/external/cc-lib/dist/functions/quill/stripTags.js";
    import encodeHtml from "@app/external/cc-lib/dist/functions/eventGroups/encodeHtml.js";

	import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
	import Swal from "sweetalert2";

	let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
	let subtitleImportDefaults = JSON.parse(localStorage.getItem("cc-subtitle-import-defaults")) || {};

	/* Default Settings */
	let srcFileName,
		srcExt,
		srcProfile,
		srcFrameRate = $projectState.frameRate,
		srcDropFrame = $projectState.dropFrame ? "true" : "false",
		dropFrameConvert = false,
		showOptions = false,
		files,
		fileEncoding = defaults.encoding,
		fileContents = "",
		allowedExtensions = "." + formats.srcExt.join(", ."),
		eventGroup = "new",
		importOption = subtitleImportDefaults.importOption || "merge",
		language = eventGroupDefaults.language || "en-US",
		rtl = eventGroupDefaults.rtl,
		languages = supportedLanguages.languages;

	function updateFile() {
		if (files[0] && files[0].name) {
			let fileName = files[0].name.split(".");
			srcExt = fileName.pop().toLowerCase();
			srcFileName = fileName.shift();
			srcProfile = formats.srcProfiles[srcExt][0];
			updateFileContents();
		}
	}

	function updateFileContents() {
		if (files && files[0]) {
			toast.push("Reading file contents...", {classes: ['toast-warning']});
			let reader = new FileReader();
			reader.onload = async function (e) {
				if (srcExt.toLowerCase() === "docx") {
					let rawText = await mammoth.extractRawText({ arrayBuffer: e.target.result });
					fileContents = rawText.value;
				} else if (srcExt.toLowerCase() === "xlsx") {
					const workbook = new ExcelJS.Workbook();
					await workbook.xlsx.load(e.target.result);
					fileContents = JSON.stringify(workbook.model, null, 4);
				} else if (formats.binaryFormats.includes(formats.profileMapping[srcProfile].name)) {
					fileContents = bufferToHex(e.target.result);
				} else {
					fileContents = e.target.result;
				}
			};

			formats.binaryFormats.includes(formats.profileMapping[srcProfile].name) || srcExt === "docx" || srcExt === "xlsx" ? reader.readAsArrayBuffer(files[0]) : reader.readAsText(files[0], fileEncoding);
		}
	}

	async function importSubtitleFile() {
		toast.push("Importing subtitle events...", {classes: ['toast-warning']});

		try {
			/* Create or select the event group */
			if (eventGroup === "new") {
				let eventGroupOptions = {
					type: "subtitle",
					name: srcFileName,
					language: language,
					rtl: rtl,
				};
				$eventGroupState = [...$eventGroupState, new _EventGroup(eventGroupOptions)];
				$projectState.selected = $eventGroupState.length - 1;
			} else {
				$projectState.selected = $eventGroupState.findIndex((group) => {
					return group.id === eventGroup;
				});
			}

			srcDropFrame = srcDropFrame && srcDropFrame !== "false" ? true : false;

			/* Translate for Creator */
			let playerWidth = document.getElementById("PlayerWrapper").clientWidth;
			let playerHeight = document.getElementById("PlayerWrapper").clientHeight;
			let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
            let yPadding = $styleState.mode ? 10 : $styleState.yPadding;

			let options = new defaults.options({
				profile: formats.profileMapping[srcProfile].name,
				formatOptions: formats.profileMapping[srcProfile].options.decode,
				frameRate: srcFrameRate,
				dropFrame: srcDropFrame,
				target_profile: "closedCaptionProject",
				source_profile: formats.profileMapping[srcProfile].name,
				incode: tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame),
				window: {
					width: playerWidth,
					height: playerHeight,
					xOffset: (xPadding / 100) * playerWidth,
					yOffset: (yPadding / 100) * playerHeight,
				},
			});

			//console.log("Options:", JSON.stringify(options, null, 4));
			let decodedEvGroup = await decode(fileContents, options);
            decodedEvGroup = encodeHtml(decodedEvGroup);
			let offsetAmount = getAutomaticOffsetAmount(decodedEvGroup, $projectState.frameRate, tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame));

			if (offsetAmount != 0) {
				try {
					if ($projectState.offset > 0){
						decodedEvGroup = automaticOs(decodedEvGroup, $projectState.frameRate);
					} else {
						let offsetRes = await Swal.fire({
							title: "Automatic Offset",
							text: `The subtitle file you are importing has an offset of ${tcLib.secToTc(offsetAmount, $projectState.frameRate, $projectState.dropFrame)}. Would you like to apply an automatic offset and update the project incode to match?`,
							icon: "question",
							showCancelButton: true,
							confirmButtonText: "Yes",
							cancelButtonText: "No",
							allowOutsideClick: false,
							allowEscapeKey: false,
							buttonsStyling: false,
							customClass: {
								confirmButton: "btn btn-primary me-2",
								cancelButton: "btn btn-light",
							},
						});

						if (offsetRes.isConfirmed) {
							$projectState.incode = offsetAmount;
							decodedEvGroup = automaticOs(decodedEvGroup, $projectState.frameRate);
						}
					}
					
				} catch (err) {
					console.log(err, err.message);
				}
			}

			if (decodedEvGroup.events.every(ev => ev.style === "Pop-On")) {
				decodedEvGroup.events = decodedEvGroup.events.filter((event) => {
					return stripTags(event.text) || event.notes || event.reply;
				});
                
				//Check for overlaps in the new event group. If overlaps are found, ask the user if they want them fixed using SweetAlert2
				if (checkForOverlaps(decodedEvGroup)){
					let overlapRes = await Swal.fire({
						title: "Overlapping Events",
						text: "The subtitle file you are importing has overlapping events. Would you like to fix them?",
						icon: "question",
						showCancelButton: true,
						confirmButtonText: "Yes",
						cancelButtonText: "No",
						allowOutsideClick: false,
						allowEscapeKey: false,
						buttonsStyling: false,
						customClass: {
							confirmButton: "btn btn-primary me-2",
							cancelButton: "btn btn-light",
						},
					});

					if (overlapRes.isConfirmed) {
						decodedEvGroup = fixOverlap(decodedEvGroup);
					}
				}
			}

            //Import Speakers from events
            decodedEvGroup.events.forEach(event =>{
                event.speakers.forEach(speaker =>{
                    //Check if the speaker already exists in $speakerState, otherwise add it.
                    if(!$speakerState.find(s => s.name === speaker.name)){
                        $speakerState = [...$speakerState, speaker];
                    }
                });
            });

			if (dropFrameConvert) {
				decodedEvGroup = dropFrameCv(decodedEvGroup, {
					frameRate: $projectState.frameRate,
					dropFrame: $projectState.dropFrame,
				});
			}

			if (eventGroup === "new" || importOption === "replace") {
				$eventGroupState[$projectState.selected].events = decodedEvGroup.events;
			} else {
				$eventGroupState[$projectState.selected].events = [...$eventGroupState[$projectState.selected].events, ...decodedEvGroup.events];
			}

			historyState.insert({
				name: "import subtitles", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});

			localStorage.setItem(
				"cc-subtitle-import-defaults",
				JSON.stringify({
					showOptions: showOptions,
					importOption: importOption,
				})
			);

			modalState.hideModal();
		} catch (err) {
			console.log(err, err.message);
			toast.push("Importing subtitle events failed: " + err.message, {classes: ['toast-danger']});
		}
	}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'subtitleImport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="SubtitleImportModal">
	<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Subtitle Import</h4>
				<button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
			</div>
			<div class="modal-body">
				<p class="small text-muted"><i class="bi bi-lightning-fill"></i> Powered by Closed Caption Converter | www.closedcaptionconverter.com</p>

				<div class="row">
					<div class="col">
						<!-- Import Form Start -->
						<form on:submit|preventDefault={importSubtitleFile}>
							<!-- File Input -->
							<div class="mb-3 shadow bg-secondary rounded p-3">
								<label class="form-label" for="subtitleFilePicker">Select Subtitle File <i class="bi bi-info-circle" title="Supported formats: {allowedExtensions}"></i></label>
								<input type="file" class="form-control" id="subtitleFilePicker" bind:files accept={allowedExtensions} on:change={updateFile} />
							</div>
							<div class="row">
								<!-- Target Event Group Option Start -->
								<div class="mb-3 col-6">
									<label class="form-label text-warning" for="EventGroupSelection">Event Group</label>
									<select class="form-select" bind:value={eventGroup}>
										{#each $eventGroupState as eventGroupOption}
											<option value={eventGroupOption.id}>{eventGroupOption.name}</option>
										{/each}
										<option class="fw-bold" value="new">*NEW*</option>
									</select>
								</div>
								<!-- Target Event Group Option End -->
								<!-- Merge or Replace Option Start -->
								{#if eventGroup !== "new"}
									<div class="mb-3 col-6">
										<p class="text-warning">Merge/Replace Events <i class="bi bi-info-circle" title="Merge - Add events to existing group without deleting any other events. Replace - Removes all events in existing group prior to import"></i></p>

										<div class="form-check">
											<input class="form-check-input" type="radio" bind:group={importOption} name="importOption" id="mergeImportOption" value="merge" checked />
											<label class="form-check-label" for="mergeImportOption"> Merge </label>
										</div>
										<div class="form-check">
											<input class="form-check-input" type="radio" bind:group={importOption} name="importOption" id="replaceImportOption" value="replace" />
											<label class="form-check-label" for="replaceImportOption"> Replace </label>
										</div>
									</div>
								{:else}
									<!-- Language -->
									<div class="mb-3 col-12">
										<label class="form-label text-warning" for="Language">Group Language</label>
										<select class="form-select" bind:value={language}>
											{#each languages as languageOption, index (index)}
												<option>{languageOption}</option>
											{/each}
										</select>
									</div>
									<div class="mb-3 col-12">
										<div class="form-check form-switch mb-2">
											<input type="checkbox" class="form-check-input" id="rtlSwitch" bind:checked={rtl} />
											<label class="form-check-label" for="rtlSwitch">Right-To-Left</label>
										</div>
									</div>
								{/if}
								<!-- Merge or Replace Option End -->
							</div>
							<!-- Source Profile List -->
							{#if formats.srcProfiles[srcExt] && formats.srcProfiles[srcExt].length > 1}
								<div class="mb-3">
									<label class="form-label" for="srcProfileSelectList">Source Profile <i class="bi bi-asterisk text-danger"></i></label>
									<select id="srcProfileSelectList" class="form-select" bind:value={srcProfile} on:change={updateFileContents}>
										{#each formats.srcProfiles[srcExt] as profile}
											<option>{profile}</option>
										{/each}
									</select>
								</div>
							{/if}
							<button
								type="button"
								class="btn btn-outline-warning mb-2 dropdown-toggle"
								on:click={() => {
									showOptions = !showOptions;
								}}>More Options</button>
							{#if showOptions}
								<div id="advancedOptions" class="p-3 bg-secondary shadow rounded">
									<div class="row pt-2 mb-2">
										<h5 class="col-12 lead text-warning">General Options</h5>
									</div>
									{#if formats.profileMapping[srcProfile] && formats.profileMapping[srcProfile].timecode !== "ms"}
										<div class="row mb-2">
											<div class="col-8">
												<label class="form-label" for="SrcFrameRateSelect">Source Framerate</label>
												<select id="SrcFrameRateSelect" class="form-select" bind:value={srcFrameRate}>
													{#each frameRates.frameRates as frameRate}
														<option>{frameRate}</option>
													{/each}
												</select>
											</div>
											{#if parseFloat(srcFrameRate) === 29.97 || parseFloat(srcFrameRate) === 59.94}
												<div class="col-4">
													<label class="form-label" for="SrcDropFrameSelect">Dropframe</label>
													<select id="SrcDropFrameSelect" class="form-select" bind:value={srcDropFrame}>
														<option>true</option>
														<option>false</option>
													</select>
												</div>
											{/if}
										</div>
									{/if}
									<div class="mb-3 mt-2">
										<label class="form-label" for="srcFileEncoding">File Encoding</label>
										<select id="srcFileEncoding" class="form-select" bind:value={fileEncoding} on:change={updateFileContents}>
											{#each encodings as encodingOption}
												<option>{encodingOption}</option>
											{/each}
										</select>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" id="dropFrameConvert" bind:checked={dropFrameConvert} />
										<label class="form-check-label" for="dropFrameConvert">
											Drop Frame Convert
											<i class="bi bi-info-circle" title="This option is valid when converting from a format that uses SMPTE timecode (e.g. SCC, MCC, etc.) to an offset timecode format (e.g. SRT, WebVTT, etc.)"></i>
										</label>
									</div>
									{#if srcProfile && formats.profileMapping[srcProfile].options && formats.profileMapping[srcProfile].options.decode.length > 0}
										<div class="row pt-4 mt-4 mb-3 border-top border-secondary">
											<h5 class="col-12 lead text-warning">Decoding Options</h5>
											{#each formats.profileMapping[srcProfile].options.decode as decodeOption}
												<div class="mb-3 col-6">
													<label class="form-label" for="decodingOption">{decodeOption.name}</label>
													{#if decodeOption.type === "textarea"}
														<textarea class="form-control" rows="3" bind:value={decodeOption.selected}></textarea>
													{:else if decodeOption.type === "text-input"}
														<input class="form-control" type="text" bind:value={decodeOption.selected} />
													{:else if decodeOption.type === "number-input"}
														<input class="form-control" type="number" bind:value={decodeOption.selected} />
													{:else}
														<select class="form-select" bind:value={decodeOption.selected}>
															{#each decodeOption.values as valueOption}
																<option>{valueOption}</option>
															{/each}
														</select>
													{/if}
												</div>
											{/each}
										</div>
									{/if}
								</div>
								<!-- End of Advanced Settings -->
							{/if}
						</form>
						<!-- Import Form End -->
					</div>
					<div class="col">
						<!-- File Preview -->
						<form id="SourcePreviewForm">
							<div class="mb-3">
								<label class="form-label" for="srcPreviewText">Source Preview</label>
								<textarea bind:value={fileContents} disabled class="form-control" id="srcPreviewText" rows="10" placeholder="Please select a file from the menu on the left."></textarea>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-primary" type="button" on:click={importSubtitleFile} disabled={fileContents && fileContents.length === 0}>Import Subtitles </button>
			</div>
		</div>
	</div>
</div>

<style>
	#advancedOptions {
		overflow-y: auto;
		overflow-x: none;
		max-height: 50vh;
	}
</style>
