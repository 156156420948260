export default (function convertRegionsToPositionMap(styles) {
  let styleMap = {};
  styles.forEach(style => {
    try {
      let styleId = style['@_xml:id'];
      if (!styleId) {
        return;
      }
      styleMap[styleId] = {
        ...style
      };
    } catch (err) {
      //console.log(err);
    }
  });
  return styleMap;
});