<script>
    /* States */
    import { explorerState } from '@app/store/explorerStore.js';
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { authState } from "@app/store/authStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { Circle } from "svelte-loading-spinners";
    import { toast } from "@zerodevx/svelte-toast";
    import Swal from "sweetalert2";
    import workflowDict from "@app/external/cc-lib/dict/workflow.js";
    
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    import "firebase/functions";

    const STATES = workflowDict.states;
    let loading = false;
    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    let storageRef = storage.ref();
    let homeStorageBasePath = `users/${userId}/projects/`;
    let teamStorageBasePath = teamId ? "teams/" + teamId + "/storage/" : null;
    
    // Icon mapping for different record types
    const iconMap = {
        "project": "bi-file-earmark-play",
        "team project": "bi-people",
        "work order": "bi-clipboard-check"
    };

    // Filter records to only include project types and organize by state
    $: filteredRecords = $explorerState.records.filter(record => 
        (record.type === "project" || record.type === "team project" || record.type === "work order")
        && record.state !== "Archived"
    );
    
    // Create a map of state to records
    $: recordsByState = STATES.reduce((acc, state) => {
        acc[state] = filteredRecords.filter(record => record.state === state);
        return acc;
    }, {});

    // Also create a group for records with no state
    $: recordsWithNoState = filteredRecords.filter(record => !record.state);

    // Track the current drag operation
    let draggedRecord = null;
    let sourceState = null;
    let sourceIndex = null;
    let dragOverColumn = null;
    let dragOverIndex = null;

    // Handler for starting a drag operation
    function handleDragStart(event, record, state, index) {
        // Don't allow drag in save mode
        if ($explorerState.context === "save") {
            event.preventDefault();
            return;
        }
        
        // Don't allow dragging locked records (unless owned by current user)
        if (record.locked && record.lockedBy !== userEmail) {
            event.preventDefault();
            toast.push(`Cannot move "${record.name}" as it is locked by ${record.lockedBy}`, {
                classes: ["toast-warning"],
            });
            return;
        }
        
        draggedRecord = record;
        sourceState = state;
        sourceIndex = index;
        
        // Set data for the drag operation
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("application/json", JSON.stringify({
            recordId: record.id,
            sourceState: state,
            sourceIndex: index
        }));
        
        // Add dragging class for styling
        event.target.classList.add("dragging");
    }

    // Handler for dragging over a column
    function handleColumnDragOver(event, state) {
        if (!draggedRecord) return;
        
        event.preventDefault();
        dragOverColumn = state;
        event.currentTarget.classList.add("drag-over");
    }
    
    // Handler for dragging over a card
    function handleCardDragOver(event, state, index) {
        if (!draggedRecord) return;
        
        event.preventDefault();
        dragOverColumn = state;
        dragOverIndex = index;
        
        const rect = event.currentTarget.getBoundingClientRect();
        const middle = rect.top + rect.height / 2;
        
        // Determine if the drag is over the top or bottom half of the card
        if (event.clientY < middle) {
            event.currentTarget.classList.add("drag-above");
            event.currentTarget.classList.remove("drag-below");
        } else {
            event.currentTarget.classList.add("drag-below");
            event.currentTarget.classList.remove("drag-above");
        }
    }
    
    // Handler for leaving a drop target
    function handleDragLeave(event) {
        event.currentTarget.classList.remove("drag-over");
        event.currentTarget.classList.remove("drag-above");
        event.currentTarget.classList.remove("drag-below");
    }
    
    // Handler for ending a drag operation
    function handleDragEnd(event) {
        event.target.classList.remove("dragging");
        draggedRecord = null;
        sourceState = null;
        sourceIndex = null;
        dragOverColumn = null;
        dragOverIndex = null;
        
        // Remove drag-over classes from all elements
        document.querySelectorAll(".drag-over, .drag-above, .drag-below").forEach(el => {
            el.classList.remove("drag-over", "drag-above", "drag-below");
        });
    }
    
    // Handler for dropping on a column (to change state)
    async function handleColumnDrop(event, targetState) {
        event.preventDefault();
        event.currentTarget.classList.remove("drag-over");
        
        if (!draggedRecord) return;
        
        try {
            // If target state is the same as source state, do nothing
            if (targetState === sourceState) return;
            
            // Update the record's state in the database and locally
            await updateRecordState(draggedRecord, targetState);
            
            // No need to call getRecords() as we've updated locally
            
        } catch (error) {
            console.error("Error handling column drop:", error);
            toast.push(`Failed to update state: ${error.message}`, {
                classes: ["toast-danger"],
            });
        }
    }
    
    // Handler for dropping on a card (to reorder or change state)
    async function handleCardDrop(event, targetState, targetIndex) {
        event.preventDefault();
        event.currentTarget.classList.remove("drag-above");
        event.currentTarget.classList.remove("drag-below");
        
        if (!draggedRecord) return;
        
        try {
            // Determine if we're changing state or just reordering
            if (targetState !== sourceState) {
                // Update record state in database and locally
                await updateRecordState(draggedRecord, targetState);
            } else if (targetIndex !== sourceIndex) {
                // Reordering within the same state
                // This is visual only as we don't persist order in the database
                
                // Create a copy of the records for the state
                const stateRecords = [...recordsByState[targetState]];
                
                // Remove the record from its original position
                const [record] = stateRecords.splice(sourceIndex, 1);
                
                // Insert it at the new position
                stateRecords.splice(
                    targetIndex > sourceIndex ? targetIndex - 1 : targetIndex, 
                    0, 
                    record
                );
                
                // Update the recordsByState object
                recordsByState[targetState] = stateRecords;
            }
            
            // No need to call getRecords() as we've updated locally
            
        } catch (error) {
            console.error("Error handling card drop:", error);
            toast.push(`Failed to update record: ${error.message}`, {
                classes: ["toast-danger"],
            });
        }
    }

    // Update a record's state in the database
    async function updateRecordState(record, newState) {        
        try {                      
            // Determine which database reference to use
            const dbRef = record.rootDir === "team" ? teamDbRef : homeDbRef;
            if (!dbRef) {
                throw new Error("Database reference not available");
            }
            
            // Update the state and modification timestamp in database
            await dbRef.doc(record.id).update({
                state: newState,
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
            });
            
            // Update the record locally in explorerState
            const currentTime = firebase.firestore.Timestamp.fromDate(new Date());
            
            // Find and update the record in the local array
            $explorerState.records = $explorerState.records.map(item => {
                if (item.id === record.id && item.rootDir === record.rootDir) {
                    return {
                        ...item,
                        state: newState,
                        updatedOn: currentTime
                    };
                }
                return item;
            });
            
            // Show success message
            toast.push(`Updated "${record.name}" state to "${newState}"`, {
                classes: ["toast-success"],
            });
            
        } catch (error) {
            console.error("Error updating record state:", error);
            throw error;
        } finally {
            loading = false;
        }
    }

    // Fetch records based on root directory and filters
    async function getRecords() {
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];

            // console.log("Refreshing Kanban view records");
            
            // Handle special rootDir values
            if ($explorerState.rootDir === "recent") {
                // Fetch recent records from both personal and team storage
                let recentRecords = [];
                
                // Personal storage
                let personalSnapshot = await homeDbRef
                    .orderBy("updatedOn", "desc")
                    .limit(100)
                    .get();
                    
                personalSnapshot.forEach((doc) => {
                    // Create a unique composite ID using original ID and source
                    const record = doc.data();
                    recentRecords.push({
                        ...record, 
                        rootDir: "personal",
                        uniqueId: `personal-${record.id}`
                    });
                });
                
                // Team storage (if available)
                if (teamDbRef) {
                    let teamSnapshot = await teamDbRef
                        .orderBy("updatedOn", "desc")
                        .limit(100)
                        .get();
                        
                    teamSnapshot.forEach((doc) => {
                        // Create a unique composite ID using original ID and source
                        const record = doc.data();
                        recentRecords.push({
                            ...record, 
                            rootDir: "team",
                            uniqueId: `team-${record.id}`
                        });
                    });
                }
                
                // Filter to only include projects and work orders
                recentRecords = recentRecords.filter(record => 
                    record.type === "project" || record.type === "team project" || record.type === "work order"
                );
                
                // Sort by updatedOn timestamp
                recentRecords.sort((a, b) => {
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                $explorerState.records = recentRecords.slice(0, 100);
                
            } else if ($explorerState.rootDir === "starred") {
                // Fetch starred records from both personal and team storage
                let starredRecords = [];
                
                // Personal storage
                let personalSnapshot = await homeDbRef
                    .where("star", "==", true)
                    .limit(100)
                    .get();
                    
                personalSnapshot.forEach((doc) => {
                    // Create a unique composite ID using original ID and source
                    const record = doc.data();
                    starredRecords.push({
                        ...record, 
                        rootDir: "personal",
                        uniqueId: `personal-${record.id}`
                    });
                });
                
                // Team storage (if available)
                if (teamDbRef) {
                    let teamSnapshot = await teamDbRef
                        .where("star", "==", true)
                        .limit(100)
                        .get();
                        
                    teamSnapshot.forEach((doc) => {
                        // Create a unique composite ID using original ID and source
                        const record = doc.data();
                        starredRecords.push({
                            ...record, 
                            rootDir: "team", 
                            uniqueId: `team-${record.id}`
                        });
                    });
                }
                
                // Filter to only include projects and work orders
                starredRecords = starredRecords.filter(record => 
                    record.type === "project" || record.type === "team project" || record.type === "work order"
                );
                
                // Sort by updatedOn timestamp
                starredRecords.sort((a, b) => {
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                $explorerState.records = starredRecords.slice(0, 100);
                
            } else {
                // For personal or team storage, use locationId to respect folder structure
                // Get locationId from the last element in navHistory, or use null if at root
                const currentFolderId = $explorerState.navHistory.length > 0 
                    ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id 
                    : null;
                    
                console.log(`Getting Kanban records for folder ID: ${currentFolderId}`);
                
                // Only get project types (not folders) for Kanban view
                let projectTypes = ["project", "team project", "work order"];
                let queries = [];
                
                if ($explorerState.rootDir === "personal") {
                    // For personal storage, get project types with matching locationId
                    queries = projectTypes.map(type => 
                        homeDbRef
                            .where("type", "==", type)
                            .where("locationId", "==", currentFolderId)
                            .orderBy("name", "asc")
                            .get()
                    );
                } else if (teamDbRef) {
                    // For team storage, get project types with matching locationId
                    queries = projectTypes.map(type => 
                        teamDbRef
                            .where("type", "==", type)
                            .where("locationId", "==", currentFolderId)
                            .orderBy("name", "asc")
                            .get()
                    );
                }
                
                const queryResults = await Promise.all(queries);
                
                // Combine results
                const records = [];
                queryResults.forEach(snapshot => {
                    snapshot.forEach(doc => {
                        const record = doc.data();
                        const source = $explorerState.rootDir;
                        records.push({
                            ...record, 
                            rootDir: source,
                            uniqueId: `${source}-${record.id}`
                        });
                    });
                });
                
                $explorerState.records = records;
            }
            
            // console.log("Kanban view records loaded:", $explorerState.records.length);
        } catch(err){
            console.error(err);
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    // Open a record when clicked
    async function openRecord(record) {
        try {
            loading = true;
            
            // Check if project is locked by someone else
            if (record.locked && record.lockedBy !== userEmail) {
                // Show notification about locked project
                await Swal.fire({
                    title: 'Locked Project',
                    text: `This project is locked by ${record.lockedBy}. You can view it, but to make changes you'll need to create a new copy.`,
                    icon: 'info',
                    confirmButtonText: 'Continue in Read-Only Mode',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-lg btn-primary"
                    }
                });
            }
            
            // Determine the storage path based on whether it's personal or team
            const projectFilePath = record.rootDir === "team" 
                ? `${teamStorageBasePath}${record.id}` 
                : `${homeStorageBasePath}${record.id}`;
            
            // Get the URL for the file
            let url;
            if (record.rootDir === "team") {
                const result = await firebase.functions().httpsCallable("v8GetDownloadLink")(projectFilePath);
                url = result.data;
            } else {
                url = await storageRef.child(projectFilePath).getDownloadURL();
            }
            
            // Fetch the file content
            const response = await fetch(url);
            const fileData = await response.json();
            
            // Process based on record type
            if (record.type === "work order") {
                openWorkOrder(fileData, record);
            } else if (record.type === "project") {
                openProjectFile(fileData, record);
            } else if (record.type === "team project") {
                openTeamProjectFile(fileData, record);
            }
            
        } catch (error) {
            console.error(`Error opening ${record.type}:`, error);
            toast.push(`Failed to open ${record.type}: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    function openProjectFile(description, record){
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Project File",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call projectFileImport modal
        modalState.showModal("loadProjectFile");
    }

    function openTeamProjectFile(description, record) {
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Team Project File",
            status: "importing",
            rawDescription: JSON.stringify(description)
        };
        
        modalState.showModal("teamProjectFileImport");
    }

    function openWorkOrder(description, record) {
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Work Order",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call workOrderImport modal
        modalState.showModal("workOrderImport");
    }

    // Formats a date for display
    function formatDate(timestamp) {
        if (!timestamp) return "-";
        const date = timestamp.toDate();
        return date.toLocaleDateString();
    }
    
    // Function to select a record when clicked
    function selectRecord(id) {
        // For Kanban view, we only support single selection
        $explorerState.selectedRecords = [id];
    }
    
    // Initial load of records
    getRecords();
</script>

{#if loading}
<div class="loading-container d-flex justify-content-center align-items-center w-100 h-100">
    <Circle
    size="75"
    color="#1eb4b2"
    unit="px"
    duration="1s"
    ></Circle>
</div>
{:else}
<div class="kanban-container">
    <div class="kanban-board" role="application" aria-label="Kanban Board">
        <!-- Column for records with no state -->
        <div 
            class="kanban-column"
            role="region"
            aria-label="No State"
            on:dragover={(e) => handleColumnDragOver(e, null)}
            on:dragleave={handleDragLeave}
            on:drop={(e) => handleColumnDrop(e, null)}
        >
            <div class="column-header">
                <h5>No State</h5>
                <span class="badge bg-secondary">{recordsWithNoState.length}</span>
            </div>
            <div class="column-content" role="list">
                {#each recordsWithNoState as record, index (record.uniqueId || record.id)}
                    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
                    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                    <div 
                        class="kanban-card card {$explorerState.selectedRecords.includes(record.id) ? 'selected' : ''}"
                        role="article"
                        aria-roledescription="draggable item"
                        tabindex="0"
                        draggable="true"
                        aria-label="{record.name}"
                        on:click={() => selectRecord(record.id)}
                        on:dragstart={(e) => handleDragStart(e, record, null, index)}
                        on:dragend={handleDragEnd}
                        on:dragover={(e) => handleCardDragOver(e, null, index)}
                        on:dragleave={handleDragLeave}
                        on:drop={(e) => handleCardDrop(e, null, index)}
                        on:dblclick={() => openRecord(record)}
                        on:keydown={(e) => e.key === 'Enter' && openRecord(record)}
                    >
                        <div class="card-header d-flex align-items-center py-2">
                            <i class="bi {iconMap[record.type]} me-2"></i>
                            <span class="card-title">{record.name}</span>
                            <div class="ms-auto d-flex">
                                {#if record.locked}<i class="bi bi-lock-fill text-danger ms-1"></i>{/if}
                                {#if record.star}<i class="bi bi-star-fill text-warning ms-1"></i>{/if}
                            </div>
                        </div>
                        <div class="card-body py-2">
                            <div class="card-info">
                                <div><i class="bi bi-person text-secondary me-1"></i> <span class="text-body-secondary">{record.owner || "Unknown"}</span></div>
                                {#if record.assignedTo}
                                    <div><i class="bi bi-person-check text-secondary me-1"></i> <span class="text-body-secondary">{record.assignedTo}</span></div>
                                {/if}
                                <div><i class="bi bi-clock text-secondary me-1"></i> <span class="text-body-secondary">{formatDate(record.updatedOn)}</span></div>
                            </div>
                        </div>
                    </div>
                {/each}
            </div>
        </div>
        
        <!-- Columns for each workflow state -->
        {#each STATES as state}
            <div 
                class="kanban-column"
                role="region"
                aria-label="{state}"
                on:dragover={(e) => handleColumnDragOver(e, state)}
                on:dragleave={handleDragLeave}
                on:drop={(e) => handleColumnDrop(e, state)}
            >
                <div class="column-header">
                    <h5>{state}</h5>
                    <span class="badge bg-secondary">{recordsByState[state].length}</span>
                </div>
                <div class="column-content" role="list">
                    {#each recordsByState[state] as record, index (record.uniqueId || record.id)}
                        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
                        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                        <div 
                            class="kanban-card card {$explorerState.selectedRecords.includes(record.id) ? 'selected' : ''}"
                            role="article"
                            aria-roledescription="draggable item" 
                            tabindex="0"
                            draggable="true"
                            aria-label="{record.name}"
                            on:click={() => selectRecord(record.id)}
                            on:dragstart={(e) => handleDragStart(e, record, state, index)}
                            on:dragend={handleDragEnd}
                            on:dragover={(e) => handleCardDragOver(e, state, index)}
                            on:dragleave={handleDragLeave}
                            on:drop={(e) => handleCardDrop(e, state, index)}
                            on:dblclick={() => openRecord(record)}
                            on:keydown={(e) => e.key === 'Enter' && openRecord(record)}
                        >
                            <div class="card-header d-flex align-items-center py-2">
                                <i class="bi {iconMap[record.type]} me-2"></i>
                                <span class="card-title">{record.name}</span>
                                <div class="ms-auto d-flex">
                                    {#if record.locked}<i class="bi bi-lock-fill text-danger ms-1"></i>{/if}
                                    {#if record.star}<i class="bi bi-star-fill text-warning ms-1"></i>{/if}
                                </div>
                            </div>
                            <div class="card-body py-2">
                                <div class="card-info">
                                    <div><i class="bi bi-person text-secondary me-1"></i> <span class="text-body-secondary">{record.owner || "Unknown"}</span></div>
                                    {#if record.assignedTo}
                                        <div><i class="bi bi-person-check text-secondary me-1"></i> <span class="text-body-secondary">{record.assignedTo}</span></div>
                                    {/if}
                                    <div><i class="bi bi-clock text-secondary me-1"></i> <span class="text-body-secondary">{formatDate(record.updatedOn)}</span></div>
                                </div>
                            </div>
                        </div>
                    {/each}
                </div>
            </div>
        {/each}
    </div>
</div>
{/if}

<style>
    .loading-container {
        height: 80vh;
    }
    
    .kanban-container {
        height: 80vh;
        overflow-x: auto;
        padding: 1rem 0;
    }
    
    .kanban-board {
        display: flex;
        min-height: 100%;
        gap: 1rem;
    }
    
    .kanban-column {
        flex: 0 0 280px;
        background-color: var(--bs-light);
        border-radius: 0.375rem;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        border: 1px solid var(--bs-border-color);
    }
    
    .kanban-column.drag-over {
        background-color: var(--bs-primary-bg-subtle);
        border: 1px dashed var(--bs-primary);
    }
    
    .column-header {
        padding: 0.75rem;
        background-color: var(--bs-light-bg-subtle);
        border-bottom: 1px solid var(--bs-border-color);
        border-radius: 0.375rem 0.375rem 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 1;
    }
    
    .column-header h5 {
        margin: 0;
        font-size: 1rem;
        font-weight: 600;
        color: var(--bs-body-color);
    }
    
    .column-content {
        padding: 0.75rem;
        overflow-y: auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
    
    .kanban-card {
        cursor: pointer;
        user-select: none;
        position: relative;
        background-color: var(--bs-card-bg, var(--bs-body-bg));
        border-color: var(--bs-card-border-color, var(--bs-border-color));
    }
    
    .kanban-card:hover {
        box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.15);
    }
    
    .kanban-card.dragging {
        opacity: 0.5;
    }
    
    .kanban-card.drag-above {
        border-top: 2px solid var(--bs-primary);
        margin-top: -2px;
    }
    
    .kanban-card.drag-below {
        border-bottom: 2px solid var(--bs-primary);
        margin-bottom: -2px;
    }
    
    .kanban-card.selected {
        border-color: var(--bs-primary);
        border-width: 2px;
    }
    
    .card-title {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--bs-body-color);
    }
    
    .card-info {
        font-size: 0.8rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }
    
    .card-info div {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    /* Overriding Bootstrap card styles for our specific use case */
    .card-header {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: var(--bs-card-cap-bg, rgba(var(--bs-body-color-rgb), 0.03));
    }
    
    .card-body {
        padding: 0.5rem;
    }
</style>
