<script>
import {
    environment
} from '@app/store/envStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    videoFilterState
} from '@app/store/videoFilterStore.js'; 
import {
    styleState
} from '@app/store/styleStore.js';
import {
        uiState
    } from "@app/store/uiStore.js";
import { toast } from '@zerodevx/svelte-toast';
import ErrorBoundary from '@app/ErrorBoundary.svelte';
import { createBoundary } from '@crownframework/svelte-error-boundary';
import SubtitlePreview from './SubtitlePreview.svelte'
import AdPreview from './AdPreview.svelte'
import AdrPreview from './AdrPreview.svelte'
import TranscriptPreview from './TranscriptPreview.svelte'
import MediaPlayer from './MediaPlayer.svelte'
import Swal from 'sweetalert2';
import loadMedia from '@app/external/cc-lib/dist/functions/projects/loadMedia.js';
import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
const Boundary = createBoundary(ErrorBoundary);

let ffmpegRes;

function alertUserBoolean(msg) {
    let response = Swal.fire({
        titleText: "Metadata Scan",
        text : msg,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-danger',
            denyButton: 'btn btn-light mx-2',
            cancelButton : 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else {
            return false;
        }
    });

    return response;
}

async function getFileMetadata(filePath, ffmpegPath) {
    try {
        const exec = window.exec;
        return await exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
    } catch (err) {
        return err.message;
    }
}

function getMediaIncode(ffmpegOutput){
    let matches =  ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
    let mediaIncode = matches ? matches[0].replace(";", ":") : null;

    console.log("Media incode detected: "+mediaIncode);
    return mediaIncode;
}

function getMediaFrameRate(ffmpegOutput) {
		let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
		let mediaFrameRate = matches ? matches[0] : false;

		console.log("Media frame rate detected: " + mediaFrameRate);
		let fr = frameRates.frameRateMapping[mediaFrameRate];
		return fr;
	}

async function importMedia(ev) {
    ev.preventDefault();
    try {
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            if (ev.dataTransfer.items[0] && ev.dataTransfer.items[0].kind === 'file') {
                let mediaFile = ev.dataTransfer.items[0].getAsFile();
                console.log(mediaFile);
                console.log(mediaFile.name);
                console.log(mediaFile.size);

                playerState.updateDuration(false);
                $uiState.timeline = false;
                $projectState = loadMedia($projectState, {
                    projectName: $projectState.name,
                    storage: "Local Storage",
                    mediaUrl: "",
                    files: [mediaFile],
                    frameRate: $projectState.frameRate,
                    dropFrame: $projectState.dropFrame
                });

                if (mediaFile && mediaFile.size < 524288000) {
                    $projectState.media.useFallback = false;
                } else {
                    $projectState.media.useFallback = true;
                }

                let source = {
                    src: $projectState.media.path
                }

                source.src = $projectState.media.path;

                if ($projectState.media.type === "video/quicktime"){
                    source.type = "video/mp4"
                } else if ($projectState.media.type === "video/x-matroska"){
                    source.type = "video/mkv"
                } else {
                    source.type = $projectState.media.type;
                }

                if ($environment.electron){
                    const ffmpegPath = require("ffmpeg-static-electron").path;
                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    $projectState.media.info = {
                        incode : getMediaIncode(ffmpegRes),
                        frameRate : getMediaFrameRate(ffmpegRes)
                    }
                    /* Auto detect frame rate */
                    if ($projectState.media.info.frameRate && $projectState.media.info.frameRate != $projectState.frameRate){
                        if (await alertUserBoolean(`Would you like to update the Project frame rate to match the frame rate found in the media (${$projectState.media.info.frameRate})?`)) {
                            $projectState.frameRate = $projectState.media.info.frameRate;
                            $projectState.dropFrame = $projectState.media.info.frameRate === 29.97 || $projectState.media.info.frameRate === 59.94 ? true : false;
                        };
                    }
                    /* -------------auto detect frame rate------------- */
                }

                /* Update media player global */
                player.source = {
                    type: "video",
                    sources: [source]
                }     

                toast.push("Media imported successfully", {classes: ['toast-success']});
                setTimeout(() => {
                    $uiState.timeline = true;
                }, 1000);
                
            }
        }
    } catch (err) {
        console.log(err, err.message);
        toast.push("Failed to import media", {classes: ['toast-danger']});
    }
}
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div id="PlayerWrapper" style="filter: contrast({$videoFilterState.contrast}%) saturate({$videoFilterState.saturation}%) invert({$videoFilterState.invert}%) brightness({$videoFilterState.brightness}%);" on:drop={importMedia} on:dragover={(ev) => { ev.preventDefault() }}>
    <Boundary onError={console.error}>
        <MediaPlayer></MediaPlayer>
    </Boundary>  
    {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "audio description" && $styleState.adrPreview} 
        <Boundary onError={console.error}>
            <AdrPreview></AdrPreview>
        </Boundary>
    {:else if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "audio description"}
        <Boundary onError={console.error}>
            <AdPreview></AdPreview>
        </Boundary>    
    {:else if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "transcription"} 
        <Boundary onError={console.error}>
            <TranscriptPreview></TranscriptPreview>
        </Boundary>
    {:else if $eventGroupState[$projectState.selected]}
        <Boundary onError={console.error}>
            <SubtitlePreview></SubtitlePreview>
        </Boundary>
    {/if}

</div>

<style>
#PlayerWrapper {
    position: relative;
}
</style>
