import _autoFormat from "../eventGroups/autoFormat.js";
import _convertToHtml from "../quill/convertToHtml.js";
import _Event from "../../classes/event.js";
const Event = _Event;
const convertToHtml = _convertToHtml;
const autoFormat = _autoFormat;
export default (function convertWordMapToSubtitles(wordMap, maxLines, maxChars, window) {
  let events = [],
    wordMapSegments = [],
    wordMapSegment = [];

  //Limit the duration of words to max of 0.8. This is to make up for some service providers not being able to detect a proper word boundary.
  wordMap.forEach((word, i, words) => {
    words[i].end = Math.min(word.end, word.start + 0.8);
  });

  //Split WordMap into Segments based on speaker, timing, and punctuation. If the speaker changes than it is a new segment. If there is a gap of more than 1 second than it is a new segment. If there is a period, exclamation mark, or question mark than it is a new segment unless it is preceded by Mr, Mrs, Ms, or Dr.
  // console.log(wordMap);
  wordMap.forEach(word => {
    if (wordMapSegment.length === 0) {
      wordMapSegment.push(word);
    } else {
      if (word.speakers[0] !== wordMapSegment[0].speakers[0]) {
        wordMapSegments.push(wordMapSegment);
        wordMapSegment = [word];
      } else if (word.start - wordMapSegment[wordMapSegment.length - 1].end > 1) {
        wordMapSegments.push(wordMapSegment);
        wordMapSegment = [word];
      } else if ((word.text.slice(-1) === "." || word.text.slice(-1) === "!" || word.text.slice(-1) === "?") && word.text.toLowerCase() != "mr." && word.text.toLowerCase() !== "mrs." && word.text.toLowerCase() !== "ms." && word.text.toLowerCase() !== "dr.") {
        wordMapSegment.push(word);
        wordMapSegments.push(wordMapSegment);
        wordMapSegment = [];
      } else {
        wordMapSegment.push(word);
      }
    }
  });
  if (wordMapSegment.length > 0) {
    wordMapSegments.push(wordMapSegment);
  }
  let blocks = [];
  //Loop over the word map segments and generate an array of blocks that will later be converted to events.
  wordMapSegments.forEach(wordMap => {
    let sentence = {
      characters: 0,
      words: []
    };
    let sentences = {
      lines: 0,
      sentences: []
    };
    wordMap.forEach((word, index, words) => {
      if (sentence.characters + word.text.length >= maxChars) {
        if (index === words.length - 1 && sentences.lines + 1 === maxLines && sentences.lines > 0) {
          blocks.push(sentences);
          sentences = {
            lines: 0,
            sentences: []
          };
        }
        sentences.sentences.push(sentence);
        sentences.lines++;
        sentence = {
          characters: 0,
          words: []
        };
        if (sentences.lines === maxLines) {
          blocks.push(sentences);
          sentence = {
            characters: word.text.length + 1,
            words: [word]
          };
          sentences = {
            lines: 0,
            sentences: []
          };
          return;
        } else {
          sentence.words.push(word);
          sentence.characters += word.text.length + 1;
        }
      } else {
        sentence.words.push(word);
        sentence.characters += word.text.length + 1;
      }
    });
    if (sentence.words.length > 0) {
      sentences.sentences.push(sentence);
      sentences.lines++;
      blocks.push(sentences);
    }
  });

  //Loop over each block and create CC Events from the sentences inside the block.
  blocks.forEach(block => {
    // console.log(block);
    let text = "",
      confidence = [];
    block.sentences = block.sentences.filter(sentence => sentence.words.length > 0);
    if (block.sentences.length === 0) {
      return;
    }
    block.sentences.forEach(sentence => {
      sentence.words.forEach(word => {
        confidence.push(word.confidence);
        text += word.text + " ";
      });
      text = text.trim() + "\n";
    });

    //Remove line break from the last sentence.
    text = text.trim();

    // console.log(text);
    // console.log(block.sentences[0].words)
    // console.log("Speaker:", block.sentences[0].words[0].speakers);

    let ccEvent = new Event({
      start: block.sentences[0].words[0].start,
      end: block.sentences[block.sentences.length - 1].words[block.sentences[block.sentences.length - 1].words.length - 1].end,
      text: text,
      confidence: confidence.length > 0 ? confidence.reduce(function (p, c, i) {
        return p + (c - p) / (i + 1);
      }, 0) : 0,
      speakers: block.sentences[0].words[0].speakers || [],
      yOffset: window.height * -0.10
    });
    ccEvent.text = convertToHtml(ccEvent.text);
    events.push(ccEvent);
  });

  // console.log(JSON.stringify(events, null, 4));
  events = autoFormat({
    events: events
  }, maxLines, maxChars, 0.83, true, null).events;
  // console.log(JSON.stringify(events, null, 4));
  return events;
});