<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
const copyEvents = function() {
    if ($eventGroupState[$projectState.selected].selected.length > 0) {
        let textToCopy, range = quillEditor.getSelection();
        if (range && range.length !== 0 && $eventGroupState[$projectState.selected].selected.length === 1) {
            textToCopy = quillEditor.getText(range.index, range.length);
        } else {
            let eventsToCopy = [];
            $eventGroupState[$projectState.selected].selected.sort((eventA, eventB) =>{
                return eventA - eventB;
            }).forEach(eventIndex => {
                eventsToCopy.push($eventGroupState[$projectState.selected].events[eventIndex]);
            });

            textToCopy = JSON.stringify(eventsToCopy);
        }

        navigator.clipboard.writeText(textToCopy);
    }
}
</script>
<li class="nav-item" title="Copy Event/Text">
    <a 
        class="nav-link" 
        href="#!/" 
        id="copy-event-btn"
        role="button"
        aria-label="Copy selected events or text"
        aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        on:click={copyEvents}
    ><i class="bi bi-clipboard-plus" /></a>
</li>