<script>
    export let errorMsg = "";
</script>
{#if errorMsg}
<button type="button" class="btn btn-link text-custom-medium" title="{errorMsg}">
    <i class="bi bi-exclamation-diamond-fill text-danger"></i>
</button>
{/if}

<style>
    button {
        padding : 2px;
        margin : 0;
    }
</style>