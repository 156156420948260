import _convertToPlainText from "../quill/convertToPlainText.js";
import _convertToPlainTextCustom from "../quill/convertToPlainTextCustom.js";
const convertToPlainTextCustom = _convertToPlainTextCustom;
const convertToPlainText = _convertToPlainText;
const endPunctuation = [".", "?", "!", ":"];
const speakerTags = ["-", "—", "[", ">", "(", "¿"];
const getFirstChar = function (w) {
  let plainText = convertToPlainTextCustom(w, " ", true);
  return plainText.slice(0, 1);
};
const getLastChar = function (w) {
  let plainText = convertToPlainTextCustom(w, " ", true);
  return plainText.slice(w.length - 1);
};
const getLength = function (w) {
  let plainText = convertToPlainTextCustom(w, " ", true);
  return plainText.trim().length;
};
function getLongestLineIndex(lines, whiteSpace) {
  let longestLine = 0;
  let longestLength = 0;
  lines.forEach((line, index) => {
    line = whiteSpace ? convertToPlainText(line.join(" ").trim()) : convertToPlainText(line.join("").trim());
    let length = line.length;
    if (length > longestLength) {
      longestLength = length;
      longestLine = index;
    }
  });
  return longestLine;
}
function getMaxLength(lines, whiteSpace) {
  let longestLength = 0;
  lines.forEach(line => {
    line = whiteSpace ? convertToPlainText(line.join(" ").trim()) : convertToPlainText(line.join("").trim());
    let length = whiteSpace ? line.length : line.length;
    if (length > longestLength) {
      longestLength = length;
    }
  });
  return longestLength;
}
function getBalancedScore(lines, whiteSpace) {
  let lineLengths = lines.map(line => {
    line = whiteSpace ? convertToPlainText(line.join(" ").trim()) : convertToPlainText(line.join("").trim());
    return line.length;
  });
  let deltas = [];
  lineLengths.forEach((lineLength, count, lengths) => {
    if (count < lengths.length - 1) {
      deltas.push(lineLength - lengths[count + 1]);
    }
  });
  return Math.abs(deltas.reduce((partialSum, a) => partialSum + a, 0) / lines.length);
}
const bestFit = function (line1, line2, direction) {
  let line1Length = getLength(line1);
  let line2Length = getLength(line2);
  let line2Array = line2.split(" ");
  let oDiff = Math.abs(line1Length - line2Length);
  if (direction === "start") {
    let word = line2Array.shift();
    line1 += " " + word;
  } else {
    let word = line2Array.pop();
    line1 = word + " " + line1;
  }
  let nDiff = Math.abs(getLength(line1) - getLength(line2Array.join(" ")));
  // console.log("Old Difference: ", oDiff);
  // console.log("New Difference: ", nDiff);
  return nDiff >= oDiff; //Return true if the new differernce is greater than the old difference. This tells the function upstream to stop processing.
};
/* Takes event text as html */
export default (function balanceText(html, maxChars, maxLines) {
  if (!html) {
    // console.log("No text provided to balanceText." + text);
    return [''];
  }
  let text = convertToPlainTextCustom(html, " ");
  let whiteSpace = true;
  let prevLines = [];
  let prevBalanceScore = 100000;
  if (text.split(/\s+|(?<=，+)/g).length === 1 && text.length > maxChars) {
    text = convertToPlainText(text, " ");
    whiteSpace = false;
  }
  let words = text.split(" ");
  let lines = whiteSpace ? [text.split(/\s+|(?<=，+)/g)] : [text.split("")];
  let textLines = [];
  let newTextLines = [];
  let textLine = "";
  let processing = false;
  let iterations = 0;
  //Check to see if text contains a speaker tag from the speakerTags array.
  let containsSpeakerTag = words.some(word => {
    return speakerTags.includes(getFirstChar(word));
  });
  let containsEndPunctuation = words.some(word => {
    return endPunctuation.includes(getLastChar(word));
  });
  if (!containsSpeakerTag && !containsEndPunctuation || !whiteSpace) {
    processing = true;
    while (processing && iterations < 2000) {
      processing = false;
      iterations++;
      prevLines = JSON.parse(JSON.stringify(lines));
      let longestLineIndex = getLongestLineIndex(lines, whiteSpace);
      let word = lines[longestLineIndex].pop();
      if (lines[longestLineIndex + 1]) {
        lines[longestLineIndex + 1].unshift(word);
      } else {
        lines.push([word]);
      }
      lines = lines.filter(line => {
        return line;
      });
      let maxLength = getMaxLength(prevLines, whiteSpace);
      let balanceScore = getBalancedScore(lines, whiteSpace);
      if (maxLength > maxChars) {
        processing = true;
      } else if (balanceScore > prevBalanceScore || prevLines.length === 1) {
        processing = false;
        iterations = 1000;
      } else if (lines.length > maxLines) {
        processing = false;
        iterations = 1000;
      } else {
        processing = true;
      }
      prevBalanceScore = balanceScore;
    }
    prevLines = prevLines.filter(line => {
      return line.length > 0;
    });
    return prevLines.map(line => {
      return whiteSpace ? line.join(" ").trim() : line.join("").trim();
    });
  } else {
    words.forEach((word, index, words) => {
      let firstChar = getFirstChar(word);
      let lastChar = getLastChar(word);
      if (endPunctuation.includes(lastChar)) {
        textLine += " " + word;
        textLines.push(textLine.trim());
        textLine = "";
        return;
      }
      if (!textLine) {
        textLine += word;
        return;
      }
      if (speakerTags.includes(firstChar)) {
        textLines.push(textLine);
        textLine = word;
        return;
      }
      if (getLength(textLine) + 1 + getLength(word) > maxChars) {
        textLines.push(textLine);
        textLine = word;
        return;
      }
      textLine += " " + word;
    });

    //Push Remaining text.
    if (textLine) {
      textLines.push(textLine);
    }
    // console.log("Before:", textLines);

    textLines.forEach((line, index, lines) => {
      // console.log("Line: ", line);
      if (getLength(line) > maxChars) {
        /* Line is over the max character limit */
        //Check if the line starts with a speaker tag/id (which we'll need to push to the next line or create a new line)
        if (speakerTags.includes(getFirstChar(line))) {
          let words = line.split(" ");
          let nextLine = lines[index + 1] && !speakerTags.includes(getFirstChar(lines[index + 1])) && getLength(lines[index + 1]) + (getLength(line) - maxChars) < maxChars ? lines[index + 1] : "";
          processing = true;
          iterations = 0;
          let nextLineExists = nextLine ? true : false;
          while (processing && iterations < 1000) {
            iterations++;
            if (getLength(nextLine) + 1 + getLength(words[words.length - 1]) > maxChars || getLength(words.join(" ")) < maxChars && bestFit(nextLine, words.join(" "), "end")) {
              if (nextLineExists) {
                lines[index + 1] = nextLine;
                newTextLines.push(words.join(" "));
              } else {
                newTextLines.push(words.join(" "));
                newTextLines.push(nextLine);
              }
              processing = false;
            } else {
              nextLine = words.pop() + " " + nextLine;
            }
          }
        } else if (endPunctuation.includes(getLastChar(line))) {
          /* Line Ends with period or other final punctuation so we'll need to move words to the previous line or create a new line above if one doesn't exist */
          let words = line.split(" ");
          let lastLine = newTextLines[newTextLines.length - 1] && !endPunctuation.includes(getLastChar(newTextLines[newTextLines.length - 1])) && getLength(newTextLines[newTextLines.length - 1]) + (getLength(line) - maxChars) < maxChars ? newTextLines[newTextLines.length - 1] : "";
          processing = true;
          iterations = 0;
          let lastLineExists = lastLine ? true : false;
          while (processing && iterations < 1000) {
            iterations++;
            if (getLength(lastLine) + 1 + getLength(words[0]) > maxChars || getLength(words.join(" ")) < maxChars && bestFit(lastLine, words.join(" "), "start")) {
              if (lastLineExists) {
                newTextLines[newTextLines.length - 1] = lastLine;
                newTextLines.push(words.join(" "));
              } else {
                newTextLines.push(lastLine);
                newTextLines.push(words.join(" "));
              }
              processing = false;
            } else {
              lastLine += " " + words.shift();
              lastLine = lastLine.trim();
            }
          }
        } else {
          /* Line is too long, but doesn't end in punctuation or start with a speaker tag so we'll need to split it */
          let words = line.split(" ");
          let lastLine = newTextLines[newTextLines.length - 1] && !endPunctuation.includes(getLastChar(newTextLines[newTextLines.length - 1])) && getLength(newTextLines[newTextLines.length - 1]) + (getLength(line) - maxChars) < maxChars ? newTextLines[newTextLines.length - 1] : "";
          processing = true;
          iterations = 0;
          let lastLineExists = lastLine ? true : false;
          while (processing && iterations < 1000) {
            iterations++;
            if (getLength(lastLine) + 1 + getLength(words[0]) > maxChars || getLength(words.join(" ")) < maxChars && bestFit(lastLine, words.join(" "), "start")) {
              if (lastLineExists) {
                newTextLines[newTextLines.length - 1] = lastLine;
                newTextLines.push(words.join(" "));
              } else {
                newTextLines.push(lastLine);
                newTextLines.push(words.join(" "));
              }
              processing = false;
            } else {
              lastLine += " " + words.shift();
              lastLine = lastLine.trim();
            }
          }
        }
      } else {
        newTextLines.push(line);
      }
    });
    return newTextLines;
  }
});