module.exports = {
    align : {
        start : "", //Flexbox
        center : "ql-align-center",
        end : "ql-align-right", //Flexbox
        left : "",
        right : "ql-align-right"
    },
    color : {
        foreground : {
            black : "ql-color-black",
            red : "ql-color-red",
            orange : "ql-color-orange",
            yellow : "ql-color-yellow",
            green : "ql-color-green",
            blue : "ql-color-blue",
            purple : "ql-color-purple"
        },     
        background : {
            black : "ql-bg-black",
            red : "ql-bg-red",
            orange : "ql-bg-orange",
            yellow : "ql-bg-yellow",
            green : "ql-bg-green",
            blue : "ql-bg-blue",
            purple : "ql-bg-purple"
        }        
    },
    rtl : "ql-direction-rtl"
}