export default (function shiftWordBack(event1, event2) {
  // Create a DOM parser to work with HTML
  const parser = new DOMParser();

  // Parse both event texts into DOM
  const doc1 = parser.parseFromString(event1.text, 'text/html');
  const doc2 = parser.parseFromString(event2.text, 'text/html');

  // Get paragraphs from first event
  const firstEventParagraphs = doc1.getElementsByTagName('p');
  if (firstEventParagraphs.length === 0) {
    return {
      event1,
      event2
    }; // No paragraphs to move
  }

  // Get the last paragraph from first event
  const paragraphToMove = firstEventParagraphs[firstEventParagraphs.length - 1];

  // Clone the paragraph and insert at beginning of second event
  const clonedParagraph = paragraphToMove.cloneNode(true);
  if (doc2.body.firstChild) {
    doc2.body.insertBefore(clonedParagraph, doc2.body.firstChild);
  } else {
    doc2.body.appendChild(clonedParagraph);
  }

  // Remove the paragraph from first event
  paragraphToMove.parentNode.removeChild(paragraphToMove);

  // Update both events with new HTML
  event1.text = doc1.body.innerHTML;
  event2.text = doc2.body.innerHTML;
  return {
    event1,
    event2
  };
});