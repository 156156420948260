import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _formatXml from "xml-formatter";
import { decode as _decodeHtml } from "html-entities";
import { encode as _encodeHtml } from "html-entities";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _flexbox from "../dict/flexbox.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _eol from "eol";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const eol = _eol;
const convertToPlainText = _convertToPlainText;
const convertToHtml = _convertToHtml;
const flexbox = _flexbox;
const removeInvalidEvents = _removeInvalidEvents;
const encodeHtml = _encodeHtml; //Encode
const decodeHtml = _decodeHtml; //Decode
const formatXml = _formatXml; //Encode
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent;
    const xmlToJson = new XMLParser({
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(JSON.stringify(fileJson, null, 4));

    fileJson["tt"]["body"]["div"]["p"].forEach(paragraph => {
      ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.10
      });

      //console.log(paragraph);
      ccEvent.start = tcLib.parseTcToSec(paragraph['@_begin'] || paragraph['@_tt:begin'], options.frameRate, false);
      ccEvent.end = tcLib.parseTcToSec(paragraph['@_end'] || paragraph['@_tt:end'], options.frameRate, false);

      /* Get Y Positioning */
      if (paragraph['@_region'] && flexbox.alignmentMap[paragraph['@_region']]) {
        ccEvent.yPos = flexbox.alignmentMap[paragraph['@_region']];
      }
      if (Array.isArray(paragraph['span'])) {
        ccEvent.text = paragraph['span'].map(textLines => {
          return textLines["#text"];
        }).join("\n");
      } else {
        ccEvent.text = paragraph['span']["#text"];
      }
      ccEvent.text = convertToHtml(decodeHtml(ccEvent.text));
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt:tt ttp:timeBase="smpte" xml:lang="en" xmlns:tt="http://www.w3.org/ns/ttml" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:tts="http://www.w3.org/ns/ttml#styling" ttp:cellResolution="50 30" xmlns:ebuttm="urn:ebu:tt:metadata" tts:extent="704px 576px" ttp:dropMode="${options.dropFrame ? 'drop' : 'nonDrop'}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:markerMode="discontinuous">`);
    output += eol.after(`<tt:head>`);
    output += eol.after(`<tt:metadata>
        <ebuttm:documentMetadata>
            <ebuttm:documentEbuttVersion>v1.0</ebuttm:documentEbuttVersion>
            <ebuttm:documentTotalNumberOfSubtitles>${eventGroup.events.length}</ebuttm:documentTotalNumberOfSubtitles>
            <ebuttm:documentMaximumNumberOfDisplayableCharacterInAnyRow>40</ebuttm:documentMaximumNumberOfDisplayableCharacterInAnyRow>
            <ebuttm:documentStartOfProgramme>00:00:00:00</ebuttm:documentStartOfProgramme>
            <ebuttm:documentCountryOfOrigin>EN</ebuttm:documentCountryOfOrigin>
            <ebuttm:documentPublisher>Closed Caption Converter V3</ebuttm:documentPublisher>
        </ebuttm:documentMetadata>
    </tt:metadata>`);
    output += eol.after(`<tt:styling>
        <tt:style xml:id="defaultStyle" tts:fontFamily="monospaceSansSerif" tts:fontSize="1c 1c" tts:lineHeight="normal" tts:textAlign="center" tts:color="white" tts:backgroundColor="transparent" tts:fontStyle="normal" tts:fontWeight="normal" tts:textDecoration="none" />
        <tt:style xml:id="WhiteOnBlack" tts:color="white" tts:backgroundColor="black" tts:fontSize="1c 2c"/>
        <tt:style xml:id="textCenter" tts:textAlign="center"/>
    </tt:styling>`);
    output += eol.after(`<tt:layout>
        <tt:region xml:id="top" tts:origin="10% 10%" tts:extent="80% 80%" tts:padding="0c" tts:displayAlign="before" tts:writingMode="lrtb"/>
        <tt:region xml:id="bottom" tts:origin="10% 10%" tts:extent="80% 80%" tts:padding="0c" tts:displayAlign="after" tts:writingMode="lrtb"/>
    </tt:layout>`);
    output += eol.after(`</tt:head>`);
    output += eol.after(`<tt:body>`);
    output += eol.after(`<tt:div>`);
    eventGroup.events.forEach((event, index) => {
      output += eol.after(`<tt:p xml:id="sub${index + 1}" style="textCenter" region="top" begin="${tcLib.secToTc(event.start, options.frameRate)}" end="${tcLib.secToTc(event.end, options.frameRate)}">`);
      convertToPlainText(event.text).split("\n").forEach((textLine, index) => {
        if (index > 0) {
          output += eol.after(`<tt:br/>`);
        }
        output += eol.after(`<tt:span style="WhiteOnBlack">${encodeHtml(textLine)}</tt:span>`);
      });
      output += eol.after(`</tt:p>`);
    });
    output += eol.after(`</tt:div>`);
    output += eol.after(`</tt:body>`);
    output += eol.after(`</tt:tt>`);
    return formatXml(output);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};