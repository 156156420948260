import _stripTags from "../quill/stripTags.js";
const stripTags = _stripTags;
export default (function removeInvalidEvents(eventGroup, allowMetadata = false) {
  eventGroup.events = eventGroup.events.filter(event => {
    //If allowMetadata is true, then we don't need to check for start, end, or text. We only need to check if the event is longer than 0 seconds.
    if (allowMetadata) {
      return event.end - event.start > 0;
    } else {
      return event.start !== undefined && event.start !== false && event.end !== false && event.end !== undefined && stripTags(event.text).trim() !== "" && event.end - event.start > 0;
    }
  });
  return eventGroup;
});