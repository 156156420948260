<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { fade } from 'svelte/transition';
let selected = 0, hide = true;
const slides = [
    {
        title: "Release 1.8.31",        
        description: "2025-03-11",
        lead : "",
        cards : [
            {
                title : "Storage Explorer",
                sub : "Manage your projects and files with ease",
                description : "We've added a new Storage Explorer to help you manage your projects and files with ease. You can now create folders, upload files, and move files between folders.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/storageExplorer.jpg'
            },   
            {
                title : "Project Sharing",
                sub : "Share projects with full permissions",
                description : "Project shares can now be created with full permissions. This allows you to share a project with another team member and allow them to edit and create Event Groups as if they were the Project Owner themselves.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/teamSharePermissions.jpg'
            },                    
            {
                title : "Kanban View",
                sub : "Workflow management built in",
                description : "View projects by workflow status with our new Kanban View. This view allows you to see all of your projects in a visual workflow format, and you can drag and drop projects between states.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/kanbanView.jpg'
            },    
        ]
    }
];

function hideModal(){
    if (hide){
        localStorage.setItem('cc-hide-v31', 'true');
    } else {
        localStorage.setItem('cc-hide-v31', 'false');
    }
    
    modalState.showModal('welcome');    
}
</script>

<div class="modal fade {$modalState === 'tips' ? 'show d-block' : ''}" role="dialog" aria-labelledby="tipsTitle" aria-modal="true" tabindex="-1" id="TipsModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="tipsTitle">What's New</h4>
                <button type="button" class="btn-close" aria-label="Close Tips" on:click={() => hideModal()}></button>
            </div>
            <div class="modal-body">
                <p class="text-secondary m-0" aria-label="Release Date">{slides[selected].description}</p>
                <h4>{slides[selected].title}</h4>
                
                <p class="lead">{slides[selected].lead}</p>
                <div class="container">
                    <div class="row" role="list">
                        {#each slides[selected].cards as card}
                        <div class="col-4 mb-4" role="listitem" transition:fade="{{delay: 250, duration: 300}}">
                            <div class="card text-dark bg-light shadow border-0">
                                {#if card.image}
                                    <img src="{card.image}" class="card-img-top" alt="{card.title} feature screenshot">
                                {/if}
                                <div class="card-body">
                                    <h4 class="fw-bold card-title">{card.title}</h4>
                                    <h6 class="fw-bold card-subtitle mb-2">{card.sub}</h6>
                                    <p class="card-text">{card.description}</p>
                                    {#if card.link}
                                        <button class="btn btn-outline-primary w-100" type="button" 
                                            aria-label="{card.buttonText}"
                                            on:click={()=>{isNaN(card.buttonLink) ? modalState.showModal(card.buttonLink) : selected = card.buttonLink}}>
                                            {card.buttonText}
                                        </button>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="p-3 border-top border-light">
                <div class="float-start">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="hideCheck" bind:checked={hide}>
                        <label class="form-check-label" for="hideCheck">Hide on startup</label>
                    </div>
                </div>                
                <div class="float-end">                          
                    <button type="button" class="btn btn-primary me-2" id="closeTipsBtn" aria-label="Close Tips" on:click="{() => hideModal()}">Close</button>
                    <a class="btn btn-outline-dark" href="https://www.closedcaptioncreator.com/releases.html" target="_blank" rel="noreferrer" aria-label="Learn More about Updates">Learn More</a>
                </div>                
            </div>
        </div>
    </div>
</div>
