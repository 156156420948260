import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class Marker {
  constructor(options = {
    time: 0,
    comment: ""
  }) {
    this.id = options.id || uuidv4();
    this.time = options.time || 0;
    this.comment = options.comment || "";
  }
});