import _stripTags from "../quill/stripTags.js";
import _orderByTime from "../eventGroups/orderByTime.js";
import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
const orderByTime = _orderByTime;
const stripTags = _stripTags;
export default (function subtractIncode(eventGroup, options) {
  // console.log(JSON.stringify(eventGroup, null, 4));
  if (options.incode === "00:00:00:00") {
    return eventGroup;
  }
  if (eventGroup.events.length === 0) {
    throw new Error("No Events in Event Group");
  }
  if (!options.incode || options.incode === "auto") {
    eventGroup = orderByTime(eventGroup);
    if (stripTags(eventGroup.events[0].text).trim() == "") {
      eventGroup.events.shift();
      if (eventGroup.events.length === 0) {
        throw new Error("No Events in Event Group");
      }
    }
    let tcStart = tcLib.secToTc(eventGroup.events[0].start, options.frameRate);
    //console.log(tcStart);
    options.incode = tcStart.split(":")[0] + ":00:00:00";
  }

  //console.log(options.incode, options.frameRate, options.dropFrame);
  eventGroup.events.forEach((event, index, events) => {
    if (event.start === false || event.end === false || event.start === undefined || event.end === undefined) {
      return;
    }

    // console.log(events[index]);
    /* let tcStart =  tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame);
    let tcEnd = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame); */

    let incodeSec = tcLib.tcToSec(options.incode, options.frameRate);

    //console.log(events[index].start, events[index].end, options.incode);

    events[index].start -= incodeSec;
    events[index].end -= incodeSec;

    //console.log("AFTER:",events[index].start);
  });
  return eventGroup;
});