export default (function expandText(htmlString, maxCharLimit = 32) {
  // Create DOM parser and parse HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const paragraphs = Array.from(doc.getElementsByTagName('p'));
  if (paragraphs.length <= 1) return htmlString;

  // Find shortest paragraph and its index
  let shortestLength = Infinity;
  let shortestIndex = -1;
  paragraphs.forEach((p, index) => {
    const length = p.textContent.trim().length;
    if (length > 0 && length < shortestLength) {
      shortestLength = length;
      shortestIndex = index;
    }
  });
  if (shortestIndex === -1) return htmlString;
  const currentP = paragraphs[shortestIndex];
  const prevP = shortestIndex > 0 ? paragraphs[shortestIndex - 1] : null;
  const nextP = shortestIndex < paragraphs.length - 1 ? paragraphs[shortestIndex + 1] : null;

  // Get words from current paragraph
  const currentWords = currentP.textContent.trim().split(/\s+/);

  // Try moving word to adjacent paragraphs
  let moved = false;
  if (prevP && currentWords.length > 0) {
    // Check if moving first word to previous paragraph is within limit
    const newPrevLength = prevP.textContent.trim().length + currentWords[0].length + 1;
    if (newPrevLength <= maxCharLimit) {
      // Move first word to previous paragraph
      prevP.textContent = prevP.textContent.trim() + ' ' + currentWords[0];
      currentWords.shift();
      moved = true;
    }
  }
  if (!moved && nextP && currentWords.length > 0) {
    // Check if moving last word to next paragraph is within limit
    const newNextLength = nextP.textContent.trim().length + currentWords[currentWords.length - 1].length + 1;
    if (newNextLength <= maxCharLimit) {
      // Move last word to next paragraph
      nextP.textContent = currentWords[currentWords.length - 1] + ' ' + nextP.textContent.trim();
      currentWords.pop();
      moved = true;
    }
  }

  // Update or remove current paragraph
  if (currentWords.length === 0) {
    currentP.remove();
  } else {
    currentP.textContent = currentWords.join(' ');
  }
  return doc.body.innerHTML;
});