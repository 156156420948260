<script>
import {environment} from '@app/store/envStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

async function addToTemplates() {
    try {
        if ($eventGroupState[$projectState.selected].selected.length > 0) {            
            let eventTemplate = JSON.parse(JSON.stringify($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]]));
            eventTemplate.start = false;
            eventTemplate.end = false;
            eventTemplate.duration = false;
            eventTemplate.original = "";
            eventTemplate.speakers = [];
            eventTemplate.audioFile = ""; //We don't want to store blob data in DB
            eventTemplate.waveformFile = "";

            // Refactor to use local storage instead of firebase when environment.online is set to false

            if ($environment.online){
                    let userId = firebase.auth().currentUser.uid;
                    db.collection("users").doc(userId).collection('templates').doc(eventTemplate.id).set(eventTemplate).then(() => {

                    toast.push("New event template created", {classes: ["toast-success"]});

                }).catch((err) => {
                    console.log("Error creating event template: ", err, err.message);

                    toast.push("Error creating event template", {classes: ["toast-danger"]});
                });
            } else {
                let templates = JSON.parse(localStorage.getItem('cc-event-templates'));
                if (!templates){
                    templates = [];
                }

                templates.push(eventTemplate);
                localStorage.setItem('cc-event-templates', JSON.stringify(templates));

                toast.push("New event template created", {classes: ["toast-success"]});
            }
            
        }
    } catch(err){
        console.log(err, err.message);

        toast.push("Error creating event template", {classes: ["toast-danger"]});
        
    } finally{
        dispatch("done");
    }
}
</script>

<a id="btnAddToTemplates"
   role="menuitem"
   aria-label="Add selected event to templates"
   aria-disabled="{$eventGroupState.length === 0 || $eventGroupState[$projectState.selected].selected.length === 0}"
   class="dropdown-item {$eventGroupState.length === 0 || $eventGroupState[$projectState.selected].selected.length === 0 ? 'disabled' : ''}"
   href="#!/"
   on:click={addToTemplates}>
   Add To Templates
</a>
