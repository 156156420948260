<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { fade } from 'svelte/transition';
let selected = 0, hide = localStorage.getItem('cc-hide-v27') === "true" ? true : false;
const slides = [
    {
        title: "Release 1.8.27",        
        description: "2024-10-16",
        lead : "",
        cards : [
            {
                title : "API Key Manager",
                sub : "Generate and manage API keys",
                description : "Access to the Closed Caption Creator API is coming to all Pro and Enterprise users. The API Key Manager is the first step. Create and manage API Keys for your team using the new API Key Manager.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/apiKeyManager.jpg'
            },   
            {
                title : "Manual QC",
                sub : "A scratchpad for your edit notes",
                description : "The Manual QC panel can be found in the QuickTools drawer and offers a simplified way of creating notes and adding descriptions to your project and Events.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/manualQc.jpg'
            },                    
            {
                title : "XDS Data Support",
                sub : "Support for V-CHIP and Program Metadata",
                description : "Scenerist Advanced, MacCaption Advanced, and RAW Vanc (for AAF) Profiles now include options to embed XDS data such as VCHIP and Program Metadata. This feature is available in the Export Settings dialog.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/xdsDataExport.jpg'
            },    
        ]
    }
];

function hideModal(){
    if (hide){
        localStorage.setItem('cc-hide-v27', 'true');
    } else {
        localStorage.setItem('cc-hide-v27', 'false');
    }
    
    modalState.showModal('welcome');    
}
</script>

<div class="modal fade {$modalState === 'tips' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TipsModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">What's New</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={() => hideModal()}></button>
            </div>
            <div class="modal-body">
                <p class="text-secondary m-0">{slides[selected].description}</p>
                <h4>{slides[selected].title}</h4>
                
                <p class="lead">{slides[selected].lead}</p>
                <div class="container">
                    <div class="row">
                        {#each slides[selected].cards as card}
                        <div class="col-4 mb-4" transition:fade="{{delay: 250, duration: 300}}">
                            <div class="card text-dark bg-light shadow border-0">
                                {#if card.image}
                                    <img src="{card.image}" class="card-img-top" alt='Help Top'>
                                {/if}
                                <div class="card-body">
                                    <h4 class="fw-bold card-title">{card.title}</h4>
                                    <h6 class="fw-bold card-subtitle mb-2">{card.sub}</h6>
                                    <p class="card-text">{card.description}</p>
                                    {#if card.link}
                                        <button class="btn btn-outline-primary w-100" type="button" on:click={()=>{isNaN(card.buttonLink) ? modalState.showModal(card.buttonLink) : selected = card.buttonLink}}>{card.buttonText}</button>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="p-3 border-top border-light">
                <div class="float-start">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="hideCheck" bind:checked={hide}>
                        <label class="form-check-label" for="hideCheck">Hide on startup</label>
                    </div>
                </div>                
                    <div class="float-end">                          
                        <button type="button" class="btn btn-primary me-2" on:click="{() => hideModal()}">Close</button>
                        <a class="btn btn-outline-dark" href="https://www.closedcaptioncreator.com/releases.html" target="_blank" rel="noreferrer">Learn More</a>
                    </div>                
            </div>
        </div>
    </div>
</div>
