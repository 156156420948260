<script>
    import Swal from "sweetalert2";
    import { v4 as uuidv4 } from "uuid";
    import { onMount } from "svelte";
    import { BarLoader } from "svelte-loading-spinners";
    import { toast } from "@zerodevx/svelte-toast";

    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import "firebase/functions";

    let keys = [];
    let gettingKeys;
    let loading = false;
    let loadingMsg = "Work in progress. Please wait...";
    let userId = firebase.auth().currentUser.uid;
    let dbRef = db.collection("users").doc(userId).collection("keys");

    onMount(() => {
        refreshKeys();
    });

    function refreshKeys() {
        gettingKeys = getKeys();
    }

    async function getKeys() {
        try {
            keys = [];
            loading = true;
            loadingMsg = "Loading API Keys. Please wait...";
            let res = await firebase.functions().httpsCallable("v8GetApiKeys")();
            keys = res.data || [];
            return keys;
        } catch (error) {
            toast.push(error.message, {
                classes: ["toast-danger"],
            });

            console.log(error);
            console.log(error.message);
            throw new Error(error.message);
        } finally {
            loading = false;
        }
    }

    async function createKey() {
        try {
            //Use Swal to get the name of the key
            let res = await Swal.fire({
                titleText: "New API Key",
                showCancelButton: true,
                confirmButtonText: "Create",
                input: "text",
                inputLabel: "Key Name",
                inputPlaceholder: "Enter a name for the key",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-outline-secondary",
                },
            });

            if (res.isConfirmed && res.value) {
                let keyData = {
                    id: uuidv4(),
                    name: res.value,
                    key: null,
                    role: "admin",
                };

                loading = true;
                loadingMsg = "Creating new API Key. Please wait...";
                await firebase.functions().httpsCallable("v8CreateApiKey")(
                    keyData,
                );
                refreshKeys();
            }
        } catch (err) {
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
            console.log(err);
            console.log(err.message);
            refreshKeys();
        } finally {
            loading = false;
        }
    }

    async function removeKey(id) {
        try {
            let res = await Swal.fire({
                titleText: "Delete API Key",
                text: "Are you sure you want to delete this key?",
                showCancelButton: true,
                confirmButtonText: "Delete",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-danger me-2",
                    cancelButton: "btn btn-outline-secondary",
                },
            });

            if (res.isConfirmed) {
                loading = true;
                loadingMsg = "Deleting API Key. Please wait...";
                await firebase.functions().httpsCallable("v8DeleteApiKey")(id);
                refreshKeys();
            }
        } catch (err) {
            toast.push(err.message, {
                classes: ["toast-danger"],
            });

            console.log(err);
            console.log(err.message);
            refreshKeys();
        } finally {
            loading = false;
        }
    }

    async function editKey(id) {
        try {
            //Use Swal to get the name of the key
            let res = await Swal.fire({
                titleText: "New API Key",
                showCancelButton: true,
                confirmButtonText: "Update",
                input: "text",
                inputLabel: "Key Name",
                inputValue : keys.find(key => key.id === id).name,
                inputPlaceholder: "Enter a name for the key",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-outline-secondary",
                },
            });

            if (res.isConfirmed && res.value) {
                let keyData = {
                    id: id,
                    name: res.value,
                    role: "admin",
                };

                loading = true;
                loadingMsg = "Updating API Key. Please wait...";
                await firebase.functions().httpsCallable('v8UpdateApiKey')( keyData );
                refreshKeys();
            }
        } catch (err) {
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
            console.log(err);
            console.log(err.message);
            refreshKeys();
        } finally {
            loading = false;
        }
    }
</script>

{#await gettingKeys}
    <BarLoader size="250" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
    <p>Loading API Keys... Please wait.</p>
{:then}
    {#if loading}
        <BarLoader size="250" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
        <p>{loadingMsg}</p>
    {:else}
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Key</th>
                    <th>Created On</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {#each keys as key}
                    <tr>
                        <td>{key.name}</td>
                        <td class="font-monospace"
                            >{key.hidden ? "*****************************" : key.key}
                            {#if !key.hidden}
                            <button
                                title="Copy to clipboard"
                                class="btn btn-secondary float-end"
                                type="button"
                                disabled={loading}
                                on:click={() => {
                                    navigator.clipboard.writeText(key.key);
                                    toast.push("Key copied to clipboard", {
                                        classes: ["toast-success"],
                                    });
                                    key.hidden = true;
                                }}
                            >
                                <i class="bi bi-copy"></i>
                            </button>
                            {:else}
                            <button
                                class="btn btn-primary float-end"
                                type="button"
                                disabled={loading}
                                on:click={() => {
                                    key.hidden = false;
                                }}
                            >
                                <i class="bi bi-eye"></i>
                            </button>
                            {/if}
                        </td>
                        <td>{key.createdOn.substring(0, 24)}</td>
                        <td>
                            <div class="btn-group" role="group">
                                <button
                                class="btn btn-secondary"
                                type="button"
                                disabled={loading}
                                on:click={editKey(key.id)}
                            >
                                <i class="bi bi-pen"></i>
                            </button>
                            <button
                                class="btn btn-outline-danger"
                                type="button"
                                disabled={loading}
                                on:click={removeKey(key.id)}
                            >
                                <i class="bi bi-trash"></i>
                            </button>
                            </div>
                            
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
    {/if}
{:catch error}
    <p class="text-danger">{error.message}</p>
    <p>Please contact support for more information.</p>
{/await}
<div class="btn-group" role="group">
    <button class="btn btn-primary" type="button" disabled={loading} on:click={() => createKey()}
        >New API Key</button
    >
</div>
