import _quillClasses from "../dict/quillClasses.js";
import _hex2ascii from "hex2ascii";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const hex2ascii = _hex2ascii;
const quillClasses = _quillClasses;
function decodeTc(tcHex, frameRate, dropFrame) {
  let tc = "";
  /* remove first values... we don't know what they're for */
  tcHex.splice(0, 3);
  tcHex.forEach(hexCode => {
    tc += hex2ascii(hexCode);
  });
  return tcLib.tcValidate(tc, frameRate, dropFrame);
}
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent = new Event({
        alignment: "left"
      }),
      hexCodes = input.match(/(..?)/g),
      hexCode,
      tc,
      tcHex,
      tcStartFlag = false,
      metadata,
      lastPosition,
      header = hexCodes.splice(0, 256);
    // console.log(header);
    // console.log(hexCodes);

    while (hexCodes.length > 0) {
      hexCode = hexCodes.shift();
      if (hexCode === "fe") {
        /* Timecode */
        tcHex = hexCodes.splice(0, 11);
        tc = decodeTc(tcHex, options.frameRate, options.dropFrame);
        if (tcStartFlag) {
          ccEvent.end = tcLib.tcToSec(tc, options.frameRate);
          ccEvent.text = convertToHtml(ccEvent.text, [quillClasses.align[ccEvent.alignment]]);
          //console.log(ccEvent.text);
          events.push(ccEvent);
          ccEvent = new Event({
            alignment: "left"
          });
          tcStartFlag = false;
          lastPosition = false;
        } else {
          ccEvent.start = tcLib.tcToSec(tc, options.frameRate);
          tcStartFlag = true;
        }
      } else if (hexCode === "0d" && hexCodes[0] === "0a") {
        /* Line break */
        hexCodes.shift();
      } else if (hexCode < 20) {
        //console.log(hexCode + hexCodes[0]);                    
        metadata = hexCode + hexCodes[0];
        if (metadata < 1999) {
          if (!tcStartFlag && events.length > 0) {
            ccEvent.start = events[events.length - 1].end;
            tcStartFlag = true;
          }
        }
        if (metadata == "1420") {
          /* Pop-on captions? */
        }
        if (metadata == "112e") {
          /* Italics */
          ccEvent.text += "<em>";
        }
        if (metadata == "144e" || metadata == "134e" || metadata == "146e" || metadata == "136e") {
          /* Italics */
          ccEvent.text += "\n<em>";
        }
        if ((hexCode == "14" || hexCode == "13") && hexCodes[0] >= 50 && hexCodes[0] <= 53) {
          ccEvent.xPos = "start";
          ccEvent.yPos = "end";
          if (metadata !== lastPosition) {
            ccEvent.text += "\n";
          }
          lastPosition = metadata;
        } else if ((hexCode == "14" || hexCode == "13") && hexCodes[0] >= 70 && hexCodes[0] <= 73) {
          ccEvent.xPos = "start";
          ccEvent.yPos = "end";
          if (metadata !== lastPosition) {
            ccEvent.text += "\n";
          }
          lastPosition = metadata;
        } else if ((hexCode == "14" || hexCode == "13") && hexCodes[0] >= 54 && hexCodes[0] <= 55) {
          ccEvent.xPos = "center";
          ccEvent.yPos = "end";
          if (metadata !== lastPosition) {
            ccEvent.text += "\n";
          }
          lastPosition = metadata;
        } else if ((hexCode == "14" || hexCode == "13") && hexCodes[0] >= 74 && hexCodes[0] <= 75) {
          ccEvent.xPos = "center";
          ccEvent.yPos = "end";
          if (metadata !== lastPosition) {
            ccEvent.text += "\n";
          }
          lastPosition = metadata;
        } else if ((hexCode == "14" || hexCode == "13") && hexCodes[0] >= 56 && hexCodes[0] <= 58) {
          ccEvent.xPos = "end";
          ccEvent.yPos = "end";
          if (metadata !== lastPosition) {
            ccEvent.text += "\n";
          }
          lastPosition = metadata;
        } else if ((hexCode == "14" || hexCode == "13") && hexCodes[0] >= 76 && hexCodes[0] <= 78) {
          ccEvent.xPos = "end";
          ccEvent.yPos = "end";
          if (metadata !== lastPosition) {
            ccEvent.text += "\n";
          }
          lastPosition = metadata;
        }

        /* Remove next code */
        hexCodes.shift();
      } else {
        //console.log(hexCode);
        ccEvent.text += hex2ascii(hexCode);
      }
    }
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Closed Caption Converter does not support writing Caption Inc files. Please contact us to have Caption Inc support added.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};