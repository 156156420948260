import _nciFunc from "../functions/profiles/nciCaption.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const nciFunc = _nciFunc;
export default {
  decode: function (input, options) {
    let events = [],
      hexCodes = input.match(/(..?)/g),
      hexCode,
      header = hexCodes.splice(0, 128),
      version = nciFunc.getVersion(header.slice(0, 8)),
      nciBlocks = [],
      nciBlock,
      contFlag = false,
      timecodes = [],
      tcStart,
      tcEnd,
      compareTc,
      timingHexCodes = [[]],
      textSectionFlag = false,
      tcSectionFlag = false,
      lastId = false;

    //console.log(JSON.stringify(header, null, 4));
    //console.log(hexCodes);
    /* Parse For Text Events */
    let skipValue = 1;
    for (let i = 0; i < hexCodes.length; i += skipValue) {
      if (!textSectionFlag) {
        if (hexCodes[i] == "ff" && hexCodes[i + 1] == "ff" && hexCodes[i + 3] != "ff" && hexCodes[i - 1] != "ff" && hexCodes[i + 64] == "ff" && hexCodes[i + 65] == "ff" && hexCodes[i + 66] != "ff" && hexCodes.slice(i + 2, i + 6).join("") != "00000000") {
          //console.log("TEXT SECTION START: "+i);
          textSectionFlag = true;
          skipValue = 64;
          if (!contFlag) {
            nciBlock = new nciFunc.Block();
            nciBlock.insertLine({
              xPos: hexCodes[i + 3],
              yPos: hexCodes[i + 2]
            });
          }
          for (let j = contFlag ? 2 : 4; j < 64; j++) {
            if (hexCodes[i + j] === "00") {
              nciBlocks.push(nciBlock);
              contFlag = false;
              break;
            } else if (hexCodes[i + j] === "0d") {
              nciBlock.insertLine({
                xPos: hexCodes[i + j + 2],
                yPos: hexCodes[i + j + 1]
              });
              hexCodes[i + j + 2] = "20";
              hexCodes[i + j + 1] = "20";
            } else if (hexCodes[i + j] === "87") {
              nciBlock.lines[nciBlock.lines.length - 1].text += "♪";
              if (hexCodes[i + j + 1] === "a0") {
                hexCodes[i + j + 1] = 20;
              }
            } else if (hexCodes[i + j] !== "ff") {
              nciBlock.lines[nciBlock.lines.length - 1].text += nciFunc.decodeChar(hexCodes[i + j], "28591");
              if (j === 63) {
                contFlag = true;
              }
            }
          }
        }
      } else {
        //console.log(hexCodes.slice(i,i+64));
        if (!contFlag) {
          nciBlock = new nciFunc.Block();
          nciBlock.insertLine({
            xPos: hexCodes[i + 3],
            yPos: hexCodes[i + 2]
          });
        }
        for (let j = contFlag ? 2 : 4; j < 64; j++) {
          if (hexCodes[i + j] === "00") {
            nciBlocks.push(nciBlock);
            contFlag = false;
            break;
          } else if (hexCodes[i + j] === "0d") {
            nciBlock.insertLine({
              xPos: hexCodes[i + j + 2],
              yPos: hexCodes[i + j + 1]
            });
            hexCodes[i + j + 2] = "20";
            hexCodes[i + j + 1] = "20";
          } else if (hexCodes[i + j] === "87") {
            nciBlock.lines[nciBlock.lines.length - 1].text += "♪";
            if (hexCodes[i + j + 1] === "a0") {
              hexCodes[i + j + 1] = 20;
            }
          } else if (hexCodes[i + j] !== "ff") {
            nciBlock.lines[nciBlock.lines.length - 1].text += nciFunc.decodeChar(hexCodes[i + j], "28591");
            if (j === 63) {
              contFlag = true;
            }
          }
        }
      }
    }

    //console.log(JSON.stringify(nciBlocks, null, 4));
    while (hexCodes.length > 0) {
      hexCode = hexCodes.shift();
      if (hexCode === "ff" && hexCodes.slice(0, 3).join("") === "ffffff") {
        tcSectionFlag = true;
        hexCodes.splice(0, 3);
        timingHexCodes.push([]);
      } else if (tcSectionFlag) {
        timingHexCodes[timingHexCodes.length - 1].push(hexCode);
      }
    }
    timingHexCodes.pop();
    //console.log(JSON.stringify(timingHexCodes, null, 4));
    timingHexCodes.forEach((tcSection, index, tcSections) => {
      if (tcSection.join("") === "ffff00000000" || tcSection.join("") === "") {
        return;
      } /* else {
          console.log("------TC Section-----");
          console.log(tcSection.join())
        } */

      while (tcSection.length > 0) {
        let tcId = tcSection.splice(0, 2);
        //console.log(`Timecode Id: ${tcId[0]} | ${parseInt(tcId[0], 16)}`);
        let tcMetadata = tcSection.splice(0, 4);
        //console.log(`TC Metadata: ${tcMetadata}`);

        if (lastId && parseInt(tcId[0], 16) < lastId && lastId !== 255) {
          // console.log("ERROR: NOT IN SEQUENCE");
          // console.log(lastId, parseInt(tcId[0], 16))
          return;
        } else {
          lastId = parseInt(tcId[0], 16);
        }
        if (tcSection.length === 4) {
          tcStart = nciFunc.decodeTc(tcSection.splice(0, 4).join(""), options.frameRate);
          timecodes.push({
            start: tcStart,
            end: false
          });
        } else if (tcSection.length > 4) {
          tcStart = nciFunc.decodeTc(tcSection.splice(0, 4).join(""), options.frameRate);
          tcEnd = nciFunc.decodeTc(tcSection.splice(0, 4).join(""), options.frameRate);
          timecodes.push({
            start: tcStart,
            end: tcEnd
          });
        }
      }
    });
    timecodes.forEach((timecode, index, timecodesRef) => {
      if (!nciBlocks[index]) {
        return;
      }
      if (timecode.start !== false) {
        nciBlocks[index].start = timecode.start;
      }
      if (timecode.end !== false) {
        nciBlocks[index].end = timecode.end;
      } else if (timecodesRef[index + 1]) {
        nciBlocks[index].end = timecodesRef[index + 1].start;
      } else {
        nciBlocks[index].end = parseFloat(timecode.start) + 2;
      }
    });
    nciBlocks.forEach(block => {
      events.push(nciFunc.decodeBlock(block, options));
    });

    //console.log(nciBlocks.length, timecodes.length);
    //console.log(JSON.stringify(timecodes,null,4));
    //console.log(JSON.stringify(events,null,4));
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("NCI Caption files are not supported for encoding by Closed Caption Converter. You can request this feature by contacting support@closedcaptioncreator.com");
  },
  preProcess: {
    encode: function (eventGroup, options) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input, options) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};