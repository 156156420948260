<script>
    import { fade } from "svelte/transition";
    import { environment } from "@app/store/envStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { autoReplaceState } from "@app/store/autoReplaceStore.js";
    import { toast } from '@zerodevx/svelte-toast';
    import firebase from '@app/configs/firebase.js';
    import {v4 as uuidv4} from 'uuid';
    let userId = $environment.online ? firebase.auth().currentUser.uid : null, selectedList = $autoReplaceState.length > 0 ? $autoReplaceState[0] : null, selectedReplacement, shortformInput = "", replacementInput = "";


    function selectList(list){
        selectedList = list;
        cancelEdit();
    }
    
    function createList(){
        autoReplaceState.createList(
            {
                id : uuidv4(),
                name : "Unknown",
                enabled : true,
                uppercase : false,
                replacements : {}
            }, 
            userId
        );

        selectedList = $autoReplaceState[$autoReplaceState.length-1];

        toast.push("New list created successfully", {
            classes : ["toast-success"]
        });
    }

    function removeList(listId){        
        autoReplaceState.removeList(listId, userId);     
        selectedList = $autoReplaceState[0];   

        toast.push("List removed successfully", {
            classes : ["toast-success"]
        });
    }

    function updateList(listInfo){
        autoReplaceState.updateList(listInfo, userId);

        toast.push("List updated successfully", {
            classes : ["toast-success"]
        });
    }

    function toggleList(listInfo){
        updateList({...listInfo, enabled : !listInfo.enabled});

        if (selectedList.id ===  listInfo.id){
            selectedList.enabled = !selectedList.enabled;
        }
    }

    function addShortform(){
        selectedList.replacements[shortformInput] =  replacementInput;
        updateList(selectedList);
        resetInput();
    }

    function updateReplacement(replacementValue){
        shortformInput = replacementValue;
        replacementInput = selectedList.replacements[replacementValue];
        selectedReplacement = replacementValue;
    }

    function removeReplacement(replacementValue){
        delete selectedList.replacements[replacementValue];
        selectedList = selectedList;
        updateList(selectedList);
        resetInput();
    }

    function updateShortform(){
        delete selectedList.replacements[selectedReplacement];
        selectedList.replacements[shortformInput] =  replacementInput;
        updateList(selectedList);
        cancelEdit();
    }

    function cancelEdit(){
        resetInput();
        selectedReplacement = undefined;
    }

    function resetInput(){
        shortformInput = "";
        replacementInput = "";
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'autoReplace' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="AutoReplaceModal">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Auto Replacement</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                />
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 border-end border-secondary">
                        <div class="d-flex justify-content-end mb-2">
                            <button type="button" class="btn btn-primary btn-sm me-2" title="New List" on:click={createList}><i class="bi bi-plus-lg"></i> New</button>

                            <button type="button" class="btn btn-danger btn-sm {selectedList ? '' : 'disabled'}" title="Remove List" on:click="{() => removeList(selectedList.id)}"><i class="bi bi-x-lg"></i> Remove</button>                          
                        </div>
                        <ul class="nav nav-pills flex-column">
                            {#await $autoReplaceState}
                                <p>Loading lists... please wait...</p>
                            {:then}                                
                            {#each $autoReplaceState as list (list.id)}
                                <li class="nav-item">
                                    <a class="nav-link {selectedList && selectedList.id === list.id ? 'active' : ''}" href="#!/" on:click={() => selectList(list)}>{list.name} <input class="form-check-input float-end" type="checkbox" checked={list.enabled} on:change={toggleList(list)} title="Enable/Disable"></a>
                                </li>
                                {:else}
                                    <p class="text-muted small text-center mt-5">Please create a new list to start</p>
                            {/each}
                            {/await}
                        </ul>                        
                    </div>
                    <div class="col">
                        {#if selectedList}
                        <form on:submit|preventDefault="{()=>{updateList(selectedList)}}">
                            <div class="row">
                                <div class="col-2">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="EnableListCheck" bind:checked={selectedList.enabled}/>
                                        <label class="form-check-label" for="EnableListCheck">Enable</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="AutoUppercaseCheck" bind:checked={selectedList.uppercase}/>
                                        <label class="form-check-label" for="AutoUppercaseCheck">Auto Uppercase <i class="bi bi-info-circle" title='Auto capitalize words at the beginning of sentences'></i></label>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="my-2">
                                <label for="listNameInput" class="form-label"
                                    >List Name</label
                                >
                                <input type="text" class="form-control" bind:value={selectedList.name}/>
                            </div>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-primary" on:click={updateList(selectedList)}>Save Changes</button>                    
                            </div>
                        </form>
                        <hr/>
                        <!-- Shortforms Table -->
                        <p class="text-warning small"><i class="bi bi-exclamation-circle"></i> Avoid the use of real words or abbreviations. They will automatically be replaced.</p>
                        <div class="table-responsive">
                            <table class="table table-light table-sm">
                                <thead>
                                    <tr>                                        
                                        <th scope="col">Shortform</th>
                                        <th scope="col">Replacement</th>
                                        <th scope="col"></th>
                                    </tr>
                                    <tr>                                        
                                        <td class='p-0'>
                                            <input type="text" bind:value={shortformInput} class="form-control border-0 rounded-0 bg-secondary text-dark" placeholder="Shortform Text (e.g. hw)">
                                        </td>
                                        <td class='p-0'>
                                            <input type="text" bind:value={replacementInput} class="form-control border-0 rounded-0 bg-secondary text-dark" placeholder="Replacement Text (e.g. hello world)">
                                        </td>
                                        <td>
                                            {#if selectedReplacement}
                                                <button class="btn btn-sm btn-outline-info me-1 {shortformInput && replacementInput ? '' : 'disabled'}" on:click={() => updateShortform()} type="button" title="Update Shortform">Update Shortform</button>

                                                <button class="btn btn-sm btn-outline-dark" on:click={() => cancelEdit()} type="button" title="Cancel Update">Cancel</button>
                                            {:else}
                                                <button class="btn btn-sm btn-outline-primary w-100 {shortformInput && replacementInput ? '' : 'disabled'}" on:click={() => addShortform()} type="button" title="Add Shortform To List">Add Shortform</button>
                                            {/if}
                                        </td>                        
                                    </tr>              
                                </thead>
                                <tbody>                                                        
                                    {#each Object.keys(selectedList.replacements).sort() as replacement}
                                        <tr>                                        
                                            <td>{replacement}</td>
                                            <td>{selectedList.replacements[replacement]}</td>
                                            <td>
                                                <button class="btn btn-info btn-sm me-1" type="button" title="Edit" on:click={()=> updateReplacement(replacement)}><i class="bi bi-pencil"></i> Edit</button>
                                                <button class="btn btn-danger btn-sm" type="button" title="Delete" on:click={() => removeReplacement(replacement)}><i class="bi bi-x-lg"></i></button>
                                            </td>
                                        </tr>
                                    {/each}                                    
                                </tbody>
                            </table>
                        </div>
                        {:else}
                        <p class="text-center text-muted lead">Please select a list from the menu on the left</p>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<style>
    tbody {
        display: block;
        height: 250px;
        overflow: auto;
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
    }
</style>