<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();
function mergeEvents() {
    try {
        if ($eventGroupState[$projectState.selected].selected.length < 2) {
            return;
        }
        
        let text = "";
        let sortedEvents = $eventGroupState[$projectState.selected].selected.sort((a, b) => {
            return b - a;
        });
        
        sortedEvents.forEach((eventIndex, selectedIndex) => {
            text = $eventGroupState[$projectState.selected].events[eventIndex].text + text;
            if (selectedIndex < sortedEvents.length - 1) {
                $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected],eventIndex);
            }
        });

        $eventGroupState[$projectState.selected].events[sortedEvents[sortedEvents.length - 1]].text = text;

        $eventGroupState[$projectState.selected].selected = [];

        historyState.insert({
            name: "merge events", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }
}
</script>

<a
    id="mergeEventsBtn"
    class="dropdown-item"
    role="button"
    aria-label="Merge Events"
    href="#!/"
    on:click={mergeEvents}>
    Merge
</a>
