<script>
	import { modalState } from "@app/store/modalStore.js";
	import { voiceState } from "@app/store/voiceStore.js";
	import { toast } from '@zerodevx/svelte-toast';
	import { fade } from "svelte/transition";
	import { onMount } from "svelte";
	import { Circle } from "svelte-loading-spinners";
	import voiceLib from "@app/external/cc-lib/dist/providers/textToSpeechAll/voices.js";
	/* Firebase */
	import firebase from "@app/configs/firebase.js";
	import "firebase/compat/functions";

	let userId = firebase.auth().currentUser.uid;
	let serviceProvider = "all",
		gender = "all",
		language = "English - US",
		rendering = false,
		audioPlayer,
		selectedVoice,
		previewText = "John walks into the bar to see Alice already waiting for him. She sips her drink nervously.";

	onMount(() => {
		try {
			audioPlayer = document.getElementById("adPreviewPlayer");
			loadElevenLabsVoices();
		} catch (err) {
			console.log(err, err.message);
		}
	});

	function saveVoicesToLocalStorage() {
		localStorage.setItem("cc-voice-defaults", JSON.stringify($voiceState));
	}

	function pinVoice(v) {
		$voiceState = [...$voiceState, v];
		toast.push(`${v.name} pinned successfully`, {classes: ['toast-success']});

		saveVoicesToLocalStorage();
	}

	function unPinVoice(v) {
		$voiceState = $voiceState.filter((voice) => {
			return voice.name !== v.name;
		});

		toast.push(`${v.name} un-pinned successfully`, {classes: ['toast-success']});

		saveVoicesToLocalStorage();
	}

	async function previewVoice(v) {
		rendering = true;
		selectedVoice = v;
		try {
			rendering = true;
			if (selectedVoice.preview_url) {
				audioPlayer.src = selectedVoice.preview_url;
				audioPlayer.load();
				audioPlayer.play();
			} else {
				let res = await firebase.functions().httpsCallable("v8TextToSpeechProviderV4")({
					provider: v.provider,
					language: v.language,
					voice: v.name,
                    voice_id : v.id,
                    stability : 0.5,
                    similarity : 0.5,
					speed: 1,
					pitch: 0,
					style: "default",
					audioEncoding: "MP3",
					preview: true,
					text: previewText || "John walks into the bar to see Alice already waiting for him. She sips her drink nervously.",
				});
				console.log(res);
				if (res.data) {
					audioPlayer.src = res.data.audio;
					audioPlayer.load();
					audioPlayer.play();
				} else {
					throw new Error("No data found in response");
				}
			}
		} catch (err) {
			console.log(err, err.message);
			toast.push(`Audio Rendering Failed. ${err.message}`, {classes: ['toast-danger']});
		} finally {
			rendering = false;
		}
	}

	async function loadElevenLabsVoices() {
		let elevenLabsVoices = await firebase.functions().httpsCallable("v8ElevenLabsGetVoices")(userId);

		elevenLabsVoices.data.forEach((elevenLabsVoice) => {
			let gender = elevenLabsVoice.labels && elevenLabsVoice.labels.gender === "female" ? "female" : "male";
			voiceLib.ElevenLabs[gender] = [
				...voiceLib.ElevenLabs[gender],
				{
					"provider": "ElevenLabs",
					"id": elevenLabsVoice.voice_id,
					"name": elevenLabsVoice.name,
					"styles": ["default", "eleven_multilingual_v2"],
					"preview_url": elevenLabsVoice.preview_url,
					"gender": gender,
					"language": "ElevenLabs",
					"high_quality_base_model_ids": elevenLabsVoice.high_quality_base_model_ids,
				},
			];
		});
	}
</script>

<div 
    transition:fade={{ duration: 100 }} 
    class="modal {$modalState === 'voiceManager' ? 'show d-block' : ''}" 
    role="dialog" 
    tabindex="-1" 
    id="VoiceManagerModal"
    aria-labelledby="voiceManagerTitle"
>
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="voiceManagerTitle">Virtual Voice Manager</h4>
                <button type="button" class="btn-close" id="voiceManagerClose" aria-label="Close" on:click={modalState.hideModal} />
                <audio id="adPreviewPlayer" class="d-none" autobuffer="autobuffer" preload="metadata">
                    <source src="" type="audio/wav" />
                </audio>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-2">
                        <p class="lead" id="filterSection">Filter</p>
                        <form aria-labelledby="filterSection">
                            <div class="mb-3">
                                <label class="form-label" for="LanguageSelectDropdown">Language</label>
                                <select class="form-select" id="LanguageSelectDropdown" bind:value={language}>
                                    {#each Object.keys(voiceLib) as lang}
                                        <option>{lang}</option>
                                    {/each}
                                </select>
                            </div>
                            <div class="mb-3">
								<label class="form-label" for="genderDropdown">Gender</label>
								<select class="form-select" id="genderDropdown" bind:value={gender}>
									<option value="all">All</option>
									<option value="male">male</option>
									<option value="female">female</option>
								</select>
							</div>
							<div class="mb-3">
								<label class="form-label" for="serviceProviderDropdown">Service Provider</label>
								<select class="form-select" id="serviceProviderDropdown" bind:value={serviceProvider}>
									<option value="all">All</option>
									<option value="amazon">Amazon</option>
									<option value="microsoft">Microsoft</option>
									<option value="google">Google</option>
									<option value="ElevenLabs">ElevenLabs</option>
								</select>
							</div>
							<hr />
							<div class="mb-3">
								<label class="form-label" for="previewTextInput">Preview Text <span class="small text-muted">({previewText.length}/100)</span></label>
								<textarea bind:value={previewText} class="form-control text-muted" rows="5" maxlength="100" />
							</div>
                        </form>
                    </div>
                    <div class="col-7">
                        <p class="lead" id="allVoicesSection">All Voices</p>
                        <div class="container rounded pt-3 scrollArea" role="region" aria-labelledby="allVoicesSection">
                            <div class="row g-3">
                                {#if gender === "all" || gender === "male"}
                                    {#each voiceLib[language].male.filter((v) => {
                                        return serviceProvider === "all" || serviceProvider === v.provider;
                                    }) as voice}
                                        <div class="col-4">
                                            <div class="card border-secondary">
                                                <div class="card-body">
                                                    <h5 class="card-title">
                                                        <i class="bi bi-person-circle" aria-hidden="true" /> {voice.name}
                                                    </h5>
                                                    <h6 class="card-subtitle mb-2 text-muted small">male</h6>
                                                    {#if $voiceState.findIndex((v) => v.name === voice.name) > -1}
                                                        <button type="button" 
                                                            id="unpin_{voice.name}" 
                                                            class="btn btn-primary btn-sm" 
                                                            aria-label="Unpin {voice.name}" 
                                                            on:click={() => unPinVoice(voice)}>
                                                            <i class="bi bi-pin-angle-fill" aria-hidden="true" />
                                                        </button>
                                                    {:else}
                                                        <button type="button" 
                                                            id="pin_{voice.name}" 
                                                            class="btn btn-light btn-sm" 
                                                            aria-label="Pin {voice.name}" 
                                                            on:click={() => pinVoice(voice)}>
                                                            <i class="bi bi-pin" aria-hidden="true" />
                                                        </button>
                                                    {/if}
                                                    <button type="button" 
                                                        id="preview_{voice.name}" 
                                                        class="btn btn-light btn-sm" 
                                                        aria-label="Preview {voice.name}" 
                                                        on:click={() => previewVoice(voice)}>
                                                        {#if rendering && selectedVoice.name === voice.name}
                                                            <Circle size="15" color="#1eb4b2" unit="px" duration="1s" />
                                                        {:else}
                                                            <i class="bi bi-play-circle" aria-hidden="true" />
                                                        {/if}
                                                    </button>
                                                    <p class="mt-2 mb-1">
														{#each voice.styles as style}
															<span class="badge bg-light me-1">{style}</span>
														{/each}
													</p>
                                                </div>
                                            </div>
                                        </div>
                                    {/each}
                                {/if}
                                {#if gender === "all" || gender === "female"}
									{#each voiceLib[language].female.filter((v) => {
										return serviceProvider === "all" || serviceProvider === v.provider;
									}) as voice}
										<div class="col-4">
											<div class="card border-secondary">
												<div class="card-body">
													<h5 class="card-title"><i class="bi bi-person-circle" /> {voice.name}</h5>
													<h6 class="card-subtitle mb-2 text-muted small">female</h6>
													{#if $voiceState.findIndex((v) => {
														return v.name === voice.name;
													}) > -1}
														<button type="button" title="Unpin" class="btn btn-primary btn-sm" on:click={() => unPinVoice(voice)}><i class="bi bi-pin-angle-fill" /></button>
													{:else}
														<button type="button" title="Pin Voice" class="btn btn-light btn-sm" on:click={() => pinVoice(voice)}><i class="bi bi-pin" /></button>
													{/if}
													<button type="button" class="btn btn-light btn-sm" on:click={() => previewVoice(voice)}>
														{#if rendering && selectedVoice.name === voice.name}
															<Circle size="15" color="#1eb4b2" unit="px" duration="1s" />
														{:else}
															<i class="bi bi-play-circle" />
														{/if}
													</button>
													<p class="mt-2 mb-1">
														{#each voice.styles as style}
															<span class="badge bg-light me-1">{style}</span>
														{/each}
													</p>
												</div>
											</div>
										</div>
									{/each}
								{/if}
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <p class="lead" id="pinnedVoicesSection">Pinned Voices</p>
                        <div class="container rounded py-3 scrollArea" role="region" aria-labelledby="pinnedVoicesSection">
                            <ul class="list-group">
								{#each $voiceState as pinnedVoice}
									<li class="list-group-item">
										{pinnedVoice.name} <button type="button" title="Unpin" class="btn btn-primary btn-sm float-end" on:click={() => unPinVoice(pinnedVoice)}><i class="bi bi-pin-angle-fill" /></button>
										<p class="text-muted small m-0 text-capitalize">{pinnedVoice.gender} | {pinnedVoice.provider}</p>
									</li>
								{/each}
							</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
	.scrollArea {
		height: 500px;
		overflow-y: auto;
		background-color: rgba(0, 0, 0, 0.1);
	}
</style>
