<script>
    import {
        modalState
    } from '@app/store/modalStore.js';
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import {
        historyState
    } from '@app/store/historyStore.js';
    import {
        projectState
    } from '@app/store/projectStore.js';
    import { toast } from '@zerodevx/svelte-toast';
    import {
        fade
    } from 'svelte/transition';
    
    let srcGroupId = "", trgGroupId = "";

    function copyTimecodesFromGroup(){
        try {
            let srcIndex = $eventGroupState.findIndex(eventGroup =>{
            return eventGroup.id === srcGroupId;
            });

            let trgIndex = $eventGroupState.findIndex(eventGroup =>{
                return eventGroup.id === trgGroupId;
            });

            if (srcIndex === trgIndex){
                throw new Error("Source and target event groups match. Nothing to copy.");
            }
            
            $eventGroupState[srcIndex].events.forEach((event, index) => {
                if ($eventGroupState[trgIndex].events[index]){
                    $eventGroupState[trgIndex].events[index].start = event.start;
                    $eventGroupState[trgIndex].events[index].end = event.end;
                }
            });

            toast.push("Timecodes copied successfully", {
                classes: ["toast-success"]
            });

            historyState.insert({
                name: "copy timecode", //action name
                eventGroup: $projectState.selected,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });
        } catch(err){
            console.log(err, err.message);

            toast.push("Timecode copy failed", {
                classes: ["toast-danger"]
            });
        } finally {
            modalState.hideModal();
        }
    }
    
    </script>
    
    <div transition:fade="{{duration: 100}}" class="modal {$modalState === 'copyTcFromGroup' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="copyTcModalTitle"
    aria-describedby="copyTcModalDesc"
    tabindex="-1" 
    id="CopyTcModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="copyTcModalTitle">TC Copy</h4>
                <button type="button" class="btn-close" id="closeTcCopyBtn" aria-label="Close dialog" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p id="copyTcModalDesc" class="visually-hidden">Copy timecodes from one group to another</p>
                <form on:submit|preventDefault="{copyTimecodesFromGroup}">
                    <div class="mb-3">
                        <label class="form-label" for="sourceGroupSelect">Source Group</label>
                        <select class="form-select" id="sourceGroupSelect" bind:value="{srcGroupId}" 
                            aria-describedby="sourceGroupHelp">
                            {#each $eventGroupState as eventGroupOption}
                                <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                            {/each}
                        </select>
                        <div id="sourceGroupHelp" class="form-text">Select the group to copy timecodes from</div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="targetGroupSelect">Target Group</label>
                        <select class="form-select" id="targetGroupSelect" bind:value="{trgGroupId}"
                            aria-describedby="targetGroupHelp">
                            {#each $eventGroupState as eventGroupOption}
                                <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                            {/each}
                        </select>
                        <div id="targetGroupHelp" class="form-text">Select the group to copy timecodes to</div>
                    </div>
                    <button class="btn btn-primary float-end" id="copyTcBtn" type="button" 
                        on:click="{copyTimecodesFromGroup}"
                        aria-label="Copy timecodes">Copy</button>
                </form>
            </div>
        </div>
    </div>
</div>
