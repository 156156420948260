<script>
    import { projectState } from "@app/store/projectStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import { fade } from "svelte/transition";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import _Marker from "@app/external/cc-lib/dist/classes/marker.js";
    import { v4 as uuidv4 } from "uuid";
    let files,
        srcExt = "",
        srcFileName = "",
        fileContents = "",
        markerGroup = "new",
        offsetTimecode = false,
        importOption = "replace",
        markerGroupName = "Untitled";

    const hexColors = [
        "#ff66cc",
        "#00cc00",
        "#0066ff",
        "#6600ff",
        "#ff0066",
        "#6666ff",
        "#00cc99",
        "#99ccff",
        "#ff9966",
        "#ff33cc",
        "#009900",
        "#ff9933",
        "#009999",
        "#333399",
        "#999966",
        "#99ccff",
        "#990033",
        "#996600",
        "#ffcc99",
        "#6699ff",
    ];

    function updateFile() {
        if (files[0] && files[0].name) {
            let fileName = files[0].name.split(".");
            srcExt = fileName.pop().toLowerCase();
            srcFileName = fileName.shift();
            updateFileContents();
        }
    }

    function updateFileContents() {
        if (files && files[0]) {
            toast.push("Reading file contents...", {
                classes: ["toast-warning"],
            });
            let reader = new FileReader();
            reader.onload = async function (e) {
                fileContents = e.target.result;
            };

            reader.readAsText(files[0], "utf-8");
        }
    }

    async function importMarkers() {
        try {
            let markers = [];
            if (srcExt === "json") {
                let markerData = JSON.parse(fileContents);
                markerData.forEach((marker) => {
                    try {
                        let newMarker = new _Marker({
                            time: tcLib.parseTcToSec(
                                marker.time,
                                $projectState.frameRate,
                                $projectState.dropFrame,
                            ),
                            comment: marker.comment
                                ? marker.comment.toString()
                                : "",
                        });

                        markers.push(newMarker);
                    } catch (error) {
                        console.error("Error pushing marker:", error);
                    }
                });
            } else if (srcExt === "edl") {
                let edlData = parseEDL(fileContents);
                // console.log(edlData);
                edlData.clips.forEach((clip) => {
                    try {
                        markers.push(
                            new _Marker({
                                time: tcLib.parseTcToSec(
                                    clip.sequence_in,
                                    $projectState.frameRate,
                                    $projectState.dropFrame,
                                ),
                                comment: clip.clip_id.toString(),
                            }),
                        );
                    } catch (error) {
                        console.error("Error pushing marker:", error);
                    }

                    try {
                        markers.push(
                            new _Marker({
                                time: tcLib.parseTcToSec(
                                    clip.sequence_out,
                                    $projectState.frameRate,
                                    $projectState.dropFrame,
                                ),
                                comment: clip.clip_id.toString(),
                            }),
                        );
                    } catch (error) {
                        console.error("Error pushing marker:", error);
                    }
                });
            } else {
                toast.push(
                    "Invalid file format. Please select a valid file format.",
                    { classes: ["toast-error"] },
                );
                modalState.hideModal();
                return;
            }

            //Filter markers to remove markers that have the same time. Only keep the first one.
            // console.log("MARKERS BEFORE FILTER", markers);
            markers = markers.filter((marker, index, self) => {
                return (
                    index ===
                    self.findIndex((m) => {
                        return (
                            parseFloat(m.time).toFixed(3) ===
                            parseFloat(marker.time).toFixed(3)
                        );
                    })
                );
            });

            if (offsetTimecode) {
                let offset = $projectState.incode;
                markers = markers.map((marker) => {
                    return {
                        ...marker,
                        time: marker.time - offset,
                    };
                });
            }
            

            if (markerGroup === "new") {
                $markerState.lists = [
                    ...$markerState.lists,
                    {
                        id: uuidv4(),
                        name: markerGroupName,
                        color: hexColors[Math.floor(Math.random() * 20)],
                        markers: markers,
                    },
                ];

                $markerState.selected = $markerState.lists.length - 1;
            } else {
                let markerListIndex = $markerState.lists.findIndex(
                    (markerList) => {
                        return markerList.id == markerGroup;
                    },
                );
                if (importOption === "replace") {
                    $markerState.lists[markerListIndex] = {
                        ...$markerState.lists[markerListIndex],
                        markers: markers,
                    }
                } else {
                    /* Merge markers */
                    $markerState.lists[markerListIndex] = {
                        ...$markerState.lists[markerListIndex],
                        markers: [
                            ...$markerState.lists[markerListIndex].markers,
                            ...markers,
                        ]
                    };
                }
            }

            $markerState = $markerState;

            historyState.insert({
                name: "import markers", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "markerState",
                        value: JSON.stringify($markerState),
                    },
                ],
            });
        } catch (err) {
            console.error(err);
            console.log(err.message);
            toast.push(
                "An error occurred while importing markers: " + err.message,
                { classes: ["toast-error"] },
            );
        } finally {
            modalState.hideModal();
        }
    }

    function parseEDL(edlContent) {
        console.log("Paring EDL...");
        let result = {
            clips : []
        };

        let lines = edlContent.split("\n");
        let lastIndex = 0;
        lines.forEach(line =>{
            let lineContent = line.split(" ").filter(l => {return l});
            if (lineContent[0] && parseFloat(lineContent[0]) === lastIndex+1){
                // console.log(lineContent[0]);
                // console.log(lineContent[4]);
                result.clips.push({
                    clip_id: lineContent[0],
                    sequence_in: lineContent[4],
                    sequence_out: lineContent[5]
                });

                lastIndex++;
            }
        });

        console.log("Done parsing EDL", result);
        return result;
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'markerImport' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="MarkerImportModal"
>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Marker Import</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p class="small text-muted">
                    <i class="bi bi-lightning-fill"></i> Powered by Closed Caption
                    Converter | www.closedcaptionconverter.com
                </p>

                <div class="row">
                    <div class="col">
                        <!-- Import Form Start -->
                        <form on:submit|preventDefault={importMarkers}>
                            <!-- File Input -->
                            <div class="mb-3 shadow bg-secondary rounded p-3">
                                <label
                                    class="form-label"
                                    for="subtitleFilePicker"
                                    >Select Marker File <i
                                        class="bi bi-info-circle"
                                        title="Supported formats: .JSON, .EDL"
                                    ></i></label
                                >
                                <input
                                    type="file"
                                    class="form-control"
                                    id="subtitleFilePicker"
                                    bind:files
                                    accept=".json, .edl"
                                    on:change={updateFile}
                                />
                            </div>
                            <div class="row">
                                <!-- Offset by Project Incode Checkbox -->
                                <div class="mb-3 col-12">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="offsetTimecode"
                                            bind:checked={offsetTimecode}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="offsetTimecode"
                                            >Offset by Project Incode</label>
                                    </div>
                                </div>
                                <!-- Target Event Group Option Start -->
                                <div class="mb-3 col-6">
                                    <label
                                        class="form-label text-warning"
                                        for="EventGroupSelection"
                                        >Marker Group</label
                                    >
                                    <select
                                        class="form-select"
                                        bind:value={markerGroup}
                                    >
                                        {#each $markerState.lists as markerGroupOption}
                                            <option value={markerGroupOption.id}
                                                >{markerGroupOption.name}</option
                                            >
                                        {/each}
                                        <option class="fw-bold" value="new"
                                            >*NEW*</option
                                        >
                                    </select>
                                </div>
                                <!-- Target Event Group Option End -->
                                <!-- Merge or Replace Option Start -->
                                {#if markerGroup !== "new"}
                                    <div class="mb-3 col-6">
                                        <p class="text-warning">
                                            Merge/Replace Events <i
                                                class="bi bi-info-circle"
                                                title="Merge - Add markers to existing group without deleting any other markers. Replace - Removes all markers in the existing marker group prior to import"
                                            ></i>
                                        </p>

                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                bind:group={importOption}
                                                name="importOption"
                                                id="mergeImportOption"
                                                value="merge"
                                                checked
                                            />
                                            <label
                                                class="form-check-label"
                                                for="mergeImportOption"
                                            >
                                                Merge
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                bind:group={importOption}
                                                name="importOption"
                                                id="replaceImportOption"
                                                value="replace"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="replaceImportOption"
                                            >
                                                Replace
                                            </label>
                                        </div>
                                    </div>
                                {:else}
                                    <!-- Marker Group Name -->
                                    <div class="mb-3 col-12">
                                        <label
                                            class="form-label text-warning"
                                            for="newMarkerGroupName"
                                            >New Group Name</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            bind:value={markerGroupName}
                                        />
                                    </div>
                                {/if}
                                <!-- Merge or Replace Option End -->
                            </div>
                        </form>
                        <!-- Import Form End -->
                    </div>
                    <div class="col">
                        <!-- File Preview -->
                        <form id="SourcePreviewForm">
                            <div class="mb-3">
                                <label class="form-label" for="srcPreviewText"
                                    >Source Preview</label
                                >
                                <textarea
                                    bind:value={fileContents}
                                    disabled
                                    class="form-control"
                                    id="srcPreviewText"
                                    rows="10"
                                    placeholder="Please select a file from the menu on the left."
                                ></textarea>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    class="btn btn-primary"
                    type="button"
                    disabled={fileContents && fileContents.length === 0}
                    on:click={() => importMarkers()}>Import Markers</button
                >
            </div>
        </div>
    </div>
</div>
