export default {
  source: {
    "ace": "Acehnese",
    "af": "Afrikaans",
    "ak": "Akan",
    "sq": "Albanian",
    "am": "Amharic",
    "ar": "Arabic",
    "hy": "Armenian",
    "as": "Assamese",
    "ast": "Asturian",
    "awa": "Awadhi",
    "quy": "Ayacucho Quechua",
    "ayr": "Aymara, Central",
    "az": "Azerbaijani",
    "azj": "Azerbaijani, Northern",
    "azb": "Azerbaijani, Southern",
    "ban": "Balinese",
    "bm": "Bambara",
    "bjn": "Banjar",
    "ba": "Bashkir",
    "be": "Belarusian",
    "bem": "Bemba",
    "bn": "Bengali",
    "bho": "Bhojpuri",
    "bs": "Bosnian",
    "bug": "Buginese",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "ceb": "Cebuano",
    "hne": "Chhattisgarhi",
    "zh-CN": "Chinese (Simplified)",
    "zh-TW": "Chinese (Traditional)",
    "cjk": "Chokwe",
    "crh": "Crimean Tatar",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "prs": "Dari",
    "diq": "Dimli",
    "dik": "Dinka, Southwestern",
    "nl": "Dutch",
    "dyu": "Dyula",
    "dz": "Dzongkha",
    "en": "English",
    "eo": "Esperanto",
    "et": "Estonian",
    "ee": "Ewe",
    "fo": "Faroese",
    "fj": "Fijian",
    "fi": "Finnish",
    "fon": "Fon",
    "fr": "French",
    "fur": "Friulian",
    "gl": "Galician",
    "lg": "Ganda",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gn": "Guarani",
    "gu": "Gujarati",
    "ht": "Haitian",
    "khk": "Halh Mongolian",
    "ha": "Hausa",
    "he": "Hebrew",
    "hi": "Hindi",
    "hu": "Hungarian",
    "is": "Icelandic",
    "ig": "Igbo",
    "ilo": "Iloko",
    "id": "Indonesian",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "jv": "Javanese",
    "kac": "Jingpho",
    "kbp": "Kabiyè",
    "kea": "Kabuverdianu",
    "kab": "Kabyle",
    "kam": "Kamba",
    "kn": "Kannada",
    "knc": "Kanuri, Central (Latin script)",
    "kas": "Kashmiri (Arabic script)",
    "ks": "Kashmiri (Devanagari script)",
    "kk": "Kazakh",
    "km": "Khmer",
    "ki": "Kikuyu",
    "kmb": "Kimbundu",
    "rw": "Kinyarwanda",
    "kg": "Kongo",
    "ko": "Korean",
    "ckb": "Kurdish, Central",
    "kmr": "Kurdish, Northern",
    "ky": "Kyrgyz",
    "lo": "Lao",
    "ltg": "Latgalian",
    "la": "Latin",
    "lv": "Latvian",
    "lij": "Ligurian",
    "li": "Limburgish",
    "ln": "Lingala",
    "lt": "Lithuanian",
    "lmo": "Lombard",
    "lua": "Luba-Kasai",
    "luo": "Luo",
    "lb": "Luxembourgish",
    "mk": "Macedonian",
    "mag": "Magahi",
    "mai": "Maithili",
    "mg": "Malagasy",
    "ms": "Malay",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mni": "Manipuri",
    "mi": "Maori",
    "mr": "Marathi",
    "min": "Minangkabau",
    "lus": "Mizo",
    "mn": "Mongolian (Traditional)",
    "mos": "Mossi",
    "my": "Myanmar (Burmese)",
    "ne": "Nepali",
    "fuv": "Nigerian Fulfulde",
    "nb": "Norwegian (Bokmål)",
    "nn": "Norwegian (Nynorsk)",
    "nus": "Nuer",
    "ny": "Nyanja",
    "oc": "Occitan",
    "or": "Odia (Oriya)",
    "gaz": "Oromo, West Central",
    "pag": "Pangasinan",
    "pap": "Papiamento",
    "pbt": "Pashto, Southern",
    "ps": "Pastho",
    "pes": "Persian, Western",
    "plt": "Plateau Malagasy",
    "pl": "Polish",
    "pt-BR": "Portuguese (Brazil)",
    "pt-PT": "Portuguese (Portugal)",
    "pa": "Punjabi",
    "ro": "Romanian",
    "rn": "Rundi",
    "ru": "Russian",
    "sm": "Samoan",
    "sg": "Sango",
    "sa": "Sanskrit",
    "sat": "Santali",
    "sc": "Sardinian",
    "gd": "Scots Gaelic",
    "sr-Cyrl": "Serbian (Cyrillic)",
    "sr-Latn": "Serbian (Latin)",
    "shn": "Shan",
    "sn": "Shona",
    "scn": "Sicilian",
    "szl": "Silesian",
    "sd": "Sindhi",
    "si": "Sinhala",
    "sk": "Slovak",
    "sl": "Slovenian",
    "so": "Somali",
    "nso": "Sotho, Northern",
    "st": "Sotho, Southern",
    "es-ES": "Spanish",
    "es-419": "Spanish (Latin America)",
    "lvs": "Standard Latvian",
    "zsm": "Standard Malay",
    "su": "Sundanese",
    "sw": "Swahili",
    "ss": "Swati",
    "sv": "Swedish",
    "tl": "Tagalog",
    "tg": "Tajik",
    "taq": "Tamasheq",
    "tzm": "Tamazight, Central Atlas",
    "ta": "Tamil",
    "tt": "Tatar",
    "te": "Telugu",
    "th": "Thai",
    "bo": "Tibetan",
    "ti": "Tigrinya",
    "tpi": "Tok Pisin",
    "als": "Tosk Albanian",
    "ts": "Tsonga",
    "tn": "Tswana",
    "tum": "Tumbuka",
    "tr": "Turkish",
    "tk": "Turkmen",
    "tw": "Twi",
    "uk": "Ukrainian",
    "umb": "Umbundu",
    "ur": "Urdu",
    "ug": "Uyghur",
    "uzn": "Uzbek, Northern",
    "vec": "Venetian",
    "vi": "Vietnamese",
    "war": "Waray (Philippines)",
    "cy": "Welsh",
    "wo": "Wolof",
    "xh": "Xhosa",
    "ydd": "Yiddish, Eastern",
    "yo": "Yoruba",
    "zu": "Zulu"
  },
  target: {
    "ace": "Acehnese",
    "af": "Afrikaans",
    "ak": "Akan",
    "sq": "Albanian",
    "am": "Amharic",
    "ar": "Arabic",
    "hy": "Armenian",
    "as": "Assamese",
    "ast": "Asturian",
    "awa": "Awadhi",
    "quy": "Ayacucho Quechua",
    "ayr": "Aymara, Central",
    "az": "Azerbaijani",
    "azj": "Azerbaijani, Northern",
    "azb": "Azerbaijani, Southern",
    "ban": "Balinese",
    "bm": "Bambara",
    "bjn": "Banjar",
    "ba": "Bashkir",
    "be": "Belarusian",
    "bem": "Bemba",
    "bn": "Bengali",
    "bho": "Bhojpuri",
    "bs": "Bosnian",
    "bug": "Buginese",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "ceb": "Cebuano",
    "hne": "Chhattisgarhi",
    "zh-CN": "Chinese (Simplified)",
    "zh-TW": "Chinese (Traditional)",
    "cjk": "Chokwe",
    "crh": "Crimean Tatar",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "prs": "Dari",
    "diq": "Dimli",
    "dik": "Dinka, Southwestern",
    "nl": "Dutch",
    "dyu": "Dyula",
    "dz": "Dzongkha",
    "en": "English",
    "eo": "Esperanto",
    "et": "Estonian",
    "ee": "Ewe",
    "fo": "Faroese",
    "fj": "Fijian",
    "fi": "Finnish",
    "fon": "Fon",
    "fr": "French",
    "fur": "Friulian",
    "gl": "Galician",
    "lg": "Ganda",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gn": "Guarani",
    "gu": "Gujarati",
    "ht": "Haitian",
    "khk": "Halh Mongolian",
    "ha": "Hausa",
    "he": "Hebrew",
    "hi": "Hindi",
    "hu": "Hungarian",
    "is": "Icelandic",
    "ig": "Igbo",
    "ilo": "Iloko",
    "id": "Indonesian",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "jv": "Javanese",
    "kac": "Jingpho",
    "kbp": "Kabiyè",
    "kea": "Kabuverdianu",
    "kab": "Kabyle",
    "kam": "Kamba",
    "kn": "Kannada",
    "knc": "Kanuri, Central (Latin script)",
    "kas": "Kashmiri (Arabic script)",
    "ks": "Kashmiri (Devanagari script)",
    "kk": "Kazakh",
    "km": "Khmer",
    "ki": "Kikuyu",
    "kmb": "Kimbundu",
    "rw": "Kinyarwanda",
    "kg": "Kongo",
    "ko": "Korean",
    "ckb": "Kurdish, Central",
    "kmr": "Kurdish, Northern",
    "ky": "Kyrgyz",
    "lo": "Lao",
    "ltg": "Latgalian",
    "la": "Latin",
    "lv": "Latvian",
    "lij": "Ligurian",
    "li": "Limburgish",
    "ln": "Lingala",
    "lt": "Lithuanian",
    "lmo": "Lombard",
    "lua": "Luba-Kasai",
    "luo": "Luo",
    "lb": "Luxembourgish",
    "mk": "Macedonian",
    "mag": "Magahi",
    "mai": "Maithili",
    "mg": "Malagasy",
    "ms": "Malay",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mni": "Manipuri",
    "mi": "Maori",
    "mr": "Marathi",
    "min": "Minangkabau",
    "lus": "Mizo",
    "mn": "Mongolian (Traditional)",
    "mos": "Mossi",
    "my": "Myanmar (Burmese)",
    "ne": "Nepali",
    "fuv": "Nigerian Fulfulde",
    "nb": "Norwegian (Bokmål)",
    "nn": "Norwegian (Nynorsk)",
    "nus": "Nuer",
    "ny": "Nyanja",
    "oc": "Occitan",
    "or": "Odia (Oriya)",
    "gaz": "Oromo, West Central",
    "pag": "Pangasinan",
    "pap": "Papiamento",
    "pbt": "Pashto, Southern",
    "ps": "Pastho",
    "pes": "Persian, Western",
    "plt": "Plateau Malagasy",
    "pl": "Polish",
    "pt-BR": "Portuguese (Brazil)",
    "pt-PT": "Portuguese (Portugal)",
    "pa": "Punjabi",
    "ro": "Romanian",
    "rn": "Rundi",
    "ru": "Russian",
    "sm": "Samoan",
    "sg": "Sango",
    "sa": "Sanskrit",
    "sat": "Santali",
    "sc": "Sardinian",
    "gd": "Scots Gaelic",
    "sr-Cyrl": "Serbian (Cyrillic)",
    "sr-Latn": "Serbian (Latin)",
    "shn": "Shan",
    "sn": "Shona",
    "scn": "Sicilian",
    "szl": "Silesian",
    "sd": "Sindhi",
    "si": "Sinhala",
    "sk": "Slovak",
    "sl": "Slovenian",
    "so": "Somali",
    "nso": "Sotho, Northern",
    "st": "Sotho, Southern",
    "es-ES": "Spanish",
    "es-419": "Spanish (Latin America)",
    "lvs": "Standard Latvian",
    "zsm": "Standard Malay",
    "su": "Sundanese",
    "sw": "Swahili",
    "ss": "Swati",
    "sv": "Swedish",
    "tl": "Tagalog",
    "tg": "Tajik",
    "taq": "Tamasheq",
    "tzm": "Tamazight, Central Atlas",
    "ta": "Tamil",
    "tt": "Tatar",
    "te": "Telugu",
    "th": "Thai",
    "bo": "Tibetan",
    "ti": "Tigrinya",
    "tpi": "Tok Pisin",
    "als": "Tosk Albanian",
    "ts": "Tsonga",
    "tn": "Tswana",
    "tum": "Tumbuka",
    "tr": "Turkish",
    "tk": "Turkmen",
    "tw": "Twi",
    "uk": "Ukrainian",
    "umb": "Umbundu",
    "ur": "Urdu",
    "ug": "Uyghur",
    "uzn": "Uzbek, Northern",
    "vec": "Venetian",
    "vi": "Vietnamese",
    "war": "Waray (Philippines)",
    "cy": "Welsh",
    "wo": "Wolof",
    "xh": "Xhosa",
    "ydd": "Yiddish, Eastern",
    "yo": "Yoruba",
    "zu": "Zulu"
  }
};