<script>
import {
    fade
} from 'svelte/transition';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    fontState
} from '@app/store/fontStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    authState
} from '@app/store/authStore.js';
import supportedLanguages from '@app/external/cc-lib/dist/dict/supportedLanguages.js';
let eventGroupType = $eventGroupState[$projectState.selected].type,
    displayName = $eventGroupState[$projectState.selected].name,
    language = $eventGroupState[$projectState.selected].language,
    linkedGroupIndex = $eventGroupState[$projectState.selected].linkedGroupIndex,
    rtl = $eventGroupState[$projectState.selected].rtl,
    originalRtl = $eventGroupState[$projectState.selected].originalRtl,
    fontFamily = $eventGroupState[$projectState.selected].originalFontFamily,
    overlap = $eventGroupState[$projectState.selected].overlap,
    maxCps = $eventGroupState[$projectState.selected].maxCps,
    maxWpm = $eventGroupState[$projectState.selected].maxWpm,
    maxChars = $eventGroupState[$projectState.selected].maxChars,
    maxLines = $eventGroupState[$projectState.selected].maxLines,
    minDuration = $eventGroupState[$projectState.selected].minDuration,
    maxDuration = $eventGroupState[$projectState.selected].maxDuration,
    illegalChars = $eventGroupState[$projectState.selected].illegalChars,
    languages = supportedLanguages.languages;

function updateEventGroup() {
    $eventGroupState[$projectState.selected].name = displayName;
    $eventGroupState[$projectState.selected].language = language;
    $eventGroupState[$projectState.selected].rtl = rtl;
    $eventGroupState[$projectState.selected].overlap = overlap,
    $eventGroupState[$projectState.selected].illegalChars = illegalChars,
    $eventGroupState[$projectState.selected].maxCps = maxCps || 9999,
    $eventGroupState[$projectState.selected].maxWpm = maxWpm || 9999,
    $eventGroupState[$projectState.selected].maxChars = maxChars || 9999,
    $eventGroupState[$projectState.selected].maxLines = maxLines || 9999,
    $eventGroupState[$projectState.selected].minDuration = minDuration || 0.0001,
    $eventGroupState[$projectState.selected].maxDuration = maxDuration || 9999,
    $eventGroupState[$projectState.selected].originalFontFamily = fontFamily;
    $eventGroupState[$projectState.selected].originalRtl = originalRtl;

    historyState.insert({
        name: "update event group", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    localStorage.setItem("cc-event-group-defaults", JSON.stringify({
        eventGroupType: eventGroupType,
        name: displayName,
        language: language,
        overlap : overlap,
        illegalChars : illegalChars,
        maxCps : maxCps || 9999,
        maxWpm : maxWpm || 9999,
        maxChars : maxChars || 9999,
        maxLines : maxLines || 9999,  
        minDuration : minDuration >= 0 ? minDuration : 1,
        maxDuration : maxDuration || 9999,
        rtl: rtl
    }));

    modalState.hideModal();
}

</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'editEventGroup' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="editEventGroupTitle"
    aria-describedby="editEventGroupDesc"
    tabindex="-1" 
    id="EditEventGroupModal">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="editEventGroupTitle">Edit Event Group</h4>
                <button type="button" class="btn-close" id="closeEditEventBtn" aria-label="Close dialog" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p id="editEventGroupDesc" class="visually-hidden">Edit event group settings and properties</p>
                <form on:submit|preventDefault="{updateEventGroup}" id="EditEventGroupForm">
                    <!-- Group Type -->
                    <div class="mb-3">
                        <label class="form-label" for="groupTypeSelect">Group Type</label>
                        <select class="form-select bg-light" id="groupTypeSelect" value={eventGroupType} disabled aria-describedby="groupTypeHelp">
                            <option value="subtitle">Subtitle</option>
                            {#if $authState.ad}
                            <option value="audio description">Audio Description</option>
                            {/if}
                            <option value="transcription">Transcription</option>
                            <option value="translation">Translation</option>
                        </select>
                        <div id="groupTypeHelp" class="form-text">The type of content this group contains</div>
                    </div>
                    <!-- Name -->
                    <div class="mb-3">
                        <label class="form-label" for="displayNameInput">Display Name</label>
                        <input class="form-control" id="displayNameInput" on:focus="{(e)=>{e.target.select()}}" required 
                            placeholder="Display Name" type="text" bind:value={displayName}
                            aria-describedby="displayNameHelp">
                        <div id="displayNameHelp" class="form-text">The name that will be displayed for this group</div>
                    </div>
                    <!-- Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Language">Language</label>
                        <select class="form-select" bind:value={language}>
                            {#each languages as languageOption, index(index)}
                            <option>{languageOption}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="form-check form-switch mb-2">
                        <input type="checkbox" class="form-check-input" id="rtlSwitch" bind:checked={rtl}>
                        <label class="form-check-label" for="rtlSwitch">Right-To-Left</label>
                    </div>
                    <div>
                        <hr/>
                        <p class="lead text-warning m-0">Error Detection</p>
                        <div class="row">
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum characters per second">Max Characters Per Second (CPS)</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxCps}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum words per minute">Max Words Per Minute (WPM)</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxWpm}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum characters per line">Max Characters Per Line</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxChars}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum lines per event">Max Lines Per Event</label>
                                <input type="number" step="1" min="0" max="9999" class="form-control" bind:value={maxLines}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Minimum Event Duration">Minimum Event Duration (sec)</label>
                                <input type="number" step="0.01" min="0" max="9999" class="form-control" bind:value={minDuration}>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="Maximum Event Duration">Maximum Event Duration (sec)</label>
                                <input type="number" step="0.01" min="0" max="9999" class="form-control" bind:value={maxDuration}>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" id="overlapCheck" bind:checked={overlap}>
                                    <label class="form-check-label" for="overlapCheck">Overlap Detection</label>
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" id="illegalCharsCheck" bind:checked={illegalChars}>
                                    <label class="form-check-label" for="illegalCharsCheck">Detect Illegal Chararacters (608)</label>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <!-- Linked Group -->
                    {#if eventGroupType === 'translation'}
                    <div transition:fade="{{duration: 100 }}">
                        <hr/>
                        <p class="lead text-warning m-0">Translation Options</p>
                        <div class="mb-3">
                            <label class="form-label" for="Linked group">Linked Group <i class="bi bi-info-circle" title="The linked group is used to populate the source events for translation."></i></label>
                            <select class="form-select bg-light"  bind:value={linkedGroupIndex} disabled>
                                {#each $eventGroupState as eventGroup, index(index)}
                                <option value={index}>{eventGroup.name}</option>
                                {/each}
                                <option value="none">None</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="Original font family">Original Font Family</label>
                            <select class="form-select" style="font-family: {fontFamily};" bind:value={fontFamily}>

                                {#each $fontState as font (font.id)}
                                <option style="font-family: {font.name};">{font.name}</option>
                                {/each}
                                <option style="font-family: Arial;">Arial</option>
                                <option style="font-family: Verdana;">Verdana</option>
                                <option style="font-family: Helvetica;">Helvetica</option>
                                <option style="font-family: Tahoma;">Tahoma</option>
                                <option style="font-family: Trebuchet MS;">Trebuchet MS</option>
                                <option style="font-family: Times New Roman;">Times New Roman</option>
                                <option style="font-family: Georgia;">Georgia</option>
                                <option style="font-family: Garamond;">Garamond</option>
                                <option style="font-family: Courier New;">Courier New</option>
                                <option style="font-family: Brush Script MT;">Brush Script MT</option>
                                <option style="font-family: serif;">serif</option>
                                <option style="font-family: sans-serif;">sans-serif</option>
                                <option style="font-family: plexMono;">plexMono</option>
                                <option style="font-family: cursive;">cursive</option>
                                <option style="font-family: fantasy;">fantasy</option>
                            </select>
                        </div>
                        <div class="form-check form-switch mb-2">
                            <input type="checkbox" class="form-check-input" id="originalRtlSwitch" bind:checked={originalRtl}>
                            <label class="form-check-label" for="originalRtlSwitch">Right-To-Left</label>
                        </div>
                    </div>                    
                    {/if}
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" id="updateEventGroupBtn" type="button" on:click={updateEventGroup}>Update Group</button>
            </div>
        </div>
    </div>
</div>
