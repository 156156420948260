import { decode as _decodeHtml } from "html-entities";
import _stripTags from "./stripTags.js";
const stripTags = _stripTags;
const decodeHtml = _decodeHtml;
export default (function convertToPlainText(text, divider = "\n") {
  text = stripTags(decodeHtml(text).replace(/<\/p>/gmi, divider)).trim();
  //replace one or more spaces with a one space
  text = text.replace(/ +/g, " ");
  //replace spaces before punctuation
  return text.replace(/ ([,.!?])/g, "$1");
});