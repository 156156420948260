export default (function (textArray, maxLength) {
  for (let i = 0; i < textArray.length; i++) {
    let sentences = [];
    let sentence = "";
    const words = textArray[i].split(" ");
    for (let j = 0; j < words.length; j++) {
      if (sentence.length + words[j].length + 1 > maxLength) {
        sentences.push(sentence.trim());
        sentence = words[j];
      } else {
        sentence += " " + words[j];
      }
    }
    if (sentence.length > 0) {
      sentences.push(sentence.trim());
    }

    //console.log(sentences);
    textArray[i] = sentences.shift();
    if (sentences.length > 0) {
      if (textArray[i + 1]) {
        textArray[i + 1] = sentences.join(" ") + " " + textArray[i + 1];
      } else {
        textArray.push(sentences.join(" "));
      }
    }
  }
  return textArray;
});