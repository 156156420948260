<script>
    /* States */
    import { explorerState } from "@app/store/explorerStore.js";
    import { authState } from "@app/store/authStore.js";
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { issueState } from "@app/store/issueStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { historyState } from "@app/store/historyStore.js";
    /* Libraries */
    import Swal from "sweetalert2";
    import { toast } from "@zerodevx/svelte-toast";
    import refreshId from "@app/external/cc-lib/dist/functions/utility/refreshId.js";
    import { ccprjFunc } from '@app/lib/ccprj.js';
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    import "firebase/functions";
    /* CC LIB */
    import workflowDict from "@app/external/cc-lib/dist/dict/workflow.js";
    const iconMap = {
        "folder" : "bi-folder-fill",
        "project" : "bi-file-earmark-play",
        "team project" : "bi-people",
        "work order" : "bi-clipboard-check"
    }

    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;

    let selectedRecord = null;

    $: selectedRecord = $explorerState.selectedRecords.length > 0 ? $explorerState.records.find(record => record.id === $explorerState.selectedRecords[0]) : null;
    
    // Add storage paths
    let storageRef = storage.ref();
    let homeStorageBasePath = `users/${userId}/projects/`;
    let teamStorageBasePath = "teams/" + teamId + "/storage/";
    
    // Function to copy record ID to clipboard
    function copyIdToClipboard() {
        if (selectedRecord && selectedRecord.id) {
            navigator.clipboard.writeText(selectedRecord.id)
                .then(() => {
                    // console.log('ID copied to clipboard');
                })
                .catch(err => {
                    // console.error('Failed to copy ID: ', err);
                });
        }
    }
    
    // Action placeholder functions
    async function openRecord() {
        // console.log("Opening record with id: ", selectedRecord.id);
        
        if (selectedRecord.type === "folder") {
            // Handle folder navigation through parent component
            $explorerState.navHistory = [...$explorerState.navHistory, selectedRecord];
            // Get records in this folder - typically handled by the parent component
            const event = new CustomEvent('folderOpen', { detail: selectedRecord.id });
            window.dispatchEvent(event);
        } else if (selectedRecord.type === "project" || selectedRecord.type === "team project" || selectedRecord.type === "work order") {
            try {
                // Determine the storage path based on whether it's personal or team
                const projectFilePath = selectedRecord.rootDir === "team" 
                    ? `${teamStorageBasePath}${selectedRecord.id}` 
                    : `${homeStorageBasePath}${selectedRecord.id}`;
                
                // console.log(`Fetching file from: ${projectFilePath}`);
                
                // Get the URL for the file
                let url;
                if (selectedRecord.rootDir === "team") {
                    const result = await firebase.functions().httpsCallable("v8GetDownloadLink")(projectFilePath);
                    url = result.data;
                } else {
                    url = await storageRef.child(projectFilePath).getDownloadURL();
                }
                
                // Fetch the file content
                const response = await fetch(url);
                const fileData = await response.json();
                
                // Process based on record type
                if (selectedRecord.type === "work order") {
                    openWorkOrder(fileData, selectedRecord);
                } else if (selectedRecord.type === "project") {
                    openProjectFile(fileData, selectedRecord);
                } else if (selectedRecord.type === "team project") {
                    openTeamProjectFile(fileData, selectedRecord);
                }
                
            } catch (error) {
                console.error(`Error opening ${selectedRecord.type}:`, error);
                toast.push(`Failed to open ${selectedRecord.type}: ${error.message}`, {
                    classes: ["toast-danger"],
                });
            }
        } 
    }

    function openProjectFile(description, selectedRecord){
        // Add folderId to description object
        description.folderId = selectedRecord.locationId;
        
        $externalDataState = {
            platform: "Project File",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call projectFileImport modal
        modalState.showModal("loadProjectFile");
    }

    async function openTeamProjectFile(description, selectedRecord) {
        try {
            // Extract team ID and project ID
            const projectTeamId = description.teamId;
            
            if (!projectTeamId || !teamId) {
                throw new Error("Missing team or project ID in file");
            }
            
            // console.log(`Looking up share codes for team project: ${projectTeamId} in team: ${teamId}`);
            
            // Check for project snapshots first (to ensure there's data to share)
            const snapshotsRef = db.collection("teams").doc(teamId)
                .collection("projects").doc(projectTeamId)
                .collection("snapshots");
                
            const snapshotsQuery = await snapshotsRef.limit(1).get();
            
            if (snapshotsQuery.empty) {
                // console.log("No snapshots found for team project, falling back to project file import");
                fallbackToProjectFileImport(description, selectedRecord);
                return;
            }
            
            // Look for share codes that reference this project
            const sharesRef = db.collection("teams").doc(teamId).collection("shares");
                
            const sharesQuery = await sharesRef
                .where("projectTeamId", "==", projectTeamId)
                .limit(1)
                .get();
            
            if (sharesQuery.empty) {
                // console.log("No share codes found for team project, falling back to project file import");
                fallbackToProjectFileImport(description, selectedRecord);
                return;
            }
            
            // Get the first share code
            const shareDoc = sharesQuery.docs[0];
            const shareId = shareDoc.id;
            
            // console.log(`Found share code: ${shareId}`);
            
            // Update URL with share code and open teamProjectImport modal
            const url = new URL(window.location.href);
            url.searchParams.set("id", shareId);
            window.history.pushState({}, '', url);
            
            toast.push("Opening team project via share code...", {
                classes: ["toast-info"],
            });
            
            modalState.showModal("teamProjectImport");
            
        } catch (error) {
            console.error("Error processing team project:", error);
            fallbackToProjectFileImport(description, selectedRecord);
        }
    }
    
    function fallbackToProjectFileImport(description, selectedRecord) {
        toast.push("Using project file import method", {
            classes: ["toast-info"],
        });
        
        // Add folderId to description object
        description.folderId = selectedRecord.locationId;
        
        $externalDataState = {
            platform: "Team Project File",
            status: "importing",
            rawDescription: JSON.stringify(description)
        };
        
        modalState.showModal("teamProjectFileImport");
    }

    function openWorkOrder(description, selectedRecord) {
        // Add folderId to description object
        description.folderId = selectedRecord.locationId;
        
        $externalDataState = {
            platform: "Work Order",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call workOrderImport modal
        modalState.showModal("workOrderImport");
    }
    
    async function deleteRecord() {
        if (!selectedRecord) return;
    
        // Show confirmation dialog using SweetAlert
        const result = await Swal.fire({
            title: "Delete Record",
            text: `Are you sure you want to delete "${selectedRecord.name}"?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel",
            buttonsStyling: true,
            customClass: {
                confirmButton: "btn btn-danger me-2",
                cancelButton: "btn btn-outline-secondary"
            }
        });

        if (!result.isConfirmed) return;
    
        try {
            // Determine which database reference to use
            const dbRef = selectedRecord.rootDir === "personal" ? homeDbRef : teamDbRef;
        
            // Delete the record from Firestore
            await dbRef.doc(selectedRecord.id).delete();
        
            // If it's a project, also delete the project file from storage
            if (selectedRecord.type === "project" || selectedRecord.type === "team project" || selectedRecord.type === "work order") {
                const projectFilePath = selectedRecord.rootDir === "personal" 
                    ? "users/" + userId + "/projects/" + selectedRecord.id
                    : "teams/" + teamId + "/storage/" + selectedRecord.id;
                
                if (selectedRecord.rootDir === "team") {
                    // Use Cloud Function for team projects
                    await firebase.functions().httpsCallable("v8DeleteProjectFile")(projectFilePath);
                } else {
                    // Use direct storage reference for personal projects
                    await storage.ref().child(projectFilePath).delete();
                }
            }
        
            // Update the explorer state to remove the deleted record
            $explorerState.records = $explorerState.records.filter(record => record.id !== selectedRecord.id);
        
            // Clear selection if the deleted record was selected
            if ($explorerState.selectedRecords.includes(selectedRecord.id)) {
                $explorerState.selectedRecords = $explorerState.selectedRecords.filter(id => id !== selectedRecord.id);
            }
        
            // Show success message
            toast.push(`"${selectedRecord.name}" was deleted successfully`, {
                classes: ["toast-success"]
            });
        
            // Clear the selected record
            selectedRecord = null;
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.push(`Failed to delete "${selectedRecord.name}". ${error.message}`, {
                classes: ["toast-danger"]
            });
        }
    }
    
    async function replaceRecord() {
        if (!selectedRecord) return;
        
        try {
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Overwrite Project",
                text: `Are you sure you want to overwrite "${selectedRecord.name}"?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Overwrite",
                denyButtonText: "Cancel",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-danger me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) {
                throw new Error("Overwriting project was cancelled");
            }
            
            // Update the project to use the same ID as the selected record
            $projectState = refreshId($projectState, selectedRecord.id);
            
            // Determine file path based on storage location
            const projectFilePath = selectedRecord.rootDir === "team"
                ? teamStorageBasePath + selectedRecord.id
                : homeStorageBasePath + selectedRecord.id;
                
            // Create project JSON
            const projectJson = ccprjFunc.createCcprj({
                project: JSON.parse(JSON.stringify($projectState)),
                metadata: JSON.parse(JSON.stringify($metadataState)),
                speakers: JSON.parse(JSON.stringify($speakerState)),
                issues: JSON.parse(JSON.stringify($issueState)),
                markers: JSON.parse(JSON.stringify($markerState)),
                eventGroups: JSON.parse(JSON.stringify($eventGroupState)),
                style: JSON.parse(JSON.stringify($styleState))
            });
            
            // Upload to storage
            const uploadProjectRef = storage.ref().child(projectFilePath);
            await uploadProjectRef.putString(JSON.stringify(projectJson));
            
            // Update project record in database
            const projectInfo = {
                id: selectedRecord.id,
                name: $projectState.name,
                type: $projectState.type === "team" ? "team project" : "project",
                owner: userEmail,
                createdOn: selectedRecord.createdOn,
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                locationId: selectedRecord.locationId,
                shared: selectedRecord.rootDir === "team" ? true : false,
                star: selectedRecord.star || false,
                rootDir: selectedRecord.rootDir
            };
            
            const dbRef = selectedRecord.rootDir === "team" ? teamDbRef : homeDbRef;
            await dbRef.doc(projectInfo.id).set(projectInfo);
            
            // Update project state with location info
            $projectState.folderId = selectedRecord.locationId;
            $projectState.rootDir = selectedRecord.rootDir;
            
            // Reset history and show success message
            historyState.reset();
            toast.push(`Project "${$projectState.name}" overwritten successfully`, {
                classes: ["toast-success"],
            });
            
            // Update the record in the records list
            $explorerState.records = $explorerState.records.map(record => 
                record.id === selectedRecord.id ? projectInfo : record
            );
            
            modalState.hideModal();
        } catch (error) {
            console.error("Error overwriting project:", error);
            toast.push(`Failed to overwrite project: ${error.message}`, {
                classes: ["toast-danger"],
            });
        }
    }
    
    function starRecord() {
        console.log('Starring record:', selectedRecord.id);
        console.log($authState);
        updateStarStatus(true);
    }
    
    function unstarRecord() {
        console.log('Unstarring record:', selectedRecord.id);
        updateStarStatus(false);
    }
    
    async function updateStarStatus(starValue) {
        try {
            // Determine which database reference to use based on rootDir
            const dbRef = selectedRecord.rootDir === "personal" ? homeDbRef : teamDbRef;
            
            // Update the document star status in Firestore
            await dbRef.doc(selectedRecord.id).update({
                star: starValue
            });
            
            // Update the local record in the explorer state
            $explorerState.records = $explorerState.records.map(record => {
                if (record.id === selectedRecord.id) {
                    return {...record, star: starValue};
                }
                return record;
            });

            toast.push(`Record ${starValue ? "starred" : "unstarred"} successfully`, {
                classes: ["toast-success"]
            });
            
            // Update the selected record to reflect the change
            selectedRecord = {...selectedRecord, star: starValue};
        } catch (error) {
            console.error('Error updating star status:', error);
            toast.push(error.message, {
                classes: ["toast-danger"],
            });
        }
    }

    async function updateState(){
        console.log('Updating state for record:', selectedRecord.id);
        //Use Sweet Alert to ask the user to select a new state from a select dropdown list. The dropdown list will contain the states stored in workflowDict.states
        const { value: newState } = await Swal.fire({
            title: 'Select new state',
            input: 'select',
            inputOptions: workflowDict.states,
            inputPlaceholder: 'Select a new state',
            showCancelButton: true,
            customClass: {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-outline-secondary"
            },
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value !== '') {
                        resolve();
                    } else {
                        resolve('Please select a new state.');
                    }
                });
            }
        });

        if (workflowDict.states[newState]) {
            console.log('New state selected:', newState);
            //Update the state of the selected record in firestore
            try {
                // Determine which database reference to use based on rootDir
                const dbRef = selectedRecord.rootDir === "personal" ? homeDbRef : teamDbRef;
                
                // Update the document state in Firestore
                await dbRef.doc(selectedRecord.id).update({
                    state: workflowDict.states[newState]
                });
                
                // Update the local record in the explorer state
                $explorerState.records = $explorerState.records.map(record => {
                    if (record.id === selectedRecord.id) {
                        return {...record, state: workflowDict.states[newState]};
                    }
                    return record;
                });

                toast.push(`State updated to "${workflowDict.states[newState]}"`, {
                    classes: ["toast-success"]
                });
            } catch (error) {
                console.error('Error updating state:', error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            }
        }
    }

    // Implementation for lock and unlock features
    async function updateLockStatus(lockValue) {
        try {
            // Determine which database reference to use based on rootDir
            const dbRef = selectedRecord.rootDir === "personal" ? homeDbRef : teamDbRef;
            
            // Update the document lock status in Firestore
            await dbRef.doc(selectedRecord.id).update({
                locked: lockValue,
                lockedBy: lockValue ? userEmail : null,
                lockedOn: lockValue ? firebase.firestore.Timestamp.fromDate(new Date()) : null
            });
            
            // Update the local record in the explorer state
            $explorerState.records = $explorerState.records.map(record => {
                if (record.id === selectedRecord.id) {
                    return {
                        ...record, 
                        locked: lockValue,
                        lockedBy: lockValue ? userEmail : null,
                        lockedOn: lockValue ? firebase.firestore.Timestamp.fromDate(new Date()) : null
                    };
                }
                return record;
            });

            toast.push(`Record ${lockValue ? "locked" : "unlocked"} successfully`, {
                classes: ["toast-success"]
            });
            
            // Update the selected record to reflect the change
            selectedRecord = {
                ...selectedRecord, 
                locked: lockValue,
                lockedBy: lockValue ? userEmail : null,
                lockedOn: lockValue ? firebase.firestore.Timestamp.fromDate(new Date()) : null
            };
        } catch (error) {
            console.error('Error updating lock status:', error);
            toast.push(error.message, {
                classes: ["toast-danger"],
            });
        }
    }

    function lockRecord() {
        // First confirm with the user
        Swal.fire({
            title: "Lock Record",
            text: `Do you want to lock "${selectedRecord.name}"? This will prevent others from modifying it.`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, lock it",
            cancelButtonText: "Cancel",
            buttonsStyling: true,
            customClass: {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-outline-secondary"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                updateLockStatus(true);
            }
        });
    }

    function unlockRecord() {
        // First confirm with the user
        Swal.fire({
            title: "Unlock Record",
            text: `Do you want to unlock "${selectedRecord.name}"? This will allow others to modify it.`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, unlock it",
            cancelButtonText: "Cancel",
            buttonsStyling: true,
            customClass: {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-outline-secondary"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                updateLockStatus(false);
            }
        });
    }

    // Function to update the assigned user
    async function updateAssignedUser() {
        if (!selectedRecord) return;
        
        // Prepare the options for the dropdown
        let assignmentOptions = [];
        
        if ($authState.team && $authState.team.members && $authState.team.members.length > 0) {
            // If user has a team, show all team members
            assignmentOptions = $authState.team.members;
        } else {
            // If user doesn't have a team, only show the user's email
            assignmentOptions = [userEmail];
        }
        
        // Add an option to clear the assignment
        assignmentOptions.push('none');
        
        // Show SweetAlert with dropdown
        let { value: assignedEmail } = await Swal.fire({
            title: 'Assign to User',
            input: 'select',
            inputOptions: assignmentOptions,
            inputPlaceholder: 'Select user',
            showCancelButton: true,
            inputValue: selectedRecord.assignedTo || '',
            customClass: {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-outline-secondary"
            }
        });
        
        if (assignedEmail) {
            try {
                // Determine which database reference to use based on rootDir
                const dbRef = selectedRecord.rootDir === "personal" ? homeDbRef : teamDbRef;
                
                // Set the assigned user (or null if 'none' was selected)
                assignedEmail = assignmentOptions[assignedEmail];
                const assignValue = assignedEmail === 'none' ? null : assignedEmail;
                
                // Update the document in Firestore
                await dbRef.doc(selectedRecord.id).update({
                    assignedTo: assignValue,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
                
                // Update the local record in the explorer state
                $explorerState.records = $explorerState.records.map(record => {
                    if (record.id === selectedRecord.id) {
                        return {
                            ...record,
                            assignedTo: assignValue,
                            updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                        };
                    }
                    return record;
                });

                toast.push(`Assignment updated successfully`, {
                    classes: ["toast-success"]
                });
                
                // Update the selected record
                selectedRecord = {
                    ...selectedRecord,
                    assignedTo: assignValue,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                };
            } catch (error) {
                console.error('Error updating assignment:', error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            }
        }
    }

    // Function to rename the selected record
    async function renameRecord() {
        if (!selectedRecord) return;
        
        // Show SweetAlert with input field
        const { value: newName } = await Swal.fire({
            title: 'Rename Record',
            input: 'text',
            inputLabel: 'New name',
            inputValue: selectedRecord.name,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Name cannot be empty';
                }
            },
            customClass: {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-outline-secondary"
            }
        });
        
        if (newName && newName !== selectedRecord.name) {
            try {
                // Determine which database reference to use based on rootDir
                const dbRef = selectedRecord.rootDir === "personal" ? homeDbRef : teamDbRef;
                
                // Update the document name in Firestore
                await dbRef.doc(selectedRecord.id).update({
                    name: newName,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
                
                // Update the local record in the explorer state
                $explorerState.records = $explorerState.records.map(record => {
                    if (record.id === selectedRecord.id) {
                        return {
                            ...record,
                            name: newName,
                            updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                        };
                    }
                    return record;
                });

                toast.push(`Record renamed to "${newName}" successfully`, {
                    classes: ["toast-success"]
                });
                
                // Update the selected record
                selectedRecord = {
                    ...selectedRecord,
                    name: newName,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                };
            } catch (error) {
                console.error('Error renaming record:', error);
                toast.push(error.message, {
                    classes: ["toast-danger"],
                });
            }
        }
    }
</script>
<div class="col-3 bg-light rounded p-3">
    {#if selectedRecord}
    <p class="text-muted m-0 small d-flex align-items-center">
        {selectedRecord.id}
        <button type="button" class="btn btn-sm btn-link p-0 ms-1" on:click={copyIdToClipboard} title="Copy ID to clipboard">
            <i class="bi bi-clipboard"></i>
        </button>
    </p>
    <h4 class="my-1"><i class="bi {iconMap[selectedRecord.type]}"></i> {selectedRecord.name}</h4>
    <div id="workspace-table-view" class="table-responsive border rounded border-0 my-2">
        <table class="table table-light border-secondary">
            <thead class="bg-light">
                <tr>
                    <th>Property</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Star</td>
                    <td>{#if selectedRecord.star}<i class="bi bi-star-fill"></i>{:else}-{/if}</td>
                </tr>
                <tr>
                    <td>State</td>
                    <td>{selectedRecord.state || "-"}</td>
                </tr>
                <tr>
                    <td>Lock Status</td>
                    <td>
                        {#if selectedRecord.locked}
                            <span class="text-danger"><i class="bi bi-lock-fill"></i> Locked</span>
                        {:else}
                            <i class="bi bi-unlock"></i>
                        {/if}
                    </td>
                </tr>
                {#if selectedRecord.locked}
                <tr>
                    <td>Locked By</td>
                    <td>{selectedRecord.lockedBy || "-"}</td>
                </tr>
                <tr>
                    <td>Locked On</td>
                    <td>{selectedRecord.lockedOn ? selectedRecord.lockedOn.toDate().toLocaleDateString() : "-"}</td>
                </tr>
                {/if}
                <tr>
                    <td>Owner</td>
                    <td>{selectedRecord.owner || "-"}</td>
                </tr>
                <tr>
                    <td>Assigned To</td>
                    <td>{selectedRecord.assignedTo || "-"}</td>
                </tr>
                <tr>
                    <td>Event Groups</td>
                    <td>{selectedRecord.eventGroups || "-"}</td>
                </tr>
                <tr>
                    <td>Total Events</td>
                    <td>{selectedRecord.totalEvents || "-"}</td>
                </tr>
                <tr>
                    <td>Last Modified</td>
                    <td>{selectedRecord.updatedOn ? selectedRecord.updatedOn.toDate().toLocaleDateString() : "-"}</td>
                </tr>
                <tr>
                    <td>Created On</td>
                    <td>{selectedRecord.createdOn ? selectedRecord.createdOn.toDate().toLocaleDateString() : "-"}</td>
                </tr>
                <!-- Storage Root Dir -->
                <tr>
                    <td>Storage</td>
                    <td class="text-capitalize">{selectedRecord.rootDir || "-"}</td>
                </tr>
                <!-- Location ID -->
                <tr>
                    <td>Location ID</td>
                    <td>{selectedRecord.locationId || "/"}</td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <!-- Action Buttons -->
      <!-- Open Record (not folder) -->
      {#if $explorerState.context === "load" && selectedRecord.type !== "folder"}
      <button type="button" class="w-100 btn btn-lg btn-outline-primary" on:click={openRecord}>
          <i class="bi bi-folder-symlink"></i> Open Project
      </button>
      <hr/>
      {:else if $explorerState.context === "save" && selectedRecord.type !== "folder"}
      <button type="button" class="w-100 btn btn-lg btn-outline-secondary" on:click={replaceRecord}>
          <i class="bi bi-arrow-repeat"></i> Save & Overwrite
      </button>
      <hr/>
      {/if}

    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group btn-group-sm me-2" role="group" aria-label="Record actions">
            <!-- Rename Button -->
            <button type="button" class="btn btn-outline-warning" on:click={renameRecord}
                disabled={selectedRecord.locked && selectedRecord.lockedBy !== userEmail}>
                <i class="bi bi-pencil-square"></i> Rename
            </button>

            <!-- Update State -->
            {#if selectedRecord.type !== "folder"}
            <button type="button" class="btn btn-outline-warning" on:click={updateState}>
                <i class="bi bi-pencil"></i> Update State
            </button>
            {/if}

            <!-- Assign To User -->
            {#if selectedRecord.type !== "folder"}
            <button type="button" class="btn btn-outline-warning" on:click={updateAssignedUser}>
                <i class="bi bi-person"></i> Assign
            </button>
            {/if}

            <!-- STAR RECORD -->
            {#if selectedRecord.star}
            <button type="button" class="btn btn-outline-warning" on:click={unstarRecord} class:d-none={!selectedRecord.star}>
                <i class="bi bi-star-fill"></i> Unstar
            </button>
            {:else}
            <button type="button" class="btn btn-outline-warning" on:click={starRecord} class:d-none={selectedRecord.star}>
                <i class="bi bi-star"></i> Star
            </button>
            {/if}

            <!-- LOCK RECORD -->
            {#if selectedRecord.locked && selectedRecord.type !== "folder"}        
                <button type="button" class="btn btn-outline-warning" on:click={unlockRecord} 
                    disabled={selectedRecord.lockedBy && selectedRecord.lockedBy !== userEmail}>
                    <i class="bi bi-unlock"></i>
                </button>
            {:else if selectedRecord.type !== "folder"}
                <button type="button" class="btn btn-outline-warning" on:click={lockRecord}>
                    <i class="bi bi-lock"></i>
                </button>
            {/if}

            
        </div>
        <div class="btn-group btn-group-sm" role="group" aria-label="Record actions">
            <button type="button" class="btn btn-outline-danger" on:click={deleteRecord}
                disabled={selectedRecord.locked && selectedRecord.lockedBy !== userEmail}>
                <i class="bi bi-trash"></i>
            </button>
        </div>
    </div>
    {:else}
    <h4>No record selected</h4>
    {/if}
    
</div>