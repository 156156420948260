<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
export let eventIndex;
</script>
<div class="mb-2">
    <select id="caption-style-select" aria-label="Caption style" class="select-form form-control-sm rounded-0 w-100" title="Caption Style" bind:value={$eventGroupState[$projectState.selected].events[eventIndex].style}>
        <option selected="">Pop-On</option>
        <option>Roll-Up 2</option>
        <option>Roll-Up 3</option>
        <option>Roll-Up 4</option>
        <option>Paint-On</option>
    </select>
</div>