export const ccprjFunc = {
    requiredStates : ["project", "metadata", "speakers", "issues", "markers", "eventGroups", "style"],
    checkRequiredState : function(states){
        //Check to ensure the states argumenmt object has all the required states as properties
        for (let i = 0; i < this.requiredStates.length; i++) {
            if (!states.hasOwnProperty(this.requiredStates[i])) {
                console.error(`Missing required state: ${this.requiredStates[i]}`);
            }
        }

        return true;
    },

    createCcprj : function(states){
        //Check to ensure the states argument object has all the required states as properties
        this.checkRequiredState(states);        

        //Create the ccprj object
        let projectJson = JSON.parse(JSON.stringify(states.project));
        //Loop through the required states and add them to the projectJson object
        //Start at 1 because the project state is already added
        for (let i = 1; i < this.requiredStates.length; i++) {
            projectJson[this.requiredStates[i]] = JSON.parse(JSON.stringify(states[this.requiredStates[i]]));
        }

        return projectJson;
    }
}