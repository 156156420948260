export default {
  'en': 'English - Global',
  'en_au': 'English - Australian',
  'en_uk': 'English - UK',
  'en_us': 'English - US',
  'es': 'Spanish',
  'fr': "French",
  'de': "German",
  'it': "Italian",
  'pt': "Portuguese",
  'nl': 'Dutch',
  'hi': 'Hindi',
  'ja': 'Japanese',
  "zh": "Chinese",
  "fi": "Finnish",
  "ko": "Korean",
  "pl": "Polish",
  "ru": "Russian",
  "tr": "Turkish",
  "uk": "Ukrainian",
  "vi": "Vietnamese"
};