/* 
https://developer.mozilla.org/en-US/docs/Web/API/WebHID_API
*/

import { writable, get } from "svelte/store";
function createPedalStore(defaultValue) {
	const { subscribe, set, update } = writable(defaultValue);
    
	return {
		subscribe,
		set,
		update,
        selectDeviceByName(name){
            let pedalState = get(this);
            let index = pedalState.inputDevices.findIndex(d =>{
                return d.productName === name;
            });

            this.selectDeviceByIndex(index);
        },
        selectDeviceByIndex(index){
            let pedalState = get(this);

            if (index < 0){
                pedalState.enable = false;
                return;
            }

            
            this.enableDevice(pedalState.inputDevices[index]);            
        },
        enableDevice(device){
            let pedalState = get(this);
            if (!device){
                pedalState.enable = false;
                return;
            }

            pedalState.selectedInput = device;
            try {
                device.open();
            } catch(err){
                console.log(err.message);
            }
            
            device.addEventListener("inputreport", (event) => {
                //console.log(event);
                const { data } = event;
                const value = data.getUint8(0);

                //console.log(value);
                /* value mapping */
                /* 
                    2 = play
                    1 = fast forward
                    4 = rewind
                    0 = pause
                */

                if (!player){
                    return;
                }

                switch(pedalState.functionMapping[value]) {
                    case 'play':
                        setTimeout(function() {
                            player.play();
                        }, 0);
                        break;
                    case 'fastForward':
                        player.speed = 8;
                        setTimeout(function() {
                            player.play();
                        }, 0);
                        break;
                    case 'rewind':
                        this.rewindState = setInterval(() => {
                            player.rewind(0.25);
                            setTimeout(function() {
                                player.play();
                            }, 0);
                        }, 50);
                        
                        break;
                    case 'pause':
                        /* pause */
                        player.pause();
                        player.speed = 1;
                        if (this.rewindState) {
                            clearInterval(this.rewindState);
                            this.rewindState = undefined;
                        }
                        break;
                    default:
                        console.log("Unknown Value: " + value);
                }

            });
            
        },
		async togglePedalControl() {
			let pedalState = get(this);
			pedalState.enable = !pedalState.enable;
			pedalState.inputDevices = [];
			pedalState.selectedInput = undefined;

			if (pedalState.enable) {
                try {
                    await navigator.hid.requestDevice({ filters: [] });
                } catch(err){
                    console.log(err.message);
                }
				
				let devices = await navigator.hid.getDevices();
                let deviceProductIds = [];
				devices.forEach((device) => {
                    if (device.productName && deviceProductIds.indexOf(device.productId) === -1){
                        pedalState.inputDevices.push(device);
                        deviceProductIds.push(device.productId);
                    }					
				});				
			}

			set(pedalState);
		}
	};
}

export const pedalState = createPedalStore({
	enable: false,
	selectedInput: undefined,
	inputDevices: [],
    functionMapping : {
        0 : 'pause',
        1 : 'fastForward',
        2 : 'play',
        3 : 'none',
        4 : 'rewind'
    }
});
