<script>
import { onMount } from 'svelte';
import {
    environment
} from "@app/store/envStore.js";
import { toast } from '@zerodevx/svelte-toast';

let backupDestination = $environment.online ? "Cloud" : "Local"; //Cloud or Local
let targetFolder = ""; //Target Folder for Local Backup
let numberOfBackups = 5; //Number of backups to keep
let backupInterval = 15; //Backup Interval in minutes

onMount(() => {
    //Load settings from local storage
    let settings = JSON.parse(localStorage.getItem("cc-automatic-backup-settings"));
    if(settings){
        backupDestination = $environment.online ?  settings.backupDestination : "Local";
        targetFolder = settings.targetFolder || "";
        numberOfBackups = Math.min(5, settings.numberOfBackups);
        backupInterval =  Math.max(15, settings.backupInterval);
    }
});

//Save Changes to Local Storage
function saveChanges(){
    //Save changes to local storage
    localStorage.setItem("cc-automatic-backup-settings", JSON.stringify({
        backupDestination: backupDestination,
        targetFolder : targetFolder,
        numberOfBackups : numberOfBackups,
        backupInterval : backupInterval
    }));

    toast.push("Please restart the application for changes to take effect.", {
        classes: ["toast-warning"]
    });
    toast.push("Automatic backup settings updated.", {
        classes: ["toast-success"]
    });
    
}

function selectTargetFolder() {
    ipcRenderer.invoke('selectFolder', {
        title: "Automatic Backup - Select Folder",
        properties: ["openDirectory", "createDirectory"]
    }).then((path) => {
        if (!path.canceled) {
            targetFolder = path.filePaths[0];
        }
    });
}
</script>
<form on:submit|preventDefault="{saveChanges}">   
    <div class="mb-4">
        <label class="form-label" for="Project Type">Backup Destination</label>
        <select class="form-select" bind:value={backupDestination}>
            {#if $environment.online}
                <option>Cloud</option>
            {/if}
            {#if $environment.electron}
                <option>Local</option>
            {/if}
        </select>
    </div>    
    {#if backupDestination === "Local"}
    <div class="p-3 mb-3 border rounded">
        <button type="button" class="btn btn-info text-white" on:click="{selectTargetFolder}"><i class="bi bi-folder"></i> Target Folder</button>
        <p class="form-text text-muted text-truncate mt-2 mb-0">{targetFolder || ""}</p>
        {#if !targetFolder}
            <p class="form-text text-muted m-0">Select a target location that is used for automatic backups.</p>
        {/if}
    </div>
    <div class="mb-3">
        <label class="form-label" for="Number of Backups">Number of Backups to Keep</label>
        <input type="number" class="form-control" bind:value={numberOfBackups} min="1" max="10">
    </div>
    {/if}    
    <div class="mb-3">
        <label class="form-label" for="Backup Interval">Backup Interval (minutes)</label>
        <input type="number" class="form-control" bind:value={backupInterval} min="10" max="999">
    </div>
    
    <button class="btn btn-primary float-end" type="button" on:click={saveChanges}>Save Settings</button>
</form>