<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';
import convertToPlainTextCustom from '@app/external/cc-lib/dist/functions/quill/convertToPlainTextCustom.js';
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
import Swal from "sweetalert2";

const mergeEvents = async function() {
    try {
        if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0) {
            //If there is more than one event selected, ask the user if they want to merge all of the selected events. If there is only one event selected, then ask the user if they wish to merge the selected event with the event before it or after it.
            if ($eventGroupState[$projectState.selected].selected.length > 1) {
                let swalRes = await Swal.fire({
                    title: "Merge Events",
                    text: "Are you sure you want to merge the selected events?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33"
                });

                if (swalRes.isConfirmed) {
                    mergeSelectedEvents();
                } else {
                    return;
                }

            } else {
                let swalRes = await Swal.fire({
                    title: "Merge Events",
                    text: "Would you like to merge the selected event with the event before or after it?",
                    showCancelButton: true,
                    showDenyButton: true,
                    confirmButtonText: "Before",
                    denyButtonText: "After",
                    cancelButtonText: "Cancel"
                });

                if (swalRes.isConfirmed) {
                    if ($eventGroupState[$projectState.selected].selected[0] > 0) {
                        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0] - 1, $eventGroupState[$projectState.selected].selected[0]];
                    } else {
                        return;
                    }
                } else if (swalRes.isDenied) {
                    if ($eventGroupState[$projectState.selected].selected[0] < $eventGroupState[$projectState.selected].events.length - 1) {
                        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0], $eventGroupState[$projectState.selected].selected[0] + 1];
                    } else {
                        return;
                    }
                } else {
                    return;
                }

                mergeSelectedEvents();
            }

        }
    } catch (err) {
        console.log(err, err.message);

    }
};

const mergeSelectedEvents = async function() {
    try {
        let text = "";
        /* Reverse sort by index */
        let sortedEvents = $eventGroupState[$projectState.selected].selected.sort((a, b) => {
            return b - a;
        });

        /* Save the selected end time of the last event */
        let lastEnd = $eventGroupState[$projectState.selected].events[sortedEvents[0]].end;

        sortedEvents.forEach((eventIndex, selectedIndex) => {
            text = convertToPlainTextCustom($eventGroupState[$projectState.selected].events[eventIndex].text) + "\n" + convertToPlainTextCustom(text);
            if (selectedIndex < sortedEvents.length - 1) {
                $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected], eventIndex);
            }
        });

        $eventGroupState[$projectState.selected].events[sortedEvents[sortedEvents.length - 1]].text = convertToHtml(text);
        $eventGroupState[$projectState.selected].events[sortedEvents[sortedEvents.length - 1]].end = lastEnd || $eventGroupState[$projectState.selected].events[sortedEvents[sortedEvents.length - 1]].end;
        $eventGroupState[$projectState.selected].selected = [];

        historyState.insert({
            name: "merge events", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err) {
        console.log(err, err.message);
    }
};
</script>
<li class="nav-item" title="Merge Event">
    <a 
        class="nav-link" 
        href="#!/" 
        id="merge-events-btn"
        role="button"
        aria-label="Merge selected events"
        aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
        class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}  
        on:click="{mergeEvents}"
    ><i class="bi bi-view-list"></i><i class="bi bi-chevron-contract small"></i></a>
</li>