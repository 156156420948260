import _cptable from "codepage";
const cptable = _cptable;
export default {
  // Decode from Hex
  decodeChar: function (charCode, codePage) {
    try {
      let char = cptable[codePage].dec[parseInt(charCode, 16)];
      return char;
    } catch (e) {
      console.log(e.message);
      return "";
    }
  },
  /* Takes array of  hex values and returns a smpteTC: [ "e8", "03", "64", "00" ] -> 10:00:01:00*/
  decodeTc: function (hexTc) {
    let timecode = [];
    hexTc.forEach(tcElement => {
      timecode.push(parseInt(tcElement, 16).toString().padStart(2, "0"));
    });
    return timecode.join(":");
  },
  charLookup: {
    "81": '♪',
    "82": 'á',
    "83": 'é',
    "84": 'í',
    "85": 'ó',
    "86": 'ú',
    "87": 'â',
    "88": 'ê',
    "89": 'î',
    "8a": 'ô',
    "8b": 'û',
    "8c": 'à',
    "8d": 'è',
    "8e": 'Ñ',
    "8f": 'ñ',
    "90": 'ç',
    "91": '¢',
    "92": '£',
    "93": '¿',
    "94": '½',
    "95": '®'
  },
  codePages: ["37", "437", "500", "620", "708", "720", "737", "775", "808", "850", "852", "855", "857", "858", "860", "861", "862", "863", "864", "865", "866", "869", "870", "872", "874", "875", "895", "932", "936", "949", "950", "1010", "1026", "1047", "1132", "1140", "1141", "1142", "1143", "1144", "1145", "1146", "1147", "1148", "1149", "1200", "1201", "1250", "1251", "1252", "1253", "1254", "1255", "1256", "1257", "1258", "1361", "10000", "10001", "10002", "10003", "10004", "10005", "10006", "10007", "10008", "10010", "10017", "10021", "10029", "10079", "10081", "10082", "12000", "12001", "20000", "20001", "20002", "20003", "20004", "20005", "20105", "20106", "20107", "20108", "20127", "20261", "20269", "20273", "20277", "20278", "20280", "20284", "20285", "20290", "20297", "20420", "20423", "20424", "20833", "20838", "20866", "20871", "20880", "20905", "20924", "20932", "20936", "20949", "21025", "21027", "21866", "28591", "28592", "28593", "28594", "28595", "28596", "28597", "28598", "28599", "28600", "28601", "28603", "28604", "28605", "28606", "29001", "38598", "47451", "50220", "50221", "50222", "50225", "50227", "51932", "51936", "51949", "52936", "54936", "57002", "57003", "57004", "57005", "57006", "57007", "57008", "57009", "57010", "57011", "65000", "6500"],
  // Encode timecode to hex array format
  encodeTc: function (tcString, frameRate) {
    const tc = tcString.split(':');
    const hours = parseInt(tc[0]);
    const minutes = parseInt(tc[1]);
    const seconds = parseInt(tc[2]);
    let frames = 0;
    if (tc[3]) {
      frames = parseInt(tc[3]);
    } else if (tc[2].includes('.')) {
      const secParts = tc[2].split('.');
      frames = Math.round(parseFloat('0.' + secParts[1]) * frameRate);
    }
    return [hours, minutes, seconds, frames];
  },
  // Encode character to specific code page
  encodeChar: function (char, codePage) {
    try {
      if (this.charLookup[char]) {
        return this.charLookup[char];
      }
      return cptable[codePage].enc[char.charCodeAt(0)];
    } catch (e) {
      console.warn(`Warning: Could not encode character '${char}' in codepage ${codePage}`);
      return 0x20; // Space as fallback
    }
  }
};