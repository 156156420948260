export default (function shiftWordBack(event1, event2, maxChars = 32) {
  // Create a DOM parser to work with HTML
  const parser = new DOMParser();

  // Parse both event texts into DOM
  const doc1 = parser.parseFromString(event1.text, 'text/html');
  const doc2 = parser.parseFromString(event2.text, 'text/html');

  // Get all paragraphs from second event
  const secondEventParagraphs = doc2.getElementsByTagName('p');
  if (secondEventParagraphs.length === 0) {
    return {
      event1,
      event2
    }; // No paragraphs to process
  }

  // Get the first paragraph with content
  const firstParagraph = secondEventParagraphs[0];

  // Get all text nodes and their wrapping elements
  const textNodes = [];
  function traverseNodes(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.trim()) {
        textNodes.push({
          node,
          parent: node.parentNode
        });
      }
    } else {
      node.childNodes.forEach(child => traverseNodes(child));
    }
  }
  traverseNodes(firstParagraph);
  if (textNodes.length === 0) {
    return {
      event1,
      event2
    }; // No text content to process
  }

  // Get the first word from the first text node
  const firstTextNode = textNodes[0].node;
  const words = firstTextNode.textContent.trim().split(/\s+/);
  const firstWord = words[0];
  const remainingWords = words.slice(1).join(' ');

  // Update the second event's text
  if (remainingWords) {
    firstTextNode.textContent = remainingWords + ' ';
  } else {
    // Remove the text node if it's empty
    const parent = textNodes[0].parent;
    parent.removeChild(firstTextNode);

    // If the parent element is now empty and it's not the paragraph itself,
    // remove the parent element
    if (parent !== firstParagraph && !parent.hasChildNodes()) {
      parent.parentNode.removeChild(parent);
    }
  }

  // If the paragraph is now empty, remove it
  if (!firstParagraph.textContent.trim()) {
    firstParagraph.parentNode.removeChild(firstParagraph);
  }

  // Add the word to the first event
  const doc1Paragraphs = doc1.getElementsByTagName('p');
  let lastParagraph = doc1Paragraphs[doc1Paragraphs.length - 1];

  // If there's no paragraph in the first event, create one
  if (!lastParagraph) {
    lastParagraph = doc1.createElement('p');
    doc1.body.appendChild(lastParagraph);
  }

  // Check if adding the word would exceed maxChars
  const currentText = lastParagraph.textContent;
  if (currentText.length + firstWord.length + 1 > maxChars) {
    // Create new paragraph for the word
    const newParagraph = doc1.createElement('p');
    newParagraph.textContent = firstWord;
    doc1.body.appendChild(newParagraph);
  } else {
    // Add space if paragraph already has content
    if (currentText.length > 0) {
      lastParagraph.appendChild(doc1.createTextNode(' '));
    }
    lastParagraph.appendChild(doc1.createTextNode(firstWord));
  }

  // Update both events with new HTML
  event1.text = doc1.body.innerHTML;
  event2.text = doc2.body.innerHTML;
  return {
    event1,
    event2
  };
});