<script>
    /* States */
    import { explorerState } from "@app/store/explorerStore.js";
    /* Components */
    import Breadcrumbs from "./Breadcrumbs.svelte";
    import DisplayToggles from "./DisplayToggles.svelte";
    import TableView from "./workspaces/TableView.svelte";
    import ThumbnailView from "./workspaces/ThumbnailView.svelte";
    import KanbanView from "./workspaces/KanbanView.svelte";
</script>

<div id="workspaceWrapper" class="col-7">
    <div class="bg-light rounded p-3 h-100 d-flex flex-column">
        <div class="row mb-2">
            <Breadcrumbs />
            <DisplayToggles />
        </div>
        <div class="flex-grow-1">
            {#if $explorerState.view === 'table'}
                <TableView />
            {:else if $explorerState.view === 'thumbnail'}
                <ThumbnailView />
            {:else if $explorerState.view === 'kanban'}
                <KanbanView />
            {/if}
        </div>        
    </div>
</div>

<style>
    #workspaceWrapper {
        max-height: 90vh;
        height: 90vh;
    }
</style>