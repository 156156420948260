export default (function convertMaptoSegments(missingDialogueMap) {
  //Loop over the missing dialogue map and convert it into segments of missing dialogue. If the next word is within 2 seconds then we can assume that it is part of the same segment. If it is more than 2 seconds then we can assume that it is a new segment.
  let segmentInfo = [];
  let currentSegment = {
    start: false,
    end: false,
    text: "",
    words: []
  };
  missingDialogueMap.forEach((word, index) => {
    if (currentSegment.words.length > 0 && word.start - currentSegment.words[currentSegment.words.length - 1].end >= 2) {
      //console.log("New Segment");
      //console.log(JSON.stringify(currentSegment, null, 4));
      segmentInfo.push(JSON.parse(JSON.stringify(currentSegment)));
      currentSegment = {
        start: false,
        end: false,
        text: "",
        words: []
      };
    }
    currentSegment.words.push(word);
    currentSegment.text += word.text + " ";
  });
  if (currentSegment.start) {
    segmentInfo.push(JSON.parse(JSON.stringify(currentSegment)));
  }
  segmentInfo.forEach(segment => {
    segment.start = segment.words[0].start;
    segment.end = segment.words[segment.words.length - 1].end;
    segment.text = segment.text.trim();
  });

  //console.log(`Missing Dialogue Segments: ${segmentInfo.length}`);
  //console.log(JSON.stringify(segmentInfo, null, 4));
  return segmentInfo;
});