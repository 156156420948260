import _eol from "eol";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _stripTags from "../functions/quill/stripTags.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _Event from "../classes/event.js";
const Event = _Event;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const stripTags = _stripTags;
const convertToPlainText = _convertToPlainText;
const eol = _eol;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent;
    let fileLines = input.split("\n").filter(fileLine => {
      return fileLine;
    });
    fileLines.forEach(fileLine => {
      if (/Start=\d+>/.test(fileLine)) {
        ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.10
        });
        let ms = fileLine.match(/\d+/);
        ccEvent.start = ms / 1000;
        if (events.length > 0) {
          events[events.length - 1].end = ms / 1000;
        }
        ccEvent.text = convertToHtml(stripTags(fileLine.replace(/<br>/gmi, "\n")));
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = `<SAMI>
<HEAD>
<TITLE>Untitled</TITLE>
<SAMIParam>
    Metrics {time:ms;}
    Spec {MSFT:1.0;}
</SAMIParam>
<STYLE TYPE="text/css">
<!--
    P { font-family: Arial; font-weight: normal; color: white; background-color: black; text-align: center; }
    .ENUSCC { name: English; lang: en-US ; SAMIType: CC ; }
-->
</STYLE>
</HEAD>
<BODY>
<-- Open play menu, choose Captions and Subtiles, On if available -->
<-- Open tools menu, Security, Show local captions when present -->`;
    let previousEndTime = 0;
    eventGroup.events.forEach(function (event) {
      const textLine = convertToPlainText(event.text).replace(/\n/g, "<br>");
      if (parseFloat(event.start) * 1000 !== previousEndTime && previousEndTime !== 0) {
        output += `<SYNC Start=${previousEndTime}>\n`;
      }
      output += `<SYNC Start=${parseFloat(event.start) * 1000}>${textLine}\n`;
      previousEndTime = parseFloat(event.end) * 1000;
    });
    output += "</BODY>\n</SAMI>";
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};