<script>
    import {pedalState} from '@app/store/pedalStore.js';

    async function toggleFootPedalControl(){
        await pedalState.togglePedalControl()
        pedalState.selectDeviceByIndex(0);
        saveSettingsToLocalStorage();
    }

    function selectFootPedalDevice(){
        console.log($pedalState.selectedInput);
        pedalState.selectDeviceByName($pedalState.selectedInput.productName);
        saveSettingsToLocalStorage();
    }

    function saveSettingsToLocalStorage(){
        console.log("Saving Foot Pedal Settings to Local Storage");
        localStorage.setItem("cc-device-defaults", JSON.stringify({
            enable : $pedalState.enable,
            deviceName : $pedalState.selectedInput ? $pedalState.selectedInput.productName : "",
            functionMapping : $pedalState.functionMapping
        }));
    }
    </script>
    
    <h5>Foot Pedal Settings</h5>
    <form>
        <div class="my-4 form-check form-switch">
            <input type="checkbox" class="form-check-input" id="footPedalEnabledCheck" checked={$pedalState.enable}  on:change={() => toggleFootPedalControl()}>
            <label class="form-check-label" for="footPedalEnabledCheck">Enable Foot Pedal Control <i class="bi bi-info-circle" title="Enable or disable foot pedal control."></i></label>
        </div>
        
        <div class="mb-3">
            <label for="footPedaliInputDeviceSelect" class="form-label">Input Device</label>
            <select class="form-select" id="footPedaliInputDeviceSelect" bind:value={$pedalState.selectedInput} on:change="{() => selectFootPedalDevice()}">
                {#each $pedalState.inputDevices as inputDevice (inputDevice.productId)}
                    <option value="{inputDevice}">{inputDevice.productName}</option>
                {/each}
            </select>
        </div>
        <h5>Function Mapping</h5>
        <p class="text-muted small">Some foot pedal models may require the default function mappings to be updated.</p>
        <table class="table">
            <thead>
              <tr>
                <th scope="col"># Value</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                <td>0</td>
                <td>
                    <select class="form-select" bind:value="{$pedalState.functionMapping[0]}" on:change="{() => saveSettingsToLocalStorage()}">
                        <option value="pause">Pause</option>
                        <option value="play">Play</option>
                        <option value="rewind">Rewind</option>
                        <option value="fastForward">Fast Forward</option>
                        <option value="none">None</option>
                    </select>
                </td>
                </tr>
              <tr>
                <td>1</td>
                <td>
                    <select class="form-select" bind:value="{$pedalState.functionMapping[1]}" on:change="{() => saveSettingsToLocalStorage()}">
                        <option value="play">Play/Pause</option>
                        <option value="rewind">Rewind</option>
                        <option value="fastForward">Fast Forward</option>
                        <option value="none">None</option>
                    </select>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                    <select class="form-select" bind:value="{$pedalState.functionMapping[2]}" on:change="{() => saveSettingsToLocalStorage()}">
                        <option value="play">Play/Pause</option>
                        <option value="rewind">Rewind</option>
                        <option value="fastForward">Fast Forward</option>
                        <option value="none">None</option>
                    </select>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                    <select class="form-select" bind:value="{$pedalState.functionMapping[3]}" on:change="{() => saveSettingsToLocalStorage()}">
                        <option value="play">Play/Pause</option>
                        <option value="rewind">Rewind</option>
                        <option value="fastForward">Fast Forward</option>
                        <option value="none">None</option>
                    </select>
                </td>
              </tr> 
              <tr>
                <td>4</td>
                <td>
                    <select class="form-select" bind:value="{$pedalState.functionMapping[4]}" on:change="{() => saveSettingsToLocalStorage()}">
                        <option value="play">Play/Pause</option>
                        <option value="rewind">Rewind</option>
                        <option value="fastForward">Fast Forward</option>
                        <option value="none">None</option>
                    </select>
                </td>
              </tr>             
            </tbody>
          </table>
    </form>
    