<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

function approveEvents(approval) {
    try {
        $eventGroupState[$projectState.selected].selected.forEach(selectedEventIndex => {
            $eventGroupState[$projectState.selected].events[selectedEventIndex].approved = approval;
        });

        historyState.insert({
            name: "approve events", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }
}
</script>

<a id="btnApproveEvents"
   role="menuitem"
   aria-label="Approve selected events"
   class="dropdown-item" href="#!/" on:click={() => approveEvents(true)}>
   Approve
</a>
<a id="btnFailEvents"
   role="menuitem"
   aria-label="Fail selected events"
   class="dropdown-item" href="#!/" on:click={() => approveEvents(false)}>
   Fail
</a>