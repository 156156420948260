<script>
import { uiState } from "@app/store/uiStore.js";
import {
    modalState
} from "@app/store/modalStore.js";
import {
    externalDataState
} from "@app/store/externalDataStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    fade
} from "svelte/transition";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    playerState
} from "@app/store/playerStore.js";
import {
    markerState
} from '@app/store/markerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    speakerState
} from "@app/store/speakerStore.js";
import {
    metadataState
} from "@app/store/metadataStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    onMount
} from "svelte";

/* Firebase */
import firebase from '@app/configs/firebase.js';

import _Event from "@app/external/cc-lib/dist/classes/event.js";
import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
import _Project from "@app/external/cc-lib/dist/classes/project.js";
import loadMedia from "@app/external/cc-lib/dist/functions/projects/loadMedia.js";;

let progress = 0,
    statusMsg = "Starting import...";

async function updateStatus(statusInfo) {
    progress = statusInfo.progress;
    statusMsg = statusInfo.msg;
}

async function getFileSize() {
    try {
        const response = await fetch($projectState.media.path, {
            method: "HEAD",
        });
        const fileSize = response.headers.get("content-length");
        return fileSize;
    } catch (err){
        return false;
    }    
}

onMount(async () => {
    try {
        let projectInfo, assetId;

        await updateStatus({
            progress: 10,
            msg: "Receiving project from Iconik...",
        });

        $projectState.selected = false;
        $eventGroupState = [];
        $issueState = [];
        $markerState = {
            selected : 0,
            lists : [
                {
                    id : '0',
                    name : "Shot Changes",
                    color : "#E74C3C",
                    markers : []
                }
            ]
        };
        $speakerState = [];
        $metadataState = [];
        $uiState.timeline = false;
        playerState.updateDuration(false);

        await updateStatus({
            progress: 25,
            msg: "Getting storage info...",
        });

        let getStorageRes = await firebase.functions().httpsCallable("v8IconikGetStorageList")();
        //console.log(getStorageRes);
        //console.log(JSON.parse(getStorageRes.data));
        let iconikStorages = JSON.parse(getStorageRes.data).objects;
        // console.log(iconikStorages);
        iconikStorages = iconikStorages.filter((s) =>{
            return s.purpose === "FILES" && s.status === "ACTIVE";
        });

        // console.log(iconikStorages);

        await updateStatus({
            progress: 65,
            msg: "Loading project info... please wait a moment.",
        });

        console.info("DEBUG: Parsing URL params from URL: " + window.location.href);
        let urlString = window.location.href;
        let url = new URL(urlString);
        assetId = url.searchParams.get("asset_ids");
        console.info("DEBUG: Asset id is set to " + assetId);

        if (!assetId) {
            throw new Error("Unable to get Asset ID, or Token Id.");
        }

        let projectRes = await firebase.functions().httpsCallable("v8IconikImport")(assetId);
        // console.log(projectRes);
        projectInfo = JSON.parse(projectRes.data);

        await updateStatus({
            progress: 35,
            msg: "Creating project...",
        });

        console.info(`DEBUG: Creating new project.`);

        $externalDataState = {
            platform : "Iconik",
            projectName : projectInfo.name,
            frameRate : projectInfo.frameRate,
            dropFrame : projectInfo.dropFrame,
            assetId : assetId,
            mediaUrl : projectInfo.media.path,
            storages : iconikStorages
        };

        console.info("EXTERNAL DATA STORE: ", JSON.stringify($externalDataState, null, 4));

        await updateStatus({
            progress: 50,
            msg: "Loading project...",
        });
        
        $projectState = new _Project({
            ...projectInfo,
            media : {},
            eventGroups : [],
            username : firebase.auth().currentUser.email,
            ownerId : firebase.auth().currentUser.uid,
            selected : false
        });

        projectInfo.eventGroups.forEach(evg =>{
            $eventGroupState.push(new _EventGroup(evg));
            $eventGroupState[$eventGroupState.length-1].extId = evg.extId;
        });

        $projectState.selected = 0;   
        
        await updateStatus({
            progress: 75,
            msg: "Loading media...",
        });

        $projectState = loadMedia($projectState, {
			projectName: projectInfo.name,
			storage: projectInfo.media.storage,
			mediaUrl: projectInfo.media.path,
			incode: 0,
			frameRate: projectInfo.frameRate,
			dropFrame: projectInfo.dropFrame,
			aspectRatio: "16/9",
		});

        let mediaType = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type; 
        
        let fileSize = await getFileSize($projectState.media.path);
        if (fileSize) {
            const sizeInBytes = parseInt(fileSize);
            const sizeInKilobytes = sizeInBytes / 1024;
            const sizeInMegabytes = sizeInKilobytes / 1024;
            if (sizeInMegabytes < 512) {
                $projectState.media.useFallback = false;
            }
        }

        player.src = { src: $projectState.media.path, type: mediaType};

        await updateStatus({
            progress: 100,
            msg: "Done",
        });
        
        toast.push(`Project import completed successfully`, {classes: ['toast-success']});
        
        setTimeout(() => {
            $uiState.timeline = true;
            modalState.hideModal();
        }, 2500);
    } catch (err) {
        console.log(err);
        console.log(err.message);
        toast.push(`External Project Import Failed. ${err.message}`, {classes: ['toast-danger']});  
        modalState.hideModal();      
    }
});
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'iconikImport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="LoadExternalProjectModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Iconik Import</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <p><i class="bi bi-exclamation-diamond-fill" /> {statusMsg}</p>
                <div class="progress">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                </div>
            </div>
        </div>
    </div>
</div>
