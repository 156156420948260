<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from "@app/store/historyStore.js";

function assignSpeakerVoice(speaker) {
    if ($eventGroupState[$projectState.selected].selected.length > 0 && $eventGroupState[$projectState.selected]) {
        $eventGroupState[$projectState.selected].selected.forEach(eventId => {
            if ($eventGroupState[$projectState.selected].events[eventId]) {
                $eventGroupState[$projectState.selected].events[eventId].speakers = [...$eventGroupState[$projectState.selected].events[eventId].speakers, speaker].filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.place === value.place && t.name === value.name
                    ))
                );
            }
        });

        toast.push("Event speaker updated successfully", {classes: ['toast-success']});

        historyState.insert({
            name: "speaker update", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    }
}

function assignRecipientVoice(speaker) {
    if ($eventGroupState[$projectState.selected].selected.length > 0 && $eventGroupState[$projectState.selected]) {
        $eventGroupState[$projectState.selected].selected.forEach(eventId => {
            if ($eventGroupState[$projectState.selected].events[eventId]) {
                $eventGroupState[$projectState.selected].events[eventId].recipients = [...$eventGroupState[$projectState.selected].events[eventId].recipients, speaker].filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.place === value.place && t.name === value.name
                    ))
                );
            }
        });

        toast.push("Event speaker updated successfully", {classes: ['toast-success']});

        historyState.insert({
            name: "speaker update", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    }
}
</script>

<div id='SpeakerWrapper' class="rounded" role="region" aria-label="Speaker management">
    <div class="row p-3">
        <div id="SpeakerList" class="col-11 resize-vertical" role="listbox" aria-label="Available speakers">
            <div class="row g-2">
                {#each $speakerState as speaker}
                <div class="col-3">
                    <div class="speakerCard p-2 text-center bg-light rounded" role="listitem">
                        <h1 style="color:{speaker.colour}; font-size: 2vw; margin:0;" aria-hidden="true"><i class="bi bi-person-fill"></i></h1>
                        <p style="color:{speaker.colour}" class="small m-0 text-capitalize text-truncate" title='{speaker.name}'>{speaker.name}</p>
                        <div class="btn-group btn-group-sm" role="group" aria-label="Speaker actions">
                            <button 
                                type="button" 
                                class="btn btn-secondary text-custom-medium" 
                                title="Add Speaker" 
                                id="addSpeaker_{speaker.id}"
                                aria-label="Add {speaker.name} as speaker"
                                on:click="{() => assignSpeakerVoice(speaker)}">
                                <i class="bi bi-plus" aria-hidden="true"></i> SP
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-secondary text-custom-medium" 
                                title="Add Recipient" 
                                id="addRecipient_{speaker.id}"
                                aria-label="Add {speaker.name} as recipient"
                                on:click="{() => assignRecipientVoice(speaker)}">
                                <i class="bi bi-plus" aria-hidden="true"></i> RE
                            </button>
                        </div>                        
                    </div>
                </div>
                {:else}
                <p class="m-3 text-muted" role="alert">No speakers found</p>
                {/each}
            </div>
        </div>
        <div class="col-1 p-2">
            <button 
                type="button" 
                class="btn btn-light btn-sm h-100 float-end" 
                id="manageSpeakers"
                aria-label="Manage speakers"
                on:click={() => modalState.showModal("speakerManager")}>
                <i class="bi bi-three-dots" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>

<style>
#SpeakerWrapper {
    background-color: rgba(255, 255, 255, 0.03);
}

.speakerCard .btn-group {
    display : none;
}

.speakerCard:hover .btn-group {
    display : block;
}
</style>
