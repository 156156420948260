export default {
  colors: ["black", "red", "green", "yellow", "blue", "magenta", "cyan", "white"],
  hexColorMapping: {
    "black": "#000000",
    "red": "#FF0000",
    "green": "#00FF00",
    "yellow": "#FFFF00",
    "blue": "#0000FF",
    "magenta": "#FF00FF",
    "cyan": "#00FFFF",
    "white": "#FFFFFF"
  }
};