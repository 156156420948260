import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class Tag {
  constructor(options = {
    term: 'Unknown',
    type: "Character",
    //Character, Location, Organization, Phrase, Other
    definition: "",
    color: "info" //info, light, warning, success, secondary
  }) {
    this.id = options.id || uuidv4();
    this.term = options.term || "Unknown";
    this.type = options.type || "Character";
    this.definition = options.definition || "";
    this.color = options.color || "info";
  }
});