export default (function extractStyleAttributes(spanTag) {
  // Regular expression to match the style attribute
  const styleRegex = /style\s*=\s*['"]([^'"]*)['"]/;

  // Extract the style attribute value
  const match = spanTag.match(styleRegex);
  const styleAttributeValue = match ? match[1] : "";

  // Split the style attribute value into individual key-value pairs
  const stylePairs = styleAttributeValue.split(";");

  // Create an object to store the style attributes
  const styleObject = {};

  // Populate the object with key-value pairs
  stylePairs.forEach(pair => {
    const [key, value] = pair.split(":");
    if (key && value) {
      styleObject[key.trim()] = value.trim();
    }
  });
  return styleObject;
});