<script>
    import { projectState } from "@app/store/projectStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { environment } from "@app/store/envStore.js";
    import FileMenu from "./menus/FileMenu.svelte";
    import EditMenu from "./menus/EditMenu.svelte";
    import FormatMenu from "./menus/FormatMenu.svelte";
    import TimecodeMenu from "./menus/TimecodeMenu.svelte";
    import InsertMenu from "./menus/InsertMenu.svelte";
    import AiToolsMenu from "./menus/AiToolsMenu.svelte";
    import WorkspacesMenu from "./menus/WorkspacesMenu.svelte";
    import TeamMenu from "./menus/TeamMenu.svelte";
    import ThemeMenu from "./menus/ThemeMenu.svelte";
    import HelpMenu from "./menus/HelpMenu.svelte";
    import CliMenu from "./menus/CliMenu.svelte";
    import Swal from "sweetalert2";
    import { clickOutside } from "svelte-use-click-outside";
    /* CC LIB */
    import _Speaker from "@app/external/cc-lib/dist/classes/speaker.js";
    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Project from "@app/external/cc-lib/dist/classes/project.js";
    import decode from "@app/external/cc-lib/dist/functions/decode.js";
    import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
    import alignCaptionFile from "@app/external/cc-lib/dist/functions/special/alignCaptionFile.js";
    import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
    
    let cachedMenu;
    let loading = false;
    let menuState = {
        file: false,
        edit: false,
        format: false,
        timecode: false,
        workspaces: false,
        theme: false,
        insert: false,
        aiTools: false,
        team: false,
        help: false,
        drastic: false,
    };

    function updateMenu(event) {
        closeMenus();
        setTimeout(() => {
            menuState[event.detail.menu] = !menuState[event.detail.menu];
            cachedMenu = event.detail.menu;
        }, 0);
    }

    function closeMenus() {
        if (cachedMenu) {
            menuState = {
                file: false,
                edit: false,
                format: false,
                timecode: false,
                insert: false,
                aiTools: false,
                team: false,
                help: false,
                theme: false,
                workspaces: false,
                cli: false,
            };

            cachedMenu = undefined;
        }
    }

    function updateSpeakerStore(evg) {
        evg.events.forEach((event, index, events) => {
            if (event.speakers && event.speakers.length > 0) {
                let speakerIndex = $speakerState.findIndex((sp) => {
                    return sp.name == "Speaker" + event.speakers[0];
                });

                if (speakerIndex === -1) {
                    let newSpeaker = new _Speaker({
                        name: "Speaker" + event.speakers[0],
                    });
                    $speakerState = [...$speakerState, newSpeaker];
                    events[index].speakers = [newSpeaker];
                } else {
                    events[index].speakers = [$speakerState[speakerIndex]];
                }
            }
        });
    }

    async function automaticSync() {
        try {
            if (loading) {
                return;
            }

            //Use Sweet alert to confirm that the user wants to proceed with the automatic sync process.
            let swalRes = await Swal.fire({
                title: "Automatic Sync",
                text: `This process will attempt to automatically sync the transcript with the current event group. Would you like to proceed?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            });


            if (!swalRes.isConfirmed) {
                return;
            }

            toast.push("Calculating sync timing... please wait...", {
                classes: ["toast-info"],
            });

            loading = true;
            let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};

            let transcripts = $externalDataState.transcripts.filter(
                (transcript, tIndex, allTranscripts) => {
                    if (transcript.language) {
                        return (
                            transcript.language.toLowerCase() ===
                            $eventGroupState[
                                $projectState.selected
                            ].language.toLowerCase()
                        );
                    } else if (allTranscripts.length === 1){
                        return true;
                    } else{
                        return false;
                    }
                },
            );

            if (transcripts.length === 0) {
                //Confirm with the user using Sweetalert that no transcript was found for the selected language.
                await Swal.fire({
                    title: "No Transcript Found",
                    text: `No transcript found for the selected language: ${$eventGroupState[$projectState.selected].language}.`,
                    icon: "warning",
                    confirmButtonText: "Ok",
                });

                toast.push(
                    "No transcript found for the selected language: " +
                        $eventGroupState[$projectState.selected].language,
                    {
                        classes: ["toast-danger"],
                    },
                );

                return;
            }

            //Fetch the transcript json from the first transcript object using the url property.
            let transcript = transcripts[0];
            let res = await fetch(transcript.url);
            let resJson = await res.json();
            let srcProfile = transcript.profile;
            let options = new defaults.options({
                profile: srcProfile,
                formatOptions: [
                    {
                        name: "Import Type",
                        type: "list",
                        values: ["subtitle", "transcription"],
                        selected: "word map",
                    },
                    {
                        name: "Max Characters",
                        type: "number-input",
                        values: "",
                        selected: 32,
                    },
                    {
                        name: "Max Lines",
                        type: "number-input",
                        values: "",
                        selected: 3,
                    },
                ],
                frameRate: $projectState.frameRate,
                dropFrame: $projectState.dropFrame
            });

            console.log(options);
            let wordMap = await decode(JSON.stringify(resJson), options);
            let eventGroup = JSON.parse(
                JSON.stringify($eventGroupState[$projectState.selected]),
            );

            if (eventGroup.events.length === 0) {
                return;
            }

            //Calculate missing timecodes so that they don't get filtered out by the alignCaptionFile function.
            eventGroup.events.forEach((event, i, events) => {
                events[i].start = i + 0.25;
                events[i].end = i + 0.75;
            });

            let alignmentRes = await alignCaptionFile(
                eventGroup,
                wordMap,
                $projectState.frameRate,
                false,
            );

            $eventGroupState[$projectState.selected].events =
                alignmentRes.eventGroup.events;

            if (alignmentRes.missingSegments.length > 0) {
                //Alert user of missing dialogue segments, and ask if they would like a new event group created with the missing dialogue.
                let swalRes = await Swal.fire({
                    title: "Missing Dialogue",
                    text: `The Alignment Process has detected missing dialogue. Would you like to create a new event group with the missing dialogue Events?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                });

                //If user selects yes, create a new event group with the missing dialogue segments and add it to the event group state.
                if (swalRes.isConfirmed) {
                    let newEventGroup = new _EventGroup({
                        name: "Missing Dialogue 0" + $eventGroupState.length,
                        type: "subtitle",
                        maxCps: eventGroupDefaults.maxCps || 9999,
                        maxWpm: eventGroupDefaults.maxWpm || 9999,
                        maxChars: eventGroupDefaults.maxChars || 9999,
                        maxLines: eventGroupDefaults.maxLines || 9999,
                        illegalChars: eventGroupDefaults.illegalChars,
                    });

                    alignmentRes.missingSegments.forEach((segment) => {
                        newEventGroup.events.push(
                            new _Event({
                                start: segment.start,
                                end: segment.end,
                                text: convertToHtml(segment.text),
                            }),
                        );
                    });

                    $eventGroupState = [...$eventGroupState, newEventGroup];
                }
            }

            historyState.insert({
                name: "automatic sync", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            toast.push("Timing applied successfully", {
                classes: ["toast-success"],
            });
        } catch (err) {
            console.log(err, err.message);
        } finally {
            loading = false;
        }
    }

    async function automaticSpeakerId() {
        try {
            if (loading) {
                return;
            }

            //Use Sweet alert to confirm that the user wants to proceed with the automatic sync process.
            let swalRes = await Swal.fire({
                title: "Automatic Speaker ID",
                text: `This process will attempt to automatically assign speakers to the Events in the current Event Group. Would you like to proceed?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            });

            if (!swalRes.isConfirmed) {
                return;
            }

            toast.push("Calculating speakers of events... please wait...", {
                classes: ["toast-info"],
            });

            loading = true;

            let transcripts = $externalDataState.transcripts.filter(
                (transcript, tIndex, allTranscripts) => {
                    if (transcript.language) {
                        return (
                            transcript.language.toLowerCase() ===
                            $eventGroupState[
                                $projectState.selected
                            ].language.toLowerCase()
                        );
                    } else if (allTranscripts.length === 1){
                        return true;
                    } else{
                        return false;
                    }
                },
            );

            if (transcripts.length === 0) {
                //Confirm with the user using Sweetalert that no transcript was found for the selected language.
                await Swal.fire({
                    title: "No Transcript Found",
                    text: `No transcript found for the selected language: ${$eventGroupState[$projectState.selected].language}.`,
                    icon: "warning",
                    confirmButtonText: "Ok",
                });

                toast.push(
                    "No transcript found for the selected language: " +
                        $eventGroupState[$projectState.selected].language,
                    {
                        classes: ["toast-danger"],
                    },
                );

                return;
            }

            //Fetch the transcript json from the first transcript object using the url property.
            let transcript = transcripts[0];
            let res = await fetch(transcript.url);
            let resJson = await res.json();
            let srcProfile = transcript.profile;
            let options = new defaults.options({
                profile: srcProfile,
                formatOptions: [
                    {
                        name: "Import Type",
                        type: "list",
                        values: ["subtitle", "transcription"],
                        selected: "word map",
                    },
                    {
                        name: "Max Characters",
                        type: "number-input",
                        values: "",
                        selected: 32,
                    },
                    {
                        name: "Max Lines",
                        type: "number-input",
                        values: "",
                        selected: 3,
                    },
                ],
                frameRate: $projectState.frameRate,
                dropFrame: $projectState.dropFrame
            });

            console.log(options);
            let wordMap = await decode(JSON.stringify(resJson), options);
            let eventGroup = JSON.parse(
                JSON.stringify($eventGroupState[$projectState.selected]),
            );

            if (eventGroup.events.length === 0) {
                return;
            }

            //Calculate missing timecodes so that they don't get filtered out by the alignCaptionFile function.
            eventGroup.events.forEach((event, i, events) => {
                events[i].start = i + 0.25;
                events[i].end = i + 0.75;
            });

            let alignmentRes = await alignCaptionFile(
                eventGroup,
                wordMap,
                $projectState.frameRate,
                false,
            );

            $eventGroupState[$projectState.selected].events.forEach(
                (event, i, events) => {
                    events[i].speakers = [];
                },
            );

            //Assign speakers to the events in the selected event group.
            $eventGroupState[$projectState.selected].events.forEach(
                (event, index, events) => {
                    let eventMatch = alignmentRes.eventGroup.events.find(
                        (e) => {
                            return e.id === event.id;
                        },
                    );

                    if (
                        eventMatch &&
                        eventMatch.matches &&
                        eventMatch.matches.length > 0
                    ) {
                        let speakers = eventMatch.matches.map((m) => {
                            return m.speakers[0];
                        });

                        let speakerCount = speakers.reduce((acc, speaker) => {
                            acc[speaker] = (acc[speaker] || 0) + 1;
                            return acc;
                        }, {});

                        let maxSpeaker = Object.keys(speakerCount).reduce(
                            (a, b) =>
                                speakerCount[a] > speakerCount[b] ? a : b,
                        );

                        events[index].speakers = [maxSpeaker];
                    }
                },
            );

            //Call the update speaker store function to update the speaker store with the new speakers.
            updateSpeakerStore($eventGroupState[$projectState.selected]);
            $eventGroupState[$projectState.selected] = $eventGroupState[$projectState.selected];

            historyState.insert({
                name: "auto assign speakers", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            historyState.insert({
                name: "auto assign speakers", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "speakerState",
                        value: JSON.stringify($speakerState),
                    },
                ],
            });

            toast.push("Auto Assign Speakers Complete", {
                classes: ["toast-success"],
            });
        } catch (err) {
            console.log(err, err.message);
        } finally {
            loading = false;
        }
    }
</script>

<nav
    aria-label="Primary Toolbar"
    class="navbar navbar-expand bg-light shadow"
    style="height: 3vh;"
    use:clickOutside={closeMenus}
>
    <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navcol-1">
            <ul class="nav navbar-nav">
                <FileMenu
                    currentState={menuState.file}
                    on:toggleMenu={updateMenu}
                ></FileMenu>
                <EditMenu
                    currentState={menuState.edit}
                    on:toggleMenu={updateMenu}
                ></EditMenu>
                <FormatMenu
                    currentState={menuState.format}
                    on:toggleMenu={updateMenu}
                ></FormatMenu>
                <TimecodeMenu
                    currentState={menuState.timecode}
                    on:toggleMenu={updateMenu}
                ></TimecodeMenu>
                <InsertMenu
                    currentState={menuState.insert}
                    on:toggleMenu={updateMenu}
                ></InsertMenu>
                <AiToolsMenu
                    currentState={menuState.aiTools}
                    on:toggleMenu={updateMenu}
                ></AiToolsMenu>
                <WorkspacesMenu
                    currentState={menuState.workspaces}
                    on:toggleMenu={updateMenu}
                ></WorkspacesMenu>
                {#if $environment.electron}
                    <CliMenu
                        currentState={menuState.cli}
                        on:toggleMenu={updateMenu}
                    ></CliMenu>
                {/if}
                <HelpMenu
                    currentState={menuState.help}
                    on:toggleMenu={updateMenu}
                ></HelpMenu>
            </ul>
            <ul class="nav navbar-nav ms-auto">
                <div class="vr align-self-center"></div>
                <ThemeMenu
                    currentState={menuState.theme}
                    on:toggleMenu={updateMenu}
                ></ThemeMenu>
                <li class="nav-item" title="Show/Hide Timeline">
                    <a
                        class="nav-link {$uiState.timeline
                            ? 'text-primary'
                            : 'text-muted'}"
                        href="#!/"
                        on:click={() =>
                            ($uiState.timeline = !$uiState.timeline)}
                        ><i class="bi bi-input-cursor"></i></a
                    >
                </li>
                <!-- List Preview -->
                <li class="nav-item" title="Toggle Prev/Next Event View">
                    <a
                        class="nav-link {$uiState.listPreview
                            ? 'text-primary'
                            : 'text-muted'}"
                        href="#!/"
                        on:click={() =>
                            ($uiState.listPreview = !$uiState.listPreview)}
                        ><i class="bi bi-view-list"></i>
                    </a>
                </li>

                {#if $projectState.type === "team"}
                    <div class="vr align-self-center"></div>
                    <TeamMenu></TeamMenu>
                {/if}

                {#if $externalDataState.platform === "Iconik"}
                    <div class="vr align-self-center"></div>
                    <li class="nav-item" title="Reload Proxy">
                        <a
                            class="nav-link"
                            href="#!/"
                            on:click={() =>
                                modalState.showModal("iconikGetProxy")}
                            ><i class="bi bi-file-earmark-play"></i><sub
                                ><i
                                    class="bi bi-arrow-clockwise text-custom-xsmall"
                                ></i></sub
                            ></a
                        >
                    </li>
                    <li class="nav-item" title="Publish work to Iconik">
                        <a
                            class="nav-link fw-bold"
                            style="color: #00E1BE !important;"
                            href="#!/"
                            on:click={() =>
                                modalState.showModal("iconikPublish")}
                            ><img
                                width="15px"
                                src="./assets/img/iconik-icon.ico"
                                alt="iconik logo"
                            /> Publish</a
                        >
                    </li>
                {:else if $externalDataState.platform === "Work Order"}
                    <div class="vr align-self-center"></div>
                    {#if $externalDataState.transcripts?.length > 0}
                        <li class="nav-item" title="Automatic Sync">
                            <a
                                class="nav-link {loading ? 'disabled' : ''}"
                                href="#!/"
                                on:click={() => automaticSync()}
                                >
                                {#if loading}
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                {:else}
                                  <i class="bi bi-bar-chart-steps"></i>
                                {/if}
                                </a
                            >
                        </li>
                        <li
                            class="nav-item"
                            title="Automatic Speaker Identification"
                        >
                            <a
                                class="nav-link {loading ? 'disabled' : ''}"
                                href="#!/"
                                on:click={() => automaticSpeakerId()}
                                >
                                {#if loading}
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                {:else}
                                <i class="bi bi-person-check-fill"></i>
                                {/if}
                                </a
                            >
                        </li>
                        <div class="vr align-self-center"></div>
                    {/if}
                    <li class="nav-item" title="Publish work order">
                        <a
                            class="nav-link fw-bold"
                            href="#!/"
                            on:click={() =>
                                modalState.showModal("workOrderPublish")}
                            ><i class="bi bi-check-lg"></i> Publish</a
                        >
                    </li>
                {:else if $environment.online}
                    <div class="vr align-self-center"></div>
                    <li class="nav-item" title="Live Support Chat">
                        <a
                            class="nav-link"
                            href="https://chatting.page/qaabxezhqhnaqf53cf3ylbzryxamrn8o"
                            target="_blank"
                            rel="noreferrer"
                            ><i class="bi bi-life-preserver"></i></a
                        >
                    </li>
                {/if}
            </ul>
        </div>
    </div>
</nav>
