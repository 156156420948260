<script>
    import { playerState } from "@app/store/playerStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import { adrState } from "@app/store/adrStore.js";
    import {
        styleState
    } from '@app/store/styleStore.js';
    import { onMount } from "svelte";
    import SubtitlePreview from './SubtitlePreview.svelte'
    import AdPreview from './AdPreview.svelte'
    import AdrPreview from './AdrPreview.svelte'
    import TranscriptPreview from './TranscriptPreview.svelte'
    import debounce from "just-debounce-it";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import ErrorBoundary from '@app/ErrorBoundary.svelte';
    import { createBoundary } from '@crownframework/svelte-error-boundary';
    import 'vidstack/player/styles/base.css';
    import 'vidstack/player/styles/plyr/theme.css';
    import 'vidstack/player';
    import 'vidstack/player/layouts/plyr';
    import 'vidstack/player/ui';

    /* HLS */
	import HLS from "hls.js";
    
    const Boundary = createBoundary(ErrorBoundary);

    let frameTimer, unsubscribe, currentTimeUnsubscribe;
    const updateTcFrame = async function () {
        try {
            if (player) {
                playerState.updateTime(player.currentTime);
            }
        } catch (err) {
            console.log("unable to update current time in store.", err.message);
        }
    };

    const goToMidi = debounce(
        (currentTime) => {
            let intValue = (currentTime * ($adrState.bpm / 60)) / 0.25;
            let binaryValue = intValue.toString(2).padStart(14, "0");
            let binA = binaryValue.slice(-7);
            let binB = binaryValue.slice(0, 7);
            let spp = [0xf2, parseInt(binA, 2), parseInt(binB, 2)];
            let tc = tcLib.secToTc(
                currentTime,
                $projectState.frameRate,
                $projectState.dropFrame,
            );
            let tcArray = tc.split(/:|;/g);
            let midiCmd = [
                0xf0,
                0x7f,
                0x00,
                0x06,
                0x44,
                0x06,
                0x01,
                ...tcArray,
                0x00,
                0xf7,
            ];

            //console.log(`MIDI SEND: ${midiCmd} (${tc})`);
            $adrState.selectedOutput.send(midiCmd);
            /* Go to Song Position */
            $adrState.selectedOutput.send(spp);
        },
        500,
        true,
    );

    onMount(async () => {
        window.player = document.querySelector("media-player");
        window.player.poster = "./assets/img/poster-new.jpg";
        window.player.viewType = "video";
        window.player.playsInline = true;
        window.player.streamType = "on-demand";
        window.player.hideControlsOnMouseLeave = true;

        const plyrLayout = document.querySelector("media-plyr-layout");
        plyrLayout.controls = ["progress", "current-time", "fullscreen"];
        plyrLayout.seekTime = 1;
        plyrLayout.clickToPlay = false;
        plyrLayout.clickToFullscreen = false;

        player.addEventListener("duration-change", (ev) => {
            playerState.updateDuration(ev.detail);
        });

        player.addEventListener("seeked", (ev) => {
            playerState.updateTime(ev.detail);
        });

        player.addEventListener("seeking", (ev) => {
            playerState.updateTime(ev.detail);
        });

        player.addEventListener("error", (ev) => {
            console.log("Media Player Error:", ev);
        });

        player.addEventListener('provider-change', (event) => {
            const provider = event.detail;
            if (provider?.type === 'hls') {
                // Static import
                provider.library = HLS;
            }
        });

        player.addEventListener('source-change', (event) => {
            const source = event.detail;
            console.log('Source changed to', source);
            setTimeout(() => {
                playerState.updatePlayingStatus(false);
            }, 1000);
            
        });

        unsubscribe = player.subscribe(({ playing }) => {
            playerState.updatePlayingStatus(playing);

            if (playing){
                //console.log("PLAY");
                if (frameTimer) {
                    clearInterval(frameTimer);
                }

                frameTimer = setInterval(updateTcFrame, 50);

                if ($adrState.enable && $adrState.selectedOutput) {
                    if ($adrState.record) {
                        /* Record Command */
                        //console.log(`MIDI SEND: RECORD`);
                        $adrState.selectedOutput.send([
                            0xf0, 0x7f, 0x00, 0x06, 0x06, 0xf7,
                        ]);
                        $adrState.selectedOutput.send([251]);
                    } else {
                        /* Playback command */
                        //console.log(`MIDI SEND: PLAY`);
                        $adrState.selectedOutput.send([
                            0xf0, 0x7f, 0x00, 0x06, 0x02, 0xf7,
                        ]);
                        $adrState.selectedOutput.send([251]);
                    }
                }
            } else {
                if (frameTimer) {
                    clearInterval(frameTimer);
                }

                if ($adrState.enable && $adrState.selectedOutput) {
                    /* Pause Command */
                    //console.log(`MIDI SEND: PAUSE`);
                    $adrState.selectedOutput.send([
                        0xf0, 0x7f, 0x00, 0x06, 0x01, 0xf7,
                    ]);
                    $adrState.selectedOutput.send([252]);
                }
            }
        });

        currentTimeUnsubscribe = player.subscribe(({ currentTime }) => {
             // console.log("Time Change", ev);
            playerState.updateTime(currentTime);
            if ($adrState.enable && $adrState.selectedOutput) {
                goToMidi(ev.detail);
            }
        });

        try {
            player.volume = 1;
            player.playbackRate = 1;
        } catch (err) {
            console.log("Error setting player volume or playback rate", err.message);
        }
        

        return () =>{
            console.log("Destroying Media Player");

            player.destroy();
            if (frameTimer) {
                clearInterval(frameTimer);
            }

            if (unsubscribe) {
                unsubscribe();
            }

            if (currentTimeUnsubscribe){
                currentTimeUnsubscribe();
            }
        }
    });
</script>
<media-player playsinline keep-alive key-disabled class="plyr" style="aspect-ratio: {$projectState?.media?.aspectRatio || "16/9"}; background-color:black">
  <media-provider >
    {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "audio description" && $styleState.adrPreview} 
        <Boundary onError={console.error}>
            <AdrPreview></AdrPreview>
        </Boundary>
    {:else if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "audio description"}
        <Boundary onError={console.error}>
            <AdPreview></AdPreview>
        </Boundary>    
    {:else if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "transcription"} 
        <Boundary onError={console.error}>
            <TranscriptPreview></TranscriptPreview>
        </Boundary>
    {:else if $eventGroupState[$projectState.selected]}
        <Boundary onError={console.error}>
            <SubtitlePreview></SubtitlePreview>
        </Boundary>
    {/if}
  </media-provider>
  <media-plyr-layout></media-plyr-layout>
</media-player>    

<style>
.plyr {
    --plyr-color-main: #0fbc8c;
    --plyr-border-radius : 0;
}
</style>