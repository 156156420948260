import _convertToHtml from "../functions/quill/convertToHtml.js";
import _cavenaFunc from "../functions/profiles/cavena890.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const getFormatOptions = _getFormatOptions;
const cavenaFunc = _cavenaFunc;
const convertToHtml = _convertToHtml;
export default {
  decode: function (input, options) {
    let events = [],
      textLength = 51,
      language = 'english',
      startFrame,
      endFrame,
      decodingOptions = getFormatOptions(options.formatOptions);
    let hexPairs = input.match(/(..?)/g);
    let primaryLang = cavenaFunc.languageCodes[hexPairs[146]];
    let secondaryLang = cavenaFunc.languageCodes[hexPairs[147]];
    let primaryFontName = cavenaFunc.hexToAscii(hexPairs.slice(187, 193).join(""));
    let secondaryFontName = cavenaFunc.hexToAscii(hexPairs.slice(246, 252).join(""));

    // console.log("Languages: ", primaryLang, secondaryLang);
    // console.log("Fonts: ", primaryFontName,secondaryFontName);

    /* Hebrew */
    if (primaryFontName.toLowerCase() === "hebnoa" || secondaryFontName.toLowerCase() === "hebnoa" || primaryLang === "hebrew") {
      language = "hebrew";
    }

    /* Arabic */else if (primaryFontName.toLowerCase() === "arabic" || secondaryFontName.toLowerCase() === "arabic" || secondaryLang === "arabic") {
      language = "arabic";
    }

    /* Russian */else if (primaryFontName.toLowerCase().startsWith("kyril") || secondaryFontName.toLowerCase().startsWith("kyril") || primaryLang === "russian") {
      language = "russian";
    }

    /* chinese simplified */else if (primaryLang === "chineseSimplified") {
      language = "chineseSimplified";
    }

    /* chinese traditional */else if (primaryLang === "chineseTraditional") {
      language = "chineseTraditional";
    }

    // console.log("LANGUAGE: ",language);

    // console.log(decodingOptions);

    let decodeLineA = decodingOptions["Decode Line 1"] ? decodingOptions["Decode Line 1"].toLowerCase() : "yes";
    let decodeLineB = decodingOptions["Decode Line 2"] ? decodingOptions["Decode Line 2"].toLowerCase() : "yes";

    // console.log(decodeLineA, decodeLineB);

    let i = 455;
    let start;
    let lastSubtitleId = -1;
    let lineA, lineB, currentSubtitleId;
    while (i < hexPairs.length - 20) {
      //console.log("---------------");
      start = i - textLength;
      currentSubtitleId = hexPairs[start - 16] + hexPairs[start - 15];
      //console.log("Current Subtitle Id: ", currentSubtitleId);

      startFrame = cavenaFunc.calcTotalFrames(hexPairs[start - 14], hexPairs[start - 13], hexPairs[start - 12]);
      endFrame = cavenaFunc.calcTotalFrames(hexPairs[start - 11], hexPairs[start - 10], hexPairs[start - 9]);

      /* TC Troubleshooting */
      //console.log(startFrame, tcLib.frameToTc(startFrame, options.frameRate));
      //console.log(endFrame, tcLib.frameToTc(endFrame, options.frameRate));

      lineA = hexPairs.slice(start, start + textLength).filter(char => {
        return char !== '7f' && char !== 'be';
      });
      lineB = hexPairs.slice(start + textLength + 6, start + textLength + 6 + textLength).filter(char => {
        return char !== '7f' && char !== 'be';
      });

      //console.log("LINE A:", lineA, "LINE B:", lineB);

      lineA = decodeLineA === "yes" ? lineA : [];
      lineB = decodeLineB === "yes" ? lineB : [];
      events.push(new Event({
        start: tcLib.framesToSec(startFrame, options.frameRate),
        end: tcLib.framesToSec(endFrame, options.frameRate),
        text: convertToHtml(cavenaFunc.hexToText(lineA, lineB, primaryLang, secondaryLang)),
        xOffset: 0,
        yOffset: options.window.height * -0.10
      }));
      lastSubtitleId = currentSubtitleId;
      i += 128;
    }
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    //console.log(encodingOptions);
    let langA = encodingOptions["Line 1 Language"] || "english";
    let langB = encodingOptions["Line 2 Language"] || "english";
    let som = encodingOptions["SOM"] || "00:00:00:00";
    langA = langA.toLowerCase();
    langB = langB.toLowerCase();
    let output = cavenaFunc.writeHeader(eventGroup, langA, langB, som);
    eventGroup.events.forEach((event, index, events) => {
      //number
      //console.log(event);
      let eventCodes = "";
      eventCodes += parseInt((index + 1) * 16 / 256).toString(16).padStart(2, "0");
      eventCodes += parseInt((index + 1) * 16 % 256).toString(16).padStart(2, "0");

      /* Timecode */
      eventCodes += cavenaFunc.encodeTc(event.start, options.frameRate);
      eventCodes += cavenaFunc.encodeTc(event.end, options.frameRate);

      /* Alignment */
      if (event.alignment === "left") {
        eventCodes += "50";
      } else if (event.alignment === "right") {
        eventCodes += "52";
      } else {
        eventCodes += "54";
      }

      /* Vertical? */
      eventCodes += "00000000000000";
      /* Text */
      eventCodes += cavenaFunc.encodeText(event, langA, langB);
      output += eventCodes.padEnd(248, "7f");
      if (index !== events.length - 1) {
        output += "00000000";
      }
    });
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input.toLowerCase();
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};