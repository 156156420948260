<script>
    import { toast } from '@zerodevx/svelte-toast';
    import * as Sentry from "@sentry/browser";

    export let error = null;
    export let onError = null;
    $: if ($error){
        onError($error);
        Sentry.captureMessage($error);
        toast.push(`Fatal Error: ${$error}`, {classes: ['toast-danger']});
    };
  </script>
  
  {#if !$error}
    <slot />
  {/if}