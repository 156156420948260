<script>
    import { modalState } from "@app/store/modalStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { authState } from "@app/store/authStore.js";
    import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';

    let totalEvents = $eventGroupState.reduce((total, eventGroup) => {
        // Add the length of the current event group's events array to the total
        return total + eventGroup.events.length;
    }, 0);
</script>




<div
    class="modal fade {$modalState === 'projectInfo' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="ProjectInfoModal"
    aria-labelledby="projectInfoTitle"
    aria-describedby="projectInfoDescription"
>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="projectInfoTitle">Project Info</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close project info modal"
                    on:click={modalState.hideModal}
                    id="closeProjectInfo"
                ></button>
            </div>
            <div class="modal-body">
                <table class="table table-striped" id="projectInfoDescription" aria-label="Project information details">
                    <thead>
                        <tr>
                          <th>Project Name</th>
                          <td>{$projectState.name}</td>
                        </tr>
                        <tr>
                            <th>Project Description</th>
                            <td>{$projectState.description}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <th>Frame Rate</th>
                            <td>{$projectState.frameRate}</td>
                        </tr>
                        <tr>
                            <th>Drop Frame</th>
                            <td>{$projectState.dropFrame}</td>
                        </tr>
                        <tr>
                            <th>Incode</th>
                            <td>{tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame)}</td>
                        </tr>
                        <tr>
                            <th>Media Source</th>
                            <td>{$projectState?.media?.storage}</td>
                        </tr>
                        <tr>
                            <th>Media Local Path</th>
                            <td>{$projectState?.media?.localPath}</td>
                        </tr>
                        <tr>
                            <th>Media Name</th>
                            <td>{$projectState?.media?.name}</td>
                        </tr>
                        <tr>
                            <th>Media Type</th>
                            <td>{$projectState?.media?.type}</td>
                        </tr>
                        <tr>
                            <th>Event Groups</th>
                            <td>{$eventGroupState.length}</td>
                        </tr>
                        <tr>
                            <th>Total Event</th>
                            <td>{totalEvents}</td>
                        </tr>
                        <tr>
                            <th>Subscription Status</th>
                            <td>{$authState.status}</td>
                        </tr>
                        <tr>
                            <th>Subscription Owner</th>
                            <td>{$authState.owner}</td>
                        </tr>
                      </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
