<script>
    import { editorState } from "@app/store/editorStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { historyState } from "@app/store/historyStore.js";

    function snapToStart() {
        try {
            let selectedIndex =
                $eventGroupState[$projectState.selected].selected[0];
            const selectedEvent =
                $eventGroupState[$projectState.selected].events[selectedIndex];

            const validEvents = $eventGroupState[$projectState.selected].events.filter(
                (event, index) =>
                    index !== selectedIndex &&
                    event.start < selectedEvent.start &&
                    event.end > 0,
            );

            if (validEvents.length === 0) return;

            // Find the event with the start time closest to selected event's end time
            const closestEvent = validEvents.reduce((closest, current) => {
                const currentDiff = Math.abs(current.end - selectedEvent.start);
                const closestDiff = Math.abs(closest.end - selectedEvent.start);
                
                return currentDiff < closestDiff ? current : closest;
            });

            // Update the selected event's end time to match the closest event's start time
            $eventGroupState[$projectState.selected].events[selectedIndex].start =
                closestEvent.end + ($editorState.minFrameGap / $projectState.frameRate);

            historyState.insert({
                name: "snap to end", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });
        } catch (err) {
            console.log(err);
            console.log(err.message);
        }
    }
</script>

<li class="nav-item" 
    role="none">
    <a class="nav-link" 
       href="#!/"
       role="button"
       id="snap-to-start-btn"
       aria-label="Chain to Previous Event"
       class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
       aria-disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}
       on:click={snapToStart}>
        <i class="bi bi-align-start" aria-hidden="true"></i>
    </a>
</li>
