<script>
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import {
        projectState
    } from '@app/store/projectStore.js';
    import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();
    
function selectAll() {
    try {
        $eventGroupState[$projectState.selected].selected = $eventGroupState[$projectState.selected].events.map((event, count)=>{
            return count;
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }        
}
</script>
    
<a
    id="selectAllBtn"
    class="dropdown-item"
    role="button"
    aria-label="Select All"
    href="#!/"
    on:click={selectAll}>
    Select All
</a>
