<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import flexbox from '@app/external/cc-lib/dist/dict/flexbox.js';
export let eventIndex;
</script>

<div class="text-white bg-secondary bg-opacity-50 p-2 EditorPreviewText text-{flexbox.alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]}" style="font-family: {$styleState.mode ? 'plexMono' : $styleState.fontFamily} !important; line-height: {$styleState.mode ? '100' : $styleState.lineSpacing}%; font-size: {$styleState.mode ? '16' : $styleState.fontSize}px; direction: {$eventGroupState[$projectState.selected].rtl ? 'rtl' : 'ltr'};">
    {@html $eventGroupState[$projectState.selected].events[eventIndex].text}
</div>
<style>
.EditorPreviewText {
    height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>