<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

const copyEvents = () => {
    try {
        if ($eventGroupState[$projectState.selected].selected.length > 0) {
            let textToCopy, range = quillEditor.getSelection();
            if (range && range.length !== 0 && $eventGroupState[$projectState.selected].selected.length === 1) {
                textToCopy = quillEditor.getText(range.index, range.length);
            } else {
                let eventsToCopy = [];
                $eventGroupState[$projectState.selected].selected.sort((eventA, eventB) =>{
                    return eventA - eventB;
                }).forEach(eventIndex => {
                    eventsToCopy.push($eventGroupState[$projectState.selected].events[eventIndex]);
                })

                textToCopy = eventsToCopy.map(event => event.text).join("");
            }

            navigator.clipboard.writeText(textToCopy);
        }
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }
    
}
</script>

<a id="btnCopyText"
   role="menuitem"
   aria-label="Copy text from selected events"
   class="dropdown-item" href="#!/"
   on:click={copyEvents}>
   Copy Text
</a>
