import _convertWordMapToSubtitles from "../functions/special/convertWordMapToSubtitles.js";
import _convertWordMapToTranscript from "../functions/special/convertWordMapToTranscript.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _Event from "../classes/event.js";
const Event = _Event;
const getFormatOptions = _getFormatOptions;
const convertWordMapToTranscript = _convertWordMapToTranscript;
const convertWordMapToSubtitles = _convertWordMapToSubtitles;
export default {
  decode: function (input, options) {
    let events = [],
      wordMap = [],
      decodingOptions = getFormatOptions(options.formatOptions);
    let fileJson = JSON.parse(input);
    let importType = decodingOptions["Import Type"] || "subtitle";
    let maxLines = decodingOptions["Max Lines"] || 2;
    let maxChars = decodingOptions["Max Characters"] || 32;
    fileJson.result.words.forEach(word => {
      if (word.utterance === "[MUSIC]") {
        return;
      }
      wordMap.push(new Event({
        start: word.start / 1000,
        end: (word.start + word.duration) / 1000,
        text: word.utterance,
        confidence: word.confidence,
        speakers: word.spk !== undefined ? [word.spk] : []
      }));
    });
    if (importType === "word map") {
      events = wordMap;
    } else if (importType === "transcription") {
      events = convertWordMapToTranscript(wordMap, maxLines, maxChars, options.window);
    } else {
      events = convertWordMapToSubtitles(wordMap, maxLines, maxChars, options.window);
    }
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("JSON Transcript files are not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      /* All */
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      /* Order by start time */
      eventGroup.events.sort((eventA, eventB) => {
        return eventA.start - eventB.start;
      });
      eventGroup.events = eventGroup.events.filter((event, index, events) => {
        if (index > 0) {
          return event.start != events[index - 1].start;
        } else {
          return true;
        }
      });
      return eventGroup;
    }
  }
};