import _Event from "../../classes/event.js";
const Event = _Event;
export default (function insertEvent(eventGroup, options, index = null) {
  // Check for start and end times to handle overlap
  if (options.start !== undefined && options.end !== undefined) {
    options = adjustEventTimes(eventGroup.events, options);
  }

  // Create new event with adjusted options if necessary
  const newEvent = new Event(options);

  // Handle insertion based on index
  if (index !== null && eventGroup.events[index]) {
    eventGroup.events.splice(index, 0, newEvent);
  } else if (index === 0) {
    // If index is 0, insert at the beginning of the array
    eventGroup.events.unshift(newEvent);
  } else {
    eventGroup.events.push(newEvent);
  }
  return eventGroup;
});
function adjustEventTimes(events, options) {
  // Sort events by start time for efficient overlap checking
  const sortedEvents = [...events].sort((a, b) => a.start - b.start);

  // Create a copy of the options to avoid modifying the original
  const adjustedOptions = {
    ...options
  };
  for (const existingEvent of sortedEvents) {
    // Check if there's an overlap
    if (adjustedOptions.start < existingEvent.end && adjustedOptions.end > existingEvent.start) {
      // Case 1: New event starts during existing event
      if (adjustedOptions.start >= existingEvent.start) {
        adjustedOptions.start = existingEvent.end;
        adjustedOptions.end = adjustedOptions.end + (existingEvent.end - adjustedOptions.start);
      }
      // Case 2: New event ends during existing event
      else if (adjustedOptions.end <= existingEvent.end) {
        adjustedOptions.end = existingEvent.start;
      }
      // Case 3: New event completely overlaps existing event
      else {
        adjustedOptions.start = existingEvent.end;
      }
    }
  }
  return adjustedOptions;
}