<script>
import {projectState} from '@app/store/projectStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

import firebase from '@app/configs/firebase.js';
let userId = firebase.auth().currentUser.uid;
</script>

{#if $projectState.ownerId === userId}
<li class="nav-item" title="Project History">
    <a class="nav-link" href="#!/" on:click={() => modalState.showModal("snapshotHistory")}>
        <i class="bi bi-clock-history"></i>
    </a>
</li>
<li class="nav-item" title="Share Project">
    <a class="nav-link" href="#!/" on:click={() => modalState.showModal("teamShare")}><i class="bi bi-folder-symlink"></i></a>
</li>
{/if}
<li class="nav-item" title="Sync Changes"> 
    <a class="nav-link {$historyState.actions.length > 1 ? 'text-warning' : ''}" href="#!/" on:click={() => modalState.showModal("teamSync")}><i class="bi bi-cloud-upload"></i></a>
</li>

<style>
.text-warning>i {
    animation: fade 1s linear infinite;
}

@keyframes fade {  
  50% { opacity: 0.2; }
}
</style>