<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { editorState } from "@app/store/editorStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import autoFormat from "@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js";
    import _Event from "@app/external/cc-lib/dist/classes/event.js";
    import insertEvent from "@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js";
    import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
    import Swal from "sweetalert2";

    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();

    function pasteEvents() {
        try {
            navigator.clipboard.readText().then((text) => {
                try {
                    let clipboardEvents = JSON.parse(text);
                    //check if clipboardEvents is actually subtitle events
                    if (
                        clipboardEvents.length > 0 &&
                        clipboardEvents[0].id &&
                        clipboardEvents[0].style
                    ) {
                        let currentTime = player ? player.currentTime : 0;
                        let newCurrentTime = currentTime;
                        Swal.fire({
                            title: "Paste Events",
                            text: "Would you like to paste the event(s) based on the current time?",
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "Use Original Timing"
                        })
                            .then((sAlertRes) => {
                                clipboardEvents.forEach(
                                    (event, count, events) => {
                                        // Use Sweet Alert to ask the user if they wish to paste events at the current Time. If yes, then paste the events, but update the start and end time of the event so that the start time is the current time and the end time is the current time + the duration of the event.
                                        if (
                                            sAlertRes.isConfirmed &&
                                            currentTime >= 0
                                        ) {
                                            let duration =
                                                event.end - event.start;
                                            let startTime =
                                                count === 0
                                                    ? currentTime
                                                    : currentTime +
                                                      (event.start -
                                                          events[0].end);

                                            startTime = startTime
                                                ? startTime
                                                : newCurrentTime;
                                            let endTime = startTime + duration;
                                            newCurrentTime = endTime;
                                            $eventGroupState[
                                                $projectState.selected
                                            ] = insertEvent(
                                                $eventGroupState[
                                                    $projectState.selected
                                                ],
                                                {
                                                    ...event,
                                                    start: startTime,
                                                    end: endTime,
                                                },
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] +
                                                    count +
                                                    1,
                                            );
                                        } else {
                                            $eventGroupState[
                                                $projectState.selected
                                            ] = insertEvent(
                                                $eventGroupState[
                                                    $projectState.selected
                                                ],
                                                event,
                                                $eventGroupState[
                                                    $projectState.selected
                                                ].selected[0] +
                                                    count +
                                                    1,
                                            );
                                        }
                                        //Order events by time
                                        $eventGroupState[
                                            $projectState.selected
                                        ] = orderByTime(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                        );
                                    },
                                );
                            })
                            .catch((err) => {
                                console.log("Error pasting events");
                                console.log(err);
                                console.log(err.message);
                            });
                    } else {
                        throw new Error("No events found in clipboard");
                    }
                } catch (err) {
                    let range = quillEditor.getSelection();
                    if (range && range.length === 0) {
                        quillEditor.insertText(range.index, text);
                    } else if (range) {
                        quillEditor.deleteText(range.index, range.length);
                        quillEditor.insertText(range.index, text);
                    }

                    if (
                        $editorState.autoFormatText &&
                        $eventGroupState[$projectState.selected].selected
                            .length > 0
                    ) {
                        let maxLines = $eventGroupState[$projectState.selected]
                                ? $eventGroupState[$projectState.selected]
                                      .maxLines
                                : 2,
                            maxChars = $eventGroupState[$projectState.selected]
                                ? $eventGroupState[$projectState.selected]
                                      .maxChars
                                : 32,
                            minDuration = 0,
                            allowOrphanWords = true;

                        try {
                            let selectedEvent =
                                $eventGroupState[$projectState.selected]
                                    .selected[0];
                            $eventGroupState[$projectState.selected].selected =
                                [selectedEvent];

                            $eventGroupState[$projectState.selected] =
                                autoFormat(
                                    $eventGroupState[$projectState.selected],
                                    maxLines,
                                    maxChars,
                                    minDuration,
                                    allowOrphanWords,
                                    $eventGroupState[$projectState.selected]
                                        .selected,
                                );

                            $eventGroupState[$projectState.selected].selected =
                                [];
                            setTimeout(() => {
                                $eventGroupState[
                                    $projectState.selected
                                ].selected = [selectedEvent];
                            }, 50);
                        } catch (err) {
                            console.log(err.message);
                        }
                    }
                }

                historyState.insert({
                    name: "paste events", //action name
                    eventGroup: $projectState.selected,
                    snapshots: [
                        {
                            store: "eventGroupState",
                            value: JSON.stringify($eventGroupState),
                        },
                    ],
                });

                $eventGroupState = $eventGroupState;
            });
        } catch (err) {
            console.log(err);
            console.log(err.message);
        } finally {
            dispatch("done");
        }
    }
</script>

<a class="dropdown-item" href="#!/" on:click={pasteEvents}>Paste</a>
