<script>
import {
    explorerState
} from '@app/store/explorerStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import { ccprjFunc } from '@app/lib/ccprj.js';
import { toast } from '@zerodevx/svelte-toast';
import Swal from "sweetalert2";

/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import storage from '@app/configs/storage.js';
let userId = firebase.auth().currentUser.uid,
    userEmail = firebase.auth().currentUser.email,
    teamId = $authState.team ? $authState.team.id : null,
    homeRef = db.collection("users").doc(userId).collection("storage"),
    teamRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null,
    storageRef = storage.ref(),
    teamDriveUploadBasePath = "teams/" + teamId + "/storage/",
    myDriveUploadBasePath = "users/" + userId + "/projects/";

async function saveProject() {
    // Show immediate feedback that save is in progress
    toast.push("Saving project...", {classes: ['toast-info']});
    
    if ($projectState.rootDir === 'team' && !teamId) {
        teamId = $authState.team ? $authState.team.id : null;        
        teamRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
        teamDriveUploadBasePath = "teams/" + teamId + "/storage/";
    }

    // Check if the project record exists and if it's locked
    try {
        const dbRef = $projectState.rootDir === 'team' ? teamRef : homeRef;
        const docRef = await dbRef.doc($projectState.id).get();
        
        if (docRef.exists) {
            const projectRecord = docRef.data();
            
            // If project is locked by someone else, show warning and suggest saving as new copy
            if (projectRecord.locked && projectRecord.lockedBy !== userEmail) {
                const result = await Swal.fire({
                    title: 'Project is Locked',
                    text: `This project is currently locked by ${projectRecord.lockedBy}. You can save as a new copy instead.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Save as New Copy',
                    cancelButtonText: 'Cancel',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-lg btn-primary me-2",
                        cancelButton: "btn btn-lg btn-outline-secondary"
                    }
                });
                
                if (result.isConfirmed) {
                    saveProjectAs();
                }
                return;
            }
        }
        
        // Continue with normal save process if not locked or locked by current user
        let projectJson = ccprjFunc.createCcprj({
            project : JSON.parse(JSON.stringify($projectState)),
            metadata : JSON.parse(JSON.stringify($metadataState)),
            speakers : JSON.parse(JSON.stringify($speakerState)),
            issues : JSON.parse(JSON.stringify($issueState)),
            markers : JSON.parse(JSON.stringify($markerState)),
            eventGroups : JSON.parse(JSON.stringify($eventGroupState)),
            style : JSON.parse(JSON.stringify($styleState))
        });

        let projectFilePath = $projectState.rootDir === 'team' ? teamDriveUploadBasePath+ $projectState.id : myDriveUploadBasePath + $projectState.id;

        await storageRef.child(projectFilePath).putString(JSON.stringify(projectJson));
        
        let projectInfo = {
            id: $projectState.id,
            name: $projectState.name,
            type: $projectState.type === "team" ? "team project" : "project",
            updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
            shared : $projectState.rootDir === 'team' ? true : false,
            locationId: $projectState.folderId
        }

        await ($projectState.rootDir === 'team' ? teamRef : homeRef).doc(projectInfo.id).update(projectInfo);
        toast.push("Project saved successfully", {classes: ['toast-success']});
        historyState.reset();
    } catch (error) {
        console.log("Error saving project: ", error);
        toast.push(error.message, {classes: ['toast-danger']});
    }
}

async function saveProjectAs() {
    $explorerState.context = "save";
    modalState.showModal("storageExplorer");
}

</script>
<a class="dropdown-item" 
   href="#!/" 
   id="onlineSaveMenuItem"
   role="menuitem"
   aria-label="Save project"
   on:click="{() => ($projectState.folderId || $projectState.folderId === null) ? saveProject() : saveProjectAs()}">Save Project</a>
<a class="dropdown-item" 
   href="#!/" 
   id="onlineSaveAsMenuItem"
   role="menuitem"
   aria-label="Save project as"
   on:click="{saveProjectAs}">Save Project As...</a>