<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {styleState} from '@app/store/styleStore.js';
export let eventGroupIndex;
export let eventIndex;
export let scaleMultiplier = 10;
</script>

{#if $eventGroupState[eventGroupIndex] && $eventGroupState[eventGroupIndex].events[eventIndex]}
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="compareEvent bg-secondary bg-opacity-75 text-white border border-top-0 border-bottom-0 border-secondary " style="left: {$eventGroupState[eventGroupIndex].events[eventIndex].start*scaleMultiplier}px; width: {($eventGroupState[eventGroupIndex].events[eventIndex].end - $eventGroupState[eventGroupIndex].events[eventIndex].start)*scaleMultiplier}px;">
    <!-- Event Text -->
    <div class="eventTimelineText h-100" style="font-family: {$styleState.mode ? 'monospace' : $styleState.fontFamily}; line-height: {$styleState.mode ? '100' : $styleState.lineSpacing}%;">
        {#if $eventGroupState[eventGroupIndex].events[eventIndex]}
            {@html $eventGroupState[eventGroupIndex].events[eventIndex].text}
        {/if}
    </div>
</div>
{/if}

<style>
.compareEvent {
    position: absolute;
    height: 100%;
    font-size: 0.8vw;
    pointer-events: none;
}

.eventTimelineText {
    padding: 5px;
    overflow: hidden;
    cursor: move;
    font-size: 0.7rem;
}
</style>
