<script>
  import {modalState} from '@app/store/modalStore.js';
  import {environment} from '@app/store/envStore.js';
  import {authState} from '@app/store/authStore.js';

  async function openDevTools(){
    if ($environment.electron){
      await window.ipcRenderer.invoke("openDevTools");
    }
  }
</script>

<div class="modal fade {$modalState === 'about' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AboutModal" aria-labelledby="aboutTitle" aria-describedby="aboutDesc">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="aboutTitle" class="modal-title">About</h4>
        <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} id="closeAbout"></button>
      </div>
      <div class="modal-body" id="aboutDesc">
        <p>Closed Caption Creator v{$environment.version}</p>
        <div class="row">
          <div class="col-4 d-xl-flex justify-content-xl-center align-items-xl-center">
             <!-- svelte-ignore a11y-click-events-have-key-events -->
             <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
             <img class="img-fluid" src="./assets/img/icon.png" style="width: 105px;" alt="Closed Caption Creator Logo" on:click="{() => openDevTools()}" id="aboutLogo"></div>
          <div class="col">
            <h5>Contact Information</h5>
            <p>support@closedcaptioncreator.com<br>www.closedcaptioncreator.com</p>
            
            <h6>User: {$authState.user ? $authState.user.email : ''}</h6>
            <h6>License type: {$authState.plan}</h6>
            <h6>License status: {$authState.status}</h6>
            {#if $authState.ad}
                <h6 class="mb-2">Plugins: Audio Description</h6>
            {/if}
          </div>
        </div>
      </div>
      <div class="modal-footer d-xl-flex justify-content-xl-center">
        <p class="text-center small">Copyright &copy; 2024 Closed Caption Creator Inc. All rights reserved.</p>
      </div>
    </div>
  </div>
</div>
