<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    authState
} from '@app/store/authStore.js';
import {
    fade
} from 'svelte/transition';
import Swal from 'sweetalert2'
import throttle from 'just-throttle';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';
/* CC LIB */
import encode from "@app/external/cc-lib/dist/functions/encode.js";
import defaults from "@app/external/cc-lib/dist/lib/defaults.js";
import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
import _Event from "@app/external/cc-lib/dist/classes/event.js";
import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
import ffmpegPresets from "@app/external/cc-lib/dist/dict/ffmpegPresets.js"; 
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import removeHtmlEntities from "@app/external/cc-lib/dist/functions/eventGroups/removeHtmlEntities.js";
import reorderRtlText from "@app/external/cc-lib/dist/functions/utility/reorderRtlText.js";
import wrapStyledTextWithSpan from '@app/external/cc-lib/dist/functions/quill/wrapStyledTextWithSpan.js'; 
/* Electron/FFMPEG */
const {
    shell
} = require('electron');
const ipcRenderer = window.ipcRenderer; 
const ffmpegPath = require('ffmpeg-static-electron').path;
const ffmpeg = require('fluent-ffmpeg');
const fs = window.fsSync;
const os = window.os;
const path = window.path;

ffmpeg.setFfmpegPath(ffmpegPath.replace('app.asar', 'app.asar.unpacked'));

let targetFolderPath = sessionStorage.getItem("videoExportPath"),
    //Get preset from cc-video-export-preset in localstorage
    preset = localStorage.getItem("cc-video-export-preset") || "fb720",
    progress = 0,
    exporting = false,
    eventGroup = $eventGroupState[$projectState.selected].id,
    duration,
    fps = 0,
    statusMsg = "",
    tempDir = os.tmpdir(),
    retry = false,
    ignoreAll = false;

if ($authState.status === "in_trial"){
    showTrialWarning();
}

async function cancelExport(eventGroupId, eventId) {
    toast.push("Video export was aborted", {classes: ['toast-warning']});
    
    $projectState.selected = eventGroupId;
    $eventGroupState[$projectState.selected].selected = [eventId];
    setTimeout(() =>{
        let eventListElement = document.getElementById("EventList");
        eventListElement.scrollTo(0, eventId * 230);
    }, 250);    

    modalState.hideModal();
}

function alertUser(msg) {
    let response = Swal.fire({
        titleText: "Export Pre-Checks",
        text : msg,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Abort',
        denyButtonText: 'Ignore',
        cancelButtonText: 'Ignore All',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-danger',
            denyButton: 'btn btn-light mx-2',
            cancelButton : 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        } else if (result.isDenied) {
            return false;
        } else if (result.isDismissed){
            ignoreAll = true;
            return false;
        }
    });

    return response;
}

async function preExportCheck(events) {
    for (let i = 0; i < events.length; i++) {
        if (!ignoreAll) {
            if (events[i].text) {
                if (isNaN(events[i].start)) {
                    if (await alertUser(`Event ${i+1} is missing a start time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (isNaN(events[i].end)) {
                    if (await alertUser(`Event ${i+1} is missing an end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start === events[i].end) {
                    if (await alertUser(`Event ${i+1} has the same start and end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (events[i].start > events[i].end) {
                    if (await alertUser(`Event ${i+1} has a start time greater than its end time.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                } else if (i > 0 && events[i].start < events[i - 1].end) {
                    /* Adding check in case overlap is less than a frame */
                    if (events[i - 1].end-events[i].start < 1/$projectState.frameRate){
                        events[i].start = events[i - 1].end;
                    } else if (await alertUser(`Event ${i+1} overlaps with the previous event.\n Would you like to ABORT your export?`)) {
                        return {
                            err: true,
                            eventId: i
                        }
                    };
                }
            } else {
                if (await alertUser(`Event ${i+1} has no text.\n Would you like to ABORT your export?`)) {
                    return {
                        err: true,
                        eventId: i
                    }
                };
            }
        }
        
    }

    return {
        err: false
    }
}

function showTrialWarning(){
    Swal.fire({
        titleText: "Trial Mode",
        text : "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Activate Account',
        denyButtonText: 'Continue Trial',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            denyButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            activateSubscription();
            return true;
        } else {
            return false;
        }
    }).then((res) => {
        if (res){
            console.log(res);
            showRestartNotification();
        }
        
        return true;
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function showRestartNotification(){
    Swal.fire({
        titleText: "Restart Required",
        text : "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
        confirmButtonText: 'Ok',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-light'
        }
    }).then(res =>{
        console.log(res);
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function selectTargetFolder() {
    ipcRenderer.invoke('selectFolder', {
        title : "Export Video - Select Folder",
        properties: ["openDirectory", "createDirectory"]
    }).then((path) => {
        if (!path.canceled) {
            targetFolderPath = path.filePaths[0];            
            sessionStorage.setItem("videoExportPath", targetFolderPath);
        }
    });
}

function updateProgress(tc, duration) {
    let tcSec = tcLib.tcMsToSec(tc);
    progress = ((tcSec.toFixed(2)/duration) * 100).toFixed(2);
    //console.log(tcSec, progress);
}

async function exportVideo() {
    try {
        toast.push("Starting video export... encoding subtitles.", {classes: ['toast-info']});
        //Save preset to cc-video-export-preset in localstorage
        localStorage.setItem("cc-video-export-preset", preset);
        $eventGroupState = $eventGroupState;
        exporting = true;
        duration = $playerState.duration ? $playerState.duration : 500;
        let outputFilePath = (process.platform !== "win32" ? path.sep : "") + path.join(...targetFolderPath.split(path.sep), $projectState.name + "." + ffmpegPresets.profiles[preset].ext);
        let subtitleFilePath = targetFolderPath + "\\" + `${$projectState.id}.ass`;
        subtitleFilePath = subtitleFilePath.substring(1, 2) === ":" ? subtitleFilePath.substring(2) : subtitleFilePath;
        subtitleFilePath = subtitleFilePath.replace(/\\/g, "/");

        /* Tempt Backup */
        let outputFilePathTemp = (process.platform !== "win32" ? path.sep : "") + path.join(...os.tmpdir().split(path.sep), $projectState.name + "." + ffmpegPresets.profiles[preset].ext);        
        let subtitleFilePathTemp = os.tmpdir() + "\\" + `${$projectState.id}.ass`;
        subtitleFilePathTemp = subtitleFilePathTemp.substring(1, 2) === ":" ? subtitleFilePathTemp.substring(2) : subtitleFilePathTemp;
        subtitleFilePathTemp = subtitleFilePathTemp.replace(/\\/g, "/");

        if (retry){
            outputFilePath = outputFilePathTemp;
            subtitleFilePath = subtitleFilePathTemp;
        }

        // console.log(outputFilePath, subtitleFilePath);

        /* 1. Export ASS file with styling */
        /* Get the event group we need to export */
        let eventGroupIndex = $eventGroupState.findIndex(group => {
            return group.id === eventGroup;
        });

        /* Translate for Creator */
        let playerWidth = document.getElementById('PlayerWrapper').clientWidth;
        let playerHeight = document.getElementById('PlayerWrapper').clientHeight;
        let videoWidth = ffmpegPresets.profiles[preset].crop ? ffmpegPresets.profiles[preset].crop.h : ffmpegPresets.profiles[preset].scale.h;
        let videoHeight = ffmpegPresets.profiles[preset].crop ? ffmpegPresets.profiles[preset].crop.v : ffmpegPresets.profiles[preset].scale.v;
        let xMultiplier = videoWidth/playerWidth;
        let yMultiplier = videoHeight/playerHeight;
        let fontSize = $styleState.mode ? (playerHeight/19).toFixed(2) : ($styleState.fontSize*0.7).toFixed(2);
        
        /* console.log("------------------");
        console.log(fontSize, $styleState.fontSize,playerWidth,playerHeight,window.screen.availWidth,window.devicePixelRatio, videoWidth);
        console.log("------------------"); */

        let copyOfEvents = JSON.parse(JSON.stringify($eventGroupState[eventGroupIndex].events));

        if ($eventGroupState[eventGroupIndex].rtl){
            copyOfEvents = reorderRtlText(copyOfEvents);
        }

        let xPadding = $styleState.mode ? 18 : $styleState.xPadding;
        let yPadding = $styleState.mode ? 10 : $styleState.yPadding;
        console.log($styleState);

        let options = new defaults.options({
            profile : 'subStationAlpha',
            formatOptions : [
                {
                    name: "Border Style",
                    selected: $styleState.mode ? 3 : $styleState.outlineOpacity < 100 ? 4 : $styleState.borderStyle
                },
                {
                    name: "Font Family",
                    selected: $styleState.mode ? 'monospace' : $styleState.fontFamily
                },
                {
                    name: "Font Size",
                    selected: fontSize
                },
                {
                    name: "Font Color",
                    selected: $styleState.mode ? '#FFFFFF' : $styleState.color
                },
                {
                    name: "Opacity",
                    selected: $styleState.mode ? 100 : $styleState.opacity
                },
                {
                    name: "Letter Spacing",
                    selected: $eventGroupState[eventGroupIndex].rtl ? 0 : $styleState.spacing
                },
                {
                    name: "Shadow Size",
                    selected: $styleState.mode ? 0 : ($styleState.shadow*0.7).toFixed(2)
                },                  
                
                {
                    name: "Shadow Color",
                    selected: $styleState.shadowColor
                },
                {
                    name: "Shadow Opacity",
                    selected: $styleState.mode ? 0 :  $styleState.outlineOpacity < 100 ? $styleState.outlineOpacity : $styleState.shadowOpacity
                },
                {
                    name: "Outline Size",
                    selected: $styleState.mode ? 2 : $styleState.outlineOpacity < 100 ? 0 : ($styleState.outline*0.65).toFixed(2)
                },                  
                
                {
                    name: "Outline Color",
                    selected: $styleState.mode ? '#000000' : $styleState.outlineColor
                },
                {
                    name: "Outline Opacity",
                    selected: $styleState.mode ? 100 : $styleState.outlineOpacity
                },
                {
                    name: "ScaleX",
                    selected: parseInt(xMultiplier*100)
                },
                {
                    name: "ScaleY",
                    selected: parseInt(yMultiplier*100)
                },
                {
                    name: "Video Width",
                    selected: parseInt(videoWidth)
                },
                {
                    name: "Video Height",
                    selected: parseInt(videoHeight)
                }
            ],
            frameRate : $projectState.frameRate,
            dropFrame : $projectState.dropFrame,
            window : {
                width: playerWidth,
                height: playerHeight,
                xOffset: (xPadding/100) * playerWidth,
                yOffset: (yPadding/100) * playerHeight
            }
        });

        console.log("Encoding subtitle contents");      
        copyOfEvents.forEach((eventCopy, index, events) => {
            if ($authState.status === "in_trial"){
                events[index].text = eventCopy.text.replace(/>[^<].{5}/g,">TRIAL");
            }
        });

        try {
            let newEventGroup = new _EventGroup({
                events : copyOfEvents
            });
            newEventGroup = orderByTime(newEventGroup);
            newEventGroup = removeHtmlEntities(newEventGroup);
            let preCheckResult = await preExportCheck(newEventGroup.events);
            if (preCheckResult.err) {
                cancelExport(eventGroupIndex, preCheckResult.eventId);
                return;
            }

            newEventGroup.events.forEach((event, index, events) =>{
                events[index].text = wrapStyledTextWithSpan(event.text);
            });

            let subtitleContents = await encode(newEventGroup, options);
            console.log("Writing subtitle file to disk...");
            fs.writeFileSync(subtitleFilePath, subtitleContents);
        } catch(err){
            console.log("Subtitle encoding failed");
            throw new Error("Subtitle encoding failed with message: "+err.message);
        }
        

        console.log("Starting FFMPEG...");
        /* 2. Start Export */
        let command = ffmpeg($projectState.media.localPath);
        
        if (ffmpegPresets.profiles[preset].scale){
            command.videoFilters(`scale=${ffmpegPresets.profiles[preset].scale.h}:${ffmpegPresets.profiles[preset].scale.v}`);
        }

        if (ffmpegPresets.profiles[preset].crop){
            command.videoFilters(`crop=${ffmpegPresets.profiles[preset].crop.h}:${ffmpegPresets.profiles[preset].crop.v}`);
        }

        if (!ffmpegPresets.profiles[preset].proxy){
            command.videoFilters(`ass=${subtitleFilePath}`);
        }

        if (ffmpegPresets.profiles[preset].pixelFormat){
            command.outputOptions(`-pix_fmt ${ffmpegPresets.profiles[preset].pixelFormat}`);
        }

        if (ffmpegPresets.profiles[preset].pixelFormat){
            command.outputOptions(`-pix_fmt ${ffmpegPresets.profiles[preset].pixelFormat}`);
        }

        /* ProRes */
        if (ffmpegPresets.profiles[preset].profile !== undefined){
            command.outputOptions(`-profile:v ${ffmpegPresets.profiles[preset].profile}`);
        }

        if (ffmpegPresets.profiles[preset].aCodec){
            command.audioCodec(ffmpegPresets.profiles[preset].aCodec);
        }

        if (ffmpegPresets.profiles[preset].bitrate){
            command.videoBitrate(ffmpegPresets.profiles[preset].bitrate || 12000000);
        }

        command.videoCodec(ffmpegPresets.profiles[preset].codec)            
            .output(outputFilePath)
            .on('start', function(commandLine) {
                console.log('Spawned Ffmpeg with command: ' + commandLine);
            })
            .on('codecData', function(data) {
                console.log('Input is ' + data.audio + ' audio ' +
                    'with ' + data.video + ' video');
            })
            .on('progress', function(progress) {
                /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
                updateProgress(progress.timemark, duration)
                statusMsg = 'Exporting: ' + progress.timemark + ' done';
                fps = progress.currentFps;
                console.log('Exporting: ' + progress.timemark + ' done', progress);
            })
            .on('end', function() {
                toast.push("Video export is now complete", {classes: ['toast-success']});
                console.log('Finished Exporting');
                statusMsg = 'Export Complete';
                progress = 100;
                exporting = false;
                shell.showItemInFolder(outputFilePath);
                modalState.hideModal();
            })
            .on('error', function(err, stdout, stderr) {
                if (!retry){
                    console.log("First failure... retrying.");
                    retry = true;
                    exportVideo();
                } else {
                    console.log("Second failure... failing.")
                    toast.push("Video export failed with error: " + err.message, {classes: ['toast-danger']});
                    console.log('Cannot process video: ' + err.message);
                    statusMsg = 'Cannot exporting video: ' + err.message;
                    progress = 100;
                    exporting = false;
                    modalState.hideModal();
                }                
            })
            .run();
    } catch (err) {
        if (!retry){
            console.log("First failure... retrying.");
            retry = true;
            exportVideo();
        } else {
            console.log(err,err.message)
            toast.push("Video export process failed with error: " + err.message, {classes: ['toast-danger']});
            console.log('Video export process failed: ' + err.message);
            progress = 100;
            exporting = false;
        }        
    }
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, { leading: true });
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'videoExport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="VideoExportModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Video Export</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                {#if exporting}
                <p >
                    <i class="bi bi-exclamation-diamond-fill"></i>
                    Please leave this window open until your export completes
                </p>
                {/if}
                <form on:submit|preventDefault="{exportVideo}">
                    <div id="targetFolderInputGroup" class="mb-3 p-3 border border-secondary rounded">
                        <p class="lead">Video Export Location:</p>
                        <button type="button" disabled="{exporting}" class="btn btn-info text-white" on:click="{selectTargetFolder}"><i class="bi bi-folder"></i> Choose Export Folder</button>
                        <span class="ms-2 font-italic text-truncate">{targetFolderPath || ""}</span>
                        {#if !targetFolderPath}
                            <small class="form-text text-muted">Select an export location for your video</small>
                        {/if}
                    </div>  
                    <div class="mb-3">
                        <label class="form-label" for="EventGroupSelection">Event Group</label>
                        <select class="form-select" bind:value="{eventGroup}">
                            {#each $eventGroupState.filter(group=>{return group.type === "subtitle" || group.type==="translation"}) as eventGroupOption}
                            <option value="{eventGroupOption.id}" selected="{eventGroup === eventGroupOption.id}">{eventGroupOption.name}</option>
                            {/each}
                        </select>
                    </div>                  
                    <div class="mb-3">
                        <label class="form-label" for="EventGroupSelection">Preset</label>
                        <select class="form-select" bind:value="{preset}">
                            {#each ffmpegPresets.presets as ffmpegPreset}
                                <option value="{ffmpegPreset.id}" selected>{ffmpegPreset.displayName}</option>

                            {/each}                   
                        </select>
                    </div>
                    <button type="button" disabled="{!targetFolderPath || exporting}" class="btn btn-primary float-end" on:click="{exportVideo}">
                        Export
                    </button>
                </form>
                {#if retry}
                <p class='text-warning'><i class="bi bi-exclamation-triangle"></i> There was an error exporting your video to the selected target folder. Target folder has been updated to temp directory: {tempDir}</p>
                {/if}
                {#if exporting}
                <div class="progress w-75 float-start mt-2" >
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">Exporting - {progress}% | {fps}FPS
                    </div>
                </div>
                <br/>
                <p class="float-start text-muted"><i class="bi bi-info-circle"></i> {statusMsg}</p>
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
#targetFolderInputGroup {
    background-color: #00000014;
}
</style>
