import { decode as _decodeHtml } from "html-entities";
const decodeHtml = _decodeHtml;
export default (function decodeHtmlEvg(eventGroup) {
  eventGroup.events.forEach((event, index, events) => {
    try {
      // let before = event.text;
      events[index].text = decodeHtml(event.text);
      // let after = events[index].text;

      // if (before !== after) {
      //     console.log("-------------");
      //     console.log(before);
      //     console.log(after);
      // }
    } catch (err) {
      console.log(index, event.text);
      console.log(err.message);
      console.log(err);
    }
  });
  return eventGroup;
});