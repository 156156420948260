<script>
import {
    authState
} from '@app/store/authStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import {
    BarLoader,
    Circle
} from 'svelte-loading-spinners'
import Papa from 'papaparse';

/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';
import db from '@app/configs/firestore.js';

import {
    saveAs
} from 'file-saver';
import eol from 'eol';

let jobs = [],
    teamId = $authState.team ? $authState.team.id : null,
    userId = firebase.auth().currentUser.uid,
    dbRef = $authState.team ? db.collection("teams").doc(teamId).collection("jobs") : db.collection("users").doc(userId).collection("jobs"),
    loading = false;

let dateRange = "month",
    startDate,
    endDate,
    jobTypeFilter = "All",
    transcriptMins = 0,
    translationChars = 0,
    ttsChars = 0,
    totalCost = 0.00,
    gettingJobs = getJobs();

function getDateRange() {
    let start, end;
    switch (dateRange) {
        case "custom":
            start = new Date(startDate);
            end = new Date(endDate);
            break;
        case "week":
            // code block
            start = new Date();
            end = new Date();
            start.setHours(start.getHours() - 168);
            break;
        case "month":
            // code block
            start = new Date();
            end = new Date();
            start.setMonth(start.getMonth() - 1);
            break;
        case "year":
            // code block
            start = new Date();
            end = new Date();
            start.setFullYear(start.getFullYear() - 1);
            break;
        default:
            start = new Date();
            end = new Date();
            start.setHours(start.getHours() - 24);
    }

    return {
        start: start,
        end: end
    }

}

function getJobs() {
    let queryDateRange = getDateRange();
    jobs = [];

    return dbRef.where('createdOn', ">=", queryDateRange.start)
        .where('createdOn', "<=", queryDateRange.end)
        .where('status', "==", "Passed")
        .where('type', "in", jobTypeFilter === 'All' ? ["transcription", "translation", 'text to speech'] : [jobTypeFilter])
        .orderBy('createdOn', 'desc')
        .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                jobs = [...jobs, doc.data()];
            });
            console.log(jobs);
            calculateTotals();
        }).catch((error) => {
            console.log("Error getting documents: ", error);
            toast.push(error.message, {classes: ['toast-danger']});
        });
}

function calculateTotals(){
    totalCost = 0;
    transcriptMins = 0;
    translationChars = 0;
    ttsChars = 0;

    jobs.forEach(job =>{
        totalCost += job.cost;
        if (job.type === "transcription"){
            transcriptMins += (job.duration/60);
        } else if (job.type === "translation"){
            translationChars += parseInt(job.chars);
        } else if (job.type === "text to speech"){
            ttsChars += parseInt(job.chars);
        }
    })
}

function exportCsv() { 
    toast.push("Exporting results...", {classes: ['toast-info']});

    let csv = Papa.unparse(JSON.stringify(
        jobs.map(job => {
            return {
                "Job ID": job.id,
                "Project ID": job.projectId,
                "Project Name": job.projectName,
                "Submitted By": job.submittedBy,
                "Created On": job.createdOn.toDate(),
                "Updated On": job.updatedOn.toDate(),
                "Completed On": job.completedOn.toDate(),
                "Status": job.status,
                "Status Message": job.statusMsg,
                "Type": job.type,
                "Duration": job.duration ? job.duration : 0,
                "Characters": job.chars ? job.chars : 0,
                "Cost": job.cost
            }
        })
    ));

    let fileBlob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
    });

    saveAs(fileBlob, `ccc_usage_${new Date().toISOString().slice(0,10)}.csv`, {
        autoBom: true
    });
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'usage' ? 'show d-block' : ''}" role="dialog" aria-labelledby="usageTitle" aria-modal="true" tabindex="-1" id="UsageModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="usageTitle">Reporting & Usage</h4>
                <button type="button" class="btn-close" aria-label="Close Modal" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <form class="d-flex flex-row align-items-center flex-wrap">
                                <label class="form-label" id="dateRangeLabel" for="dateRangeSelect">Date Range Select</label>
                                <select class="form-select me-2 mb-2" id="dateRangeSelect" bind:value="{dateRange}" aria-labelledby="dateRangeLabel">
                                    <option value="today">24 Hours</option>
                                    <option value="week">Past Week</option>
                                    <option value="month">Past Month</option>
                                    <option value="year">Past Year</option>
                                    <option value="custom">Custom</option>
                                </select>
                                {#if dateRange === 'custom'}
                                <label class="form-label" id="startDateLabel" for="startDateInput">Custom Date Start</label>
                                <input class="form-control me-2 mb-2" id="startDateInput" type="date" bind:value="{startDate}" aria-labelledby="startDateLabel"/>
                                <label class="form-label" id="endDateLabel" for="endDateInput">Custom Date End</label>
                                <input class="form-control me-2 mb-2" id="endDateInput" type="date" bind:value="{endDate}" aria-labelledby="endDateLabel"/>
                                {/if}
                                <label class="form-label" id="jobTypeLabel" for="jobTypeSelect">Job Type Filter</label>
                                <select class="form-select me-2 mb-2" id="jobTypeSelect" bind:value="{jobTypeFilter}" aria-labelledby="jobTypeLabel">
                                    <option value="All">All</option>
                                    <option value="transcription">Transcription</option>
                                    <option value="translation">Translation</option>
                                    <option value="text to speech">Text To Speech</option>
                                </select>
                                <button type="button" id="refreshBtn" class="btn btn-light mb-2 me-2" disabled="{dateRange === 'custom' && (!startDate || !endDate)}" on:click={() => {gettingJobs = getJobs()}} aria-label="Refresh Results">
                                    <i class="bi bi-arrow-clockwise" aria-hidden="true"></i> Refresh
                                </button>
                                <button type="button" id="exportBtn" class="btn btn-outline-dark mb-2" disabled="{jobs.length === 0}" on:click="{exportCsv}" aria-label="Export Results">
                                    <i class="bi bi-save" aria-hidden="true"></i> Export
                                </button>
                                {#if dateRange === 'custom' && (!startDate || !endDate)}
                                <p class="text-danger small pt-2 me-2">Please provide a start and end date for custom queries.</p>
                                {/if}
                                {#if loading}
                                <Circle size="30" color="#1eb4b2" unit="px" duration="1s"></Circle>
                                {/if}
                            </form>
                        </div>
                    </div>
                    <div class="col-10">
                        <!-- Results Table -->
                        <div id="UsageTable" class="table-responsive rounded shadow border border-secondary">
                            <table class="table table-hover table-light">
                                <thead class="table-light">
                                    <tr>
                                        <th>Project Name</th>
                                        <th>Job Type</th>
                                        <th>Submitted By</th>
                                        <th>Usage</th>
                                        <th>Status</th>
                                        <th>Status Message</th>
                                        <th>Estimated Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#await gettingJobs}
                                    <div style="padding: 10%; position:relative; left:105%;">
                                        <BarLoader size="160" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                                    </div>
                                    {:then}
                                    {#each jobs as job (job.id)}
                                    <tr>
                                        <td class="small" title="{job.projectName}">{job.projectName}</td>
                                        <td title="{job.type}">{job.type}</td>
                                        <td title="{job.submittedBy}">{job.submittedBy}</td>
                                        <td title="{job.chars || job.duration/60}">{job.chars || (job.duration/60).toFixed(2)} {job.chars ? "char(s)" : "min(s)"}</td>
                                        <td title="{job.status}" class="{job.status === 'Passed' ? 'text-primary' : ''} {job.status === 'Failed' ? 'text-danger' : ''}">{job.status}</td>
                                        <td title="{job.statusMsg}">{job.statusMsg.substring(0,25)}</td>
                                        <td title="{job.cost}">${job.type === 'translation' || job.type === 'text to speech' ? job.cost.toFixed(2) : (job.cost/100).toFixed(2)}</td>
                                    </tr>
                                    {:else}
                                    <p class="text-light text-center m-5">No results to display</p>
                                    {/each}
                                    {/await}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="p-4 mb-2 rounded shadow bg-dark text-light">
                            <h4 class="text-truncate" title="{transcriptMins.toLocaleString()}">{transcriptMins.toLocaleString()}</h4>
                            <p class="text-light m-0">Transcription Minutes</p>
                        </div>
                        <div class="p-4 mb-2 rounded shadow bg-dark text-light">
                            <h4 class="text-truncate" title="{translationChars.toLocaleString()}">{translationChars.toLocaleString()}</h4>
                            <p class="text-light m-0">Translation Characters</p>
                        </div>
                        <div class="p-4 mb-2 rounded shadow bg-dark text-light">
                            <h4 class="text-truncate" title="{ttsChars.toLocaleString()}">{ttsChars.toLocaleString()}</h4>
                            <p class="text-light m-0">Text-to-Speech Characters</p>
                        </div>
                        <div class="p-4 mb-2 rounded shadow bg-dark text-light">
                            <h4 class="text-truncate" title="{(totalCost/100).toFixed(2)}">${(totalCost/100).toFixed(2)}</h4>
                            <p class="text-light m-0">Est. Cost <i class="bi bi-info-circle" title="This value is only an estimate based on the cost of the jobs displayed. It does not take into account the number of free minutes or characters included in your plan. For an exact cost please visit the customer portal by going to Help -> Manage Account."></i></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    #UsageTable{
        max-height: 500px;
        overflow-y: auto;
    }
</style>
