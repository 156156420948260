<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {authState} from '@app/store/authStore.js';

import workflowDict from '@app/external/cc-lib/dist/dict/workflow.js';

let useLegacyStorageExplorer = localStorage.getItem("cc-legacy-storage-explorer") === "true";
let useLocalStorage = $projectState.useLocalStorage;
let projectType = $projectState.type;
let name = $projectState.name;
let state = $projectState.state;
let aspectRatio = $projectState.media.aspectRatio;
let description = $projectState.description;
let createdOn = new Date($projectState.created).toString();
let xPadding = $styleState.xPadding;
let yPadding = $styleState.yPadding;
let lineSpacing = $styleState.lineSpacing;
let projectDefaults = JSON.parse(localStorage.getItem("cc-project-defaults")) || {};

function saveChanges() {
    $projectState.type = projectType;
    $projectState.name = name;
    $projectState.description = description;
    $projectState.media.aspectRatio = aspectRatio;
    $projectState.useLocalStorage = useLocalStorage;
    $projectState.state = state;
    $styleState.xPadding = xPadding;
    $styleState.yPadding = yPadding;
    $styleState.lineSpacing = lineSpacing;
    localStorage.setItem("cc-style-defaults", JSON.stringify($styleState));
    localStorage.setItem("cc-project-defaults", JSON.stringify({...projectDefaults, useLocalStorage : useLocalStorage}));
    localStorage.setItem("cc-legacy-storage-explorer", useLegacyStorageExplorer);

    historyState.insert({
        name: "project update", //action name
        eventGroup: false,
        snapshots: [{
            store: "projectState",
            value: JSON.stringify($projectState)
        }]
    });

    modalState.hideModal();
}
</script>

<form id="generalSettingsForm" on:submit|preventDefault="{saveChanges}" aria-label="General Settings">   
    <div class="mb-3 p-3 rounded bg-dark-subtle">
        <label id="projectStateLabel" class="form-label" for="projectStateSelect">Project State</label>
        <select id="projectStateSelect" class="form-select" bind:value={state}>
            {#each workflowDict.states as state}
                <option>{state}</option>
            {/each}
        </select>
    </div>
    <hr class="bg-secondary mt-4"/>
    {#if $authState.team && $authState.team.id}
    <div class="mb-3">
        <label id="projectTypeLabel" class="form-label" for="projectTypeSelect">Project Type</label>
        <select id="projectTypeSelect" class="form-select {$projectState.type === "team" ? 'bg-light' : ''}" bind:value={projectType} disabled="{$projectState.type === "team"}">
            <option>default</option>

            <option>team</option>
            <!-- <option>live</option> -->
        </select>
    </div>
    {/if}
    <div class="row mb-3">
        <div class="col-6">
            <div class="form-check form-switch">
                <input id="useLocalStorageCheck" type="checkbox" class="form-check-input" bind:checked={useLocalStorage} aria-labelledby="useLocalStorageLabel">
                <label class="form-check-label" id="useLocalStorageLabel" for="useLocalStorageCheck">Use Local Storage <i class="bi bi-info-circle" title="Use local storage when saving project files"></i></label>
            </div>    
        </div>
        {#if !useLocalStorage}
        <div class="col-6">
            <div class="form-check form-switch">
                <input id="useLegacyStorageExplorerCheck" type="checkbox" class="form-check-input" bind:checked={useLegacyStorageExplorer} aria-labelledby="useLegacyStorageExplorerLabel">
                <label class="form-check-label" id="useLegacyStorageExplorerLabel" for="useLegacyStorageExplorerCheck">Use Legacy Storage Explorer <i class="bi bi-info-circle" title="Use legacy storage explorer when saving project files to cloud."></i></label>
            </div>    
        </div>
        {/if}
    </div>
    
    <div class="mb-3">
        <label id="projectNameLabel" class="form-label" for="projectNameInput">Project Name</label>
        <input id="projectNameInput" class="form-control" type="text" bind:value={name} aria-labelledby="projectNameLabel">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Project Name">Project Description</label>
        <textarea class="form-control" bind:value={description}></textarea>
    </div>
    <div class="mb-3">
        <label class="form-label" for="Author">Created By</label>
        <input class="form-control bg-light" type="text" disabled readonly value="{$projectState.username}">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Created On Date">Created On</label>
        <input class="form-control bg-light" type="text" disabled readonly value="{createdOn}">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Frame Rate">Frame Rate <i class="bi bi-info-circle" title="The project frame rate can only be changed using the frame rate option under the Timecode menu"></i></label>
        <input class="form-control bg-light" type="text" disabled readonly value="{$projectState.frameRate}">
    </div>
    <div class="mb-3">
        <label class="form-label" for="Aspect Ratio">Aspect Ratio</label>
        <select class="form-select" bind:value={aspectRatio}>
            <option value="16/9">16:9</option>
            <option value="4/3">4:3</option>
            <option value="1/1">1:1</option>
            <option value="9/16">9:16</option>
            <option value="21/9">21:9</option>
            <option value="143/100">1.43:1</option>
            <option value="185/100">1.85:1</option>
            <option value="235/100">2.35:1</option>
            <option value="239/100">2.39:1</option>
            <option value="276/100">2.76:1</option>
        </select>
    </div>
    <div class="row">
        <div class="col">
            <div class="mb-3">
                <label class="form-label" for="Line Spacing">Line Spacing (%)</label>
                <input class="form-control" type="number" bind:value={lineSpacing}>
            </div>
        </div>
        <div class="col">
            <div class="mb-3">
                <label class="form-label" for="Display Padding Horizontal">Display Padding Horizontal (%)</label>
                <input class="form-control" type="number" bind:value={xPadding} step="1" min="0" max="25">
            </div>
        </div>
        <div class="col">
            <div class="mb-3">
                <label class="form-label" for="Display Padding Vertical">Display Padding Vertical (%)</label>
                <input class="form-control" type="number" bind:value={yPadding} step="1" min="0" max="25">
            </div>
        </div>        
    </div>
    <button id="saveGeneralSettingsButton" class="btn btn-primary float-end" type="button" on:click={saveChanges} aria-label="Save general settings">Save Settings</button>
</form>
