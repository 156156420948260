<script>
import { SvelteToast } from '@zerodevx/svelte-toast';
import { toast } from '@zerodevx/svelte-toast';
const ipcRenderer = window.ipcRenderer;
let machineId = "", activationCode = "", errorMsg = "", loading = false;

async function getMachineId(){
    try {
        machineId = await ipcRenderer.invoke("getMachineId");
    } catch(err){
        console.log(err, err.message);
    }
}

async function activate(){
    loading = true;
    try {
        let licenseInfo = await ipcRenderer.invoke("licenseCheck", activationCode);
        if (licenseInfo.active){
            toast.push( `Activation was successful. Please restart the program.`, {classes: ['toast-`success`']});
            localStorage.setItem("cc-offline-license", activationCode);
            window.location.reload();
        } else {
            activationCode = "";
            errorMsg = "Activation Failed. Please contact support for more information (support@closedcaptioncreator.com)";

            toast.push( `There was an error with activation.`, {classes: ['toast-`danger`']});
        } 
    } catch(err){
        errorMsg = "Activation Failed. "+ err.message;
        toast.push( `There was an error with activation. ${err.message}`, {classes: ['toast-`danger`']});
    } finally {
        loading = false;
    }    
}

function copyMachineId(){
        navigator.clipboard.writeText(machineId);
        /* Notification */
        toast.push( `Machine Id copied to clipboard successfully.`, {classes: ['toast-`success`']});
    }

async function PasteActivationCode(){
    try {
        activationCode = await navigator.clipboard.readText();
    } catch(err){
        console.log(err.message);
    }
}

getMachineId();
</script>
    <SvelteToast  options={{
        duration: 1500,
        position: 'top-right',
        showProgress: true,
        pauseOnHover: true,
        closeOnClick: true,
        transition: 'SlideInRight'
    }}/>
    <div id="LoginScreen">
        <div
            class="container d-flex justify-content-center align-items-center LoginPageWrapper">
            <div
                class="col-6 shadow LoginFormWrapper text-white rounded">
                <div
                    class="d-flex justify-content-center align-items-center mb-2">
                    <img
                        src="./icon.png" width="100px"
                        alt="Closed Caption Creator Logo"
                        class="mb-1"/>
                </div>
                <h4 class="text-center mb-4">Closed Caption Creator</h4>
                <h6 class="text-center text-danger">Offline Mode</h6>
                <form on:submit|preventDefault={activate}>
                    <div class="mb-3">
                        <label for="machineIdInput" class="form-label">Machine ID </label>
                        <input type="text" class="form-control" disabled id="machineIdInput" value={machineId}>
                    </div>
                    <div class="mb-3">
                        <label for="activationCodeInput" class="form-label">Activation Code</label>
                        <input type="text" class="form-control" id="activationCodeInput" placeholder="Activation Code" bind:value={activationCode}>
                    </div>
                    <button type="button" class="btn-small btn btn-secondary" on:click={copyMachineId}>Copy Machine Id</button>
                    <button type="button" class="btn-small btn btn-secondary" on:click={PasteActivationCode}>Paste Code</button>
                    <button type="button" class="btn btn-primary {!activationCode ? 'disabled' : ''}" on:click={activate}>Activate</button>
                    {#if errorMsg}
                    <p class="text-danger my-2">{errorMsg}</p>
                    {/if}
                </form>
                <div class='my-3'>
                    <p>Activation Instructions:</p>
                    <ul>
                        <li>Copy the Machine ID from above</li>
                        <li>Visit https://app.closedcaptioncreator.com on a computer with an internet connection</li>        
                        <li>Login to Closed Caption Creator using your email and password</li>           
                        <li>Go to Help -&gt; Offline Activation</li>
                        <li>Enter the Machine Id and click Activate to generate an Activation Code</li>
                        <li>Paste the Activation Code in the form field above</li>
                    </ul>
                    <small class="text-center text-warning">Please contact support if you have any questions: support@closedcaptioncreator.com</small>
                </div>
                
            </div>
        </div>
    </div>
    
    <style>
    #LoginScreen {
        background-color: #080d08;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23080d08'/%3E%3Cstop offset='1' stop-color='%23222222'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23383838' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23383838' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23383838' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23383838' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E") !important;
        background-attachment: fixed;
        background-size: cover;
    }
    
    .LoginPageWrapper {
        height: 100vh;
    }
    
    .LoginFormWrapper {
        background: #202121;
        border-style: none;
        padding-top: 10%;
        padding-bottom: 10%;
        padding-right: 5%;
        padding-left: 5%;
    }
    </style>
    