<script>
import {
    onMount
} from 'svelte';
import {
    styleState
} from '@app/store/styleStore.js';
import {
    environment
} from '@app/store/envStore.js';
import {
    fontState
} from '@app/store/fontStore.js';
import { toast } from '@zerodevx/svelte-toast';

import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
import {
    v4 as uuidv4
} from 'uuid';
import Swal from 'sweetalert2';
import {
    BarLoader
} from 'svelte-loading-spinners'

let userId,
    homeRef,
    loading = false,
    gettingPresets = false,
    presets = [],
    selectedPreset = false;

if ($environment.online) {
    userId = firebase.auth().currentUser.uid;
    homeRef = db.collection("users").doc(userId).collection("styles");
}

onMount(() => {
    gettingPresets = getPresets();
});

let defaultPreset = {
        mode: true,
        adrPreview: false,
        grid: false,
        name: "Default",
        borderStyle: "3",
        fontFamily: "monospace",
        fontSize: 16,
        spacing: 0,
        color: "#ffffff",
        opacity: 100,
        shadow: 0,
        shadowColor: "#000000",
        shadowOpacity: 100,
        outline: 5,
        outlineColor: "#000000",
        outlineOpacity: 100,
        windowXOffset: 25, //px
        windowYOffset: 25, //px
        xPadding: 18, //%
        yPadding: 10, //%
        lineSpacing: 100
    }

function getPresets(id = null) {
    loading = true;
    presets = [];
    if ($environment.online) {
        return homeRef.orderBy("name").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                //console.log(doc.data());
                //console.log(doc.id, " => ", doc.data());
                presets = [...presets, doc.data()];
            });

            loadPreset();
        }).catch((error) => {
            console.log("Error getting presets: ", error);
            toast.push(error.message, {classes: ['toast-danger']});
        }).finally(() => {
            loading = false;
        });
    } else {
        try {
            let offlinePresets = localStorage.getItem("cc-style-presets");
            presets = offlinePresets ? JSON.parse(offlinePresets) : [];
            console.log(presets);
            loadPreset();
            loading = false;
        } catch (err) {
            toast.push(err.message, {classes: ['toast-danger']});
            console.log(err.message);
            console.log(err);
            loading = false;
        }
    }
}

function changePreset(){
    $styleState.mounted = false;
    loadPreset();
}

function loadPreset() {
    let presetStyle;
    //console.log(selectedPreset);

    if (selectedPreset === false) {
        let savedStyle = localStorage.getItem("cc-style-preset");
        //console.log("Saved Style: "+savedStyle);

        if (!savedStyle) {
            presetStyle = defaultPreset;
        } else {
            presetStyle = getPresetByName(savedStyle);
            //console.log(presetStyle);
            if (presetStyle) {
                selectedPreset = presetStyle.id;
            } else {
                presetStyle = defaultPreset;
            }
        }
    } else {
        presetStyle = getPresetById(selectedPreset);
        //console.log(presetStyle);
        if (presetStyle) {
            localStorage.setItem("cc-style-preset", presetStyle.name)
        }
    }

    presetStyle = presetStyle || defaultPreset;

    if (!$styleState.mounted){
        $styleState.grid = presetStyle.grid;
        $styleState.name = presetStyle.name || "";
        $styleState.mode = presetStyle.mode;
        $styleState.adrPreview = presetStyle.adrPreview,
        $styleState.borderStyle = presetStyle.borderStyle || "3";
        $styleState.fontFamily = presetStyle.fontFamily || "monospace";
        $styleState.fontSize = presetStyle.fontSize || 16;
        $styleState.spacing = presetStyle.spacing || 0;
        $styleState.color = presetStyle.color || "#ffffff";
        $styleState.opacity = presetStyle.opacity || 100;
        $styleState.shadow = presetStyle.shadow || 0;
        $styleState.shadowColor = presetStyle.shadowColor || "#000000";
        $styleState.shadowOpacity = presetStyle.bgOpacity || 100;
        $styleState.outline = presetStyle.outline || 5;
        $styleState.outlineColor = presetStyle.outlineColor || "#000000";
        $styleState.outlineOpacity = presetStyle.outlineOpacity || 100;
        $styleState.windowXOffset = presetStyle.windowXOffset || 25;
        $styleState.windowYOffset = presetStyle.windowYOffset || 25;
        $styleState.xPadding = presetStyle.xPadding || 18;
        $styleState.yPadding = presetStyle.yPadding || 10;
        $styleState.lineSpacing = presetStyle.lineSpacing || 100;
        $styleState.mounted = true;
    }
}

function getPresetIdByName(name) {
    let p = presets.find(preset => {
        return preset.name === name;
    });

    if (p) {
        return p.id;
    } else {
        return false;
    }
}

function getPresetById(id) {
    return presets.find(preset => {
        return preset.id === id;
    });
}

function getPresetByName(name) {
    return presets.find(preset => {
        return preset.name === name;
    });
}

function savePreset() {
    loading = true;
    Swal.fire({
        titleText: 'Save Preset',
        text: 'Please enter a preset name:',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Save Preset',
        denyButtonText: 'Cancel',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        inputLabel: "Preset Name",
        inputValue: $styleState.name || "Untitled",
        input: "text",
        customClass: {
            confirmButton: 'btn btn-lg btn-primary me-2',
            denyButton: 'btn btn-lg btn-outline-secondary',
        }
    }).then((result) => {
        //console.log(result);
        if (result.isConfirmed) {
            let presetName = result.value;
            if (presetName) {
                let presetInfo = {
                    ...$styleState,
                    id: getPresetIdByName(presetName) || uuidv4(),
                    name: presetName,
                    grid: ($styleState.grid === undefined ? false : $styleState.grid)
                }

                // console.log(JSON.stringify(presetInfo));

                if ($environment.online) {
                    homeRef.doc(presetInfo.id).set(presetInfo).then(() => {
                        selectedPreset = presetInfo.id;
                        getPresets();
                    }).catch((error) => {
                        console.log("Error saving preset:");
                        console.log(error)
                        toast.push(error.message, {classes: ['toast-danger']});
                    }).finally(() => {
                        loading = false;
                    });
                } else {
                    try {
                        let presetId = presets.findIndex(pre => {
                            return pre.id === presetInfo.id;
                        });

                        if (presetId > -1) {
                            presets[presetId] = presetInfo;
                        } else {
                            presets.push(presetInfo);
                        }

                        localStorage.setItem("cc-style-presets", JSON.stringify(presets));
                        selectedPreset = presetInfo.id;
                        getPresets();
                    } catch (error) {
                        console.log("Error saving preset:");
                        console.log(error)
                        toast.push(error.message, {classes: ['toast-danger']});
                    } finally {
                        loading = false;
                    }
                }
            }
        } else {
            loading = false;
            toast.push("Save preset aborted.", {classes: ['toast-info']});
        }
    }).catch((err) => {
        console.log(err);
        console.log(err.message);
        loading = false;

        toast.push(err.message, {classes: ['toast-danger']});
    });
}

function deletePreset() {
    loading = true;

    if (!selectedPreset) {
        loading = false;
        selectedPreset = false;
        getPresets()
        return;
    }

    if ($environment.online) {
        homeRef.doc(selectedPreset).delete().then(() => {
            toast.push("Preset was deleted successfully", {classes: ['toast-success']});
        }).catch((error) => {
            console.log("Error deleting preset record: ", error);
            toast.push("Failed to delete preset. " + error.message, {classes: ['toast-danger']});

        }).finally(() => {
            gettingPresets = getPresets();
        });
    } else {
        try {
            let filteredPresets = presets.filter(pre => {
                return pre.id !== selectedPreset;
            });

            localStorage.setItem("cc-style-presets", JSON.stringify(filteredPresets));
        } catch (err) {
            console.log(err, err.message);
            toast.push("Failed to delete preset. " + err.message, {classes: ['toast-danger']});
        } finally {
            gettingPresets = getPresets();
        }
    }
}
</script>

<div>
    {#await gettingPresets}
    <div class="d-flex justify-content-center align-items-center">
        <BarLoader size="120" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
    </div>
    {:then}
    <form>
        <div class="row g-2 mb-2 border-bottom border-secondary">
            <div class="col-2">
                <select id="displayProfileSelect" class="form-select form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} bind:value="{$styleState.borderStyle}">
                    <option value = "3">Solid Background</option>
                    <option value = "1">Text Outline</option>
                </select>
                <label class="form-label small" for="displayProfileSelect">Display</label>
            </div>
            <div class="col-2">
                <select id="fontFamilySelect" class="form-select form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} style="font-family: {$styleState.fontFamily};" bind:value="{$styleState.fontFamily}">
                    {#each $fontState as font (font.id)}
                    <option style="font-family: {font.name};">{font.name}</option>
                    {/each}
                    <option style="font-family: Arial;">Arial</option>
                    <option style="font-family: Verdana;">Verdana</option>
                    <option style="font-family: Helvetica;">Helvetica</option>
                    <option style="font-family: Tahoma;">Tahoma</option>
                    <option style="font-family: Trebuchet MS;">Trebuchet MS</option>
                    <option style="font-family: Times New Roman;">Times New Roman</option>
                    <option style="font-family: Georgia;">Georgia</option>
                    <option style="font-family: Garamond;">Garamond</option>
                    <option style="font-family: Courier New;">Courier New</option>
                    <option style="font-family: Brush Script MT;">Brush Script MT</option>
                    <option style="font-family: serif;">serif</option>
                    <option style="font-family: sans-serif;">sans-serif</option>
                    <option style="font-family: monospace;">monospace</option>
                    <option style="font-family: cursive;">cursive</option>
                    <option style="font-family: fantasy;">fantasy</option>
                </select>
                <label class="form-label small" for="fontFamilySelect">Font Family</label>
            </div>
            <div class="col-2">
                <input id="fontSizeInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="1" step="1" bind:value="{$styleState.fontSize}"/>
                <label class="form-label small"  for="fontSizeInput">Font Size</label>
            </div>
            <div class="col-2">
                <input id="fontColorInput" type="color" class="form-control form-control-sm" bind:value="{$styleState.color}" disabled={$styleState.mode}/>
                <label class="form-label small"  for="fontColorInput" >Font Color</label>
            </div>
            <div class="col">
                <input id="textopacityInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="1" step="1" max="100" bind:value="{$styleState.opacity}"/>
                <label class="form-label small"  for="textopacityInput" >Opacity</label>
            </div>
            <div class="col-2">
                <input id="letterSpacingInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="0" step="1" bind:value="{$styleState.spacing}"/>
                <label class="form-label small"  for="letterSpacingInput" >Spacing</label>
            </div>
            <div class="col-2">
                <input id="secondaryColorInput" type="color" class="form-control form-control-sm" bind:value="{$styleState.outlineColor}" disabled={$styleState.mode}/>
                <label class="form-label small"  for="secondaryColorInput">Secondary</label>
            </div>
            <div class="col-2">
                <input id="secondaryOpacityInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="1" step="1" max="100" bind:value="{$styleState.outlineOpacity}"/>
                <label class="form-label small"  for="secondaryOpacityInput" >Opacity</label>
            </div>
            <div class="col-2">
                <input id="shadowColorInput" type="color" class="form-control form-control-sm" bind:value="{$styleState.shadowColor}" disabled={$styleState.mode}/>
                <label class="form-label small"  for="shadowColorInput" >Shadow</label>
            </div>
            <div class="col-2">
                <input id="shadowOpacityInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="1" step="1" max="100" bind:value="{$styleState.shadowOpacity}"/>
                <label class="form-label small"  for="shadowOpacityInput" >Opacity</label>
            </div>
            <div class="col-2">
                <input id="shadowSizeInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="0" step="0.1" bind:value="{$styleState.shadow}"/>
                <label class="form-label small"  for="shadowSizeInput" >Shadow Size</label>
            </div>
            <div class="col-2">
                <input id="paddingInput" type="number" class="form-control form-control-sm {$styleState.mode ? '' : ''}" disabled={$styleState.mode} min="0" step="0.1" bind:value="{$styleState.outline}"/>
                <label class="form-label small"  for="paddingInput" >Padding</label>
            </div>
        </div>
        <div class="row align-items-center g-1">
            <div class="col-6">
                <select class="form-select form-select-sm" bind:value="{selectedPreset}" on:change={() => changePreset()}>
                    {#each presets as preset (preset.id)}
                    <option value="{preset.id}">{preset.name}</option>
                    {/each}
                </select>
            </div>
            <div class="col-auto">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-outline-dark" title="Save Preset" on:click="{() => savePreset()}"><i class="bi bi-upload"></i></button>
                    <button type="button" class="btn btn-outline-dark" title="Delete Preset" on:click="{() => deletePreset()}"><i class="bi bi-trash"></i></button>
                </div>
            </div>
            <div class="col">
                <button type="button" class="btn btn-sm {$styleState.grid ? 'btn-primary' : 'btn-light text-muted'}" on:click="{() => $styleState.grid = !$styleState.grid}"><i class="bi bi-grid-3x3"></i></button>
                <div class="form-check form-switch float-end">
                    <input class="form-check-input" type="checkbox" role="switch" id="StyleModeSwitch" bind:checked={$styleState.mode}>
                    <label class="form-check-label" for="StyleModeSwitch">{$styleState.mode ? "Caption" : "Subtitle"} Mode</label>
                </div>
            </div>
        </div>
    </form>
    {:catch}
    <div class="d-flex justify-content-center align-items-center">
        <p class="text-center">There was an error loading style presets. Please reload tab.</p>
    </div>
    {/await}
</div>
