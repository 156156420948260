<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { fade } from "svelte/transition";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import _FrameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    import tcLib from '@app/external/cc-lib/dist/lib/timecode.js'
    let clockTimes = "maintain"; //modify, or maintain
    let srcFrameRate = $projectState.frameRate.toString();
    let trgFrameRate = $projectState.frameRate.toString();
    let srcDropFrame = $projectState.dropFrame ? "true" : "false";
    let trgDropFrame = $projectState.dropFrame ? "true" : "false";
    let maintainOffset = false;
    let applyAll = false;
    let incode = tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame);

    let frameRateCalcMap = {
        "23.976": "23.976",
        "24": "24",
        "25": "25",
        "29.97": "29.97",
        "30": "30",
        "50" : "25",
        "59.94": "29.97",
        "60": "30",
    };

    function updateSrcDropFrame(){
        if (["29.97", "59.94"].indexOf(srcFrameRate) > -1){
            srcDropFrame = "true";
        } else {
            srcDropFrame = "false";
        }
    }

    function updateTrgDropFrame(){
        if (["29.97", "59.94"].indexOf(trgFrameRate) > -1){
            trgDropFrame = "true";
        } else {
            trgDropFrame = "false";
        }
    }

    function convertTimecode(){
       try {
            if (clockTimes === "modify"){
                let srcFrameRateCalc = frameRateCalcMap[srcFrameRate];
                let trgFrameRateCalc = frameRateCalcMap[trgFrameRate];

                $eventGroupState.forEach((evg, evgIndex) =>{
                    if (applyAll || evgIndex === $projectState.selected){
                        evg.events.forEach((event, index, events) => {
                            events[index].start = event.start * (parseFloat(srcFrameRateCalc) / parseFloat(trgFrameRateCalc));
                            events[index].end = event.end * (parseFloat(srcFrameRateCalc) / parseFloat(trgFrameRateCalc));
                        });
                    }
                });
                
            }

            
            if (maintainOffset){
                $projectState.incode = tcLib.tcToSec(tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame), parseFloat(trgFrameRate), $projectState.dropFrame = trgDropFrame === "true" ? true : false);
            }

            $projectState.frameRate = parseFloat(trgFrameRate);
            $projectState.dropFrame = trgDropFrame === "true" ? true : false;

            historyState.insert({
                name: "convert timecode", //action name
                eventGroup: false,
                snapshots: [
                    {
                        store: "projectState",
                        value: JSON.stringify($projectState),
                    },
                ],
            });

            toast.push(`Timecode conversion complete.`, {
                classes: ["toast-success"],
            });
       } catch(err){
            toast.push(`Timecode conversion failed.`, {
                classes: ["toast-error"],
            });

            console.log(err.message, err);
       } finally {
            modalState.hideModal();
       }        
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'convertTimecode' ? 'show d-block' : ''}"
    role="dialog"
    aria-labelledby="convertTimecodeTitle"
    aria-describedby="convertTimecodeDescription"
    tabindex="-1"
    id="ConvertTimecodeModal"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="convertTimecodeTitle">Convert Timecode</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close Modal"
                    id="closeConvertTimecodeBtn"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p id="convertTimecodeDescription" class="sr-only">Convert timecode settings and options</p>
                <form on:submit|preventDefault={convertTimecode} id="ConvertTimecodeForm">
                    <!-- Radio buttons for user to set clockTimes to modify or maintain   -->
                     <div class="row">
                        <div class="col-12 mb-3">
                            <fieldset>
                                <legend class="sr-only">Clock Times Option</legend>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="clockTimesRadios" 
                                        id="maintainClockTimesCheck" 
                                        value="maintain" 
                                        bind:group={clockTimes}
                                        aria-describedby="maintainClockTimesHelp"
                                    >
                                    <label class="form-check-label" for="maintainClockTimesCheck">
                                      Maintain Clock Times 
                                    </label>
                                    <span id="maintainClockTimesHelp" class="visually-hidden">The timing will remain the same, but will be represented by different SMPTE timecode values</span>
                                </div>
                                <div class="form-check">
                                    <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="clockTimesRadios" 
                                        id="modifyClockTimesCheck" 
                                        value="modify" 
                                        bind:group={clockTimes}
                                        aria-describedby="modifyClockTimesHelp"
                                    >
                                    <label class="form-check-label" for="modifyClockTimesCheck">
                                      Modify Clock Times 
                                    </label>
                                    <span id="modifyClockTimesHelp" class="visually-hidden">The timing will be adjusted based on the difference between the source and target Frame Rates</span>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-6 mb-3">
                            <!-- Select List of From and To -->
                            <label class="form-label" for="srcFrameRateSelector">Source Frame Rate</label>
                            <select 
                                id="srcFrameRateSelector" 
                                class="form-select" 
                                on:change={updateSrcDropFrame} 
                                bind:value={srcFrameRate}
                                aria-describedby="srcFrameRateHelp"
                            >
                                <option value="23.976">23.976</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="29.97">29.97</option>
                                <option value="30">30</option>
                                <option value="59.94">59.94</option>
                                <option value="60">60</option>
                            </select>
                            <span id="srcFrameRateHelp" class="visually-hidden">Select the frame rate of your source material</span>
                        </div>
                        <div class="col-6 mb-3">
                            <!-- Select List of From and To -->
                            <label class="form-label" for="trgFrameRateSelector">Target Frame Rate</label>
                            <select 
                                id="trgFrameRateSelector" 
                                class="form-select" 
                                on:change={updateTrgDropFrame} 
                                bind:value={trgFrameRate}
                                aria-describedby="trgFrameRateHelp"
                            >
                                <option value="23.976">23.976</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="29.97">29.97</option>
                                <option value="30">30</option>
                                <option value="59.94">59.94</option>
                                <option value="60">60</option>
                            </select>
                            <span id="trgFrameRateHelp" class="visually-hidden">Select the frame rate of your target material</span>
                        </div>

                        {#if ["29.97", "59.94"].indexOf(srcFrameRate) > -1}
                        <div class="col-6 mb-3">
                            <label class="form-label" for="srcDropFrameSelector">Source Drop Frame</label>
                            <select id="srcDropFrameSelector" class="form-select" bind:value={srcDropFrame}>
                                <option value="true">true</option>
                                <option value="false">false</option>
                            </select>
                        </div>
                        {/if}
                        {#if ["29.97", "59.94"].indexOf(trgFrameRate) > -1}
                        <div class="col-6 mb-3">
                            <label class="form-label" for="trgDropFrameSelector">Target Drop Frame</label>
                            <select id="trgDropFrameSelector" class="form-select" bind:value={trgDropFrame}>
                                <option value="true">true</option>
                                <option value="false">false</option>
                            </select>
                        </div>
                        {/if}
                     </div>
                    <div class="mb-3 col-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="maintainOffsetChecked" bind:checked={maintainOffset}>
                            <label class="form-check-label" for="maintainOffsetChecked">
                              Maintain Project Incode ({incode}) <i class="bi bi-info-circle" title="Timecode conversion will not impact the Project Incode. The first Event will continue to start from the current incode value."></i>
                            </label>
                          </div>
                    </div>
                    <div class="mb-3 col-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="applyToAllChecked" bind:checked={applyAll}>
                            <label class="form-check-label" for="applyToAllChecked">
                              Apply to All Event Groups<i class="bi bi-info-circle" title="Apply timecode conversion to all Event Groups (checked) or to the selected Event Group (unchecked)"></i>
                            </label>
                          </div>
                    </div>

                    <button 
                        class="btn btn-primary float-end" 
                        type="submit" 
                        id="convertTimecodeSubmit"
                        aria-label="Convert Timecode"
                        on:click={convertTimecode}
                    >Convert</button>
                </form>
            </div>
        </div>
    </div>
</div>
