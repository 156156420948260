import _getCps from "../events/getCps.js";
import _getDurationByCps from "../events/getDurationByCps.js";
const getDurationByCps = _getDurationByCps;
const getCps = _getCps;
export default (function autoCorrectTiming(eventGroup, frameRate, maxCps, minDuration, maxDuration, minFrameGap) {
  //console.log(maxCps, minDuration, maxDuration);
  try {
    let loops = 0;
    let processing = true;
    let frameGapSec = minFrameGap / Math.round(frameRate);
    /* CPS and Duration Check */
    while (processing && loops < 15) {
      processing = false;
      loops++;
      eventGroup.events.forEach((event, i, events) => {
        let cps = getCps(event),
          duration = event.end - event.start,
          idealDuration = getDurationByCps(event, maxCps),
          prevEvent = i > 0 ? events[i - 1] : null,
          nextEvent = i < events.length - 1 ? events[i + 1] : null;
        if (cps > maxCps) {
          processing = true;
          if (nextEvent && event.start + idealDuration <= nextEvent.start) {
            event.end = event.start + idealDuration;
          } else if (!nextEvent) {
            event.end = event.start + idealDuration;
          } else if (prevEvent && prevEvent.end <= event.end - idealDuration) {
            event.start = event.end - idealDuration;
          } else if (!prevEvent && 0 <= event.end - idealDuration) {
            event.start = event.end - idealDuration;
          } else {
            event.end = event.start + idealDuration;
            events[i + 1].start = event.end;
          }
          duration = event.end - event.start;
        }
        if (duration < minDuration) {
          processing = true;
          if (nextEvent && event.start + minDuration <= nextEvent.start) {
            event.end = event.start + minDuration;
          } else if (!nextEvent) {
            event.end = event.start + minDuration;
          } else if (prevEvent && prevEvent.end <= event.end - minDuration) {
            event.start = event.end - minDuration;
          } else if (!prevEvent && 0 <= event.end - minDuration) {
            event.start = event.end - minDuration;
          } else {
            event.end = event.start + minDuration;
            events[i + 1].start = event.end;
          }
        } else if (duration > maxDuration && maxDuration < idealDuration) {
          processing = true;
          event.end = event.start + maxDuration;
        }
        events[i] = event;
      });
    }

    /* Frame Gap Check */
    eventGroup.events.forEach((event, i, events) => {
      if (i < events.length - 1) {
        let nextEvent = events[i + 1];
        let oGap = nextEvent.start - event.end;
        if (oGap < frameGapSec) {
          let gapDelta = frameGapSec - oGap;
          events[i + 1].start = event.end + frameGapSec;
          events[i + 1].end += gapDelta;
        }
      }
    });
    return eventGroup;
  } catch (err) {
    console.log(err);
    console.log(err.message);
    throw new Error("Failed to auto correct timing with error " + err.message);
  }
});