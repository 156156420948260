import _tcLib from "../../lib/timecode.js";
import _tcOffset from "../events/tcOffset.js";
import _orderByTime from "../eventGroups/orderByTime.js";
const orderByTime = _orderByTime;
const tcOffset = _tcOffset;
const tcLib = _tcLib;
export default (function automaticOffset(eventGroup, frameRate, incode = "00:00:00:00") {
  orderByTime(eventGroup);
  let tcStart = tcLib.secToTc(eventGroup.events[0].start, frameRate);
  let subtitleHours = tcStart.split(":")[0];
  let incodeHours = incode.split(":")[0];
  let offsetAmount = incodeHours - subtitleHours;
  let offsetTc;
  if (offsetAmount == 0) {
    return eventGroup;
  } else {
    offsetTc = tcLib.tcToSec(Math.abs(offsetAmount) + ":00:00:00", frameRate);
    eventGroup.events.forEach((event, index, events) => {
      events[index] = tcOffset(event, offsetTc, offsetAmount > 0 ? 'add' : 'subtract');
    });
    return eventGroup;
  }
});