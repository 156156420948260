import { writable } from 'svelte/store';
function createPlayerStore() {
    const { subscribe, update } = writable({
        duration: false,
        time: 0,
        speed: 1,
        playing: false
    });

    return {
        subscribe,
        updateTime: (time) => update(playerState => {
            playerState.time = time;
            return playerState;
        }),
        updateDuration: (duration) => update(playerState => {
            playerState.duration = duration;
            playerState.playing = duration ? true : false;
            return playerState;
        }),
        updatePlayingStatus: (status) => update(playerState => {
            playerState.playing = status;
            return playerState;
        }),
        updatePlayerSpeed: (speed) => update(playerState => {
            playerState.speed = speed;
            return playerState;
        })
    };
}

export const playerState = createPlayerStore();