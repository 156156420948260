<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
const { webFrame } = require('electron')

let zoomLevel = webFrame.getZoomFactor() * 100;

function zoomIn() {
    zoomLevel += 10
    webFrame.setZoomFactor(zoomLevel/100);
}

function zoomOut() {
    zoomLevel -= 10
    webFrame.setZoomFactor(zoomLevel/100);
}

function updateZoomLevel() {
    webFrame.setZoomFactor(zoomLevel/100);
}

</script>

<div transition:fade="{{duration: 100}}" 
     class="modal {$modalState === 'zoom' ? 'show d-block' : ''}" 
     role="dialog" 
     tabindex="-1" 
     id="ZoomModal"
     aria-labelledby="zoomModalTitle"
     aria-modal="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="zoomModalTitle">Zoom Settings</h4>
                <button type="button" 
                        class="btn-close" 
                        aria-label="Close dialog"
                        id="zoomCloseBtn" 
                        on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="input-group" role="group" aria-label="Zoom controls">
                        <button on:click={zoomIn} 
                                id="zoomInBtn"
                                class="btn btn-outline-dark" 
                                type="button"
                                aria-label="Zoom in">
                            <i class="bi bi-plus-lg" aria-hidden="true"></i>
                        </button>
                        <button on:click={zoomOut} 
                                id="zoomOutBtn"
                                class="btn btn-outline-dark" 
                                type="button"
                                aria-label="Zoom out">
                            <i class="bi bi-dash-lg" aria-hidden="true"></i>
                        </button>
                        <input type="number" 
                               id="zoomValue"
                               step="10" 
                               min="50" 
                               max="150" 
                               class="form-control" 
                               aria-label="Zoom percentage value"
                               placeholder="Zoom Value" 
                               bind:value={zoomLevel} 
                               on:blur={updateZoomLevel}>
                        <span class="input-group-text" aria-hidden="true">%</span>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>
