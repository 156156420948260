import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
function extractPositionData(text) {
  // Regular expression to match position data like {\anX}
  var positionRegex = /{\\an(\d+)}/;

  // Use the match method to find the position data
  var match = text.match(positionRegex);
  if (match) {
    // Extract the position value (X)
    var positionValue = parseInt(match[1]);
    return {
      hasPositionData: true,
      position: positionValue
    };
  } else {
    return {
      hasPositionData: false,
      position: null
    };
  }
}
let positionMap = {
  1: {
    x: "start",
    y: "end"
  },
  2: {
    x: "center",
    y: "end"
  },
  3: {
    x: "end",
    y: "end"
  },
  4: {
    x: "start",
    y: "center"
  },
  5: {
    x: "center",
    y: "center"
  },
  6: {
    x: "end",
    y: "center"
  },
  7: {
    x: "start",
    y: "start"
  },
  8: {
    x: "center",
    y: "start"
  },
  9: {
    x: "end",
    y: "top"
  }
};
let positionMapReverse = {
  "start": {
    "start": 7,
    "center": 4,
    "end": 1
  },
  "center": {
    "start": 8,
    "center": 5,
    "end": 2
  },
  "end": {
    "start": 9,
    "center": 6,
    "end": 3
  }
};
export default {
  decode: function (input, options) {
    let events = [],
      eventId,
      tcInfo;
    let subtitleBlocks = input.split("\n\n");
    subtitleBlocks.forEach(block => {
      let ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.1
      });
      let blockInfo = block.split("\n").filter(blockLine => {
        return blockLine !== "";
      });
      if (blockInfo.length > 1) {
        eventId = blockInfo.shift();
        tcInfo = blockInfo.shift();
        ccEvent.start = tcLib.tcMsToSec(tcInfo.split(" --> ")[0]);
        ccEvent.end = tcLib.tcMsToSec(tcInfo.split(" --> ")[1]);
        let rawText = blockInfo.join("\n");
        try {
          let positionData = extractPositionData(rawText);
          if (positionData.hasPositionData) {
            ccEvent.xPos = positionMap[positionData.position] ? positionMap[positionData.position].x : "center";
            ccEvent.yPos = positionMap[positionData.position] ? positionMap[positionData.position].y : "end";
          }
        } catch (err) {
          console.log("Error parsing positiong data in SRT: ", err.message);
        }
        rawText = rawText.replace(/\\N$/gm, "").replace(/{\\an(\d+)}/gm, "");
        ccEvent.text = convertToHtml(rawText);
        events.push(ccEvent);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "",
      errors = 0,
      encodingOptions = getFormatOptions(options.formatOptions);
    eventGroup.events.forEach(function (event, id) {
      try {
        let startMs = tcLib.secToTcMs(event.start);
        let endMs = tcLib.secToTcMs(event.end);
        output += id + 1 - errors + "\n";
        output += startMs + " --> " + endMs + "\n";
        /* Encode Positioning if required */
        if (encodingOptions["Encode Positioning"] && encodingOptions["Encode Positioning"] != "false") {
          output += `{\\an${positionMapReverse[event.xPos][event.yPos]}}`;
        }
        if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] != "false") {
          output += convertToPlainTextCustom(event.text, "\n", false, "b", "i", "u") + "\n\n";
        } else {
          output += convertToPlainText(event.text) + "\n\n";
        }
      } catch (e) {
        console.log(e, e.message);
        errors++;
      }
    });
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input).trim().replace(/'(\n){3,}'/gim, "\n\n");
    }
  },
  postProcess: {
    encode: function (output) {
      return output.replace(new RegExp("(\n){3,}", "gim"), "\n\n").replace(/\n\n(?=\D)/gim, "\n").replace(/^ (?=.)/gm, '');
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};