<script>
import {projectState} from '@app/store/projectStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

import firebase from '@app/configs/firebase.js';
let userId = firebase.auth().currentUser.uid;
</script>

{#if $projectState.ownerId === userId}
<li class="nav-item" role="menuitem">
    <a class="nav-link" href="#!/" on:click={() => modalState.showModal("snapshotHistory")} id="historyBtn" aria-label="Project History">
        <i class="bi bi-clock-history" aria-hidden="true"></i>
    </a>
</li>
<li class="nav-item" role="menuitem">
    <a class="nav-link" href="#!/" on:click={() => modalState.showModal("teamShare")} id="shareBtn" aria-label="Share Project">
        <i class="bi bi-folder-symlink" aria-hidden="true"></i>
    </a>
</li>
{/if}
<li class="nav-item" role="menuitem" title="Sync Changes"> 
    <a class="nav-link {$historyState.actions.length > 1 ? 'text-warning' : ''}" href="#!/" 
       on:click={() => modalState.showModal("teamSync")} 
       id="syncBtn" 
       aria-label="Sync Changes">
        <i class="bi bi-cloud-upload" aria-hidden="true"></i>
    </a>
</li>

<style>
.text-warning>i {
    animation: fade 1s linear infinite;
}

@keyframes fade {  
  50% { opacity: 0.2; }
}
</style>