export default {
  "\\": "é",
  "{": "ç",
  "§00": "{FILLER}",
  "0137": "{FILLER}",
  "1120": "{COLOR:WHITE}",
  "112e": "{ITALICS}",
  "112f": "{ITALICS_UNDERLINE}",
  "1130": "®",
  "1131": "°",
  "1132": "½",
  "1133": "¿",
  "1134": "™",
  "1135": "¢",
  "1136": "£",
  "1137": "♪",
  "1138": "à",
  // "1139": " ", // transparent space
  "113a": "è",
  "113b": "â",
  "113c": "ê",
  "113d": "î",
  "113e": "ô",
  "113f": "û",
  "1220": "Á",
  "1221": "É",
  "1222": "Ó",
  "1223": "Ú",
  "1224": "Ü",
  "1225": "ü",
  "1226": "‘",
  "1227": "¡",
  "1228": "*",
  "1229": "’",
  "122a": "—",
  "122b": "©",
  "122c": "℠",
  "122d": "•",
  "122e": '“',
  "122f": '”',
  "1230": "À",
  "1231": "Â",
  "1232": "Ç",
  "1233": "È",
  "1234": "Ê",
  "1235": "Ë",
  "1236": "ë",
  "1237": "Î",
  "1238": "Ï",
  "1239": "ï",
  "123a": "Ô",
  "123b": "Ù",
  "123c": "ù",
  "123d": "Û",
  "123e": "«",
  "123f": "»",
  "1320": "Ã",
  "1321": "ã",
  "1322": "Í",
  "1323": "Ì",
  "1324": "ì",
  "1325": "Ò",
  "1326": "ò",
  "1327": "Õ",
  "1328": "õ",
  "1329": "{",
  "132a": "}",
  "132b": "\\",
  "132c": "^",
  "132d": "_",
  "132e": "¦",
  "132f": "~",
  "1330": "Ä",
  "1331": "ä",
  "1332": "Ö",
  "1333": "ö",
  "1334": "ß",
  "1335": "¥",
  "1336": "¤",
  "1337": "|",
  "1338": "Å",
  "1339": "å",
  "133a": "Ø",
  "133b": "ø",
  "133c": "┌",
  "133d": "┐",
  "133e": "└",
  "133f": "┘",
  "142e": "{CLEAR BUFFER}",
  "142f": "{DISPLAY BUFFER}",
  "142c": "{CLEAR DISPLAY}",
  "1420": "{RESUME LOADING}",
  "1425": "{ROLLUP2}",
  "1426": "{ROLLUP3}",
  "1427": "{ROLLUP4}",
  "1429": "{PAINT ON}",
  "1721": "{TAB01}",
  // Tab Over
  "1722": "{TAB02}",
  // Tab Over 2
  "1723": "{TAB03}",
  // Tab Over 3
  "114e": "{00_00}{ITALICS}",
  "114f": "{00_00}{ITALICS_UNDERLINE}",
  "1150": "{00_00}",
  "1151": "{00_00}{UNDERLINE}",
  "1152": "{00_03}",
  "1153": "{00_03}{UNDERLINE}",
  "1154": "{00_07}",
  "1155": "{00_07}{UNDERLINE}",
  "1156": "{00_11}",
  "1157": "{00_11}{UNDERLINE}",
  "1158": "{00_15}",
  "1159": "{00_15}{UNDERLINE}",
  "115a": "{00_19}",
  "115b": "{00_19}{UNDERLINE}",
  "115c": "{00_23}",
  "115d": "{00_23}{UNDERLINE}",
  "115e": "{00_27}",
  "115f": "{00_27}{UNDERLINE}",
  "1170": "{01_00}",
  "1171": "{01_00}{UNDERLINE}",
  "1172": "{01_03}",
  "1173": "{01_03}{UNDERLINE}",
  "1174": "{01_07}",
  "1175": "{01_07}{UNDERLINE}",
  "1176": "{01_11}",
  "1177": "{01_11}{UNDERLINE}",
  "1178": "{01_15}",
  "1179": "{01_15}{UNDERLINE}",
  "117a": "{01_19}",
  "117b": "{01_19}{UNDERLINE}",
  "117c": "{01_23}",
  "117d": "{01_23}{UNDERLINE}",
  "117e": "{01_27}",
  "117f": "{01_27}{UNDERLINE}",
  "1250": "{02_00}",
  "1251": "{02_00}{UNDERLINE}",
  "1252": "{02_03}",
  "1253": "{02_03}{UNDERLINE}",
  "1254": "{02_07}",
  "1255": "{02_07}{UNDERLINE}",
  "1256": "{02_11}",
  "1257": "{02_11}{UNDERLINE}",
  "1258": "{02_15}",
  "1259": "{02_15}{UNDERLINE}",
  "125a": "{02_19}",
  "125b": "{02_19}{UNDERLINE}",
  "125c": "{02_23}",
  "125d": "{02_23}{UNDERLINE}",
  "125e": "{02_27}",
  "125f": "{02_27}{UNDERLINE}",
  "1270": "{03_00}",
  "1271": "{03_00}{UNDERLINE}",
  "1272": "{03_03}",
  "1273": "{03_03}{UNDERLINE}",
  "1274": "{03_07}",
  "1275": "{03_07}{UNDERLINE}",
  "1276": "{03_11}",
  "1277": "{03_11}{UNDERLINE}",
  "1278": "{03_15}",
  "1279": "{03_15}{UNDERLINE}",
  "127a": "{03_19}",
  "127b": "{03_19}{UNDERLINE}",
  "127c": "{03_23}",
  "127d": "{03_23}{UNDERLINE}",
  "127e": "{03_27}",
  "127f": "{03_27}{UNDERLINE}",
  "1550": "{04_00}",
  "1551": "{04_00}{UNDERLINE}",
  "1552": "{04_03}",
  "1553": "{04_03}{UNDERLINE}",
  "1554": "{04_07}",
  "1555": "{04_07}{UNDERLINE}",
  "1556": "{04_11}",
  "1557": "{04_11}{UNDERLINE}",
  "1558": "{04_15}",
  "1559": "{04_15}{UNDERLINE}",
  "155a": "{04_19}",
  "155b": "{04_19}{UNDERLINE}",
  "155c": "{04_23}",
  "155d": "{04_23}{UNDERLINE}",
  "155e": "{04_27}",
  "155f": "{04_27}{UNDERLINE}",
  "1570": "{05_00}",
  "1571": "{05_00}{UNDERLINE}",
  "1572": "{05_03}",
  "1573": "{05_03}{UNDERLINE}",
  "1574": "{05_07}",
  "1575": "{05_07}{UNDERLINE}",
  "1576": "{05_11}",
  "1577": "{05_11}{UNDERLINE}",
  "1578": "{05_15}",
  "1579": "{05_15}{UNDERLINE}",
  "157a": "{05_19}",
  "157b": "{05_19}{UNDERLINE}",
  "157c": "{05_23}",
  "157d": "{05_23}{UNDERLINE}",
  "157e": "{05_27}",
  "157f": "{05_27}{UNDERLINE}",
  "1650": "{06_00}",
  "1651": "{06_00}{UNDERLINE}",
  "1652": "{06_03}",
  "1653": "{06_03}{UNDERLINE}",
  "1654": "{06_07}",
  "1655": "{06_07}{UNDERLINE}",
  "1656": "{06_11}",
  "1657": "{06_11}{UNDERLINE}",
  "1658": "{06_15}",
  "1659": "{06_15}{UNDERLINE}",
  "165a": "{06_19}",
  "165b": "{06_19}{UNDERLINE}",
  "165c": "{06_23}",
  "165d": "{06_23}{UNDERLINE}",
  "165e": "{06_27}",
  "165f": "{06_27}{UNDERLINE}",
  "1670": "{07_00}",
  "1671": "{07_00}{UNDERLINE}",
  "1672": "{07_03}",
  "1673": "{07_03}{UNDERLINE}",
  "1674": "{07_07}",
  "1675": "{07_07}{UNDERLINE}",
  "1676": "{07_11}",
  "1677": "{07_11}{UNDERLINE}",
  "1678": "{07_15}",
  "1679": "{07_15}{UNDERLINE}",
  "167a": "{07_19}",
  "167b": "{07_19}{UNDERLINE}",
  "167c": "{07_23}",
  "167d": "{07_23}{UNDERLINE}",
  "167e": "{07_27}",
  "167f": "{07_27}{UNDERLINE}",
  "1750": "{08_00}",
  "1751": "{08_00}{UNDERLINE}",
  "1752": "{08_03}",
  "1753": "{08_03}{UNDERLINE}",
  "1754": "{08_07}",
  "1755": "{08_07}{UNDERLINE}",
  "1756": "{08_11}",
  "1757": "{08_11}{UNDERLINE}",
  "1758": "{08_15}",
  "1759": "{08_15}{UNDERLINE}",
  "175a": "{08_19}",
  "175b": "{08_19}{UNDERLINE}",
  "175c": "{08_23}",
  "175d": "{08_23}{UNDERLINE}",
  "175e": "{08_27}",
  "175f": "{08_27}{UNDERLINE}",
  "1770": "{09_00}",
  "1771": "{09_00}{UNDERLINE}",
  "1772": "{09_03}",
  "1773": "{09_03}{UNDERLINE}",
  "1774": "{09_07}",
  "1775": "{09_07}{UNDERLINE}",
  "1776": "{09_11}",
  "1777": "{09_11}{UNDERLINE}",
  "1778": "{09_15}",
  "1779": "{09_15}{UNDERLINE}",
  "177a": "{09_19}",
  "177b": "{09_19}{UNDERLINE}",
  "177c": "{09_23}",
  "177d": "{09_23}{UNDERLINE}",
  "177e": "{09_27}",
  "177f": "{09_27}{UNDERLINE}",
  "1050": "{10_00}",
  "1051": "{10_00}{UNDERLINE}",
  "1052": "{10_03}",
  "1053": "{10_03}{UNDERLINE}",
  "1054": "{10_07}",
  "1055": "{10_07}{UNDERLINE}",
  "1056": "{10_11}",
  "1057": "{10_11}{UNDERLINE}",
  "1058": "{10_15}",
  "1059": "{10_15}{UNDERLINE}",
  "105a": "{10_19}",
  "105b": "{10_19}{UNDERLINE}",
  "105c": "{10_23}",
  "105d": "{10_23}{UNDERLINE}",
  "105e": "{10_27}",
  "105f": "{10_27}{UNDERLINE}",
  "1350": "{11_00}",
  "1351": "{11_00}{UNDERLINE}",
  "1352": "{11_03}",
  "1353": "{11_03}{UNDERLINE}",
  "1354": "{11_07}",
  "1355": "{11_07}{UNDERLINE}",
  "1356": "{11_11}",
  "1357": "{11_11}{UNDERLINE}",
  "1358": "{11_15}",
  "1359": "{11_15}{UNDERLINE}",
  "135a": "{11_19}",
  "135b": "{11_19}{UNDERLINE}",
  "135c": "{11_23}",
  "135d": "{11_23}{UNDERLINE}",
  "135e": "{11_27}",
  "135f": "{11_27}{UNDERLINE}",
  "1370": "{12_00}",
  "1371": "{12_00}{UNDERLINE}",
  "1372": "{12_03}",
  "1373": "{12_03}{UNDERLINE}",
  "1374": "{12_07}",
  "1375": "{12_07}{UNDERLINE}",
  "1376": "{12_11}",
  "1377": "{12_11}{UNDERLINE}",
  "1378": "{12_15}",
  "1379": "{12_15}{UNDERLINE}",
  "137a": "{12_19}",
  "137b": "{12_19}{UNDERLINE}",
  "137c": "{12_23}",
  "137d": "{12_23}{UNDERLINE}",
  "137e": "{12_27}",
  "137f": "{12_27}{UNDERLINE}",
  "144a": "{13_00}",
  "144e": "{13_00}{ITALICS}",
  "144f": "{13_00}{ITALICS_UNDERLINE}",
  "1450": "{13_00}",
  "1451": "{13_00}{UNDERLINE}",
  "1452": "{13_03}",
  "1453": "{13_03}{UNDERLINE}",
  "1454": "{13_07}",
  "1455": "{13_07}{UNDERLINE}",
  "1456": "{13_11}",
  "1457": "{13_11}{UNDERLINE}",
  "1458": "{13_15}",
  "1459": "{13_15}{UNDERLINE}",
  "145a": "{13_19}",
  "145b": "{13_19}{UNDERLINE}",
  "145c": "{13_23}",
  "145d": "{13_23}{UNDERLINE}",
  "145e": "{13_27}",
  "145f": "{13_27}{UNDERLINE}",
  "146e": "{14_00}{ITALICS}",
  "146f": "{14_00}{ITALICS_UNDERLINE}",
  "1470": "{14_00}",
  "1471": "{14_00}{UNDERLINE}",
  "1472": "{14_03}",
  "1473": "{14_03}{UNDERLINE}",
  "1474": "{14_07}",
  "1475": "{14_07}{UNDERLINE}",
  "1476": "{14_11}",
  "1477": "{14_11}{UNDERLINE}",
  "1478": "{14_15}",
  "1479": "{14_15}{UNDERLINE}",
  "147a": "{14_19}",
  "147b": "{14_19}{UNDERLINE}",
  "147c": "{14_23}",
  "147d": "{14_23}{UNDERLINE}",
  "147e": "{14_27}",
  "147f": "{14_27}{UNDERLINE}",
  "112a": " ",
  "142d": "{NEW LINE}",
  "1428": "{FILLER}",
  "11a1": "{COLOR:WHITE;UNDERLINE}",
  "11a2": "{COLOR:GREEN}",
  "1123": "{COLOR:GREEN;UNDERLINE}",
  "11a4": "{COLOR:BLUE}",
  "1125": "{COLOR:BLUE;UNDERLINE}",
  "1126": "{COLOR:CYAN}",
  "11a7": "{COLOR:CYAN;UNDERLINE}",
  "11a8": "{COLOR:RED}",
  "1129": "{COLOR:RED;UNDERLINE}",
  "112a": "{COLOR:YELLOW}",
  "11ab": "{COLOR:YELLOW;UNDERLINE}",
  "112c": "{COLOR:MAGENTA}",
  "11ad": "{COLOR:MAGENTA;UNDERLINE}",
  "17ae": "{COLOR:BLACK}",
  "172f": "{COLOR:BLACK;UNDERLINE}"
};