<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    lockState
} from '@app/store/lockStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { editorState } from "@app/store/editorStore.js";
import {
    historyState
} from '@app/store/historyStore.js';

import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';

export let eventIndex;
export let tcType = "start";
export let enabled = true;

let tc = "--:--:--:--";

try {
    if (!$eventGroupState[$projectState.selected].events[eventIndex][tcType] && $eventGroupState[$projectState.selected].events[eventIndex][tcType] !== 0) {
        tc = "--:--:--:--";
    } else {
        tc = tcLib.secToTc($eventGroupState[$projectState.selected].events[eventIndex][tcType] + $projectState.incode, $projectState.frameRate, $projectState.dropFrame);
    }    
} catch(err){
    tc = "--:--:--:--";
}

function setTc(e) {
    e.preventDefault();
    e.stopPropagation();

    let originalDuration = ($eventGroupState[$projectState.selected].events[eventIndex].end === false || $eventGroupState[$projectState.selected].events[eventIndex].start === false) ? 2 : ($eventGroupState[$projectState.selected].events[eventIndex].end - $eventGroupState[$projectState.selected].events[eventIndex].start);

    let originalId = $eventGroupState[$projectState.selected].events[eventIndex].id;

    $eventGroupState[$projectState.selected].events[eventIndex][tcType] = player.currentTime;
    tc = tcLib.secToTc(player.currentTime + $projectState.incode, $projectState.frameRate, $projectState.dropFrame);

    if (tcType === "start"){
        if (isNaN($eventGroupState[$projectState.selected].events[eventIndex].end)){
            $eventGroupState[$projectState.selected].events[eventIndex].end = player.currentTime + 2;
        } else if ($eventGroupState[$projectState.selected].events[eventIndex].end < $eventGroupState[$projectState.selected].events[eventIndex].start){
            $eventGroupState[$projectState.selected].events[eventIndex].end = $eventGroupState[$projectState.selected].events[eventIndex].start + originalDuration;
        }

        //If the minimum frame gap is set and there is an overlap with the previoud event in the eventgroup then adjust the end time of the previous event
        if ($editorState.minFrameGap > 0 && eventIndex > 0 && $eventGroupState[$projectState.selected].events[eventIndex].start <= $eventGroupState[$projectState.selected].events[eventIndex-1].end){
            $eventGroupState[$projectState.selected].events[eventIndex-1].end = $eventGroupState[$projectState.selected].events[eventIndex].start - ($editorState.minFrameGap /
            $projectState.frameRate);
        }
    } else if (tcType === "end"){
        if (isNaN($eventGroupState[$projectState.selected].events[eventIndex].start)){
            $eventGroupState[$projectState.selected].events[eventIndex].start = player.currentTime - 2;
        } else if ($eventGroupState[$projectState.selected].events[eventIndex].start > $eventGroupState[$projectState.selected].events[eventIndex].end){
            $eventGroupState[$projectState.selected].events[eventIndex].start = $eventGroupState[$projectState.selected].events[eventIndex].end - originalDuration;
        }

        //If the minimum frame gap is set and there is an overlap with the next event in the eventgroup then adjust the start time of the next event
        if ($editorState.minFrameGap > 0 && eventIndex < $eventGroupState[$projectState.selected].events.length-1 && $eventGroupState[$projectState.selected].events[eventIndex].end >= $eventGroupState[$projectState.selected].events[eventIndex+1].start){
            $eventGroupState[$projectState.selected].events[eventIndex+1].start = $eventGroupState[$projectState.selected].events[eventIndex].end + ($editorState.minFrameGap / $projectState.frameRate);
        }
    }

    e.target.blur();
    $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);
    
    //Select the event that was just updated
    let newIndex = $eventGroupState[$projectState.selected].events.findIndex(event => event.id === originalId);
    $eventGroupState[$projectState.selected].selected = [newIndex];

    historyState.insert({
        name: "update timecode", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}

function validateTc(e) {
    if (e.target.value === "--:--:--:--") {
        return;
    }

    tc = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
    $eventGroupState[$projectState.selected].events[eventIndex][tcType] = tcLib.tcToSec(tc, $projectState.frameRate, $projectState.dropFrame)-$projectState.incode;
    
    e.target.blur();
    
    /* Save to undo/redo history */
    historyState.insert({
        name: "update timecode", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}

function updateCurrentTime(e) {
    if ($lockState.video && player) {
        player.currentTime = $eventGroupState[$projectState.selected].events[eventIndex][tcType];
    }
}

function frameByFrame(e) {
    if (e.key === "ArrowUp") {
        $eventGroupState[$projectState.selected].events[eventIndex][tcType] += parseFloat((1 / $projectState.frameRate).toFixed(4));
        tc = tcLib.secToTc($eventGroupState[$projectState.selected].events[eventIndex][tcType]+$projectState.incode, $projectState.frameRate, $projectState.dropFrame);

        if ($lockState.video && player) {
            player.currentTime = $eventGroupState[$projectState.selected].events[eventIndex][tcType];
        }

    } else if (e.key === "ArrowDown") {
        $eventGroupState[$projectState.selected].events[eventIndex][tcType] -= parseFloat((1 / $projectState.frameRate).toFixed(4));
        tc = tcLib.secToTc($eventGroupState[$projectState.selected].events[eventIndex][tcType]+$projectState.incode, $projectState.frameRate, $projectState.dropFrame);

        if ($lockState.video && player) {
            player.currentTime = $eventGroupState[$projectState.selected].events[eventIndex][tcType];
        }
    }
}

function updateTc(sec) {    
    if (!sec && sec !== 0) {
        tc = "--:--:--:--";
        return;
    }

    tc = tcLib.secToTc(parseFloat(sec).toFixed(4)+$projectState.incode, $projectState.frameRate, $projectState.dropFrame);
}

$: if ($projectState.frameRate || $projectState.incode) {
    updateTc($eventGroupState[$projectState.selected].events[eventIndex][tcType]);
}
</script>

<button
  id="tc-set-button-{tcType}"
  aria-label="Set timecode for {tcType}"
  disabled="{!enabled}" class="btn btn-secondary"  type="button" title="Insert TC {tcType}" on:click="{setTc}"> <i class="bi bi-clock" /></button>
<input
  id="tc-input-{tcType}"
  aria-label="Timecode input for {tcType}"
  disabled="{!enabled}" class="form-control border" type="text" on:click|stopPropagation="{updateCurrentTime}" on:keydown={frameByFrame} placeholder="{tcType}" bind:value={tc} on:blur={validateTc} on:keyup={e=>e.key==='Enter' && validateTc(e)} on:focus="{(e)=>{e.target.select()}}" title="{tc}"/>

<style>
    button, input {
        font-size: 0.9vw !important;
        padding: 3px 6px !important;
    }
</style>