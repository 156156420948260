import { writable } from 'svelte/store';
function loadCliConfigFromLocalStorage() {
    let cliConfig = JSON.parse(localStorage.getItem("cc-cli-configs")) || [
        {
            name : "Drastic Technologies",
            modalName : "drasticTech",
            options : [
                {
                    name : "Enable",
                    type : "list-input",
                    values : ["true", "false"],
                    selected : "false"
                },
                {
                    name : "CC Embed",
                    type : "file-input",
                    values : [],
                    selected : ""
                },
                {
                    name : "CC Extract",
                    type : "file-input",
                    values : [],
                    selected : ""
                }
            ]
        }
    ];
    
    return cliConfig;
}

export const cliConfigState = writable(loadCliConfigFromLocalStorage());

