<script>
import {
    fade
} from 'svelte/transition';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {authState} from '@app/store/authStore.js';
import Event from '@app/external/cc-lib/dist/classes/event.js';
import EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import supportedLanguages from '@app/external/cc-lib/dist/dict/supportedLanguages.js';
import voiceLib from "@app/external/cc-lib/dist/providers/textToSpeechAll/voices.js";

let eventGroupDefaults = JSON.parse(localStorage.getItem("cc-event-group-defaults")) || {};
let eventGroupType = eventGroupDefaults.eventGroupType || "subtitle",
    selectedGroups = [],
    displayName = "",
    orderBy = "start",
    //ripple = false,
    language = eventGroupDefaults.language || "en-US",
    linkedGroupIndex = "none",
    rtl = eventGroupDefaults.rtl,
    gender = "male",
    selectedLanguage = "English - US",
    virtualVoice = "en-US-Wavenet-A",
    languages = supportedLanguages.languages;

function mergeEventGroups() {
    let mergedEvents = [];
    let virtualVoiceOptions = {
        virtualVoice: virtualVoice,
        gender: gender,
        selectedLanguage: selectedLanguage
    }

    $eventGroupState.filter(group => {
        return selectedGroups.indexOf(group.id) > -1;
    }).forEach(group => {
        mergedEvents = [...mergedEvents, ...group.events]
    });

    if (orderBy === "start") {
        mergedEvents.sort((eventA, eventB) => {
            return eventA.start - eventB.start
        });
    }

    mergedEvents = JSON.parse(JSON.stringify(mergedEvents));
    mergedEvents.forEach((event, i, events) => {
        events[i] = new Event(event);
    });

    let eventGroupOptions = {
        type: eventGroupType,
        name: displayName,
        language: language,
        linkedGroupIndex: linkedGroupIndex,
        rtl: rtl,
        events: mergedEvents
    }

    $eventGroupState = [
        ...$eventGroupState, new EventGroup({
            ...eventGroupOptions,
            ...virtualVoiceOptions
        })
    ]

    $projectState.selected = $eventGroupState.length - 1;
    $eventGroupState[$projectState.selected].selected = [0];
    
    historyState.insert({
        name: "insert event", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    modalState.hideModal();
}

function updateVirtualVoice(){
    virtualVoice = voiceLib[selectedLanguage][gender][0];
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'mergeEventGroup' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="MergeEventGroupModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Merge Event Group <i class="bi bi-info-circle" title="Create a new event group by merging two or more groups together of the same type."></i></h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{mergeEventGroups}">
                    <!-- Name -->
                    <div class="mb-3"><label class="form-label" for="display name"><span class="badge bg-primary">New</span> Display Name</label>
                        <input class="form-control" required placeholder="Display Name" type="text" bind:value={displayName}>
                    </div>
                    <!-- Language -->
                    <div class="mb-3">
                        <label class="form-label" for="Language">Language</label>
                        <select class="form-select" bind:value={language}>
                            {#each languages as languageOption, index(index)}
                            <option>{languageOption}</option>
                            {/each}
                        </select>
                    </div>
                    <!-- RTL -->
                    <div class="form-check form-switch mb-2">
                        <input type="checkbox" class="form-check-input" id="rtlSwitch">
                        <label class="form-check-label" for="rtlSwitch">Right-To-Left</label>
                    </div>
                    <!-- Group Type -->
                    <div class="mb-3">
                        <label class="form-label" for="group type">Group Type</label>
                        <select class="form-select" bind:value={eventGroupType}>
                            <option value="subtitle">Subtitle</option>
                            {#if $authState.ad}
                                <option value="audio description">Audio Description</option>
                            {/if}
                            <option value="transcription">Transcription</option>
                            <option value="translation">Translation</option>
                        </select>
                    </div>
                    <!-- Event Groups -->
                    <div class="mb-3">
                        <label class="form-label" for="exampleFormControlSelect2">Event Groups <span class="text-danger small">Required</span></label>
                        <select multiple class="form-select" bind:value={selectedGroups}>
                            {#each $eventGroupState as eventGroup}
                                <option value="{eventGroup.id}">{eventGroup.name}</option>
                            {/each}
                        </select>
                    </div>
                    {#if selectedGroups.length < 2}
                    <p transition:fade="{{duration: 50 }}" class="small text-warning">You must select at least two event groups to merge</p>
                    {/if}
                    <!-- Order By -->
                    <div class="mb-3">
                        <label class="form-label" for="group type">Order By</label>
                        <select class="form-select" bind:value={orderBy}>
                            <option value="id">Id</option>
                            <option value="start">Start Time</option>
                        </select>
                    </div>
                    <!-- Linked Group -->
                    {#if eventGroupType === 'translation'}
                    <div transition:fade="{{duration: 100 }}">
                        <hr/>
                        <p class="lead text-warning m-0">Translation Options</p>
                        <div class="mb-3">
                            <label class="form-label" for="Linked group">Linked Group <i class="bi bi-info-circle" title="The linked group is used to populate the source events for translation."></i></label>
                            <select class="form-select"  bind:value={linkedGroupIndex}>
                                {#each $eventGroupState as eventGroup, index(index)}
                                <option value={index}>{eventGroup.name}</option>
                                {/each}
                                <option value="none">None</option>
                            </select>
                        </div>
                    </div>
                    {/if}
                </form>
            </div>
            <div class="modal-footer"><button class="btn btn-primary" type="button" disabled="{selectedGroups.length<2}" on:click={mergeEventGroups}>Merge Group</button></div>
        </div>
    </div>
</div>
