import { writable } from 'svelte/store';
export const markerState = writable(
    {
        selected : 0,
        lists : [
            {
                id : '0',
                name : "Shot Changes",
                color : "#E74C3C",
                markers : []
            }
        ]
    }
);