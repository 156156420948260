<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';

function removeEvents() {
    $eventGroupState[$projectState.selected].selected.sort((a, b) => {
        return b - a;
    });

    let lastEvent = $eventGroupState[$projectState.selected].selected[$eventGroupState[$projectState.selected].selected.length-1]-1;

    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected], eventIndex);
    });

    
    $eventGroupState[$projectState.selected].selected = lastEvent >= 0 ? [lastEvent] : [];

    historyState.insert({
        name: "remove event(s)", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}
</script>
<li class="nav-item" class:disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}" title="Remove Selected Events">
    <a class="nav-link" href="#!/" on:click={removeEvents}><i class="bi bi-trash"></i></a>
</li>
