import _eol from "eol";
import _rtfLookup from "../dict/rtf.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const rtfLookup = _rtfLookup;
const eol = _eol;
function replaceEscapedCodes(text) {
  Object.entries(rtfLookup).forEach(code => {
    var re = new RegExp(code[0], "g");
    text = text.replace(re, code[1]);
  });
  return text;
}
function decodeRtfText(text) {
  text = replaceEscapedCodes(text);
  text = text.replace(/\\par/gmi, "");
  text = text.replace(/\\i /gmi, "<i>");
  text = text.replace(/\\i0/gmi, "</i>");
  text = text.replace(/\n<\/i>/gmi, "</i>\n");
  return convertToHtml(text);
}
export default {
  decode: function (input, options) {
    let events = [],
      eventId,
      tcInfo,
      subtitleBlocks = [],
      subtitleBlock = "";
    eol.split(input).forEach(fileLine => {
      let matches = fileLine.match(/\d+  \d\d:\d\d:\d\d:\d\d \d\d:\d\d:\d\d:\d\d \d\d:\d\d/);
      if (matches) {
        if (subtitleBlock) {
          subtitleBlocks.push(subtitleBlock);
        }
        subtitleBlock = matches.join("");
      } else if (subtitleBlock) {
        subtitleBlock += "\n" + fileLine;
      }
    });
    subtitleBlocks.push(subtitleBlock);
    //console.log(subtitleBlocks);

    subtitleBlocks.forEach(block => {
      let ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.10
      });
      let blockInfo = eol.split(block);
      let tcInfo = blockInfo.shift();
      let tcData = tcInfo.match(/\d\d:\d\d:\d\d:\d\d/g);
      blockInfo = blockInfo.filter(part => {
        return part !== `}` && part !== `\x00` && part !== `\\par`;
      });

      // console.log(blockInfo);
      if (tcData.length === 2) {
        ccEvent.start = tcLib.parseTcToSec(tcData[0], options.frameRate);
        ccEvent.end = tcLib.parseTcToSec(tcData[1], options.frameRate);
      } else {
        return;
      }
      ccEvent.text = decodeRtfText(blockInfo.join("\n"));
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("RTF files are not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};