import _quillClasses from "../dict/quillClasses.js";
import _flexbox from "../dict/flexbox.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _dlpFunc from "../functions/profiles/dlpCinema.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import { decode as _decodeHtml } from "html-entities";
import { v4 as _uuidv } from "uuid";
import _eol from "eol";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const eol = _eol;
const uuidv4 = _uuidv;
const decodeHtml = _decodeHtml; //Encode
const XMLParser = _XMLParser; //Decode
const dlpFunc = _dlpFunc;
const convertToHtml = _convertToHtml;
const flexbox = _flexbox;
const quillClasses = _quillClasses;
export default {
  decode: function (input, options) {
    let events = [],
      subtitleList;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.Text"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    input = input.replace(/(?<=<|<\/)dcst:/g, "");
    let fileJson = xmlToJson.parse(input);
    if (fileJson.SubtitleReel.SubtitleList.Font.Subtitle) {
      subtitleList = fileJson.SubtitleReel.SubtitleList.Font.Subtitle;
    } else if (fileJson.SubtitleReel.SubtitleList.Subtitle) {
      subtitleList = fileJson.SubtitleReel.SubtitleList.Subtitle;
    }
    subtitleList.forEach(event => {
      //console.log(event);
      let ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.10
      });
      ccEvent.start = tcLib.tcToSec(event["@_TimeIn"], options.frameRate);
      ccEvent.end = tcLib.tcToSec(event["@_TimeOut"], options.frameRate);
      if (event["Text"] && Array.isArray(event["Text"])) {
        event["Text"].forEach(textLine => {
          ccEvent.text += decodeHtml(textLine["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + "\n";
          ccEvent.text = ccEvent.text.replace(/ +/gm, " ").replace(/^ /gm, "");
          //console.log(ccEvent.text);
        });
        ccEvent.alignment = event["Text"][0]["@_HAlign"] ? event["Text"][0]["@_HAlign"] : "center";
        ccEvent.xPos = event["Text"][0]["@_HAlign"] ? flexbox.alignmentMap[event["Text"][0]["@_HAlign"]] : "center";
        ccEvent.yPos = event["Text"][0]["@_VAlign"] ? flexbox.alignmentMap[event["Text"][0]["@_VAlign"]] : "end";
      } else if (event["Text"]) {
        ccEvent.text = decodeHtml(event["Text"]["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>");
        ccEvent.alignment = event["Text"]["@_HAlign"] ? event["Text"]["@_HAlign"] : "center";
        ccEvent.xPos = event["Text"]["@_HAlign"] ? flexbox.alignmentMap[event["Text"]["@_HAlign"]] : "center";
        ccEvent.yPos = event["Text"]["@_VAlign"] ? flexbox.alignmentMap[event["Text"]["@_VAlign"]] : "end";
      } else if (event["Font"]) {
        let prefix = "",
          suffix = "";
        if (event["Font"]['@_Bold'] && event["Font"]['@_Bold'] === 'yes') {
          prefix = "<b>";
          suffix = "</b>";
        }
        if (event["Font"]['@_Italic'] && event["Font"]['@_Italic'] === 'yes') {
          prefix += "<i>";
          suffix = "</i>" + suffix;
        }
        if (event["Font"]['@_Underline'] && event["Font"]['@_Underline'] === 'yes') {
          prefix += "<u>";
          suffix = "</u>" + suffix;
        }
        if (Array.isArray(event["Font"]["Text"])) {
          event["Font"]["Text"].forEach(textLine => {
            ccEvent.text += prefix + decodeHtml(textLine["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + suffix + "\n";
          });
          ccEvent.alignment = event["Font"]["Text"][0]["@_HAlign"] ? event["Font"]["Text"][0]["@_HAlign"] : "center";
          ccEvent.xPos = event["Font"]["Text"][0]["@_HAlign"] ? flexbox.alignmentMap[event["Font"]["Text"][0]["@_HAlign"]] : "center";
          ccEvent.yPos = event["Font"]["Text"][0]["@_VAlign"] ? flexbox.alignmentMap[event["Font"]["Text"][0]["@_VAlign"]] : "end";
        } else if (event["Font"]["Text"]) {
          ccEvent.text = prefix + decodeHtml(event["Font"]["Text"]["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + suffix;
          ccEvent.alignment = event["Font"]["Text"]["@_HAlign"] ? event["Font"]["Text"]["@_HAlign"] : "center";
          ccEvent.xPos = event["Font"]["Text"]["@_HAlign"] ? flexbox.alignmentMap[event["Font"]["Text"]["@_HAlign"]] : "center";
          ccEvent.yPos = event["Font"]["Text"]["@_VAlign"] ? flexbox.alignmentMap[event["Font"]["Text"]["@_VAlign"]] : "end";
        }
      }
      ccEvent.text = convertToHtml(ccEvent.text, [quillClasses.align[ccEvent.alignment]]);
      if (ccEvent.yPos === "start") {
        ccEvent.yOffset = options.window.height * 0.10;
      }
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Closed Caption Converter does not support writing DCDM Subtitle Files. Please contact us to have DCDM support added.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};