import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _eol from "eol";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const eol = _eol;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const ttmlFunc = _ttmlFunc;
const convertToPlainText = _convertToPlainText;
export default {
  decode: function (input, options) {
    throw new Error("The profile [HBO Max SMPTE] is not supported for decoding by Closed Caption Converter. Please use Timed-Text Markup Language (ttml)");
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let fontSize = encodingOptions["Font Size"] || 80;
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xml:lang="${encodingOptions["Language Code"] || "en"}"
        xmlns="http://www.w3.org/ns/ttml"
        xmlns:tts="http://www.w3.org/ns/ttml#styling"
        xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        xmlns:ttp="http://www.w3.org/ns/ttml#parameter"
        ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${frameRateMultiplier}">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>`);
    output += eol.after(`<ttm:desc>Created by Closed Caption Converter | www.closedcaptionconverter.com</ttm:desc>`);
    output += eol.after(`<smpte:information
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        mode="Preserved" m608:channel="CC1" m608:programName="SFT" m608:captionService="F1C1CC"
       />`);
    output += eol.after(`</metadata>`);
    output += eol.after(`<styling>`);
    /* Basic Style */
    output += eol.after(`<style xml:id='basic' tts:color='${encodingOptions["Font Color"] || "white"}' tts:backgroundColor='${encodingOptions["Background Color"] || "black"}' tts:fontFamily='${encodingOptions["Font Family"] || "monospace"}' tts:fontSize='${fontSize}%'/>`);
    output += eol.after(`</styling>`);
    output += eol.after(`<layout>`);
    output += eol.after(`<region xml:id='pop1' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='pop2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='pop3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='pop4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='paint' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='paint2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='paint3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='paint4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='rollup2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='rollup3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`<region xml:id='rollup4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>`);
    output += eol.after(`</layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div>`);
    /* EVENTS GO HERE */
    eventGroup.events.forEach(event => {
      let plainTextCustom = convertToPlainTextCustom(event.text);
      let startTime = encodingOptions["Timecode Format"] === "ms" ? tcLib.secToTcMs(event.start) : tcLib.secToTcClock(event.start, options.frameRate);
      let endTime = encodingOptions["Timecode Format"] === "ms" ? tcLib.secToTcMs(event.end) : tcLib.secToTcClock(event.end, options.frameRate);
      eol.split(plainTextCustom).forEach((textLine, index, textLines) => {
        if (!convertToPlainText(textLine)) {
          return;
        }
        let region = "pop" + (index + 1),
          style = "basic";
        let extents = ttmlFunc.singleLine.calcExtents(textLine, fontSize);
        let origins = ttmlFunc.singleLine.calcOrigin(event, textLine, index, textLines.length, fontSize, options.window);
        output += eol.after(`<p region='${region}' style='${style}' begin='${startTime}' end='${endTime}' tts:origin='${origins}' tts:extent='${extents}'>${ttmlFunc.singleLine.convertToTtml(textLine)}</p>`);
      });
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += eol.after(`</tt>`);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};