<script>
    /* States */
    import {
        explorerState
    } from '@app/store/explorerStore.js';
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { authState } from "@app/store/authStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { issueState } from "@app/store/issueStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { Circle } from "svelte-loading-spinners";
    import { toast } from "@zerodevx/svelte-toast";
    import { modalState } from "@app/store/modalStore.js";
    import Swal from "sweetalert2";
    import refreshId from "@app/external/cc-lib/dist/functions/utility/refreshId.js";
    import { ccprjFunc } from '@app/lib/ccprj.js';
    
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    import "firebase/functions";

    const iconMap = {
        "folder" : "bi-folder-fill",
        "project" : "bi-file-earmark-play",
        "team project" : "bi-people",
        "work order" : "bi-clipboard-check"
    }

    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    let storageRef = storage.ref();
    let homeStorageBasePath = `users/${userId}/projects/`;
    let teamStorageBasePath = "teams/" + teamId + "/storage/";
    let loading = false;
    let currentDragTarget = null;

    // Separate folders from other records for the thumbnail view
    $: folders = $explorerState.records.filter(record => record.type === "folder");
    $: otherRecords = $explorerState.records.filter(record => record.type !== "folder");

    /* Get records where folder Id is the records locationId */
    async function getRecords(folderId = null){
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];

            // console.log("Getting Records for Folder ID: " + folderId);
            
            // Handle special rootDir values
            if ($explorerState.rootDir === "recent") {
                // Fetch recent records from both personal and team storage
                let recentRecords = [];
                
                // Personal storage
                let personalSnapshot = await homeDbRef
                    .orderBy("updatedOn", "desc")
                    .limit(100)
                    .get();
                    
                personalSnapshot.forEach((doc) => {
                    recentRecords.push({...(doc.data()), rootDir: "personal"});
                });
                
                // Team storage (if available)
                if (teamDbRef) {
                    let teamSnapshot = await teamDbRef
                        .orderBy("updatedOn", "desc")
                        .limit(100)
                        .get();
                        
                    teamSnapshot.forEach((doc) => {
                        recentRecords.push({...(doc.data()), rootDir: "team"});
                    });
                }
                
                // First, separate folders from other records
                const folders = recentRecords.filter(record => record.type === "folder");
                const nonFolders = recentRecords.filter(record => record.type !== "folder");
                
                // Sort each group by updatedOn timestamp
                folders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                nonFolders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                // Combine: folders first, then other records
                $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
                
            } else if ($explorerState.rootDir === "starred") {
                // Fetch starred records from both personal and team storage
                let starredRecords = [];
                
                // Personal storage
                let personalSnapshot = await homeDbRef
                    .where("star", "==", true)
                    .limit(100)
                    .get();
                    
                personalSnapshot.forEach((doc) => {
                    starredRecords.push({...(doc.data()), rootDir: "personal"});
                });
                
                // Team storage (if available)
                if (teamDbRef) {
                    let teamSnapshot = await teamDbRef
                        .where("star", "==", true)
                        .limit(100)
                        .get();
                        
                    teamSnapshot.forEach((doc) => {
                        starredRecords.push({...(doc.data()), rootDir: "team"});
                    });
                }
                
                // First, separate folders from other records
                const folders = starredRecords.filter(record => record.type === "folder");
                const nonFolders = starredRecords.filter(record => record.type !== "folder");
                
                // Sort each group by updatedOn timestamp
                folders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                nonFolders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                // Combine: folders first, then other records
                $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
                
            } else {
                // Normal folder query for personal or team storage
                let querySnapshot = await ($explorerState.rootDir === "personal" ? homeDbRef : teamDbRef).where("locationId", "==", folderId).orderBy("type").orderBy("name", "asc").get();

                querySnapshot.forEach((doc) => {
                    $explorerState.records = [...$explorerState.records, {...(doc.data()), rootDir: $explorerState.rootDir}];
                });
            }
        } catch(err){
            // console.error(err);
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }   
    }

    async function openRecord(id) {
        // console.log("Opening record with id: ", id);
        let record = $explorerState.records.find(record => record.id === id);

        // Handle folder navigation
        if (record.type === "folder") {
            // console.log(`Opening folder: ${record.name}, current rootDir: ${$explorerState.rootDir}`);
            
            // Check if we're in recent or starred view
            if ($explorerState.rootDir === "recent" || $explorerState.rootDir === "starred") {
                // console.log(`Switching from ${$explorerState.rootDir} view to ${record.rootDir} view`);
                
                // Switch to the appropriate rootDir based on the folder's rootDir
                $explorerState.rootDir = record.rootDir;
                
                // Build the navigation path by finding all parent folders
                await buildFolderNavigationPath(record);
            } else {
                // Regular folder navigation in normal view
                $explorerState.navHistory = [...$explorerState.navHistory, record];
            }
            
            // Navigate to the folder contents
            getRecords(record.id);
        } 
        // Handle projects differently based on context
        else if (record.type === "project" || record.type === "team project" || record.type === "work order") {
            // If in save mode, overwrite the project instead of opening it
            if ($explorerState.context === "save") {
                overwriteProject(record);
            } else {
                // In load mode, open the project as before
                loadProject(record);
            }
        } 
    }
    
    // Build navigation path for a folder by finding all parent folders
    async function buildFolderNavigationPath(folder) {
        try {
            loading = true;
            
            // Clear current navigation history
            $explorerState.navHistory = [];
            
            // If no folder, we're at root
            if (!folder) {
                return;
            }
            
            // Select the appropriate database reference
            const dbRef = folder.rootDir === "personal" ? homeDbRef : teamDbRef;
            if (!dbRef) {
                throw new Error(`Invalid database reference for rootDir: ${folder.rootDir}`);
            }
            
            // First add the current folder to the path
            let folderPath = [];
            
            // If folder has no locationId, it's at root level already
            if (!folder.locationId) {
                $explorerState.navHistory = [folder];
                return;
            }
            
            // Start with the current folder's locationId to find its parent
            let currentFolderId = folder.locationId;
            let pathComplete = false;
            let safetyCounter = 0; // Prevent infinite loops
            const MAX_DEPTH = 20; // Maximum folder nesting depth
            
            // Keep traversing up until we reach the root or hit safety limit
            while (currentFolderId && !pathComplete && safetyCounter < MAX_DEPTH) {
                safetyCounter++;
                
                try {
                    // Get the parent folder
                    const parentDoc = await dbRef.doc(currentFolderId).get();
                    
                    if (parentDoc.exists) {
                        const parentData = parentDoc.data();
                        const parentFolder = { ...parentData, rootDir: folder.rootDir };
                        
                        // Add to path (at the beginning)
                        folderPath.unshift(parentFolder);
                        
                        // Continue with parent's parent if it exists
                        if (parentFolder.locationId) {
                            currentFolderId = parentFolder.locationId;
                        } else {
                            pathComplete = true;
                        }
                    } else {
                        pathComplete = true;
                    }
                } catch (err) {
                    // console.error(`Error fetching parent folder: ${err.message}`);
                    pathComplete = true;
                }
            }
            
            // Add the original folder at the end
            folderPath.push(folder);
            
            // Update navigation history with the complete path
            $explorerState.navHistory = folderPath;
            
        } catch (error) {
            // console.error("Error building folder navigation path:", error);
            toast.push(`Failed to build folder path: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    async function overwriteProject(record) {
        if (loading) return;
        
        try {
            loading = true;
            
            // Check if record is locked by someone else
            if (record.locked && record.lockedBy !== userEmail) {
                throw new Error(`Cannot overwrite "${record.name}" as it's locked by ${record.lockedBy}`);
            }
            
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Overwrite Project",
                text: `Are you sure you want to overwrite "${record.name}"?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Overwrite",
                denyButtonText: "Cancel",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-danger me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) {
                throw new Error("Overwriting project was cancelled");
            }
            
            // Update the project to use the same ID as the selected record
            $projectState = refreshId($projectState, record.id);
            
            // Determine file path based on storage location
            const projectFilePath = record.rootDir === "team"
                ? teamStorageBasePath + record.id
                : homeStorageBasePath + record.id;
                
            // Create project JSON
            const projectJson = ccprjFunc.createCcprj({
                project: JSON.parse(JSON.stringify($projectState)),
                metadata: JSON.parse(JSON.stringify($metadataState)),
                speakers: JSON.parse(JSON.stringify($speakerState)),
                issues: JSON.parse(JSON.stringify($issueState)),
                markers: JSON.parse(JSON.stringify($markerState)),
                eventGroups: JSON.parse(JSON.stringify($eventGroupState)),
                style: JSON.parse(JSON.stringify($styleState))
            });
            
            // Upload to storage
            const uploadProjectRef = storage.ref().child(projectFilePath);
            await uploadProjectRef.putString(JSON.stringify(projectJson));
            
            // Update project record in database
            const projectInfo = {
                id: record.id,
                name: $projectState.name,
                type: $projectState.type === "team" ? "team project" : "project",
                owner: userEmail,
                createdOn: record.createdOn,
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                locationId: record.locationId,
                shared: record.rootDir === "team" ? true : false,
                star: record.star || false,
                rootDir: record.rootDir
            };
            
            const dbRef = record.rootDir === "team" ? teamDbRef : homeDbRef;
            await dbRef.doc(projectInfo.id).set(projectInfo);
            
            // Update project state with location info
            $projectState.folderId = record.locationId;
            $projectState.rootDir = record.rootDir;
            
            // Reset history and show success message
            historyState.reset();
            toast.push(`Project "${$projectState.name}" overwritten successfully`, {
                classes: ["toast-success"],
            });
            
            // Update the record in the records list
            $explorerState.records = $explorerState.records.map(r => 
                r.id === record.id ? projectInfo : r
            );
            
            modalState.hideModal();
        } catch (error) {
            // console.error("Error overwriting project:", error);
            toast.push(`Failed to overwrite project: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    async function loadProject(record) {
        try {
            loading = true;
            
            // Check if project is locked by someone else
            if (record.locked && record.lockedBy !== userEmail) {
                // Show notification about locked project
                await Swal.fire({
                    title: 'Locked Project',
                    text: `This project is locked by ${record.lockedBy}. You can view it, but to make changes you'll need to create a new copy.`,
                    icon: 'info',
                    confirmButtonText: 'Continue in Read-Only Mode',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-lg btn-primary"
                    }
                });
            }
            
            // Determine the storage path based on whether it's personal or team
            const projectFilePath = record.rootDir === "team" 
                ? `${teamStorageBasePath}${record.id}` 
                : `${homeStorageBasePath}${record.id}`;
            
            // Get the URL for the file
            let url;
            if (record.rootDir === "team") {
                const result = await firebase.functions().httpsCallable("v8GetDownloadLink")(projectFilePath);
                url = result.data;
            } else {
                url = await storageRef.child(projectFilePath).getDownloadURL();
            }
            
            // Fetch the file content
            const response = await fetch(url);
            const fileData = await response.json();
            
            // Process based on record type
            if (record.type === "work order") {
                openWorkOrder(fileData, record);
            } else if (record.type === "project") {
                openProjectFile(fileData, record);
            } else if (record.type === "team project") {
                openTeamProjectFile(fileData, record);
            }
            
        } catch (error) {
            // console.error(`Error opening ${record.type}:`, error);
            toast.push(`Failed to open ${record.type}: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    function openProjectFile(description, record){
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Project File",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call projectFileImport modal
        modalState.showModal("loadProjectFile");
    }

    async function openTeamProjectFile(description, record) {
        try {
            loading = true;
            
            // Extract team ID and project ID
            const projectTeamId = description.teamId;
            
            if (!projectTeamId || !teamId) {
                throw new Error("Missing team or project ID in file");
            }
            
            // Check for project snapshots first (to ensure there's data to share)
            const snapshotsRef = db.collection("teams").doc(teamId)
                .collection("projects").doc(projectTeamId)
                .collection("snapshots");
                
            const snapshotsQuery = await snapshotsRef.limit(1).get();
            
            if (snapshotsQuery.empty) {
                fallbackToProjectFileImport(description, record);
                return;
            }
            
            // Look for share codes that reference this project
            const sharesRef = db.collection("teams").doc(teamId).collection("shares");
                
            const sharesQuery = await sharesRef
                .where("projectTeamId", "==", projectTeamId)
                .limit(1)
                .get();
            
            if (sharesQuery.empty) {
                fallbackToProjectFileImport(description, record);
                return;
            }
            
            // Get the first share code
            const shareDoc = sharesQuery.docs[0];
            const shareId = shareDoc.id;
            
            // Update URL with share code and open teamProjectImport modal
            const url = new URL(window.location.href);
            url.searchParams.set("id", shareId);
            window.history.pushState({}, '', url);
            
            toast.push("Opening team project via share code...", {
                classes: ["toast-info"],
            });
            
            modalState.showModal("teamProjectImport");
            
        } catch (error) {
            // console.error("Error processing team project:", error);
            fallbackToProjectFileImport(description, record);
        } finally {
            loading = false;
        }
    }
    
    function fallbackToProjectFileImport(description, record) {
        toast.push("Using project file import method", {
            classes: ["toast-info"],
        });
        
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Team Project File",
            status: "importing",
            rawDescription: JSON.stringify(description)
        };
        
        modalState.showModal("teamProjectFileImport");
    }

    function openWorkOrder(description, record) {
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Work Order",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call workOrderImport modal
        modalState.showModal("workOrderImport");
    }

    function selectRecord(clickEvent, id) {
        if (clickEvent.ctrlKey || clickEvent.metaKey) {
            if ($explorerState.selectedRecords.includes(id)) {
                $explorerState.selectedRecords = $explorerState.selectedRecords.filter(recordId => recordId !== id);
            } else {
                $explorerState.selectedRecords = [...$explorerState.selectedRecords, id];
            }
        } else if (clickEvent.shiftKey) {
            const lastSelectedRecord = $explorerState.selectedRecords[$explorerState.selectedRecords.length - 1];
            const lastSelectedRecordIndex = $explorerState.records.findIndex(record => record.id === lastSelectedRecord);
            const currentRecordIndex = $explorerState.records.findIndex(record => record.id === id);
            const recordsToSelect = $explorerState.records.slice(Math.min(lastSelectedRecordIndex, currentRecordIndex), Math.max(lastSelectedRecordIndex, currentRecordIndex) + 1);
            $explorerState.selectedRecords = recordsToSelect.map(record => record.id);
        } else {
            $explorerState.selectedRecords = [id];
        }
    }

    // Drag and drop functions
    function handleDragStart(event, id) {
        // Don't allow drag in save mode
        if ($explorerState.context === "save") {
            event.preventDefault();
            return;
        }
        
        // If the current item isn't selected, select only this item
        if (!$explorerState.selectedRecords.includes(id)) {
            $explorerState.selectedRecords = [id];
        }
        
        // Set drag data
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/plain", JSON.stringify($explorerState.selectedRecords));
        
        // Set a custom class for styling
        const draggedItems = $explorerState.selectedRecords.length;
        event.dataTransfer.setData("application/count", draggedItems);
        event.target.classList.add("dragging");
    }
    
    function handleDragEnd(event) {
        event.target.classList.remove("dragging");
        currentDragTarget = null;
    }
    
    function handleDragOver(event, record) {
        // Only allow dropping on folders
        if (record.type !== "folder") {
            return;
        }
        
        // Get the dragged item IDs
        const dragDataString = event.dataTransfer.types.includes('text/plain') ? 
            event.dataTransfer.getData('text/plain') : null;
            
        // If we're dragging the folder onto itself, don't allow drop
        if (dragDataString) {
            try {
                const draggedIds = JSON.parse(dragDataString);
                // Prevent dropping onto self
                if (draggedIds.includes(record.id)) {
                    event.dataTransfer.dropEffect = "none";
                    return;
                }
            } catch (e) {
                // If parsing fails, continue with normal behavior
            }
        }
        
        // Prevent default to allow drop
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        
        // Highlight the drop target
        if (currentDragTarget && currentDragTarget !== record.id) {
            const prevElement = document.querySelector(`[data-record-id="${currentDragTarget}"]`);
            if (prevElement) prevElement.classList.remove("drop-target");
        }
        
        event.currentTarget.classList.add("drop-target");
        currentDragTarget = record.id;
    }
    
    function handleDragLeave(event) {
        event.currentTarget.classList.remove("drop-target");
    }
    
    async function handleDrop(event, targetRecord) {
        event.preventDefault();
        event.currentTarget.classList.remove("drop-target");
        
        // Only allow dropping on folders
        if (targetRecord.type !== "folder") {
            return;
        }
        
        try {
            // Get the dragged record IDs
            const recordIds = JSON.parse(event.dataTransfer.getData("text/plain"));
            
            // Don't move if nothing was dragged or trying to move to same folder
            if (!recordIds.length) return;
            
            // Check if trying to drop a folder onto itself
            if (recordIds.includes(targetRecord.id)) {
                toast.push("Cannot move a folder into itself", {
                    classes: ["toast-warning"],
                });
                return;
            }
            
            const recordsToMove = $explorerState.records.filter(r => recordIds.includes(r.id));
            
            // Check if any of the records are already in the target folder
            const alreadyInFolder = recordsToMove.some(r => r.locationId === targetRecord.id);
            if (alreadyInFolder) {
                toast.push("Some items are already in this folder", {
                    classes: ["toast-warning"],
                });
                return;
            }
            
            // Confirm move with the user
            await moveRecords(recordsToMove, targetRecord);
            
        } catch (error) {
            // console.error("Error handling drop:", error);
            toast.push(`Drop failed: ${error.message}`, {
                classes: ["toast-danger"],
            });
        }
    }
    
    async function moveRecords(records, targetFolder) {
        if (loading) return;
        
        try {
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Move Items",
                text: `Move ${records.length} item${records.length > 1 ? 's' : ''} to "${targetFolder.name}"?`,
                showDenyButton: true,
                confirmButtonText: "Move",
                denyButtonText: "Cancel",
                icon: "question",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-primary me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) return;
            
            loading = true;
            
            // Check if any records are locked
            const lockedRecords = records.filter(r => r.locked && r.lockedBy !== userEmail);
            if (lockedRecords.length > 0) {
                throw new Error(`Cannot move locked item${lockedRecords.length > 1 ? 's' : ''}`);
            }
            
            // Perform the move operation for each record
            for (const record of records) {
                // Get the appropriate database reference based on the root directory
                const dbRef = record.rootDir === "team" ? teamDbRef : homeDbRef;
                
                // Update the locationId
                await dbRef.doc(record.id).update({
                    locationId: targetFolder.id,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
                
                // Update local record
                const index = $explorerState.records.findIndex(r => r.id === record.id);
                if (index !== -1) {
                    $explorerState.records[index].locationId = targetFolder.id;
                }
            }
            
            // Refresh the records list
            await getRecords($explorerState.navHistory[$explorerState.navHistory.length - 1]?.id || null);
            
            // Clear selected records
            $explorerState.selectedRecords = [];
            
            toast.push(`Moved ${records.length} item${records.length > 1 ? 's' : ''} to "${targetFolder.name}"`, {
                classes: ["toast-success"],
            });
            
        } catch (error) {
            // console.error("Error moving records:", error);
            toast.push(`Failed to move: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    // Format date helper function
    function formatDate(timestamp) {
        if (!timestamp) return "-";
        const date = timestamp.toDate();
        return date.toLocaleDateString();
    }
</script>

{#if loading}
<div class="loading-container d-flex justify-content-center align-items-center w-100 h-100">
    <Circle
    size="75"
    color="#1eb4b2"
    unit="px"
    duration="1s"
    />
</div>
{:else}
<div id="thumbnail-view" class="container-fluid p-3">
    <!-- Folders Section -->
    {#if folders.length > 0}
    <div class="folders-section mb-3">
        <h6 class="section-title mb-3">Folders</h6>
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-3">
            {#each folders as folder}
            <div class="col">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div 
                    class="card folder-card h-100 {$explorerState.selectedRecords.includes(folder.id) ? 'selected' : ''}"
                    on:dblclick={() => openRecord(folder.id)}
                    on:click={(e) => selectRecord(e, folder.id)}
                    draggable={$explorerState.context !== "save"}
                    on:dragstart={(e) => handleDragStart(e, folder.id)}
                    on:dragend={handleDragEnd}
                    on:dragover={(e) => handleDragOver(e, folder)}
                    on:dragleave={handleDragLeave}
                    on:drop={(e) => handleDrop(e, folder)}
                    data-record-id={folder.id}
                    data-record-type="folder">
                    <div class="card-body d-flex align-items-center">
                        <i class="bi bi-folder-fill me-2 fs-4 text-warning"></i>
                        <h6 class="card-title mb-0 text-truncate">{folder.name}</h6>
                    </div>
                </div>
            </div>
            {/each}
        </div>
    </div>
    
    <!-- Divider between sections -->
    {#if folders.length > 0 && otherRecords.length > 0}
        <hr class="my-4">
    {/if}
    {/if}
    
    <!-- Other Records Section -->
    {#if otherRecords.length > 0}
    <div class="records-section">
        <h6 class="section-title mb-3">Projects</h6>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3">
            {#each otherRecords as record}
            <!-- svelte-ignore a11y-no-static-element-interactions -->
             <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="col">
                <div 
                    class="card record-card h-100 {$explorerState.selectedRecords.includes(record.id) ? 'selected' : ''}"
                    on:dblclick={() => openRecord(record.id)}
                    on:click={(e) => selectRecord(e, record.id)}
                    draggable={$explorerState.context !== "save"}
                    on:dragstart={(e) => handleDragStart(e, record.id)}
                    on:dragend={handleDragEnd}
                    data-record-id={record.id}
                    data-record-type={record.type}>
                    <div class="card-header d-flex justify-content-between align-items-start">
                        <div class="d-flex title-container">
                            <i class="bi {iconMap[record.type]} me-2 flex-shrink-0"></i>
                            <span class="record-title fw-bold">{record.name}</span>
                        </div>
                        <div class="record-icons flex-shrink-0 ms-1">
                            {#if record.locked}<i class="bi bi-lock-fill ms-1 text-secondary"></i>{/if}
                            {#if record.star}<i class="bi bi-star-fill ms-1 text-warning"></i>{/if}
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="record-details">
                            {#if record.state}
                            <div class="detail-row">
                                <small class="text-muted">State:</small>
                                <small class="text-truncate">{record.state}</small>
                            </div>
                            {/if}
                            
                            {#if record.assignedTo}
                            <div class="detail-row">
                                <small class="text-muted">Assigned To:</small>
                                <small class="text-truncate">{record.assignedTo}</small>
                            </div>
                            {/if}
                            
                            {#if record.eventGroups}
                            <div class="detail-row">
                                <small class="text-muted">Event Groups:</small>
                                <small>{record.eventGroups}</small>
                            </div>
                            {/if}
                            
                            {#if record.totalEvents}
                            <div class="detail-row">
                                <small class="text-muted">Total Events:</small>
                                <small>{record.totalEvents}</small>
                            </div>
                            {/if}
                            
                            <div class="detail-row">
                                <small class="text-muted">Modified:</small>
                                <small>{formatDate(record.updatedOn)}</small>
                            </div>
                            
                            {#if record.owner}
                            <div class="detail-row">
                                <small class="text-muted">Owner:</small>
                                <small class="text-truncate">{record.owner}</small>
                            </div>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
            {/each}
        </div>
    </div>
    {/if}
    
    <!-- Empty state -->
    {#if $explorerState.records.length === 0}
    <div class="empty-state text-center py-5">
        <i class="bi bi-folder-x display-1 text-muted"></i>
        <p class="mt-3 text-muted">No items found</p>
    </div>
    {/if}
</div>
{/if}

<style>
    #thumbnail-view {
        height: 80vh;
        overflow-y: auto;
    }
    
    .loading-container {
        height: 80vh;
    }

    .folder-card {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    
    .record-card {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    
    .selected {
        background-color: rgba(13, 110, 253, 0.1);
        border-color: #0d6efd;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    
    .card.dragging {
        opacity: 0.5;
    }
    
    .card.drop-target {
        border-color: #0d6efd;
        background-color: rgba(13, 110, 253, 0.1);
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    
    .card-header {
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding: 0.75rem;
    }
    
    .detail-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }
    
    .empty-state {
        color: #6c757d;
    }
    
    .section-title {
        font-weight: 600;
        color: #495057;
    }
    
    .title-container {
        flex: 1;
        min-width: 0; /* Important for proper flex item wrapping */
        align-items: flex-start;
    }
    
    .record-title {
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
        line-height: 1.2;
    }
</style>