<script>
	import { modalState } from "@app/store/modalStore.js";
	import { toast } from '@zerodevx/svelte-toast';
	import { projectState } from "@app/store/projectStore.js";
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import { speakerState } from "@app/store/speakerStore.js";
	import { markerState } from "@app/store/markerStore.js";
	import { issueState } from "@app/store/issueStore.js";
	import { metadataState } from "@app/store/metadataStore.js";
	import { styleState } from "@app/store/styleStore.js";
	import { historyState } from "@app/store/historyStore.js";
	import { authState } from "@app/store/authStore.js";
	import { fade } from "svelte/transition";
	import { tick } from "svelte";
	import { onDestroy } from "svelte";
	/* Firebase */
	import firebase from "@app/configs/firebase.js";
	import storage from "@app/configs/storage.js";
	import db from "@app/configs/firestore.js";

	/* CC LIB */
	import _Project from "@app/external/cc-lib/dist/classes/project.js";
	import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
	import _Event from "@app/external/cc-lib/dist/classes/event.js";

    import Swal from 'sweetalert2'

	let userId = firebase.auth().currentUser.uid;
	let progress = 0,
        processing = false,
        commitMessage = "",
		statusMsg = "",
		selectedEventGroupId = false,
		indexOfSelectedEventGroup = -1,
		unsubscribe;

	onDestroy(() => {
		if (unsubscribe) {
			unsubscribe();
		}
	});

	async function retrieveSnapshot(commitFileUrl) {
		try {
			console.log("Retrieving Commit File");
			progress = 45;
			statusMsg = "Retrieving latest commit from server...";
			let commitFileRes = await fetch(commitFileUrl);
			let commitFileJson = await commitFileRes.json();
			console.log("COMMIT FILE JSON:", commitFileJson);

			progress = 45;
			statusMsg = "Loading new commit...";

			/* Save place for user */
			if ($projectState.selected > -1 && $eventGroupState[$projectState.selected]) {
				selectedEventGroupId = $eventGroupState[$projectState.selected].id;
			}

			/* Clear and wait for update... */
			$projectState.selected = false;
			await tick();
		
			//console.log("Updating Name and Commit ID");
			$projectState.name = commitFileJson.name || "Untitled";
			$projectState.commit = commitFileJson.id;

            //Check if there are events groups in the current $eventGroupState that are not in the commitFileJson
            //If so, we need to create an array with their event group names.

            let eventGroupsNotInShare = [];
            for (var eventGroup of $eventGroupState) {
                let eventGroupInShareIndex = commitFileJson.eventGroups.findIndex((shareEventGroup) => {
                    return shareEventGroup.id === eventGroup.id;
                });

                if (eventGroupInShareIndex === -1) {
                    eventGroupsNotInShare.push(eventGroup.name);
                }
            }

            console.log("Event Groups Not In Share:", eventGroupsNotInShare);

			//console.log("Importing Changes:");
			for (var eventGroup of commitFileJson.eventGroups) {
				if (eventGroup.state !== "deleted") {
					//console.log("Importing changes from Event Group");
					let teamEvGroup = new _EventGroup({
						...eventGroup,
						events: [],
						selected: [],
					});

					teamEvGroup.id = eventGroup.id;

					for (var event of eventGroup.events) {
						if (event.state !== "deleted") {
							//console.log(event);
							let teamEv = new _Event(event);
							teamEv.id = event.id;
							teamEvGroup.events.push(teamEv);
						}
					}

					//console.log("Commiting event group to event group state");
                    //Check $eventGroupState to see if the event group already exists
                    let existingEventGroupIndex = $eventGroupState.findIndex((eventGroup) => {
                        return eventGroup.id === teamEvGroup.id;
                    }); 

                    teamEvGroup.scrollPosition = 0;
					$eventGroupState[existingEventGroupIndex] = teamEvGroup;
				}
			}

            if (eventGroupsNotInShare.length >0){
                /* Show sweet alert to let user know there are events that are not part of the team project and were not synced with the server. These projects only exist on their system. */
                Swal.fire({
                    title: 'Events Not In Team Project',
                    html: `The following Event Groups are not part of the team project and were not synced with the server. These Event Groups only exist on your system. <br><br> <ul class="list-unstyled">${eventGroupsNotInShare.map((eventGroupName) => {
                        return `<li>${eventGroupName}</li>`;
                    }).join("")}</ul>`,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    allowOutsideClick : false,
                    allowEscapeKey : false,
                    buttonsStyling : false,
                    customClass: {
                        confirmButton: 'btn btn-success',
                    }
                });
            }

            $eventGroupState = $eventGroupState;
			progress = 100;
			statusMsg = "Done";

			await tick();

			if (selectedEventGroupId !== false) {
				indexOfSelectedEventGroup = $eventGroupState.findIndex((eventGroup) => {
					return eventGroup.id === selectedEventGroupId;
				});

				$projectState.selected = indexOfSelectedEventGroup > -1 ? indexOfSelectedEventGroup : 0;
			} else if ($eventGroupState.length > 0) {
				$projectState.selected = 0;
			}

			historyState.reset();
			historyState.insert({
				name: "project sync", //action name
				eventGroup: false,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});

			toast.push("Sync completed successfully", {classes: ['toast-success']});
		} catch (err) {
			console.log(err, err.message);
			toast.push("Project sync failed: " + err.message, {classes: ['toast-danger']});
		} finally {
			modalState.hideModal();
		}
	}

	async function syncProject() {
		toast.push("Syncing team project with Junction. This may take a moment...", {classes: ['toast-info']});

        processing = true;

		try {
			if (!$authState.team || !$authState.team.id) {
				throw new Error("Unable to sync Team Project. Your user does not belong to a team.");
			}

			let commitFileUrl;
			let teamId = $authState.team.id;
			let storageRef = storage.ref();
			let snapshotId = `${Date.now().toString()}_${userId}`; //Snapshot is like a copy of our project
			let fileName = `${snapshotId}.json`;
			/* commits : teams/*teamId/projects/*project.teamId/commits */
			let dbRef = db.collection("teams").doc(teamId).collection("projects").doc($projectState.teamId).collection("snapshots"); //Location in DB so we can track a commit job in case our sync function times out.

			/* snapshots: /teams/*teamId/projects/*project.teamId/snapshots/*date_userId*.json */
			let uploadRef = storageRef.child(`teams/${teamId}/projects/${$projectState.teamId}/snapshots/${fileName}`); //Where we will upload our snapshot used by the v8SyncProjectV3 script

			/* Generate a Snapshot of the project using each SVELTE Store */
			let projectJson = JSON.parse(JSON.stringify($projectState));
			projectJson.metadata = JSON.parse(JSON.stringify($metadataState));
			projectJson.speakers = JSON.parse(JSON.stringify($speakerState));
			projectJson.issues = JSON.parse(JSON.stringify($issueState));
			projectJson.markers = JSON.parse(JSON.stringify($markerState));
			projectJson.eventGroups = JSON.parse(JSON.stringify($eventGroupState));
			projectJson.style = JSON.parse(JSON.stringify($styleState));

			/* Register Project Sync Commit */
			try {
				progress = 5;
				let registerProjectRes = await firebase.functions().httpsCallable("v8RegisterProjectSync")({
					id: snapshotId,
					projectTeamId: $projectState.teamId,
                    message : commitMessage
				});

				if (registerProjectRes && registerProjectRes.data) {
					console.log(JSON.stringify(registerProjectRes));
					throw new Error(registerProjectRes.data);
				}
			} catch (err) {
				/* Another sync may already be in progress. Cancelling for now... */
				console.error("Unable to register snapshot");
				console.log(err, err.message);
				toast.push("Unable to register project sync job: " + err.message, {classes: ['toast-danger']});

				modalState.hideModal();
				return;
			}

			progress = 15;
			statusMsg = "Uploading snapshot to storage...";
			await uploadRef.putString(JSON.stringify(projectJson));
			progress = 25;

			statusMsg = "Performing sync and generating new commit... please wait.";
			try {
				commitFileUrl = await firebase.functions().httpsCallable("v8SyncProjectV3")({
					id: snapshotId,
					projectTeamId: $projectState.teamId,
				});

				retrieveSnapshot(commitFileUrl.data[0]);
			} catch (err) {
				console.log(err, err.message);
				progress = 35;
				unsubscribe = dbRef.doc(snapshotId).onSnapshot((doc) => {
					console.log(doc.data());
					if (doc.data() === undefined) {
						console.log(err, err.message);
						toast.push("Project sync failed: " + err.message, {classes: ['toast-danger']});

						modalState.hideModal();
						return;
					}

					let commitData = doc.data();
					if (commitData.status === "in progress") {
						return;
					} else if (commitData.status === "complete") {
						retrieveSnapshot(commitData.signedFileUrl[0]);
					} else if (commitData.status === "failed") {
						toast.push("Commit status failed: " + err.message, {classes: ['toast-danger']});

						modalState.hideModal();
					} else {
						toast.push("Project sync failed: " + err.message, {classes: ['toast-danger']});

						modalState.hideModal();
					}
				});
			}
		} catch (err) {
			console.log(err, err.message);
			toast.push("Project sync failed: " + err.message, {classes: ['toast-danger']});

			modalState.hideModal();
		}
	}
</script>

<div transition:fade={{ duration: 100 }} class="modal {$modalState === 'teamSync' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TeamSync">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Project Sync</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
			</div>
			<div class="modal-body">
                {#if processing}
                <p><i class="bi bi-exclamation-diamond-fill" /> Please leave this window open until syncing completes</p>
				<div class="progress">
					<div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
				</div>
				<p class="text-center text-muted small">{statusMsg}</p>
                {:else}
                <form>
					<div class="mb-3">
						<label for="CommitMessageTextArea" class="form-label">Commit Message <small class="">(optional)</small></label>
						<textarea bind:value="{commitMessage}" class="form-control" id="CommitMessageTextArea" rows="3" placeholder="Explain what change you made to this project." />
					</div>					
                    <button type="button" class="btn btn-light float-end" on:click={modalState.hideModal}>Cancel</button>
                    <button type="button" class="btn btn-primary float-end mx-2" on:click="{syncProject}">Sync Changes</button>
				</form>
                {/if}			
			</div>
		</div>
	</div>
</div>
