<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import shiftLineBack from "@app/external/cc-lib/dist/functions/quill/shiftLineBack.js";

function shiftLineToPreviousEvent() {
    try {
        if (!$eventGroupState[$projectState.selected]) return;
        if ($eventGroupState[$projectState.selected].selected.length === 0) return;
        if ($eventGroupState[$projectState.selected].selected[0] === 0) return;

        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0]];

        let res = shiftLineBack($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1], $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]]);

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1].text = res.event1.text;

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text = res.event2.text;

        if (window.quillEditor){
            quillEditor.clipboard.dangerouslyPasteHTML(
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text
            );
        }

        historyState.insert({
            name: "shift line", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err){
        console.log(err, err.message);
    }
}
</script>
<li class="nav-item" title="Shift Line Up">
    <a class="nav-link" href="#!/" class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === 0} on:click="{shiftLineToPreviousEvent}"><i class="bi bi-arrow-bar-up"></i><sub><i class="bi bi-fonts"></i></sub></a>
</li>
