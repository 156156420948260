import _proCapFunc from "../functions/profiles/proCapTransfer.js";
import _sccFunc from "../functions/profiles/scenerist.js";
import _sccLookup from "../dict/608.js";
import _scenerist from "./scenerist.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _eol from "eol";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const eol = _eol;
const getFormatOptions = _getFormatOptions;
const scenerist = _scenerist;
const sccLookup = _sccLookup;
const sccFunc = _sccFunc;
const proCapFunc = _proCapFunc;
export default {
  decode: function (input, options) {
    let events = [],
      sccFile = "Scenarist_SCC V1.0",
      tc;
    let fileLines = eol.split(input);
    let frameRateInfo = proCapFunc.getFrameRateInfo(input, options);
    options.frameRate = frameRateInfo.frameRate;
    options.dropFrame = frameRateInfo.dropFrame;
    fileLines.forEach(fileLine => {
      if (!new RegExp(/TC:\W\W\d\d:\d\d.\d\d/).test(fileLine)) {
        return;
      }
      sccFile += "\n\n" + fileLine.match(/\d\d:\d\d:\d\d.\d\d/)[0].replace(/\.|\,/g, ":") + "\t";
      let cmds = fileLine.split("  ")[2].replace("¶", "");
      let sccCodes = [];
      // console.log(cmds);
      cmds.split("÷").forEach(cmd => {
        if (!cmd) {
          return;
        }
        sccCodes.push(proCapFunc.translateCntrlCode(cmd.substring(0, 4)));
        cmd = cmd.substring(4).split("");
        while (cmd.length > 0) {
          var s = cmd.shift();
          if (s === "§") {
            sccCodes.push("80");
            cmd.shift();
            cmd.shift();
          } else if (s === "\\") {
            sccCodes.push("dc");
          } else {
            sccCodes.push(sccFunc.getCodeByCmd(sccLookup.ch01, s));
          }
        }
      });
      sccFile += sccFunc.formatEncodedCmds(sccCodes);
    });
    events = scenerist.decode(sccFile, {
      frameRate: options.frameRate,
      dropFrame: options.dropFrame,
      window: options.window
    });
    let eventGroup = scenerist.postProcess.decode({
      events: events
    }, options);
    return eventGroup.events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions),
      channel = "ch01",
      incode;
    let output = `'**************************************************

\\ Title: ${encodingOptions.Title || ""}

\\ Version: 1.1
\\ Channel: F1C1
\\ Rate: ${proCapFunc.getRateCode(options.frameRate, options.dropFrame)}
\\ Type: LTC

\\ Generated By: CC Converter 3 (wwww.closedcaptionconverter.com)
\\ CaptionFile: Unknown
\\ MediaFile: Unknown

\\ Author: ${encodingOptions.Author || ""}
\\ Owner: ${encodingOptions.Owner || ""}

\\ Date: ${proCapFunc.getDate()}
\\ Time: ${proCapFunc.getTime()}
        
'**************************************************


`;
    eventGroup.events.forEach((event, index, events) => {
      incode = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame);
      let eventDetails = sccFunc.getEventDetails(event, options.window);
      let encodedText = sccFunc.encodeEvent(eventDetails, channel, options.window);
      let encodedTextString = sccFunc.formatEncodedCmds(encodedText);
      let encodeTime = sccFunc.calculateEncodeTime(encodedTextString);
      //console.log(eventDetails);
      //console.log(encodeTime,encodedText);

      /* We need to check if we need to clear the last event from the screen before starting the encode for the new event. Or if we can just start the encode the new event */
      if (index > 0 && event.start > events[index - 1].end + 0.3) {
        let endTimeOfLastEvent = tcLib.createTc(tcLib.secToTc(events[index - 1].end, options.frameRate), options.frameRate, options.dropFrame);
        if (incode.frameCount - encodeTime > endTimeOfLastEvent.frameCount) {
          output += `\\ TC:  ${proCapFunc.formatTimecodeString(tcLib.secToTc(events[index - 1].end, options.frameRate), options.dropFrame)}  ¶÷142C\n`;
        }
      }
      if (event.style === "Pop-On") {
        //console.log(incode.frameCount, encodeTime);
        if (incode.frameCount - encodeTime >= 0) {
          incode.subtract(encodeTime);
        } else {
          incode.subtract(incode.frameCount);
        }

        //console.log(incode.toString());
      }
      output += `\\ TC:  ${proCapFunc.formatTimecodeString(incode.toString(), options.dropFrame)}  ¶`;
      encodedText.forEach(cmd => {
        let proCapCmd = proCapFunc.translateSccCode(cmd);
        if (proCapCmd.length === 4) {
          output += `÷${proCapCmd.toUpperCase()}`;
        } else if (proCapCmd == "80") {
          output += `§00`;
        } else {
          output += proCapCmd;
        }
      });
      if (event.style === "Pop-On") {
        output += `÷142F`;
      }
      output += "\n";
    });

    //console.log(eventGroup);
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      eventGroup.events.forEach((event, index, events) => {
        sccLookup.specialCharsFilter.forEach(specialChar => {
          let regexPattern = new RegExp(`\\` + specialChar, "g");
          events[index].text = event.text.replace(regexPattern, sccLookup.specialCharacterReplacement[specialChar] + specialChar);
        });

        /* Debug */
        //console.log(events[index].text)
      });
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup, options) {
      eventGroup.events.forEach((event, index, events) => {
        // console.log("---------");
        // console.log("BEFORE:");
        // console.log(event.text);        
        sccLookup.specialCharsFilter.forEach(specialChar => {
          let regexPattern = new RegExp(`.(?<!>)\\` + specialChar, "g");
          events[index].text = event.text.replace(regexPattern, specialChar);
          events[index].text = sccFunc.replaceMusicNotes(events[index].text);
        });

        // console.log("AFTER:");
        // console.log(events[index].text); 
      });
      return eventGroup;
    }
  }
};