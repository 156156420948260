import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const getFormatOptions = _getFormatOptions;
const eol = _eol;

/* Date Functions */
function getTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ':' + seconds + ' ' + ampm;
}
function getDayOfWeek(date) {
  let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let day = date.getDay();
  return days[day];
}
function getMonthOfYear(date) {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let month = date.getMonth();
  return months[month];
}
export default {
  decode: function (input, options) {
    let events = [],
      tcInFlag = false,
      tcOutFlag = false;
    input.split("\n\n").forEach(eventBlock => {
      let ccEvent = new Event({
        xOffset: 0,
        yOffset: options.window.height * -0.10
      });
      tcInFlag = false;
      tcOutFlag = false;
      eol.split(eventBlock).forEach(eventPart => {
        if (eventPart.match(/--> Display at \d\d:\d\d:\d\d:\d\d/g)) {
          tcInFlag = true;
          ccEvent.start = tcLib.tcToSec(eventPart.split(" ")[3], options.frameRate);
        } else if (eventPart.match(/--> Erase at \d\d:\d\d:\d\d:\d\d/g)) {
          tcOutFlag = true;
          ccEvent.end = tcLib.tcToSec(eventPart.split(" ")[3], options.frameRate);
        } else if (tcInFlag) {
          ccEvent.text += "\n" + eventPart;
        }
      });
      if (events.length > 0 && !events[events.length - 1].end) {
        events[events.length - 1].end = ccEvent.start;
      }
      ccEvent.text = convertToHtml(ccEvent.text);
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let d = new Date();
    let time = getTime(d);
    let day = getDayOfWeek(d);
    let month = getMonthOfYear(d);
    let dayNumber = d.getDate();
    let year = d.getFullYear();
    let output = `** Caption file: ${encodingOptions["Title"] ? encodingOptions["title"] : 'untitled'}.vnl
** Generated ${time} on ${day}, ${month} ${dayNumber}, ${year}
** Using Closed Caption Converter 3
** Copyright ©2021 Closed Caption Creator Inc.`;
    output += "\n\n";
    eventGroup.events.forEach(event => {
      output += "--> Display at " + tcLib.secToTc(event.start, options.frameRate) + "\n";
      output += "--> Erase at " + tcLib.secToTc(event.end, options.frameRate) + "\n";
      output += convertToPlainText(event.text);
      output += "\n\n";
    });
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};