import _randomHexColor from "random-hex-color";
import _Speaker from "../../classes/speaker.js";
const Speaker = _Speaker;
const randomHexColor = _randomHexColor;
export default (function convertSpeakersToObjects(eventGroup) {
  eventGroup.speakers = [];
  eventGroup.events.forEach(event => {
    if (event.speakers) {
      let speakerName = "";
      if (Array.isArray(event.speakers) && event.speakers.length > 0) {
        speakerName = event.speakers[0]?.name || event.speakers[0];
        if (speakerName) {
          speakerName = speakerName.toString();
        }
      } else if (event.speakers.length > 0) {
        speakerName = event.speakers.toString();
      }
      if (speakerExists(eventGroup, speakerName)) {
        event.speakers = [eventGroup.speakers.find(speaker => speaker.name === speakerName)];
      } else if (speakerName !== undefined && speakerName !== "" && (typeof speakerName === 'string' || speakerName instanceof String)) {
        // console.log("Adding new speaker: " + speakerName);
        let speaker = new Speaker({
          name: speakerName,
          colour: randomHexColor()
        });
        event.speakers = [speaker];
        eventGroup.speakers.push(speaker);
      }
    }
  });
  return eventGroup;
}); //Check the event group speakers array to see if the speaker already exists by name
function speakerExists(eventGroup, name) {
  return eventGroup.speakers.find(speaker => speaker.name === name);
}