import _eol from "eol";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const eol = _eol;
export default {
  decode: function (input, options) {
    let events = [];
    let ccEvent = false;
    let endFlag = false;
    let fileLines = input.split("\n").filter(fileLine => {
      return fileLine;
    });
    fileLines.forEach(fileLine => {
      if (!endFlag) {
        if (/^\d\d:\d\d:\d\d.\d\d\\\d\d\:\d\d:\d\d.\d\d/.test(fileLine)) {
          if (ccEvent) {
            ccEvent.text = convertToHtml(ccEvent.text);
            events.push(ccEvent);
          }
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          ccEvent.start = tcLib.tcToSec(fileLine.split("\\")[0].replace(".", ":"), options.frameRate);
          ccEvent.end = tcLib.tcToSec(fileLine.split("\\")[1].replace(".", ":"), options.frameRate);
        } else if (/^\*END\*/.test(fileLine)) {
          ccEvent.text = convertToHtml(fileLine);
          events.push(ccEvent);
          endFlag = true;
        } else {
          if (ccEvent) {
            ccEvent.text += fileLine + "\n";
          }
        }
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "*PART 1*\n",
      tcIn,
      tcOut,
      endTime;
    eventGroup.events.forEach(event => {
      endTime = event.end;
      tcIn = tcLib.secToTc(event.start, options.frameRate);
      tcOut = tcLib.secToTc(event.end, options.frameRate);
      output += tcIn.substring(0, 8) + "." + tcIn.substring(9);
      output += "\\";
      output += tcOut.substring(0, 8) + "." + tcIn.substring(9);
      output += "\n";
      output += convertToPlainText(event.text);
      output += "\n";
    });
    tcIn = tcLib.secToTc(endTime, options.frameRate);
    tcOut = tcLib.secToTc(endTime + 5, options.frameRate);
    output += tcIn.substring(0, 8) + "." + tcIn.substring(9);
    output += "\\";
    output += tcOut.substring(0, 8) + "." + tcIn.substring(9);
    output += "\n";
    output += "*END*\n";
    tcIn = tcLib.secToTc(endTime + 5, options.frameRate);
    tcOut = tcLib.secToTc(endTime + 10, options.frameRate);
    output += tcIn.substring(0, 8) + "." + tcIn.substring(9);
    output += "\\";
    output += tcOut.substring(0, 8) + "." + tcIn.substring(9);
    output += "\n";
    output += "*CODE*\n000000000000000\n*CAST*\n*GENERATOR*\n*FONTS*\n*READ*\n0,300 15,000 130,000 100,000 25,000\n*TIMING*\n";
    output += "1 " + options.frameRate.toString().slice(0, 2) + " 0\n";
    output += "*TIMED BACKUP NAME*\n";
    output += "C:\\\n";
    output += "*FORMAT SAMPLE ÅåÉéÌìÕõÛûÿ*\n";
    output += "*READ ADVANCED*\n";
    output += "< > 1 1 1,000\n";
    output += "*MARKERS*\n";
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};