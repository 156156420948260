<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import {
    environment
} from '@app/store/envStore.js';
    import { projectState } from "@app/store/projectStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { onDestroy } from "svelte";
    import supportedLanguages from "@app/external/cc-lib/dist/dict/spellcheckLanguages.js";
    import { Circle } from "svelte-loading-spinners";
    import diff from "generic-diff";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
    import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
    import autoFormat from "@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js";
    let lang = supportedLanguages[0].languageCode,
        engine = $environment.online ? "OpenAi" : "Local", //OpenAi, Local
        correctedEvents = [],
        progress = 0,
        cancelled = false,
        previewText = "",
        loading = false,
        selectedCorrection,
        eventListElement = document.getElementById("EventList"),
        defaultLang = localStorage.getItem("cc-spellcheck-language-v4");

    if (defaultLang) {
        lang = defaultLang;
    }

    onDestroy(() => {
        cancelled = true;
    });

    async function startSpellCheck() {
        try {
            if (
                !$eventGroupState[$projectState.selected] ||
                $eventGroupState[$projectState.selected].events.length === 0
            ) {
                return;
            }

            loading = true;
            cancelled = false;
            correctedEvents = [];
            progress = 0;
            $eventGroupState[$projectState.selected].selected = [];
            selectedCorrection = undefined;
            console.log(
                "Starting Spell Check Process using " +
                    engine +
                    " engine and " +
                    lang +
                    " language.",
            );
            toast.push("Starting spell check process... please wait.", {
                classes: ["toast-info"],
            });

            //Generate list of events with plaintext text check spelling on.
            let events = $eventGroupState[$projectState.selected].events.map(
                (event) => {
                    return {
                        id: event.id,
                        text: convertToPlainText(event.text, " "),
                    };
                },
            );

            //split Events into groups of 10 (or 50 for local). This allows us to run spellcheck in batches of 10.
            let processGroups = events.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / (engine === "Local" ? 50 : 10));

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                }

                resultArray[chunkIndex].push(item);

                return resultArray;
            }, []);

            let completed = 0;
            let total = processGroups.length;
            while (processGroups.length > 0 && !cancelled) {
                let groupsToProcess = processGroups.splice(
                    0,
                    engine === "Local" ? progress > 1 ? 10 : 1 : progress > 1 ? 10 : 2,
                );

                let spellCheckPromises = [];
                groupsToProcess.forEach((group) => {
                    if (engine === "Local") {
                        spellCheckPromises.push(
                            new Promise((resolve) => {
                                let spellCheckWorker = new Worker("./assets/js/spellcheckWorker.js");

                                spellCheckWorker.onmessage = (e) => {
                                    spellCheckWorker.terminate();
                                    resolve(e);
                                };

                                spellCheckWorker.postMessage({
                                    groupId:
                                        $eventGroupState[$projectState.selected]
                                            .id,
                                    events: JSON.stringify(group),
                                    language: lang,
                                });
                            })
                        );
                    } else {
                        let displayName = supportedLanguages.find(
                            (language) => language.languageCode === lang,
                        );

                        console.log(
                            "Spell Check Group: " +
                                group.length +
                                " Events using " +
                                engine +
                                " engine and " +
                                displayName.displayName +
                                " language.",
                        );

                        console.log(group);

                        spellCheckPromises.push(
                            firebase
                                .functions()
                                .httpsCallable("v8SpellCheckerV3")({
                                groupId:
                                    $eventGroupState[$projectState.selected].id,
                                events: JSON.stringify(group),
                                language: displayName.displayName,
                            }),
                        );
                    }
                });

                let results = await Promise.allSettled(spellCheckPromises);
                results.forEach((res) => {
                    if (
                        res.status === "fulfilled" &&
                        res.value.data &&
                        res.value.data.response
                    ) {
                        let corrections = JSON.parse(res.value.data.response);
                        //Filter wordCorrections array in each event to only include wordCorrections where the original word is different from the corrected word
                        corrections.events.forEach((event, i, events) => {
                            let eventIndex = $eventGroupState[
                                $projectState.selected
                            ].events.findIndex((evt) => {
                                return evt.id === event.id;
                            });

                            if (eventIndex === -1) {
                                events[i].wordCorrections = [];
                                return;
                            }

                            let changes = diff(
                                convertToPlainText(
                                    $eventGroupState[$projectState.selected]
                                        .events[eventIndex].text,
                                    " ",
                                ),
                                event.correctedText,
                            );

                            if (
                                changes.length === 1 &&
                                !changes[0].added &&
                                !changes[0].removed
                            ) {
                                event.wordCorrections = [];
                            }

                            events[i].wordCorrections =
                                event.wordCorrections.filter(
                                    (wordCorrection) => {
                                        return (
                                            wordCorrection.original !==
                                            wordCorrection.corrected
                                        );
                                    },
                                );
                        });

                        corrections.events = corrections.events.filter(
                            (event) => {
                                return event.wordCorrections.length > 0;
                            },
                        );

                        correctedEvents = [
                            ...correctedEvents,
                            ...corrections.events,
                        ];
                    }

                    completed++;
                    progress = parseInt((completed / total) * 100);
                });
            }

            finalizeSpellCheck();
        } catch (err) {
            console.log(err, err.message);
            loading = false;
            correctedEvents = [];
            progress = 0;
            selectedCorrection = undefined;
            toast.push(
                "There was an error starting spell check " + err.message,
                { classes: ["toast-danger"] },
            );
        }
    }

    async function finalizeSpellCheck() {
        //console.log(correctedEvents);
        console.log("Ending Spell Check");
        loading = false;
        progress = 0;
        toast.push("Spell check process completed successfully", {
            classes: ["toast-success"],
        });
    }

    function acceptAllChanges() {
        correctedEvents.forEach((eventCorrection) => {
            let eventIndex = $eventGroupState[
                $projectState.selected
            ].events.findIndex((event) => {
                return event.id === eventCorrection.id;
            });

            if (eventIndex > -1) {
                eventCorrection.wordCorrections.forEach((correction) => {
                    let searchPattern = new RegExp(
                        `\\b${correction.original}\\b|\\B${correction.original}\\B`,
                    );
                    if (
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text.match(searchPattern)
                    ) {
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text = $eventGroupState[
                            $projectState.selected
                        ].events[eventIndex].text.replace(
                            searchPattern,
                            correction.corrected,
                        );
                    } else {
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text = convertToHtml(eventCorrection.correctedText);
                        $eventGroupState[$projectState.selected] = autoFormat(
                            $eventGroupState[$projectState.selected],
                            $eventGroupState[$projectState.selected].maxLines,
                            $eventGroupState[$projectState.selected].maxChars,
                            0,
                            false,
                            [eventIndex],
                        );
                    }
                });
            }
        });

        selectedCorrection = undefined;
        correctedEvents = [];
        progress = 0;
        $eventGroupState[$projectState.selected].selected = [];
        historyState.insert({
            name: "correct event", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
    }

    function acceptChange() {
        if (!selectedCorrection) {
            return;
        }

        let eventIndex = $eventGroupState[
            $projectState.selected
        ].events.findIndex((event) => {
            return event.id === selectedCorrection.id;
        });

        if (eventIndex > -1) {
            //Go through the wordCorrections array and replace the original word with the corrected word in the event text.
            selectedCorrection.wordCorrections.forEach((correction) => {
                //First check to see if the original word in the text. If it is then replace it with the corrected word. If it is not then we'll need to replace the entire text with the corrected text.
                let searchPattern = new RegExp(
                    `\\b${correction.original}\\b|\\B${correction.original}\\B`,
                );
                if (
                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].text.match(searchPattern)
                ) {
                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].text = $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].text.replace(searchPattern, correction.corrected);
                } else {
                    $eventGroupState[$projectState.selected].events[
                        eventIndex
                    ].text = convertToHtml(selectedCorrection.correctedText);
                    $eventGroupState[$projectState.selected] = autoFormat(
                        $eventGroupState[$projectState.selected],
                        $eventGroupState[$projectState.selected].maxLines,
                        $eventGroupState[$projectState.selected].maxChars,
                        0,
                        false,
                        [eventIndex],
                    );
                }
            });

            historyState.insert({
                name: "correct event", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            ignoreChange();
        }
    }

    function ignoreChange() {
        let eventIndex = correctedEvents.findIndex((event) => {
            return event.id === selectedCorrection.id;
        });

        correctedEvents = correctedEvents.filter((correction) => {
            return correction.id !== selectedCorrection.id;
        });

        if (correctedEvents.length > 0) {
            selectedCorrection = correctedEvents[eventIndex]
                ? correctedEvents[eventIndex]
                : correctedEvents[0];

            let selectedEventIndex = $eventGroupState[
                $projectState.selected
            ].events.findIndex((event) => {
                return event.id === selectedCorrection.id;
            });

            if (selectedEventIndex > -1) {
                if (!eventListElement) {
                    eventListElement = document.getElementById("EventList");
                }

                try {
                    eventListElement.scrollTo(0, selectedEventIndex * 230);
                } catch (err) {
                    eventListElement = document.getElementById("EventList");
                    eventListElement.scrollTo(0, selectedEventIndex * 230);
                }

                $eventGroupState[$projectState.selected].selected = [
                    selectedEventIndex,
                ];
            } else {
                $eventGroupState[$projectState.selected].selected = [];
            }
        } else {
            selectedCorrection = undefined;
            $eventGroupState[$projectState.selected].selected = [];
        }
    }

    function updateDefaultDictionary() {
        localStorage.setItem("cc-spellcheck-language-v4", lang);
    }

    function selectEvent() {
        if (selectedCorrection && selectedCorrection.id) {
            let eventIndex = $eventGroupState[
                $projectState.selected
            ].events.findIndex((event) => {
                return event.id === selectedCorrection.id;
            });

            if (!eventListElement) {
                eventListElement = document.getElementById("EventList");
            }

            eventListElement.scrollTo(0, eventIndex * 230);
            $eventGroupState[$projectState.selected].selected = [eventIndex];
        }
    }

    function diffChanges(selectedCorrection) {
        try {
            if (!selectedCorrection) {
                previewText = "";
                return;
            }
            let eventIndex = $eventGroupState[
                $projectState.selected
            ].events.findIndex((event) => {
                return event.id === selectedCorrection.id;
            });

            if (eventIndex === -1) {
                previewText = "";
                return;
            }

            let changes = diff(
                convertToPlainText(
                    $eventGroupState[$projectState.selected].events[eventIndex]
                        .text,
                    " ",
                ),
                selectedCorrection.correctedText,
            );
            // console.log("------DEBUG------");
            // console.log(convertToPlainText($eventGroupState[$projectState.selected].events[eventIndex].text, " "));
            // console.log(selectedCorrection.correctedText);
            // console.log(changes);

            return changes
                .map(function (edit) {
                    if (edit.added) {
                        return (
                            '<span class="text-primary fw-bold">' +
                            edit.items.join("") +
                            "</span>"
                        );
                    } else if (edit.removed) {
                        return (
                            '<span class="text-decoration-line-through text-danger">' +
                            edit.items.join("") +
                            "</span>"
                        );
                    } else {
                        return edit.items.join("");
                    }
                })
                .join("");
        } catch (err) {
            console.log(err, err.message);
        }
    }

    $: previewText = diffChanges(selectedCorrection);
</script>

<div class="row">
    <div class="col-12">
        <form aria-label="Spell Check Form">
            <div class="row g-2">
                <div class="mb-2 col-6">
                    <label for="corrections-list" class="form-label"
                        >Corrections {#if correctedEvents.length > 0}
                            <span class="badge bg-danger align-middle" role="status" aria-live="polite"
                                >{correctedEvents.length}</span
                            >
                        {/if}</label
                    >
                    <select
                        id="corrections-list"
                        class="form-select form-select-sm resize-vertical"
                        size="5"
                        bind:value={selectedCorrection}
                        on:change={selectEvent}
                        aria-label="List of spelling corrections"
                    >
                        {#each correctedEvents as correction}
                            <option value={correction}
                                >{convertToPlainText(
                                    correction.correctedText,
                                    " ",
                                ).substring(0, 50)}</option
                            >
                        {/each}
                    </select>
                </div>
                <div class="mb-2 col-6">
                    <label for="OriginalText" class="form-label">Preview</label>
                    <div
                        id="HtmlTextPreview"
                        class="bg-light resize-vertical p-1 px-2 rounded textPreview"
                    >
                        {@html previewText ? previewText : ""}
                    </div>
                </div>
            </div>
            <div class="row g-2">
                <div class="col-2">
                    <select
                        id="engine-select"
                        class="form-select form-select-sm"
                        disabled={loading}
                        aria-label="Spell check engine selection"
                        bind:value={engine}
                        on:change={updateDefaultDictionary}
                    >   
                        <option>Local</option>
                        {#if $environment.online}
                            <option>OpenAi</option>
                        {/if}
                    </select>
                </div>
                <div class="col-4">
                    <select
                        id="language-select"
                        class="form-select form-select-sm"
                        disabled={loading}
                        aria-label="Language selection"
                        bind:value={lang}
                        on:change={updateDefaultDictionary}
                    >
                        {#each supportedLanguages as languageOption}
                            <option value="{languageOption.languageCode}">{languageOption.displayName}</option>            
                        {/each}
                    </select>
                </div>

                <div class="col-6">
                    <button
                        type="button"
                        id="start-spellcheck"
                        class="btn btn-info text-white btn-sm"
                        title="Start the spell check process. Errors will appear as they are found. Correct errors while scanning is in progress."
                        disabled={loading}
                        on:click={() => startSpellCheck()}
                        aria-label={loading ? `Spell check in progress: ${progress}%` : "Start spell check"}
                    >
                        {#if loading}
                            <div class="float-start p-1" role="status" aria-label="Loading indicator">
                                <Circle
                                    size="12"
                                    color="#ffffff"
                                    unit="px"
                                    duration="1s"
                                ></Circle>
                            </div>
                            <span aria-live="polite">{progress}%</span>
                        {:else}
                            <i class="bi bi-spellcheck" aria-hidden="true"></i> Run
                        {/if}
                    </button>

                    <div class="btn-group btn-group-sm float-end" role="group" aria-label="Correction actions">
                        <button
                            type="button"
                            id="accept-change"
                            class="btn btn-primary"
                            on:click={acceptChange}
                            title="Accept Change"
                            disabled={!selectedCorrection}
                            aria-label="Accept current correction"
                        ><i class="bi bi-check-lg" aria-hidden="true"></i> Ok</button
                        >
                        <button
                            type="button"
                            id="ignore-change"
                            class="btn btn-outline-dark"
                            on:click={ignoreChange}
                            title="Skip/Ignore"
                            disabled={!selectedCorrection}
                            aria-label="Skip current correction"
                        ><i class="bi bi-x-lg" aria-hidden="true"></i></button
                        >
                        <button
                            type="button"
                            id="accept-all"
                            class="btn btn-outline-primary ms-2"
                            on:click={acceptAllChanges}
                            title="Accept All"
                            disabled={correctedEvents.length === 0}
                            aria-label="Accept all corrections"
                            ><i class="bi bi-check2-all" aria-hidden="true" /> All</button
                        >
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<style>
    #HtmlTextPreview {
        height: 10vh;
        overflow-y: auto;
    }
</style>
