<script>
import {adrState} from '@app/store/adrStore.js';
import {styleState} from '@app/store/styleStore.js';
</script>

<h5>ADR Setup & Configuration</h5>
<form id="adrConfigForm" aria-label="ADR Setup & Configuration">
    <h6 class="fw-bold">MIDI Configuration</h6>
    <div class="my-4 form-check form-switch">
        <input id="midiEnabledCheck" type="checkbox" class="form-check-input" checked={$adrState.enable} on:change={() => adrState.toggleMidiControl()} aria-labelledby="midiEnabledLabel">
        <label id="midiEnabledLabel" class="form-check-label" for="midiEnabledCheck">Enable Midi Control <i class="bi bi-info-circle" title="Enable or disable midi control with external applications (e.g. Avid ProTools). This feature is intended to help when recording AD and DV scripts. Contact Support for more information."></i></label>
    </div>    
    <div class="row mb-3">
        <div class="col">
            <label class="form-label" for="bpmInput">Tempo / BPM</label>
            <input id="bpmInput" class="form-control" type="number" min="0" step="1" bind:value={$adrState.bpm}>
        </div>
    </div>
    
    <div class="mb-3">
        <label for="midiInputDeviceSelect" class="form-label">Input Device</label>
        <select class="form-select" id="midiInputDeviceSelect" bind:value={$adrState.selectedInput}>
            {#each $adrState.inputDevices as inputDevice (inputDevice.id)}
                <option value="{inputDevice}">{inputDevice.id} | {inputDevice.name}</option>
            {/each}
        </select>
    </div>
    <div class="mb-3">
        <label for="midiOutputDeviceSelect" class="form-label">Output Device</label>
        <select class="form-select" id="midiOutputDeviceSelect" bind:value={$adrState.selectedOutput}>
            {#each $adrState.outputDevices as outputDevice (outputDevice.id)}
                <option value="{outputDevice}">{outputDevice.id} | {outputDevice.name}</option>
            {/each}
        </select>
    </div>
    <h6 class="fw-bold">ADR Preview Setup</h6>
    <div class="my-4 form-check form-switch">
        <input id="adrPreviewEnabledCheck" type="checkbox" class="form-check-input" bind:checked={$styleState.adrPreview} aria-labelledby="adrPreviewEnabledLabel">
        <label id="adrPreviewEnabledLabel" class="form-check-label" for="adrPreviewEnabledCheck">Enable ADR Preview <i class="bi bi-info-circle" title="Update the static text preview with an animated preview to help when recording."></i></label>
    </div>
</form>
