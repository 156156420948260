<script>
import { historyState } from "@app/store/historyStore.js";
import { eventGroupState } from "@app/store/eventGroupStore.js";
import { projectState } from "@app/store/projectStore.js";
import { createEventDispatcher } from "svelte";
import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
const dispatch = createEventDispatcher();

function cleanText(text) {
    return text
        // Remove bullet points (•, *, -, etc.)
        .replace(/[•*\-–—]\s*/g, '')
        // Remove numbered lists (1., 1), etc.)
        .replace(/^\d+[\.\)]\s*/gm, '')
        // Remove unicode symbols and emojis
        .replace(/[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/g, '')
        // Remove special characters but keep basic punctuation
        .replace(/[^\w\s\.,!?'"\n]/g, '')
        // Remove tabs
        .replace(/\t/g, ' ')
        // Remove multiple spaces (but keep line breaks)
        .replace(/[ ]+/g, ' ')
        // Remove spaces at the start of lines
        .replace(/^\s+/gm, '')
        // Remove spaces at the end of lines
        .replace(/\s+$/gm, '')
        // Remove multiple consecutive line breaks
        .replace(/\n\s*\n\s*\n+/g, '\n\n');
}

function cleanEventText() {
    try {
        if (
            !$eventGroupState[$projectState.selected] ||
            $eventGroupState[$projectState.selected].selected.length === 0
        ) {
            return;
        }

        $eventGroupState[$projectState.selected].selected
            .sort()
            .forEach((eventIndex) => {
                try {
                    let plainText = convertToPlainText(
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text, "\n"
                    );

                    let cleanedText = cleanText(plainText);
                    $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ].text = convertToHtml(cleanedText);                
                } catch (err) {
                    console.log(err, err.message);
                }
            });

        historyState.insert({
            name: "clean text", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
    } catch (err) {
        console.log(err);
        console.log(err.message);
    } finally {
        $eventGroupState[$projectState.selected].selected = [];
        dispatch("done");
    }
}
</script>

<a
    id="btnAutoTime"
    role="menuitem"
    aria-label="Clean up text"
    class="dropdown-item"
    href="#!/"
    on:click={cleanEventText}>Clean Up Text</a>
