import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _alignments from "../dict/alignments.js";
import _convertStylestoStyleMap from "../functions/special/convertStylestoStyleMap.js";
import _convertRegionsToPositionMap from "../functions/special/convertRegionsToPositionMap.js";
import _JsonFind from "json-find";
import _eol from "eol";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _formatXml from "xml-formatter";
import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const getFormatOptions = _getFormatOptions;
const removeInvalidEvents = _removeInvalidEvents;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const ttmlFunc = _ttmlFunc;
const formatXml = _formatXml; //Encode
const XMLParser = _XMLParser; //Decode
const eol = _eol;
const JsonFind = _JsonFind;
const convertRegionsToPositionMap = _convertRegionsToPositionMap;
const convertStylestoStyleMap = _convertStylestoStyleMap;
const alignments = _alignments;
const convertToPlainTextCustom = _convertToPlainTextCustom;
export default {
  decode: function (input, options) {
    let events = [],
      ccEvent;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let jsonInput = xmlToJson.parse(input);
    let regions = JsonFind(jsonInput).findValues("region") || {
      region: []
    };
    let styles = JsonFind(jsonInput).findValues("style") || {
      style: []
    };
    if (!Array.isArray(regions.region)) {
      regions.region = [regions.region];
    }
    if (!Array.isArray(styles.style)) {
      styles.style = [styles.style];
    }
    let positionMap = convertRegionsToPositionMap(regions.region, options.window);
    let styleMap = convertStylestoStyleMap(styles.style);

    //Check if p property is an array or object
    if (jsonInput.tt.body.div.p.length === undefined) {
      jsonInput.tt.body.div.p = [jsonInput.tt.body.div.p];
    }
    jsonInput.tt.body.div.p.forEach(paragraph => {
      if (!paragraph["#text"]) {
        return;
      }
      ccEvent = new Event();
      ccEvent.start = tcLib.tcMsToSec(paragraph["@_begin"]);
      ccEvent.end = tcLib.tcMsToSec(paragraph["@_end"]);
      ccEvent.text = convertToHtml(ttmlFunc.multiLine.formatText(paragraph["#text"].replace(/<br \/>|<br\/>|<br>/gim, "\n")));
      if (paragraph["@_region"]) {
        ccEvent = {
          ...ccEvent,
          ...positionMap[paragraph["@_region"]]
        };
      } else {
        ccEvent.xPos = "center";
        ccEvent.yPos = "end";
        ccEvent.xOffset = 0;
        ccEvent.yOffset = options.window.height * -0.1;
      }
      if (paragraph["@_tts:textAlign"] && alignments.includes(paragraph["@_tts:textAlign"])) {
        ccEvent.alignment = paragraph["@_tts:textAlign"];
      } else if (paragraph["textAlign"] && alignments.includes(paragraph["textAlign"])) {
        ccEvent.alignment = paragraph["textAlign"];
      } else if (paragraph["@_style"] && styleMap[paragraph["@_style"]] && styleMap[paragraph["@_style"]]["@_tts:textAlign"] && alignments.includes(styleMap[paragraph["@_style"]]["@_tts:textAlign"])) {
        ccEvent.alignment = styleMap[paragraph["@_style"]]["@_tts:textAlign"];
      }
      events.push(ccEvent);
    });
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    //console.log(encodingOptions);
    let languageCode = encodingOptions["Language Code"] || "en-US";
    let fontFamily = encodingOptions["Font Family"] || "SansSerif";
    let fontSize = encodingOptions["Font Size"] || 10;
    let fontWeight = encodingOptions["Font Weight"] || "normal";
    let fontStyle = encodingOptions["Font Style"] || "normal";
    let encodeFormatting = encodingOptions["Encode Formatting"] || false;
    let formatXmlOpt = encodingOptions["Format XML"] || false;
    let output = eol.after('<?xml version="1.0" encoding="UTF-8"?>');
    output += eol.after(`    <tt xml:lang="${languageCode}" xmlns="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttm="http://www.w3.org/ns/ttml#metadata">`);
    output += eol.after(`        <head>
			<layout>
				<region xml:id="top" tts:backgroundColor="transparent" tts:showBackground="whenActive" tts:origin="10% 10%" tts:extent="80% 80%" tts:displayAlign="before"/>
				<region xml:id="bottom" tts:backgroundColor="transparent" tts:showBackground="whenActive" tts:origin="10% 10%" tts:extent="80% 80%" tts:displayAlign="after"/>
			</layout>
			<styling>
				<style xml:id="defaultCaption" tts:fontSize="${fontSize}" tts:fontFamily="${fontFamily}" tts:fontWeight="${fontWeight}" tts:fontStyle="${fontStyle}" tts:textDecoration="none" tts:color="white" tts:backgroundColor="black" />
			</styling>
		</head>
		<body>
			<div style="defaultCaption" xml:lang="${languageCode}">`);
    eventGroup.events.forEach(event => {
      let regionId = event.yPos === "end" ? "bottom" : "top";
      let alignment = event.alignment || "center";
      let plainTextCustom = convertToPlainTextCustom(event.text);
      let text = encodeFormatting ? ttmlFunc.multiLine.convertToTtml(plainTextCustom) : convertToPlainText(event.text).replace(/\n/gim, "<br />");

      // console.log(encodeFormatting, text);
      output += eol.after(`                <p begin="${tcLib.secToTcMs(event.start).replace(",", ".")}" end="${tcLib.secToTcMs(event.end).replace(",", ".")}" region="${regionId}" tts:textAlign="${alignment}">${text}</p>`);
    });
    output += `            </div>
		</body>
	</tt>`;
    if (formatXmlOpt) {
      return formatXml(output);
    } else {
      return output;
    }
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};