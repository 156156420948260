<script>
import { v4 as uuidv4 } from "uuid";
import {
    environment
} from '@app/store/envStore.js';
import {
    uiState
} from "@app/store/uiStore.js";
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    styleState
} from '@app/store/styleStore.js';
import { externalDataState } from "@app/store/externalDataStore.js";
import {
    historyState
} from '@app/store/historyStore.js';
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
import frameRates from '@app/external/cc-lib/dist/dict/frameRates.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
import {
    onDestroy, onMount
} from "svelte";
import Swal from "sweetalert2";

import _Event from '@app/external/cc-lib/dist/classes/event.js';
import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import _Project from '@app/external/cc-lib/dist/classes/project.js';
import insertEvent from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';

/* Firebase */
export let projectFilePath;
const uid = uuidv4();

let progress = 0,
    statusMsg = "Starting import...", 
    audioExtractionComplete = false,
    manifestFileExists = false,
    audioExtractionWorker,
    proxyRtWorker;

onMount(() => {
    console.log($externalDataState);
    if (projectFilePath){
        readProjectFileFromPath();
    } else if ($externalDataState.platform === "Project File"){
        importProjectFile(JSON.parse($externalDataState.rawDescription));
    } else {
        modalState.hideModal();
    }
});

onDestroy(() => {
    if (audioExtractionWorker) {
        audioExtractionWorker.terminate();
    }
});

function checkFileExistsSync(filePath) {
  try {
    window.fsSync.accessSync(filePath);
    return true;
  } catch (error) {
    return false;
  }
}

async function getFileMetadata(filePath, ffmpegPath) {
    try {
        return await window.exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
    } catch (err) {
        return err.message;
    }
}
    
function getMediaIncode(ffmpegOutput) {
    let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
    let mediaIncode = matches ? matches[0].replace(";", ":") : false;

    console.log("Media incode detected: " + mediaIncode);
    return mediaIncode == "null" ? false : mediaIncode;
}

function getMediaFrameRate(ffmpegOutput) {    
    let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
    let mediaFrameRate = matches ? matches[0] : false;

    console.log("Media frame rate detected: " + mediaFrameRate);
    let fr = frameRates.frameRateMapping[mediaFrameRate];
    return fr;
}

function getMediaDuration(ffmpegOutput){
    let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
    let mediaDuration = matches ? matches[0] : false;

    console.log(mediaDuration);
    if (mediaDuration){
        mediaDuration = tcLib.tcMsToSec(mediaDuration);
        console.log("media duration in seconds: " + mediaDuration);
    }

    return mediaDuration;
}

function readProjectFileFromPath(){
    progress = 10;
    statusMsg = "Reading project file...";
    window.fsSync.readFile(projectFilePath.replace(/"/g,""), (err, data) => {
        if (err) {
            console.log(err);
            toast.push(`Project file read failed. ${err.message}`, {classes: ['toast-danger']});
            modalState.hideModal();
        } else {
            importProjectFile(JSON.parse(data));
        }
    });
}

async function importProjectFile(projectJson){
    try {
        progress = 15;
        statusMsg = "Importing project file:";
        /* Reset States */
        $eventGroupState = [];
        $metadataState = [];
        $speakerState = [];
        $issueState = [];
        $markerState = {
            selected : 0,
            lists : [
                {
                    id : '0',
                    name : "Shot Changes",
                    color : "#E74C3C",
                    markers : []
                }
            ]
        };

        $projectState = new _Project({
            ...projectJson,
            ...{
                eventGroups: [],
                metadata: [],
                speakers: [],
                issues: [],
                markers: {},
                style : {},
                folderId: $externalDataState.folderId || projectJson.folderId,
                localPath : projectFilePath ? projectFilePath : null,
                useLocalStorage : projectFilePath ? true : false
            }
        });

        $projectState.id = projectJson.id;
        projectJson.eventGroups.forEach((eventGroup, eventGroupIndex) => {
            $eventGroupState = [...$eventGroupState, new _EventGroup({
                ...eventGroup,
                events: []
            })];

            $eventGroupState[$eventGroupState.length-1].id = eventGroup.id;

            eventGroup.events.forEach(event => {
                $eventGroupState[eventGroupIndex] = insertEvent($eventGroupState[eventGroupIndex], event);
                $eventGroupState[eventGroupIndex].events[$eventGroupState[eventGroupIndex].events.length-1].id = event.id;
            });
        });

        progress = 25;
        statusMsg = "Importing project metadata...";

        if (projectJson.metadata){
            projectJson.metadata.forEach(metadataInfo => {
                $metadataState = [...$metadataState, metadataInfo];
            });
        }

        if (projectJson.speakers){
            projectJson.speakers.forEach(speakerInfo => {
                $speakerState = [...$speakerState, speakerInfo];
            });  
        }
            
        if (projectJson.issues){
            projectJson.issues.forEach(issue => {
                $issueState = [...$issueState, issue];
            });  
        }

        $markerState = projectJson.markers || {
            selected : 0,
            lists : [
                {
                    id : '0',
                    name : "Shot Changes",
                    color : "#E74C3C",
                    markers : []
                }
            ]
        };

        /* Import Style Info */
        if (projectJson.style){
            for (const [key, value] of Object.entries(projectJson.style)) {
                $styleState[key] = value;
            }
        }

        if ($eventGroupState.length > 0){
            $projectState.selected = $eventGroupState.length - 1;
        }

        progress = 45;
        statusMsg = "Importing media...";

        historyState.reset();
        historyState.insert({
            name: "import project", //action name
            eventGroup: false,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        if (((projectJson.media.storage === "Local Storage" || projectJson.media.storage === "Proxy RT") && !$environment.electron) || projectJson.media.localPath === "") {
            //ALERT USER TO IMPORT MEDIA USING SWAL
            await Swal.fire({
                title: 'Media Import Required',
                text: 'Please re-import the media file.',
                icon: 'warning',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: "btn btn-lg btn-primary"
                }
            });

            modalState.showModal("mediaImport");
        } else {
            importMedia();
        }
    } catch(err){
        console.log(err);
        toast.push( `Project Import Failed. ${err.msg}`, {classes: ['toast-danger']});
        modalState.hideModal();
    } 
}

async function importMedia() {
    playerState.updateDuration(false);
    $uiState.timeline = false;
    let mediaType = "video/mp4"; //default 
    let videoOutputPath;
    let audioOutputPath;
    let ffmpegPath;
    switch ($projectState.media.storage) {
        case 'Vimeo':
            progress = 75;
            statusMsg = "Loading Vimeo Video...";
            player.src = $projectState.media.path;
            manifestFileExists = true;
            audioExtractionComplete = true;
            $projectState.media.useFallback = true;
            break;
        case 'YouTube':
            progress = 75;
            statusMsg = "Loading YouTube Video...";
            player.src = $projectState.media.path;
            manifestFileExists = true;
            audioExtractionComplete = true;
            $projectState.media.useFallback = true;
            break;
        case 'HLS Manifest':      
            progress = 75;
            statusMsg = "Loading HLS Manifest...";       
            audioExtractionComplete = true;
            player.src = $projectState.media.path;
            break;
        case 'Proxy RT':
            progress = 75;
            statusMsg = "Generating Proxy Stream...";  
            if (!checkFileExistsSync($projectState.media.localPath)){
                toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                modalState.showModal('mediaImport');
                return;
            }
            
            videoOutputPath = window.os.tmpdir() + window.path.sep + uid + ".m3u8";
            audioOutputPath = window.os.tmpdir() + window.path.sep + uid + ".json";
            $projectState.media.path = videoOutputPath;
            ffmpegPath = require("ffmpeg-static-electron").path;
            let ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
            $projectState.media.info = {
                incode: getMediaIncode(ffmpegRes),
                frameRate: getMediaFrameRate(ffmpegRes),
                duration : getMediaDuration(ffmpegRes)
            };

            audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");

            audioExtractionWorker.postMessage({
                inputPath: $projectState.media.localPath,
                outputPath: audioOutputPath,
                ffmpegPath: ffmpegPath,
                duration : $projectState.media.info.duration
            });

            audioExtractionWorker.onmessage = (msg) =>{
                console.log(msg);
                if (msg.data.status === "in_progress") {
                    statusMsg = msg.data.result;
                } else {
                    $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                    $projectState.media.useFallback = false;

                    toast.push(`${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`, {classes: ['toast-`${msg.data.error ? "danger" : "success"}`,']});

                    audioExtractionComplete = true;
                    closeModal();
                }
            }    
            
            proxyRtWorker = new Worker("./build/workers/proxyRt.js");
            proxyRtWorker.postMessage({
                inputPath: $projectState.media.localPath,
                outputPath: videoOutputPath,
                ffmpegPath: ffmpegPath,
                tmpDir: window.os.tmpdir(),
                pathSep: window.path.sep,
            });

            proxyRtWorker.onmessage = (msg) => {
                console.log(msg);
                if (msg.data.status_msg) {
                    manifestFileExists = true;
                    player.src = $projectState.media.path;
                    closeModal();
                } else if (msg.data.error) {
                    manifestFileExists = true;
                    toast.push(`${msg.data.error}`, {classes: ['toast-danger']});
                    closeModal();
                }
            };
            
            break;
        case "Cloud Storage":
            /* Cloud Storage*/     
            $projectState.media.useFallback = true;     
            if ($projectState.media.type === "video/quicktime") {
                mediaType = "video/mp4";
            } else if ($projectState.media.type === "video/x-matroska") {
                mediaType = "video/mkv";
            } else {
                mediaType = $projectState.media.type;
            }

            //Get file size of url
            let fileSize = await getFileSize($projectState.media.path);
            if (fileSize) {
                const sizeInBytes = parseInt(fileSize);
                const sizeInKilobytes = sizeInBytes / 1024;
                const sizeInMegabytes = sizeInKilobytes / 1024;
                if (sizeInMegabytes < 512) {
                    $projectState.media.useFallback = false;
                }
            }

            player.src = {src: $projectState.media.path, type: mediaType};

            /* Update Flags */
            manifestFileExists = true;
            audioExtractionComplete = true;

            break;
        default:
            if (!checkFileExistsSync($projectState.media.localPath)){
                toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                modalState.showModal('mediaImport');
                return;
            }
            
            /* Local Storage */
            videoOutputPath = window.os.tmpdir() + window.path.sep + uid + ".m3u8";
            audioOutputPath = window.os.tmpdir() + window.path.sep + uid + ".json";
            mediaType = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type;
            player.src = { src: $projectState.media.localPath, type: mediaType};           

            progress = 75;
            manifestFileExists = true;
            ffmpegPath = require("ffmpeg-static-electron").path;
            statusMsg = "Generating audio preview...";
            audioExtractionWorker = new Worker("./build/workers/audioExtraction.js");
            audioExtractionWorker.postMessage({
                inputPath: $projectState.media.localPath,
                outputPath: audioOutputPath,
                ffmpegPath: ffmpegPath,
                duration : $projectState.media.info.duration
            });

        audioExtractionWorker.onmessage = (msg) => {
            console.log(msg);
            if (msg.data.status === "in_progress") {
                statusMsg = msg.data.result;
            } else {
                $projectState.media.peaksPath = msg.data.error ? "" : audioOutputPath;
                $projectState.media.useFallback = false;

                toast.push(`${msg.data.error ? msg.data.error : "Media import completed successfully."}`, {classes: [`toast-${msg.data.error ? "danger" : "success"}`]});

                audioExtractionComplete = true;
                closeModal();
            }
        };
    }

    closeModal();
}

function closeModal(){
    console.log("Close Modal Called", audioExtractionComplete, manifestFileExists);
    if (audioExtractionComplete && manifestFileExists){
        statusMsg = "Project import complete...";
        progress = 100;

        setTimeout(() => {
            $uiState.timeline = true;
            modalState.hideModal();
        }, 1000);
    }
}

async function getFileSize() {
    try {
        const response = await fetch($projectState.media.path, {
            method: "HEAD",
        });
        const fileSize = response.headers.get("content-length");
        return fileSize;
    } catch (err){
        return false;
    }    
}
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'loadProjectFile' ? 'show d-block' : ''}" 
    role="dialog"
    aria-labelledby="loadProjectTitle"
    aria-describedby="loadProjectStatus" 
    tabindex="-1" 
    id="LoadProjectFileModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="loadProjectTitle">Project File Loader</h4>
                <button type="button" 
                    class="btn-close"
                    id="btnCloseProjectLoad" 
                    aria-label="Close dialog" 
                    on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p id="loadProjectStatus" aria-live="polite">
                    <i class="bi bi-exclamation-diamond-fill" aria-hidden="true"></i>
                    Import in progress | {statusMsg}
                </p>
                <div class="progress" role="progressbar" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" 
                        style="width: {progress}%;">
                        {progress}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
