export default {
  'en': 'English - Global',
  'en-GB': 'English - UK',
  'en-IN': 'English - India',
  'en-NZ': 'English - New Zealand',
  'en-US': 'English - US',
  'fr': 'French - France',
  'fr-CA': 'French - Canada',
  'nl': 'Dutch',
  'de': 'German',
  'hi': 'Hindi',
  'pt': 'Portuguese',
  'pt-BR': 'Portuguese - Brazil',
  'pt-PT': 'Portuguese - Portugal',
  'ru': 'Russian',
  'es': 'Spanish',
  'es-419': 'Spanish Latin America',
  'tr': 'Turkish',
  'uk': 'Ukrainian',
  'sv': "Swedish",
  'ja': "Japanese",
  'ko': 'Korean',
  'it': 'Italian',
  'id': "Indonesian",
  "pl": "Polish"
};