import _convertToPlainText from "../../quill/convertToPlainText.js";
const convertToPlainText = _convertToPlainText;
export default (async function calculateMissingTimecodes(eventGroup, driftAnalysis, captionFileTimings) {
  let processing = true;
  let loops = 0;
  let minDuration = 0.83;
  let maxDuration = 12;
  let minCps = 25;
  console.log("Calculating missing timecodes. Event group Original timings based on Caption File: ", captionFileTimings);
  while (processing && loops < 10000) {
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    /* -------------------STAGE 1------------------- */
    //Stage 1 looks at events with missing timecodes with events on either side with timecode.
    eventGroup.events.forEach((event, i) => {
      // Calculate start and end time for non-sdh events (events where plainText is not empty) and there is an event before and after with timecodes. 
      if (!event.end && event.plainText && eventGroup.events[i - 1] && eventGroup.events[i - 1].end && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10001;
        let oDuration = event.oDuration;
        let cpsDuration = event.plainText.length / minCps; //duration is based on a cps of 25.
        let possibleDuration = Math.max(0, eventGroup.events[i + 1].start - eventGroup.events[i - 1].end);
        let startGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
        let endGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
        if (possibleDuration <= cpsDuration + cpsDuration * 0.25) {
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
        } else if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (eventGroup.events[i - 1].end + startGap + oDuration <= eventGroup.events[i + 1].start) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + oDuration;
          } else if (eventGroup.events[i + 1].start - endGap - oDuration >= eventGroup.events[i - 1].end) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - oDuration;
          } else if (eventGroup.events[i - 1].end + startGap + cpsDuration <= eventGroup.events[i + 1].start) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + cpsDuration;
          } else if (eventGroup.events[i + 1].start - endGap - cpsDuration >= eventGroup.events[i - 1].end) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - cpsDuration;
          } else {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = maxDuration;
          }
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = Math.min(eventGroup.events[i - 1].end + cpsDuration + cpsDuration * 0.15, eventGroup.events[i + 1].start);
        }
      }
      ;
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 2------------------- */
    //Stage 2 looks at SDH events with missing timecodes with events on either side with timecode.
    eventGroup.events.forEach((event, i) => {
      // Calculate start and end time for sdh events (events where plainText is empty) and there is an event before and after. 
      if (!event.end && eventGroup.events[i - 1] && eventGroup.events[i - 1].end && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10002;
        let oDuration = event.oDuration;
        let cpsDuration = convertToPlainText(event.text, " ").length / minCps; //duration is based on a cps of 25.
        let possibleDuration = Math.max(0, eventGroup.events[i + 1].start - eventGroup.events[i - 1].end);
        let startGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
        let endGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
        if (possibleDuration <= cpsDuration + cpsDuration * 0.25) {
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
        } else if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (eventGroup.events[i - 1].end + startGap + oDuration <= eventGroup.events[i + 1].start) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + oDuration;
          } else if (eventGroup.events[i + 1].start - endGap - oDuration >= eventGroup.events[i - 1].end) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - oDuration;
          } else if (eventGroup.events[i - 1].end + startGap + cpsDuration <= eventGroup.events[i + 1].start) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + cpsDuration;
          } else if (eventGroup.events[i + 1].start - endGap - cpsDuration >= eventGroup.events[i - 1].end) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - cpsDuration;
          } else {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = maxDuration;
          }
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = Math.min(eventGroup.events[i - 1].end + cpsDuration + cpsDuration * 0.15, eventGroup.events[i + 1].start);
        }
      }
      ;
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 3------------------- */
    //Stage 3 looks at non-sdh events with missing timecodes with an event after if the Event is a Stage 1 or 2 or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i + 1] && eventGroup.events[i + 1].start && eventGroup.events[i].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10003;
        let oDuration = event.oDuration;
        let cpsDuration = event.plainText.length / minCps; //duration is based on a cps of 25.
        let endGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (endGap < 3 && eventGroup.events[i + 1].start - endGap - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - oDuration;
          } else if (eventGroup.events[i + 1].start - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - oDuration;
          } else {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = Math.max(0, eventGroup.events[i + 1].start - Math.max(minDuration, possibleDuration));
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(eventGroup.events[i].end - cpsDuration, 0);
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    /* -------------------STAGE 4------------------- */
    //Stage 4 looks at non-sdh events with missing timecodes with an event before that was stage 1, 2, or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i - 1] && eventGroup.events[i - 1].end && eventGroup.events[i - 1].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10004;
        let oDuration = event.oDuration;
        let cpsDuration = event.plainText.length / minCps; //duration is based on a cps of 25.
        let startGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (startGap < 3 && eventGroup.events[i - 1].end + startGap + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + oDuration;
          } else if (eventGroup.events[i - 1].end + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + oDuration;
          } else {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + Math.max(minDuration, possibleDuration);
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i - 1].end + cpsDuration;
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    /* -------------------STAGE 5------------------- */
    //Stage 5 looks at sdh events with missing timecodes with an event after that was a stage 1, 2, or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && eventGroup.events[i + 1] && eventGroup.events[i + 1].start && eventGroup.events[i].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10005;
        let oDuration = event.oDuration;
        let cpsDuration = convertToPlainText(event.text, " ").length / minCps; //duration is based on a cps of 25.
        let endGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (endGap < 3 && eventGroup.events[i + 1].start - endGap - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - oDuration;
          } else if (eventGroup.events[i + 1].start - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - oDuration;
          } else {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = Math.max(0, eventGroup.events[i + 1].start - Math.max(minDuration, possibleDuration));
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(eventGroup.events[i].end - cpsDuration, 0);
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 6------------------- */
    //Stage 6 looks at sdh events with missing timecodes with an event before that were a stage 1, 2, or 3 match.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && eventGroup.events[i - 1] && eventGroup.events[i - 1].start && eventGroup.events[i - 1].stage < 10000) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10006;
        let oDuration = event.oDuration;
        let cpsDuration = convertToPlainText(event.text, " ").length / minCps; //duration is based on a cps of 25.
        let startGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (startGap < 3 && eventGroup.events[i - 1].end + startGap + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + oDuration;
          } else if (eventGroup.events[i - 1].end + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + oDuration;
          } else {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + Math.max(minDuration, possibleDuration);
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i - 1].end + cpsDuration;
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 7------------------- */
    //Stage 7 looks at non-sdh events with missing timecodes with an event after.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10007;
        let oDuration = event.oDuration;
        let cpsDuration = event.plainText.length / minCps; //duration is based on a cps of 25.
        let endGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (endGap < 3 && eventGroup.events[i + 1].start - endGap - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - oDuration;
          } else if (eventGroup.events[i + 1].start - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - oDuration;
          } else {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = Math.max(0, eventGroup.events[i + 1].start - Math.max(minDuration, possibleDuration));
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(eventGroup.events[i].end - cpsDuration, 0);
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 8------------------- */
    //Stage 8 looks at non-sdh events with missing timecodes with an event before.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && event.plainText && eventGroup.events[i - 1] && eventGroup.events[i - 1].end) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10008;
        let oDuration = event.oDuration;
        let cpsDuration = event.plainText.length / minCps; //duration is based on a cps of 25.
        let startGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (startGap < 3 && eventGroup.events[i - 1].end + startGap + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + oDuration;
          } else if (eventGroup.events[i - 1].end + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + oDuration;
          } else {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + Math.max(minDuration, possibleDuration);
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i - 1].end + cpsDuration;
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    /* -------------------STAGE 9------------------- */
    //Stage 9 looks at sdh events with missing timecodes with an event after.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && !event.plainText && eventGroup.events[i + 1] && eventGroup.events[i + 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10009;
        let oDuration = event.oDuration;
        let cpsDuration = convertToPlainText(event.text, " ").length / minCps; //duration is based on a cps of 25.
        let endGap = Math.max(0, eventGroup.events[i + 1].oStart - event.oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (endGap < 3 && eventGroup.events[i + 1].start - endGap - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start - endGap;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - endGap - oDuration;
          } else if (eventGroup.events[i + 1].start - oDuration >= eventGroup.events[i + 1].start - possibleDuration) {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = eventGroup.events[i + 1].start - oDuration;
          } else {
            eventGroup.events[i].end = eventGroup.events[i + 1].start;
            eventGroup.events[i].start = Math.max(0, eventGroup.events[i + 1].start - Math.max(minDuration, possibleDuration));
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].end = eventGroup.events[i + 1].start;
          eventGroup.events[i].start = Math.max(eventGroup.events[i].end - cpsDuration, 0);
        }
      }
    });

    //log the number of events where end is falsy
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);

    /* -------------------STAGE 10------------------- */
    //Stage 10 looks at sdh events with missing timecodes with an event before.
    eventGroup.events.forEach((event, i) => {
      if (!event.end && eventGroup.events[i - 1] && eventGroup.events[i - 1].start) {
        //Fail the event for CC Creator.
        eventGroup.events[i].approved = false;
        eventGroup.events[i].stage = 10010;
        let oDuration = event.oDuration;
        let cpsDuration = convertToPlainText(event.text, " ").length / minCps; //duration is based on a cps of 25.
        let startGap = Math.max(0, event.oStart - eventGroup.events[i - 1].oEnd);
        let {
          nextEventIndexWithTimecodes,
          prevEventIndexWithTimecodes,
          nextEventStartTime,
          prevEventEndTime,
          eventGapSeconds,
          eventGapCount
        } = findTimecodesBoundaries(eventGroup.events, i);

        // console.log("NEXT EVENT INDEX WITH TIMECODES:" + nextEventIndexWithTimecodes);
        // console.log("PREV EVENT INDEX WITH TIMECODES:" + prevEventIndexWithTimecodes);
        // console.log("NEXT EVENT START TIME:" + nextEventStartTime);
        // console.log("PREV EVENT END TIME:" + prevEventEndTime);
        // console.log("EVENT GAP SECONDS:" + eventGapSeconds);
        // console.log("EVENT GAP COUNT:" + eventGapCount);

        let possibleDuration = (nextEventStartTime - prevEventEndTime) / eventGapCount;
        // console.log("POSSIBLE DURATION:" + possibleDuration);

        if (captionFileTimings) {
          if (oDuration < cpsDuration) {
            oDuration = cpsDuration;
          }
          if (oDuration < minDuration) {
            oDuration = minDuration;
          }
          if (oDuration > maxDuration) {
            oDuration = maxDuration;
          }
          if (startGap < 3 && eventGroup.events[i - 1].end + startGap + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end + startGap;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + startGap + oDuration;
          } else if (eventGroup.events[i - 1].end + oDuration <= eventGroup.events[i - 1].end + possibleDuration) {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + oDuration;
          } else {
            eventGroup.events[i].start = eventGroup.events[i - 1].end;
            eventGroup.events[i].end = eventGroup.events[i - 1].end + Math.max(minDuration, possibleDuration);
          }
        } else {
          if (cpsDuration < minDuration) {
            cpsDuration = minDuration;
          }
          if (cpsDuration > maxDuration) {
            cpsDuration = Math.min(possibleDuration, maxDuration);
          }
          eventGroup.events[i].start = eventGroup.events[i - 1].end;
          eventGroup.events[i].end = eventGroup.events[i - 1].end + cpsDuration;
        }
      }
    });
    console.log("Number of events with missing end timecodes: ", eventGroup.events.filter(event => !event.end).length, "/", eventGroup.events.length);
    let eventWithMissingEnd = eventGroup.events.find(event => !event.end);
    if (!eventWithMissingEnd) {
      processing = false;
    } else {
      /* eventGroup.events.forEach((event, i) => {
          if (!event.end) {
              console.log(`-----${i}-------`);
              console.log("Event with missing end timecode: ", event.text);
          }
      }); */
    }
  }
  return eventGroup;
});
/**
 * Finds the surrounding events with timecodes and calculates gap information
 * @param {Array<{start: number|false, end: number|false, text: string}>} captions 
 * @param {number} currentIndex - Index of the caption we're analyzing
 * @returns {{
*   nextEventIndexWithTimecodes: number|null,
*   prevEventIndexWithTimecodes: number|null,
*   nextEventStartTime: number|null,
*   prevEventEndTime: number|null,
*   eventGapSeconds: number|null,
*   eventGapCount: number|null
* }}
*/
function findTimecodesBoundaries(captions, currentIndex) {
  // Default return object with null values
  const result = {
    nextEventIndexWithTimecodes: null,
    prevEventIndexWithTimecodes: null,
    nextEventStartTime: null,
    prevEventEndTime: null,
    eventGapSeconds: null,
    eventGapCount: null
  };

  // Validate inputs
  if (!Array.isArray(captions) || currentIndex < 0 || currentIndex >= captions.length) {
    return result;
  }

  // Find next event with timecodes
  let nextIndex = currentIndex + 1;
  while (nextIndex < captions.length) {
    if (captions[nextIndex].start !== false && captions[nextIndex].end !== false) {
      result.nextEventIndexWithTimecodes = nextIndex;
      result.nextEventStartTime = captions[nextIndex].start;
      break;
    }
    nextIndex++;
  }

  // Find previous event with timecodes
  let prevIndex = currentIndex - 1;
  while (prevIndex >= 0) {
    if (captions[prevIndex].start !== false && captions[prevIndex].end !== false) {
      result.prevEventIndexWithTimecodes = prevIndex;
      result.prevEventEndTime = captions[prevIndex].end;
      break;
    }
    prevIndex--;
  }

  // Calculate gap information if we found both boundaries
  if (result.nextEventStartTime !== null && result.prevEventEndTime !== null) {
    result.eventGapSeconds = result.nextEventStartTime - result.prevEventEndTime;
    result.eventGapCount = result.nextEventIndexWithTimecodes - result.prevEventIndexWithTimecodes - 1;
  }
  return result;
}