import _xmlFormat from "xml-formatter";
import _formats from "../lib/formats.js";
import _subtractIncode from "./special/subtractIncode.js";
import _convertSpeakersToObject from "./eventGroups/convertSpeakersToObject.js";
import _decodeHtmlEvg from "./eventGroups/decodeHtmlEvg.js";
import _applyWindowOffsets from "./special/applyWindowOffsets.js";
import _defaults from "../lib/defaults.js";
import _decoder from "../profiles/index.js";
import _EventGroup from "../classes/eventGroup.js";
const EventGroup = _EventGroup;
const decoder = _decoder;
const defaults = _defaults;
const applyWindowOffsets = _applyWindowOffsets;
const decodeHtmlEvg = _decodeHtmlEvg;
const convertSpeakersToObject = _convertSpeakersToObject;
const subtractIncode = _subtractIncode;
const formats = _formats;
const xmlFormat = _xmlFormat;
export default (async function (input, options = new defaults.options()) {
  if (decoder[options.profile]) {
    let eventGroup = new EventGroup();
    if (options.profile && ["xml", "ttml", "dfxp", "itt"].indexOf(formats.defaultExtMap[options.profile]) > -1) {
      //Remove everything before the <?xml tag
      input = input.substring(input.indexOf("<?xml"));
      /* Replace tt:tt with tt */
      input = input.replace(/tt:tt/g, "tt");
      /* Replace tt:head with head */
      input = input.replace(/tt:head/g, "head");
      /* Replace tt:body with body */
      input = input.replace(/tt:body/g, "body");
      /* Replace tt:div with div */
      input = input.replace(/tt:div/g, "div");
      /* Replace tt:p with p */
      input = input.replace(/tt:p/g, "p");
      /* Replace tt:span with span */
      input = input.replace(/tt:span/g, "span");
      /* Replace tt:br with br */
      input = input.replace(/tt:br/g, "br");
      /* Replace tt:style with style */
      input = input.replace(/tt:style/g, "style");
      /* Replace tt:layout with layout */
      input = input.replace(/tt:layout/g, "layout");
      /* Replace tt:region with region */
      input = input.replace(/tt:region/g, "region");
      /* Replace tt:metadata with metadata */
      input = input.replace(/tt:metadata/g, "metadata");
      /* Replace tt:styling with styling */
      input = input.replace(/tt:styling/g, "styling");
      input = xmlFormat.minify(input, {
        collapseContent: true
      });
    }
    /* Pre-Process Decode */
    input = decoder[options.profile].preProcess.decode(input, options);
    /* Decode */
    eventGroup.events = await decoder[options.profile].decode(input, options);
    /* Post-Process Decode */
    eventGroup = decoder[options.profile].postProcess.decode(eventGroup, options);
    /* Decode HTML */
    eventGroup = decodeHtmlEvg(eventGroup);
    /* Converter Speakers to Speaker Objects */
    eventGroup = convertSpeakersToObject(eventGroup);
    /* Remove incode from start/end times */
    eventGroup = subtractIncode(eventGroup, options);
    /* Apply Window Offset and Return Event Group */
    eventGroup = applyWindowOffsets(eventGroup, options.window, "subtract");
    //Return Event Group
    return eventGroup;
  } else {
    throw new Error(`Source profile [${options.profile}] does not exist. Please ensure name matches a supported decoding profile. Profile names are case-sensitive.`);
  }
});