import _Media from "../../classes/media.js";
const Media = _Media;
export default (function loadMedia(project, mediaInfo) {
  try {
    if (mediaInfo.storage === "Local Storage") {
      const URL = window.URL || window.webkitURL;
      let mediaFile = mediaInfo.files[0];
      mediaInfo.type = mediaFile.type;
      mediaInfo.localPath = mediaFile.path, mediaInfo.audioPath = URL.createObjectURL(mediaFile), mediaInfo.path = mediaInfo.audioPath;
      mediaInfo.name = mediaFile.name;
    } else if (mediaInfo.storage === "Proxy RT") {
      let mediaFile = mediaInfo.files[0];
      mediaInfo.type = mediaFile.type;
      mediaInfo.localPath = mediaFile.path, mediaInfo.audioPath = mediaFile.path, mediaInfo.path = "";
      mediaInfo.name = mediaFile.name;
    } else {
      mediaInfo.type = "video/mp4";
      mediaInfo.path = mediaInfo.mediaUrl;
      mediaInfo.localPath = mediaInfo.mediaUrl;
      mediaInfo.audioPath = mediaInfo.mediaUrl;
      mediaInfo.name = mediaInfo.projectName;
    }
    project.media = new Media(mediaInfo);
  } catch (err) {
    console.log(err, err.message);
  } finally {
    return project;
  }
});