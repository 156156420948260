import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _eol from "eol";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const eol = _eol;
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
export default {
  decode: function (input, options) {
    let events = [],
      displays = [];
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(JSON.stringify(fileJson, null, 4));
    fileJson.tt.body.div.p.forEach(paragraph => {
      let ccDisplay = new ttmlFunc.singleLine.Display();
      let start = tcLib.tcClockToSec(paragraph["@_begin"], options.frameRate);
      let end = tcLib.tcClockToSec(paragraph["@_end"], options.frameRate);
      let original = paragraph["#text"];
      let text = convertToPlainText(original);
      let html = ttmlFunc.multiLine.formatText(paragraph["#text"]);
      let originX, originY, extentX, extentY;
      if (paragraph["@_tts:origin"]) {
        originX = paragraph["@_tts:origin"].split(" ")[0].replace("%", "");
        originY = paragraph["@_tts:origin"].split(" ")[1].replace("%", "");
      }
      if (paragraph["@_tts:extent"]) {
        extentX = paragraph["@_tts:extent"].split(" ")[0].replace("%", "");
        extentY = paragraph["@_tts:extent"].split(" ")[1].replace("%", "");
      }
      if (displays.length > 0 && displays[displays.length - 1].start === start) {
        displays[displays.length - 1].insertLine({
          text: text,
          html: html,
          original: original,
          extentX: extentX,
          extentY: extentY,
          originX: originX,
          originY: originY
        });
      } else {
        ccDisplay.start = start;
        ccDisplay.end = end;
        ccDisplay.insertLine({
          text: text,
          html: html,
          original: original,
          extentX: extentX,
          extentY: extentY,
          originX: originX,
          originY: originY
        });
        displays.push(ccDisplay);
      }
    });

    //console.log(JSON.stringify(displays, null, 4));
    displays.forEach(display => {
      events.push(ttmlFunc.singleLine.decodeDisplay(display, options.window));
    });
    //console.log(JSON.stringify(events, null, 4));
    return events;
  },
  encode: function (eventGroup, options) {
    let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
    output += eol.after(`<tt xml:lang="en"
        xmlns="http://www.w3.org/ns/ttml"
        xmlns:tts="http://www.w3.org/ns/ttml#styling"
        xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        xmlns:ttp="http://www.w3.org/ns/ttml#parameter"
        ttp:profile="http://www.netflix.com/ns/ttml/profile/NFTT-CC"
        ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>
        <ttm:title>Untitled</ttm:title>
        <ttm:desc>Closed Caption Converter - Netflix TT Captions (4x3)</ttm:desc>
        <smpte:information
         xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
         origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
         mode="Preserved" m608:channel="CC1" m608:programName="Untitled" m608:captionService="F1C1CC"
        />
       </metadata>`);
    output += eol.after(`<styling>
       <style xml:id='basic' tts:color='white' tts:backgroundColor='transparent' tts:fontFamily='monospace' tts:fontSize='100%' tts:fontWeight='normal' tts:fontStyle='normal'/>
      </styling>`);
    output += eol.after(`<layout>
        <region xml:id='pop1' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='pop2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='pop3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='pop4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='rollup2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='rollup3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='rollup4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
       </layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div>`);
    eventGroup.events.forEach(event => {
      let start = tcLib.secToTcClock(event.start, options.frameRate);
      let end = tcLib.secToTcClock(event.end, options.frameRate);
      let plainTextCustom = convertToPlainTextCustom(event.text);
      eol.split(plainTextCustom).forEach((textLine, index, textLines) => {
        let extents = ttmlFunc.singleLine.calcExtents(textLine);
        let origins = ttmlFunc.singleLine.calcOrigin(event, textLine, index, textLines.length, 80, options.window);
        output += eol.after(`<p region='pop${index + 1}' style='basic' begin='${start}' end='${end}' tts:origin='${origins}' tts:extent='${extents}'>${ttmlFunc.singleLine.convertToTtml(textLine)}</p>`);
      });
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += `</tt>`;
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};