<script>
    import {
        eventGroupState
    } from '@app/store/eventGroupStore.js';
    import {
        projectState
    } from '@app/store/projectStore.js';
    import {
        lockState
    } from '@app/store/lockStore.js';
    import {
        styleState
    } from '@app/store/styleStore.js';
    import {
        onMount,
        onDestroy,
        tick
    } from 'svelte';
    
    let eventIndex, audioPlayer, eventListElement = document.getElementById("EventList");
    
    onMount(() => {
        try {
            audioPlayer = document.getElementById("adPlayer");
            player.on("seeked", loadAndPlaySource);
            player.on("pause", pauseAudioPlayer);
            player.on("timeupdate", cueAndPlayAudio);
        } catch (err) {
            console.log(err, err.message);
        }
    });
    
    onDestroy(() => {
        player.off("seeked", loadAndPlaySource);
        player.off("pause", pauseAudioPlayer);
        player.off("timeupdate", cueAndPlayAudio);
    });
    
    function loadAndPlaySource() {
        eventIndex = getEventByTime(player.currentTime);
        loadSource(eventIndex, true, player.playing);
    }
    
    function pauseAudioPlayer() {
        try {
            audioPlayer.pause();
        } catch (err) {
            console.log(err.message);
        }
    }
    
    async function cueAndPlayAudio() {
        if (player.playing) {
            //console.log("Player is playing.");
            let newEventIndex = getEventByTime(player.currentTime);
            //console.log(`New event index ${newEventIndex} current event index ${eventIndex}`);
            if (newEventIndex === -1){
                //console.log("No event");
                audioPlayer.src = "";
                audioPlayer.load();
                eventIndex = undefined;
            } else if (newEventIndex !== eventIndex) {
                //console.log("Loading source...");
                loadSource(eventIndex = newEventIndex, false, true);
                if ($lockState.caption && eventIndex > -1) {
                    $eventGroupState[$projectState.selected].selected = [eventIndex];
                    await tick();
                    if (!document.getElementById("QuillEditor")) {
                        try {
                            eventListElement.scrollTo(0, (eventIndex - 1) * 230);
                        } catch (err) {
                            eventListElement = document.getElementById("EventList");
                            eventListElement.scrollTo(0, (eventIndex - 1) * 230);
                        }                        
                    }
                }
            } else if (newEventIndex > -1 && $eventGroupState[$projectState.selected].events[newEventIndex].audioFile) {
                if (player.currentTime <= $eventGroupState[$projectState.selected].events[newEventIndex].start + $eventGroupState[$projectState.selected].events[newEventIndex].audioFileDuration){
                    //console.log("Playing audio Player");
                    try {
                        await audioPlayer.play();
                    } catch(err){
                        console.log("CueAndPlay Error:" + err.message);
                    }   
                } else {
                    audioPlayer.pause();
                }
                
            }
        }
    }
    
    async function playAudio() {
        try {
            await audioPlayer.play();
        } catch (err) {
            console.log(err.message);
        }
    }
    
    function cueToTime(videoCurrentTime, startTime) {
        audioPlayer.currentTime = videoCurrentTime - startTime;
    }
    
    function loadSource(index, cue, play) {
        if (index > -1 && $eventGroupState[$projectState.selected].events[index].audioFile !== "") {
            audioPlayer.src = $eventGroupState[$projectState.selected].events[index].audioFile;
            audioPlayer.load();
            cue ? cueToTime(player.currentTime, $eventGroupState[$projectState.selected].events[index].start) : null;
            play ? playAudio() : null;
        } else {
            try { 
                audioPlayer.pause();
                audioPlayer.removeAttribute('src');
            } catch(err){
                console.log("Load Source Error: "+err.message);
            }
            
        }
    }
    
    function getEventByTime(sec){
        return $eventGroupState[$projectState.selected].events.findIndex(event=>{
            return event.start <= sec && event.end >= sec;
        });
    }
    
    function decToHex(value){
        return Math.floor((value/100)*255).toString(16).padStart(2, "0");
    }
    
    $: eventIndex = $eventGroupState[$projectState.selected].selected[0];
    </script>
    {#if $lockState.preview && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events[eventIndex]}
    <div id="SubtitlePreviewWrapper" class="d-flex justify-content-start align-items-end">
        <div id="SubtitlePreview"
            style="direction: {$eventGroupState[$projectState.selected].rtl ? 'rtl' : 'ltr'}; font-family: {$styleState.mode ? 'plexMono' : $styleState.fontFamily}; font-size: {$styleState.mode ? '16' : $styleState.fontSize}px; color: {$styleState.mode ? '#fff' : $styleState.color+decToHex($styleState.opacity)}; line-height: {$styleState.mode ? 100 : $styleState.lineSpacing}%; letter-spacing: {$styleState.mode ? `0.59ex` : `${$styleState.spacing}px`}; {$styleState.borderStyle == "1" ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor+decToHex($styleState.shadowOpacity)}; -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor+decToHex($styleState.outlineOpacity)}; background: none;` : `background: ${$styleState.mode ? 'black' : $styleState.outlineColor+decToHex($styleState.outlineOpacity)}; 
            padding: ${$styleState.mode ? 0 : $styleState.outline}px`};">
            {@html $eventGroupState[$projectState.selected].events[eventIndex].text}
        </div>
    </div>
    {/if}
    
    <style>
    #SubtitlePreviewWrapper {
        position: absolute;
        pointer-events: none;
        overflow: hidden;
        height: 100%;
        width: 100%;
        top: 0;
    }
    
    #SubtitlePreview {
        user-select: none;
    }
    </style>
    