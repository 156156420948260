<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import EventGroupIcons from '@app/components/editor/EventGroupIcons.svelte';

export let eventGroupIndex = 0;
export let height = 40;
export let labelId = "";
</script>

<div 
    class="text-center border-bottom border-secondary d-flex align-items-center justify-content-center p-3" 
    style="height: {height}px; font-size: 1vw"
    role="region"
    id={labelId}
    aria-label={`Track ${eventGroupIndex + 1}`}>
    {#if $eventGroupState[eventGroupIndex]}
        <EventGroupIcons 
            type="{$eventGroupState[eventGroupIndex].type}"
            aria-label={`Track ${eventGroupIndex + 1} type: ${$eventGroupState[eventGroupIndex].type}`}>
        </EventGroupIcons>
    {/if}
</div>