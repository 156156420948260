import _convertToHtml from "../functions/quill/convertToHtml.js";
import _eol from "eol";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const getFormatOptions = _getFormatOptions;
const eol = _eol;
const convertToHtml = _convertToHtml;
/* Format Notes */
/* 
    - Ruby data is stored in formatting object
    - iOrientation we believe is the left or right vertical
    - Format Ids
               
        - 1 = Ruby related ?
        - 2 = ruby ?
        - 3 = Ruby related ?
        - 6 = Ruby related ?
        - 8 = Italics ? 
        - 10 = Bouten ?
        - 12 = Bouton ?
        - 13 = Ruby related ?
        - 14 = Ruby related ?
        - 15 = Ruby related ?        
        - 16 = Ruby related ?
        
        
*/
export default {
  decode: function (input, options) {
    let events = [],
      event,
      fileJson = JSON.parse(input),
      track = "all",
      decodingOptions = getFormatOptions(options.formatOptions);
    if (decodingOptions["Track"]) {
      track = decodingOptions["Track"].toLowerCase();
    }
    fileJson.Track.data.forEach(dataTrack => {
      if (track === "a" && dataTrack.iTrack !== 0 || track === "b" && dataTrack.iTrack !== 1) {
        return;
      }

      /* Collect formatting data */
      dataTrack.formatting = fileJson.Format.data.filter(formatData => {
        return formatData.iSubtitleID === dataTrack.iID;
      });
      let text = dataTrack.strTranslation;
      let matches = text.match(/･+/g);
      let transformations = [];
      if (matches) {
        matches.forEach(matchRes => {
          let index = text.search(/･+/);
          text = text.slice(0, index - matchRes.length) + "<strong>" + text.slice(index - matchRes.length, index) + "</strong>" + text.slice(index + matchRes.length);
          transformations.push({
            start: index - matchRes.length,
            end: index,
            startValue: 8,
            endValue: 17 - matchRes.length
          });
        });
      }
      dataTrack.formatting.forEach(formatInfo => {
        if (matches) {
          return;
        }
        if (formatInfo.iFormat === 2 && formatInfo.strParam.length > 0) {
          text = text.slice(0, formatInfo.iTo + 1) + `(${formatInfo.strParam})` + text.slice(formatInfo.iTo + 1);
          transformations.push({
            start: formatInfo.iTo + 1,
            end: formatInfo.iTo + 1,
            startValue: formatInfo.strParam.length + 2,
            endValue: formatInfo.strParam.length + 2
          });
        } else if (formatInfo.iFormat === 8) {
          text = text.slice(0, formatInfo.iFrom) + "<em>" + text.slice(formatInfo.iFrom, formatInfo.iTo + 1) + "</em>" + text.slice(formatInfo.iTo + 1);
          transformations.push({
            start: formatInfo.iFrom,
            end: formatInfo.iTo + 1,
            startValue: 4,
            endValue: 9
          });
        }
      });
      event = new Event({
        text: convertToHtml(eol.lf(text)),
        start: tcLib.framesToSec(dataTrack.iInFrame, options.frameRate),
        end: tcLib.framesToSec(dataTrack.iOutFrame, options.frameRate),
        yPos: dataTrack.iOrientation === 0 ? "end" : "start",
        xPos: dataTrack.iOrientation === 0 ? "center" : "start",
        xOffset: 0,
        yOffset: dataTrack.iOrientation === 0 ? options.window.height * -0.10 : options.window.height * 0.10,
        notes: dataTrack.strComment,
        vertical: dataTrack.iOrientation !== 0
      });
      events.push(event);
    });

    //console.log(events);
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Canvass SST Project Files are not support for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};