import _balanceTextOld from "../utility/balanceTextOld.js";
import _balanceText from "../utility/balanceText.js";
import _closeHtmlTags from "../quill/closeHtmlTags.js";
import _convertToPlainText from "../quill/convertToPlainText.js";
import _convertToHtml from "../quill/convertToHtml.js";
import _Event from "../../classes/event.js";
const Event = _Event;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const closeHtmlTags = _closeHtmlTags;
const balanceText = _balanceText;
const balanceTextOld = _balanceTextOld;
const speakerTags = ["-", "—", "[", ">", "(", "¿"];
export default (function autoFormat(eventGroup, maxLines, maxChars, minDuration, allowOrphanWords, selectedEvents = null) {
  let events = [];
  eventGroup.events.forEach((ccEvent, count) => {
    if (!selectedEvents || selectedEvents.indexOf(count) > -1) {
      //console.log(`Working on event ${count}`);
      let textLines;
      let plainText = convertToPlainText(ccEvent.text, " ");
      if (hasSpeakerTag(plainText)) {
        textLines = balanceText(ccEvent.text, maxChars, maxLines);
      } else {
        textLines = balanceTextOld(ccEvent.text, maxChars, maxLines);
      }

      // console.log(textLines);
      let numberOfEvents = Math.ceil(textLines.length / maxLines);
      //console.log(`There are ${numberOfEvents} events required for formatting of text ${textLines}`);
      let avgDuration = Math.max(minDuration, (ccEvent.end - ccEvent.start) / numberOfEvents);
      ccEvent.text = textLines.splice(0, maxLines).join("\n");
      if (!allowOrphanWords && textLines.length === 1 && textLines[0].split(" ").length === 1) {
        ccEvent.text += " " + textLines[0];
        numberOfEvents = 1;
      }
      ccEvent.text = closeHtmlTags(ccEvent.text);
      ccEvent.text = convertToHtml(ccEvent.text);
      if (numberOfEvents > 1) {
        ccEvent.end = ccEvent.start + avgDuration;
        events.push(ccEvent);
        while (textLines.length > 0) {
          let t = textLines.splice(0, maxLines).join("\n");
          if (!allowOrphanWords && textLines.length === 1 && textLines[0].split(" ").length === 1) {
            t += " " + textLines[0];
            textLines = [];
          }
          events.push(new Event({
            ...ccEvent,
            text: convertToHtml(closeHtmlTags(t)),
            start: events[events.length - 1].end,
            end: events[events.length - 1].end + avgDuration
          }));
        }
      } else {
        events.push(ccEvent);
      }
    } else {
      events.push(ccEvent);
    }
  });
  eventGroup.events = events;
  return eventGroup;
});
function hasSpeakerTag(text) {
  const speakerTags = ["-", "—", "[", ">", "(", "¿"];

  // Escape special regex characters and join with |
  const escapedTags = speakerTags.map(tag => tag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');

  // Create regex pattern that matches any tag at the start of a word
  // \b: word boundary
  // (?=\w): positive lookahead for a word character
  const pattern = new RegExp(`(?:^|\\s)(${escapedTags})(?=\\w)`, '');
  return pattern.test(text);
}