<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    fade
} from 'svelte/transition';
import {
    BarLoader
} from 'svelte-loading-spinners';

let userDefaults = JSON.parse(localStorage.getItem("cc-paste-attribute-defaults")) || {};

let eventInfo,
    text = userDefaults.text,
    metadata = userDefaults.metadata,
    voice = userDefaults.voice,
    style = userDefaults.style,
    position = userDefaults.position,
    tags = userDefaults.tags,
    speakers = userDefaults.speakers,
    notes = userDefaults.notes;

async function getEventInfo(){
    try {
        let clipboardtext = await navigator.clipboard.readText();
        let eventJson = JSON.parse(clipboardtext);
        if (Array.isArray(eventJson) && eventJson[0].id && eventJson[0].xPos && eventJson[0].yPos){
            eventInfo = eventJson[0];
            return eventJson[0];
        } else {
            throw new Error("Clipboard does not contain Event Info");
        }
    } catch(err){
        console.log(err.message);
        throw new Error("Clipboard does not contain Event Info");
    }    
}

function selectAll(){
    text = true;
    metadata = true;
    voice = true;
    style = true;
    position = true;
    tags = true;
    speakers = true;
    notes = true;
}

function selectInverse(){
    text = !text;
    metadata = !metadata;
    voice = !voice;
    style = !style;
    position = !position;
    tags = !tags;
    speakers = !speakers;
    notes = !notes;
}

function pasteAttributes(){    
    try {
        $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
            if (text){
                $eventGroupState[$projectState.selected].events[eventIndex].text = eventInfo.text || "<p></p>";
                $eventGroupState[$projectState.selected].events[eventIndex].original = eventInfo.original;
                $eventGroupState[$projectState.selected].events[eventIndex].audioFile = "";
                $eventGroupState[$projectState.selected].events[eventIndex].audioFileDuration = false;
            }

            if (metadata){
                $eventGroupState[$projectState.selected].events[eventIndex].metadata = eventInfo.metadata || [];
            }

            if (voice){
                $eventGroupState[$projectState.selected].events[eventIndex].voice = eventInfo.voice;
                $eventGroupState[$projectState.selected].events[eventIndex].speakingStyle = eventInfo.speakingStyle;
                $eventGroupState[$projectState.selected].events[eventIndex].extended = eventInfo.extended;
                $eventGroupState[$projectState.selected].events[eventIndex].rate = eventInfo.rate;
                // New For ElevenLabs
                $eventGroupState[$projectState.selected].events[eventIndex].voiceStability = eventInfo.voiceStability;
                $eventGroupState[$projectState.selected].events[eventIndex].voiceSimilarity = eventInfo.voiceSimilarity;
                $eventGroupState[$projectState.selected].events[eventIndex].rendered = false;
            }

            if (style){
                $eventGroupState[$projectState.selected].events[eventIndex].style = eventInfo.style || "Pop-On";
                $eventGroupState[$projectState.selected].events[eventIndex].forced = eventInfo.forced;
            }

            if (position){
                $eventGroupState[$projectState.selected].events[eventIndex].xPos = eventInfo.xPos || "center";
                $eventGroupState[$projectState.selected].events[eventIndex].yPos = eventInfo.yPos || "end";
                $eventGroupState[$projectState.selected].events[eventIndex].xOffset = eventInfo.xOffset || 0;
                $eventGroupState[$projectState.selected].events[eventIndex].yOffset = eventInfo.yOffset || 0;
                $eventGroupState[$projectState.selected].events[eventIndex].alignment = eventInfo.alignment || "center";
                $eventGroupState[$projectState.selected].events[eventIndex].vertical = eventInfo.vertical;
            }

            if (tags){
                $eventGroupState[$projectState.selected].events[eventIndex].tags = eventInfo.tags || [];
            }

            if (speakers){
                $eventGroupState[$projectState.selected].events[eventIndex].speakers = eventInfo.speakers || [];
            }

            if (notes){
                $eventGroupState[$projectState.selected].events[eventIndex].notes = eventInfo.notes;
                $eventGroupState[$projectState.selected].events[eventIndex].reply = eventInfo.reply;
                $eventGroupState[$projectState.selected].events[eventIndex].notesCheck = eventInfo.notesCheck;
                $eventGroupState[$projectState.selected].events[eventIndex].replyCheck = eventInfo.replyCheck;
            }
        });

        $eventGroupState[$projectState.selected].selected = [];

        toast.push("Attributes have been pasted successfully", {classes: ['toast-success']});

        historyState.insert({
            name: "paste attributes", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        localStorage.setItem("cc-paste-attribute-defaults", JSON.stringify({
            text: text,
            metadata : metadata,
            voice : voice,
            style : style,
            position : position,
            tags : tags,
            speakers : speakers,
            notes : notes
        }));


    } catch(err){
        console.log(err.message);
        toast.push(`Attributes failed to paste. ${err.message}`, {classes: ['toast-danger']});
    } finally {
        modalState.hideModal();
    }
}

</script>
    
    <div transition:fade="{{duration: 100}}" class="modal {$modalState === 'pasteAttributes' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="PasteAttributesModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h5 class="modal-title">Paste Attributes</h5>
              <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                {#await getEventInfo()}
                    <div class="d-flex justify-content-center align-items-center">
                        <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
                    </div>   
                {:then}
                <div class="btn-group btn-group-sm float-end" role="group">
                    <button class="btn btn-light" type="button" on:click={() => {selectAll()}}>Select All</button>
                    <button class="btn btn-light" type="button" on:click={() => {selectInverse()}}>Select Inverse</button>
                </div>
                <p>Attributes</p>
                <form>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="textCheckInput" bind:checked={text}><label class="form-check-label" for="textCheckInput">Text</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check"><input class="form-check-input" type="checkbox" id="positionCheckInput" bind:checked={position}><label class="form-check-label" for="positionCheckInput">Position</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check"><input class="form-check-input" type="checkbox" id="metadataCheckInput" bind:checked={metadata}><label class="form-check-label" for="metadataCheckInput">Metadata</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check"><input class="form-check-input" type="checkbox" id="tagsCheckInput" bind:checked={tags}><label class="form-check-label" for="tagsCheckInput">Tags</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check text-nowrap"><input class="form-check-input" type="checkbox" id="syntheticCheckInput" bind:checked={voice}><label class="form-check-label" for="syntheticCheckInput">Synthetic Voice</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check"><input class="form-check-input" type="checkbox" id="speakerCheckInput" bind:checked={speakers}><label class="form-check-label" for="speakerCheckInput">Speakers</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check"><input class="form-check-input" type="checkbox" id="styleCheckInput" bind:checked={style}><label class="form-check-label" for="styleCheckInput">Style</label></div>
                        </div>
                        <div class="col-6">
                            <div class="form-check"><input class="form-check-input" type="checkbox" id="notesCheckInput" bind:checked={notes}><label class="form-check-label" for="notesCheckInput">Notes</label></div>
                        </div>
                    </div>                    
                </form>
                <button class="btn btn-primary float-end mt-2" type="button" on:click={() => pasteAttributes()}>Apply</button>
                {:catch error}
                    <p>Something went wrong: {error.message}</p>
                {/await}
            </div>
          </div>
        </div>
      </div>