<script>
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import removeEvent from "@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js";
import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
import eol from "eol";

function shiftWordBack() {
    try {
        if (
            !$eventGroupState[$projectState.selected] ||
            $eventGroupState[$projectState.selected].selected.length === 0 ||
            $eventGroupState[$projectState.selected].selected[0] === 0
        ) {
            return;
        }
            
        let eventRemoved = false;
        let maxChars = $eventGroupState[$projectState.selected].maxChars;
        let html = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text;
        let plainText = convertToPlainText(html);

        //Split text at spaces and line endings to get number of words
        let numberOfWords = plainText.split(/\s+/g).length;
        let textLines = eol.split(plainText);
        let firstLine = textLines[0].split(" ");
        let firstWord = firstLine[0];
        console.log("First Word:", firstWord);
        console.log("Text Line Length:", textLines.length);
        console.log("First Line Length:", firstLine.length);
        let deletePadding = firstLine.length === 1 ? 0 : 1;
        let lastWordFlag = textLines.length === 1 && firstLine.length === 1 ? true : false;
        let oStart =
            $eventGroupState[$projectState.selected].events[
                $eventGroupState[$projectState.selected].selected[0]
            ].start;
        let oEnd =
            $eventGroupState[$projectState.selected].events[
                $eventGroupState[$projectState.selected].selected[0]
            ].end;

        let oDuration = oEnd - oStart;

        let prevEventText =
            $eventGroupState[$projectState.selected].events[
                $eventGroupState[$projectState.selected].selected[0] - 1
            ].text || "<p></p>";
            
        let prevEventTextArray = prevEventText.split(">").filter((word) => {
            return word;
        });

        if (
            prevEventTextArray[prevEventTextArray.length - 1].length + firstWord.length >
            maxChars + 2
        ) {
            $eventGroupState[$projectState.selected].events[
                $eventGroupState[$projectState.selected].selected[0] - 1
            ].text += `${prevEventTextArray[0]}>${firstWord}</p>`;
        } else {
            $eventGroupState[$projectState.selected].events[
                    $eventGroupState[$projectState.selected].selected[0] - 1
                ].text =
                prevEventText.substring(0, prevEventText.length - 4) +
                " " +
                firstWord +
                "</p>";
        }

        /* Remove first word from event */
        if (quillEditor) {
            quillEditor.deleteText(0, firstWord.length + deletePadding);
            if (lastWordFlag) {
                $eventGroupState[$projectState.selected] = removeEvent(
                    $eventGroupState[$projectState.selected],
                    $eventGroupState[$projectState.selected].selected[0]
                );
                $eventGroupState[$projectState.selected].selected = [
                    $eventGroupState[$projectState.selected].selected[0] - 1,
                ];
                eventRemoved = true;
            }
        }

        if (oDuration && oDuration > 0) {
            let avgDurationOfWord = oDuration / numberOfWords;
            if (eventRemoved) {
                $eventGroupState[$projectState.selected].events[
                    $eventGroupState[$projectState.selected].selected[0]
                ].end += avgDurationOfWord;
            } else {
                $eventGroupState[$projectState.selected].events[
                    $eventGroupState[$projectState.selected].selected[0]
                ].start += avgDurationOfWord;
                if (
                    $eventGroupState[$projectState.selected].events[
                        $eventGroupState[$projectState.selected].selected[0] - 1
                    ].end > 0
                ) {
                    $eventGroupState[$projectState.selected].events[
                        $eventGroupState[$projectState.selected].selected[0] - 1
                    ].end += avgDurationOfWord;
                }
            }
        }

        historyState.insert({
            name: "shift word", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    } catch (err) {
        console.log(err, err.message);
    }
}
</script>

<li class="nav-item" title="Shift Word Up">
    <a
        class="nav-link"
        href="#!/"
        class:disabled={!$eventGroupState[$projectState.selected] ||
        $eventGroupState[$projectState.selected].selected.length === 0 ||
        $eventGroupState[$projectState.selected].selected[0] === 0}
        on:click={shiftWordBack}
        ><strong>w</strong><sup><i class="bi bi-arrow-up"></i></sup></a
        >
        </li>
