<script>
import {
    modalState
} from "@app/store/modalStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import { toast } from '@zerodevx/svelte-toast';
import stripTags from "@app/external/cc-lib/dist/functions/quill/stripTags.js";
import {
    fade
} from "svelte/transition";

let removeSdhDefaults = JSON.parse(localStorage.getItem("cc-remove-sdh-defaults")) || {};
let removeSquareBrackets = removeSdhDefaults.removeSquareBrackets,
    removeCurlyBraces = removeSdhDefaults.removeCurlyBraces,
    removeBrackets = removeSdhDefaults.removeBrackets,
    removeMusicNotes = removeSdhDefaults.removeMusicNotes,
    removeStars = removeSdhDefaults.removeStars,
    removeUpperCaseSpeakerLabel = removeSdhDefaults.removeUpperCaseSpeakerLabel,
    removeUpperCaseLine = removeSdhDefaults.removeUpperCaseLine,
    applyToAll = true;

const removeFunc = {
    squareBrackets: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }
        
        return event.text.replace(/<p.+\[.+\]<\/p>/g, "");
    },
    curlyBraces: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }

        return event.text.replace(/<p.+\{.+\}<\/p>/g, "");

    },
    brackets: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }

        return event.text.replace(/<p.+\(.+\)<\/p>/g, "");
    },
    musicNotes: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }
        return event.text.replace(/<p.+\♪+.+\♪+<\/p>/g, "");
    },

    stars: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }

        return event.text.replace(/<p.+\*+.+\*+<\/p>/g, "");

    },
    upperCaseSpeakerLabel: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }

        return event.text.replace(/<p.+[A-Z\s]+:\s+<\/p>/g, "");

    },
    upperCaseLine: function(removeText, event) {
        if (!removeText) {
            return event.text;
        }

        return event.text.replace(/<p.+[A-Z\s]+\s+<\/p>/g, "");
    }
}

async function removeSdh() {
    if (applyToAll) {
        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            events[index].text = removeFunc.squareBrackets(removeSquareBrackets, event);
            events[index].text = removeFunc.curlyBraces(removeCurlyBraces, event);
            events[index].text = removeFunc.brackets(removeBrackets, event);
            events[index].text = removeFunc.musicNotes(removeMusicNotes, event);
            events[index].text = removeFunc.stars(removeStars, event);
            events[index].text = removeFunc.upperCaseSpeakerLabel(removeUpperCaseSpeakerLabel, event);
            events[index].text = removeFunc.upperCaseLine(removeUpperCaseLine, event);
        });
    } else {
        $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.squareBrackets(removeSquareBrackets, $eventGroupState[$projectState.selected].events[eventIndex]);
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.curlyBraces(removeCurlyBraces, $eventGroupState[$projectState.selected].events[eventIndex]);
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.brackets(removeBrackets, $eventGroupState[$projectState.selected].events[eventIndex]);
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.musicNotes(removeMusicNotes, $eventGroupState[$projectState.selected].events[eventIndex]);
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.stars(removeStars, $eventGroupState[$projectState.selected].events[eventIndex]);
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.upperCaseSpeakerLabel(removeUpperCaseSpeakerLabel, $eventGroupState[$projectState.selected].events[eventIndex]);
            $eventGroupState[$projectState.selected].events[eventIndex].text = removeFunc.upperCaseLine(removeUpperCaseLine, $eventGroupState[$projectState.selected].events[eventIndex]);
        });
    }
    
    $eventGroupState[$projectState.selected].events = $eventGroupState[$projectState.selected].events.filter(event => {
        return stripTags(event.text) !== "";
    });

    $eventGroupState[$projectState.selected].selected = [];
    
    historyState.insert({
        name: "remove SDH", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    toast.push("SDH removal process complete", {classes: ['toast-success']});

    localStorage.setItem("cc-remove-sdh-defaults", JSON.stringify({
        removeSquareBrackets: removeSquareBrackets,
        removeCurlyBraces: removeCurlyBraces,
        removeBrackets: removeBrackets,
        removeMusicNotes: removeMusicNotes,
        removeStars: removeStars,
        removeUpperCaseSpeakerLabel: removeUpperCaseSpeakerLabel,
        removeUpperCaseLine: removeUpperCaseLine
    }));

    modalState.hideModal();
}
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'removeSdh' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="RemoveSdhModal"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Remove SDH Elements</h4>
                <button
                    type="button"
                    class="btn-close" aria-label="Close"
                    on:click={modalState.hideModal}
                    ></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeSquareBrackets" bind:checked={removeSquareBrackets}>
                                <label class="form-check-label" for="removeSquareBrackets">Remove text between [ and ]</label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeCurlyBraces" bind:checked={removeCurlyBraces}>
                                <label class="form-check-label" for="removeCurlyBraces">Remove text between &#123; and &#125;</label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeBrackets" bind:checked={removeBrackets}>
                                <label class="form-check-label" for="removeBrackets">Remove text between ( and )</label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeMusicNotes" bind:checked={removeMusicNotes}>
                                <label class="form-check-label" for="removeMusicNotes">Remove text between ♪ and ♪</label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeStars" bind:checked={removeStars}>
                                <label class="form-check-label" for="removeStars">Remove text between * and *</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeUpperCaseSpeakerLabel" bind:checked={removeUpperCaseSpeakerLabel}>
                                <label class="form-check-label" for="removeUpperCaseSpeakerLabel">Remove uppercase text before ':'</label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="removeUpperCaseLine" bind:checked={removeUpperCaseLine}>
                                <label class="form-check-label" for="removeUpperCaseLine">Remove line if it's all uppercase</label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="applyToAll" bind:checked={applyToAll}>
                                <label class="form-check-label" for="applyToAll">Apply to all</label>
                            </div>
                        </div>
                    </div>

                    <button
                        class="btn btn-primary float-end"
                        type="button" title="apply"
                        on:click={removeSdh}>Remove SDH</button
                        >
                        </form>
                        </div>
                        </div>
                        </div>
                        </div>
