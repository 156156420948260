export default (function orderByTime(eventGroup) {
  if (!eventGroup || !Array.isArray(eventGroup.events)) {
    throw new Error('Invalid eventGroup: expected object with events array');
  }

  // Create groups where each false-start event is paired with its preceding valid event
  const groups = [];
  let currentGroup = [];
  eventGroup.events.forEach(event => {
    if (event.start !== false) {
      // Start a new group with this valid-start event
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }
      currentGroup = [event];
    } else {
      // Add this false-start event to the current group
      currentGroup.push(event);
    }
  });

  // Add the last group if it exists
  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  // Sort the groups based on their leading event's start time
  groups.sort((groupA, groupB) => {
    const timeA = groupA[0].start;
    const timeB = groupB[0].start;

    // Handle edge case where first event in group is false
    if (timeA === false) return -1;
    if (timeB === false) return 1;
    return timeA - timeB;
  });

  // Flatten the groups back into a single array
  eventGroup.events = groups.flat();
  return eventGroup;
});