<script>
    import { modalState } from "@app/store/modalStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import { fade } from "svelte/transition";
    import { Circle } from "svelte-loading-spinners";
    import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";

    //Get settings from local storage
    let defaultShotChangeSettings =
        JSON.parse(localStorage.getItem("cc-snap-to-shot-changes-settings")) ||
        {};

    let eventGapFrames = defaultShotChangeSettings.eventGapFrames || 0,
        startTolerance = defaultShotChangeSettings.startTolerance || 0.5,
        endTolerance = defaultShotChangeSettings.endTolerance || 0.5,
        shotChangeOffset = defaultShotChangeSettings.shotChangeOffset || 0,
        processing = false;

    function syncToShotChanges() {
        try {
            if ($markerState.lists[0].markers.length === 0) {
                throw new Error(
                    "No shot change markers found. Please run shot change detection from the Ai Tools menu first.",
                );
            }

            processing = true;
            const offsetInSeconds = shotChangeOffset / $projectState.frameRate;

            toast.push("Snap To shot changes started... please wait.", {
                classes: ["toast-info"],
            });

            $eventGroupState[$projectState.selected] = orderByTime(
                $eventGroupState[$projectState.selected],
            );

            $markerState.lists[0].markers.forEach((shotChange, markerIndex) => {
                try {
                    let closestStartEvent, closestEndEvent;
                    let startToleranceEvents = $eventGroupState[
                        $projectState.selected
                    ].events.filter((event) => {
                        return (
                            event.start >= shotChange.time - startTolerance &&
                            event.start <= shotChange.time + startTolerance
                        );
                    });

                    let endToleranceEvents = $eventGroupState[
                        $projectState.selected
                    ].events.filter((event) => {
                        return (
                            event.end >= shotChange.time - endTolerance &&
                            event.end <= shotChange.time + endTolerance
                        );
                    });

                    if (startToleranceEvents.length > 1) {
                        closestStartEvent = startToleranceEvents.reduce(
                            (prev, curr) => {
                                return Math.abs(curr.start - shotChange.time) <
                                    Math.abs(prev.start - shotChange.time)
                                    ? curr
                                    : prev;
                            },
                        );
                    } else if (startToleranceEvents.length === 1) {
                        closestStartEvent = startToleranceEvents[0];
                    }

                    if (endToleranceEvents.length > 1) {
                        closestEndEvent = endToleranceEvents.reduce(
                            (prev, curr) => {
                                return Math.abs(curr.end - shotChange.time) <
                                    Math.abs(prev.end - shotChange.time)
                                    ? curr
                                    : prev;
                            },
                        );
                    } else if (endToleranceEvents.length === 1) {
                        closestEndEvent = endToleranceEvents[0];
                    }

                    if (!closestEndEvent && !closestStartEvent) {
                        return;
                    }

                    if (
                        closestStartEvent &&
                        closestEndEvent &&
                        closestStartEvent.id === closestEndEvent.id
                    ) {
                        closestStartEvent.start =
                            shotChange.time + offsetInSeconds;
                    } else if (closestStartEvent && closestEndEvent) {
                        closestStartEvent.start =
                            shotChange.time + offsetInSeconds;
                        closestEndEvent.end = shotChange.time - offsetInSeconds;

                        if (eventGapFrames > 0) {
                            closestStartEvent.start +=
                                eventGapFrames / $projectState.frameRate;
                        }
                    } else if (closestStartEvent) {
                        closestStartEvent.start =
                            shotChange.time + offsetInSeconds;
                    } else if (closestEndEvent) {
                        closestEndEvent.end = shotChange.time - offsetInSeconds;
                    }

                    if (closestEndEvent) {
                        let closestEndEventIndex = $eventGroupState[
                            $projectState.selected
                        ].events.findIndex(
                            (event) => event.id === closestEndEvent.id,
                        );
                        $eventGroupState[$projectState.selected].events[
                            closestEndEventIndex
                        ] = closestEndEvent;
                        if (
                            closestEndEventIndex <
                                $eventGroupState[$projectState.selected].events
                                    .length -
                                    1 &&
                            $eventGroupState[$projectState.selected].events[
                                closestEndEventIndex + 1
                            ].start <= closestEndEvent.end
                        ) {
                            $eventGroupState[$projectState.selected].events[
                                closestEndEventIndex + 1
                            ].start =
                                closestEndEvent.end +
                                eventGapFrames / $projectState.frameRate;
                        }
                    }

                    if (closestStartEvent) {
                        let closestStartEventIndex = $eventGroupState[
                            $projectState.selected
                        ].events.findIndex(
                            (event) => event.id === closestStartEvent.id,
                        );
                        $eventGroupState[$projectState.selected].events[
                            closestStartEventIndex
                        ] = closestStartEvent;
                        if (
                            closestStartEventIndex > 0 &&
                            $eventGroupState[$projectState.selected].events[
                                closestStartEventIndex - 1
                            ].end >= closestStartEvent.start
                        ) {
                            $eventGroupState[$projectState.selected].events[
                                closestStartEventIndex - 1
                            ].end = closestStartEvent.start;
                            $eventGroupState[$projectState.selected].events[
                                closestStartEventIndex
                            ].start += eventGapFrames / $projectState.frameRate;
                        }
                    }
                } catch (err) {
                    console.info(
                        "Error processing shot change",
                        err.message,
                        markerIndex,
                        shotChange,
                    );
                    console.error(err);
                }
            });

            $eventGroupState[$projectState.selected] =
                $eventGroupState[$projectState.selected];

            historyState.insert({
                name: "snap to shot changes",
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            toast.push("Snap To shot changes finished successfully", {
                classes: ["toast-success"],
            });

            localStorage.setItem(
                "cc-snap-to-shot-changes-settings",
                JSON.stringify({
                    eventGapFrames,
                    startTolerance,
                    endTolerance,
                    shotChangeOffset,
                }),
            );
        } catch (err) {
            console.error(err, err.message);
            toast.push(
                "Snap To shot changes failed with message: " + err.message,
                { classes: ["toast-danger"] },
            );
        } finally {
            modalState.hideModal();
        }
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'snapToShotChanges' ? 'show d-block' : ''}"
    role="dialog"
    aria-labelledby="snapToShotChangesTitle"
    aria-describedby="snapToShotChangesDescription"
    tabindex="-1"
    id="snapToShotChangesModal"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="snapToShotChangesTitle">Snap To Shot Changes</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close modal"
                    id="closeSnapToShotChanges"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p id="snapToShotChangesDescription" class="visually-hidden">
                    Configure settings for snapping events to shot changes
                </p>
                <form on:submit|preventDefault={syncToShotChanges} aria-label="Shot change settings">
                    <div class="row">
                        <div class="col-12 my-3">
                            <label class="form-label" for="eventGapFrames">Event Gap (Frames)</label>
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="1"
                                id="eventGapFrames"
                                aria-describedby="eventGapHelp"
                                bind:value={eventGapFrames}
                            />
                            <div id="eventGapHelp" class="form-text">Minimum gap between events in frames</div>
                        </div>
                        <div class="col-12 my-3">
                            <label class="form-label" for="shotChangeOffset">Shot Change Offset (Frames)</label>
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="1"
                                id="shotChangeOffset"
                                aria-describedby="offsetHelp"
                                bind:value={shotChangeOffset}
                            />
                            <div id="offsetHelp" class="form-text">Offset from shot change in frames</div>
                        </div>
                        <div class="col-6 my-3">
                            <label class="form-label" for="startTolerance">Start Tolerance (Seconds)</label>
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="0.01"
                                id="startTolerance"
                                aria-describedby="startToleranceHelp"
                                bind:value={startTolerance}
                            />
                            <div id="startToleranceHelp" class="form-text">Tolerance for start points in seconds</div>
                        </div>
                        <div class="col-6 my-3">
                            <label class="form-label" for="endTolerance">End Tolerance (Seconds)</label>
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="0.01"
                                id="endTolerance"
                                aria-describedby="endToleranceHelp"
                                bind:value={endTolerance}
                            />
                            <div id="endToleranceHelp" class="form-text">Tolerance for end points in seconds</div>
                        </div>
                    </div>
                    <button
                        class="btn btn-primary float-end"
                        type="button"
                        id="applySnapChanges"
                        on:click={syncToShotChanges}
                        disabled={processing}
                        aria-busy={processing}
                        >{#if processing}<Circle
                                size="10"
                                color="#1eb4b2"
                                unit="px"
                                duration="1s"
                            ></Circle>{/if} Apply</button
                    >
                </form>
            </div>
        </div>
    </div>
</div>
