<script>
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { editorState } from "@app/store/editorStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { lockState } from "@app/store/lockStore.js";
    import throttle from "just-throttle";
    import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
    let errors = [];
    let errorTypeFilter = "all";
    let eventListElement = document.getElementById("EventList");

    class subtitleError {
        constructor(options) {
            this.id = crypto.randomUUID();
            this.type = options.type || "timing";
            this.eventId = options.eventId;
            this.eventIndex = options.eventIndex;
            this.title = options.title ?? "";
            this.description = options.description ?? "";
        }
    }

    const iconMap = {
        timing: "bi-clock",
        content: "bi-card-text",
        review: "bi-chat-left-text",
    };

    const getErrors = throttle(
        () => {
            try {
                errors = [];

                if (isNaN($projectState.selected)) {
                    return;
                }

                if (!$eventGroupState[$projectState.selected]) {
                    return;
                }

                if (
                    $eventGroupState[$projectState.selected].events.length === 0
                ) {
                    return;
                }

                /* Get a list of errors */
                $eventGroupState[$projectState.selected].events.forEach(
                    (event, index, events) => {
                        try {
                            let duration = event.end - event.start;
                            let lineInfo = [];
                            let plainText = convertToPlainText(event.text);
                            plainText.split("\n").forEach((line) => {
                                lineInfo.push(line.length);
                            });
                            let totalChars = lineInfo.reduce(
                                (partialSum, a) => partialSum + a,
                                0,
                            );
                            let cps = (totalChars / duration).toFixed(2);

                            /* Timing Errors */
                            if (
                                errorTypeFilter === "all" || errorTypeFilter === "timing"
                            ) {
                                /* Timing Checks */
                                //Check for missing start or end time
                                if (
                                    event.start === false ||
                                    event.end === false
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "timing",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Missing timing",
                                            description:
                                                "This subtitle is missing a start or end time.",
                                        }),
                                    );

                                    return;
                                }

                                //Check for min duration
                                if (
                                    duration <
                                    $eventGroupState[$projectState.selected]
                                        .minDuration
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "timing",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Minimum duration",
                                            description:
                                                "This subtitle has a duration less than the set minimum.",
                                        }),
                                    );

                                    return;
                                }

                                //Check for max duration
                                if (
                                    duration >
                                    $eventGroupState[$projectState.selected]
                                        .maxDuration
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "timing",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Maximum duration",
                                            description:
                                                "This subtitle has a duration greater than the set maximum.",
                                        }),
                                    );

                                    return;
                                }

                                /* Check for player duration */
                                if (
                                    $playerState.duration &&
                                    event.end > $playerState.duration
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "timing",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "End time exceeds media duration",
                                            description:
                                                "This subtitle ends after the video ends.",
                                        }),
                                    );

                                    return;
                                }

                                //Check reading speed of event
                                if (
                                    cps >
                                    $eventGroupState[$projectState.selected]
                                        .maxCps
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "timing",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Reading speed",
                                            description:
                                                "This subtitle has a reading speed greater than the set maximum.",
                                        }),
                                    );

                                    return;
                                }

                                //Check for overlapping events
                                if (index > 0) {
                                    let previousEvent = events[index - 1];
                                    if (event.start < previousEvent.end) {
                                        errors.push(
                                            new subtitleError({
                                                type: "timing",
                                                eventId: event.id,
                                                eventIndex: index,
                                                title: "Overlapping events",
                                                description:
                                                    "This subtitle overlaps with the previous subtitle.",
                                            }),
                                        );

                                        return;
                                    }
                                }

                                if ($editorState.minFrameGap > 0 && index < events.length - 1){
                                    let frameGapSec = (frames/$projectState.frameRate);
                                    let eventFrameGap = events[index+1].start - event.end;
                                    if (eventFrameGap < frameGapSec){
                                        errors.push(
                                            new subtitleError({
                                                type: "timing",
                                                eventId: event.id,
                                                eventIndex: index,
                                                title: "Minimum Frame Gap",
                                                description: "The frame gap between this subtitle and the next is less than the set minimum."
                                            })
                                        );
                                    }
                                }
                            }

                            /* Content Checks */
                            if (
                                errorTypeFilter === "all" ||
                                errorTypeFilter === "content"
                            ) {
                                //Check for empty text
                                if (
                                    plainText.trim().length === 0 ||
                                    plainText.trim() === "<br>"
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "content",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Empty subtitle",
                                            description:
                                                "This subtitle is empty and will not be displayed.",
                                        }),
                                    );

                                    return;
                                }

                                //Check for too many lines
                                if (
                                    lineInfo.length >
                                    $eventGroupState[$projectState.selected]
                                        .maxLines
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "content",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Too many lines",
                                            description:
                                                "This subtitle contains too many lines.",
                                        }),
                                    );

                                    return;
                                }

                                //Check for too many characters in any line
                                if (
                                    lineInfo.some(
                                        (line) =>
                                            line >
                                            $eventGroupState[
                                                $projectState.selected
                                            ].maxChars,
                                    )
                                ) {
                                    errors.push(
                                        new subtitleError({
                                            type: "content",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Line too long",
                                            description:
                                                "This subtitle contains too many charcters in a line.",
                                        }),
                                    );

                                    return;
                                }
                            }

                            if (
                                errorTypeFilter === "all" ||
                                errorTypeFilter === "review"
                            ) {
                                //Check for review status
                                if (event.approved === false) {
                                    errors.push(
                                        new subtitleError({
                                            type: "review",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Approval",
                                            description:
                                                "This subtitle has not been approved.",
                                        }),
                                    );

                                    return;
                                }

                                if (event.notes && !event.notesCheck){
                                    errors.push(
                                        new subtitleError({
                                            type: "review",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Notes",
                                            description: "This subtitle has notes that have not been checked."
                                        })
                                    );
                                }

                                if (event.reply && !event.replyCheck){
                                    errors.push(
                                        new subtitleError({
                                            type: "review",
                                            eventId: event.id,
                                            eventIndex: index,
                                            title: "Reply",
                                            description: "This subtitle has a reply that has not been checked."
                                        })
                                    );
                                }
                            }
                        } catch (err) {
                            console.log(err.message);
                        }
                    },
                );

                errors = errors;
            } catch (err) {
                console.error("Error fetching errors");
                console.error(err);
            }
        },
        1500,
        { leading: true },
    );

    function selectNextError() {
        if (
            !$eventGroupState[$projectState.selected] ||
            $eventGroupState[$projectState.selected].events.length === 0 ||
            errors.length === 0
        ) {
            return;
        }

        let selectedEvent =
            $eventGroupState[$projectState.selected].selected.length > 0
                ? $eventGroupState[$projectState.selected].selected[0]
                : 0;
        let nextError =
            errors.find((error) => error.eventIndex > selectedEvent) ||
            errors[0];
        $eventGroupState[$projectState.selected].selected = [
            nextError.eventIndex,
        ];

        try {
            eventListElement.scrollTo(0, nextError.eventIndex * 230);
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, nextError.eventIndex * 230);
        }

        if (
            $lockState.video &&
            $eventGroupState[$projectState.selected] &&
            $eventGroupState[$projectState.selected].events[
                nextError.eventIndex
            ] &&
            $eventGroupState[$projectState.selected].events[
                nextError.eventIndex
            ].start >= 0
        ) {
            player.currentTime =
                $eventGroupState[$projectState.selected].events[
                    nextError.eventIndex
                ].start;
        }
    }

    function selectPrevError() {
        if (
            !$eventGroupState[$projectState.selected] ||
            $eventGroupState[$projectState.selected].events.length === 0 ||
            errors.length === 0
        ) {
            return;
        }

        let selectedEvent =
            $eventGroupState[$projectState.selected].selected.length > 0
                ? $eventGroupState[$projectState.selected].selected[0]
                : 0;
        let prevError =
            errors
                .reverse()
                .find((error) => error.eventIndex < selectedEvent) ||
            errors[errors.length - 1];
        $eventGroupState[$projectState.selected].selected = [
            prevError.eventIndex,
        ];

        try {
            eventListElement.scrollTo(0, prevError.eventIndex * 230);
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, prevError.eventIndex * 230);
        }

        if (
            $lockState.video &&
            $eventGroupState[$projectState.selected] &&
            $eventGroupState[$projectState.selected].events[
                prevError.eventIndex
            ] &&
            $eventGroupState[$projectState.selected].events[
                prevError.eventIndex
            ].start >= 0
        ) {
            player.currentTime =
                $eventGroupState[$projectState.selected].events[
                    prevError.eventIndex
                ].start;
        }
    }

    $: getErrors($eventGroupState[$projectState.selected]);
</script>

<!-- Error summary header -->
<div
    class="d-flex justify-content-between align-items-center p-3 bg-light border-bottom"
>
    <div>
        <!-- Error type filter -->
        <select
            id="ErrorTypeFilterSelect"
            class="form-select form-select-sm"
            bind:value={errorTypeFilter}
            on:change={getErrors}
            aria-label="Select error type filter"
        >
            <option value="all">All Errors ({errors.length})</option>
            <option value="timing">Timing Errors ({errors.length})</option>
            <option value="content">Content Errors ({errors.length})</option>
            <option value="review">Review Errors ({errors.length})</option>
        </select>
    </div>
    <div class="btn-group">
        <button
            type="button"
            class="btn btn-secondary btn-sm {errors.length === 0
                ? 'disabled'
                : ''}"
            on:click={selectPrevError}
            id="prevErrorButton"
            aria-label="Go to previous error"
        >
            <i class="bi bi-chevron-left"></i> Previous
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm {errors.length === 0
                ? 'disabled'
                : ''}"
            on:click={selectNextError}
            id="nextErrorButton"
            aria-label="Go to next error"
        >
            Next <i class="bi bi-chevron-right"></i>
        </button>
    </div>
</div>

<!-- Error details -->
{#each errors.filter((evError) => {
    return evError.eventIndex === $eventGroupState[$projectState.selected]?.selected[0];
}) as eventError}
    <div class="p-3 mt-2">
        <div class="alert alert-warning mb-0">
            <h6 class="alert-heading">
                <i class="bi {iconMap[eventError.type]} me-2"></i>
                {eventError.title}
            </h6>
            <p class="mb-0">
                {eventError.description}
            </p>
        </div>
    </div>
{:else}
    <p class="text-muted text-center p-3 mt-2 mb-0">No Errors Found</p>
{/each}
