<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import _Marker from "@app/external/cc-lib/dist/classes/marker.js";
import {
    fade
} from 'svelte/transition';
import Swal from 'sweetalert2';

import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";

/* FFMPEG */
const ffmpegPath = require('ffmpeg-static-electron').path;
const ffmpeg = require('fluent-ffmpeg');
ffmpeg.setFfmpegPath(ffmpegPath.replace('app.asar', 'app.asar.unpacked'));

let progress = 0,
    statusMsg = "Starting shot change detection...",
    duration = 10,
    durationTc = "00:00:10:00";

function updateProgress(tc) {
    let tcSec = tcLib.tcMsToSec(tc);
    progress = ((tcSec.toFixed(2)/duration) * 100).toFixed(2);
    console.log(tcSec, progress);
}

function detectSceneChanges() {
    $markerState.lists[0].markers = [];
    try {
        toast.push("Scanning media for shot changes", {classes: ['toast-info']});

        //Ask customer using swal alert if they want scene change detection low, medium, or high
        Swal.fire({
            title: 'Shot Change Detection',
            text: 'Select the level of shot change detection',
            icon: 'question',
            //disallow outside click
            allowOutsideClick: false,
            //disallow escape key
            allowEscapeKey: false,            
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Low',
            denyButtonText: 'Medium',
            cancelButtonText: 'High',
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn btn-warning mx-2',
                cancelButton: 'btn btn-danger'                
            }
            
        }).then((result) => {
            let sceneChangeDetectionLevel;
            if (result.isDismissed) {
                sceneChangeDetectionLevel = 0.15;
            } else if (result.isDenied) {
                sceneChangeDetectionLevel = 0.3;
            } else if (result.isConfirmed) {
                sceneChangeDetectionLevel = 0.45;
            }

            console.log(sceneChangeDetectionLevel);
            ffmpeg($projectState.media.localPath)
            .videoFilters(`select='gt(scene,${sceneChangeDetectionLevel})'`)
            .videoFilters(`showinfo`)
            .outputOptions("-f null")
            .output("scene-detect")
            .on('start', function(commandLine) {
                console.log('Spawned Ffmpeg with command: ' + commandLine);
            })
            .on('codecData', function(data) {
                durationTc = data.duration;
                duration = tcLib.tcMsToSec(data.duration);
                console.log('Input is ' + data.audio + ' audio ' +
                    'with ' + data.video + ' video');
            })
            .on('progress', function(prog) {
                /* progress = {percent, timemark, currentFps, frames, targetSize, currentKbps} */
                //console.log(progress);
                updateProgress(prog.timemark)
                statusMsg = `Scanning: ${prog.timemark}/${durationTc} (${progress}%) done`;
                console.log('Scanning: ' + prog.timemark + ' done', prog);
            })
            .on('end', function() {
                toast.push("Scene Detection Complete", {classes: ['toast-success']});
                console.log('Finished Scanning');               
                modalState.hideModal();
            }).on('stderr', function(stderrLine) {
                if (stderrLine.search("showinfo") !== -1) {
                    let sceneChangeTc = stderrLine.match(/pts_time:[0-9.]*/g);
                    if (sceneChangeTc) {
                        $markerState.lists[0].markers = [...$markerState.lists[0].markers, new _Marker({
                            time : parseFloat(sceneChangeTc[0].split("pts_time:")[1]),
                            comment : "Shot Change "+ ($markerState.lists[0].markers.length+1)
                        })]
                    }
                }
            })
            .on('error', function(err, stdout, stderr) {
                console.log(err, stderr);
                toast.push("Failed to detect shot changes. " + err.message, {classes: ['toast-warning']});
                console.log('Cannot scan video: ' + err.message);
                statusMsg = 'Cannot scan video: ' + err.message;
                progress = 100;
                modalState.hideModal();
            })
            .run();
        });

    } catch (err) {
        console.log(err, err.message);
        toast.push("Failed to detect shot changes. " + err.message, {classes: ['toast-warning']});
    }
};

detectSceneChanges();
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'sceneChangeDetection' ? 'show d-block' : ''}" role="dialog" aria-labelledby="sceneChangeTitle" tabindex="-1" id="SceneChangeDetectionModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="sceneChangeTitle">Shot Change Detection</h4>
            </div>
            <div class="modal-body">
                <p><i class="bi bi-exclamation-diamond-fill" role="img" aria-hidden="true"></i> Detecting shot changes...</p>
                <div class="progress" role="progressbar" aria-label="Shot change detection progress" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" style="width: {progress}%;">{progress}%</div>
                </div>
                <p class="text-center text-muted small" aria-live="polite">{statusMsg}</p>
            </div>
        </div>
    </div>
</div>
