<script>
import { toast } from '@zerodevx/svelte-toast';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import db from '@app/configs/firestore.js';
/* 3rd Party Lib */
import {
    BarLoader
} from 'svelte-loading-spinners';

let jobUpdate = true, teamUpdate = true, dbWork, userId = firebase.auth().currentUser.uid, userDbRef = db.collection("users").doc(userId);

async function updateNotificationSettings(){
    dbWork= await userDbRef.update({
        notifications : {
            jobUpdate : jobUpdate,
            teamUpdate : teamUpdate
        }
    })
    toast.push("Settings have been saved successfully.", {classes: ['toast-success']});
}       

async function setup(){
    try {
        let userDoc = await userDbRef.get();
        if (!userDoc.exists){
            console.warn(`No user document exists for current user: ${userId}`);
            dbWork = await userDbRef.set({
                notifications : {
                    jobUpdate : true,
                    teamUpdate : true
                }
            });
            return;
        }

        let userInfo = userDoc.data();
        console.log(userInfo);

        jobUpdate = userInfo.notifications ? userInfo.notifications.jobUpdate : true;
        teamUpdate = userInfo.notifications ? userInfo.notifications.teamUpdate : true;
    } catch(err){
        console.log(err, err.message);
        return err;
    }   
}

dbWork = setup();
</script>    
    <h5 id="notificationSettingsTitle">Notification Settings</h5>
    <p id="notificationSettingsDesc" class="small text-muted">Enable and disable email notifications.</p>
    {#await dbWork}
        <div class="d-flex justify-content-center align-items-center p-3">
            <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
        </div>       
    {:then}
    <form on:submit|preventDefault="{() => {dbWork = updateNotificationSettings()}}" aria-labelledby="notificationSettingsTitle" aria-describedby="notificationSettingsDesc">
        <div class="mb-3 form-check form-switch">
            <input type="checkbox" class="form-check-input" id="jobUpdateCheck" bind:checked={jobUpdate} on:change={() => {dbWork = updateNotificationSettings()}} aria-labelledby="jobUpdateCheckLabel">
            <label id="jobUpdateCheckLabel" class="form-check-label" for="jobUpdateCheck">Job Completion Report <i class="bi bi-info-circle" title="Receive an email notification any time an AI Transcription or AI Translation job completes successfully."></i></label>
        </div>
        <div class="mb-3 form-check form-switch">
            <input type="checkbox" class="form-check-input" id="teamUpdateCheck" bind:checked={teamUpdate} on:change={() => {dbWork = updateNotificationSettings()}} aria-labelledby="teamUpdateCheckLabel">
            <label id="teamUpdateCheckLabel" class="form-check-label" for="teamUpdateCheck">New Team Member Added <i class="bi bi-info-circle" title="Receive an email notification any time a new team member is added to your team. This option applies to Team Admins only."></i></label>
        </div>
    </form>
    {:catch error}
    <div class="d-flex justify-content-center align-items-center p-3">
        <!-- promise was rejected -->
        <p class="text-danger m-0">Error loading notification settings from database. : {error.message}. Please close the options menu and try again.</p>
    </div>	
    {/await}
