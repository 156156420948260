import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
export default (function tcRound(eventGroup, frameRate, dropFrame) {
  eventGroup.events.forEach((event, index, events) => {
    let oStart = event.start;
    let oEnd = event.end;
    try {
      if (event.start) {
        let startTc = tcLib.secToTc(event.start, frameRate, dropFrame);
        events[index].start = tcLib.tcToSec(startTc, frameRate, dropFrame);
      }
      if (event.end) {
        let endTc = tcLib.secToTc(event.end, frameRate, dropFrame);
        events[index].end = tcLib.tcToSec(endTc, frameRate, dropFrame);
      }
    } catch (err) {
      console.log("There was an error rounding the following timecodes: ", oStart, oEnd);
      events[index].start = oStart;
      events[index].end = oEnd;
    }
  });
  return eventGroup;
});