<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    fade
} from 'svelte/transition';
let insertOption = "single";

function insertMusicNotes() {
    let insertChar,
        alignmentMap = {
            left: false,
            right: "right",
            center: "center"
        };
    if (insertOption === "single" || insertOption === "singleSurround") {
        insertChar = "♪";
    } else {
        insertChar = "♪♪";
    }

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
        let startIndex = $eventGroupState[$projectState.selected].events[eventIndex].text.indexOf(">") + 1;

        $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.substring(0, startIndex) + insertChar + 
        " " + $eventGroupState[$projectState.selected].events[eventIndex].text.substring(startIndex);

        if (insertOption === "singleSurround" || insertOption === "doubleSurround") {
            let endIndex = $eventGroupState[$projectState.selected].events[eventIndex].text.lastIndexOf("<");

            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.substring(0, endIndex) + " " + insertChar + $eventGroupState[$projectState.selected].events[eventIndex].text.substring(endIndex);
        }

        if (count === 0 && quillEditor) {
            quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
            quillEditor.setSelection(0, quillEditor.getLength());
            quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
        }
    });
    
    historyState.insert({
        name: "insert music notes", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    modalState.hideModal();
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'musicNotes' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="MusicNotesModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Insert Music Notes</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="insertOption1" bind:group={insertOption} name="insertOption" value={"single"}>
                        <label class="form-check-label" for="insertOption1">Single (♪)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="insertOption2" bind:group={insertOption} name="insertOption" value={"double"}>
                        <label class="form-check-label" for="insertOption2">Double (♪♪)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="insertOption3" bind:group={insertOption} name="insertOption" value={"singleSurround"}>
                        <label class="form-check-label" for="insertOption3">Surround Single (♪... ♪)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="insertOption4" bind:group={insertOption} name="insertOption" value={"doubleSurround"}>
                        <label class="form-check-label" for="insertOption4">Surround Double (♪♪... ♪♪)</label>
                    </div>
                    <button class="btn btn-primary float-end" type="button" on:click="{insertMusicNotes}">Insert</button>
                </form>

            </div>

        </div>

    </div>

</div>
