<script>
	import { historyState } from "@app/store/historyStore.js";
	import { projectState } from "@app/store/projectStore.js";
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import throttle from "just-throttle";

	let event = $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : false;

	const saveToHistory = throttle(
		() => {
			historyState.insert({
				name: "update notes", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});
		},
		250,
		{
			leading: false,
			trailing: true,
		}
	);

	$: event = $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : false;
</script>

<form>
	<div class="row">
		{#if event}
			<div class="col">
				<label for="textareanotesinputlabel" class="form-label">Notes:</label>
				<textarea id="textareanotesinputlabel" class="w-100 p-1 rounded" rows="3" bind:value={event.notes} disabled={!event} placeholder="Notes" on:input={() => saveToHistory()}></textarea>
				<div class="form-check">
					<input id="qtNotesStatusCheck" class="form-check-input" type="checkbox" bind:checked={event.notesCheck} on:change={() => saveToHistory()}/>
					<label class="form-check-label" for="qtNotesStatusCheck"> Done </label>
				</div>
			</div>

			<div class="col">
				<label for="textareareplyinputlabel" class="form-label">Reply:</label>
				<textarea id="textareareplyinputlabel" class="w-100 p-1 rounded" rows="3" bind:value={event.reply} disabled={!event} placeholder="Reply" on:input={() => saveToHistory()}></textarea>
				<div class="form-check">
					<input id="qtReplyStatusCheck" class="form-check-input" type="checkbox" bind:checked={event.replyCheck} on:change={() => saveToHistory()}/>
					<label class="form-check-label" for="qtReplyStatusCheck"> Done </label>
				</div>
			</div>
		{:else}
			<div class="col">
				<label for="textareainputlabel" class="form-label text-muted">No event selected</label>
				<textarea class="w-100 p-1 rounded" disabled placeholder="Please select an Event"></textarea>
			</div>
		{/if}
	</div>
</form>
