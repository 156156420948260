<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    fade
} from 'svelte/transition';
let insertOption = "single";

function insertMusicNotes() {
    let insertChar,
        alignmentMap = {
            left: false,
            right: "right",
            center: "center"
        };
    if (insertOption === "single" || insertOption === "singleSurround") {
        insertChar = "♪";
    } else {
        insertChar = "♪♪";
    }

    $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
        let startIndex = $eventGroupState[$projectState.selected].events[eventIndex].text.indexOf(">") + 1;

        $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.substring(0, startIndex) + insertChar + 
        " " + $eventGroupState[$projectState.selected].events[eventIndex].text.substring(startIndex);

        if (insertOption === "singleSurround" || insertOption === "doubleSurround") {
            let endIndex = $eventGroupState[$projectState.selected].events[eventIndex].text.lastIndexOf("<");

            $eventGroupState[$projectState.selected].events[eventIndex].text = $eventGroupState[$projectState.selected].events[eventIndex].text.substring(0, endIndex) + " " + insertChar + $eventGroupState[$projectState.selected].events[eventIndex].text.substring(endIndex);
        }

        if (count === 0 && quillEditor) {
            quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
            quillEditor.setSelection(0, quillEditor.getLength());
            quillEditor.format('align', alignmentMap[$eventGroupState[$projectState.selected].events[eventIndex].alignment]);
        }
    });
    
    historyState.insert({
        name: "insert music notes", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    modalState.hideModal();
}
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'musicNotes' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="musicNotesTitle"
    aria-describedby="musicNotesDesc"
    tabindex="-1" 
    id="MusicNotesModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="musicNotesTitle">Insert Music Notes</h4>
                <button type="button" 
                    class="btn-close" 
                    id="btnCloseMusicNotes"
                    aria-label="Close dialog" 
                    on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p id="musicNotesDesc" class="sr-only">Choose how to insert music note symbols into your text</p>
                <form>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="musicNotesSingle" bind:group={insertOption} name="insertOption" value={"single"}>
                        <label class="form-check-label" for="musicNotesSingle">Single (♪)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="musicNotesDouble" bind:group={insertOption} name="insertOption" value={"double"}>
                        <label class="form-check-label" for="musicNotesDouble">Double (♪♪)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="musicNotesSurround" bind:group={insertOption} name="insertOption" value={"singleSurround"}>
                        <label class="form-check-label" for="musicNotesSurround">Surround Single (♪... ♪)</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" id="musicNotesDoubleSurround" bind:group={insertOption} name="insertOption" value={"doubleSurround"}>
                        <label class="form-check-label" for="musicNotesDoubleSurround">Surround Double (♪♪... ♪♪)</label>
                    </div>
                    <button class="btn btn-primary float-end" 
                        type="button"
                        id="btnInsertMusicNotes"
                        aria-label="Insert music notes" 
                        on:click="{insertMusicNotes}">Insert</button>
                </form>
            </div>
        </div>
    </div>
</div>
