<script>
import {
    onMount
} from 'svelte';
import firebase from '../configs/firebase'
import * as firebaseui from 'firebaseui'
import {
    replace
} from 'svelte-spa-router'


onMount(async () => {
    let fbUiConfig = {
        'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
        signInOptions: [{
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
        }],
        callbacks: {
            signInSuccessWithAuthResult() {
                replace("/");
            }
        }
    }

    let fbUi = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    fbUi.start("#firebaseui-auth-container", fbUiConfig);
});
</script>

<div id="LoginScreen">
    <div
        class="container d-flex justify-content-center align-items-center LoginPageWrapper">
        <div
            class="col-5 shadow LoginFormWrapper text-white rounded">
            <div
                class="d-flex justify-content-center align-items-center mb-2">
                <img
                    src="./icon.png" width="100px"
                    alt="Closed Caption Creator Logo"
                    class="mb-1"/>
            </div>
            <h4 class="text-center mb-4">Closed Caption Creator</h4>
            <div id="firebaseui-auth-container"></div>
            <small
                class="form-text text-center text-white small">Please contact support if you
                have any issues: support@closedcaptioncreator.com</small>
        </div>
    </div>
</div>

<style>
#LoginScreen {
    background-color: #080d08;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23080d08'/%3E%3Cstop offset='1' stop-color='%23222222'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23383838' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23383838' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23383838' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23383838' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E") !important;
    background-attachment: fixed;
    background-size: cover;
}

.LoginPageWrapper {
    height: 100vh;
}

.LoginFormWrapper {
    background: #202121;
    border-style: none;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-right: 5%;
    padding-left: 5%;
}
</style>
