<script>
    import { projectState } from "@app/store/projectStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import { onMount } from "svelte";

    import escapeRegExp from "@app/external/cc-lib/dist/functions/quill/escapeRegExp.js";
    import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";

    let searchValue,
        replaceValue,
        caseSensitive = false,
        replaceAllOption = false,
        regexOption = false,
        wholeWord = false,
        results = [],
        eventListElement = document.getElementById("EventList"),
        selectedResult;

    onMount(() => {
        //Focus on input with id of FindInput
        setTimeout(() => {
            document.getElementById("FindInput").focus();
        }, 100);
    });

    function search() {
        let regexOptions = caseSensitive ? "gm" : "gmi", searchPattern;
        
        //console.log("Before:", searchValue, replaceValue);
        searchValue = document.getElementById("FindInput").value;
        replaceValue = document.getElementById("ReplaceInput").value;
        //console.log("After:", searchValue, replaceValue);
        
        
        if (regexOption) {
            searchPattern = wholeWord
                ? new RegExp(
                      `\\b${searchValue}\\b|\\B${searchValue}\\B`,
                      regexOptions,
                  )
                : new RegExp(searchValue, regexOptions);
        } else {
            searchPattern = wholeWord
                ? new RegExp(
                      `\\b${escapeRegExp(searchValue)}\\b|\\B${escapeRegExp(searchValue)}\\B`,
                      regexOptions,
                  )
                : new RegExp(escapeRegExp(searchValue), regexOptions);
        }

        results = $eventGroupState[$projectState.selected].events.filter(
            (event) => {
                return convertToPlainText(event.text, " ").match(searchPattern);
            },
        );

        toast.push(`Search returned ${results.length} results`, {
            classes: ["toast-info"],
        });
        try {
            if (results.length > 0) {
                selectedResult = 0;
                selectResult();
            } else if (window.quillEditor) {
                quillEditor.blur();
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    function replaceAll() {
        let regexOptions = caseSensitive ? "gm" : "gmi",
            searchPattern;

        replaceValue = document.getElementById("ReplaceInput").value;
        if (regexOption) {
            searchPattern = wholeWord
                ? new RegExp(
                      `\\b${searchValue}\\b|\\B${searchValue}\\B`,
                      regexOptions,
                  )
                : new RegExp(searchValue, regexOptions);
        } else {
            searchPattern = wholeWord
                ? new RegExp(
                      `\\b${escapeRegExp(searchValue)}\\b|\\B${escapeRegExp(searchValue)}\\B`,
                      regexOptions,
                  )
                : new RegExp(escapeRegExp(searchValue), regexOptions);
        }

        replaceValue = !replaceValue ? "" : replaceValue;

        for (
            let i = 0;
            i < $eventGroupState[$projectState.selected].events.length;
            i++
        ) {
            $eventGroupState[$projectState.selected].events[i].text =
                $eventGroupState[$projectState.selected].events[i].text.replace(
                    searchPattern,
                    replaceValue,
                );
        }

        toast.push(`All results replaced successfully`, {
            classes: ["toast-info"],
        });

        historyState.insert({
            name: "replace text", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
        results = [];
        selectedResult = undefined;
        $eventGroupState[$projectState.selected].selected = [];
    }

    function replaceOnce() {
        let regexOptions = caseSensitive ? "gm" : "gmi",
            searchPattern;

        replaceValue = document.getElementById("ReplaceInput").value;
        if (regexOption) {
            searchPattern = wholeWord
                ? new RegExp(
                      `\\b${searchValue}\\b|\\B${searchValue}\\B`,
                      regexOptions,
                  )
                : new RegExp(searchValue, regexOptions);
        } else {
            searchPattern = wholeWord
                ? new RegExp(
                      `\\b${escapeRegExp(searchValue)}\\b|\\B${escapeRegExp(searchValue)}\\B`,
                      regexOptions,
                  )
                : new RegExp(escapeRegExp(searchValue), regexOptions);
        }

        replaceValue = !replaceValue ? "" : replaceValue;

        let eventIndex = $eventGroupState[
            $projectState.selected
        ].events.findIndex((event) => {
            return event.id === results[selectedResult].id;
        });

        $eventGroupState[$projectState.selected].events[eventIndex].text =
            $eventGroupState[$projectState.selected].events[
                eventIndex
            ].text.replace(searchPattern, replaceValue);

        results = results.filter((result) => {
            return result.id !== results[selectedResult].id;
        });

        toast.push(`Selected result replaced successfully`, {
            classes: ["toast-info"],
        });

        if (results.length > 0 && results[selectedResult]) {
            selectResult();
        } else {
            nextResult();
        }

        historyState.insert({
            name: "replace text", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
    }

    function prevResult() {
        selectedResult =
            selectedResult > 0 ? --selectedResult : results.length - 1;
        selectResult();
    }

    function nextResult() {
        if (results.length > 0) {
            selectedResult =
                selectedResult + 1 < results.length ? ++selectedResult : 0;
            selectResult();
        } else {
            selectedResult = undefined;
            $eventGroupState[$projectState.selected].selected = [];
        }
    }

    async function selectResult() {
        if (!isNaN(selectedResult) && results.length > 0) {
            /* Scroll and select the event */
            let eventIndex = $eventGroupState[
                $projectState.selected
            ].events.findIndex((event) => {
                return event.id === results[selectedResult].id;
            });

            try {
                eventListElement.scrollTo(0, eventIndex * 230);
            } catch (err) {
                eventListElement = document.getElementById("EventList");
                eventListElement.scrollTo(0, eventIndex * 230);
            }

            $eventGroupState[$projectState.selected].selected = [eventIndex];

            /* Highlight the search value */
            let regexOptions = caseSensitive ? "gm" : "gmi",
                searchPattern;
            if (regexOption) {
                searchPattern = wholeWord
                    ? new RegExp(
                          `\\b${searchValue}\\b|\\B${searchValue}\\B`,
                          regexOptions,
                      )
                    : new RegExp(searchValue, regexOptions);
            } else {
                searchPattern = wholeWord
                    ? new RegExp(
                          `\\b${escapeRegExp(searchValue)}\\b|\\B${escapeRegExp(searchValue)}\\B`,
                          regexOptions,
                      )
                    : new RegExp(escapeRegExp(searchValue), regexOptions);
            }

            let text = convertToPlainText(results[selectedResult].text, " ");
            let regexResult = searchPattern.exec(text);
            setTimeout(function () {
                try {
                    quillEditor.setSelection(
                        regexResult.index,
                        regexResult[0].length,
                    );
                } catch (err) {
                    console.log(err, err.message, regexResult);
                }
            }, 100);
        }
    }
</script>

<form on:submit|preventDefault={search} id="SearchAndReplaceForm">
    <div class="row mb-3">
        <div class="col">
            <label class="form-label" for="FindInput">Search</label>
            <div class="input-group input-group-sm">
                <input
                    id="FindInput"
                    class="form-control"
                    type="text"
                    bind:value={searchValue}
                    on:blur={() => (results = [])}
                />
            </div>
        </div>
        <div class="col">
            <label class="form-label" for="ReplaceInput">Replace</label>
            <div class="input-group input-group-sm">
                <input
                    id="ReplaceInput"
                    class="form-control"
                    type="text"
                    bind:value={replaceValue}
                />
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="caseSensitive"
                    bind:checked={caseSensitive}
                    on:change={() => (results = [])}
                />
                <label class="form-check-label" for="caseSensitive"
                    >Case Sensitive</label
                >
            </div>
        </div>
        <div class="col">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="wholeWordCheck"
                    bind:checked={wholeWord}
                    on:change={() => (results = [])}
                />
                <label class="form-check-label" for="wholeWordCheck"
                    >Whole Word</label
                >
            </div>
        </div>
        <div class="col">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="replaceAllCheck"
                    bind:checked={replaceAllOption}
                />
                <label class="form-check-label" for="replaceAllCheck"
                    >Replace All</label
                >
            </div>
        </div>
        <div class="col">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="regexCheck"
                    bind:checked={regexOption}
                />
                <label class="form-check-label" for="regexCheck">Regex</label>
            </div>
        </div>
    </div>
    <div class="btn-group mt-2" role="group">
        <button class="btn btn-primary" type="submit" on:click={search}>
            <i class="bi bi-search" /> Search
        </button>
        <button
            class="btn btn-primary"
            type="button"
            disabled={results.length === 0}
            on:click={() => (replaceAllOption ? replaceAll() : replaceOnce())}
        >
            <i class="bi bi-arrow-left-right" /> Replace
        </button>
        <button
            class="btn btn-light"
            type="button"
            disabled={results.length === 0}
            on:click={prevResult}
        >
            <i class="bi bi-chevron-left" />
        </button>
        <button
            class="btn btn-light"
            type="button"
            disabled={results.length === 0}
            on:click={nextResult}
        >
            <i class="bi bi-chevron-right" />
        </button>
        {#if selectedResult !== undefined}
            <button class="btn btn-light" type="button" disabled="">
                {selectedResult + 1}/{results.length}
            </button>
        {/if}
    </div>
    {#if results.length === 0}
        <small class="form-text text-end text-danger">No Results Found</small>
    {/if}
</form>
