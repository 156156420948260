import {readable} from 'svelte/store';
import platform from 'platform-detect';
import online from "../configs/status.js";
export const environment = readable(
  {
    desktop : platform.node,
    browser : platform.website,
    web : platform.web,
    electron : platform.packaged,
    mac : platform.macos,
    windows : platform.windows,
    linux : platform.linux,
    version : "1.8.28",
    online : online
  }
)