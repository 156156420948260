<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    fade
} from 'svelte/transition';
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import tcRound from "@app/external/cc-lib/dist/functions/eventGroups/tcRound.js";

let frames = 2, minFrameGap = 0, maxFrameGap = 6, shotChangeAware = true, processing = false;

function insertBlankFrames() {
    try {
        processing = true;

        toast.push("Inserting blank frames...", {
            classes: ["toast-info"]
        });

        let frameGapSec = (frames/$projectState.frameRate);
        let minFrameGapSec = minFrameGap/$projectState.frameRate;
        let maxFrameGapSec = maxFrameGap/$projectState.frameRate;

        $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);
        $eventGroupState[$projectState.selected] = tcRound($eventGroupState[$projectState.selected], $projectState.frameRate, $projectState.dropFrame);

        $eventGroupState[$projectState.selected].events.forEach((event, index, events) => {
            if (index === events.length-1){
                return;
            }

            if (!event.end || event.end < 0){
                return;
            }

            let eventFrameGap = events[index+1].start - event.end;
            if (eventFrameGap <= maxFrameGapSec && eventFrameGap >= minFrameGapSec){
                //console.log("Event Frame Gap: ",eventFrameGap);
                if (shotChangeAware){
                    //console.log("Shot Change Aware is true");
                    let marker = $markerState.lists[0].markers.find(mark =>{
                        return parseFloat(mark.time).toFixed(5) === parseFloat(events[index].end).toFixed(5) || parseFloat(mark.time).toFixed(5) === parseFloat(events[index+1].start).toFixed(5);
                    });

                    //console.log("Marker Found:", marker);

                    if (marker){
                        if (parseFloat(marker.time).toFixed(5) === parseFloat(events[index+1].start).toFixed(5)){
                            if (events[index+1].start-frameGapSec <= event.start){
                                events[index+1].start = event.end + frameGapSec;
                            } else {
                                events[index].end = events[index+1].start-frameGapSec;
                            }
                        } else {
                            if (events[index].end+frameGapSec >= events[index+1].end){
                                events[index].end = events[index+1].start-frameGapSec;
                            } else {
                                events[index+1].start = event.end + frameGapSec;
                            }
                        }
                    } else {
                        //console.log("No marker found:");
                        if (events[index+1].start-frameGapSec <= event.start){
                            events[index+1].start = event.end + frameGapSec;
                        } else {
                            events[index].end = events[index+1].start-frameGapSec;
                        }
                    }
                } else {
                    if (events[index+1].start-frameGapSec <= event.start){
                        events[index+1].start = event.end + frameGapSec;
                    } else {
                        events[index].end = events[index+1].start-frameGapSec;
                    }
                }
            }
        });

        $eventGroupState[$projectState.selected] = $eventGroupState[$projectState.selected];

        historyState.insert({
            name: "insert frame gap", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        toast.push("Inserting blank frames finished successfully", {
            classes: ["toast-success"]
        });
    } catch (err) {
        console.error(err, err.message);
        toast.push("Inserting blank frames failed", {
            classes: ["toast-danger"]
        });
    } finally {
        modalState.hideModal();
    }
}
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'blankFrames' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="blankFramesModalTitle"
    aria-describedby="blankFramesModalDesc"
    tabindex="-1" 
    id="blankFramesModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="blankFramesModalTitle">Insert Blank Frames</h4>
                <button type="button" class="btn-close" id="closeBlankFramesModal" aria-label="Close dialog" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p id="blankFramesModalDesc" class="visually-hidden">Configure and insert blank frames between captions</p>
                <form on:submit|preventDefault="{insertBlankFrames}">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label" for="framesInput">Frames to Insert</label>
                            <input class="form-control" id="framesInput" type="number" min="0" step="1" 
                                aria-describedby="framesHelp" bind:value="{frames}">
                            <div id="framesHelp" class="form-text">Number of frames to insert between captions</div>
                        </div>
                        <div class="col my-3">
                            <label class="form-label" for="minFrameGap">Minimum Frame Gap</label>
                            <input class="form-control" id="minFrameGap" type="number" min="0" step="1" 
                                aria-describedby="minGapHelp" bind:value="{minFrameGap}">
                            <div id="minGapHelp" class="form-text">Minimum gap allowed between frames</div>
                        </div>
                        <div class="col my-3">
                            <label class="form-label" for="maxFrameGap">Maximum Frame Gap</label>
                            <input class="form-control" id="maxFrameGap" type="number" min="0" step="1" 
                                aria-describedby="maxGapHelp" bind:value="{maxFrameGap}">
                            <div id="maxGapHelp" class="form-text">Maximum gap allowed between frames</div>
                        </div>
                        <div class="mb-3 col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="shotChangeAware" 
                                    aria-describedby="shotChangeHelp" bind:checked={shotChangeAware}>
                                <label class="form-check-label" for="shotChangeAware">Shot Change Aware</label>
                                <div id="shotChangeHelp" class="form-text">Frame gap will be calculated using shot change markers</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary float-end" id="applyBlankFrames" type="button" 
                        on:click="{insertBlankFrames}" 
                        disabled={processing}
                        aria-busy={processing}>
                        {processing ? 'Processing...' : 'Apply'}
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
