<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    editorState
} from "@app/store/editorStore.js";
import {
    historyState
} from '@app/store/historyStore.js';
import {
    fade
} from 'svelte/transition';
import {
    environment
} from '@app/store/envStore.js';
import {
    v4 as uuidv4
} from 'uuid';
/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

/* CC LIB */
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';

let progress = 0,
    statusMsg = "Starting audio render...",
    queueSize = 10,
    eventQueue,
    totalEvents;
 
const ipcRenderer = window.ipcRenderer;

async function beginProcessing() {
    toast.push("Converting text to speech audio...", {classes: ['toast-info']});

    if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type !== "audio description") {
        toast.push("Selected Event Group must be of type: Audio Description", {classes: ['toast-danger']});

        modalState.hideModal();
    }

    try {
        eventQueue = JSON.parse(JSON.stringify($eventGroupState[$projectState.selected].events.filter((event, index) => {
            return $eventGroupState[$projectState.selected].selected.indexOf(index) > -1
        })));

        totalEvents = $eventGroupState[$projectState.selected].selected.length;
        statusMsg = "Processing Event Queue...";
        let elevenLabsEventIndex = eventQueue.findIndex((evt) => {
            return evt.voice.provider === "ElevenLabs"
        });

        if (elevenLabsEventIndex > -1) {
            renderAudio(eventQueue.splice(0, eventQueue.length > 2 ? 2 : eventQueue.length));
        } else {
            renderAudio(eventQueue.splice(0, eventQueue.length > queueSize ? queueSize : eventQueue.length));
        }

    } catch (err) {
        console.log(err, err.message);
        toast.push("Converting text to speech audio failed. " + err.message, {classes: ['toast-danger']});

        modalState.hideModal();
    }
}

function renderAudio(queue) {
    try {
        progress = (((totalEvents - eventQueue.length) / totalEvents) * 100).toFixed(2);
        statusMsg = `Rendering event ${totalEvents-eventQueue.length}-${Math.min(totalEvents-eventQueue.length+(queueSize-1), totalEvents)} of ${totalEvents}`;
        let renderTasks = [];
        queue.forEach((event) => {
            let plainText = convertToPlainText(event.text, " ");
            if (plainText) {
                try {
                    renderTasks.push(firebase.functions().httpsCallable('v8TextToSpeechProviderV3')({
                        provider: event.voice.provider,
                        language: event.voice.language,
                        voice: event.voice.name,
                        voice_id: event.voice.id,
                        stability: event.voiceStability / 100,
                        similarity: event.voiceSimilarity / 100,
                        speed: parseFloat(event.rate),
                        style: event.speakingStyle,
                        audioEncoding: "MP3",
                        text: plainText,
                        eventGroupId: $eventGroupState[$projectState.selected].id,
                        eventId: event.id
                    }));
                } catch (err) {
                    console.log("ERROR - FAILED CALL TO RENDER CLOUD");
                    console.log(err, err.message);
                    toast.push("Converting text to speech audio failed. " + err.message, {classes: ['toast-danger']});

                    modalState.hideModal();
                }
            }
        });

        Promise.allSettled(renderTasks).then((results) => {
            results.forEach(res => {
                if (res.status === "rejected") {
                    return;
                }

                if (!res.value) {
                    return;
                }

                let eventIndex = $eventGroupState[$projectState.selected].events.findIndex(event => {
                    return event.id === res.value.data.eventId;
                })

                let durationSec = tcLib.tcMsToSec(res.value.data.duration);

                //Testing AD Audio File Streaming
                /* if ($environment.electron && false) {
                    const os = window.os
                    const path = window.path
                    let audioFilePath = os.tmpdir() + path.sep + uuidv4() + ".mp3";
                    let trackHeight;

                    try {                        
                        trackHeight = document.getElementsByClassName('TimelineTrack')[0].clientHeight / 1.5;
                    } catch (err) {
                        trackHeight = 75;
                    }

                    writeFileStream(res.value.data.audio, audioFilePath).then(() => {
                        $eventGroupState[$projectState.selected].events[eventIndex].audioFile = audioFilePath;     
                        $eventGroupState[$projectState.selected].events[eventIndex].rendered = true;
                    });
                } */

                $eventGroupState[$projectState.selected].events[eventIndex].audioFile = res.value.data.audio;
                $eventGroupState[$projectState.selected].events[eventIndex].rendered = true;
                $eventGroupState[$projectState.selected].events[eventIndex].audioFileDuration = durationSec;

                //Auto Trim Event Setting
                if ($editorState.autoTrimEvent && !isNaN($eventGroupState[$projectState.selected].events[eventIndex].start && durationSec > 0)){
                    // console.log("Auto Trim Event");
                    $eventGroupState[$projectState.selected].events[eventIndex].end = $eventGroupState[$projectState.selected].events[eventIndex].start + durationSec + 0.5;
                }
            });

            if (eventQueue.length > 0) {
                let elevenLabsEventIndex = eventQueue.findIndex((evt) => {
                    return evt.voice.provider === "ElevenLabs"
                });

                if (elevenLabsEventIndex > -1) {
                    renderAudio(eventQueue.splice(0, eventQueue.length > 2 ? 2 : eventQueue.length));
                } else {
                    renderAudio(eventQueue.splice(0, eventQueue.length > queueSize ? queueSize : eventQueue.length));
                }
            } else {
                finishProcess();
            }
        }).catch(err => {
            console.log(err, err.message);
            toast.push("Converting text to speech audio failed. Please Retry:" + err.message, {classes: ['toast-danger']});

            modalState.hideModal();
        });
    } catch (err) {
        console.log(err, err.message);
        toast.push("Converting text to speech audio failed. " + err.message, {classes: ['toast-danger']});

        modalState.hideModal();
    }
}

/* async function writeFileStream(fileUrl, filePath) {
    if ($environment.electron) {
        try {
            await ipcRenderer.invoke("downloadRequest", {
                fileUrl: fileUrl,
                filePath: filePath
            });

            return filePath;
        } catch (err) {
            console.log(err);
            console.log(err.message);
            throw new Error("There was an error receiving the audio stream.");
        }
    }
} */

function finishProcess() {
    $eventGroupState = $eventGroupState;
    
    historyState.insert({
        name: "rendering audio", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });

    toast.push("Converting text to speech audio complete", {classes: ['toast-success']});

    modalState.hideModal();
}

beginProcessing();
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'renderAudio' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="RenderAudio">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Audio Rendering</h4>
            </div>
            <div class="modal-body">
                <h5>Rendering {totalEvents || 'No'} Selected Event(s)</h5>
                <p ><i class="bi bi-exclamation-diamond-fill"></i> Please leave this window open until rendering completes</p>
                <div class="progress">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" style="width: {progress}%;" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                </div>
                <p class="text-center text-muted small">{statusMsg}</p>
            </div>
        </div>
    </div>
</div>
