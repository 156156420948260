<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
let selectedEventId = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0]+1 : 0;
let totalEvents = $eventGroupState[$projectState.selected].events.length;

function selectEvent() {
    //console.log("Going to "+selectedEventId);
    if (selectedEventId <= $eventGroupState[$projectState.selected].events.length){
        try {
            $eventGroupState[$projectState.selected].selected = [selectedEventId-1];
            let eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, (selectedEventId-1)*230);  
            $eventGroupState[$projectState.selected].selected = [selectedEventId-1];
        } catch (err) {
            console.log(err.message);
            console.log(err);
        }
    }

    modalState.hideModal();
} 

function init(el){
    el.focus()
  }
</script>


<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'goToEvent' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="ProjectIncodeModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Go To Event</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault={selectEvent}>
                    <label for="basic-url" class="form-label">Event Id</label>
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="EventId" aria-label="EventId" step="1" min="1" max="{totalEvents}" bind:value={selectedEventId} on:focus="{(e)=>{e.target.select()}}" use:init>

                        <span class="input-group-text">/</span>

                        <input type="number" class="form-control" placeholder="Server" aria-label="Server" disabled bind:value={totalEvents}>
                      </div>
                      <button class="btn btn-primary float-end" type="submit">Select Event</button>
                </form>
            </div>
        </div>
    </div>
</div>
