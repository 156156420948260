import _eol from "eol";
import _formatTags from "../dict/formatTags.js";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const convertToPlainText = _convertToPlainText;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const formatTags = _formatTags;
const eol = _eol;
export default {
  decode: function (input, options) {
    let events = [];
    let ccEvent = false;
    let fileLines = input.split("\n");
    fileLines.forEach(fileLine => {
      if (fileLine.split(",").length === 2 && /\d\d:\d\d:\d\d\.\d\d/.test(fileLine.split(",")[0]) && /\d\d:\d\d:\d\d\.\d\d/.test(fileLine.split(",")[1])) {
        if (ccEvent) {
          ccEvent.text = convertToHtml(ccEvent);
          events.push(ccEvent);
        }
        ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.10
        });
        ccEvent.start = tcLib.tcToSec(fileLine.split(",")[0].replace(".", ":"), options.frameRate);
        ccEvent.end = tcLib.tcToSec(fileLine.split(",")[1].replace(".", ":"), options.frameRate);
      } else if (ccEvent) {
        ccEvent.text += fileLine.replace(/\[br\]/gmi, "\n") + "\n";
      }
    });
    if (ccEvent) {
      ccEvent.text = convertToHtml(ccEvent);
      events.push(ccEvent);
    }
    events.push(ccEvent);
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "";
    eventGroup.events.forEach(event => {
      //console.log(event.text,event.start,event.end);
      let tcIn = tcLib.secToTc(event.start, options.frameRate);
      let tcOut = tcLib.secToTc(event.end, options.frameRate);

      //console.log(event.start,tcIn,event.end,tcOut);
      output += tcIn.replace(/:(?=\d\d$)/, ".");
      output += ",";
      output += tcOut.replace(/:(?=\d\d$)/, ".");
      output += "\n";
      output += convertToPlainText(event.text, "[br]");
      output = output.slice(0, -4);
      output += "\n";
    });
    return output.trim();
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};