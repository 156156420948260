<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import insertEvent from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';
import getWordCount from '@app/external/cc-lib/dist/functions/quill/getWordCount.js';
import convertToHtml from '@app/external/cc-lib/dist/functions/quill/convertToHtml.js';
const splitEvent = function() {
    let range = quillEditor.getSelection();
    let length = quillEditor.getLength();
    //console.log(range, length);
    if (range && range.index < length-1) {
        let newEventEnd;
        let start = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start;
        let end = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end;
        let totalWordCount = getWordCount($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text);

        /* Split the event */
        if (quillEditor) {
            quillEditor.setSelection(range.index, quillEditor.getLength());
            document.execCommand('cut');
            navigator.clipboard.readText().then(text => {
                if (!isNaN(start) && !isNaN(end) && end > start){
                    let duration = end - start;
                    let wpmAvg = duration/totalWordCount;
                    let event1WordCount = getWordCount($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text);
                    let event2WordCount = getWordCount(text);
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start + (event1WordCount*wpmAvg);
                    newEventEnd = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end + (event2WordCount*wpmAvg);
                }

                let options = {
                    rate : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].rate,
                    xPos : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].xPos,
                    yPos : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].yPos,
                    xOffset : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].xOffset,
                    yOffset : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].yOffset,
                    vertical : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].vertical,
                    alignment: $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].alignment,
                    style : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].style,
                    voice : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].voice,
                    speakingStyle : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].speakingStyle,
                    text: convertToHtml(text),
                    start : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end,
                    end : newEventEnd || $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end
                }

                $eventGroupState[$projectState.selected] = insertEvent($eventGroupState[$projectState.selected], options, $eventGroupState[$projectState.selected].selected[0] + 1);

            });

            historyState.insert({
                name: "split event", //action name
                eventGroup: $projectState.selected,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });
        }
    }
};
</script>
<li class="nav-item" title="Split Event">
    <a class="nav-link" href="#!/" on:click="{splitEvent}" class:disabled={!$eventGroupState[$projectState.selected] ||$eventGroupState[$projectState.selected].selected.length !== 1}><i class="bi bi-view-stacked"></i><i class="bi bi-chevron-expand small"></i></a>
</li> 