<script>
import { eventGroupState } from "@app/store/eventGroupStore.js";
import { projectState } from "@app/store/projectStore.js";
import { styleState } from "@app/store/styleStore.js";
import flexbox from "@app/external/cc-lib/dist/dict/flexbox.js";
export let eventIndex;
export let height = 160;

function highlightExcessCharacters(htmlString) {
    if (!htmlString) return "";
    // Create a temporary DOM element to parse HTML
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const limit = $eventGroupState[$projectState.selected].maxChars;

    // Process each paragraph
    doc.querySelectorAll("p").forEach((p) => {
        const text = p.textContent;
        if (text.length > limit) {
            const before = text.slice(0, limit);
            const after = text.slice(limit);
            p.innerHTML = before + `<span class="bg-danger">${after}</span>`;
        }
    });
    // Return the processed HTML
    return doc.body.innerHTML;
}
</script>

<div
    class="text-white bg-secondary bg-opacity-50 p-2 EditorPreviewText text-{flexbox
        .alignmentMap[
        $eventGroupState[$projectState.selected]?.events[eventIndex]?.alignment
    ]}"
    style="height:{height}px; font-family: {$styleState.mode
        ? 'plexMono'
        : $styleState.fontFamily} !important; line-height: {$styleState.mode
        ? '100'
        : $styleState.lineSpacing}%; font-size: {$styleState.mode
        ? '16'
        : $styleState.fontSize}px; direction: {$eventGroupState[
        $projectState?.selected
    ]?.rtl
        ? 'rtl'
        : 'ltr'};">
    {@html highlightExcessCharacters(
        $eventGroupState[$projectState.selected]?.events[eventIndex]?.text,
    )}
</div>

<style>
.EditorPreviewText {
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
