<script>
import {environment} from '@app/store/envStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    BarLoader
} from 'svelte-loading-spinners'

/* Firebase */
import firebase from '@app/configs/firebase.js'
import db from '@app/configs/firestore.js'
/* cc-lib */
import insertEv from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';
import flexboxLib from '@app/external/cc-lib/dist/dict/flexbox.js';

let userId = $environment.online ? firebase.auth().currentUser.uid : null, eventTemplates = [];
let gettingRecords = getEventTemplates();

function getEventTemplates(){
    eventTemplates = [];
    if ($environment.online){
        return db.collection("users").doc(userId).collection('templates').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                eventTemplates = [...eventTemplates, doc.data()];
            });
        }).catch((err) => {
            console.log(err, err.message);
            toast.push(err.message, {classes: ['toast-danger']});
        });
    } else {
        return new Promise((resolve, reject) => {
            let templates = JSON.parse(localStorage.getItem('cc-event-templates')) || [];
            eventTemplates = templates;
            resolve();
        });
    }        
}

async function insertEventTemplate(eventTemplate){
    if (!$eventGroupState[$projectState.selected]){
        return;
    }

    let eventIndex = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0] + 1 : null;

    $eventGroupState[$projectState.selected] = insertEv($eventGroupState[$projectState.selected], eventTemplate, eventIndex);

    $eventGroupState[$projectState.selected].selected = eventIndex !== null ? [eventIndex] : [$eventGroupState[$projectState.selected].events.length - 1];

    /* Store in History */
    historyState.insert({
        name : "insert event", //action name
        eventGroup : $projectState.selected,
        snapshots : [
            {
                store : "eventGroupState",
                value : JSON.stringify($eventGroupState)
            }
        ]
    });
};

async function deleteEventTemplate(eventTemplate){
    // Refactor to use local storage instead of firebase when $environment.online is false

    if ($environment.online){
        db.collection("users").doc(userId).collection('templates').doc(eventTemplate.id).delete().then(() => {
            gettingRecords = getEventTemplates();
        }).catch(e => {
            console.log(e.message, e);
            toast.push("Failed to delete style guide: " + e.message, {classes: ['toast-danger']});
        })
    } else {
        eventTemplates = eventTemplates.filter(template => template.id !== eventTemplate.id);
        localStorage.setItem('cc-event-templates', JSON.stringify(eventTemplates));
        gettingRecords = getEventTemplates();
    }
}

</script>
<button type="button" class="btn btn-light btn-sm mb-2" title="Refresh" on:click="{() => getEventTemplates()}"><i class="bi bi-arrow-clockwise"></i></button>
<div id="EventTemplateListWrapper" class="resize-vertical">
    {#await gettingRecords}
    <div style="margin: 5% 20%">
        <BarLoader size="160" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
    </div>
    {:then}
    <ul class="list-group">
        {#each eventTemplates as eventTemplate (eventTemplate.id)}
        <a href="#!/" on:click={() => insertEventTemplate(eventTemplate)} class="list-group-item list-group-item-action" title="Insert Event">
            <div class="row">
                <div class="col-8 bg-light text-dark p-2 rounded text-{eventTemplate.alignment}">
                    {@html eventTemplate.text}
                </div>
                <div class="col-3">
                    <p class="m-0">{eventTemplate.style}</p>
                    <p class="m-0">H: {flexboxLib.flexMap.horizontal[eventTemplate.xPos]}</p>
                    <p class="m-0">V: {flexboxLib.flexMap.vertical[eventTemplate.yPos]}</p>
                </div>
                <div class="col-1 d-flex align-items-center">
                    <button class="btn btn-link" type="button" title="Delete Template" on:click={()=> deleteEventTemplate(eventTemplate)}><i class="bi bi-x-lg"></i></button>
                </div>
            </div>              
        </a>
        {:else}
         <p>No Event Templates Found</p>
        {/each}
    </ul>
    {/await}
</div>

<style>
    #EventTemplateListWrapper{
        overflow-x: hidden;
        overflow-y: auto;
        height: 9em;
    }
</style>