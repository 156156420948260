<script>
import {
    fade
} from 'svelte/transition';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';

import {
    historyState
} from '@app/store/historyStore.js';
import {
    v4 as uuidv4
} from 'uuid';
import Swal from 'sweetalert2';
import { toast } from '@zerodevx/svelte-toast';
import randomHexColor from 'random-hex-color';

let speakerName = "", speakerColour = randomHexColor(), mergeSpeakerOpt, selected;
function addSpeaker() {
    if (!speakerName){
        return;
    }
    $speakerState = [...$speakerState, {
        id: uuidv4(),
        name: speakerName,
        colour: speakerColour,
    }];

    clear();

    historyState.insert({
        name: "add speaker", //action name
        eventGroup: false,
        snapshots: [{
            store: "speakerState",
            value: JSON.stringify($speakerState)
        }]
    });
}

function editSpeaker(speaker){
    selected = speaker;
    speakerName = speaker.name;
    speakerColour = speaker.colour
}

function updateSpeaker(){
    try {
        let index = $speakerState.findIndex(sp =>{
            return sp.id === selected.id;
        });

        historyState.insert({
            name: "update speakers", //action name
            eventGroup: false,
            snapshots: [{
                store: "speakerState",
                value: JSON.stringify($speakerState)
            }]
        });

        if (mergeSpeakerOpt){
            //ask the user if they want to merge the speakers using sweetalert
            let mergedSpeakerIndex = $speakerState.findIndex(sp => sp.id === mergeSpeakerOpt);            
            if (mergedSpeakerIndex > -1 && index > -1 && selected.id !== mergeSpeakerOpt){
                console.log("Merge speaker", mergedSpeakerIndex, "with speaker", index);
                Swal.fire({
                    title: 'Merge Speakers',
                    text: `Are you sure you want to merge ${selected.name} with ${$speakerState[mergedSpeakerIndex].name}? ${$speakerState[mergedSpeakerIndex].name} will be the primary speaker and ${selected.name} will be removed.`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary me-2',
                        cancelButton: 'btn btn-secondary'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        $eventGroupState.forEach(eventGroup=>{
                            eventGroup.events.forEach((event, i, events) =>{
                                events[i].speakers.forEach((sp, c, s) =>{
                                    if (sp.id === selected.id || sp.name === selected.name){
                                        events[i].speakers[c] = $speakerState[mergedSpeakerIndex];
                                    }
                                });

                                //Ensure that all speakers assigned to the Event are unique
                                events[i].speakers = events[i].speakers.filter((value, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.id === value.id || t.name === value.name
                                    ))
                                );                                                          
                            });
                        });

                        $eventGroupState = $eventGroupState;
                        removeSpeaker(selected);

                        historyState.insert({
                            name: "update speakers", //action name
                            eventGroup: false,
                            snapshots: [{
                                store: "eventGroupState",
                                value: JSON.stringify($eventGroupState)
                            }]
                        });

                        historyState.insert({
                            name: "update speakers", //action name
                            eventGroup: false,
                            snapshots: [{
                                store: "speakerState",
                                value: JSON.stringify($speakerState)
                            }]
                        });
                    }
                }).finally(()=>{
                    clear();
                });
            } else {
                toast.push("Failed to merge speakers", {classes: ['toast-warning']});
                clear();
            }       
        } else if (index > -1 && speakerName){      
            $speakerState[index].name = speakerName;
            $speakerState[index].colour = speakerColour;
            /* Update Event Speakers */
            $eventGroupState.forEach(eventGroup=>{
                eventGroup.events.forEach((event, i, events) =>{
                    events[i].speakers.forEach((sp, c, s) =>{
                        if (sp.id === $speakerState[index].id){
                            s[c].name = $speakerState[index].name;
                            s[c].colour = $speakerState[index].colour;
                        }
                    });
                })
            });

            $eventGroupState = $eventGroupState;
            historyState.insert({
                name: "update speakers", //action name
                eventGroup: false,
                snapshots: [{
                    store: "speakerState",
                    value: JSON.stringify($speakerState)
                }]
            });

            historyState.insert({
                name: "update speakers", //action name
                eventGroup: false,
                snapshots: [{
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState)
                }]
            });

            clear();
        }
    } catch (err){
        console.error(err);
        toast.push("Failed to update speaker", {classes: ['toast-warning']});
    }
}

function removeSpeaker(speaker){
    console.log("REMOVING SPEAKER: "+speaker.name);
    $speakerState = $speakerState.filter(sp =>{
        return sp.id !== speaker.id;
    });

    /* Update Event Speakers */
    $eventGroupState.forEach(eventGroup=>{
        eventGroup.events.forEach((event, i, events) =>{
            events[i].speakers = event.speakers.filter(sp =>{
                return sp.id !== speaker.id;
            });
        });
    });

    $eventGroupState = $eventGroupState;

    clear()

    historyState.insert({
        name: "remove speaker", //action name
        eventGroup: false,
        snapshots: [{
            store: "speakerState",
            value: JSON.stringify($speakerState)
        }]
    });
}

function clear(){
    speakerName = "";
    speakerColour = randomHexColor();
    selected = undefined;
    mergeSpeakerOpt = undefined;
}
</script>

<div 
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'speakerManager' ? 'show d-block' : ''}"
    role="dialog"
    aria-modal="true"
    aria-labelledby="speakerManagerTitle"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="speakerManagerTitle">Speaker Manager</h4>
                <button 
                    type="button"
                    class="btn-close"
                    aria-label="Close dialog"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault={() => !selected ? addSpeaker() : updateSpeaker()}>
                    <div class="row mb-2">
                        <div class="col-9">
                            <label for="speakerName" class="form-label">Speaker Name</label>
                            <input 
                                type="text"
                                class="form-control"
                                id="speakerName"
                                bind:value={speakerName}
                                aria-required="true"
                            />
                        </div>
                        <div class="col-3">
                            <label for="speakerColor" class="form-label">Color</label>
                            <input 
                                type="color"
                                class="form-control"
                                id="speakerColor"
                                bind:value={speakerColour}
                                aria-label="Choose speaker color"
                            />
                        </div>
                        {#if selected}
                        <div class="col-9 my-2">
                            <label class="form-label" for="speakerMergeSelect">Merge With Speaker:</label>
                            <select id="speakerMergeSelect" class="form-select" bind:value={mergeSpeakerOpt}>
                                <option value="">Select Speaker</option>
                                {#each $speakerState as speaker}
                                <option value={speaker.id}>{speaker.name}</option>
                                {/each}
                            </select>
                        </div>
                        {/if}
                    </div>
                    <div class="mb-3 text-end">
                        {#if selected}
                        <button type="button" class="btn btn-primary" disabled={!speakerName} on:click="{updateSpeaker}">Update</button>
                        {:else}
                        <button type="button" class="btn btn-primary" disabled={!speakerName} on:click="{addSpeaker}">Add Speaker</button>
                        {/if}
                        <button type="button" class="btn btn-outline-dark" on:click="{clear}">Clear</button>
                    </div>
                </form>
                <div class="table-responsive">
                <table 
                    class="table table-sm table-striped"
                    aria-label="Speaker list"
                >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Speaker Name</th>
                        <th scope="col">Colour</th>
                        <th style="width:125px;" scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                        {#each $speakerState as speaker, index (speaker.id)}
                        <tr>
                            <td>{index+1}</td>
                            <td>{speaker.name}</td>
                            <td style="background-color: {speaker.colour}"></td>
                            <td class='text-center'>
                                <button class="btn btn-sm btn-info" type="button" title="Edit Speaker" on:click="{editSpeaker(speaker)}"><i class="bi bi-pencil-square"></i></button>
                                <button class="btn btn-sm btn-danger" type="button" title="Remove Speaker" on:click="{removeSpeaker(speaker)}"><i class="bi bi-trash"></i></button>
                            </td>                            
                        </tr>
                        {/each}
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .table-responsive {
        max-height: 500px;
        overflow: auto;
    }
</style>
