import _frameRates from "../dict/frameRates.js";
const frameRates = _frameRates;
export default {
  frameRate: 29.97,
  dropFrame: true,
  trgExt: "srt",
  encoding: "UTF-8",
  options: class {
    constructor(options = {
      profile: "subRip",
      formatOptions: [],
      frameRate: 29.97,
      dropFrame: true,
      incode: "00:00:00:00",
      window: {
        width: 1920,
        height: 1080,
        xOffset: 0,
        yOffset: 0
      },
      jobInfo: {}
    }) {
      this.profile = options.profile || "subRip", this.formatOptions = options.formatOptions || [], this.frameRate = options.frameRate || 29.97, this.dropFrame = options.dropFrame || frameRates.dropFrameMap[this.frameRate], this.window = options.window || {
        width: 1920,
        height: 1080,
        xOffset: 0,
        yOffset: 0
      }, this.jobInfo = options.jobInfo || {}, this.incode = options.incode || "00:00:00:00";
    }
  }
};