<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import EventGroupIcons from './EventGroupIcons.svelte';
export let name;
export let eventGroupIndex;
export let eventGroupType;

async function selectEventGroup() {
    $projectState.selected = eventGroupIndex;
    historyState.insert({
        name: "select Event Group", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "projectState",
            value: JSON.stringify($projectState),
        }, ],
    });
}
</script>

<li class="nav-item">
    <button
        type="button"
        id={"eventGroupTab-" + eventGroupIndex}
        aria-label={"Select event group " + name}
        class="btn nav-link btn-link rounded-0 rounded-top {$projectState.selected === eventGroupIndex ? 'active bg-light bg-gradient border-light shadow fw-bold' : ''}"
        on:click={selectEventGroup}
    >
        <EventGroupIcons type={eventGroupType} /> {name.substring(0,50)} <div class="float-end">
        <button class="btn btn-link ms-3 p-0 {$projectState.selected === eventGroupIndex ? 'text-dark' : ''}" type="button" on:click={() => modalState.showModal("eventGroupOptions")}><i class="bi bi-three-dots-vertical p-2" /></button>
    </div></button>
</li>