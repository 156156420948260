<script>
import {
    markerState
} from '@app/store/markerStore.js';
import {
    timelineZoomState
} from '@app/store/timelineZoomStore.js';
import TimelineMarker from './TimelineMarker.svelte';

export let scaleMultiplier;
export let scrollPosition;
export let width;

let markers = $markerState.lists[$markerState.selected].markers.filter(marker => {
    return (marker.time >= ((scrollPosition / scaleMultiplier) - ($timelineZoomState / 2)) && marker.time <= ((scrollPosition + width) / scaleMultiplier) + ($timelineZoomState / 2));
});

let nextUpdatePos = scrollPosition + (width * 0.25);
let prevUpdatePos = scrollPosition - (width * 0.25);

function refreshMarkers() {
    markers = $markerState.lists[$markerState.selected].markers.filter(marker => {
        return (marker.time >= ((scrollPosition / scaleMultiplier) - ($timelineZoomState / 2)) && marker.time <= ((scrollPosition + width) / scaleMultiplier) + ($timelineZoomState / 2));
    });

    nextUpdatePos = scrollPosition + (width * 0.25);
    prevUpdatePos = scrollPosition - (width * 0.25);
}

refreshMarkers();

$: if (scrollPosition >= nextUpdatePos || scrollPosition <= prevUpdatePos) {
    refreshMarkers();
}

$: if (scaleMultiplier) {
    refreshMarkers();
}


$: if ($markerState.selected > -1){
    console.log("refreshing markers");
    refreshMarkers();
}

/*       
    C:\Users\natha\Documents\Dev\cc-creator-8\node_modules\ffmpeg-static-electron\bin\win\x64\ffmpeg.exe -i C:\Users\natha\Videos\sintel.mp4 -vf "select='gt(scene,0.4)',showinfo,scale=320:240" -vsync 0 frames/%05d.jpg 
*/
</script>

{#each markers as marker}
<TimelineMarker tcSec="{marker.time}" comment="{marker.comment}" color="{$markerState.lists[$markerState.selected].color}" scaleMultiplier="{scaleMultiplier}"></TimelineMarker>
{/each}
