<script>
import {
  modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
  createEventDispatcher
} from 'svelte';
export let currentState;
const dispatch = createEventDispatcher();

function toggleMenu() {
  dispatch('toggleMenu', {
    menu: 'timecode'
  });
}

async function trimToDuration(){
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        try {
            if ($eventGroupState[$projectState.selected].events[eventIndex].audioFile && !isNaN($eventGroupState[$projectState.selected].events[eventIndex].audioFileDuration)){
                $eventGroupState[$projectState.selected].events[eventIndex].end = $eventGroupState[$projectState.selected].events[eventIndex].start + $eventGroupState[$projectState.selected].events[eventIndex].audioFileDuration+0.5;
            }            
        } catch(err){
            console.log(err.message, err);
        }
        
    });
}

function clearTimecodes(){
    $eventGroupState[$projectState.selected].selected.forEach(eventIndex => {
        try {
            $eventGroupState[$projectState.selected].events[eventIndex].start = false;
            $eventGroupState[$projectState.selected].events[eventIndex].end = false;
        } catch(err){
            console.log(err.message, err);
        }
        
    });
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}" role="menuitem">
    <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/" id="timecodeMenu" aria-haspopup="true" aria-expanded={currentState}>Timecode</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}" aria-labelledby="timecodeMenu" role="menu">
        <!-- Project Incode and Frame Rate -->
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("projectTimingSettings")} role="menuitem" id="projectSettingsBtn">Project Settings</a>
        <!-- Convert Time Code -->
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("convertTimecode")} role="menuitem" id="convertTimecodeBtn">Convert Timecode</a>
        <!-- Timecode Offset -->
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click={() => modalState.showModal("tcOffset")} role="menuitem" id="tcOffsetBtn" aria-disabled={!$eventGroupState[$projectState.selected] || !$eventGroupState[$projectState.selected].selected.length}>Timecode Offset...</a>
        
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click={() => modalState.showModal("timeStretch")} role="menuitem" id="timeStretchBtn" aria-disabled={!$eventGroupState[$projectState.selected] || !$eventGroupState[$projectState.selected].selected.length}>Stretch/Shrink...</a>

        <!-- Auto Correct Timing -->
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events.length > 0 ? '' : 'disabled'}" href="#!/" on:click={() => modalState.showModal("autoCorrectTiming")} role="menuitem" id="autoCorrectBtn" aria-disabled={!$eventGroupState[$projectState.selected] || !$eventGroupState[$projectState.selected].events.length}>Auto Correct Reading Speed</a>

        <a class="dropdown-item {$eventGroupState.length < 2 ? 'disabled' : ''}" href="#!/" on:click={() => modalState.showModal("copyTcFromGroup")} role="menuitem" id="copyTcBtn" aria-disabled={$eventGroupState.length < 2}>Copy TC From Group</a>
        {#if $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === 'audio description'}
            <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click={trimToDuration} role="menuitem" id="trimDurationBtn" aria-disabled={!$eventGroupState[$projectState.selected] || !$eventGroupState[$projectState.selected].selected.length}>Trim to Duration</a>
        {/if}
        <hr class="dropdown-divider">
        <a class="dropdown-item {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? '' : 'disabled'}" href="#!/" on:click={clearTimecodes} role="menuitem" id="clearTcBtn" aria-disabled={!$eventGroupState[$projectState.selected] || !$eventGroupState[$projectState.selected].selected.length}>Clear Timecodes</a>
    </div>
</li>
