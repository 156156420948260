<script>
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import { projectState } from "@app/store/projectStore.js";
	import { historyState } from "@app/store/historyStore.js";
    import {
    editorState
} from "@app/store/editorStore.js";
    import shiftWordForward from "@app/external/cc-lib/dist/functions/quill/shiftWordForward.js";
    import getAvgTimePerWord from "@app/external/cc-lib/dist/functions/events/getAvgTimePerWord.js";

	/* remove last word from event and add it to the beginning of the next event. */
	function shiftWordToNextEvent() {
		try {
			if (!$eventGroupState[$projectState.selected]) return;
            if ($eventGroupState[$projectState.selected].selected.length === 0) return;
            if ($eventGroupState[$projectState.selected].selected[0] === $eventGroupState[$projectState.selected].events.length -1) return;

            $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0]];

            let res = shiftWordForward($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]], $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]+1], $eventGroupState[$projectState.selected].maxChars);

            $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text = res.event1.text;

            $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]+1].text = res.event2.text;

            if (window.quillEditor){
                quillEditor.clipboard.dangerouslyPasteHTML(
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text
                );
            }

            if ($editorState.updateTimeOnEdit){
                let currentEvent = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]];
                let nextEvent = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]+1];

                if (currentEvent.start && currentEvent.end && nextEvent.start && nextEvent.end){
                    let avgTimePerWord = getAvgTimePerWord(currentEvent);

                    if (avgTimePerWord > 0){
                        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]+1].start -= avgTimePerWord;
                        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end -= avgTimePerWord;
                    }                                
                }
            }
            
			historyState.insert({
				name: "shift word", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});
		} catch (err) {
			console.log(err, err.message);
		}
	}
</script>

<li class="nav-item">
    <a 
        id="shift-word-down-btn"
        class="nav-link" 
        href="#!/" 
        class:disabled={!$eventGroupState[$projectState.selected] || 
            $eventGroupState[$projectState.selected].selected.length === 0 || 
            $eventGroupState[$projectState.selected].selected[0] === $eventGroupState[$projectState.selected].events.length - 1}
        aria-disabled={!$eventGroupState[$projectState.selected] || 
            $eventGroupState[$projectState.selected].selected.length === 0 || 
            $eventGroupState[$projectState.selected].selected[0] === $eventGroupState[$projectState.selected].events.length - 1}
        aria-label="Shift Word Down"
        role="button"
        on:click={shiftWordToNextEvent}><strong>w</strong><sub><i class="bi bi-arrow-down" aria-hidden="true"></i></sub></a>
</li>
