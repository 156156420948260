export default (class Media {
  constructor(options = {
    name: "",
    storage: "",
    localPath: "",
    path: "",
    audioPath: "",
    channel: 0,
    type: "video/mp4",
    duration: false,
    transcript: {},
    sceneChanges: [],
    /* Scene change values in seconds */
    adTemplate: [],
    waveformUrl: "",
    thumbnail: "",
    aspectRatio: "16:9",
    frameRate: 29.97,
    dropFrame: true,
    language: "en-US",
    info: {},
    peaksPath: "",
    useFallback: true
  }) {
    this.name = options.name || "";
    this.storage = options.storage || "";
    this.localPath = options.localPath || "", this.path = options.path || "";
    this.audioPath = options.audioPath || "";
    this.channel = options.channel || 0;
    this.type = options.type || "video/mp4";
    this.duration = options.duration;
    this.transcript = options.transcript || {};
    this.sceneChanges = options.sceneChanges || [];
    this.adTemplate = options.adTemplate || [];
    this.waveformUrl = options.waveformUrl || "";
    this.thumbnail = options.thumbnail || "";
    this.aspectRatio = options.aspectRatio || "16:9";
    this.frameRate = options.frameRate || 29.97;
    this.dropFrame = options.dropFrame || true;
    this.info = options.info || {};
    this.peaksPath = options.peaksPath || "";
    this.useFallback = true;
  }
});