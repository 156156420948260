<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import SubtitleEvent from './events/SubtitleEvent.svelte';
export let scrollPosition = 0;

let startingIndex = Math.floor(scrollPosition/230)-5;
startingIndex = startingIndex > -1 ? startingIndex : 0;
let endingIndex = startingIndex + 20; //The overscan so we always render at least 20
let events = $eventGroupState[$projectState.selected].events.slice(startingIndex, endingIndex);
let totalEvents = $eventGroupState[$projectState.selected].events.length;
let currentEventGroup = $projectState.selected;
let nextEventUpdatePos = (endingIndex-8)*230;
let prevEventUpdatePos = -1;

function refreshEvents(){
    if (!$eventGroupState[$projectState.selected]){        
        return; 
    }
    
    startingIndex = Math.floor(scrollPosition/230)-5;
    startingIndex = startingIndex > -1 ? startingIndex : 0;
    startingIndex = startingIndex%2 === 0 ? startingIndex : startingIndex+1;
    endingIndex = startingIndex + 20; //The overscan so we always render at least 20
    events = $eventGroupState[$projectState.selected].events.slice(startingIndex, endingIndex);
    totalEvents = $eventGroupState[$projectState.selected].events.length;
    currentEventGroup = $projectState.selected;
    nextEventUpdatePos = (endingIndex-8)*230;
    prevEventUpdatePos = (endingIndex-15)*230;
}

$ : if (scrollPosition >= nextEventUpdatePos || scrollPosition <= prevEventUpdatePos) {
    refreshEvents();
}

$ : if ($eventGroupState[$projectState.selected].events.length !== totalEvents){
    refreshEvents();
}

$ : if ($projectState.selected !== undefined && $projectState.selected > -1){
    console.log("UPDATE TO SUBTITLE LIST");
    refreshEvents();
}
</script>

<div role="list" style="position:relative; top: {230*startingIndex}px;">
{#each events as event, index (event.id)}
<li
    role="listitem"
    aria-label="Subtitle event {index+startingIndex}"
    id={'Event'+(index+startingIndex)}
    class="border-bottom border-secondary"
>
    <SubtitleEvent eventIndex={index+startingIndex}></SubtitleEvent>
</li>
{/each}
</div>
