export default (function tcOffset(event, sec, type = "add") {
  switch (type) {
    case "add":
      event.start += parseFloat(sec);
      event.end += parseFloat(sec);
      break;
    default:
      event.start -= parseFloat(sec);
      event.end -= parseFloat(sec);
  }
  return event;
});