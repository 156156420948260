import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class EventGroup {
  constructor(options = {
    extId: "",
    name: "Untitled",
    /* Event group name */
    type: "subtitle",
    /* subtitle, transcription, translation, or audio description */
    linkedGroup: false,
    incode: 0,
    frameRate: 29.97,
    dropFrame: true,
    originalFontFamily: "arial",
    originalRtl: false,
    events: [],
    speakers: [],
    incode: 0,
    selected: [],
    /* selected events in group */
    scrollPosition: 0,
    overlap: true,
    maxCps: 25,
    maxWpm: 250,
    maxChars: 32,
    maxLines: 2,
    minDuration: 1,
    maxDuration: 10,
    illegalChars: false,
    language: "en-US",
    /* event group language */
    ad: {
      gender: "male",
      language: "en-US",
      voice: {
        "provider": "google",
        "name": "en-US-Wavenet-A",
        "styles": ["default"],
        "gender": "male",
        "language": "en-US"
      }
    },
    //AD Settings
    rtl: false,
    /* Right To Left Support */
    metadata: []
  }) {
    this.id = uuidv4();
    this.extId = options.extId ?? "";
    this.type = options.type || "subtitle";
    this.linkedGroup = options.linkedGroup ?? false;
    this.incode = options.incode ?? 0;
    this.frameRate = options.frameRate || 29.97;
    this.dropFrame = options.dropFrame ?? true;
    this.originalFontFamily = options.originalFontFamily || "arial", this.originalRtl = options.originalRtl ?? false, this.name = options.name || "Untitled";
    this.events = options.events || [];
    this.speakers = options.speakers || [];
    this.incode = options.incode ?? 0;
    this.selected = options.selected || [];
    this.scrollPosition = options.scrollPosition ?? 0;
    this.overlap = options.overlap;
    this.maxCps = options.maxCps || 25;
    this.maxWpm = options.maxWpm || 250;
    this.maxChars = options.maxChars || 32;
    this.maxLines = options.maxLines || 2;
    this.minDuration = options.minDuration || 1;
    this.maxDuration = options.maxDuration || 12;
    this.illegalChars = options.illegalChars;
    this.language = options.language || "en-US";
    this.ad = options.ad || {
      gender: "female",
      language: "en-US",
      voice: {
        "provider": "google",
        "name": "en-US-Studio-O",
        "styles": ["default"],
        "gender": "female",
        "language": "en-US"
      }
    };
    this.rtl = options.rtl ?? false;
    this.metadata = options.metadata || [];
  }
});