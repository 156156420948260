<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    authState
} from '@app/store/authStore.js';
import {
    fade
} from 'svelte/transition';
import Swal from 'sweetalert2';
import throttle from 'just-throttle';
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import offset from "@app/external/cc-lib/dist/functions/events/tcOffset.js";
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
import replaceTrialText from "@app/external/cc-lib/dist/functions/eventGroups/replaceTrialText.js";
import {
    Document,
    Packer,
    Paragraph,
    TextRun
} from 'docx';
import eol from 'eol';
import {
    saveAs
} from 'file-saver';
import Papa from 'papaparse';

/* Firebase */
import firebase from '@app/configs/firebase.js';
import 'firebase/compat/functions';

let eventGroup = getSelectedEventGroup(["subtitle", "translation", "audio description", "transcription"]),
    preset = 'subtitle', //paragraph, subtitle, table
    fileFormat = 'txt', //txt, csv, docx
    fileContents = "",
    fileBlob,
    exportMetadata = true,
    exportSpeakers = true,
    exportPrimaryLang = true,
    exportIncode = true,
    exportOutcode = true,
    tcFormat = "smpte",
    autoGroupByTc = true,
    duration = 2,
    showOptions = false,
    tcOffset = tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame) || "00:00:00:00",
    tcOffsetType = "add";

if ($authState.status === "in_trial"){
    showTrialWarning();
}

function validateTc(e) {
    tcOffset = tcLib.tcValidate(e.target.value, parseFloat($projectState.frameRate), $projectState.dropFrame);
}

function getSelectedEventGroup(validTypes) {
    let evGroup = $eventGroupState[$projectState.selected];
    if (evGroup){
        if (validTypes.indexOf(evGroup.type) > -1) {
            return evGroup;
        } else {
            return false;
        }
    } else {
        return false;
    }    
}

function showTrialWarning(){
    Swal.fire({
        titleText: "Trial Mode",
        text : "Thank you for trying Closed Caption Creator. Your subscription is currently running in Trial Mode. All exports will contain the word TRIAL. Would you like to activate your account in order to remove this restriction?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Activate Account',
        denyButtonText: 'Continue Trial',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            denyButton: 'btn btn-outline-secondary',
        }
    }).then((result) => {
        if (result.isConfirmed) {
            activateSubscription();
            return true;
        } else {
            return false;
        }
    }).then((res) => {
        if (res){
            console.log(res);
            showRestartNotification();
        }
        
        return true;
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function showRestartNotification(){
    Swal.fire({
        titleText: "Restart Required",
        text : "Thank you for activating your subscription. Please save your work and restart Closed Caption Creator to continue.",
        confirmButtonText: 'Ok',
        allowOutsideClick : false,
        allowEscapeKey : false,
        buttonsStyling : false,
        customClass: {
            confirmButton: 'btn btn-light'
        }
    }).then(res =>{
        console.log(res);
    }).catch(err =>{
        console.log(err);
        console.log(err.message)
    });
}

function exportTranscription() {
    let paragraphs = [],
        metadataPart = eol.after("****METADATA****"),
        eventPart = "",
        csvContent = "START,END,SPEAKER,ORIGINAL,TEXT,NOTES,STYLE,TAGS\n",
        fileName = eventGroup.name;

    let copyOfEventGroup = JSON.parse(JSON.stringify(eventGroup));
    if ($authState.status === "in_trial"){
        copyOfEventGroup = replaceTrialText(copyOfEventGroup);
    }

    /* tcOffset */
    if (tcOffset && tcOffset !== "00:00:00:00") {
        copyOfEventGroup.events.forEach((event, index, events) => {
            events[index] = offset(event, tcLib.tcToSec(tcOffset, $projectState.frameRate, $projectState.dropFrame), tcOffsetType);
        });
    }

    if (fileFormat === "csv") {
        let csvContent = Papa.unparse(JSON.stringify(
            copyOfEventGroup.events.map(event =>{
                return {
                    start : event.start,
                    end : event.end,
                    startSmpte : tcLib.secToTc(event.start, $projectState.frameRate, $projectState.dropFrame),
                    endSmpte : tcLib.secToTc(event.end, $projectState.frameRate, $projectState.dropFrame),
                    startMs : tcLib.secToTcMs(event.start),
                    endMs : tcLib.secToTcMs(event.end),
                    speakers : event.speakers.map(speaker => {return speaker.name}).join(" | "),
                    original : convertToPlainText(event.original),
                    text : convertToPlainText(event.text),
                    notes : event.notes,
                    style : event.style,
                    tags : event.tags.join(" | ")
                }
            })
        ));

        fileBlob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8"
        });

        saveAs(fileBlob, fileName + ".csv", {
            autoBom: true
        });
    } else {
        /* Metadata */
        if (exportMetadata) {
            paragraphs.push(eol.after("ID: " + $projectState.id + " | Name: " + $projectState.name + " | Author: " + $projectState.username));

            if ($metadataState.length === 0) {
                metadataPart += eol.after("No metadata found");
            } else {
                $metadataState.forEach(metadata => {
                    metadataPart += eol.after(metadata.type + ": " + metadata.value);
                });
            }

            paragraphs.push(metadataPart);
        }

        if (preset === "paragraph") {
            let groupsByTc = [];
            let groupByTc = [];
            /* Group events based on the time distance between them */
            copyOfEventGroup.events.filter(event => {
                return event.text
            }).sort((eventA, eventB) => {
                return eventA.start - eventB.start;
            }).forEach((event, eventIndex, events) => {
                if (eventIndex > 0 && (!autoGroupByTc || event.start - events[eventIndex - 1].end > duration)) {
                    groupsByTc.push(groupByTc);
                    groupByTc = [event];
                } else {
                    groupByTc.push(event);
                }
            });

            if (groupByTc.length > 0) {
                groupsByTc.push(groupByTc);
                groupByTc = [];
            }

            groupsByTc.forEach(tcGroup => {
                eventPart = "";

                if (exportIncode) {
                    switch (tcFormat) {
                        case 'smpte':
                            eventPart += tcLib.secToTc(tcGroup[0].start, $projectState.frameRate, $projectState.dropFrame) + "\t";
                            break;
                        case 'ms':
                            eventPart += tcLib.secToTcMs(tcGroup[0].start) + "\t";
                            break;
                        default:
                            eventPart += tcGroup[0].start  + "\t";
                    }
                }

                if (exportOutcode) {
                    switch (tcFormat) {
                        case 'smpte':
                            eventPart += tcLib.secToTc(tcGroup[tcGroup.length - 1].end, $projectState.frameRate, $projectState.dropFrame);
                            break;
                        case 'ms':
                            eventPart += tcLib.secToTcMs(tcGroup[tcGroup.length - 1].end);
                            break;
                        default:
                            eventPart += tcGroup[tcGroup.length - 1].end;
                    }
                }

                eventPart = eol.after(eventPart);
                tcGroup.forEach(tcEvent => {
                    eventPart += convertToPlainText(tcEvent.text, " ") + " ";
                });

                paragraphs.push(eol.after(eventPart));
            });

        } else {
            /* Subtitle Preset */
            copyOfEventGroup.events.filter(event => {
                return event.text.trim();
            }).forEach((event, count) => {
                eventPart = eol.after(`${count+1})`);         

                if (exportIncode) {
                    switch (tcFormat) {
                        case 'smpte':
                            eventPart += tcLib.secToTc(event.start, $projectState.frameRate, $projectState.dropFrame) + "\t";
                            break;
                        case 'ms':
                            eventPart += tcLib.secToTcMs(event.start) + "\t";
                            break;
                        default:
                            eventPart += event.start + "\t";
                    }
                }

                if (exportOutcode) {
                    switch (tcFormat) {
                        case 'smpte':
                            eventPart += tcLib.secToTc(event.end, $projectState.frameRate, $projectState.dropFrame);
                            break;
                        case 'ms':
                            eventPart += tcLib.secToTcMs(event.end);
                            break;
                        default:
                            eventPart += event.end;
                    }
                }

                eventPart += "\n" + eol.after(convertToPlainText(event.text));

                if ((eventGroup.type === "audio description" || eventGroup.type === "transcription") && event.notes){
                    eventPart += "\n\t" + eol.after("NOTES: "+ event.notes);
                }
                if (eventGroup.type === "transcription" && event.tags.length > 0){                   
                    eventPart += "\n\t" + eol.after("TAGS: "+ event.tags.map(tag => {return tag.term}).join(", "));
                }

                paragraphs.push(eventPart);
            });
        }

        if (fileFormat === "txt") {
            fileContents = paragraphs.join("\n");
            fileBlob = new Blob([fileContents], {
                type: "text/plain;charset=utf-8"
            });

            saveAs(fileBlob, fileName + ".txt", {
                autoBom: true
            });
        } else {
            /* DocX */
            let textRuns = [];
            paragraphs.forEach((paragraphText) => {
                eol.split(paragraphText).forEach(pLine =>{
                    textRuns.push(
                        new TextRun({
                            text: pLine,
                            break: 1
                        })
                    );
                });
            });

            const doc = new Document({
                creator: "Closed Caption Creator Inc.",
                title: fileName,
                sections: [{
                    properties: {},
                    children: [
                        new Paragraph({
                            children: textRuns,
                        }),
                    ],
                }]
            });

            Packer.toBlob(doc).then(fileBlob => {
                saveAs(fileBlob, fileName + ".docx");
            });

        }
    }
    modalState.hideModal();
}

const activateSubscription = throttle(async () => {
    console.log("Activating subscription");
    let res = await firebase.functions().httpsCallable("v8ActivateSubscription")($authState.subId);
    console.log("subscription activation run:", res);
}, 10000, { leading: true });
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'transcriptExport' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TranscriptExportModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Export Transcription</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <p class="small text-muted"><i class="bi bi-lightning-fill"></i> Powered by Closed Caption Converter | www.closedcaptionconverter.com</p>
                <form on:submit|preventDefault="{exportTranscription}">
                    <div class="mb-3">
                        <label class="form-label" for="EventGroupSelection">Event Group</label>
                        <select class="form-select" bind:value="{eventGroup}">
                            {#each $eventGroupState as eventGroupOption}
                            <option value="{eventGroupOption}" selected="{eventGroup.id === eventGroupOption.id}">{eventGroupOption.name}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="presetSelection">Template Preset</label>
                        <select class="form-select" bind:value="{preset}">
                            <option value="paragraph">Paragraph</option>
                            <option value="subtitle">Subtitle</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="presetSelection">File Format</label>
                        <select class="form-select" bind:value="{fileFormat}">
                            <option value="docx">.docx</option>
                            <option value="txt">.txt</option>
                            <option value="csv">.csv</option>
                        </select>
                    </div>
                    <button type="button" class="btn btn-outline-warning mb-2 dropdown-toggle" on:click="{()=> {showOptions = !showOptions}}">More Options</button>
                    {#if showOptions}
                    <div id="advancedOptions" class="p-3 bg-secondary shadow rounded">
                        <!-- Timecode Offset -->
                        <div class="row">
                            <p class="text-warning mb-1">Timecode Options</p>
                            <div class="mb-3 col-8">
                                <label class="form-label" for="tcOffset">TC Offset</label>
                                <input type="text" class="form-control" bind:value="{tcOffset}" on:blur={validateTc} on:focus="{(e)=>{e.target.select()}}"/>
                            </div>

                            <div class="mb-3 col-4">
                                <label class="form-label" for="tcOffsetType">Offset Type</label>
                                <select class="form-select" bind:value="{tcOffsetType}">
                                    <option>add</option>
                                    <option>subtract</option>
                                </select>
                            </div>
                        </div>
                        {#if preset==='paragraph'}
                        <div class="row">
                            <p class="text-warning mb-1">Grouping Options</p>
                            <div class="mb-3 col-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" bind:checked="{autoGroupByTc}" id="groupEventsByTcCheck">
                                    <label class="form-check-label" for="groupEventsByTcCheck">
                                        Group Events by Timecode
                                    </label>
                                </div>
                            </div>
                            {#if autoGroupByTc}
                            <div class="mb-3 col">
                                <label class="form-label" for="durationControlRange">Duration <i class="bi bi-info-circle" title="Set the duration of empty space between events before creating a new paragraph. e.g. If the space between two events exceeds 4 seconds a new paragraph will be created for the second event."></i> <small>({duration}sec)</small></label>
                                <input type="range" min="0" max="100" step="0.5" id="durationControlRange" bind:value="{duration}">
                            </div>
                            {/if}
                        </div>
                        <hr/>
                        {/if}
                        <div class="row">
                            <p class="text-warning mb-1">Content Options</p>
                            <div class="mb-3 col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" bind:checked="{exportIncode}" id="exportIncodeCheck">
                                    <label class="form-check-label" for="exportIncodeCheck">
                                        Include Start Time
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3 col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" bind:checked="{exportOutcode}" id="exportOutcodeCheck">
                                    <label class="form-check-label" for="exportOutcodeCheck">
                                        Include End Time
                                    </label>
                                </div>
                            </div>
                        </div>
                        {#if exportIncode || exportOutcode}
                        <div class="mb-3">
                            <label class="form-label" for="tcFormat">Timecode Format</label>
                            <select class="form-select" bind:value="{tcFormat}">
                                <option value="smpte">SMPTE (hh:mm:ss:ff)</option>
                                <option value="ms">Millisecond TC (hh:mm:ss.mmm)</option>
                                <option value="seconds">seconds (ss.mmm)</option>
                            </select>
                        </div>
                        {/if}
                        <hr/>
                        <div class="row">
                            {#if eventGroup && eventGroup.type === "transcription"}
                            <div class="mb-3 col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" bind:checked="{exportSpeakers}" id="exportSpeakersOption">
                                    <label class="form-check-label" for="exportSpeakersOption">
                                        Include Speakers
                                    </label>
                                </div>
                            </div>
                            {/if}
                            {#if fileFormat !== 'csv'}
                            <div class="mb-3 col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" bind:checked="{exportMetadata}" id="exportMetdataOption">
                                    <label class="form-check-label" for="exportMetdataOption">
                                        Include Metadata
                                    </label>
                                </div>
                            </div>
                            {/if}
                            {#if eventGroup && eventGroup.type === "translation" && fileFormat === 'csv'}
                            <div class="mb-3 col">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" bind:checked="{exportPrimaryLang}" id="exportPrimaryLangOpt">
                                    <label class="form-check-label" for="exportPrimaryLangOpt">
                                        Include Original Language
                                    </label>
                                </div>
                            </div>
                            {/if}
                        </div>
                    </div>
                    {/if}

                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" on:click="{exportTranscription}" disabled="{!eventGroup}">Export</button>
            </div>
        </div>
    </div>
</div>

<style>
#advancedOptions {
    overflow-y: auto;
    overflow-x: none;
    max-height: 50vh;
}
</style>
