import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _ExcelJs from "exceljs";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _Speaker from "../classes/speaker.js";
import _Event from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const Speaker = _Speaker;
const convertToHtml = _convertToHtml;
const removeInvalidEvents = _removeInvalidEvents;
const ExcelJs = _ExcelJs;
const convertToPlainText = _convertToPlainText;
export default {
  decode: async function (input, options) {
    let events = [],
      speakers = [],
      tags = [];
    let inputJson = JSON.parse(input);
    inputJson.sheets.forEach(sheet => {
      sheet.rows.forEach(row => {
        // console.log(row);
        let ccEvent = new Event();
        row.cells.forEach(cell => {
          try {
            //Map cell.value to ccEvent properties based on index. 2 = FS (FREEZE = true),  3 = start, 4 = end, 5 = speakers, 6 = text, 7= notes
            // console.log(index, cell.value);

            //Get first letter from cell.address
            let cellCol = cell.address.charAt(0).toUpperCase();
            switch (cellCol) {
              case "B":
                ccEvent.forced = cell.value && cell.value.toLowerCase() === "freeze" ? true : false;
                break;
              case "C":
                if (cell.value === undefined || cell.value === "") {
                  break;
                }
                ccEvent.start = tcLib.tcToSec(cell.value.replace(".", ":"), options.frameRate, options.dropFrame);
                break;
              case "D":
                if (cell.value === undefined || cell.value === "") {
                  return;
                }
                ccEvent.end = tcLib.tcToSec(cell.value.replace(".", ":"), options.frameRate, options.dropFrame);
                break;
              case "E":
                if (cell.value === undefined || cell.value === "") {
                  break;
                }
                let sp = cell.value;
                let existingSpeaker = speakers.find(s => s.name === sp);
                if (existingSpeaker) {
                  ccEvent.speakers.push(existingSpeaker);
                } else {
                  let newSpeaker = new Speaker({
                    name: sp
                  });
                  speakers.push(newSpeaker);
                  ccEvent.speakers.push(newSpeaker);
                }
                break;
              case "F":
                if (cell.value === undefined || cell.value === "") {
                  return;
                }
                ccEvent.text = convertToHtml(cell.value);
                break;
              case "G":
                if (cell.value === undefined || cell.value === "") {
                  return;
                }
                ccEvent.notes = cell.value;
                break;
              default:
                break;
            }
          } catch (err) {
            console.log(err.message);
          }
        });
        if (ccEvent.start && ccEvent.end) {
          events.push(ccEvent);
        }
      });
    });
    // console.log(events);
    return events;
  },
  encode: async function (eventGroup, options) {
    let output;

    //CSV Headers: Event | FS | TimeCode In | TimeCode Out | Character | Dialogue | Note
    let rows = [["Event", "FS", "TimeCode In", "TimeCode Out", "Character", "Dialogue", "Note"]];
    eventGroup.events.forEach((event, index) => {
      rows.push([index + 1, event.forced ? "FREEZE" : "", tcLib.secToTc(Math.max(0, event.start), options.frameRate, options.dropFrame).replace(/[:;](?=[^:;]*$)/, '.'), tcLib.secToTc(Math.max(0, event.end), options.frameRate, options.dropFrame).replace(/[:;](?=[^:;]*$)/, '.'), event.speakers.map(sp => {
        return sp.name;
      }).join(","), convertToPlainText(event.text), event.notes]);
    });
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet("DVW QC Script");
    worksheet.addRows(rows);
    output = await workbook.xlsx.writeBuffer();
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup, true);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};