<script>
import {
    projectState
} from '@app/store/projectStore.js';
import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
export let tcSec = 0;
export let comment = "";
export let color = "#E74C3C";
export let scaleMultiplier = 10;

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div 
    role="button"
    tabindex="0"
    class="TimelineMarker d-flex align-items-end" 
    style="left:{(tcSec*scaleMultiplier)}px; background-color: {color};" 
    title="{tcLib.secToTc(tcSec+$projectState.incode, $projectState.frameRate, $projectState.dropFrame)} | {comment}" 
    on:click="{(e) => {window.player.currentTime = tcSec}}"
    on:keypress="{(e) => e.key === 'Enter' && (window.player.currentTime = tcSec)}"
    aria-label="Marker at {tcLib.secToTc(tcSec+$projectState.incode, $projectState.frameRate, $projectState.dropFrame)} - {comment}">
    <i class="bi bi-caret-up-fill" style="color: {color};" aria-hidden="true"></i>
</div>

<style>
.TimelineMarker {   
    position: absolute;
    height: 100%;
    width: 2px;
    bottom: 0px;
}

.bi-caret-up-fill {
    transform: translateX(-7.25px);
    height:15px;
}
</style>
