<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
export let eventIndex;
export let xPos = "center";
export let yPos = "start";
let position = yPos + xPos;

function updateEventPosition(pos){
    $eventGroupState[$projectState.selected].events[eventIndex].yPos = pos.y;
    $eventGroupState[$projectState.selected].events[eventIndex].xPos = pos.x;
    $eventGroupState[$projectState.selected].events[eventIndex].xOffset = 0;
    $eventGroupState[$projectState.selected].events[eventIndex].yOffset = 0;

    historyState.insert({
        name: "update position", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}

$: position = yPos + xPos;

</script>

<div class="row">
    <div class="col lh-1 pe-0">
        <label for="xOffsetRange" class="form-label">X Offset ({$eventGroupState[$projectState.selected].events[eventIndex].xOffset}px)</label>
        <input id="xOffsetRange" aria-label="X offset" on:dblclick="{() => $eventGroupState[$projectState.selected].events[eventIndex].xOffset = 0}" type="range" class="form-range" title="X Offset ({$eventGroupState[$projectState.selected].events[eventIndex].xOffset}px)" min="-100" max="100" step="1" bind:value="{$eventGroupState[$projectState.selected].events[eventIndex].xOffset}">
        <label for="yOffsetRange" class="form-label">Y Offset ({$eventGroupState[$projectState.selected].events[eventIndex].yOffset}px)</label>
        <input id="yOffsetRange" aria-label="Y offset" on:dblclick="{() => $eventGroupState[$projectState.selected].events[eventIndex].yOffset = 0}" type="range" class="form-range" title="Y Offset ({$eventGroupState[$projectState.selected].events[eventIndex].yOffset}px)" min="-100" max="100" step="1" bind:value="{$eventGroupState[$projectState.selected].events[eventIndex].yOffset}">
    </div>
    <div class="col-3 p-0 ms-1 EventPositionInput">
        <div class="row g-0">
            <div class='col-4' title="Position: Top Left">
                <input id="pos-top-left" aria-label="Position top left" type="radio" on:change={() => updateEventPosition({x:"start",y:"start"})} bind:group={position} name="position" value={'startstart'}>
            </div>
            <div class='col-4' title="Position: Top Center">
                <input id="pos-top-center" aria-label="Position top center" type="radio" on:change={() => updateEventPosition({x:"center",y:"start"})} bind:group={position} name="position" value={'startcenter'}>
            </div>
            <div class='col-4' title="Position: Top Right">
                <input id="pos-top-right" aria-label="Position top right" type="radio" on:change={() => updateEventPosition({x:"end",y:"start"})} bind:group={position} name="position" value={'startend'}>
            </div>
            <div class='col-4' title="Position: Center Left">
                <input id="pos-center-left" aria-label="Position center left" type="radio" on:change={() => updateEventPosition({x:"start",y:"center"})} bind:group={position} name="position" value={'centerstart'}>
            </div>
            <div class='col-4' title="Position: Center">
                <input id="pos-center" aria-label="Position center" type="radio" on:change={() => updateEventPosition({x:"center",y:"center"})} bind:group={position} name="position" value={'centercenter'}>
            </div>
            <div class='col-4' title="Position: Center Right">
                <input id="pos-center-right" aria-label="Position center right" type="radio" on:change={() => updateEventPosition({x:"end",y:"center"})} bind:group={position} name="position" value={'centerend'}>
            </div>
            <div class='col-4' title="Position: Bottom Left">
                <input id="pos-bottom-left" aria-label="Position bottom left" type="radio" on:change={() => updateEventPosition({x:"start",y:"end"})} bind:group={position} name="position" value={'endstart'}>
            </div>
            <div class='col-4' title="Position: Bottom Center">
                <input id="pos-bottom-center" aria-label="Position bottom center" type="radio" on:change={() => updateEventPosition({x:"center",y:"end"})} bind:group={position} name="position" value={'endcenter'}>
            </div>
            <div class='col-4' title="Position: Bottom Right">
                <input id="pos-bottom-right" aria-label="Position bottom right" type="radio" on:change={() => updateEventPosition({x:"end",y:"end"})} bind:group={position} name="position" value={'endend'}>
            </div>
        </div>
    </div>
</div>
<style>
    .EventPositionInput {
        transform: scale(0.7) translate(0%, 10%);
    }

    .form-range {
        height: 0.5rem;
        color:aquamarine;
    }

    .form-label {
        font-size: 10px;
        margin: 0;
    }
</style>