<script>
    /* States */
    import {
        explorerState
    } from '@app/store/explorerStore.js';
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { authState } from "@app/store/authStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { issueState } from "@app/store/issueStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { Circle } from "svelte-loading-spinners";
    import { toast } from "@zerodevx/svelte-toast";
    import { modalState } from "@app/store/modalStore.js";
    import Swal from "sweetalert2";
    import refreshId from "@app/external/cc-lib/dist/functions/utility/refreshId.js";
    import { ccprjFunc } from '@app/lib/ccprj.js';
    
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";;
    import "firebase/functions";

    const iconMap = {
        "folder" : "bi-folder-fill",
        "project" : "bi-file-earmark-play",
        "team project" : "bi-people",
        "work order" : "bi-clipboard-check"
    }

    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    let storageRef = storage.ref();
    let homeStorageBasePath = `users/${userId}/projects/`;
    let teamStorageBasePath = "teams/" + teamId + "/storage/";
    let loading = false;
    let currentDragTarget = null;

    /* Get records where folder Id is the records locationId */
    async function getRecords(folderId = null){
        try {
            loading = true;
            $explorerState.records = [];
            $explorerState.selectedRecords = [];

            // console.log("Getting Records for Folder ID: " + folderId);
            
            // Handle special rootDir values
            if ($explorerState.rootDir === "recent") {
                // Fetch recent records from both personal and team storage
                let recentRecords = [];
                
                // Personal storage
                let personalSnapshot = await homeDbRef
                    .orderBy("updatedOn", "desc")
                    .limit(100)
                    .get();
                    
                personalSnapshot.forEach((doc) => {
                    recentRecords.push({...(doc.data()), rootDir: "personal"});
                });
                
                // Team storage (if available)
                if (teamDbRef) {
                    let teamSnapshot = await teamDbRef
                        .orderBy("updatedOn", "desc")
                        .limit(100)
                        .get();
                        
                    teamSnapshot.forEach((doc) => {
                        recentRecords.push({...(doc.data()), rootDir: "team"});
                    });
                }
                
                // console.log("Before sorting:", recentRecords.map(r => ({ name: r.name, type: r.type })));
                
                // First, separate folders from other records
                const folders = recentRecords.filter(record => record.type === "folder");
                const nonFolders = recentRecords.filter(record => record.type !== "folder");
                
                // Sort each group by updatedOn timestamp
                folders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                nonFolders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                // Combine: folders first, then other records
                $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
                
                // console.log("After sorting - folders:", folders.length, "non-folders:", nonFolders.length);
                // console.log("----RECENT RECORDS (WITH FOLDERS FIRST)----");
                // console.log($explorerState.records.map(r => ({ name: r.name, type: r.type })));
                
            } else if ($explorerState.rootDir === "starred") {
                // Fetch starred records from both personal and team storage
                let starredRecords = [];
                
                // Personal storage
                let personalSnapshot = await homeDbRef
                    .where("star", "==", true)
                    .limit(100)
                    .get();
                    
                personalSnapshot.forEach((doc) => {
                    starredRecords.push({...(doc.data()), rootDir: "personal"});
                });
                
                // Team storage (if available)
                if (teamDbRef) {
                    let teamSnapshot = await teamDbRef
                        .where("star", "==", true)
                        .limit(100)
                        .get();
                        
                    teamSnapshot.forEach((doc) => {
                        starredRecords.push({...(doc.data()), rootDir: "team"});
                    });
                }
                
                // console.log("Before sorting:", starredRecords.map(r => ({ name: r.name, type: r.type })));
                
                // First, separate folders from other records
                const folders = starredRecords.filter(record => record.type === "folder");
                const nonFolders = starredRecords.filter(record => record.type !== "folder");
                
                // Sort each group by updatedOn timestamp
                folders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                nonFolders.sort((a, b) => {
                    // Convert to milliseconds for comparison
                    const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                    const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                    return timeB - timeA; // Newest first
                });
                
                // Combine: folders first, then other records
                $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
                
                // console.log("After sorting - folders:", folders.length, "non-folders:", nonFolders.length);
                // console.log("----STARRED RECORDS (WITH FOLDERS FIRST)----");
                // console.log($explorerState.records.map(r => ({ name: r.name, type: r.type })));
                
            } else {
                // Normal folder query for personal or team storage
                let querySnapshot = await ($explorerState.rootDir === "personal" ? homeDbRef : teamDbRef).where("locationId", "==", folderId).orderBy("type").orderBy("name", "asc").get();

                querySnapshot.forEach((doc) => {
                    $explorerState.records = [...$explorerState.records, {...(doc.data()), rootDir: $explorerState.rootDir}];
                });

                // console.log("----RECORDS----");
                // console.log($explorerState.records);
            }
        } catch(err){
            console.error(err);
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }   
    }

    async function openRecord(id) {
        // console.log("Opening record with id: ", id);
        let record = $explorerState.records.find(record => record.id === id);
        // console.log("----Record----");
        // console.log(record);
        // console.log("--------------");

        // Handle folder navigation
        if (record.type === "folder") {
            // console.log(`Opening folder: ${record.name}, current rootDir: ${$explorerState.rootDir}`);
            
            // Check if we're in recent or starred view
            if ($explorerState.rootDir === "recent" || $explorerState.rootDir === "starred") {
                // console.log(`Switching from ${$explorerState.rootDir} view to ${record.rootDir} view`);
                
                // Switch to the appropriate rootDir based on the folder's rootDir
                $explorerState.rootDir = record.rootDir;
                
                // console.log(`Building navigation path for folder: ${record.name}`);
                // Build the navigation path by finding all parent folders
                await buildFolderNavigationPath(record);
                
                // console.log(`Navigation path built, length: ${$explorerState.navHistory.length}`);
            } else {
                // console.log(`Regular folder navigation in ${$explorerState.rootDir} view`);
                // Regular folder navigation in normal view
                $explorerState.navHistory = [...$explorerState.navHistory, record];
            }
            
            // console.log(`Getting records for folder: ${record.name}, id: ${record.id}`);
            // Navigate to the folder contents
            getRecords(record.id);
        } 
        // Handle projects differently based on context
        else if (record.type === "project" || record.type === "team project" || record.type === "work order") {
            // If in save mode, overwrite the project instead of opening it
            if ($explorerState.context === "save") {
                overwriteProject(record);
            } else {
                // In load mode, open the project as before
                loadProject(record);
            }
        } 
    }
    
    // Build navigation path for a folder by finding all parent folders
    async function buildFolderNavigationPath(folder) {
        try {
            loading = true;
            // console.log(`Building navigation path for folder: ${folder.name} (ID: ${folder.id})`);
            
            // Clear current navigation history
            $explorerState.navHistory = [];
            
            // If no folder, we're at root
            if (!folder) {
                // console.log("No folder provided, returning empty path");
                return;
            }
            
            // Select the appropriate database reference
            const dbRef = folder.rootDir === "personal" ? homeDbRef : teamDbRef;
            if (!dbRef) {
                throw new Error(`Invalid database reference for rootDir: ${folder.rootDir}`);
            }
            
            // console.log(`Using ${folder.rootDir} database reference`);
            
            // First add the current folder to the path
            let folderPath = [];
            
            // If folder has no locationId, it's at root level already
            if (!folder.locationId) {
                // console.log(`Folder is at root level: ${folder.name}`);
                $explorerState.navHistory = [folder];
                return;
            }
            
            // Start with the current folder's locationId to find its parent
            let currentFolderId = folder.locationId;
            let pathComplete = false;
            let safetyCounter = 0; // Prevent infinite loops
            const MAX_DEPTH = 20; // Maximum folder nesting depth to prevent infinite loops
            
            // Keep traversing up until we reach the root or hit safety limit
            while (currentFolderId && !pathComplete && safetyCounter < MAX_DEPTH) {
                safetyCounter++;
                // console.log(`Looking up parent folder with ID: ${currentFolderId}`);
                
                try {
                    // Get the parent folder
                    const parentDoc = await dbRef.doc(currentFolderId).get();
                    
                    if (parentDoc.exists) {
                        const parentData = parentDoc.data();
                        const parentFolder = { ...parentData, rootDir: folder.rootDir };
                        
                        // console.log(`Found parent: ${parentFolder.name} (ID: ${parentFolder.id})`);
                        
                        // Add to path (at the beginning)
                        folderPath.unshift(parentFolder);
                        
                        // Continue with parent's parent if it exists
                        if (parentFolder.locationId) {
                            currentFolderId = parentFolder.locationId;
                        } else {
                            // console.log("Reached root folder in hierarchy");
                            pathComplete = true;
                        }
                    } else {
                        // console.log(`Parent folder not found with ID: ${currentFolderId}`);
                        pathComplete = true;
                    }
                } catch (err) {
                    console.error(`Error fetching parent folder: ${err.message}`);
                    pathComplete = true;
                }
            }
            
            if (safetyCounter >= MAX_DEPTH) {
                console.warn("Possible circular reference in folder structure, path building stopped");
            }
            
            // Add the original folder at the end
            folderPath.push(folder);
            
            // console.log("Full navigation path built:", folderPath);
            
            // Update navigation history with the complete path
            $explorerState.navHistory = folderPath;
            
        } catch (error) {
            console.error("Error building folder navigation path:", error);
            toast.push(`Failed to build folder path: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    async function overwriteProject(record) {
        if (loading) return;
        
        try {
            loading = true;
            
            // Check if record is locked by someone else
            if (record.locked && record.lockedBy !== userEmail) {
                throw new Error(`Cannot overwrite "${record.name}" as it's locked by ${record.lockedBy}`);
            }
            
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Overwrite Project",
                text: `Are you sure you want to overwrite "${record.name}"?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Overwrite",
                denyButtonText: "Cancel",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-danger me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) {
                throw new Error("Overwriting project was cancelled");
            }
            
            // Update the project to use the same ID as the selected record
            $projectState = refreshId($projectState, record.id);
            
            // Determine file path based on storage location
            const projectFilePath = record.rootDir === "team"
                ? teamStorageBasePath + record.id
                : homeStorageBasePath + record.id;
                
            // Create project JSON
            const projectJson = ccprjFunc.createCcprj({
                project: JSON.parse(JSON.stringify($projectState)),
                metadata: JSON.parse(JSON.stringify($metadataState)),
                speakers: JSON.parse(JSON.stringify($speakerState)),
                issues: JSON.parse(JSON.stringify($issueState)),
                markers: JSON.parse(JSON.stringify($markerState)),
                eventGroups: JSON.parse(JSON.stringify($eventGroupState)),
                style: JSON.parse(JSON.stringify($styleState))
            });
            
            // Upload to storage
            const uploadProjectRef = storage.ref().child(projectFilePath);
            await uploadProjectRef.putString(JSON.stringify(projectJson));
            
            // Update project record in database
            const projectInfo = {
                id: record.id,
                name: $projectState.name,
                type: $projectState.type === "team" ? "team project" : "project",
                owner: userEmail,
                createdOn: record.createdOn,
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                locationId: record.locationId,
                shared: record.rootDir === "team" ? true : false,
                star: record.star || false,
                rootDir: record.rootDir
            };
            
            const dbRef = record.rootDir === "team" ? teamDbRef : homeDbRef;
            await dbRef.doc(projectInfo.id).set(projectInfo);
            
            // Update project state with location info
            $projectState.folderId = record.locationId;
            $projectState.rootDir = record.rootDir;
            
            // Reset history and show success message
            historyState.reset();
            toast.push(`Project "${$projectState.name}" overwritten successfully`, {
                classes: ["toast-success"],
            });
            
            // Update the record in the records list
            $explorerState.records = $explorerState.records.map(r => 
                r.id === record.id ? projectInfo : r
            );
            
            modalState.hideModal();
        } catch (error) {
            console.error("Error overwriting project:", error);
            toast.push(`Failed to overwrite project: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    async function loadProject(record) {
        try {
            loading = true;
            
            // Check if project is locked by someone else
            if (record.locked && record.lockedBy !== userEmail) {
                // Show notification about locked project
                await Swal.fire({
                    title: 'Locked Project',
                    text: `This project is locked by ${record.lockedBy}. You can view it, but to make changes you'll need to create a new copy.`,
                    icon: 'info',
                    confirmButtonText: 'Continue in Read-Only Mode',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: "btn btn-lg btn-primary"
                    }
                });
            }
            
            // Determine the storage path based on whether it's personal or team
            const projectFilePath = record.rootDir === "team" 
                ? `${teamStorageBasePath}${record.id}` 
                : `${homeStorageBasePath}${record.id}`;
            
            // Get the URL for the file
            let url;
            if (record.rootDir === "team") {
                const result = await firebase.functions().httpsCallable("v8GetDownloadLink")(projectFilePath);
                url = result.data;
            } else {
                url = await storageRef.child(projectFilePath).getDownloadURL();
            }
            
            // Fetch the file content
            const response = await fetch(url);
            const fileData = await response.json();
            
            // Process based on record type
            if (record.type === "work order") {
                openWorkOrder(fileData, record);
            } else if (record.type === "project") {
                openProjectFile(fileData, record);
            } else if (record.type === "team project") {
                openTeamProjectFile(fileData, record);
            }
            
        } catch (error) {
            console.error(`Error opening ${record.type}:`, error);
            toast.push(`Failed to open ${record.type}: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    function openProjectFile(description, record){
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Project File",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call projectFileImport modal
        modalState.showModal("loadProjectFile");
    }

    async function openTeamProjectFile(description, record) {
        try {
            loading = true;
            
            // Extract team ID and project ID
            const projectTeamId = description.teamId;
            
            if (!projectTeamId || !teamId) {
                throw new Error("Missing team or project ID in file");
            }
            
            // console.log(`Looking up share codes for team project: ${projectTeamId} in team: ${teamId}`);
            
            // Check for project snapshots first (to ensure there's data to share)
            const snapshotsRef = db.collection("teams").doc(teamId)
                .collection("projects").doc(projectTeamId)
                .collection("snapshots");
                
            const snapshotsQuery = await snapshotsRef.limit(1).get();
            
            if (snapshotsQuery.empty) {
                // console.log("No snapshots found for team project, falling back to project file import");
                fallbackToProjectFileImport(description, record);
                return;
            }
            
            // Look for share codes that reference this project
            const sharesRef = db.collection("teams").doc(teamId).collection("shares");
                
            const sharesQuery = await sharesRef
                .where("projectTeamId", "==", projectTeamId)
                .limit(1)
                .get();
            
            if (sharesQuery.empty) {
                // console.log("No share codes found for team project, falling back to project file import");
                fallbackToProjectFileImport(description, record);
                return;
            }
            
            // Get the first share code
            const shareDoc = sharesQuery.docs[0];
            const shareId = shareDoc.id;
            
            // console.log(`Found share code: ${shareId}`);
            
            // Update URL with share code and open teamProjectImport modal
            const url = new URL(window.location.href);
            url.searchParams.set("id", shareId);
            window.history.pushState({}, '', url);
            
            toast.push("Opening team project via share code...", {
                classes: ["toast-info"],
            });
            
            modalState.showModal("teamProjectImport");
            
        } catch (error) {
            console.error("Error processing team project:", error);
            fallbackToProjectFileImport(description, record);
        } finally {
            loading = false;
        }
    }
    
    function fallbackToProjectFileImport(description, record) {
        toast.push("Using project file import method", {
            classes: ["toast-info"],
        });
        
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Team Project File",
            status: "importing",
            rawDescription: JSON.stringify(description)
        };
        
        modalState.showModal("teamProjectFileImport");
    }

    function openWorkOrder(description, record) {
        // Add folderId to description object
        description.folderId = record.locationId;
        
        $externalDataState = {
            platform: "Work Order",
            status : "importing",
            rawDescription: JSON.stringify(description)
        };
        
        //call workOrderImport modal
        modalState.showModal("workOrderImport");
    }

    function selectRecord(clickEvent, id) {
        // console.log("Selecting record with id: ", id);
        if (clickEvent.ctrlKey || clickEvent.metaKey) {
            if ($explorerState.selectedRecords.includes(id)) {
                $explorerState.selectedRecords = $explorerState.selectedRecords.filter(recordId => recordId !== id);
            } else {
                $explorerState.selectedRecords = [...$explorerState.selectedRecords, id];
            }
        } else if (clickEvent.shiftKey) {
            const lastSelectedRecord = $explorerState.selectedRecords[$explorerState.selectedRecords.length - 1];
            const lastSelectedRecordIndex = $explorerState.records.findIndex(record => record.id === lastSelectedRecord);
            const currentRecordIndex = $explorerState.records.findIndex(record => record.id === id);
            const recordsToSelect = $explorerState.records.slice(Math.min(lastSelectedRecordIndex, currentRecordIndex), Math.max(lastSelectedRecordIndex, currentRecordIndex) + 1);
            $explorerState.selectedRecords = recordsToSelect.map(record => record.id);
        } else {
            $explorerState.selectedRecords = [id];
        }
    }

    // Drag and drop functions
    function handleDragStart(event, id) {
        // Don't allow drag in save mode
        if ($explorerState.context === "save") {
            event.preventDefault();
            return;
        }
        
        // If the current item isn't selected, select only this item
        if (!$explorerState.selectedRecords.includes(id)) {
            $explorerState.selectedRecords = [id];
        }
        
        // Set drag data
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/plain", JSON.stringify($explorerState.selectedRecords));
        
        // Set a custom class for styling
        const draggedItems = $explorerState.selectedRecords.length;
        event.dataTransfer.setData("application/count", draggedItems);
        event.target.classList.add("dragging");
    }
    
    function handleDragEnd(event) {
        event.target.classList.remove("dragging");
        currentDragTarget = null;
    }
    
    function handleDragOver(event, record) {
        // Only allow dropping on folders
        if (record.type !== "folder") {
            return;
        }
        
        // Get the dragged item IDs
        const dragDataString = event.dataTransfer.types.includes('text/plain') ? 
            event.dataTransfer.getData('text/plain') : null;
            
        // If we're dragging the folder onto itself, don't allow drop
        if (dragDataString) {
            try {
                const draggedIds = JSON.parse(dragDataString);
                // Prevent dropping onto self
                if (draggedIds.includes(record.id)) {
                    event.dataTransfer.dropEffect = "none";
                    return;
                }
            } catch (e) {
                // If parsing fails, continue with normal behavior
            }
        }
        
        // Prevent default to allow drop
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        
        // Highlight the drop target
        if (currentDragTarget && currentDragTarget !== record.id) {
            const prevElement = document.querySelector(`[data-record-id="${currentDragTarget}"]`);
            if (prevElement) prevElement.classList.remove("drop-target");
        }
        
        event.currentTarget.classList.add("drop-target");
        currentDragTarget = record.id;
    }
    
    function handleDragLeave(event) {
        event.currentTarget.classList.remove("drop-target");
    }
    
    async function handleDrop(event, targetRecord) {
        event.preventDefault();
        event.currentTarget.classList.remove("drop-target");
        
        // Only allow dropping on folders
        if (targetRecord.type !== "folder") {
            return;
        }
        
        try {
            // Get the dragged record IDs
            const recordIds = JSON.parse(event.dataTransfer.getData("text/plain"));
            
            // Don't move if nothing was dragged or trying to move to same folder
            if (!recordIds.length) return;
            
            // Check if trying to drop a folder onto itself
            if (recordIds.includes(targetRecord.id)) {
                toast.push("Cannot move a folder into itself", {
                    classes: ["toast-warning"],
                });
                return;
            }
            
            const recordsToMove = $explorerState.records.filter(r => recordIds.includes(r.id));
            
            // Check if any of the records are already in the target folder
            const alreadyInFolder = recordsToMove.some(r => r.locationId === targetRecord.id);
            if (alreadyInFolder) {
                toast.push("Some items are already in this folder", {
                    classes: ["toast-warning"],
                });
                return;
            }
            
            // Confirm move with the user
            await moveRecords(recordsToMove, targetRecord);
            
        } catch (error) {
            console.error("Error handling drop:", error);
            toast.push(`Drop failed: ${error.message}`, {
                classes: ["toast-danger"],
            });
        }
    }
    
    async function moveRecords(records, targetFolder) {
        if (loading) return;
        
        try {
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Move Items",
                text: `Move ${records.length} item${records.length > 1 ? 's' : ''} to "${targetFolder.name}"?`,
                showDenyButton: true,
                confirmButtonText: "Move",
                denyButtonText: "Cancel",
                icon: "question",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-primary me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) return;
            
            loading = true;
            
            // Check if any records are locked
            const lockedRecords = records.filter(r => r.locked && r.lockedBy !== userEmail);
            if (lockedRecords.length > 0) {
                throw new Error(`Cannot move locked item${lockedRecords.length > 1 ? 's' : ''}`);
            }
            
            // Perform the move operation for each record
            for (const record of records) {
                // Get the appropriate database reference based on the root directory
                const dbRef = record.rootDir === "team" ? teamDbRef : homeDbRef;
                
                // Update the locationId
                await dbRef.doc(record.id).update({
                    locationId: targetFolder.id,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
                
                // Update local record
                const index = $explorerState.records.findIndex(r => r.id === record.id);
                if (index !== -1) {
                    $explorerState.records[index].locationId = targetFolder.id;
                }
            }
            
            // Refresh the records list
            await getRecords($explorerState.navHistory[$explorerState.navHistory.length - 1]?.id || null);
            
            // Clear selected records
            $explorerState.selectedRecords = [];
            
            toast.push(`Moved ${records.length} item${records.length > 1 ? 's' : ''} to "${targetFolder.name}"`, {
                classes: ["toast-success"],
            });
            
        } catch (error) {
            console.error("Error moving records:", error);
            toast.push(`Failed to move: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
</script>
{#if loading}
<div class="loading-container d-flex justify-content-center align-items-center w-100 h-100">
    <Circle
    size="75"
    color="#1eb4b2"
    unit="px"
    duration="1s"
    ></Circle>
</div>
{:else}
    <div id="workspace-table-view" class="table-responsive border rounded border-0 my-2">
        <table class="table table-hover table-light border-secondary">
            <thead class="bg-light">
                <tr>
                    <th class="col-icon"><!-- Lock Column --></th>
                    <th class="col-icon"><!-- Star Column --></th>
                    <th class="col-name">Name</th>
                    <th class="col-state">State</th>
                    <th class="col-sm">Owner</th>
                    <th class="col-sm">Assigned To</th>
                    <th class="col-sm">Event Groups</th>
                    <th class="col-sm">Total Events</th>
                    <th class="col-sm">Last Modified</th>
                    <th class="col-sm">Created On</th>
                </tr>
            </thead>
            <tbody>
                {#each $explorerState.records as record}
                <tr 
                    on:dblclick={() => openRecord(record.id)} 
                    on:click={(clickEvent) => selectRecord(clickEvent, record.id)} 
                    class="{($explorerState.selectedRecords.includes(record.id) ? 'table-primary' : '')}"
                    draggable={$explorerState.context !== "save"}
                    on:dragstart={(e) => handleDragStart(e, record.id)}
                    on:dragend={handleDragEnd}
                    on:dragover={(e) => handleDragOver(e, record)}
                    on:dragleave={handleDragLeave}
                    on:drop={(e) => handleDrop(e, record)}
                    data-record-id={record.id}
                    data-record-type={record.type}>
                    <td>{#if record.locked}<i class="bi bi-lock-fill"></i>{/if}</td>
                    <td>{#if record.star}<i class="bi bi-star-fill"></i>{/if}</td>
                    <td><i class="bi {iconMap[record.type]}"></i> {record.name}</td>
                    <td>{record.state || "-"}</td>
                    <td>{record.owner || "-"}</td>
                    <td>{record.assignedTo || "-"}</td>
                    <td>{record.eventGroups || "-"}</td>
                    <td>{record.totalEvents || "-"}</td>
                    <td>{record.updatedOn.toDate().toLocaleDateString()}</td>
                    <td>{record.createdOn.toDate().toLocaleDateString()}</td>                
                </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}
<style>
    #workspace-table-view {
        height: 80vh;
        overflow-y: auto;
    }
    
    .loading-container {
        height: 80vh;
    }
    
    /* Add sticky header styles */
    table {
        position: relative;
    }
    
    thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    
    /* Drag and drop styles */
    tr[data-record-type="folder"] {
        cursor: pointer;
    }    
    
    /* Column width styles */
    .col-icon {
        width: 40px;
        min-width: 40px;
    }
    
    .col-name {
        width: 30%;
        min-width: 250px;
    }
    
    .col-state {
        width: 15%;
        min-width: 150px;
    }
    
    .col-sm {
        width: 9%;
        min-width: 120px;
    }
</style>