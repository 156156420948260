export default (function formatRtlText(text) {
  let punctuation = [",", ".", "!", "?", "¿", ":", ";"];
  let speakerIdentifiers = [">", "<", "-"];
  let i = 0;
  let post = "";
  let replaceA = String.fromCharCode(8235);
  let replaceB = String.fromCharCode(8236);
  let reA = new RegExp(replaceA, "g");
  let reB = new RegExp(replaceB, "g");
  text = text.replace(reA, "").replace(reB, "");
  if (speakerIdentifiers.indexOf(text[0]) > -1) {
    post += text[0];
    text = text.slice(1);
  }
  while (text && punctuation.indexOf(text[text.length - 1]) > -1 && text.length > 1 && i < 10) {
    text = text[text.length - 1] + text.slice(0, -1);
    i++;
  }
  text = text.replace(/(?<=.), /g, " ,").replace(/(?<=.)\.\.\. /g, " ...") + post;
  return text;
});