<script>
  import {modalState} from '@app/store/modalStore.js';
  import {projectState} from '@app/store/projectStore.js'
  import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {authState} from '@app/store/authStore.js';
import {
    environment
} from '@app/store/envStore.js';
  import { createEventDispatcher } from 'svelte';
  //IMPORT sWAL
  import Swal from "sweetalert2";
  export let currentState;
  const dispatch = createEventDispatcher();
  
function toggleMenu(){
    dispatch('toggleMenu', {
        menu : 'aiTools'
     });
  }

function renderAllAudioEvents(){
    /* Use SweetAlert to Ask the user if they are sure they want to render all audio events, and then select all events, and open the Render audio modal. */
    Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to render all Events?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
    }).then((result) => {
        if (result.isConfirmed) {
            $eventGroupState[$projectState.selected].selected = $eventGroupState[$projectState.selected].events.map((event, count)=>{
                return count;
            });
            
            modalState.showModal("renderAudio");            
        }
    });
    
}
  
  </script>
<li class="nav-item dropdown {currentState ? 'show' : ''}">
  <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/">Ai Tools</a>
  <div class="dropdown-menu {currentState ? 'show' : ''}">
    {#if ($authState.plan === 'pro' || $authState.plan === 'enterprise') && $authState.type === "default" && $authState.status !== "in_trial" && $environment.online}
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("batchTranscription")}>Batch Transcription</a>
        <hr class="dropdown-divider">
    {/if}
    {#if $environment.online}
        <a class="dropdown-item {!$projectState.media.path || $projectState.media.storage === "HLS Manifest" || $projectState.media.storage === "Vimeo" ? 'disabled' : ''}" href="#!/" on:click={() => modalState.showModal("automaticTranscription")}>Automatic Transcription</a>
    {/if}
    {#if ($authState.plan === 'pro' || $authState.plan === 'enterprise') && $environment.online}
        <a class="dropdown-item {!$eventGroupState[$projectState.selected] ? 'disabled' : ''}" href="#!/" on:click={() => modalState.showModal("automaticTranslation")}>Automatic Translation</a>
    {/if}
    {#if $environment.electron && ($projectState.media.storage === "Local Storage" || $projectState.media.storage === "Cloud Storage")}
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("sceneChangeDetection")}>Detect Shot Changes</a>
    {:else if !$environment.online}
        <a class="dropdown-item disabled" href="#!/" on:click={() => modalState.showModal("sceneChangeDetection")}>Detect Shot Changes</a>
    {/if}
    {#if $authState.ad && $environment.online}
        <hr class="dropdown-divider">
        <a class="dropdown-item {!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].type !== 'audio description' ? 'disabled' : ''}" href="#!/" on:click={() => modalState.showModal("renderAudio")}>Force Render Audio...</a>
        <a class="dropdown-item {!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].type !== 'audio description' ? 'disabled' : ''}" href="#!/" on:click={() => renderAllAudioEvents()}>Force Render ALL Audio...</a>
    {/if}
    {#if $environment.online}
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("aiTranscriptImport")}><i class="bi bi-file-text"></i> Transcription Import...</a>
        {#if $authState.plan === 'pro' || $authState.plan === 'enterprise'}
            <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("aiTranslationImport")}><i class="bi bi-translate"></i> Translation Import...</a>
        {/if}
    {/if}
  </div>
</li>