<script>
    /* States */
    import { explorerState } from "@app/store/explorerStore.js";
    import { authState } from "@app/store/authStore.js";
    import { Circle } from "svelte-loading-spinners";
    import { toast } from "@zerodevx/svelte-toast";
    import { onMount } from 'svelte';
    import Swal from "sweetalert2";
    import { v4 as uuidv4 } from "uuid";
    
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    
    /* Components */
    import RecursiveFolderTree from "./RecursiveFolderTree.svelte";
    
    let showNewDropdown = false;
    let folderStructure = [];
    let loading = false;
    let expandedFolders = new Set();
    let refreshing = false;
    let currentDragTarget = null;
    
    // User and team info
    let userId = firebase.auth().currentUser.uid;
    let userEmail = firebase.auth().currentUser.email;
    let teamId = $authState.team ? $authState.team.id : null;
    let homeDbRef = db.collection("users").doc(userId).collection("storage");
    let teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    
    onMount(() => {
        loadFolderStructure("personal");
    });
    
    // Toggle dropdown menu
    const toggleNewDropdown = () => {
        showNewDropdown = !showNewDropdown;
    };
    
    // Toggle folder expansion
    const toggleFolder = (folderId) => {
        if (expandedFolders.has(folderId)) {
            expandedFolders.delete(folderId);
        } else {
            expandedFolders.add(folderId);
        }
        expandedFolders = expandedFolders; // trigger reactivity
    };
    
    // Load folder structure from database
    async function loadFolderStructure(rootDir) {
        try {
            loading = true;
            const dbRef = rootDir === "personal" ? homeDbRef : teamDbRef;
            
            if (!dbRef && rootDir === "team") {
                folderStructure = [];
                return;
            }
            
            // Get all folders
            const querySnapshot = await dbRef.where("type", "==", "folder").get();
            
            // Convert to array
            const folders = [];
            querySnapshot.forEach(doc => {
                folders.push({ ...doc.data(), rootDir });
            });
            
            // Build folder hierarchy
            folderStructure = buildFolderHierarchy(folders);
        } catch (error) {
            console.error("Error loading folder structure:", error);
            toast.push("Failed to load folder structure", {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    // Build hierarchical folder structure
    function buildFolderHierarchy(folders) {
        // Create a map of all folders
        const folderMap = new Map();
        
        // Add all folders to the map
        folders.forEach(folder => {
            folderMap.set(folder.id, {
                ...folder,
                children: []
            });
        });
        
        // Build the tree structure
        const rootFolders = [];
        
        // Add each folder to its parent's children array
        folderMap.forEach(folder => {
            if (!folder.locationId) {
                // This is a root folder
                rootFolders.push(folder);
            } else if (folderMap.has(folder.locationId)) {
                // This folder has a parent
                folderMap.get(folder.locationId).children.push(folder);
            } else {
                // Parent doesn't exist, treat as root
                rootFolders.push(folder);
            }
        });
        
        return rootFolders;
    }
    
    // Handle changing root directory (My Drive vs Team Drive)
    async function changeRootDir(rootDir) {
        if ($explorerState.rootDir !== rootDir) {
            $explorerState.rootDir = rootDir;
            $explorerState.navHistory = [];
            $explorerState.selectedRecords = [];
            
            await loadFolderStructure(rootDir);
            navigateToFolder(null); // Navigate to root of selected drive
        }
    }
    
    // Find the path from root to a specific folder
    function findFolderPath(folderId, folders = folderStructure, path = []) {
        // Search through current level folders
        for (const folder of folders) {
            // Check if this is the folder we're looking for
            if (folder.id === folderId) {
                return [...path, folder];
            }
            
            // Check in children
            if (folder.children && folder.children.length > 0) {
                const foundPath = findFolderPath(folderId, folder.children, [...path, folder]);
                if (foundPath) {
                    return foundPath;
                }
            }
        }
        
        // Not found in this branch
        return null;
    }
    
    // Navigate to a folder
    async function navigateToFolder(folderId, folder = null) {
        try {
            loading = true;
            
            $explorerState.selectedRecords = [];
            
            // Update navigation history based on folder location
            if (folder) {
                // Find the complete path to this folder
                const folderPath = findFolderPath(folder.id);
                
                if (folderPath) {
                    // Set navHistory to the complete path
                    $explorerState.navHistory = folderPath;
                } else {
                    // If path not found, just use the folder itself
                    $explorerState.navHistory = [folder];
                }
            } else {
                // Root folder
                $explorerState.navHistory = [];
            }
            
            // Fetch records for this folder
            await getRecords(folderId);
            
            // Expand the folder in the sidebar
            if (folderId) {
                expandedFolders.add(folderId);
                expandedFolders = expandedFolders;
            }
        } catch (error) {
            console.error("Error navigating to folder:", error);
            toast.push("Failed to navigate to folder", {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    // Show recent files
    async function showRecent() {
        try {
            loading = true;
            $explorerState.selectedRecords = [];
            $explorerState.records = [];
            
            // Clear previous navigation history completely and set to Recent view
            $explorerState.navHistory = [];
            $explorerState.rootDir = "recent";
            
            // Get records from personal storage
            let recentRecords = [];
            
            // Personal storage query
            const personalSnapshot = await homeDbRef
                .orderBy("updatedOn", "desc")
                .limit(100)
                .get();
                
            personalSnapshot.forEach((doc) => {
                recentRecords.push({...(doc.data()), rootDir: "personal"});
            });
            
            // Team storage query (if exists)
            if (teamDbRef) {
                const teamSnapshot = await teamDbRef
                    .orderBy("updatedOn", "desc")
                    .limit(100)
                    .get();
                    
                teamSnapshot.forEach((doc) => {
                    recentRecords.push({...(doc.data()), rootDir: "team"});
                });
            }
            
            // First, separate folders from other records
            const folders = recentRecords.filter(record => record.type === "folder");
            const nonFolders = recentRecords.filter(record => record.type !== "folder");
            
            // Sort each group by updatedOn timestamp
            folders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            nonFolders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            // Combine: folders first, then other records
            $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
            
        } catch (error) {
            console.error("Error loading recent files:", error);
            toast.push("Failed to load recent files", {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    // Show starred files
    async function showStarred() {
        try {
            loading = true;
            $explorerState.selectedRecords = [];
            $explorerState.records = [];
            
            // Clear previous navigation history completely and set to Starred view
            $explorerState.navHistory = [];
            $explorerState.rootDir = "starred";
            
            // Get records from personal and team storage
            let starredRecords = [];
            
            // Personal storage query
            const personalSnapshot = await homeDbRef
                .where("star", "==", true)
                .limit(100)
                .get();
                
            personalSnapshot.forEach((doc) => {
                starredRecords.push({...(doc.data()), rootDir: "personal"});
            });
            
            // Team storage query (if exists)
            if (teamDbRef) {
                const teamSnapshot = await teamDbRef
                    .where("star", "==", true)
                    .limit(100)
                    .get();
                    
                teamSnapshot.forEach((doc) => {
                    starredRecords.push({...(doc.data()), rootDir: "team"});
                });
            }
            
            // First, separate folders from other records
            const folders = starredRecords.filter(record => record.type === "folder");
            const nonFolders = starredRecords.filter(record => record.type !== "folder");
            
            // Sort each group by updatedOn timestamp
            folders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            nonFolders.sort((a, b) => {
                // Convert to milliseconds for comparison
                const timeA = a.updatedOn ? a.updatedOn.toMillis() : 0;
                const timeB = b.updatedOn ? b.updatedOn.toMillis() : 0;
                return timeB - timeA; // Newest first
            });
            
            // Combine: folders first, then other records
            $explorerState.records = [...folders, ...nonFolders].slice(0, 100);
            
        } catch (error) {
            console.error("Error loading starred files:", error);
            toast.push("Failed to load starred files", {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    // Create a new folder
    async function createFolder() {
        try {
            // Get current parent folder ID from navigation history
            const parentFolderId = $explorerState.navHistory.length > 0 
                ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id 
                : null;
            
            // Check if the parentFolderId is a system folder (recent/starred)
            if (parentFolderId === 'recent' || parentFolderId === 'starred') {
                toast.push("Cannot create folder in this view", {
                    classes: ["toast-warning"],
                });
                return;
            }
                
            // Prompt for folder name
            const { value: folderName } = await Swal.fire({
                title: 'New Folder',
                input: 'text',
                inputPlaceholder: 'Enter folder name',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-secondary ms-2',
                },
                inputValidator: (value) => {
                    if (!value) {
                        return 'Please enter a folder name';
                    }
                }
            });
            
            showNewDropdown = false;
            if (!folderName) return;
            
            // Create folder object
            const folderId = uuidv4();
            const folder = {
                id: folderId,
                name: folderName,
                type: "folder",
                owner: userEmail,
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                locationId: parentFolderId,
                shared: $explorerState.rootDir === "team"
            };
            
            // Add to database
            const dbRef = $explorerState.rootDir === "personal" ? homeDbRef : teamDbRef;
            await dbRef.doc(folderId).set(folder);
            
            // Refresh folders and current view
            await loadFolderStructure($explorerState.rootDir);
            await getRecords(parentFolderId);
            
            toast.push(`Folder "${folderName}" created`, {
                classes: ["toast-success"],
            });
        } catch (error) {
            console.error("Error creating folder:", error);
            toast.push("Failed to create folder", {
                classes: ["toast-danger"],
            });
        }
    }
    
    /* Get records where folder Id is the records locationId */
    async function getRecords(folderId = null) {
        try {
            $explorerState.records = [];
            
            // Don't fetch if we're in a system folder
            if (folderId === 'recent' || folderId === 'starred') {
                return;
            }
            
            const dbRef = $explorerState.rootDir === "personal" ? homeDbRef : teamDbRef;
            let querySnapshot = await dbRef.where("locationId", "==", folderId).orderBy("type").orderBy("name", "asc").get();

            querySnapshot.forEach((doc) => {
                $explorerState.records = [...$explorerState.records, {...(doc.data()), rootDir: $explorerState.rootDir}];
            });
        } catch(err) {
            console.error(err);
            toast.push(err.message, {
                classes: ["toast-danger"],
            });
        }
    }
    
    // Helper function to check if a folder is the currently active folder
    function isActiveFolder(folder) {
        // If we're at root level (no navHistory), nothing is active
        if ($explorerState.navHistory.length === 0) {
            return false;
        }
        
        // Check if this folder matches the current active folder
        const currentFolder = $explorerState.navHistory[$explorerState.navHistory.length - 1];
        return folder.id === currentFolder.id;
    }

    // Add keyboard navigation handler for folder items
    function handleKeyDown(event, action) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            action();
        }
    }

    // Refresh the folder structure and current view
    async function refreshFolders() {
        try {
            refreshing = true;
            teamId = $authState.team ? $authState.team.id : null;
            // Get current folder ID from navigation history if any
            const currentFolderId = $explorerState.navHistory.length > 0 
                ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id 
                : null;
            
            // Reload the current folder structure
            await loadFolderStructure($explorerState.rootDir);
            
            // Also refresh the current view
            if ($explorerState.rootDir === "recent") {
                await showRecent();
            } else if ($explorerState.rootDir === "starred") {
                await showStarred();
            } else {
                await getRecords(currentFolderId);
            }
            
            toast.push("Folders refreshed", {
                classes: ["toast-success"],
            });
        } catch (error) {
            console.error("Error refreshing folders:", error);
            toast.push("Failed to refresh folders", {
                classes: ["toast-danger"],
            });
        } finally {
            refreshing = false;
        }
    }

    // Drag and drop handling for folders in the sidebar
    function handleDragOver(event, folder) {
        // Don't allow drop in save mode
        if ($explorerState.context === "save") return;
        
        // Only proceed if there is text/plain data (our record IDs)
        if (!event.dataTransfer.types.includes('text/plain')) return;
        
        // Validate drop target
        const isValid = isDropTargetValid(folder);
        
        if (isValid) {
            // Allow drop
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";
            
            // Update drop target styling
            if (currentDragTarget && currentDragTarget !== folder.id) {
                const prevElement = document.querySelector(`[data-folder-id="${currentDragTarget}"]`);
                if (prevElement) {
                    prevElement.classList.remove("drop-target");
                    prevElement.classList.remove("invalid-drop");
                }
            }
            
            event.currentTarget.classList.add("drop-target");
            event.currentTarget.classList.remove("invalid-drop");
            currentDragTarget = folder.id;
        } else {
            // Show invalid drop style but still allow dragover for visual feedback
            event.preventDefault();
            event.dataTransfer.dropEffect = "none";
            
            // Update invalid drop styling
            if (currentDragTarget && currentDragTarget !== folder.id) {
                const prevElement = document.querySelector(`[data-folder-id="${currentDragTarget}"]`);
                if (prevElement) {
                    prevElement.classList.remove("drop-target");
                    prevElement.classList.remove("invalid-drop");
                }
            }
            
            event.currentTarget.classList.add("invalid-drop");
            event.currentTarget.classList.remove("drop-target");
            currentDragTarget = folder.id;
        }
    }
    
    function handleDragLeave(event) {
        // Remove styling
        event.currentTarget.classList.remove("drop-target");
        event.currentTarget.classList.remove("invalid-drop");
    }
    
    function isDropTargetValid(folder) {
        // Special system folders can never be drop targets
        if (folder === "recent" || folder === "starred") {
            return false;
        }
        
        // Get dragged record IDs from currently selected records
        const draggedRecords = $explorerState.records.filter(r => 
            $explorerState.selectedRecords.includes(r.id)
        );
        
        // No dragged records - nothing to validate
        if (!draggedRecords.length) return false;
        
        // Check if trying to drop onto the same folder (records' current locationId)
        const currentFolderId = $explorerState.navHistory.length > 0 
            ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id 
            : null;
            
        if (folder.id === currentFolderId) {
            return false;
        }
        
        // Check for storage mismatch (can't move between personal and team)
        const hasPersonalRecords = draggedRecords.some(r => r.rootDir === "personal");
        const hasTeamRecords = draggedRecords.some(r => r.rootDir === "team");
        
        // If mixed record types, can't move
        if (hasPersonalRecords && hasTeamRecords) {
            return false;
        }
        
        // Check if drag source and target storage match
        if (hasPersonalRecords && folder.rootDir !== "personal") {
            return false;
        }
        
        if (hasTeamRecords && folder.rootDir !== "team") {
            return false;
        }
        
        // Check for folder-to-self drop
        if (draggedRecords.some(r => r.id === folder.id)) {
            return false;
        }
        
        return true;
    }
    
    async function handleDrop(event, targetFolder) {
        event.preventDefault();
        
        // Remove any styling
        event.currentTarget.classList.remove("drop-target");
        event.currentTarget.classList.remove("invalid-drop");
        currentDragTarget = null;
        
        try {
            // Get the dragged record IDs
            const recordIdsJson = event.dataTransfer.getData("text/plain");
            if (!recordIdsJson) return;
            
            const recordIds = JSON.parse(recordIdsJson);
            if (!recordIds.length) return;
            
            // Don't move if drop target is invalid
            if (!isDropTargetValid(targetFolder)) {
                return;
            }
            
            // Get the records to move
            const recordsToMove = $explorerState.records.filter(r => recordIds.includes(r.id));
            
            // Perform the move
            await moveRecords(recordsToMove, targetFolder);
            
        } catch (error) {
            console.error("Error handling drop:", error);
            toast.push(`Drop failed: ${error.message}`, {
                classes: ["toast-danger"],
            });
        }
    }
    
    async function moveRecords(records, targetFolder) {
        if (loading) return;
        
        try {
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Move Items",
                text: `Move ${records.length} item${records.length > 1 ? 's' : ''} to "${targetFolder.name}"?`,
                showDenyButton: true,
                confirmButtonText: "Move",
                denyButtonText: "Cancel",
                icon: "question",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-primary me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) return;
            
            loading = true;
            
            // Check if any records are locked
            const lockedRecords = records.filter(r => r.locked && r.lockedBy !== userEmail);
            if (lockedRecords.length > 0) {
                throw new Error(`Cannot move locked item${lockedRecords.length > 1 ? 's' : ''}`);
            }
            
            // Perform the move operation for each record
            for (const record of records) {
                // Get the appropriate database reference based on the record's root directory
                const dbRef = record.rootDir === "team" ? teamDbRef : homeDbRef;
                
                // Update the locationId
                await dbRef.doc(record.id).update({
                    locationId: targetFolder.id,
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date())
                });
            }
            
            // Refresh the records list if we're displaying the folder from which the records were moved
            const currentFolderId = $explorerState.navHistory.length > 0 
                ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id 
                : null;
                
            if ($explorerState.rootDir !== "recent" && $explorerState.rootDir !== "starred") {
                await getRecords(currentFolderId);
            }
            
            // Clear selected records
            $explorerState.selectedRecords = [];
            
            toast.push(`Moved ${records.length} item${records.length > 1 ? 's' : ''} to "${targetFolder.name}"`, {
                classes: ["toast-success"],
            });
            
        } catch (error) {
            console.error("Error moving records:", error);
            toast.push(`Failed to move: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
</script>

<div class="col-2 p-3">
    <div class="d-flex mb-3">
        <div class="dropdown me-2">
            <button
                on:click="{toggleNewDropdown}"
                class="btn btn-primary dropdown-toggle"
                type="button" 
                aria-haspopup="true" 
                aria-expanded="{showNewDropdown}">
                <i class="bi bi-plus-lg" aria-hidden="true"></i> New
            </button>
            <div class="dropdown-menu {showNewDropdown ? 'show' : ''}" role="menu">
                <button class="dropdown-item" on:click="{createFolder}">
                    <i class="bi bi-folder-plus" aria-hidden="true"></i> Folder
                </button>
            </div>
        </div>
        
        <button 
            title="Refresh folders"
            class="btn btn-link" 
            on:click="{refreshFolders}" 
            aria-label="Refresh folders"
            disabled="{refreshing}">
            <i class="bi {refreshing ? 'bi-arrow-clockwise spin' : 'bi-arrow-clockwise'}" aria-hidden="true"></i>
        </button>
    </div>
    
    {#if loading}
    <div class="d-flex justify-content-center my-4">
        <Circle size="40" color="#1eb4b2" unit="px" duration="1s" />
    </div>
    {:else}
    <nav aria-label="Folder navigation" class="folder-nav-container">
        <ul class="list-group border-0 nav-sidebar" role="tree">
            <!-- My Drive -->
            <li class="list-group-item border-0" role="none">
                <button 
                    class="btn btn-link fw-bold d-flex align-items-center root-drive-item w-100 text-start"
                    on:click={() => changeRootDir('personal')}
                    on:keydown={(e) => handleKeyDown(e, () => changeRootDir('personal'))}
                    aria-expanded="{$explorerState.rootDir === 'personal'}"
                    on:dragover={(e) => $explorerState.context !== "save" && 
                        $explorerState.records.some(r => r.rootDir === "personal" && $explorerState.selectedRecords.includes(r.id)) ? 
                        (e.preventDefault(), e.dataTransfer.dropEffect = "none", e.currentTarget.classList.add("invalid-drop")) : null}
                    on:dragleave={(e) => e.currentTarget.classList.remove("invalid-drop")}
                    data-root-dir="personal">
                    <i class="bi bi-house me-2" aria-hidden="true"></i> My Drive
                </button>
                
                {#if $explorerState.rootDir === 'personal'}
                    <ul class="list-group border-0 ms-3 mt-1 folders-container" role="group">
                        <RecursiveFolderTree 
                            folders={folderStructure} 
                            {expandedFolders}
                            {isActiveFolder}
                            {toggleFolder}
                            {navigateToFolder}
                            {handleKeyDown}
                            handleDragOver={handleDragOver}
                            handleDragLeave={handleDragLeave}
                            handleDrop={handleDrop}
                            isDropTargetValid={isDropTargetValid}
                        />
                    </ul>
                {/if}
            </li>
            
            <!-- Team Drive -->
            {#if teamId}
            <li class="list-group-item border-0" role="none">
                <button 
                    class="btn btn-link fw-bold d-flex align-items-center root-drive-item w-100 text-start"
                    on:click={() => changeRootDir('team')}
                    on:keydown={(e) => handleKeyDown(e, () => changeRootDir('team'))}
                    aria-expanded="{$explorerState.rootDir === 'team'}"
                    on:dragover={(e) => $explorerState.context !== "save" && 
                        $explorerState.records.some(r => r.rootDir === "team" && $explorerState.selectedRecords.includes(r.id)) ? 
                        (e.preventDefault(), e.dataTransfer.dropEffect = "none", e.currentTarget.classList.add("invalid-drop")) : null}
                    on:dragleave={(e) => e.currentTarget.classList.remove("invalid-drop")}
                    data-root-dir="team">
                    <i class="bi bi-people me-2" aria-hidden="true"></i> Team Drive
                </button>
                
                {#if $explorerState.rootDir === 'team'}
                    <ul class="list-group border-0 ms-3 mt-1 folders-container" role="group">
                        <RecursiveFolderTree 
                            folders={folderStructure} 
                            {expandedFolders}
                            {isActiveFolder}
                            {toggleFolder}
                            {navigateToFolder}
                            {handleKeyDown}
                            handleDragOver={handleDragOver}
                            handleDragLeave={handleDragLeave}
                            handleDrop={handleDrop}
                            isDropTargetValid={isDropTargetValid}
                        />
                    </ul>
                {/if}
            </li>
            {/if}
            
            {#if $explorerState.context === 'load'}
                <!-- Recent -->
                <hr/>
                <li class="list-group-item border-0 {$explorerState.rootDir === 'recent' ? 'active-folder' : ''}" role="none">
                    <button 
                        class="btn btn-link d-flex align-items-center w-100 text-start"
                        on:click={showRecent}
                        on:keydown={(e) => handleKeyDown(e, showRecent)}
                        on:dragover={(e) => $explorerState.context !== "save" ? 
                            (e.preventDefault(), e.dataTransfer.dropEffect = "none", e.currentTarget.classList.add("invalid-drop")) : null}
                        on:dragleave={(e) => e.currentTarget.classList.remove("invalid-drop")}
                        role="treeitem"
                        data-special-folder="recent"
                        aria-selected="{$explorerState.rootDir === 'recent'}">
                        <i class="bi bi-clock me-2" aria-hidden="true"></i> Recent
                    </button>
                </li>
                
                <!-- Starred -->
                <li class="list-group-item border-0 {$explorerState.rootDir === 'starred' ? 'active-folder' : ''}" role="none">
                    <button 
                        class="btn btn-link d-flex align-items-center w-100 text-start"
                        on:click={showStarred}
                        on:keydown={(e) => handleKeyDown(e, showStarred)}
                        on:dragover={(e) => $explorerState.context !== "save" ? 
                            (e.preventDefault(), e.dataTransfer.dropEffect = "none", e.currentTarget.classList.add("invalid-drop")) : null}
                        on:dragleave={(e) => e.currentTarget.classList.remove("invalid-drop")}
                        role="treeitem"
                        data-special-folder="starred"
                        aria-selected="{$explorerState.rootDir === 'starred' === 'starred'}">
                        <i class="bi bi-star me-2" aria-hidden="true"></i> Starred
                    </button>
                </li>
            {/if}
        </ul>
    </nav>
    {/if}
</div>

<style>
    .folder-nav-container {
        width: 100%;
        overflow: hidden; /* Hide overflow at container level */
    }
    
    .nav-sidebar {
        height: 80vh; /* Match the other components' height */
        overflow-y: auto;
        overflow-x: auto; /* Allow horizontal scrolling */
        max-width: 100%;
        padding-bottom: 20px; /* Add space for the horizontal scrollbar */
    }
    
    /* Container for folder items - makes nested folders work better with scrolling */
    .folders-container {
        min-width: max-content; /* Prevent text wrapping in folders */
    }
    
    .list-group-item {
        padding: 0.5rem 0.25rem;
        background: transparent;
    }
    
    .active-folder {
        background-color: rgba(33, 150, 243, 0.1);
        border-radius: 4px;
    }
    
    /* Style for button elements that replaced spans */
    button.root-drive-item,
    button.root-drive-item:hover,
    button.root-drive-item:focus,
    
    /* Button reset for link-styled buttons */
    .btn-link {
        text-decoration: none;
    }

    /* Animation for refresh icon */
    .spin {
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
</style>
