<script>
import {
    videoFilterState
} from '@app/store/videoFilterStore.js';
import {projectState} from '@app/store/projectStore.js';

let videoPlayerEl = document.getElementsByTagName("video")[0], zoomLevel = 1, leftOffset = 0, topOffset =0;
    
function resetFilters(){
    $videoFilterState = {
        brightness : 100,
        contrast : 100,
        saturation : 100,
        invert : 0
    }

    zoomLevel = 1, leftOffset = 0, topOffset = 0;    
}

function updateZoomLevel(level){
    videoPlayerEl = videoPlayerEl ? videoPlayerEl : document.getElementsByTagName("video")[0];
    if (videoPlayerEl){
        videoPlayerEl.style.scale = level;
    }
}

function updateTopOffset(offset){
    videoPlayerEl = videoPlayerEl ? videoPlayerEl : document.getElementsByTagName("video")[0];
    if (videoPlayerEl){
        videoPlayerEl.style.position = "relative";
        videoPlayerEl.style.top = offset+"px";
    }
}

function updateLeftOffset(offset){
    videoPlayerEl = videoPlayerEl ? videoPlayerEl : document.getElementsByTagName("video")[0];
    if (videoPlayerEl){
        videoPlayerEl.style.position = "relative";
        videoPlayerEl.style.left = offset+"px";
    }
}


$: updateZoomLevel(zoomLevel);
$: updateTopOffset(topOffset)
$: updateLeftOffset(leftOffset)
</script>

<form id="videoFiltersForm" aria-label="Video Filters" class="p-1">
    <div class="row">
        <div class="col-3">
            <label for="brightnessFilterRange" class="form-label">Brightness</label>
            <input type="range" class="form-range" id="brightnessFilterRange" min=0 max=250 bind:value={$videoFilterState.brightness} on:dblclick={() => $videoFilterState.brightness=100} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
        <div class="col-3">
            <label for="contrastFilterRange" class="form-label">Contrast</label>
            <input type="range" class="form-range" id="contrastFilterRange" min=0 max=250 bind:value={$videoFilterState.contrast} on:dblclick={() => $videoFilterState.contrast=100} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
        <div class="col-3">
            <label for="saturationFilterRange" class="form-label">Saturation</label>
            <input type="range" class="form-range" id="saturationFilterRange" min=0 max=250 bind:value={$videoFilterState.saturation} on:dblclick={() => $videoFilterState.saturation=100} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
        <div class="col-3">
            <label for="invertFilterRange" class="form-label">Invert</label>
            <input type="range" class="form-range" id="invertFilterRange" min=0 max=100 bind:value={$videoFilterState.invert} on:dblclick={() => $videoFilterState.invert=0} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
    </div>
    <hr class="my-2"/>
    <div class="row mb-2">
        <div class="col-6">
            <label for="zoomRange" class="form-label">Zoom +/-</label>
            <input type="range" class="form-range" id="zoomRange" min="0" max="5" step="0.01" bind:value={zoomLevel} on:dblclick={() => zoomLevel=1} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
        <div class="col-3">
            <label for="topOffsetRange" class="form-label">Y-Offset</label>
            <input type="range" class="form-range" id="topOffsetRange" min="-1000" max="1000" step="1" bind:value={topOffset} on:dblclick={() => topOffset=0} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
        <div class="col-3">
            <label for="leftOffsetRange" class="form-label">X-Offset</label>
            <input type="range" class="form-range" id="leftOffsetRange" min="-1000" max="1000" step="1" bind:value={leftOffset} on:dblclick={() => leftOffset=0} disabled="{$projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}">
        </div>
    </div>
    {#if $projectState.media.storage === "Vimeo" || $projectState.media.storage === "YouTube"}
        <p class="text-danger small">Video filters are not available for YouTube & Vimeo content.</p>
    {/if}
    <button
        id="resetFiltersBtn"
        aria-label="Reset all video filters"
        type="button"
        title="Reset all filter controls"
        class='btn btn-primary btn-sm'
        on:click={() => resetFilters()}
        disabled="{$projectState.media.storage === 'Vimeo' || $projectState.media.storage === 'YouTube'}">
        <i class="bi bi-arrow-clockwise"></i> Reset All
    </button>
</form>

<style>
</style>
