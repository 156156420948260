import { writable } from 'svelte/store';

function createModalStore() {
	const { subscribe, set } = writable("");

	return {
		subscribe,
		showModal: (modalName) => set(modalName),
		hideModal: () => set("")
	};
}

export const modalState = createModalStore();