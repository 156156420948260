<script>
    import { explorerState } from "@app/store/explorerStore.js";
    import { authState } from "@app/store/authStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { issueState } from "@app/store/issueStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import Swal from "sweetalert2";
    import { v4 as uuidv4 } from "uuid";
    import { toast } from "@zerodevx/svelte-toast";
    import refreshId from "@app/external/cc-lib/dist/functions/utility/refreshId.js";
    import { ccprjFunc } from '@app/lib/ccprj.js';
    
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    
    let loading = false;
    const userId = firebase.auth().currentUser?.uid;
    const userEmail = firebase.auth().currentUser?.email;
    const teamId = $authState.team ? $authState.team.id : null;
    const homeDbRef = db.collection("users").doc(userId).collection("storage");
    const teamDbRef = teamId ? db.collection("teams").doc(teamId).collection("storage") : null;
    const homeStorageBasePath = `users/${userId}/projects/`;
    const teamStorageBasePath = teamId ? `teams/${teamId}/storage/` : "";
    
    // Variable to track the first selected record
    let selectedRecord = null;
    
    // Reactively update selectedRecord when selectedRecords changes
    $: if ($explorerState.selectedRecords.length > 0) {
        selectedRecord = $explorerState.records.find(record => 
            record.id === $explorerState.selectedRecords[0]
        );
    } else {
        selectedRecord = null;
    }
    
    // Function to check if the selected record is a folder
    $: canOverwrite = selectedRecord && selectedRecord.type !== "folder";
    
    async function createFolder() {
        if (loading) return;
        
        loading = true;
        
        try {
            const result = await Swal.fire({
                titleText: "New Folder",
                text: "Please enter a folder name:",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Create",
                denyButtonText: "Cancel",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                inputLabel: "Folder Name",
                inputPlaceholder: "untitled",
                input: "text",
                customClass: {
                    confirmButton: "btn btn-lg btn-primary me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (result.isConfirmed && result.value) {
                const folderName = result.value;
                let locationId = $explorerState.navHistory.length > 0 ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id : null;

                const folderInfo = {
                    id: uuidv4(),
                    name: folderName,
                    type: "folder",
                    createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                    updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                    locationId: locationId,
                    rootDir : $explorerState.rootDir,
                    shared: $explorerState.rootDir === "team" ? true : false,
                    star: false,
                    locked: false
                };
                
                const dbRef = $explorerState.rootDir === "team" ? teamDbRef : homeDbRef;
                
                await dbRef.doc(folderInfo.id).set(folderInfo);
                
                // Add the new folder to the records list
                $explorerState.records = [folderInfo, ...$explorerState.records];
                
                toast.push(`Folder "${folderName}" created successfully`, {
                    classes: ["toast-success"],
                });
            }
        } catch (error) {
            console.error("Error creating folder:", error);
            toast.push(`Failed to create folder: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    async function deleteSelectedRecords() {
        if ($explorerState.selectedRecords.length === 0) return;
        
        // Show confirmation dialog
        const result = await Swal.fire({
            title: "Delete Records",
            text: `Are you sure you want to delete ${$explorerState.selectedRecords.length} selected record(s)?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete them",
            cancelButtonText: "Cancel",
            buttonsStyling: true,
            customClass: {
                confirmButton: "btn btn-danger text-white me-2",
                cancelButton: "btn btn-outline-secondary"
            }
        });

        if (!result.isConfirmed) return;
        
        try {
            // Get the selected records details
            const selectedRecordObjects = $explorerState.records.filter(record => 
                $explorerState.selectedRecords.includes(record.id)
            );
            
            // Process each selected record
            for (const record of selectedRecordObjects) {
                // Determine which database reference to use
                const dbRef = record.rootDir === "personal" ? homeDbRef : teamDbRef;
                
                // Skip if record is locked by someone else
                if (record.locked && record.lockedBy !== userEmail) {
                    toast.push(`Skipped "${record.name}" as it's locked by ${record.lockedBy}`, {
                        classes: ["toast-warning"]
                    });
                    continue;
                }
                
                // Delete the record from Firestore
                await dbRef.doc(record.id).delete();
                
                // If it's a project, also delete the project file from storage
                if (record.type === "project" || record.type === "team project" || record.type === "work order") {
                    const projectFilePath = record.rootDir === "personal" 
                        ? "users/" + userId + "/projects/" + record.id
                        : "teams/" + teamId + "/storage/" + record.id;
                    
                    if (record.rootDir === "team") {
                        // Use Cloud Function for team projects
                        await firebase.functions().httpsCallable("v8DeleteProjectFile")(projectFilePath);
                    } else {
                        // Use direct storage reference for personal projects
                        await storage.ref().child(projectFilePath).delete();
                    }
                }
            }
            
            // Update the explorer state to remove the deleted records
            $explorerState.records = $explorerState.records.filter(record => 
                !$explorerState.selectedRecords.includes(record.id)
            );
            
            // Clear selection
            $explorerState.selectedRecords = [];
            
            // Show success message
            toast.push(`${selectedRecordObjects.length} record(s) deleted successfully`, {
                classes: ["toast-success"]
            });
            
        } catch (error) {
            console.error('Error deleting records:', error);
            toast.push(`Failed to delete records: ${error.message}`, {
                classes: ["toast-danger"]
            });
        }
    }

    async function saveProject(id = null) {
        if (loading) return;
        
        loading = true;
        
        try {
            // Prompt user for filename
            const result = await Swal.fire({
                titleText: "File Name",
                text: "Please enter a file name:",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Save",
                denyButtonText: "Cancel",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                input: "text",
                inputValue: $projectState.name,
                customClass: {
                    confirmButton: "btn btn-lg btn-primary me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) {
                throw new Error("Saving project was cancelled");
            }
            
            // Update project name and refresh ID if needed
            $projectState.name = result.value;
            $projectState = refreshId($projectState, id);
            
            // Determine file path based on storage location
            const locationId = $explorerState.navHistory.length > 0 
                ? $explorerState.navHistory[$explorerState.navHistory.length - 1].id 
                : null;
            
            const projectFilePath = $explorerState.rootDir === "team"
                ? teamStorageBasePath + $projectState.id
                : homeStorageBasePath + $projectState.id;
                
            // Create project JSON
            const projectJson = ccprjFunc.createCcprj({
                project: JSON.parse(JSON.stringify($projectState)),
                metadata: JSON.parse(JSON.stringify($metadataState)),
                speakers: JSON.parse(JSON.stringify($speakerState)),
                issues: JSON.parse(JSON.stringify($issueState)),
                markers: JSON.parse(JSON.stringify($markerState)),
                eventGroups: JSON.parse(JSON.stringify($eventGroupState)),
                style: JSON.parse(JSON.stringify($styleState))
            });
            
            // Upload to storage
            const uploadProjectRef = storage.ref().child(projectFilePath);
            await uploadProjectRef.putString(JSON.stringify(projectJson));
            
            // Create project record in database
            const projectInfo = {
                id: $projectState.id,
                name: $projectState.name,
                type: $projectState.type === "team" ? "team project" : "project",
                owner: userEmail,
                createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                locationId: locationId,
                shared: $explorerState.rootDir === "team" ? true : false,
                star: false,
                rootDir: $explorerState.rootDir
            };
            
            const dbRef = $explorerState.rootDir === "team" ? teamDbRef : homeDbRef;
            await dbRef.doc(projectInfo.id).set(projectInfo);
            
            // Update project state with location info
            $projectState.folderId = locationId;
            $projectState.rootDir = $explorerState.rootDir;
            
            // Reset history and show success message
            historyState.reset();
            toast.push("Project saved successfully", {
                classes: ["toast-success"],
            });
            
            // Add the new project to the records list and refresh the display
            $explorerState.records = [projectInfo, ...$explorerState.records];
            
            modalState.hideModal();
        } catch (error) {
            console.error("Error saving project:", error);
            toast.push(`Failed to save project: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }
    
    async function replaceRecord() {
        if (!selectedRecord || loading) return;
        
        loading = true;
        
        try {
            // Show confirmation dialog
            const result = await Swal.fire({
                titleText: "Overwrite Project",
                text: `Are you sure you want to overwrite "${selectedRecord.name}"?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Overwrite",
                denyButtonText: "Cancel",
                allowOutsideClick: false,
                allowEscapeKey: false,
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-lg btn-danger me-2",
                    denyButton: "btn btn-lg btn-outline-secondary",
                }
            });
            
            if (!result.isConfirmed) {
                throw new Error("Overwriting project was cancelled");
            }
            
            // Update the project to use the same ID as the selected record
            $projectState = refreshId($projectState, selectedRecord.id);
            
            // Determine file path based on storage location
            const projectFilePath = selectedRecord.rootDir === "team"
                ? teamStorageBasePath + selectedRecord.id
                : homeStorageBasePath + selectedRecord.id;
                
            // Create project JSON
            const projectJson = ccprjFunc.createCcprj({
                project: JSON.parse(JSON.stringify($projectState)),
                metadata: JSON.parse(JSON.stringify($metadataState)),
                speakers: JSON.parse(JSON.stringify($speakerState)),
                issues: JSON.parse(JSON.stringify($issueState)),
                markers: JSON.parse(JSON.stringify($markerState)),
                eventGroups: JSON.parse(JSON.stringify($eventGroupState)),
                style: JSON.parse(JSON.stringify($styleState))
            });
            
            // Upload to storage
            const uploadProjectRef = storage.ref().child(projectFilePath);
            await uploadProjectRef.putString(JSON.stringify(projectJson));
            
            // Update project record in database
            const projectInfo = {
                id: selectedRecord.id,
                name: $projectState.name,
                type: $projectState.type === "team" ? "team project" : "project",
                owner: userEmail,
                createdOn: selectedRecord.createdOn,
                updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
                locationId: selectedRecord.locationId,
                shared: selectedRecord.rootDir === "team" ? true : false,
                star: selectedRecord.star || false,
                rootDir: selectedRecord.rootDir
            };
            
            const dbRef = selectedRecord.rootDir === "team" ? teamDbRef : homeDbRef;
            await dbRef.doc(projectInfo.id).set(projectInfo);
            
            // Update project state with location info
            $projectState.folderId = selectedRecord.locationId;
            $projectState.rootDir = selectedRecord.rootDir;
            
            // Reset history and show success message
            historyState.reset();
            toast.push(`Project "${$projectState.name}" overwritten successfully`, {
                classes: ["toast-success"],
            });
            
            // Update the record in the records list
            $explorerState.records = $explorerState.records.map(record => 
                record.id === selectedRecord.id ? projectInfo : record
            );
            
            modalState.hideModal();
        } catch (error) {
            console.error("Error overwriting project:", error);
            toast.push(`Failed to overwrite project: ${error.message}`, {
                classes: ["toast-danger"],
            });
        } finally {
            loading = false;
        }
    }

    // Function to clear selected records
    function clearSelection() {
        $explorerState.selectedRecords = [];
        selectedRecord = null;
    }
</script>
<div class="col-8 d-flex justify-content-end">
    <div class="btn-toolbar" role="toolbar">
        {#if $explorerState.rootDir === 'personal' || $explorerState.rootDir === 'team'}
            <div class="btn-group btn-group-sm me-2" role="group">
                {#if $explorerState.context === 'save' && ($explorerState.rootDir === 'personal' || $explorerState.rootDir === 'team')}               
                <!-- Add Overwrite button when a non-folder item is selected -->
                {#if canOverwrite && $explorerState.selectedRecords.length === 1}
                    <button class="btn btn-warning" type="button" title="Overwrite Selected Project" on:click={replaceRecord}>
                        <i class="bi bi-arrow-repeat"></i> Overwrite
                    </button>
                {:else}
                <button class="btn btn-primary" type="button" title="Save Project" on:click={() => saveProject()}>
                    <i class="bi bi-save"></i> Save Project
                </button>
                {/if}
                {/if}
                
                {#if $explorerState.view !== 'kanban'}
                <button class="btn btn-outline-primary" type="button" title="New Folder" on:click={createFolder}>
                    <i class="bi bi-folder-plus"></i> New Folder
                </button>
                {/if}
                
                {#if $explorerState.selectedRecords.length > 0}                
                <!-- Add Clear Selection button -->
                <button class="btn btn-outline-secondary" type="button" title="Clear Selection" on:click={clearSelection}>
                    <i class="bi bi-x-circle"></i> Clear Selection
                </button>
                <button class="btn btn-outline-danger" type="button" title="Delete Selected" on:click={deleteSelectedRecords}>
                    <i class="bi bi-trash"></i>
                </button>
                {/if}
            </div>
        {/if}
        
        <div class="btn-group btn-group-sm" role="group">
            <button on:click="{() => $explorerState.view = 'table'}" 
                    class="btn {$explorerState.view === 'table' ? 'btn-primary' : 'btn-outline-secondary'}" 
                    type="button">
                <i class="bi bi-list-ul"></i>
            </button>
            <button on:click="{() => $explorerState.view = 'thumbnail'}" 
                    class="btn {$explorerState.view === 'thumbnail' ? 'btn-primary' : 'btn-outline-secondary'}" 
                    type="button">
                <i class="bi bi-grid-3x3-gap"></i>
            </button>
            {#if $explorerState.context !== 'save'}
            <button on:click="{() => $explorerState.view = 'kanban'}" 
                    class="btn {$explorerState.view === 'kanban' ? 'btn-primary' : 'btn-outline-secondary'}" 
                    type="button">
                <i class="bi bi-kanban"></i>
            </button>
            {/if}
        </div>
    </div>
</div>