function hasConsecutiveSpans(text) {
  const consecutiveSpanRegex = /<\/span>\s*<span(?:\s[^>]*)?>/gi;
  return consecutiveSpanRegex.test(text);
}
export default (function combineSpanStyles(htmlString) {
  // Regular expression to match consecutive span tags with styles
  if (hasConsecutiveSpans(htmlString)) {
    const spanRegex = /<span style="([^"]+)">\s*<span style="([^"]+)">([^<]+)<\/span>\s*<\/span>/g;

    // Replace the matched groups in the HTML string
    var replacedHtml = htmlString.replace(spanRegex, (match, style1, style2, textContent) => {
      return `<span style="${style1.trim()}; ${style2.trim()}">${textContent}</span>`;
    });

    // Handling potentially nested spans requires multiple passes since the
    // regex will not catch overlapping matches
    while (spanRegex.test(replacedHtml)) {
      replacedHtml = replacedHtml.replace(spanRegex, (match, style1, style2, textContent) => {
        return `<span style="${style1.trim()}; ${style2.trim()}">${textContent}</span>`;
      });
    }
    return replacedHtml;
  } else {
    return htmlString;
  }
});