<script>
    export let text;
    export let fontFamily;
</script>

<div class="text-white bg-dark bg-opacity-50 text-center p-2 EditorPreviewText" style="font-family: {fontFamily} !important;">
    {@html text}
</div>
<style>
.EditorPreviewText {
    font-size: 0.8vw;
    height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
}    
</style>