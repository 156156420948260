import _replaceStyleTagsFcpXml from "../functions/helpers/replaceStyleTagsFcpXml.js";
import _textBreak from "../functions/helpers/textBreak.js";
import _cleanText from "../functions/helpers/cleanText.js";
import _convertToPlainTextCustom from "../functions/quill/convertToPlainTextCustom.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
import _formatXml from "xml-formatter";
import _eol from "eol";
import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
const eol = _eol;
const formatXml = _formatXml;
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainTextCustom = _convertToPlainTextCustom;
const cleanText = _cleanText;
const textBreak = _textBreak;
const replaceStyleTagsFcpXml = _replaceStyleTagsFcpXml;
let frameRateMap = {
  "23.976": "24",
  "24": "24",
  "25": "25",
  "29.97": "30",
  "30": "30",
  "50": "50",
  "59.94": "60",
  "60": "60"
};
export default {
  decode: function (input, options) {
    throw new Error("This profile [FCP XML] is not supported for decoding by Closed Caption Converter.");
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions);
    let timebase = frameRateMap[options.frameRate] || "30";
    let output = eol.after(`<?xml version="1.0" encoding="utf-8"?>`);
    let projectName = encodingOptions["Project Name"] || "SUBTITLES";
    let height = encodingOptions["Height"] || "1080";
    let width = encodingOptions["Width"] || "1920";
    let font = encodingOptions["Font Style"] || "Lucida Sans";
    let fontSize = encodingOptions["Font Size"] || "36";
    let totalDuration = eventGroup.events[eventGroup.events.length - 1].start + eventGroup.events[eventGroup.events.length - 1].end - eventGroup.events[0].start;
    output += eol.after(`<fcpxml version="1.5">`);

    //Resources
    output += eol.after(`<resources>`);
    output += eol.after(`<format height="${height}" width="${width}" frameDuration="1/${timebase}s" id="r1" />`);
    output += `<effect id="r2" uid=".../Titles.localized/Bumper:Opener.localized/Basic Title.localized/Basic Title.moti" name="Basic Title" />`;
    output += eol.after(`</resources>`);
    output += eol.after(`<library location="">`);
    output += eol.after(`<event name= "Title">`);
    output += eol.after(`<project name="${projectName}">`);
    output += eol.after(`<sequence duration="${Math.round(totalDuration)}s" format="r1" tcStart="0s" tcFormat="NDF" audioLayout="stereo" audioRate="48k">`);
    output += eol.after(`<spine>`);

    /* Clips */
    eventGroup.events.forEach((event, count) => {
      let basicTitle = `${cleanText(convertToPlainTextCustom(event.text, " ", true), {
        removeSpecialChars: true,
        removeWhitespace: true
      })}`;
      let boldTag = `<text-style-def id="ts${count + 1}-2"><text-style font="${font}" fontSize="${fontSize}" fontFace="Regular" fontColor="0.960784 0.960784 0.960784 1" baseline="${timebase}" shadowColor="0 0 0 1" shadowOffset="5 315" alignment="${event.alignment}" bold="1"/></text-style-def>`;
      let italicTag = `<text-style-def id="ts${count + 1}-3"><text-style font="${font}" fontSize="${fontSize}" fontFace="Regular" fontColor="0.960784 0.960784 0.960784 1" baseline="${timebase}" shadowColor="0 0 0 1" shadowOffset="5 315" alignment="${event.alignment}" italic="1"/></text-style-def>`;

      //Title
      output += eol.after(`<title name="Basic Title:${basicTitle}" lane="1" offset="${tcLib.secToFrames(event.start, options.frameRate, false) * 1000}/${timebase * 1000}s" ref="r2" duration=" ${tcLib.secToFrames(event.end - event.start, options.frameRate, false) * 1000}/${timebase * 1000}s" start="${tcLib.secToFrames(event.start, options.frameRate, false) * 1000}/${timebase * 1000}s">`);

      //Position
      output += eol.after(`<param name="Position" key="9999/999166631/999166633/1/100/101" value="${event.xOffset} ${event.yOffset}" />`);

      //Text
      output += eol.after(`<text>`);
      let eventText = `${textBreak(convertToPlainTextCustom(event.text, " ", false), 26)}`;
      output += eol.after(replaceStyleTagsFcpXml(eventText, count + 1));
      output += eol.after(`</text>`);

      //Styling
      output += eol.after(`<text-style-def id="ts${count + 1}-1"><text-style font="${font}" fontSize="${fontSize}" fontFace="Regular" fontColor="0.960784 0.960784 0.960784 1" baseline="${timebase}" shadowColor="0 0 0 1" shadowOffset="5 315" alignment="${event.alignment}" /></text-style-def>`);
      output += eol.after(boldTag);
      output += eol.after(italicTag);
      output += eol.after(`</title>`);
    });
    output += eol.after(`</spine>`);
    output += eol.after(`</sequence>`);
    output += eol.after(`</project>`);
    output += eol.after(`</event>`);
    output += eol.after(`</library>`);
    output += eol.after(`</fcpxml>`);
    //return formatXml(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};