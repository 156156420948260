import _ExcelJs from "exceljs";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _stripTags from "../functions/quill/stripTags.js";
import _Tag from "../classes/tag.js";
import _Speaker from "../classes/speaker.js";
import _Event from "../classes/event.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const Event = _Event;
const Speaker = _Speaker;
const Tag = _Tag;
const stripTags = _stripTags;
const convertToHtml = _convertToHtml;
const removeInvalidEvents = _removeInvalidEvents;
const ExcelJs = _ExcelJs;
export default {
  decode: async function (input, options) {
    let events = [],
      speakers = [],
      tags = [];
    let inputJson = JSON.parse(input);
    inputJson.sheets.forEach(sheet => {
      sheet.rows.forEach(row => {
        let ccEvent = new Event();
        row.cells.forEach(cell => {
          if (cell.value === undefined) return;
          try {
            //Map cell.value to ccEvent properties based on index. 1 = start, 2 = end, 3 = speakers, 4 = text, 5 = notes, 6 = reply, 7= tags, 8 = forced, 9 = approved
            let cellCol = cell.address.charAt(0).toUpperCase();
            switch (cellCol) {
              case "A":
                ccEvent.start = tcLib.parseTcToSec(cell.value.replace(".", ":"), options.frameRate);
                break;
              case "B":
                ccEvent.end = tcLib.parseTcToSec(cell.value.replace(".", ":"), options.frameRate);
                break;
              case "C":
                let speakerArray = cell.value.split(',');
                speakerArray.forEach(sp => {
                  //Check if speaker already exists in speakers array. If it doesn't exist, add it as a new speaker and push it into ccEvent.speakers. If it does exist, then just push the current version from speakers into ccEvent.speakers.
                  let existingSpeaker = speakers.find(s => s.name === sp);
                  if (existingSpeaker) {
                    ccEvent.speakers.push(existingSpeaker);
                  } else {
                    let newSpeaker = new Speaker({
                      name: sp
                    });
                    speakers.push(newSpeaker);
                    ccEvent.speakers.push(newSpeaker);
                  }
                });
                break;
              case "D":
                ccEvent.text = cell.value;
                break;
              case "E":
                ccEvent.notes = cell.value;
                break;
              case "F":
                ccEvent.reply = cell.value;
                break;
              case "G":
                let tagArray = cell.value.split(',');
                tagArray.forEach(ta => {
                  //Check if speaker already exists in speakers array. If it doesn't exist, add it as a new speaker and push it into ccEvent.speakers. If it does exist, then just push the current version from speakers into ccEvent.speakers.
                  let existingTag = tags.find(t => t.name === ta);
                  if (existingTag) {
                    ccEvent.tags.push(existingTag);
                  } else {
                    let newTag = new Tag({
                      term: ta,
                      type: "Other"
                    });
                    tags.push(newTag);
                    ccEvent.tags.push(newTag);
                  }
                });
                break;
              case "H":
                ccEvent.forced = cell.value && cell.value.toLowerCase() === "y" ? true : false;
                break;
              case "I":
                ccEvent.approved = cell.value && cell.value.toLowerCase() === "y" ? true : false;
                break;
              default:
                break;
            }
          } catch (err) {
            console.log(err.message);
          }
        });
        if (ccEvent.start) {
          ccEvent.text = convertToHtml(ccEvent.text || "");
          events.push(ccEvent);
        }
      });
    });
    return events;
  },
  encode: async function (eventGroup, options) {
    let output;

    //CSV Headers: EVENT NUMBER | TC IN | TC OUT | CHARACTER | SCRIPT EVENT | NOTE | QC NOTE* | Tag | FS | Pass/Fail
    let rows = [["Event", "TimeCode In", "TimeCode Out", "Source", "Dialogue", "Note", "QC Note", "Tag", "FS", "Pass"]];
    eventGroup.events.forEach((event, index) => {
      rows.push([index + 1,
      //Change last : to . in timecode so that 01:00:00:00 becomes 01:00:00.00
      tcLib.secToTc(Math.max(0, event.start), options.frameRate).replace(/:([^:]*)$/, ".$1"), tcLib.secToTc(Math.max(0, event.end), options.frameRate).replace(/:([^:]*)$/, ".$1"), event.speakers.map(sp => {
        return sp.name;
      }).join(","), stripTags(event.text), event.notes, event.reply, event.tags.join(","), event.forced ? "Y" : "N", event.approved ? "Y" : "N"]);
    });
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet("DVW QC Script");
    worksheet.addRows(rows);
    output = await workbook.xlsx.writeBuffer();
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup, true);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};