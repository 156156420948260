export default [
    {
        "name": "System",
        "shortcuts": [
            {
                "name": "Copy",
                "description": "Copy event or selected text",
                "keyCmd": "Meta(CMD)+KeyC"
            },
            {
                "name": "Cut",
                "description": "Cut event or selected text",
                "keyCmd": "Meta(CMD)+KeyX"
            },
            {
                "name": "Export File",
                "description": "Open the export menu",
                "keyCmd": "Meta(CMD)+KeyM"
            },
            {
                "name": "Import File",
                "description": "Open the import file menu",
                "keyCmd": ""
            },
            {
                "name": "New Project",
                "description": "Open the New Project window",
                "keyCmd": ""
            },
            {
                "name": "Paste",
                "description": "Paste event or text",
                "keyCmd": "Meta(CMD)+KeyV"
            },
            {
                "name": "Save Project",
                "description": "Save project or open 'Save As' dialogue box",
                "keyCmd": "Meta(CMD)+KeyS"
            }
        ]
    },
    {
        "name": "Media Player",
        "shortcuts": [
            {
                "name": "Decrease Playback Speed",
                "description": "Decrease the playback speed of the player. Playback rates include 0.25x - 16x",
                "shortName": "decreasePlaybackRate",
                "keyCmd": ""
            },
            {
                "name": "Forward 1 Frame",
                "description": "Go forward one frame in the video",
                "shortName": "forward",
                "keyCmd": "Meta(CMD)+Equal"
            },
            {
                "name": "Forward 1 Second",
                "description": "Go forward one second in the video",
                "shortName": "forwardOneSec",
                "keyCmd": ""
            },
            {
                "name": "Forward 10 Frame",
                "description": "Go forward ten frames in the video",
                "shortName": "forward",
                "keyCmd": ""
            },
            {
                "name": "Forward 3 Second",
                "description": "Go forward three second in the video",
                "shortName": "forwardThreeSec",
                "keyCmd": ""
            },
            {
                "name": "Forward 5 Frame",
                "description": "Go forward five frames in the video",
                "shortName": "forwardFiveFrames",
                "keyCmd": "Shift+Meta(CMD)+Equal"
            },
            {
                "name": "Go To End Of Event",
                "description": "Update playhead to the end of the selected event",
                "shortName": "endOfEvent",
                "keyCmd": ""
            },
            {
                "name": "Go To Start Of Event",
                "description": "Update playhead to the start of the selected event",
                "shortName": "startOfEvent",
                "keyCmd": ""
            },
            {
                "name": "Increase Playback Speed",
                "description": "Increase the playback speed of the player. Playback rates include 0.25x - 16x",
                "shortName": "increasePlaybackRate",
                "keyCmd": ""
            },
            {
                "name": "Next Marker",
                "description": "Go to next marker",
                "shortName": "goToNextMarker",
                "keyCmd": ""
            },
            {
                "name": "Previous Marker",
                "description": "Go to previous marker",
                "shortName": "goToPrevMarker",
                "keyCmd": ""
            },
            {
                "name": "Reverse 1 Frame",
                "description": "Go back one frame in the video",
                "shortName": "reverse",
                "keyCmd": "Meta(CMD)+Minus"
            },
            {
                "name": "Reverse 1 Second",
                "description": "Go back one second in the video",
                "shortName": "reverseOneSec",
                "keyCmd": ""
            },
            {
                "name": "Reverse 10 Frame",
                "description": "Go back ten frames in the video",
                "shortName": "reverse",
                "keyCmd": ""
            },
            {
                "name": "Reverse 3 Second",
                "description": "Go back three second in the video",
                "shortName": "reverseThreeSec",
                "keyCmd": ""
            },
            {
                "name": "Reverse 5 Frame",
                "description": "Go back five frames in the video",
                "shortName": "reverseFiveFrames",
                "keyCmd": "Shift+Meta(CMD)+Minus"
            },
            {
                "name": "Toggle Caption Lock",
                "description": "Toggle caption lock on/off",
                "shortName": "toggleCaptionLock",
                "keyCmd": ""
            },
            {
                "name": "Toggle Fast Forward",
                "description": "Fast Forward through video (foot fedal recommended)",
                "shortName": "fastForward",
                "keyCmd": ""
            },
            {
                "name": "Toggle Play Preview",
                "description": "Toggle play preview on/off",
                "shortName": "togglePreviewLock",
                "keyCmd": ""
            },
            {
                "name": "Toggle Playback (Play/Pause)",
                "description": "Play or pause video playback",
                "shortName": "playPause",
                "keyCmd": "Backquote"
            },
            {
                "name": "Toggle Rewind",
                "description": "Rewind through video (foot fedal recommended)",
                "shortName": "rewind",
                "keyCmd": ""
            },
            {
                "name": "Toggle Video Lock",
                "description": "Toggle video lock on/off",
                "shortName": "toggleVideoLock",
                "keyCmd": ""
            }
        ]
    },
    {
        "name": "Editor",
        "shortcuts": [
            {
                "name": "Add 1 Frame To End",
                "description": "Increase end time by one frame",
                "shortName": "addFrameToEnd",
                "keyCmd": ""
            },
            {
                "name": "Add 1 Frame To Start",
                "description": "Increase start time by one frame",
                "shortName": "addFrameToStart",
                "keyCmd": ""
            },
            {
                "name": "Adjust Duration to Reading Speed",
                "description": "Automatically update the duration of the Event based on the set reading speed (CPS) of the Event Group.",
                "shortName": "adjustDuration",
                "keyCmd": "Shift+Meta(CMD)+KeyD"
            },
            {
                "name": "Auto Format",
                "description": "Auto format the currently selected event based on the Event Group Settings",
                "shortName": "autoFormat",
                "keyCmd": "Meta(CMD)+KeyD"
            },
            {
                "name": "Chain To Next Event",
                "description": "Snap the Event outcode to the next Event's incode",
                "shortName": "snapToStart",
                "keyCmd": ""
            },
            {
                "name": "Chain To Previous Event",
                "description": "Snap the Event incode to the previous Event's outcode",
                "shortName": "snapToEnd",
                "keyCmd": ""
            },
            {
                "name": "Clear Event Text",
                "description": "Clear the text of the selected Events",
                "shortName": "clearEventText",
                "keyCmd": ""
            },
            {
                "name": "Clear Timecodes",
                "description": "Clear the timecodes of the selected Events",
                "shortName": "clearTimecodes",
                "keyCmd": ""
            },
            {
                "name": "Compress",
                "description": "Compress the event text vertically",
                "shortName": "compress",
                "keyCmd": "Meta(CMD)+NumpadMultiply"
            },
            {
                "name": "Delete Event",
                "description": "Delete the selected event",
                "shortName": "deleteEvent",
                "keyCmd": ""
            },
            {
                "name": "Expand",
                "description": "Expand the event text horizontally",
                "shortName": "expand",
                "keyCmd": "Meta(CMD)+NumpadDivide"
            },
            {
                "name": "Go To Event",
                "description": "Open the Go To Event window to navigate to a specific event in the selected Event Group",
                "shortName": "goToEvent",
                "keyCmd": ""
            },
            {
                "name": "Insert ♪",
                "description": "Insert single music note",
                "shortName": "insertMusicNote",
                "keyCmd": ""
            },
            {
                "name": "Insert Ellipsis",
                "description": "Insert ellipsis",
                "shortName": "insertEllipsis",
                "keyCmd": ""
            },
            {
                "name": "Insert Event (Above)",
                "description": "Insert event above the currently selected event.",
                "shortName": "insertEventBefore",
                "keyCmd": ""
            },
            {
                "name": "Insert Event (Below)",
                "description": "Insert new event below the selected event",
                "shortName": "insertEvent",
                "keyCmd": "Tab"
            },
            {
                "name": "Insert Speaker",
                "description": "Open the Insert Speaker Name window",
                "shortName": "insertSpeaker",
                "keyCmd": ""
            },
            {
                "name": "Insert Surround ♪",
                "description": "Insert surround single music notes to all selected events",
                "shortName": "insertSurroundMusicNotes",
                "keyCmd": ""
            },
            {
                "name": "Insert Surround ♪♪",
                "description": "Insert surround ♪♪ to all selected events",
                "shortName": "insertDblSurroundMusicNotes",
                "keyCmd": "Shift+Meta(CMD)+KeyM"
            },
            {
                "name": "Merge Down",
                "description": "Merge the selected Event with the next Event.",
                "shortName": "mergeEventDown",
                "keyCmd": ""
            },
            {
                "name": "Merge Up",
                "description": "Merge the selected Event with the previous Event.",
                "shortName": "mergeEventUp",
                "keyCmd": ""
            },
            {
                "name": "Move Line Down",
                "description": "Shift line forward to the beginning of the next Event",
                "shortName": "shiftLineForward",
                "keyCmd": ""
            },
            {
                "name": "Move Line Up",
                "description": "Shift line backwards to the end of the previous Event",
                "shortName": "shiftLineBack",
                "keyCmd": ""
            },
            {
                "name": "Move Word Down",
                "description": "Move a single word down to the next line",
                "shortName": "moveWordDown",
                "keyCmd": ""
            },
            {
                "name": "Move Word To Next Event",
                "description": "Shift a single word forward to the beginning of the next Event",
                "shortName": "shiftWordForward",
                "keyCmd": ""
            },
            {
                "name": "Move Word To Previous Event",
                "description": "Shift a single word back to the end of the previous Event",
                "shortName": "shiftWordBack",
                "keyCmd": ""
            },
            {
                "name": "Move Word Up",
                "description": "Move a single word up to the previous line",
                "shortName": "moveWordUp",
                "keyCmd": ""
            },
            {
                "name": "Offset Timecode",
                "description": "Offset the timecode of the selected event(s) based on the playhead position",
                "shortName": "tcOffset",
                "keyCmd": ""
            },
            {
                "name": "Order Events by Time",
                "description": "Sort the Event List by start time.",
                "shortName": "orderByTime",
                "keyCmd": ""
            },
            {
                "name": "Select All Events",
                "description": "Select all events in event group",
                "shortName": "selectAllEvents",
                "keyCmd": "Meta(CMD)+KeyA"
            },
            {
                "name": "Select All Events From Current",
                "description": "Select all Events in Event Group starting the currently selected event.",
                "shortName": "selectAllEventsFromCurrent",
                "keyCmd": ""
            },
            {
                "name": "Select Next Event",
                "description": "Select the next event in the list",
                "shortName": "selectNextEditorEvent",
                "keyCmd": "ArrowDown"
            },
            {
                "name": "Select Previous Event",
                "description": "Select the previous event in the list",
                "shortName": "selectPrevEditorEvent",
                "keyCmd": "ArrowUp"
            },
            {
                "name": "Set Incode (Start)",
                "description": "Update the incode of the selected Event based on the current media time",
                "shortName": "markIn",
                "keyCmd": "Meta(CMD)+KeyI"
            },
            {
                "name": "Set Outcode (End)",
                "description": "Update the outcode of the selected Event based on the current media time",
                "shortName": "markOut",
                "keyCmd": "Meta(CMD)+KeyO"
            },
            {
                "name": "Shift Event Backward",
                "description": "Shift the selected event(s) backward by one frame",
                "shortName": "shiftEventBackward",
                "keyCmd": ""
            },
            {
                "name": "Shift Event Forward",
                "description": "Shift the selected event(s) forward by one frame",
                "shortName": "shiftEventForward",
                "keyCmd": ""
            },
            {
                "name": "Split Event",
                "description": "Split the selected event based on the cursor location",
                "shortName": "splitEvent",
                "keyCmd": ""
            },
            {
                "name": "Subtract 1 Frame From End",
                "description": "Decrease end time by one frame",
                "shortName": "removeFrameFromEnd",
                "keyCmd": ""
            },
            {
                "name": "Subtract 1 Frame From Start",
                "description": "Decrease start time by one frame",
                "shortName": "removeFrameFromStart",
                "keyCmd": ""
            },
            {
                "name": "Toggle Edit Mode",
                "description": "Toggling Edit Mode will focus the textarea of the selected Event. This will allow you to quickly edit the text of the Event. Disabling Edit Mode will return the focus to the Event List.",
                "shortName": "toggleEditMode",
                "keyCmd": "Meta(CMD)+Backquote"
            },
            {
                "name": "Toggle Forced Narrative Subtitles",
                "description": "Mark selected event(s) as Forced Narrative Subtitles",
                "shortName": "markForcedSubtitle",
                "keyCmd": ""
            }
        ]
    },
    {
        "name": "Timeline",
        "shortcuts": [
            {
                "name": "Select Next Event On Timeline",
                "description": "Selects the next event closest to the current player time",
                "shortName": "selectNextEvent",
                "keyCmd": ""
            },
            {
                "name": "Select Previous Event On Timeline",
                "description": "Selects the previous event closest to the current player time",
                "shortName": "selectPrevEvent",
                "keyCmd": ""
            },
            {
                "name": "Zoom In",
                "description": "Zoom in on the Timeline",
                "shortName": "timelineZoomIn",
                "keyCmd": ""
            },
            {
                "name": "Zoom Out",
                "description": "Zoom out on the Timeline",
                "shortName": "timelineZoomOut",
                "keyCmd": ""
            }
        ]
    },
    {
        "name": "Audio Description",
        "shortcuts": [
            {
                "name": "Render Text-to-Speech",
                "description": "Render Audio Description (DV) text to audio.",
                "shortName": "renderAudio",
                "keyCmd": ""
            },
            {
                "name": "Trim To Audio Duration",
                "description": "Update the outcode of selected audio descripiton Events to match the duration of the rendered audio.",
                "shortName": "trimToDuration",
                "keyCmd": ""
            }
        ]
    },
    {
        "name": "Closed Captioning",
        "shortcuts": [
            {
                "name": "Move Down 1 Line ",
                "description": "Move the Event position down 1 line",
                "shortName": "positionDownLine",
                "keyCmd": ""
            },
            {
                "name": "Move Up 1 Line ",
                "description": "Move the Event position up 1 line",
                "shortName": "positionUpLine",
                "keyCmd": ""
            },
            {
                "name": "Position Bottom Center",
                "description": "Position Events to the bottom center of the video",
                "shortName": "positionBottomCenter",
                "keyCmd": "Numpad2"
            },
            {
                "name": "Position Bottom Left",
                "description": "Position Events to the bottom left of the video",
                "shortName": "positionBottomLeft",
                "keyCmd": "Numpad1"
            },
            {
                "name": "Position Bottom Right",
                "description": "Position Events to the bottom right of the video",
                "shortName": "positionBottomRight",
                "keyCmd": "Numpad3"
            },
            {
                "name": "Position Center",
                "description": "Position Events to the center of the video",
                "shortName": "positionCenter",
                "keyCmd": "Numpad5"
            },
            {
                "name": "Position Center Left",
                "description": "Position Events to the center left of the video",
                "shortName": "positionCenterLeft",
                "keyCmd": "Numpad4"
            },
            {
                "name": "Position Center Right",
                "description": "Position Events to the center right of the video",
                "shortName": "positionCenterRight",
                "keyCmd": "Numpad6"
            },
            {
                "name": "Position Top Center",
                "description": "Position Events to the top center of the video",
                "shortName": "positionTopCenter",
                "keyCmd": "Numpad8"
            },
            {
                "name": "Position Top Left",
                "description": "Position Events to the top left of the video",
                "shortName": "positionTopLeft",
                "keyCmd": "Numpad7"
            },
            {
                "name": "Position Top Right",
                "description": "Position Events to the top right of the video",
                "shortName": "positionTopRight",
                "keyCmd": "Numpad9"
            }
        ]
    }
]