<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { fade } from 'svelte/transition';
let selected = 0, hide = true;
const slides = [
    {
        title: "Release 1.8.29",        
        description: "2025-01-28",
        lead : "",
        cards : [
            {
                title : "Now/Next Preview",
                sub : "View past and present Events",
                description : "Enable the new Event preview to show the previous and next Event in the Media Player UI. This is meant to help when caption spotting, or when you need to see the context of the current Event in relation to the previous and next Events.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/NowNextPreview.jpg'
            },   
            {
                title : "Error Panel",
                sub : "Navigate to errors quickly",
                description : "The new Error Panel in the QuickTools drawer allows you to navigate between errors and updates in realtime. Clicking on an error in the Error Panel will take you to the Event where the error occurred.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/ErrorPanel.jpg'
            },                    
            {
                title : "Import/Export User Settings",
                sub : "Share user setting with you team.",
                description : "Backup and restore your user settings, or share them with your team. Export your user settings to a file, and import them on another computer to have the same settings.",
                buttonText : "",
                buttonLink : false,
                image : './assets/img/BackupUserSettings.jpg'
            },    
        ]
    }
];

function hideModal(){
    if (hide){
        localStorage.setItem('cc-hide-v29', 'true');
    } else {
        localStorage.setItem('cc-hide-v29', 'false');
    }
    
    modalState.showModal('welcome');    
}
</script>

<div class="modal fade {$modalState === 'tips' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TipsModal">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">What's New</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={() => hideModal()}></button>
            </div>
            <div class="modal-body">
                <p class="text-secondary m-0">{slides[selected].description}</p>
                <h4>{slides[selected].title}</h4>
                
                <p class="lead">{slides[selected].lead}</p>
                <div class="container">
                    <div class="row">
                        {#each slides[selected].cards as card}
                        <div class="col-4 mb-4" transition:fade="{{delay: 250, duration: 300}}">
                            <div class="card text-dark bg-light shadow border-0">
                                {#if card.image}
                                    <img src="{card.image}" class="card-img-top" alt='Help Top'>
                                {/if}
                                <div class="card-body">
                                    <h4 class="fw-bold card-title">{card.title}</h4>
                                    <h6 class="fw-bold card-subtitle mb-2">{card.sub}</h6>
                                    <p class="card-text">{card.description}</p>
                                    {#if card.link}
                                        <button class="btn btn-outline-primary w-100" type="button" on:click={()=>{isNaN(card.buttonLink) ? modalState.showModal(card.buttonLink) : selected = card.buttonLink}}>{card.buttonText}</button>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        {/each}
                    </div>
                </div>
            </div>
            <div class="p-3 border-top border-light">
                <div class="float-start">
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="hideCheck" bind:checked={hide}>
                        <label class="form-check-label" for="hideCheck">Hide on startup</label>
                    </div>
                </div>                
                    <div class="float-end">                          
                        <button type="button" class="btn btn-primary me-2" on:click="{() => hideModal()}">Close</button>
                        <a class="btn btn-outline-dark" href="https://www.closedcaptioncreator.com/releases.html" target="_blank" rel="noreferrer">Learn More</a>
                    </div>                
            </div>
        </div>
    </div>
</div>
