import _ttmlFunc from "../functions/profiles/ttmlGeneral.js";
import { XMLParser as _XMLParser } from "fast-xml-parser";
import _eol from "eol";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
import _tcLib from "../lib/timecode.js";
const tcLib = _tcLib;
const removeInvalidEvents = _removeInvalidEvents;
const getFormatOptions = _getFormatOptions;
const convertToPlainText = _convertToPlainText;
const eol = _eol;
const XMLParser = _XMLParser; //Decode
const ttmlFunc = _ttmlFunc;
export default {
  decode: function (input, options) {
    let events = [],
      regions = [],
      region;
    const xmlToJson = new XMLParser({
      stopNodes: ["*.p"],
      numberParseOptions: {
        skipLike: /[0-9]+/
      },
      ignoreAttributes: false
    });
    let fileJson = xmlToJson.parse(input);

    //console.log(JSON.stringify(fileJson, null, 4));

    /* Put the region data into an array */
    if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
      if (Array.isArray(fileJson.tt.head.layout.region)) {
        regions = fileJson.tt.head.layout.region;
      } else {
        regions = [fileJson.tt.head.layout.region];
      }
    }
    if (Array.isArray(fileJson.tt.body.div)) {
      fileJson.tt.body.div.forEach(paragraphGroup => {
        if (Array.isArray(paragraphGroup.p)) {
          paragraphGroup.p.forEach(paragraph => {
            if (paragraph["@_region"]) {
              region = regions.find(regionEl => {
                return regionEl["@_xml:id"] === paragraph["@_region"];
              });
            } else {
              region = false;
            }
            events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
          });
        } else {
          if (paragraphGroup.p["@_region"]) {
            region = regions.find(regionEl => {
              return regionEl["@_xml:id"] === paragraphGroup.p["@_region"];
            });
          } else {
            region = false;
          }
          events.push(ttmlFunc.multiLine.decodeSubtitle(paragraphGroup.p, region, options.frameRate, options.window));
        }
      });
    } else {
      fileJson.tt.body.div.p.forEach(paragraph => {
        if (paragraph["@_region"]) {
          region = regions.find(regionEl => {
            return regionEl["@_xml:id"] === paragraph["@_region"];
          });
        } else {
          region = false;
        }
        events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
      });
    }
    return events;
  },
  encode: function (eventGroup, options) {
    let encodingOptions = getFormatOptions(options.formatOptions),
      version = encodingOptions["Version"] || "1.0",
      language = encodingOptions["Language Code"] || "ja",
      aspectRatio = encodingOptions["Language Code"] || "4:3",
      programName = encodingOptions["Program Name"] || "Untitled",
      programDescription = encodingOptions["Program Description"] || "Created by CC Converter 3",
      fontFamily = encodingOptions["Font Family"] || "monospaceSerif",
      fontSize = encodingOptions["Font Size"] || "80",
      lineHeight = encodingOptions["Line Height"] || "100",
      fontWeight = encodingOptions["Font Weight"] || "normal",
      fontStyle = encodingOptions["Font Style"] || "normal",
      fontColor = encodingOptions["Font Color"] || "white",
      bgColor = encodingOptions["Background Color"] || "black",
      tcFormat = encodingOptions["Timecode Format"] || "smpte",
      regions = "",
      paragraphs = "";
    let frameRateMultiplier = ttmlFunc.frameRateMultiplierMap[options.frameRate];
    let output = eol.after(`<?xml version="1.0" encoding="utf-8"?>`);
    output += eol.after(`<tt xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xml:lang="${language}" ttp:contentProfiles="http://www.w3.org/ns/ttml/profile/imsc1.1/text" xmlns:ebutts="urn:ebu:tt:style" xmlns:itts="http://www.w3.org/ns/ttml/profile/imsc1#styling" xmlns:ittp="http://www.w3.org/ns/ttml/profile/imsc1#parameter" xmlns:ittm="http://www.w3.org/ns/ttml/profile/imsc1#metadata" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:timeBase="media" xmlns="http://www.w3.org/ns/ttml">`);
    output += eol.after(`<head>`);
    output += eol.after(`<metadata>
    <ttm:title>${programName}</ttm:title>
    <ttm:desc>${programDescription}</ttm:desc>
</metadata>`);
    output += eol.after(`<styling>
    <initial xml:id="initialStyles" tts:color="${fontColor}" tts:fontFamily="${fontFamily}" tts:fontSize="${fontSize}%" tts:textOutline="black 4%" />
    <style tts:shear="16.67%" xml:id="italic" />
    <style tts:textEmphasis="filled dot outside" xml:id="bouten-filled-dot-outside" />
    <style tts:textCombine="all" xml:id="horizontalDigit" />
    <style tts:ruby="base" xml:id="ruby-base" />
    <style tts:ruby="text" xml:id="ruby-text" />
    <style tts:ruby="container" tts:rubyPosition="outside" tts:rubyAlign="center" xml:id="ruby-outside-container" />
</styling>`);
    output += eol.after(`<layout>
    <region ebutts:multiRowAlign="start" tts:displayAlign="after" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="center" tts:luminanceGain="3.0" xml:id="bottom-left-justified" />
    <region tts:displayAlign="before" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="center" tts:luminanceGain="3.0" xml:id="top-center-justified" />
    <region tts:displayAlign="before" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="start" tts:writingMode="tbrl" tts:luminanceGain="3.0" xml:id="right" />
    <region tts:displayAlign="after" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="start" tts:writingMode="tbrl" tts:luminanceGain="3.0" xml:id="left" />
    <region ebutts:multiRowAlign="end" tts:displayAlign="before" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="center" tts:shear="16.6667%" tts:luminanceGain="3.0" xml:id="force-narrative-example-region" />
</layout>`);
    output += eol.after(`</head>`);
    output += eol.after(`<body>`);
    output += eol.after(`<div>`);
    eventGroup.events.forEach(event => {
      try {
        let start = tcFormat === "smpte" ? tcLib.secToTcClock(event.start, options.frameRate) : tcLib.secToTcMs(event.start).replace(",", ".");
        let end = tcFormat === "smpte" ? tcLib.secToTcClock(event.end, options.frameRate) : tcLib.secToTcMs(event.end).replace(",", ".");
        let region = "bottom-left-justified";
        if (event.yPos === "start") {
          region = event.xPos === "start" ? "left" : "right";
          region = event.xPos === "center" ? "top-center-justified" : region;
        }
        event.text.split("</p>").forEach(eventLine => {
          if (!eventLine) {
            return;
          }
          let style = eventLine.match(/<em>/);
          let plainText = eventLine.replace(/<strong>/g, "__START_STRONG__");
          plainText = plainText.replace(/<\/strong>/g, "__END_STRONG__");
          plainText = plainText.replace(/(?<=\[.+)]|\[(?=.+])/g, "");
          plainText = convertToPlainText(plainText);

          /* Parse for Ruby Start */
          let plainTextArray = plainText.split(" ");
          if (plainTextArray.length > 1) {
            plainText = "";
            plainTextArray.forEach(textPart => {
              if (/.+\(.+\)/.test(textPart)) {
                plainText += `<span style="ruby-outside-container"><span style="ruby-base">`;
                plainText += textPart.split("(")[0] + `</span><span style="ruby-text">`;
                plainText += textPart.split("(")[1].replace(")", "") + `</span></span>`;
              } else {
                plainText += textPart;
              }
            });
          }
          /* Parse for Ruby end */

          /* Parse for Boutens */
          plainTextArray = plainText.split(/__END_STRONG__/g);
          if (plainTextArray.length > 1) {
            plainText = "";
            plainTextArray.forEach(word => {
              if (/__START_STRONG__/.test(word)) {
                word = word.replace("__START_STRONG__", "");
                let charArray = word.split("");
                charArray.forEach((char, index, chars) => {
                  chars[index] = `<span style="bouten-filled-dot-outside">${char}</span>`;
                });
                word = charArray.join("");
              }
              plainText += word;
            });
          }
          /* Parse for Boutens End*/

          output += eol.after(`<p begin="${start}" end="${end}" region="${region}"${style ? ' style="italic"' : ""}>${plainText}</p>`);
        });
      } catch (err) {
        console.log(err, err.message);
      }
    });
    output += eol.after(`</div>`);
    output += eol.after(`</body>`);
    output += `</tt>`;
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return eol.lf(input);
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};