import _convertToHtml from "../functions/quill/convertToHtml.js";
import _eol from "eol";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const eol = _eol;
const convertToHtml = _convertToHtml;
export default {
  decode: function (input, options) {
    let events = [],
      spaces = "    ",
      tc;
    let fileLines = input.split("\n").filter(fileLine => {
      return /^\d\d:\d\d:\d\d:\d\d|^\d\d:\d\d:\d\d;\d\d/g.test(fileLine.split(spaces)[0]);
    });
    fileLines.forEach(fileLine => {
      let lineData = fileLine.split(" ").filter(part => {
        return part;
      });
      tc = lineData.shift();
      let text = lineData.join(" ").trim();
      if (text) {
        if (events.length > 0 && !events[events.length - 1].end) {
          events[events.length - 1].end = tcLib.tcToSec(tc, options.frameRate);
        }
        let ccEvent = new Event({
          xOffset: 0,
          yOffset: options.window.height * -0.10
        });
        ccEvent.style = "Roll-Up";
        ccEvent.start = tcLib.tcToSec(tc, options.frameRate);
        ccEvent.text = convertToHtml(text);
        events.push(ccEvent);
      } else {
        events[events.length - 1].end = tcLib.tcToSec(tc, options.frameRate);
      }
    });
    return events;
  },
  encode: function (eventGroup, options) {
    throw new Error("Eclipse Caption Files are not supported for encoding by Closed Caption Converter.");
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return eol.lf(input.trim());
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};