export default (function shiftWordBack(event1, event2, maxChars = 32) {
  // Create a DOM parser to work with HTML
  const parser = new DOMParser();

  // Parse both event texts into DOM
  const doc1 = parser.parseFromString(event1.text, 'text/html');
  const doc2 = parser.parseFromString(event2.text, 'text/html');

  // Get all paragraphs from first event
  const firstEventParagraphs = doc1.getElementsByTagName('p');
  if (firstEventParagraphs.length === 0) {
    return {
      event1,
      event2
    }; // No paragraphs to process
  }

  // Get the last paragraph with content
  const lastParagraph = firstEventParagraphs[firstEventParagraphs.length - 1];

  // Get all text nodes and their wrapping elements
  const textNodes = [];
  function traverseNodes(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.trim()) {
        textNodes.push({
          node,
          parent: node.parentNode
        });
      }
    } else {
      node.childNodes.forEach(child => traverseNodes(child));
    }
  }
  traverseNodes(lastParagraph);
  if (textNodes.length === 0) {
    return {
      event1,
      event2
    }; // No text content to process
  }

  // Get the last word from the last text node
  const lastTextNode = textNodes[textNodes.length - 1].node;
  const words = lastTextNode.textContent.trim().split(/\s+/);
  const lastWord = words[words.length - 1];
  const remainingWords = words.slice(0, -1).join(' ');

  // Update the first event's text
  if (remainingWords) {
    lastTextNode.textContent = ' ' + remainingWords;
  } else {
    // Remove the text node if it's empty
    const parent = textNodes[textNodes.length - 1].parent;
    parent.removeChild(lastTextNode);

    // If the parent element is now empty and it's not the paragraph itself,
    // remove the parent element
    if (parent !== lastParagraph && !parent.hasChildNodes()) {
      parent.parentNode.removeChild(parent);
    }
  }

  // If the paragraph is now empty, remove it
  if (!lastParagraph.textContent.trim()) {
    lastParagraph.parentNode.removeChild(lastParagraph);
  }

  // Add the word to the second event
  const doc2Paragraphs = doc2.getElementsByTagName('p');
  let firstParagraph = doc2Paragraphs[0];

  // If there's no paragraph in the second event, create one
  if (!firstParagraph) {
    firstParagraph = doc2.createElement('p');
    doc2.body.appendChild(firstParagraph);
  }

  // Check if adding the word would exceed maxChars
  const currentText = firstParagraph.textContent;
  if (currentText.length + lastWord.length + 1 > maxChars) {
    // Create new paragraph for the word and insert it at the beginning
    const newParagraph = doc2.createElement('p');
    newParagraph.textContent = lastWord;
    if (firstParagraph.parentNode) {
      firstParagraph.parentNode.insertBefore(newParagraph, firstParagraph);
    } else {
      doc2.body.appendChild(newParagraph);
    }
  } else {
    // Insert the word at the beginning of the first paragraph
    const textNode = doc2.createTextNode(lastWord + (currentText.length > 0 ? ' ' : ''));
    firstParagraph.insertBefore(textNode, firstParagraph.firstChild);
  }

  // Update both events with new HTML
  event1.text = doc1.body.innerHTML;
  event2.text = doc2.body.innerHTML;
  return {
    event1,
    event2
  };
});