<script>
import {
    onMount
} from 'svelte';
import firebase from '../configs/firebase'
import * as firebaseui from 'firebaseui'
import {
    replace
} from 'svelte-spa-router'


onMount(async () => {
    let fbUiConfig = {
        'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
        signInOptions: [{
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
        }],
        callbacks: {
            signInSuccessWithAuthResult() {
                replace("/");
            }
        }
    }

    let fbUi = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    fbUi.start("#firebaseui-auth-container", fbUiConfig);
});
</script>

<div id="LoginScreen">
    <div class="container d-flex justify-content-center align-items-center LoginPageWrapper">
        <div class="col-5 shadow LoginFormWrapper text-white rounded">
            <div class="d-flex justify-content-center align-items-center mb-2">
                <img src="./icon.png" width="100px" alt="Closed Caption Creator Logo" class="mb-1"/>
            </div>
            <h4 class="text-center mb-4">Closed Caption Creator</h4>
            <div id="firebaseui-auth-container"></div>
            <small class="form-text text-center text-white small">
                Please contact support if you have any issues: support@closedcaptioncreator.com
            </small>
        </div>
    </div>
</div>

<style>
#LoginScreen {
    background-color: #1a1a1a;
    background-image: linear-gradient(135deg, #1a1a1a 25%, #333333 100%);
    background-attachment: fixed;
    background-size: cover;
    color: #f0f0f0;
    font-family: 'Roboto', sans-serif;
}

.LoginPageWrapper {
    height: 100vh;
}

.LoginFormWrapper {
    background: rgba(32, 33, 33, 0.95);
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.LoginFormWrapper img {
    border-radius: 50%;
    border: 2px solid #f0f0f0;
}

h4 {
    font-weight: 300;
    letter-spacing: 1px;
}

small {
    display: block;
    margin-top: 20px;
    font-size: 0.85rem;
    color: #cccccc;
}
</style>
