<script>
import {
    modalState
} from '@app/store/modalStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import { onMount } from 'svelte';
import {
    fade
} from 'svelte/transition';
let progress = 0, statusMsg = "Starting sync process... please wait.";

onMount(async () => {
    try {
        console.log("Starting sync process");
        if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].type === "translation"){
            let linkedGroupIndex = $eventGroupState.findIndex(evg =>{
                return evg.id === $eventGroupState[$projectState.selected].linkedGroup;
            });

            if (linkedGroupIndex === -1){
                throw new Error(`Linked Event Group not found in project. Linked group id: ${$eventGroupState[$projectState.selected].linkedGroup}`);
            }

            $eventGroupState[linkedGroupIndex].events.forEach((event, index, events) =>{
                progress = parseInt((index/events.length) * 100);
                statusMsg = `Syncing event ${index+1}/${events.length}`;

                let eventIndex = $eventGroupState[$projectState.selected].events.findIndex(ev =>{
                    return ev.id === event.id;
                });

                if (eventIndex > -1){
                    $eventGroupState[$projectState.selected].events[eventIndex].original = event.text;
                    $eventGroupState[$projectState.selected].events[eventIndex].start = event.start;
                    $eventGroupState[$projectState.selected].events[eventIndex].end = event.end;
                    $eventGroupState[$projectState.selected].events[eventIndex].xPos = event.xPos;
                    $eventGroupState[$projectState.selected].events[eventIndex].yPos = event.yPos;
                    $eventGroupState[$projectState.selected].events[eventIndex].xOffset = event.xOffset;
                    $eventGroupState[$projectState.selected].events[eventIndex].yOffset = event.yOffset;
                    $eventGroupState[$projectState.selected].events[eventIndex].alignment = event.alignment;
                    $eventGroupState[$projectState.selected].events[eventIndex].speakers = event.speakers;
                    $eventGroupState[$projectState.selected].events[eventIndex].style = event.style;
                    $eventGroupState[$projectState.selected].events[eventIndex].forced = event.forced;
                } else {
                    if ($eventGroupState[$projectState.selected].events.length > index+1){
                        $eventGroupState[$projectState.selected].events.splice(index,0, 
                        {
                            ...(JSON.parse(JSON.stringify(event))),
                            text : "",
                            original : event.text
                        });

                        $eventGroupState[$projectState.selected].events = $eventGroupState[$projectState.selected].events;
                    } else {
                        $eventGroupState[$projectState.selected].events = [
                            ...$eventGroupState[$projectState.selected].events,
                            {
                                ...(JSON.parse(JSON.stringify(event))),
                                text : "",
                                original : event.text
                            }
                        ];
                    }                    
                }
            });
        }

        toast.push(`Changes synced successfully.`, {classes: ['toast-success']});

        historyState.insert({
            name: "sync event group", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

        modalState.hideModal();
        
    } catch(err){
        console.log(err, err.message);
        progress = 100;
        statusMsg = `Error: ${err.message}`;

        toast.push(`There was an error syncing changes. ${err.message}`, {classes: ['toast-danger']});

        modalState.hideModal();
    }
});
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'syncChanges' ? 'show d-block' : ''}" 
    role="dialog"
    aria-labelledby="syncChangesTitle"
    aria-modal="true" 
    tabindex="-1" 
    id="SyncChanges">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="syncChangesTitle">Sync Changes</h4>
            </div>
            <div class="modal-body">
                <p role="alert"><i class="bi bi-exclamation-diamond-fill"></i> Please leave this window open until syncing completes</p>
                <div class="progress" role="progressbar" aria-valuenow="{progress}" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" 
                        style="width: {progress}%;" 
                        aria-label="Sync progress">
                        {progress}%
                    </div>
                </div>
                <p class="text-center text-muted small" role="status" aria-live="polite">{statusMsg}</p>
            </div>
        </div>
    </div>
</div>
