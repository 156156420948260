<script>
import {
    historyState
} from '@app/store/historyStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

function autoFormatEvents() {
    try {
        $eventGroupState[$projectState.selected].selected.forEach((eventIndex, count) => {
            let paragraph = [],
                sentense = "",
                words = $eventGroupState[$projectState.selected].events[eventIndex].text.replace(/(<([^>]+)>)/gi, " ").split(" ").filter(word => {
                    return word !== ""
                });

            let avgChars = Math.round(words.join(" ").length / words.length);
            let maxWordsPerLine = Math.round(32 / avgChars);

            while (words.length > 0) {
                sentense += words.shift();
                if (words.length > 0 && sentense.length + words[0].length + 1 > 32) {
                    paragraph.push(sentense);
                    sentense = "";
                } else if (sentense.trim().split(" ").length + 1 > maxWordsPerLine) {
                    paragraph.push(sentense);
                    sentense = "";
                } else {
                    sentense += " ";
                }
            }
            /* Last Sentense */
            sentense.length > 0 ? paragraph.push(sentense) : null;

            $eventGroupState[$projectState.selected].events[eventIndex].text = "<p>" + paragraph.join("</p><p>") + "</p>";
            if (count === 0) {
                quillEditor.clipboard.dangerouslyPasteHTML($eventGroupState[$projectState.selected].events[eventIndex].text);
                quillEditor.setSelection(0, quillEditor.getLength());
                quillEditor.format('align', 'center');
                quillEditor.focus();
            }
        });

        historyState.insert({
            name: "auto-format", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally{
        dispatch("done");
    }
}
</script>

<a class="dropdown-item" href="#!/" on:click={autoFormatEvents}>Auto Insert Line Breaks</a>
