<script>
	import { createEventDispatcher, onMount } from "svelte";
	export let currentState;
	const dispatch = createEventDispatcher();
    let theme = 'dark';
	function toggleMenu() {
		dispatch("toggleMenu", {
			menu: "theme",
		});
	}

    onMount(async () => {
		setTheme(getPreferredTheme());
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            const storedTheme = getStoredTheme();
            if (storedTheme !== 'light' && storedTheme !== 'dark') {
                setTheme(getPreferredTheme());
            }
        });
	});

	const getStoredTheme = () => localStorage.getItem("cc-theme");
	const setStoredTheme = (theme) => localStorage.setItem("cc-theme", theme);
	const getPreferredTheme = () => {
        //console.log(`getting preferred theme`);
		const storedTheme = getStoredTheme();
		if (storedTheme) {
			return storedTheme;
		}

        return "dark";
	};

	const setTheme = (newTheme) => {
		if (newTheme === "auto" && window.matchMedia("(prefers-color-scheme: dark)").matches) {
			document.documentElement.setAttribute("data-bs-theme", "dark");
		} else {
			document.documentElement.setAttribute("data-bs-theme", newTheme);
		}
        theme = newTheme;
        setStoredTheme(newTheme);
	};    
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}" role="menuitem">
	<a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/" id="themeMenu" aria-haspopup="true" aria-expanded={currentState} aria-label="Theme Selection"><i class="bi bi-sun-fill" aria-hidden="true" /></a>
	<div class="dropdown-menu {currentState ? 'show' : ''}" aria-labelledby="themeMenu" role="menu">
		<a class="dropdown-item {theme === 'light' ? 'active' : ''}" href="#!/" on:click={() => setTheme('light')} role="menuitem" id="lightThemeBtn" aria-checked={theme === 'light'}><i class="bi bi-sun-fill" aria-hidden="true" /> Light</a>
		<a class="dropdown-item {theme === 'dark' ? 'active' : ''}" href="#!/" on:click={() => setTheme('dark')} role="menuitem" id="darkThemeBtn" aria-checked={theme === 'dark'}><i class="bi bi-moon-stars-fill" aria-hidden="true" /> Dark</a>
		<a class="dropdown-item {theme === 'auto' ? 'active' : ''}" href="#!/" on:click={() => setTheme('auto')} role="menuitem" id="autoThemeBtn" aria-checked={theme === 'auto'}><i class="bi bi-circle-half" aria-hidden="true" /> Auto</a>
	</div>
</li>
