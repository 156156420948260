<script>
    import { onMount } from "svelte";
    import { onDestroy } from "svelte";
    import { authState } from "@app/store/authStore.js";
    import { externalDataState } from "@app/store/externalDataStore.js";
    import { environment } from "@app/store/envStore.js";
    import { uiState } from "@app/store/uiStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { issueState } from  "@app/store/issueStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import _EventGroup from "@app/external/cc-lib/dist/classes/eventGroup.js";
    import _Project from "@app/external/cc-lib/dist/classes/project.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { v4 as uuidv4 } from "uuid";
    import { fade } from "svelte/transition";
    import { Circle, BarLoader } from "svelte-loading-spinners";
    import { toast } from "@zerodevx/svelte-toast";
    import insertEvent from "@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js";
    import refreshId from "@app/external/cc-lib/dist/functions/utility/refreshId.js";
    import tcLib from "@app/external/cc-lib/dist/lib/timecode.js";
    import frameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    /* Firebase */
    import firebase from "@app/configs/firebase.js";
    import db from "@app/configs/firestore.js";
    import storage from "@app/configs/storage.js";
    import "firebase/functions";

    /* 
Data Structures:
    Folder Record
        {
            id : "262-626-426-262462",
            name : "John",
            type : "folder",
            createdOn : firebase.firestore.Timestamp.fromDate(new Date("December 10, 1815")),
            updatedOn : firebase.firestore.Timestamp.fromDate(new Date("December 10, 1815")),
            locationId : null,
            star : false,
        }

*/

    const uid = uuidv4();
    let records = [],
        selectedRecord,
        statusMsg = "",
        rootDir = "personal",
        userId = firebase.auth().currentUser.uid,
        email = firebase.auth().currentUser.email,
        teamId = $authState.team ? $authState.team.id : null,
        homeRef = db.collection("users").doc(userId).collection("storage"),
        teamRef = teamId
            ? db.collection("teams").doc(teamId).collection("storage")
            : null,
        storageRef = storage.ref(),
        myDriveUploadBasePath = "users/" + userId + "/projects/",
        teamDriveUploadBasePath = "teams/" + teamId + "/storage/",
        gettingRecords,
        importing = false,
        ffmpegRes,
        audioExtractionComplete = false,
        manifestFileExists = false,
        audioExtractionWorker,
        proxyRtWorker,
        ffmpegPath,
        videoOutputPath,
        audioOutputPath;

    
    onMount(() => {
        gettingRecords = getRecords();
    });

    onDestroy(() => {
        if (audioExtractionWorker) {
            audioExtractionWorker.terminate();
        }
    });

    function closeModal() {
        console.log(
            "Close Modal Called",
            audioExtractionComplete,
            manifestFileExists,
        );

        if (audioExtractionComplete && manifestFileExists) {
            statusMsg = "Project import complete...";

            setTimeout(() => {
                $uiState.timeline = true;
                modalState.hideModal();
            }, 1000);
        }
    }

    function checkFileExistsSync(filePath) {
        try {
            fsSync.accessSync(filePath);
            return true;
        } catch (error) {
            return false;
        }
    }

    function selectRecord(record) {
        selectedRecord = record;
    }

    function getRecords() {
        /* Retried the most recent 25 projects */
        selectedRecord = undefined;
        records = [];

        if (!teamId && rootDir === "team") {
            teamId = $authState.team ? $authState.team.id : null;
            teamRef = teamId
                ? db.collection("teams").doc(teamId).collection("storage")
                : null;
            teamDriveUploadBasePath = "teams/" + teamId + "/storage/";
        }

        return (rootDir === "team" ? teamRef : homeRef)
            .where("type", "in", ["project", "team project", "work order"])
            .orderBy("updatedOn", "desc")
            .limit(25)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log(doc.data());
                    // console.log(doc.id, " => ", doc.data());
                    records = [...records, doc.data()];
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                toast.push(`Error Getting Folder Contents: ${error.message}`, {
                    classes: ["toast-danger"],
                });
            });
    }

    function openProject() {
        if (importing) {
            return;
        }

        try {
            //console.log("OPENING PROJECT");
            toast.push(`Loading project in progress...`, {
                classes: ["toast-info"],
            });
            importing = true;

            let projectFilePath =
                rootDir === "team"
                    ? teamDriveUploadBasePath + selectedRecord.id
                    : myDriveUploadBasePath + selectedRecord.id;

            (rootDir === "team"
                ? firebase.functions().httpsCallable("v8GetDownloadLink")(
                      projectFilePath,
                  )
                : storageRef.child(projectFilePath).getDownloadURL()
            )
                .then((url) => {
                    if (rootDir === "team") {
                        return fetch(url.data);
                    } else {
                        return fetch(url);
                    }
                })
                .then((res) => {
                    return res.json();
                })
                .then((resJson) => {
                    console.log(resJson);
                    console.log(selectedRecord);
                    //console.log("PROJECT JSON DOWNLOADED. Creating Project");
                    if (selectedRecord.type === "work order") {
                        resJson.projectName = selectedRecord.name;
                        $externalDataState = {
                            platform: "Work Order",
                            status : "importing",
                            rawDescription: JSON.stringify(resJson),
                        };

                        modalState.showModal("workOrderImport");
                        return;
                    } else {
                        $externalDataState = {};
                    }

                    $eventGroupState = [];
                    $metadataState = [];
                    $speakerState = [];
                    $issueState = [];
                    $markerState = {
                        selected: 0,
                        lists: [
                            {
                                id: "0",
                                name: "Shot Changes",
                                color: "#E74C3C",
                                markers: [],
                            },
                        ],
                    };
                    $projectState = new _Project({
                        ...resJson,
                        ...{
                            eventGroups: [],
                            metadata: [],
                            speakers: [],
                            folderId: selectedRecord.locationId,
                            rootDir: rootDir,
                        },
                    });

                    $projectState = refreshId($projectState, selectedRecord.id);
                    //console.log("Importing Event Groups and Events...");
                    resJson.eventGroups.forEach(
                        (eventGroup, eventGroupIndex) => {
                            $eventGroupState = [
                                ...$eventGroupState,
                                new _EventGroup({
                                    ...eventGroup,
                                    events: [],
                                }),
                            ];

                            $eventGroupState[$eventGroupState.length - 1].id =
                                eventGroup.id;
                            $eventGroupState[
                                $eventGroupState.length - 1
                            ].extId = eventGroup.extId;

                            eventGroup.events.forEach((event) => {
                                $eventGroupState[eventGroupIndex] = insertEvent(
                                    $eventGroupState[eventGroupIndex],
                                    event,
                                );
                                $eventGroupState[eventGroupIndex].events[
                                    $eventGroupState[eventGroupIndex].events
                                        .length - 1
                                ].id = event.id;
                            });
                        },
                    );

                    resJson.metadata.forEach((metadataInfo) => {
                        $metadataState = [...$metadataState, metadataInfo];
                    });

                    resJson.speakers.forEach((speakerInfo) => {
                        $speakerState = [...$speakerState, speakerInfo];
                    });

                    if(resJson.issues && resJson.issues > 0){
                        resJson.issues.forEach((issue) => {
                        $issueState = [...$issueState, issue];
                        });
                    }

                    $markerState = resJson.markers || {
                        selected: 0,
                        lists: [
                            {
                                id: "0",
                                name: "Shot Changes",
                                color: "#E74C3C",
                                markers: [],
                            },
                        ],
                    };

                    for (const [key, value] of Object.entries(resJson.style)) {
                        $styleState[key] = value;
                    }

                    historyState.reset();
                    historyState.insert({
                        name: "import project", //action name
                        eventGroup: false,
                        snapshots: [
                            {
                                store: "eventGroupState",
                                value: JSON.stringify($eventGroupState),
                            },
                        ],
                    });

                    if (
                        ((resJson.media.storage === "Local Storage" ||
                            resJson.media.storage === "Proxy RT") &&
                            !$environment.electron) ||
                        resJson.media.localPath === ""
                    ) {
                        modalState.showModal("mediaImport");
                    } else {
                        importMedia();
                    }
                })
                .catch((error) => {
                    //console.log(error, error.message);
                    importing = false;
                    toast.push(`Failed to Load Project: ${error.message}`, {
                        classes: ["toast-danger"],
                    });
                });
        } catch (error) {
            console.log(error);
            importing = false;
        }
    }

    async function importMedia() {
        try {
            playerState.updateDuration(false);
            $uiState.timeline = false;
            let mediaType = "video/mp4"; 
            switch ($projectState.media.storage) {
                case "Vimeo":
                    player.src = $projectState.media.path;
                    manifestFileExists = true;
                    audioExtractionComplete = true;

                    break;
                case "YouTube":
                    player.src = $projectState.media.path;
                    manifestFileExists = true;
                    audioExtractionComplete = true;

                    break;
                case "HLS Manifest":
                    audioExtractionComplete = true;
                    player.src = $projectState.media.path;

                    break;
                case "Proxy RT":
                    if (!checkFileExistsSync($projectState.media.localPath)){
                        toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                        modalState.showModal('mediaImport');
                        return;
                    }

                    ffmpegPath = require("ffmpeg-static-electron").path;

                    videoOutputPath = os.tmpdir() + path.sep + uid + ".m3u8";
                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    $projectState.media.path = videoOutputPath;

                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);
                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };

                    audioExtractionWorker = new Worker(
                        "./build/workers/audioExtraction.js",
                    );
                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                    });

                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            console.log(msg.data.result);
                        } else {
                            $projectState.media.peaksPath = msg.data.error
                                ? ""
                                : audioOutputPath;

                            $projectState.media.useFallback = false;

                            toast.push(
                                `${msg.data.error ? msg.data.error : "Audio extraction completed successfully."}`,
                                {
                                    classes: [
                                        'toast-`${msg.data.error ? "danger" : "success"}`,',
                                    ],
                                },
                            );

                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };

                    proxyRtWorker = new Worker("./build/workers/proxyRt.js");
                    proxyRtWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: videoOutputPath,
                        ffmpegPath: ffmpegPath,
                        tmpDir: os.tmpdir(),
                        pathSep: path.sep,
                    });

                    proxyRtWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status_msg) {
                            manifestFileExists = true;
                            player.src = $projectState.media.path;
                            closeModal();
                        } else if (msg.data.error) {
                            manifestFileExists = true;
                            toast.push(`${msg.data.error}`, {
                                classes: ["toast-danger"],
                            });
                            closeModal();
                        }
                    };

                    break;
                case "Cloud Storage":
                    /* Cloud Storage*/          
                    if ($projectState.media.type === "video/quicktime") {
                        mediaType = "video/mp4";
                    } else if ($projectState.media.type === "video/x-matroska") {
                        mediaType = "video/mkv";
                    } else {
                        mediaType = $projectState.media.type;
                    }

                    //Get file size of url
                    let fileSize = await getFileSize($projectState.media.path);
                    if (fileSize) {
                        const sizeInBytes = parseInt(fileSize);
                        const sizeInKilobytes = sizeInBytes / 1024;
                        const sizeInMegabytes = sizeInKilobytes / 1024;
                        if (sizeInMegabytes < 512) {
                            $projectState.media.useFallback = false;
                        }
                    }

                    player.src = {src: $projectState.media.path, type: mediaType};

                    /* Update Flags */
                    manifestFileExists = true;
                    audioExtractionComplete = true;

                    break;
                default:
                    /* Local Storage */
                    if (!checkFileExistsSync($projectState.media.localPath)){
                        toast.push("Media file not found. Please re-import media.", {classes: ['toast-danger']});
                        modalState.showModal('mediaImport');
                        return;
                    }

                    audioOutputPath = os.tmpdir() + path.sep + uid + ".json";
                    ffmpegPath = require("ffmpeg-static-electron").path;

                    manifestFileExists = true;
                    mediaType = $projectState.media.type === "video/quicktime" ? "video/mp4" : $projectState.media.type;
                    player.src = { src: $projectState.media.localPath, type: mediaType};  

                    ffmpegRes = await getFileMetadata($projectState.media.localPath, ffmpegPath);

                    $projectState.media.info = {
                        incode: getMediaIncode(ffmpegRes),
                        frameRate: getMediaFrameRate(ffmpegRes),
                        duration : getMediaDuration(ffmpegRes)
                    };
                    
                    audioExtractionWorker = new Worker(
                        "./build/workers/audioExtraction.js",
                    );

                    audioExtractionWorker.postMessage({
                        inputPath: $projectState.media.localPath,
                        outputPath: audioOutputPath,
                        ffmpegPath: ffmpegPath,
                        duration: $projectState.media.info.duration,
                    });

                    audioExtractionWorker.onmessage = (msg) => {
                        console.log(msg);
                        if (msg.data.status === "in_progress") {
                            statusMsg = msg.data.result;
                        } else {
                            $projectState.media.peaksPath = msg.data.error
                                ? ""
                                : audioOutputPath;
                            $projectState.media.useFallback = false;

                            toast.push(
                                `${msg.data.error ? msg.data.error : "Media import completed successfully."}`,
                                {
                                    classes: [
                                        `toast-${msg.data.error ? "danger" : "success"}`,
                                    ],
                                },
                            );

                            audioExtractionComplete = true;
                            closeModal();
                        }
                    };
            }

            closeModal();
        } catch (err) {
            console.log(err);
            console.log(err.message);
            toast.push("Error - Media import failed", {
                classes: ["toast-danger"],
            });

            importing = false;
        }
    }

    function updateRootDir(dir) {
        rootDir = dir;
        gettingRecords = getRecords(true);
    }

    async function getFileMetadata(filePath, ffmpegPath) {
        try {
            const exec = window.exec;
            return await exec(`"${ffmpegPath.replace('app.asar', 'app.asar.unpacked')}" -y -i "${filePath}"`);
        } catch (err) {
            return err.message;
        }
    }
    
    function getMediaIncode(ffmpegOutput) {
        let matches = ffmpegOutput.match(/(?<=timecode\s+:\s+)\d\d:\d\d:\d\d(:|;)\d\d/);
        let mediaIncode = matches ? matches[0].replace(";", ":") : false;
    
        console.log("Media incode detected: " + mediaIncode);
        return mediaIncode == "null" ? false : mediaIncode;
    }
    
    function getMediaFrameRate(ffmpegOutput) {
        // console.log("GETTING MEDIA FRAME RATE");
        //console.log("FFMPEG OUTPUT:");
        //console.log(ffmpegOutput);
    
        let matches = ffmpegOutput.match(/\d+\.\d+(?= fps)|\d+(?= fps)/);
        let mediaFrameRate = matches ? matches[0] : false;
    
        console.log("Media frame rate detected: " + mediaFrameRate);
        let fr = frameRates.frameRateMapping[mediaFrameRate];
        return fr;
    }

    function getMediaDuration(ffmpegOutput){
       /*  console.log("GETTING MEDIA DURATION");
        console.log("FFMPEG OUTPUT:");
        console.log(ffmpegOutput); */
        let matches = ffmpegOutput.match(/(?<=Duration:\s)\d\d:\d\d:\d\d\.\d\d/);
        let mediaDuration = matches ? matches[0] : false;

        console.log("Media duration detected: " + mediaDuration);
        if (mediaDuration){
            mediaDuration = tcLib.tcMsToSec(mediaDuration);
            console.log("media duration in seconds: " + mediaDuration);
        }

        return mediaDuration;
    }

    async function getFileSize() {
        try {
            const response = await fetch($projectState.media.path, {
                method: "HEAD",
            });
            const fileSize = response.headers.get("content-length");
            return fileSize;
        } catch (err){
            return false;
        }    
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'recentProjects' ? 'show d-block' : ''}"
    role="dialog"
    aria-labelledby="recentProjectsTitle"
    aria-describedby="recentProjectsDesc"
    tabindex="-1"
>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-capitalize" id="recentProjectsTitle">Recent Projects (Legacy)</h4>
                <button
                    type="button"
                    class="btn-close"
                    id="recentProjectsCloseBtn"
                    aria-label="Close modal"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <p id="recentProjectsDesc" class="sr-only">Select a project from your recent projects list</p>
                <div class="row">
                    <div class="col-2 p-4">
                        <h5><i class="bi bi-hdd" aria-hidden="true"></i> Storage</h5>
                        <hr />
                        <nav aria-label="Storage locations">
                            <ul class="nav nav-pills flex-column">
                                <li class="nav-item">
                                    <a
                                        class="nav-link {rootDir === 'personal' ? 'active' : ''}"
                                        href="#!/"
                                        on:click={() => updateRootDir("personal")}
                                        aria-current={rootDir === 'personal' ? 'page' : undefined}
                                        >My Drive</a
                                    >
                                </li>
                                {#if $authState.team}
                                    <li class="nav-item">
                                        <a
                                            class="nav-link {rootDir === 'team'
                                                ? 'active'
                                                : ''}"
                                            href="#!/"
                                            on:click={() => updateRootDir("team")}
                                            aria-current={rootDir === 'team' ? 'page' : undefined}
                                            >Team Drive</a
                                        >
                                    </li>
                                {/if}
                            </ul>
                        </nav>
                        {#if selectedRecord}
                            <hr />
                            <div aria-live="polite">
                                <p class="fw-bold">
                                    <i class="bi {selectedRecord.type === 'project' ? 'bi-file-earmark-play-fill text-info' : ''} 
                                        {selectedRecord.type === 'team project' ? 'bi-people-fill text-info' : ''} 
                                        {selectedRecord.type === 'work order' ? 'bi-clipboard-check-fill text-info' : ''}"
                                        aria-hidden="true"></i>
                                    {selectedRecord.name}
                                </p>
                                <p class="text-muted mb-1 text-truncate">
                                    Owner: {selectedRecord.owner ?? email}
                                </p>
                                <p class="text-muted mb-1">
                                    Created On: {selectedRecord.createdOn
                                        .toDate()
                                        .toLocaleDateString()}
                                </p>
                                <p class="text-muted mb-1">
                                    Last Updated: {selectedRecord.updatedOn
                                        .toDate()
                                        .toLocaleDateString()}
                                </p>
                            </div>
                        {/if}
                    </div>
                    <div class="col">
                        <div class="m-3 rounded-lg shadow border border-secondary">
                            <div class="table-responsive">
                                <table class="table table-hover table-light" aria-label="Recent projects list">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Name</th>
                                            {#if rootDir === "team"}
                                                <th>Owner</th>
                                            {/if}

                                            <th>Created On</th>
                                            <th>Last Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {#await gettingRecords}
                                            <div style="margin: 5% 35%" aria-live="polite">
                                                <BarLoader
                                                    size="160"
                                                    color="#1eb4b2"
                                                    unit="px"
                                                    duration="3s"
                                                    aria-label="Loading projects"
                                                ></BarLoader>
                                            </div>
                                        {:then}
                                            {#each records as record (record.id)}
                                                <tr
                                                    transition:fade={{ duration: 100 }}
                                                    on:dblclick={openProject}
                                                    on:click={selectRecord(record)}
                                                    class={selectedRecord && record.id === selectedRecord.id ? "table-warning" : ""}
                                                    aria-selected={selectedRecord && record.id === selectedRecord.id}
                                                >
                                                    <td
                                                        class="text-truncate"
                                                        title={record.name}
                                                        ><i
                                                            class="bi {record.type ===
                                                            'project'
                                                                ? 'bi-file-earmark-play-fill'
                                                                : record.type === 'work order' ? 'bi-clipboard-check-fill'  : 'bi-people-fill'} text-info"
                                                            aria-hidden="true"
                                                        ></i>
                                                        {record.name}
                                                        {#if record.star}
                                                            <i
                                                                class="bi bi-star-fill text-muted"
                                                                aria-hidden="true"
                                                            ></i>
                                                        {/if}
                                                    </td>
                                                    {#if rootDir === "team"}
                                                        <td
                                                            class="text-truncate"
                                                            title={record.owner ??
                                                                ""}
                                                            >{record.owner ??
                                                                ""}</td
                                                        >
                                                    {/if}
                                                    <td
                                                        class="text-truncate"
                                                        title={record.createdOn.toDate()}
                                                        >{record.createdOn.toDate()}</td
                                                    >
                                                    <td
                                                        class="text-truncate"
                                                        title={record.updatedOn.toDate()}
                                                        >{record.updatedOn.toDate()}</td
                                                    >
                                                </tr>
                                            {/each}
                                        {/await}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                {#if importing}
                    <p class="text-muted small" aria-live="polite">Loading media | {statusMsg}</p>
                    <Circle size="30" color="#1eb4b2" unit="px" duration="1s"></Circle>
                {/if}
                <button
                    type="button"
                    class="btn btn-primary"
                    id="openProjectBtn"
                    disabled={importing || !selectedRecord}
                    on:click={openProject}
                    aria-label="Open selected project"
                ><i class="bi bi-download" aria-hidden="true"></i> Open Project</button>
            </div>
        </div>
    </div>
</div>

<style>
    table {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 60vh;
    }

    thead {
        flex: 0 0 auto;
    }

    tbody {
        flex: 1 1 auto;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
    }

    tr {
        width: 100%;
        display: table;
        table-layout: fixed;
    }
</style>
