<script>
import {
    environment
} from '@app/store/envStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    explorerContextState
} from '@app/store/explorerContextStore.js';
import {
    fade
} from 'svelte/transition';
/* Firebase */
import db from '@app/configs/firestore.js';

let updateAvailable = false;

if ($environment.online) {
    db.collection("system").doc("creator").get().then(doc => {
        if (doc.exists) {
            let data = doc.data();
            updateAvailable = data.version !== $environment.version;
        }
    });
}

function openProjectModal() {
    if ($environment.online){
        $explorerContextState = "load";
        modalState.showModal("storageExplorer");
    } else {
        modalState.showModal("projectImport");
    }    
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'welcome' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="WelcomeModal">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title"><i class="bi bi-star-fill"></i> Welcome</h3>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal} />
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="p-4 {$environment.online ? 'col-3' : 'col-6'}">
                            <button on:click={() => modalState.showModal("newProject")} type="button" class="btn btn-light btn-lg w-100 p-4  welcome-option border-0 shadow-sm">
                                <i class="bi bi-file-earmark-plus option-icon"></i><hr/>
                                <h4 class="mt-2">New Project</h4>
                            </button>
                        </div>
                        <div class="p-4 {$environment.online ? 'col-3' : 'col-6'}">
                            <button on:click={openProjectModal} type="button" class="btn btn-light btn-lg w-100 p-4  welcome-option border-0 shadow-sm" >
                                <i class="bi bi-folder2 option-icon"></i><hr/>
                                <h4 class="mt-2">Open Project</h4>
                            </button>
                        </div>
                        {#if $environment.online}
                            <div class="col-3 p-4">
                                <button on:click={() => modalState.showModal("recentProjects")} type="button" class="btn btn-light btn-lg w-100 p-4  welcome-option border-0 shadow-sm" >
                                    <i class="bi bi-clock-history option-icon"></i><hr/>
                                    <h4 class="mt-2">Recent Projects</h4>
                                </button>
                            </div>
                            <div class="col-3 p-4">
                                <button on:click={() => modalState.showModal("teamProjectImport")} type="button" class="btn btn-light btn-lg w-100 p-4  welcome-option border-0 shadow-sm" >
                                    <i class="bi bi-people option-icon"></i><hr/>
                                    <h4 class="mt-2">Team Project</h4>
                                </button>
                            </div>
                            <div class="col-3 p-4">
                                <a href="https://docs.google.com/document/d/e/2PACX-1vQjCdy__j5AOQKbQpyoNTRtF28BIhRgkYhWB-3tLZ10bQhWebqR9CH2-Jsd9qCGLp5wa2U89wu9vQFf/pub" target="_blank" rel="noreferrer" class="btn btn-light btn-lg w-100 p-4 welcome-option border-0 shadow-sm" >
                                    <i class="bi bi-journals option-icon"></i><hr/>
                                    <h4 class="mt-2">User Guide</h4>
                                </a>
                            </div>
                            <div class="col-3 p-4">
                                <a href="https://www.closedcaptioncreator.com/videos.html" target="_blank" rel="noreferrer" class="btn btn-light btn-lg w-100 p-4 welcome-option border-0 shadow-sm" >
                                    <i class="bi bi-film option-icon"></i><hr/>
                                    <h4 class="mt-2">Tutorial Videos</h4>
                                </a>
                            </div>
                            <div class="col-3 p-4">
                                <a href="https://www.closedcaptioncreator.com/support.html" target="_blank" rel="noreferrer" class="btn btn-light btn-lg w-100 p-4 welcome-option border-0 shadow-sm" >
                                    <i class="bi bi-life-preserver option-icon"></i><hr/>
                                    <h4 class="mt-2">Contact Support</h4>
                                </a>
                            </div>
                            <div class="col-3 p-4">
                                <a href="https://closedcaptioncreator.chargebeeportal.com/portal/v2/login" target="_blank" rel="noreferrer" class="btn btn-light btn-lg w-100 p-4 welcome-option border-0 shadow-sm" >
                                    <i class="bi bi-award option-icon"></i><hr/>
                                    <h4 class="mt-2">Manage Account</h4>
                                </a>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <p>Version {$environment.version}</p>
                {#if updateAvailable}
                <a id="updateButton" href="https://www.closedcaptioncreator.com/downloads.html" target="_blank" rel="noreferrer" class="btn btn-warning fw-bold text-dark" title="Download Now"><i class="bi bi-stars"></i> Update Available <i class="bi bi-stars"></i></a>
                {/if}
            </div>
        </div>
    </div>
</div>

 <style>
.welcome-option:hover {
    background-color: var(--bs-primary);
    color: var(--bs-light);
}

.option-icon {
    margin-top: 6rem;
    font-size: 5vw;
}
</style>
