import _pacFunc from "../functions/profiles/ssPoliscript.js";
import _convertToPlainText from "../functions/quill/convertToPlainText.js";
import _fixEnglishTextInRTLLanguage from "../functions/quill/fixEnglishTextInRTLLanguage.js";
import _convertToHtml from "../functions/quill/convertToHtml.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _tcLib from "../lib/timecode.js";
import _Event from "../classes/event.js";
const Event = _Event;
const tcLib = _tcLib;
const getFormatOptions = _getFormatOptions;
const convertToHtml = _convertToHtml;
const fixEnglishTextInRTLLanguage = _fixEnglishTextInRTLLanguage;
const convertToPlainText = _convertToPlainText;
const pacFunc = _pacFunc;
export default {
  decode: function (input, options) {
    /* Code Page Decode */
    /* pacFunc.codePages.forEach(codePage =>{
        console.log(codePage + " --> " +pacFunc.decodeChar("87", codePage));
    }); */

    let events = [],
      tcFlag = false,
      subtitleFlag = false,
      w16Flag = false,
      seperator = "fe",
      codePage = 28591,
      italicFlag = false,
      ccEvent,
      incodeHex,
      outcodeHex,
      decodingOptions = getFormatOptions(options.formatOptions);
    if (decodingOptions["Code Page"]) {
      codePage = pacFunc.codePageMapping[decodingOptions["Code Page"]];
    }

    //console.log(codePage);

    let pacLine = [],
      pacLines = [],
      w16MarkerIndex,
      tcMarkerIndex;
    input.match(/(..?)/g).forEach((hexCode, hexIndex, hexCodes) => {
      if (hexCode === seperator) {
        /* Check if the seperator is part of a tc block */
        tcMarkerIndex = pacLine.findIndex(pacCode => {
          return pacCode === "60";
        });
        w16MarkerIndex = pacLine.join("").indexOf("1f5731362e");
        if (tcMarkerIndex > -1 && !pacLine[tcMarkerIndex + 8]) {
          /* This is part of a TC block */
          pacLine.push(hexCode);
        } else if (w16MarkerIndex > -1 && pacLine.length % 2 === 0) {
          // console.log(pacLine.join(""), "W16 Marker: ", w16MarkerIndex, pacLine.length);
          /* This is part of a Chinese character */
          pacLine.push(hexCode);
        } else {
          /* This is not part of a TC block and is used to separate a line */
          pacLines.push(pacLine.join(""));
          pacLine = [];
        }
      } else {
        pacLine.push(hexCode);
      }
    });

    /* push last pac Line */
    if (pacLine.length > 0) {
      pacLines.push(pacLine.join(""));
    }

    // console.log(pacLines);
    pacLines.forEach(function (pacLine) {
      // console.log("----------");
      if (!pacLine) {
        return;
      }
      // console.log(pacLine);
      italicFlag = false;
      if (pacLine.includes("1f5731362e")) {
        w16Flag = true;
        pacLine = pacLine.replace("1f5731362e", "");
      } else {
        w16Flag = false;
      }
      pacLine = pacLine.match(/(..?)/g);
      // console.log(pacLine);

      /* Debug Logger: */
      //console.log(pacLine.join(", ") + "\n");
      ccEvent ? ccEvent.text += "\n" : "";

      /* Alignment Start*/
      if (ccEvent && pacLine[0] === "01") {
        ccEvent.alignment = "left";
        ccEvent.xPos = "start";
        ccEvent.xOffset = options.window.width * 0.10;
      } else if (ccEvent && pacLine[0] === "02") {
        ccEvent.alignment = "center";
      } else if (ccEvent && pacLine[0] === "00") {
        ccEvent.alignment = "right";
        ccEvent.xPos = "end";
        ccEvent.xOffset = options.window.width * -0.10;
      }

      /* Alignment End */
      while (pacLine.length > 0) {
        let char = pacLine.shift();
        if (char === "60" && pacLine[9] === "00" && pacLine.length < 15) {
          tcFlag = true;
          subtitleFlag = true;
          if (ccEvent) {
            //console.log(ccEvent.text);
            if (codePage === 28596 || codePage === 58598) {
              ccEvent.text = fixEnglishTextInRTLLanguage(ccEvent.text);
            }
            ccEvent.text = convertToHtml(ccEvent.text);
            events.push(ccEvent);
            //console.log("-------------");
          }
          ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
          });
          italicFlag = false;
          incodeHex = pacLine.splice(0, 4);
          outcodeHex = pacLine.splice(0, 4);

          /* Timecode Debug */
          // console.log(incodeHex + "-->" + pacFunc.decodeTc(incodeHex));
          // console.log(outcodeHex + "-->" + pacFunc.decodeTc(outcodeHex));

          ccEvent.start = tcLib.tcToSec(pacFunc.decodeTc(incodeHex), options.frameRate);
          ccEvent.end = tcLib.tcToSec(pacFunc.decodeTc(outcodeHex), options.frameRate);

          /* Vertical Position Start */
          if (pacLine[2]) {
            let vertPosValue = parseInt(pacLine[2], 16);
            //console.log(`Vertical position is ${vertPosValue}`);
            if (vertPosValue < 5) {
              ccEvent.yPos = "start";
              ccEvent.yOffset = options.window.height * 0.10 + (vertPosValue - 1) / 11 * (0.8 * options.window.height);
            } else if (vertPosValue >= 5 && vertPosValue <= 6) {
              ccEvent.yPos = "center";
              ccEvent.yOffset = 0;
            } else {
              ccEvent.yPos = "end";
              ccEvent.yOffset = options.window.height * -0.10 - (11 - vertPosValue) / 11 * (0.8 * options.window.height);
            }
          }
          /* Vertical Position End */

          /* remove end parts */
          //console.log(pacLine);
          pacLine = [];
        } else if (tcFlag) {
          if (parseInt(char, 16) >= 240 && parseInt(char, 16) <= 255) {
            /* control codes */
            //console.log(`CONTROL CODE: ${char}`);
          } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
            //console.log("Garbage: " + char);
            if (tcFlag && char === "00" && ccEvent.text.trim() && pacLine[0]) {
              let subCounter = (events.length + 1) % 256;
              /* console.log(                                
                  subCounter,
                  parseInt(pacLine[0], 16), 
                  pacLine[0],                                
                  events.length+1,  
                  ccEvent.text, 
                  subtitleFlag,
                  pacFunc.decodeChar(pacLine[0], codePage),
                  "\n"
              ); */

              if (parseInt(pacLine[0], 16) === subCounter || parseInt(pacLine[0], 16) === subCounter + 1) {
                subtitleFlag = false;
              }
            }
          } else if (subtitleFlag) {
            if (parseInt(char, 16) === 128 && codePage === 28591) {
              ccEvent.text += "#";
            } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) < 160) {
              /* Do Nothing? */
              //console.log(`Do Nothing? ${char}`);
              if (codePage === 28591) {
                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                } else if (pacFunc.specialLatinChars[char]) {
                  ccEvent.text += pacFunc.specialLatinChars[char];
                } else {
                  //console.log(char)
                }
              } else if (codePage === 28592) {
                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                } else if (pacFunc.specialLatinChars[char]) {
                  ccEvent.text += pacFunc.specialLatinChars[char];
                }
              } else if (codePage === 28593) {
                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                } else if (pacFunc.specialLatinChars[char]) {
                  ccEvent.text += pacFunc.specialLatinChars[char];
                }
              } else if (codePage === 28595) {
                if (pacLine[0] && pacFunc.specialCyrillicChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialCyrillicChars[char + pacLine.shift()];
                } else if (pacFunc.specialCyrillicChars[char]) {
                  ccEvent.text += pacFunc.specialCyrillicChars[char];
                }
              } else if (codePage === 28596) {
                if (pacLine[0] && pacFunc.specialArabicChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialArabicChars[char + pacLine.shift()];
                } else if (pacFunc.specialArabicChars[char]) {
                  ccEvent.text += pacFunc.specialArabicChars[char];
                }
              } else if (codePage === 28597) {
                if (pacLine[0] && pacFunc.specialGreekChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialGreekChars[char + pacLine.shift()];
                } else if (pacFunc.specialGreekChars[char]) {
                  ccEvent.text += pacFunc.specialGreekChars[char];
                }
              } else if (codePage === 28598) {
                if (pacLine[0] && pacFunc.specialHebrewChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialHebrewChars[char + pacLine.shift()];
                } else if (pacFunc.specialHebrewChars[char]) {
                  ccEvent.text += pacFunc.specialHebrewChars[char];
                }
              } else if (codePage === 28599) {
                if (pacLine[0] && pacFunc.specialTurkishChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialTurkishChars[char + pacLine.shift()];
                } else if (pacFunc.specialTurkishChars[char]) {
                  ccEvent.text += pacFunc.specialTurkishChars[char];
                }
              }
            } else {
              //console.log(char === "c0" ? char : "");
              if (codePage === 28591 || codePage === 28592 || codePage === 28593 || codePage === 28594) {
                if (w16Flag && pacFunc.decodeChar([char + pacLine[0]], 20936)) {
                  // console.log("W16 FLAG");
                  ccEvent.text += pacFunc.decodeChar(char + pacLine.shift(), 20936);
                } else if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                } else if (pacFunc.specialLatinChars[char]) {
                  ccEvent.text += pacFunc.specialLatinChars[char];
                } else if (char === "3c" || char === "3e") {
                  if (italicFlag) {
                    ccEvent.text += "</em>";
                    italicFlag = false;
                  } else {
                    ccEvent.text += "<em>";
                    italicFlag = true;
                  }
                } else {
                  //console.log(char, pacFunc.decodeChar(char, codePage));
                  //console.log(pacLine);
                  if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00") {
                    pacLine.shift();
                  } else {
                    // console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                    ccEvent.text += pacFunc.decodeChar(char, codePage);
                  }
                }
              } else if (codePage === 28595) {
                if (pacLine[0] && pacFunc.specialCyrillicChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialCyrillicChars[char + pacLine.shift()];
                } else if (pacFunc.specialCyrillicChars[char]) {
                  ccEvent.text += pacFunc.specialCyrillicChars[char];
                } else if (char === "3c" || char === "3e") {
                  if (italicFlag) {
                    ccEvent.text += "</em>";
                    italicFlag = false;
                  } else {
                    ccEvent.text += "<em>";
                    italicFlag = true;
                  }
                } else {
                  //console.log(char, pacFunc.decodeChar(char, codePage));
                  //console.log(pacLine);
                  if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00") {
                    pacLine.shift();
                  } else {
                    //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                    ccEvent.text += pacFunc.decodeChar(char, codePage);
                  }
                }
              } else if (codePage === 28596) {
                if (pacLine[0] && pacFunc.specialArabicChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialArabicChars[char + pacLine.shift()];
                } else if (pacFunc.specialArabicChars[char]) {
                  ccEvent.text += pacFunc.specialArabicChars[char];
                } else if (char === "3c" || char === "3e") {
                  if (italicFlag) {
                    ccEvent.text += "</em>";
                    italicFlag = false;
                  } else {
                    ccEvent.text += "<em>";
                    italicFlag = true;
                  }
                } else {
                  //console.log(char, pacFunc.decodeChar(char, codePage));
                  //console.log(pacLine);
                  if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00") {
                    pacLine.shift();
                  } else {
                    //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                    ccEvent.text += pacFunc.decodeChar(char, codePage);
                  }
                }
              } else if (codePage === 28597) {
                if (pacLine[0] && pacFunc.specialGreekChars[char + pacLine[0]]) {
                  //console.log("DUAL CODE", char, pacLine[0], pacFunc.specialGreekChars[char + pacLine[0]]);
                  ccEvent.text += pacFunc.specialGreekChars[char + pacLine.shift()];
                } else if (pacFunc.specialGreekChars[char]) {
                  //console.log(pacFunc.specialGreekChars[char], char)
                  //console.log("SINGLE CODE", char, pacFunc.specialGreekChars[char])
                  ccEvent.text += pacFunc.specialGreekChars[char];
                } else if (char === "3c" || char === "3e") {
                  if (italicFlag) {
                    ccEvent.text += "</em>";
                    italicFlag = false;
                  } else {
                    ccEvent.text += "<em>";
                    italicFlag = true;
                  }
                } else {
                  //console.log(char, pacFunc.decodeChar(char, codePage));
                  //console.log(pacLine);
                  if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00") {
                    pacLine.shift();
                  } else {
                    //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                    //console.log("DEFAULT CODE", char, pacFunc.decodeChar(char, codePage));
                    ccEvent.text += pacFunc.decodeChar(char, codePage);
                  }
                }
              } else if (codePage === 28598) {
                if (pacLine[0] && pacFunc.specialHebrewChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialHebrewChars[char + pacLine.shift()];
                } else if (pacFunc.specialHebrewChars[char]) {
                  ccEvent.text += pacFunc.specialHebrewChars[char];
                } else if (char === "3c" || char === "3e") {
                  if (italicFlag) {
                    ccEvent.text += "</em>";
                    italicFlag = false;
                  } else {
                    ccEvent.text += "<em>";
                    italicFlag = true;
                  }
                } else {
                  //console.log(char, pacFunc.decodeChar(char, codePage));
                  //console.log(pacLine);
                  if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00") {
                    pacLine.shift();
                  } else {
                    //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                    ccEvent.text += pacFunc.decodeChar(char, codePage);
                  }
                }
              } else if (codePage === 28599) {
                if (pacLine[0] && pacFunc.specialTurkishChars[char + pacLine[0]]) {
                  ccEvent.text += pacFunc.specialTurkishChars[char + pacLine.shift()];
                } else if (pacFunc.specialTurkishChars[char]) {
                  ccEvent.text += pacFunc.specialTurkishChars[char];
                } else if (char === "3c" || char === "3e") {
                  if (italicFlag) {
                    ccEvent.text += "</em>";
                    italicFlag = false;
                  } else {
                    ccEvent.text += "<em>";
                    italicFlag = true;
                  }
                } else {
                  //console.log(char, pacFunc.decodeChar(char, codePage));
                  //console.log(pacLine);
                  if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00") {
                    pacLine.shift();
                  } else {
                    //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                    ccEvent.text += pacFunc.decodeChar(char, codePage);
                  }
                }
              }
            }
          }
        }
      }
    });

    /* Push Last ccEvent */
    if (codePage === 28596 || codePage === 58598) {
      ccEvent.text = fixEnglishTextInRTLLanguage(ccEvent.text);
    }
    ccEvent.text = convertToHtml(ccEvent.text);
    events.push(ccEvent);
    if (decodingOptions["Ignore Metadata"]) {
      try {
        if (tcLib.secToTc(events[0].start, options.frameRate) === "00:00:00:00" && tcLib.secToTc(events[0].end, options.frameRate) === "00:00:00:08") {
          events.shift();
        }
      } catch (e) {
        console.log("Unable to detect metadata in PAC source");
      }
    }
    return events;
  },
  encode: function (eventGroup, options) {
    let output = "",
      codePage = 28591,
      format = "standard",
      pacHeaderOpt = "808080",
      markStartOfUnicode = "1fefbbbf",
      markEndOfUnicode = "2e",
      markReplaceEndOfUnicode = "ff",
      encodingOptions = getFormatOptions(options.formatOptions);
    if (encodingOptions["Code Page"]) {
      codePage = pacFunc.codePageMapping[encodingOptions["Code Page"]];
    }
    ;
    if (encodingOptions["Format"]) {
      format = encodingOptions["Format"].toLowerCase();
    }
    ;
    output += pacFunc.encodeHeader();
    output += `0000000000000800260009808080FE0103FE010343434330333A424C445F434343335F3335FE0103434F50593230323200010060`;
    eventGroup.events.forEach((event, index, events) => {
      let textHexCodes = pacFunc.encodeText(event, format, codePage, markStartOfUnicode, markEndOfUnicode, markReplaceEndOfUnicode);
      output += pacFunc.encodeTc(event.start, options.frameRate);
      output += pacFunc.encodeTc(event.end, options.frameRate);
      output += pacFunc.encodeTextLength(textHexCodes, format, pacHeaderOpt);
      output += "00";
      output += pacFunc.encodeYPos(event);
      output += pacHeaderOpt;
      output += "fe";
      output += pacFunc.encodeAlignment(event);
      output += "03";
      output += textHexCodes;
      if (index < events.length - 1) {
        output += "00";
        output += parseInt((index + 2) % 256).toString(16).padStart(2, "0");
        output += parseInt((index + 2) / 256).toString(16).padStart(2, "0");
        output += "60";
      }
    });
    output += pacFunc.encodeFooter();
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return eventGroup;
    },
    decode: function (input) {
      return input.toLowerCase();
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      eventGroup.events = eventGroup.events.filter(event => {
        event.text = event.text.replace(/åo/g, "ö").replace(/åO/g, "Ö").replace(/äo/g, "ô").replace(/sº/g, "soe").replace("dummy end of file", "");
        //console.log(event.text);
        let plainText = convertToPlainText(event.text).toLowerCase();
        return plainText != "dummy end of file" && event.start != "" && event.end != "" && plainText != "";
      });
      //console.log(JSON.stringify(eventGroup));
      return eventGroup;
    }
  }
};