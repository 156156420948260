<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    fade
} from 'svelte/transition';
let fileType = localStorage.getItem("cc-default-import-settings") || "mediaImport";

function saveDefaultSettings(opt){
    //Save the current settings as the default settings in local storage using the key cc-default-export-settings
    localStorage.setItem("cc-default-import-settings", opt);
}
</script>

<div transition:fade="{{duration: 100}}" 
    class="modal {$modalState === 'fileImport' ? 'show d-block' : ''}" 
    role="dialog" 
    aria-labelledby="importTitle"
    aria-modal="true"
    tabindex="-1" 
    id="ImportModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="importTitle">File Import</h4>
                <button type="button" 
                    class="btn-close" 
                    id="importClose"
                    aria-label="Close dialog" 
                    on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{() => modalState.showModal(fileType)}"
                    role="radiogroup" 
                    aria-label="Import file type selection">
                    <div class="form-check">
                        <input on:change={() => saveDefaultSettings('mediaImport')} 
                            class="form-check-input" 
                            type="radio" 
                            id="mediaFileCheck" 
                            bind:group={fileType} 
                            name="fileType" 
                            value={"mediaImport"}
                            aria-describedby="mediaFileLabel">
                        <label class="form-check-label" id="mediaFileLabel" for="mediaFileCheck">Media File (Audio/Video)</label>
                    </div>
                    <div class="form-check">
                        <input on:change={() => saveDefaultSettings('subtitleImport')} class="form-check-input" type="radio" id="subtitleFileCheck" bind:group={fileType} name="fileType" value={"subtitleImport"}>
                        <label class="form-check-label" for="subtitleFileCheck">Subtitle File</label>
                    </div>
                    <div class="form-check">
                        <input on:change={() => saveDefaultSettings('plainTextImport')} class="form-check-input" type="radio" id="plainTextFileCheck" bind:group={fileType} name="fileType" value={"plainTextImport"}>
                        <label class="form-check-label" for="plainTextFileCheck">Plaintext (Transcript)</label>
                    </div>
                    <div class="form-check">
                        <input on:change={() => saveDefaultSettings('projectImport')} class="form-check-input" type="radio" id="projectFileCheck" bind:group={fileType} name="fileType" value={"projectImport"}>
                        <label class="form-check-label" for="projectFileCheck">Project File</label>
                    </div>
                    <div class="form-check">
                        <input on:change={() => saveDefaultSettings('teamProjectImport')} class="form-check-input" type="radio" id="teamProjectFileCheck" bind:group={fileType} name="fileType" value={"teamProjectImport"}>
                        <label class="form-check-label" for="teamProjectFileCheck">Team Project</label>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" 
                    id="importNextBtn"
                    type="button" 
                    aria-label="Proceed to next step"
                    on:click="{() => modalState.showModal(fileType)}">Next</button>
            </div>
        </div>
    </div>
</div>
