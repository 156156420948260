<script>
    import { projectState } from "@app/store/projectStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { fade } from "svelte/transition";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import _FrameRates from "@app/external/cc-lib/dist/dict/frameRates.js";
    import tcLib from '@app/external/cc-lib/dist/lib/timecode.js'
    let frameRate = $projectState.frameRate;
    let dropFrame = $projectState.dropFrame;
    let incode = tcLib.secToTc($projectState.incode, $projectState.frameRate, $projectState.dropFrame);

    function validateTc(e) {
        incode = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
    }

    function updateProjectTimingSettings(){
        $projectState.frameRate = frameRate;
        if (_FrameRates.dropFrameMap[frameRate]) {
            $projectState.dropFrame = dropFrame;
        } else {
            $projectState.dropFrame = false;
        }

        setTimeout(() => {
            $projectState.incode = tcLib.tcToSec(incode, $projectState.frameRate, $projectState.dropFrame);

            historyState.insert({
                name: "update project timing settings", //action name
                eventGroup: false,
                snapshots: [
                    {
                        store: "projectState",
                        value: JSON.stringify($projectState),
                    },
                ],
            });


            toast.push(`Project timing settings updated.`, {
                classes: ["toast-info"],
            });

            modalState.hideModal();
        }, 50);        
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'projectTimingSettings'
        ? 'show d-block'
        : ''}"
    role="dialog"
    tabindex="-1"
    id="ProjectTimingSettingsModal"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Timing Settings</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault={updateProjectTimingSettings} id="ProjectTimingSettingsForm">
                    <p class="lead text-warning">Project Frame Rate</p>
                    <div class="row">
                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption1"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={23.976}
                                />
                                <label class="form-check-label" for="frOption1"
                                    >23.976</label
                                >
                            </div>
                        </div>
                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption2"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={24}
                                />
                                <label class="form-check-label" for="frOption2"
                                    >24</label
                                >
                            </div>
                        </div>
                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption3"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={25}
                                />
                                <label class="form-check-label" for="frOption3"
                                    >25</label
                                >
                            </div>
                        </div>
                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption4"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={29.97}
                                />
                                <label class="form-check-label" for="frOption4"
                                    >29.97</label
                                >
                            </div>
                        </div>

                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption5"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={30}
                                />
                                <label class="form-check-label" for="frOption5"
                                    >30</label
                                >
                            </div>
                        </div>
                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption6"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={59.94}
                                />
                                <label class="form-check-label" for="frOption6"
                                    >59.94</label
                                >
                            </div>
                        </div>
                        <div class="mb-3 col-3">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="frOption7"
                                    bind:group={frameRate}
                                    name="frameRate"
                                    value={60}
                                />
                                <label class="form-check-label" for="frOption7"
                                    >60</label
                                >
                            </div>
                        </div>
                    </div>
                    {#if [29.97, 59.94].indexOf(frameRate) > -1}
                        <div class="mb-3">
                            <div class="form-check">
                                <input
                                    id="dropFrameCheckbox"
                                    class="form-check-input"
                                    type="checkbox"
                                    bind:checked={dropFrame}
                                />
                                <label
                                    class="form-check-label"
                                    for="dropFrameCheckbox"
                                >
                                    Drop Frame
                                </label>
                            </div>
                        </div>
                    {/if}
                    <p class="lead text-warning">Project Incode</p>
                    <div class="mb-3">
                        <input
                            placeholder="00:00:00:00"
                            type="text"
                            class="form-control"
                            id="incodeInput"
                            bind:value={incode}
                            on:blur={validateTc}
                            on:keyup={(e) => e.key === "Enter" && validateTc(e)}
                            on:focus={(e) => {
                                e.target.select();
                            }}
                        />
                    </div>
                    <button
                        class="btn btn-primary float-end"
                        type="submit"
                        on:click={updateProjectTimingSettings}>Update Settings</button
                    >
                </form>
            </div>
        </div>
    </div>
</div>
