// Initialize Firebase
import firebase from 'firebase/compat/app';
import online from './status.js';
const config = {
    apiKey: "AIzaSyBwFMusZjo7iqYk63nCFYM-64negqrWrxE",
    authDomain: "closed-caption-creator-app.firebaseapp.com",
    databaseURL: "https://closed-caption-creator-app.firebaseio.com",
    projectId: "closed-caption-creator-app",
    storageBucket: "closed-caption-creator-app.appspot.com",
    messagingSenderId: "472541817513",
    appId: "1:472541817513:web:60ce40822728e2a78157b8"
  };

if (firebase.apps.length === 0 && online) {
    firebase.initializeApp(config);
}

export default firebase