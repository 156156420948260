<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    highlightedEventState
} from '@app/store/highlightedEventStore.js';
import {
    historyState
} from "@app/store/historyStore.js";
import {
    editorState
} from "@app/store/editorStore.js";
import debounce from "just-debounce-it";
import {decode} from 'html-entities';
import {lockState} from '@app/store/lockStore.js';
import QuillEditor from './QuillEditor.svelte';
import TranscriptEditorPreview from './TranscriptEditorPreview.svelte';
import TcInput from './inputs/TcInput.svelte';

import RealTimeErrors from './RealTimeErrors.svelte';
import EventNotes from './EventNotes.svelte';
import EventApproval from './EventApproval.svelte';
import ForcedSubtitleEvent from './ForcedSubtitleEvent.svelte';
import EventMetrics from './EventMetrics.svelte';
import EventSpeakers from './EventSpeakers.svelte';
import EventRecipients from './EventRecipients.svelte';
import EventTags from './EventTags.svelte';

import convertToPlainText from '@app/external/cc-lib/functions/quill/convertToPlainText.js';
import sccLib from '@app/external/cc-lib/dist/dict/608.js';
import insertEv from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';
export let eventIndex;

/* Event Metrics */
let wpm = 0,
    cps = 0,
    duration = 0,
    totalWords = 0,
    lineInfo = 0,
    totalChars = 0,
    overlaps, illegalChars, errorMsg = "";

function insertEvent() {
    try {
        if (!$eventGroupState[$projectState.selected]){
            return;
        }

        let eventOptions;
        if ($eventGroupState[$projectState.selected].events.length > 0){
            try {
                let eventTemplate = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].events.length-1];

                eventOptions = {
                    alignment : eventTemplate.alignment, 
                    xPos : eventTemplate.xPos,
                    yPos : eventTemplate.yPos,
                    xOffset : eventTemplate.xOffset,
                    yOffset : eventTemplate.yOffset,
                    vertical : eventTemplate.vertical,
                    voice : eventTemplate.voice,
                    speakingStyle : eventTemplate.speakingStyle,
                    style : eventTemplate.style,
                    rate : eventTemplate.rate,
                    start: $editorState.insertStartOnInsert ? player.currentTime : false,
                    end: $editorState.insertStartOnInsert ? player.currentTime + $eventGroupState[$projectState.selected].minDuration : false,
                }     
            } catch(err){
                console.log(err);
            }
        }
        
        $eventGroupState[$projectState.selected] = insertEv($eventGroupState[$projectState.selected], eventOptions, eventIndex+1);

        setTimeout(function(){
            $eventGroupState[$projectState.selected].selected = [eventIndex+1]
        }, 50);

        /* Store in History */
        historyState.insert({
            name : "insert event", //action name
            eventGroup : $projectState.selected,
            snapshots : [
                {
                    store : "eventGroupState",
                    value : JSON.stringify($eventGroupState)
                }
            ]
        });
    } catch(err){
        console.log(err, err.message);
    }
}

function selectEvent(clickEvent, index) {
    try {
        if (clickEvent.ctrlKey || clickEvent.metaKey) {
            if ($eventGroupState[$projectState.selected].selected.indexOf(index) > -1) {
                $eventGroupState[$projectState.selected].selected = $eventGroupState[$projectState.selected].selected.filter(event => {
                    return event !== index;
                });
            } else {
                $eventGroupState[$projectState.selected].selected = [...$eventGroupState[$projectState.selected].selected, index];
            }
        } else if (clickEvent.shiftKey && $eventGroupState[$projectState.selected].selected.length > 0) {
            let indexOfLastSelectedEvent = $eventGroupState[$projectState.selected].selected[0]
            if (index > indexOfLastSelectedEvent) {
                while (indexOfLastSelectedEvent < index) {
                    $eventGroupState[$projectState.selected].selected = [...$eventGroupState[$projectState.selected].selected, indexOfLastSelectedEvent + 1];
                    indexOfLastSelectedEvent++
                }
            } else {
                while (indexOfLastSelectedEvent > index) {
                    $eventGroupState[$projectState.selected].selected = [...$eventGroupState[$projectState.selected].selected, indexOfLastSelectedEvent - 1];
                    indexOfLastSelectedEvent--;
                }
            }
        } else {
            $eventGroupState[$projectState.selected].selected = [index];
            if ($lockState.video && !isNaN($eventGroupState[$projectState.selected].events[index].start)) {
                player.currentTime = $eventGroupState[$projectState.selected].events[index].start;
            }
        }
    } catch(err){
        console.log("There was an error selecting the event. "+ err.message);
        console.log(err);
    }
}

const updateEventMetrics = debounce(async () => {
    if (!$eventGroupState[$projectState.selected].events[eventIndex]){
        return;
    }

    let plainText = convertToPlainText($eventGroupState[$projectState.selected].events[eventIndex].text);
    /* Duration */
    duration = ($eventGroupState[$projectState.selected].events[eventIndex].end - $eventGroupState[$projectState.selected].events[eventIndex].start).toFixed(2);
    /* Line Info */
    lineInfo = []
    plainText.split("\n").forEach(line => {
        lineInfo.push(decode(line).length);
    });
    /* Total Characters */
    totalChars = lineInfo.reduce((partialSum, a) => partialSum + a, 0);
    /* Total Words */
    totalWords = plainText.split(/\s/gm).length;
    /* wpm */
    wpm = ((totalWords / duration) * 60).toFixed(2);
    /* cps */
    cps = (totalChars / duration).toFixed(2);
    /* Overlaps */
    overlaps = $eventGroupState[$projectState.selected].events.find((event, i, events) => {
        return eventIndex !== i && ((event.start > events[eventIndex].start && event.start < events[eventIndex].end) || (event.end < events[eventIndex].end && event.end > events[eventIndex].start));
    });

    /* Illegal Characters */
    illegalChars = plainText.split("").find(char => {
        return sccLib.valid.indexOf(char) === -1;
    });

    updateErrorMessage();
}, 50);

async function updateErrorMessage() {
    let messages = [];

    if (overlaps){
        if (overlaps && $eventGroupState[$projectState.selected].events[eventIndex].end+0.3 < overlaps.start){
            $eventGroupState[$projectState.selected].events[eventIndex].end = overlaps.start;
        } else if ($eventGroupState[$projectState.selected].overlap && overlaps) {
            messages.push("Event overlap detected.");
        }
    } 

    if ($eventGroupState[$projectState.selected].illegalChars && illegalChars) {
        messages.push(`Illegal non-608 compliant character found ${illegalChars}`);
    }

    if (wpm > $eventGroupState[$projectState.selected].maxWpm) {
        messages.push(`Words Per Minute (${wpm}) exceeds ${$eventGroupState[$projectState.selected].maxWpm}`);
    }

    if (cps > $eventGroupState[$projectState.selected].maxCps) {
        messages.push(`Characters Per Second (${cps}) exceeds ${$eventGroupState[$projectState.selected].maxCps}`);
    }

    if (duration > $eventGroupState[$projectState.selected].maxDuration || duration < $eventGroupState[$projectState.selected].minDuration) {
        messages.push(`Duration of Event (${duration}s) must be between ${$eventGroupState[$projectState.selected].minDuration} and ${$eventGroupState[$projectState.selected].maxDuration} seconds.`);
    }

    if (lineInfo && lineInfo.length > $eventGroupState[$projectState.selected].maxLines) {
        messages.push(`The number of lines (${lineInfo.length}) exceeds ${$eventGroupState[$projectState.selected].maxLines}`);
    }

    if (lineInfo && lineInfo.some(el => el > $eventGroupState[$projectState.selected].maxChars)) {
        messages.push(`The number of characters exceeds ${$eventGroupState[$projectState.selected].maxChars}`);
    }

    errorMsg = messages.join("\n");
}

$: if ($eventGroupState[$projectState.selected].events[eventIndex]) {
    try {
        updateEventMetrics();
    } catch (err) {
        console.log(err, err.message);
    }
}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<form on:click="{clickEvent => selectEvent(clickEvent, eventIndex)}" class="rounded-0 p-3 eventItem {$eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.indexOf(eventIndex) > -1 ? 'border border-warning bg-warning bg-opacity-25' : ''}" style="{$highlightedEventState.enabled && $highlightedEventState.events.indexOf($eventGroupState[$projectState.selected].events[eventIndex].id) === -1 ? 'opacity:0.2' : ''}">
    <div class="row">
        <!-- Event Metrics -->
        <div class="col-2">
            <div class="btn-group float-end" role="group">
                <RealTimeErrors
                    errorMsg="{errorMsg}"
                    ></RealTimeErrors>
                <ForcedSubtitleEvent event={$eventGroupState[$projectState.selected].events[eventIndex]}></ForcedSubtitleEvent>
                <EventApproval event={$eventGroupState[$projectState.selected].events[eventIndex]}></EventApproval>
                <EventNotes event={$eventGroupState[$projectState.selected].events[eventIndex]}></EventNotes>
            </div>

            <p class="text-dark mb-1 fw-bold">{eventIndex+1}</p>
            <div class="input-group input-group-sm mb-1 ">
                <TcInput eventIndex="{eventIndex}" tcType="{'start'}"></TcInput>
            </div>
            <div class="input-group input-group-sm  mb-2">
                <TcInput eventIndex="{eventIndex}" tcType="{'end'}"></TcInput>
            </div>        
            <EventMetrics wpm="{wpm}" cps="{cps}" duration="{duration}" totalWords="{totalWords}" lineInfo="{lineInfo}" totalChars="{totalChars}" eventGroupSettings={{
                maxChars : $eventGroupState[$projectState.selected].maxChars,
                maxLines : $eventGroupState[$projectState.selected].maxLines,
                maxCps : $eventGroupState[$projectState.selected].maxCps,
                maxWpm : $eventGroupState[$projectState.selected].maxWpm,
                minDuration : $eventGroupState[$projectState.selected].minDuration,
                maxDuration : $eventGroupState[$projectState.selected].maxDuration
            }}></EventMetrics>
        </div>
        <div class="col">
            {#if $eventGroupState[$projectState.selected].selected[0] === eventIndex }
            <QuillEditor eventIndex="{eventIndex}"></QuillEditor>
            {:else}
            <TranscriptEditorPreview eventIndex="{eventIndex}"></TranscriptEditorPreview>
            {/if}
            <div class="row">
                <EventSpeakers eventIndex="{eventIndex}"></EventSpeakers>
                <EventRecipients eventIndex="{eventIndex}"></EventRecipients>
                <EventTags eventIndex="{eventIndex}"></EventTags>
            </div>
        </div>
    </div>
    {#if $eventGroupState[$projectState.selected].selected[0] === eventIndex}
        <button type="button" class="btn btn-primary btn-sm newEventButton" title="Insert Event" on:click="{insertEvent}"><i class="bi bi-plus-lg"></i></button>
    {/if}
</form>

<style>
.newEventButton{
    box-shadow: 0.3rem 0.3rem 0.3rem rgb(0 0 0 / 50%);
    float: left;
    -webkit-text-stroke: 1px;
}

.eventItem {
    min-height: 229px;
}
</style>
