import { encode as _encodeHtml } from "html-entities";
const encodeHtml = _encodeHtml;
export default (function escapeTextContent(html) {
  // Function to escape HTML entities in text content
  let result = '';
  let index = 0;
  const length = html.length;
  while (index < length) {
    const ltIndex = html.indexOf('<', index);
    if (ltIndex === -1) {
      // No more tags. Escape the remaining text and append.
      const text = html.substring(index);
      result += encodeHtml(text);
      break;
    }
    if (ltIndex > index) {
      // Text before the next tag. Escape and append.
      const text = html.substring(index, ltIndex);
      result += encodeHtml(text);
    }

    // Find the end of the tag
    const gtIndex = html.indexOf('>', ltIndex);
    if (gtIndex === -1) {
      // No closing '>'. Append the rest as is.
      result += html.substring(ltIndex);
      break;
    }

    // Append the tag as is
    const tag = html.substring(ltIndex, gtIndex + 1);
    result += tag;

    // Move index past the tag
    index = gtIndex + 1;
  }
  result = encodeNonTagAngles(result);
  result = escapeHtmlExceptTags(result);
  return result;
});
function encodeNonTagAngles(input) {
  // Define the allowed tags
  const allowedTags = ['span', 'p', 'em', 'strong', 'u', 'i', 'br'];

  // Create a regex pattern for opening and closing tags
  // Note: Special case for br tag which can be self-closing
  const tagPattern = new RegExp(`<(?:(?:/?(?:${allowedTags.join('|')})(?:\\s+[^>]*)?)|(?:br\\s*/))>`, 'gi');

  // Split the input by HTML tags while keeping the tags
  const parts = input.split(/(<[^>]*>)/g);

  // Process each part
  return parts.map(part => {
    // If this part matches our allowed tag pattern, keep it as is
    if (tagPattern.test(part)) {
      return part;
    }
    // Otherwise, encode any remaining angle brackets
    return part.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }).join('');
}
function escapeHtmlExceptTags(htmlString) {
  const allowedTags = ['p', 'span', 'bold', 'strong', 'em', 'u', 'br'];

  // Create a regex pattern for allowed opening and closing tags
  const allowedTagPattern = new RegExp(`<(?:\/)?(?:${allowedTags.join('|')})(?:\\s+[^>]*)?(?:\/)?>`, 'gi');

  // Split the string by allowed tags and process each part
  const parts = htmlString.split(allowedTagPattern);
  const matches = htmlString.match(allowedTagPattern) || [];

  // Process each part (non-tag content) and reconstruct the string
  let result = '';
  for (let i = 0; i < parts.length; i++) {
    // Escape < and > in the content between tags
    const escapedContent = parts[i].replace(/</g, '&lt;').replace(/>/g, '&gt;');
    result += escapedContent;

    // Add back the original tag if there is one
    if (i < matches.length) {
      result += matches[i];
    }
  }
  return result;
}