import _convertToPlainText from "../quill/convertToPlainText.js";
const convertToPlainText = _convertToPlainText;
/* Converts RollUp and Paint Captions to  Pop On based on the number of Lines Passed In*/
export default (function convertToPopOn(eventGroup, lines = 2, chars = 32) {
  let events = [],
    event,
    plainText = "",
    plainTextOfCurrentEvent = "";
  while (eventGroup.events.length > 0) {
    event = eventGroup.events.shift();
    if (event.style === "Pop-On") {
      events.push(event);
    } else if (event.style === "Roll-Up 2") {
      if (eventGroup.events.length > 0 && eventGroup.events[0].style === "Roll-Up 2") {
        plainText = convertToPlainText(eventGroup.events[0].text, " ");
        plainTextOfCurrentEvent = convertToPlainText(event.text, " ");
        if (plainText.startsWith(">") || plainTextOfCurrentEvent.endsWith(".") || plainTextOfCurrentEvent.endsWith("!") || plainTextOfCurrentEvent.endsWith("?") || plainTextOfCurrentEvent.endsWith("…")) {
          /*  event.style = "Pop-On";
           events.push(event);    */
        } else {
          event.text += eventGroup.events[0].text;
          event.end = eventGroup.events[0].end;
          eventGroup.events.shift();
        }
      }
      event.style = "Pop-On";
      events.push(event);
    } else if (event.style === "Roll-Up 3") {
      for (let i = 0; i < 2; i++) {
        if (eventGroup.events.length > 0 && eventGroup.events[0].style === "Roll-Up 3") {
          plainText = convertToPlainText(eventGroup.events[0].text, " ");
          plainTextOfCurrentEvent = convertToPlainText(event.text, " ");
          if (plainText.startsWith(">") || plainTextOfCurrentEvent.endsWith(".") || plainTextOfCurrentEvent.endsWith("!") || plainTextOfCurrentEvent.endsWith("?") || plainTextOfCurrentEvent.endsWith("…")) {
            break;
          } else {
            event.text += eventGroup.events[0].text;
            event.end = eventGroup.events[0].end;
            eventGroup.events.shift();
          }
        }
      }
      event.style = "Pop-On";
      events.push(event);
    } else if (event.style === "Roll-Up 4") {
      for (let i = 0; i < 3; i++) {
        if (eventGroup.events.length > 0 && eventGroup.events[0].style === "Roll-Up 3") {
          plainText = convertToPlainText(eventGroup.events[0].text, " ");
          plainTextOfCurrentEvent = convertToPlainText(event.text, " ");
          if (plainText.startsWith(">") || plainTextOfCurrentEvent.endsWith(".") || plainTextOfCurrentEvent.endsWith("!") || plainTextOfCurrentEvent.endsWith("?") || plainTextOfCurrentEvent.endsWith("…")) {
            break;
          } else {
            event.text += eventGroup.events[0].text;
            event.end = eventGroup.events[0].end;
            eventGroup.events.shift();
          }
        }
      }
      event.style = "Pop-On";
      events.push(event);
    } else if (event.style === "Paint-On") {
      event.style = "Pop-On";
      events.push(event);
    }
  }
  eventGroup.events = events;
  return eventGroup;
});