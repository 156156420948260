import { encode as _encodeHtml } from "html-entities";
const encodeHtml = _encodeHtml;
export default (function escapeTextContent(html) {
  // Function to escape HTML entities in text content
  let result = '';
  let index = 0;
  const length = html.length;
  while (index < length) {
    const ltIndex = html.indexOf('<', index);
    if (ltIndex === -1) {
      // No more tags. Escape the remaining text and append.
      const text = html.substring(index);
      result += encodeHtml(text);
      break;
    }
    if (ltIndex > index) {
      // Text before the next tag. Escape and append.
      const text = html.substring(index, ltIndex);
      result += encodeHtml(text);
    }

    // Find the end of the tag
    const gtIndex = html.indexOf('>', ltIndex);
    if (gtIndex === -1) {
      // No closing '>'. Append the rest as is.
      result += html.substring(ltIndex);
      break;
    }

    // Append the tag as is
    const tag = html.substring(ltIndex, gtIndex + 1);
    result += tag;

    // Move index past the tag
    index = gtIndex + 1;
  }
  return result;
});