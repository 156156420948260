import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
export default {
  decode: function (input, options) {
    let projectJson = JSON.parse(input);
    if (projectJson.eventGroups && projectJson.eventGroups.length > 0) {
      return projectJson.eventGroups[0].events;
    } else if (projectJson.events && projectJson.events.length > 0) {
      return projectJson.events;
    } else {
      return projectJson;
    }
  },
  encode: function (eventGroup, options) {
    return JSON.stringify(eventGroup, null, 4);
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};