import _hex2ascii from "hex2ascii";
import _formatRtlText from "../utility/formatRtlText.js";
import _reverseNumbers from "../utility/reverseNumbers.js";
import _reverseAnsiText from "../utility/reverseAnsiText.js";
import _eol from "eol";
import _convertToPlainText from "../quill/convertToPlainText.js";
import _cptable from "codepage";
const cptable = _cptable;
const convertToPlainText = _convertToPlainText;
const eol = _eol;
const reverseAnsiText = _reverseAnsiText;
const reverseNumbers = _reverseNumbers;
const formatRtlText = _formatRtlText;
const hex2ascii = _hex2ascii;
export default {
  convertAsciiToHex: function (asciiText) {
    let hexString = "";
    asciiText.split("").forEach(char => {
      hexString += char.charCodeAt(0).toString(16).padStart(2, "0");
    });
    return hexString;
  },
  hexToAscii: function (hexValue) {
    return hex2ascii(hexValue);
  },
  calcTotalFrames: function (mm, ss, ff) {
    let totalFrames = 0;
    //console.log(`-------------------`);
    //console.log(`Calculating total frames using ${mm} ${ss} ${ff}`);
    totalFrames += parseInt(mm, 16) * 256 * 256;
    totalFrames += parseInt(ss, 16) * 256;
    totalFrames += parseInt(ff, 16);

    //console.log("Total: "+totalFrames);
    return totalFrames;
  },
  hexToText: function (lineA, lineB, langA, langB) {
    let text = "";
    text += this.decodeHex(lineA, langB) + "\n";
    text += this.decodeHex(lineB, langB);
    return text;
  },
  getCodeByCmd: function (codes, cmd) {
    let hexValue = Object.keys(codes).find(code => codes[code] === cmd);
    return hexValue;
  },
  encodeHex: function (line, lang) {
    let hexString = "";
    //console.log(lang);
    //console.log(line);
    line.split("").forEach(char => {
      try {
        let charCode = char.charCodeAt(0);
        if (charCode >= 8234 && charCode <= 8239) {
          //console.log(`CharCode (${charCode}) is informational`);
        } else if (lang === 'russian') {
          if (this.getCodeByCmd(this.russianDict, char)) {
            hexString += this.getCodeByCmd(this.russianDict, char);
          } else {
            hexString += cptable[1252].enc[char].toString(16).padStart(2, "0");
          }
        } else if (lang === 'hebrew') {
          if (this.getCodeByCmd(this.hebrewDict, char)) {
            hexString += this.getCodeByCmd(this.hebrewDict, char);
          } else {
            if (cptable[1252].enc[char]) {
              hexString += cptable[1252].enc[char].toString(16).padStart(2, "0");
            } else {
              //console.log("CHAR NOT FOUND IN TABLE: "+ char);
              //console.log("char length: "+char.length);
              //console.log("code value: "+char.charCodeAt(0));
            }
          }
        } else if (lang === 'arabic') {
          if (this.getCodeByCmd(this.arabicDict, char)) {
            hexString += this.getCodeByCmd(this.arabicDict, char);
          } else {
            hexString += cptable[1252].enc[char].toString(16).padStart(2, "0");
          }
        } else if (lang === 'chineseTraditional' || lang === 'chineseSimplified') {
          hexString += cptable[1201].enc[char].toString(16).padStart(2, "0");
        } else {
          if (this.getCodeByCmd(this.specialDict, char)) {
            hexString += this.getCodeByCmd(this.specialDict, char);
          } else {
            hexString += cptable[1252].enc[char].toString(16).padStart(2, "0");
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    });
    //console.log(hexString);
    return hexString;
  },
  decodeHex: function (line, lang) {
    let lineText = "";
    if (lang === 'russian') {
      line.forEach(char => {
        if (this.russianDict[char]) {
          lineText += this.russianDict[char];
        } else {
          lineText += this.decodeChar(char, 1252);
        }
      });
    } else if (lang === 'hebrew') {
      line.forEach(char => {
        if (this.hebrewDict[char]) {
          lineText += this.hebrewDict[char];
        } else {
          lineText += this.decodeChar(char, 1252);
        }
      });
    } else if (lang === 'arabic') {
      line.forEach(char => {
        if (this.arabicDict[char]) {
          lineText += this.arabicDict[char];
        } else {
          lineText += this.decodeChar(char, 1252);
        }
      });
    } else if (lang === 'chineseTraditional' || lang === 'chineseSimplified') {
      line.forEach(char => {
        lineText += this.decodeChar(char, 1201);
      });
    } else {
      let skip = false;
      line.forEach((char, index, chars) => {
        if (skip) {
          skip = false;
          return;
        }
        if (this.specialDict[char + chars[index + 1]]) {
          lineText += this.specialDict[char + chars[index + 1]];
          skip = true;
        } else if (this.specialDict[char]) {
          lineText += this.specialDict[char];
        } else {
          lineText += this.decodeChar(char, 1252);
        }
      });
    }
    return lineText;
  },
  // Decode from Hex
  decodeChar: function (charCode, codePage) {
    try {
      let char = cptable[codePage].dec[parseInt(charCode, 16)];
      return char;
    } catch (e) {
      console.log(e.message);
      return "";
    }
  },
  writeHeader: function (eventGroup, langA, langB, som) {
    let i = 0;
    let header = "0000"; //?Unknown
    /* Tape Number */
    for (i = 0; i < 20; i++) {
      header += "00";
    }

    //?Unknown
    for (i = 0; i < 20; i++) {
      header += "00";
    }

    // translated programme title (28 bytes)
    for (i = 0; i < 28; i++) {
      header += "00";
    }

    // translator (28 bytes)
    for (i = 0; i < 28; i++) {
      header += "00";
    }

    //?Unknown
    for (i = 0; i < 9; i++) {
      header += "00";
    }

    // translated episode title (11 bytes)
    for (i = 0; i < 11; i++) {
      header += "00";
    }

    //?Unknown
    for (i = 0; i < 16; i++) {
      header += "00";
    }
    header += "2209";

    // ? + language codes (english = 09)
    header += `04142354000000000000${this.languageCodes[langA]}${this.languageCodes[langB]}0000`;

    // comments (22 bytes)
    for (i = 0; i < 22; i++) {
      header += "00";
    }

    // ??
    header += "08900000000000";

    // number of subtitles 01
    header += parseInt(eventGroup.events.length % 256).toString(16).padStart(2, "0");
    header += parseInt(eventGroup.events.length / 256).toString(16).padStart(2, "0");

    // write font - prefix with binary zeroes
    let fontBuffer = this.convertAsciiToHex(this.fontCodes[langA]);
    for (i = 0; i < 14 - fontBuffer.length / 2; i++) {
      header += "00";
    }
    header += fontBuffer;

    //?Unknown
    for (i = 0; i < 13; i++) {
      header += "00";
    }

    // number of subtitles 02
    header += parseInt(eventGroup.events.length % 256).toString(16).padStart(2, "0");
    header += parseInt(eventGroup.events.length / 256).toString(16).padStart(2, "0");

    // number of subtitles 03
    header += parseInt(eventGroup.events.length % 256).toString(16).padStart(2, "0");
    header += parseInt(eventGroup.events.length / 256).toString(16).padStart(2, "0");

    //?Unknown
    for (i = 0; i < 6; i++) {
      header += "00";
    }

    // Program Title
    for (i = 0; i < 28; i++) {
      header += "00";
    }

    //Write Font
    header += this.convertAsciiToHex(this.fontCodes[langA]);

    //? Unknown (Subtitle Edit says to use 0x3d 0x8d)
    header += "0819";

    //Start of message
    header += this.convertAsciiToHex(som);

    //? unknown        
    header += "0000000000000000000000000000000000000000000000000000000000";
    for (i = 0; i < 92; i++) {
      header += "00";
    }
    return header;
  },
  encodeTc: function (tcSec, frameRate) {
    let frames = parseInt(tcSec * frameRate),
      mm,
      ss,
      ff,
      mmHex,
      ssHex,
      ffHex;
    //console.log(frames);

    mm = parseInt(frames / 256 / 256);
    frames -= mm * 256 * 256;
    ss = parseInt(frames / 256);
    frames -= ss * 256;
    ff = parseInt(frames);
    mmHex = mm.toString(16).padStart(2, "0");
    ssHex = ss.toString(16).padStart(2, "0");
    ffHex = ff.toString(16).padStart(2, "0");

    // console.log(mm,ss,ff);
    return mmHex + ssHex + ffHex;
  },
  encodeText: function (event, langA, langB) {
    //console.log("-------------");
    //console.log(event.start);
    let res = "";
    let plainText = convertToPlainText(event.text);
    let textLines = eol.split(plainText);

    /* cavena890 only supports two line captions */
    if (textLines.length > 2) {
      plainText = this.autoFormat(textLines.join(" ").trim());
      textLines = eol.split(plainText);
    }
    let line1 = textLines[0];
    let line2 = textLines[1] || textLines[0];
    if (langA === "hebrew") {
      //console.log(`Before 1): ${line1}`);
      line1 = reverseNumbers(line1);
      line1 = formatRtlText(line1);
      line1 = reverseAnsiText(line1);
      //console.log(`After 1): ${line1}`);
    }
    if (langB === "hebrew") {
      //console.log(`Before 2): ${line2}`);
      line2 = reverseNumbers(line2);
      line2 = formatRtlText(line2);
      line2 = reverseAnsiText(line2);
      //console.log(`After 2): ${line2}`);
    }
    if (line1 !== line2) {
      //console.log("HEX Line 1:");
      res += this.encodeHex(line1, langA).padEnd(102, "7f");
      res += "00000000000000";
    } else {
      res += "00000000000000";
    }
    //console.log("HEX Line 2:");
    res += this.encodeHex(line2, langB);
    return res;
  },
  autoFormat: function (plainText = "") {
    /* cavena890 only supports two line captions */
    let lines = [],
      line = "",
      avgCharsPerLine = plainText.length / 2,
      avgWordsPerLine = plainText.split(" ").length / 2;
    plainText.split(" ").forEach(word => {
      if (lines.length === 1) {
        line += " " + word;
      } else if (line.length + word.length > avgCharsPerLine) {
        lines.push(line.trim());
        line = word;
      } else {
        line += " " + word;
      }
    });
    if (line.length > 0) {
      lines.push(line.trim());
    }
    return lines.join("\n");
  },
  languageCodes: {
    danish: "07",
    english: "09",
    russian: "56",
    arabic: "80",
    hebrew: "8f",
    chineseTraditional: "90",
    chineseSimplified: "91",
    romanian: "22",
    "07": "danish",
    "09": "english",
    "56": "russian",
    "80": "arabic",
    "8f": "hebrew",
    "90": "chineseTraditional",
    "91": "chineseSimplified",
    "22": "romanian"
  },
  fontCodes: {
    danish: "VFONTL.V",
    english: "HLV23N.V",
    russian: "KYRIL4.V",
    arabic: "ARA19N.V",
    hebrew: "HEBNOA.V",
    chineseTraditional: "CCKM44.V",
    chineseSimplified: "CCKM44.V",
    romanian: "HLV23N.V"
  },
  logoGrams: ['的', '是', '啊', '吧', '好', '吧', '亲', '爱', '的', '早', '上'],
  russianChars: ['я', 'д', 'й', 'л', 'щ', 'ж', 'ц', 'ф', 'ы'],
  arabicDict: {
    "58": "م",
    "41": "ا",
    "4e": "ص",
    "42": "ب",
    "46": "ح",
    "57": "ل",
    "47": "خ",
    "5d": "ي",
    "4a": "ر",
    "2c": "،",
    "59": "ن",
    "5a": "و",
    "43": "ت",
    "1d": "-",
    "49": "ذ",
    "45": "ج",
    "5b": "ه",
    "56": "ك",
    "21": "؟",
    "4c": "س",
    "52": "ع",
    "5c": "ة",
    "5e": "ى",
    "61": "أ",
    "48": "د",
    "4d": "ش",
    "60": "ء",
    "68": "ﻷ",
    "54": "ف",
    "55": "ق",
    "22": "!",
    "67": "ﻻ",
    "66": "ؤ",
    "64": "آ",
    "50": "ط",
    "6a": "ﻵ",
    "4f": "ض",
    "6b": "ﺋ",
    "44": "ث",
    "51": "ظ",
    "53": "غ",
    "4b": "ز",
    "23": "\"",
    "6c": "ـ"
  },
  hebrewDict: {
    "40": "א",
    "41": "ב",
    "42": "ג",
    "43": "ד",
    "44": "ה",
    "45": "ו",
    "46": "ז",
    "47": "ח",
    "49": "י",
    "4c": "ל",
    "4c": `ל`,
    "22": "״",
    "4d": "ם",
    "4e": "מ",
    "4f": "ן",
    "50": "נ",
    "51": "ס",
    "52": "ע",
    "54": "פ",
    "56": "צ",
    "57": "ק",
    "58": "ר",
    "59": "ש",
    "5a": "ת",
    "4b": "כ",
    "4a": "ך",
    "48": "ט",
    "53": "ף",
    "55": "ץ",
    "b1": "a",
    "b2": "b",
    "b3": "c",
    "b4": "d",
    "b5": "e",
    "b6": "f",
    "b7": "g",
    "b8": "h",
    "b9": "i",
    "ba": "j",
    "bb": "k",
    "bc": "l",
    "bd": "m",
    "be": "n",
    "bf": "o",
    "c0": "p",
    "c1": "q",
    "c2": "r",
    "c3": "s",
    "c4": "t",
    "c5": "u",
    "c6": "v",
    "c7": "w",
    "c8": "x",
    "c9": "y",
    "ca": "z",
    "91": "A",
    "db": "B",
    "93": "C",
    "dc": "D",
    "95": "E",
    "96": "F",
    "97": "G",
    "ab": "H",
    "99": "I",
    "9a": "J",
    "9b": "K",
    "9c": "L",
    "dd": "M",
    "de": "N",
    "9f": "O",
    "a0": "P",
    "a1": "Q",
    "a2": "R",
    "a3": "S",
    "a4": "T",
    "a5": "U",
    "a6": "V",
    "a7": "W",
    "a8": "X",
    "a9": "Y",
    "aa": "Z"
  },
  russianDict: {
    "42": "Б",
    "45": "Е",
    "5a": "З",
    "56": "В",
    "49": "И",
    "4e": "Н",
    "58": "Ы",
    "51": "Я",
    "56": "V",
    "53": "С",
    "72": "р",
    "69": "и",
    "71": "я",
    "6e": "н",
    "74": "т",
    "5c": "Э",
    "77": "ю",
    "46": "Ф",
    "5e": "Ч",
    "44": "Д",
    "62": "б",
    "73": "с",
    "75": "у",
    "64": "д",
    "60": "ж",
    "6a": "й",
    "6c": "л",
    "47": "Г",
    "78": "ы",
    "7a": "з",
    "7e": "ч",
    "6d": "м",
    "67": "г",
    "79": "ь",
    "70": "п",
    "76": "в",
    "55": "У",
    "7d": "щ",
    "66": "ф",
    "7c": "э",
    "7b": "ш",
    "50": "П",
    "52": "П",
    "68": "П"
  },
  specialDict: {
    "1b": "æ",
    "1c": "ø",
    "1d": "å",
    "1e": "Æ",
    "1f": "Ø",
    "5b": "Æ",
    "5c": "Ø",
    "5d": "Å",
    "8141": "À",
    "8145": "È",
    "8149": "Ì",
    "814f": "Ò",
    "8155": "Ù",
    "8161": "à",
    "8165": "è",
    "8169": "ì",
    "816f": "ò",
    "8175": "ù",
    "8241": "Á",
    "8243": "Ć",
    "8245": "É",
    "8249": "Í",
    "824c": "Ĺ",
    "824e": "Ń",
    "824f": "Ó",
    "8252": "Ŕ",
    "8253": "Ś",
    "8255": "Ú",
    "8257": "Ẃ",
    "8259": "Ý",
    "825a": "Ź",
    "8261": "á",
    "8263": "ć",
    "8265": "é",
    "8269": "í",
    "826c": "ĺ",
    "826e": "ń",
    "826f": "ó",
    "8272": "ŕ",
    "8273": "ś",
    "8275": "ú",
    "8277": "ẃ",
    "8279": "ý",
    "827a": "ź",
    "8341": "Â",
    "8343": "Ĉ",
    "8345": "Ê",
    "8347": "Ĝ",
    "8348": "Ĥ",
    "8349": "Î",
    "834a": "Ĵ",
    "834f": "Ô",
    "8353": "Ŝ",
    "8355": "Û",
    "8357": "Ŵ",
    "8359": "Ŷ",
    "8361": "â",
    "8363": "ĉ",
    "8365": "ê",
    "8367": "ĝ",
    "8368": "ĥ",
    "8369": "î",
    "836a": "ĵ",
    "836f": "ô",
    "8373": "ŝ",
    "8375": "û",
    "8377": "ŵ",
    "8379": "ŷ",
    "8441": "Ǎ",
    "8443": "Č",
    "8444": "Ď",
    "8445": "Ě",
    "8447": "Ǧ",
    "8449": "Ǐ",
    "844c": "Ľ",
    "844e": "Ň",
    "8452": "Ř",
    "8453": "Š",
    "8454": "Ť",
    "845a": "Ž",
    "8461": "ǎ",
    "8463": "č",
    "8464": "ď",
    "8465": "ě",
    "8467": "ǧ",
    "8469": "ǐ",
    "846c": "ľ",
    "846e": "ň",
    "8472": "ř",
    "8473": "š",
    "8474": "ť",
    "847a": "ž",
    "8541": "Ã",
    "8549": "Ĩ",
    "854e": "Ñ",
    "854f": "Õ",
    "8555": "Ũ",
    "8561": "ã",
    "8569": "ĩ",
    "856e": "ñ",
    "856f": "õ",
    "8575": "ũ",
    "8641": "Ä",
    "8645": "Ë",
    "8649": "Ï",
    "864f": "Ö",
    "8655": "Ü",
    "8659": "Ÿ",
    "8661": "ä",
    "8665": "ë",
    "8669": "ï",
    "866f": "ö",
    "8675": "ü",
    "8679": "ÿ",
    "8c61": "å",
    "8c41": "Å",
    "88": "<i>",
    "98": "</i>",
    "8961": "ă",
    "8941": "Ă",
    "8773": "ş",
    "8753": "Ş",
    "8774": "ţ",
    "8754": "Ţ"
  }
};