<script>
    export let folders = [];
    export let expandedFolders = new Set();
    export let isActiveFolder = () => false;
    export let toggleFolder = () => {};
    export let navigateToFolder = () => {};
    export let handleKeyDown = () => {};
    export let handleDragOver = () => {};
    export let handleDragLeave = () => {};
    export let handleDrop = () => {};
    export let isDropTargetValid = () => true;
</script>

{#each folders as folder}
    <li class="list-group-item border-0" role="none">
        <div class="d-flex align-items-center">
            <button 
                class="btn btn-sm btn-link p-0 me-1" 
                on:click={() => toggleFolder(folder.id)}
                on:keydown={(e) => handleKeyDown(e, () => toggleFolder(folder.id))}
                aria-label="{expandedFolders.has(folder.id) ? 'Collapse' : 'Expand'} {folder.name}">
                <i class="bi {expandedFolders.has(folder.id) ? 'bi-caret-down-fill' : 'bi-caret-right'} small" 
                    aria-hidden="true"></i>
            </button>
            <button 
                class="folder-name {isActiveFolder(folder) ? 'active-folder' : ''} btn btn-link p-0 text-start" 
                on:click={() => navigateToFolder(folder.id, folder)}
                on:keydown={(e) => handleKeyDown(e, () => navigateToFolder(folder.id, folder))}
                on:dragover={(e) => handleDragOver(e, folder)}
                on:dragleave={handleDragLeave}
                on:drop={(e) => handleDrop(e, folder)}
                data-folder-id={folder.id}
                data-folder-rootdir={folder.rootDir}
                role="treeitem" 
                aria-selected="{isActiveFolder(folder)}"
                aria-expanded="{expandedFolders.has(folder.id)}">
                <i class="bi bi-folder me-1" aria-hidden="true"></i> {folder.name}
            </button>
        </div>
        
        {#if expandedFolders.has(folder.id) && folder.children && folder.children.length > 0}
            <ul class="list-group border-0 ms-3 mt-1 folders-container" role="group">
                <svelte:self 
                    folders={folder.children} 
                    {expandedFolders}
                    {isActiveFolder}
                    {toggleFolder}
                    {navigateToFolder}
                    {handleKeyDown}
                    {handleDragOver}
                    {handleDragLeave}
                    {handleDrop}
                    {isDropTargetValid}
                />
            </ul>
        {/if}
    </li>
{/each}

<style>
    .folders-container {
        min-width: max-content; /* Prevent text wrapping in folders */
    }
    
    .list-group-item {
        padding: 0.5rem 0.25rem;
        background: transparent;
    }
    
    .folder-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
</style>
