export default (function detectMissingDialogue(eventGroup, wordMap) {
  //Use the word map to detect where there may be missing dialogue in the event group. Loop over each event in the wordmap and check to see if there is an event in the event group that occupies that same timeframe +/- 2 seconds. If not then we can assume that there is missing dialogue.
  let missingDialogue = [];
  wordMap.events.forEach(word => {
    let events = eventGroup.events.filter(ev => {
      return ev.start && ev.end && ev.start - 1 <= word.start && ev.end + 1 >= word.end;
    });
    if (events.length === 0) {
      missingDialogue.push(word);
    }
  });
  //sort missing dialogue by index
  missingDialogue.sort((a, b) => {
    return a.index - b.index;
  });
  //console.log(`Missing Dialogue: ${missingDialogue.length}`);
  //console.log(JSON.stringify(missingDialogue, null, 4));
  return missingDialogue;
});