<script>
import { modalState } from "@app/store/modalStore.js";
import { historyState } from "@app/store/historyStore.js";
import { projectState } from "@app/store/projectStore.js";
import { editorState } from "@app/store/editorStore.js";
import { eventGroupState } from "@app/store/eventGroupStore.js";
import UndoRedo from "./UndoRedo.svelte";
import { tick } from "svelte";
import { createEventDispatcher } from "svelte";
import autoFormat from "@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js";
import _Event from "@app/external/cc-lib/dist/classes/event.js";
import insertEvent from "@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js";
import removeEvent from "@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import stripTags from "@app/external/cc-lib/dist/functions/quill/stripTags.js";
import Swal from "sweetalert2";
export let currentState;
const dispatch = createEventDispatcher();

function toggleMenu() {
    dispatch("toggleMenu", {
        menu: "edit",
    });
}

async function removeLeadingSpaces() {
    if (!$eventGroupState[$projectState.selected]) {
        return;
    }

    $eventGroupState[$projectState.selected].selected = [];
    $eventGroupState[$projectState.selected].events.forEach(
        (event, index, events) => {
            events[index].text = event.text
                .replace(/<p([^>]*)>\s+/g, "<p$1>")
                .replace(/\s+<\/p>/g, "</p>");
        },
    );

    $eventGroupState[$projectState.selected].events =
        $eventGroupState[$projectState.selected].events;

    historyState.insert({
        name: "remove leading spaces", //action name
        eventGroup: $projectState.selected,
        snapshots: [
            {
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            },
        ],
    });
}

async function removeEmptyEvents(group) {
    if (!$eventGroupState[group]) {
        return;
    }
    $eventGroupState[$projectState.selected].selected = [];
    $eventGroupState[group].events = $eventGroupState[group].events.filter(
        (event) => {
            return stripTags(event.text) !== "";
        },
    );

    historyState.insert({
        name: "remove events", //action name
        eventGroup: $projectState.selected,
        snapshots: [
            {
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            },
        ],
    });
}

function pasteEvents() {
    try {
        if (!window.quillEditor) {
            return;
        }

        navigator.clipboard.readText().then((text) => {
            try {
                let clipboardEvents = JSON.parse(text);
                //check if clipboardEvents is actually subtitle events
                if (
                    clipboardEvents.length > 0 &&
                    clipboardEvents[0].id &&
                    clipboardEvents[0].style
                ) {
                    let currentTime = player ? player.currentTime : 0;
                    let newCurrentTime = currentTime;
                    Swal.fire({
                        title: "Paste Events",
                        text: "Would you like to paste the event(s) based on the current time?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "Use Original Timing",
                    })
                        .then((sAlertRes) => {
                            clipboardEvents.forEach((event, count, events) => {
                                // Use Sweet Alert to ask the user if they wish to paste events at the current Time. If yes, then paste the events, but update the start and end time of the event so that the start time is the current time and the end time is the current time + the duration of the event.
                                if (sAlertRes.isConfirmed && currentTime >= 0) {
                                    let duration = event.end - event.start;
                                    let startTime =
                                        count === 0
                                            ? currentTime
                                            : currentTime +
                                              (event.start - events[0].end);

                                    startTime = startTime
                                        ? startTime
                                        : newCurrentTime;
                                    let endTime = startTime + duration;
                                    newCurrentTime = endTime;
                                    $eventGroupState[$projectState.selected] =
                                        insertEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            {
                                                ...event,
                                                start: startTime,
                                                end: endTime,
                                            },
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] +
                                                count +
                                                1,
                                        );
                                } else {
                                    $eventGroupState[$projectState.selected] =
                                        insertEvent(
                                            $eventGroupState[
                                                $projectState.selected
                                            ],
                                            event,
                                            $eventGroupState[
                                                $projectState.selected
                                            ].selected[0] +
                                                count +
                                                1,
                                        );
                                }
                                //Order events by time
                                $eventGroupState[$projectState.selected] =
                                    orderByTime(
                                        $eventGroupState[
                                            $projectState.selected
                                        ],
                                    );
                            });
                        })
                        .catch((err) => {
                            console.log("Error pasting events");
                            console.log(err);
                            console.log(err.message);
                        });
                } else {
                    throw new Error("No events found in clipboard");
                }
            } catch (err) {
                let range = quillEditor.getSelection();
                if (range && range.length === 0) {
                    quillEditor.insertText(range.index, text);
                } else if (range) {
                    quillEditor.deleteText(range.index, range.length);
                    quillEditor.insertText(range.index, text);
                }

                if (
                    $editorState.autoFormatText &&
                    $eventGroupState[$projectState.selected].selected.length > 0
                ) {
                    let maxLines = $eventGroupState[$projectState.selected]
                            ? $eventGroupState[$projectState.selected].maxLines
                            : 2,
                        maxChars = $eventGroupState[$projectState.selected]
                            ? $eventGroupState[$projectState.selected].maxChars
                            : 32,
                        minDuration = 0,
                        allowOrphanWords = true;

                    try {
                        let selectedEvent =
                            $eventGroupState[$projectState.selected]
                                .selected[0];
                        $eventGroupState[$projectState.selected].selected = [
                            selectedEvent,
                        ];

                        $eventGroupState[$projectState.selected] = autoFormat(
                            $eventGroupState[$projectState.selected],
                            maxLines,
                            maxChars,
                            minDuration,
                            allowOrphanWords,
                            $eventGroupState[$projectState.selected].selected,
                        );

                        $eventGroupState[$projectState.selected].selected = [];
                        setTimeout(() => {
                            $eventGroupState[$projectState.selected].selected =
                                [selectedEvent];
                        }, 50);
                    } catch (err) {
                        console.log(err.message);
                    }
                }
            }

            historyState.insert({
                name: "paste events", //action name
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            $eventGroupState = $eventGroupState;
        });
    } catch (err) {
        console.log(err.message);
    }
}

async function copyEvents() {
    if ($eventGroupState[$projectState.selected].selected.length > 0) {
        let textToCopy,
            range = quillEditor.getSelection();
        if (
            range &&
            range.length !== 0 &&
            $eventGroupState[$projectState.selected].selected.length === 1
        ) {
            textToCopy = quillEditor.getText(range.index, range.length);
        } else {
            let eventsToCopy = [];
            $eventGroupState[$projectState.selected].selected
                .sort((eventA, eventB) => {
                    return eventA - eventB;
                })
                .forEach((eventIndex) => {
                    eventsToCopy.push(
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ],
                    );
                });

            textToCopy = JSON.stringify(eventsToCopy);
        }

        navigator.clipboard.writeText(textToCopy);
    }
}

async function cutEvents() {
    if ($eventGroupState[$projectState.selected].selected.length > 0) {
        let textToCopy,
            range = quillEditor.getSelection();
        if (
            range &&
            range.length !== 0 &&
            $eventGroupState[$projectState.selected].selected.length === 1
        ) {
            textToCopy = quillEditor.getText(range.index, range.length);
            quillEditor.deleteText(range.index, range.length);
        } else {
            let eventsToCut = [];
            let selectedEvents = $eventGroupState[
                $projectState.selected
            ].selected.map((selectedEvent) => {
                return selectedEvent;
            });

            $eventGroupState[$projectState.selected].selected = [];
            /* Important! */
            await tick();
            selectedEvents
                .sort((a, b) => {
                    return b - a;
                })
                .forEach((eventIndex) => {
                    eventsToCut.push(
                        $eventGroupState[$projectState.selected].events[
                            eventIndex
                        ],
                    );
                    $eventGroupState[$projectState.selected] = removeEvent(
                        $eventGroupState[$projectState.selected],
                        eventIndex,
                    );
                });

            textToCopy = JSON.stringify(eventsToCut);
        }

        navigator.clipboard.writeText(textToCopy);

        historyState.insert({
            name: "cut event(s)", //action name
            eventGroup: $projectState.selected,
            snapshots: [
                {
                    store: "eventGroupState",
                    value: JSON.stringify($eventGroupState),
                },
            ],
        });
    }
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}" role="presentation">
    <a on:click={toggleMenu} 
       class="dropdown-toggle nav-link" 
       href="#!/"
       id="edit-menu"
       role="button"
       aria-haspopup="true"
       aria-expanded={currentState}>Edit</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}" 
         role="menu" 
         aria-labelledby="edit-menu">
        <UndoRedo></UndoRedo>
        <hr class="dropdown-divider" />
        <a class="dropdown-item" href="#!/" on:click={cutEvents} role="menuitem" id="edit-cut">
            <i class="bi bi-scissors" aria-hidden="true"></i> Cut</a>
        <a class="dropdown-item" href="#!/" on:click={copyEvents} role="menuitem" id="edit-copy">
            <i class="bi bi-clipboard-plus" aria-hidden="true"></i> Copy</a>
        <a class="dropdown-item" href="#!/" on:click={pasteEvents} role="menuitem" id="edit-paste">
            <i class="bi bi-clipboard-check" aria-hidden="true"></i> Paste</a>
        <hr class="dropdown-divider" />
        <a class="dropdown-item" 
           href="#!/" 
           on:click={() => removeEmptyEvents($projectState.selected)}
           role="menuitem"
           id="edit-remove-empty">Remove Empty Events</a>
        <a class="dropdown-item" 
           href="#!/" 
           on:click={() => modalState.showModal("removeSdh")}
           role="menuitem"
           id="edit-remove-sdh">Remove SDH</a>
        <a class="dropdown-item" 
           href="#!/" 
           on:click={removeLeadingSpaces}
           role="menuitem"
           id="edit-remove-spaces">Remove Leading Spaces</a>
        <hr class="dropdown-divider"/>
        <a class="dropdown-item {$eventGroupState[$projectState.selected] ? '' : 'disabled'}" 
           href="#!/" 
           on:click={() => modalState.showModal("goToEvent")}
           role="menuitem"
           id="edit-goto"
           aria-disabled={!$eventGroupState[$projectState.selected]}>Go to Event...</a>
        <hr class="dropdown-divider"/>
        <a class="dropdown-item" 
           href="#!/" 
           on:click={() => modalState.showModal("autoReplace")}
           role="menuitem"
           id="edit-auto-replace">
            <i class="bi bi-arrow-repeat" aria-hidden="true"></i> Auto Replacement</a>
        <a class="dropdown-item" 
           href="#!/" 
           on:click={() => modalState.showModal("shortcutKeys")}
           role="menuitem"
           id="edit-shortcuts">
            <i class="bi bi-keyboard" aria-hidden="true"></i> Shortcut Keys</a>
        <a class="dropdown-item" 
           href="#!/" 
           on:click={() => modalState.showModal("options")}
           role="menuitem"
           id="edit-options">
            <i class="bi bi-sliders" aria-hidden="true"></i> Options</a>
    </div>
</li>
