import _convertToPlainText from "../quill/convertToPlainText.js";
import _removeParagraphTags from "../quill/removeParagraphTags.js";
const removeParagraphTags = _removeParagraphTags;
const convertToPlainText = _convertToPlainText;
/* Takes event text as html */
export default (function balanceTextOld(html, maxCharLength, maxLines) {
  if (!html) {
    // console.log("No text provided to balanceText." + text);
    return [''];
  }
  let text = removeParagraphTags(html);
  let whiteSpace = true;
  let prevLines = [];
  let prevBalanceScore = 100000;
  if (text.split(/\s+|(?<=，+)/g).length === 1 && text.length > maxCharLength) {
    text = convertToPlainText(text, " ");
    whiteSpace = false;
  }
  let lines = whiteSpace ? [text.split(/\s+|(?<=，+)/g)] : [text.split("")];
  // console.log(lines);
  let processing = true;
  let i = 0;
  while (processing && i < 2000) {
    processing = false;
    i++;
    prevLines = JSON.parse(JSON.stringify(lines));
    let longestLineIndex = getLongestLineIndex(lines, whiteSpace);
    let word = lines[longestLineIndex].pop();
    if (lines[longestLineIndex + 1]) {
      lines[longestLineIndex + 1].unshift(word);
    } else {
      lines.push([word]);
    }
    lines = lines.filter(line => {
      return line;
    });
    let maxLength = getMaxLength(prevLines, whiteSpace);
    let balanceScore = getBalancedScore(lines, whiteSpace);
    if (maxLength > maxCharLength) {
      processing = true;
    } else if (balanceScore > prevBalanceScore || prevLines.length === 1) {
      processing = false;
      i = 1000;
    } else if (lines.length > maxLines) {
      processing = false;
      i = 1000;
    } else {
      processing = true;
    }
    prevBalanceScore = balanceScore;
  }
  prevLines = prevLines.filter(line => {
    return line.length > 0;
  });
  return prevLines.map(line => {
    return whiteSpace ? line.join(" ").trim() : line.join("").trim();
  });
});
function getLongestLineIndex(lines, whiteSpace) {
  let longestLine = 0;
  let longestLength = 0;
  lines.forEach((line, index) => {
    line = whiteSpace ? convertToPlainText(line.join(" ").trim()) : convertToPlainText(line.join("").trim());
    let length = line.length;
    if (length > longestLength) {
      longestLength = length;
      longestLine = index;
    }
  });
  return longestLine;
}
function getMaxLength(lines, whiteSpace) {
  let longestLength = 0;
  lines.forEach(line => {
    line = whiteSpace ? convertToPlainText(line.join(" ").trim()) : convertToPlainText(line.join("").trim());
    let length = whiteSpace ? line.length : line.length;
    if (length > longestLength) {
      longestLength = length;
    }
  });
  return longestLength;
}
function getBalancedScore(lines, whiteSpace) {
  let lineLengths = lines.map(line => {
    line = whiteSpace ? convertToPlainText(line.join(" ").trim()) : convertToPlainText(line.join("").trim());
    return line.length;
  });
  let deltas = [];
  lineLengths.forEach((lineLength, count, lengths) => {
    if (count < lengths.length - 1) {
      deltas.push(lineLength - lengths[count + 1]);
    }
  });
  return Math.abs(deltas.reduce((partialSum, a) => partialSum + a, 0) / lines.length);
}