export default (async function analyzeDrift(events) {
  let driftValues = [];
  let result = "pass"; //pass/fail
  let reason = "";
  let maxDrift = 0;
  let minDrift = 0;
  let avgDrift = 0;
  let totalEvents = 0;
  let totalDriftMultiplier = [];
  events.forEach(event => {
    if (event.start && event.oStart) {
      driftValues.push(Math.abs(event.start - event.oStart));
      totalDriftMultiplier.push(Math.abs(event.start / event.oStart));
    }
  });
  driftValues = removeOutliers(driftValues);

  //Find the largest drift value (maxDrift)
  maxDrift = Math.max(...driftValues);
  //Find the smallest drift value (minDrift)
  minDrift = Math.min(...driftValues);
  //Find the average drift value (avgDrift)
  avgDrift = driftValues.reduce((a, b) => a + b, 0) / driftValues.length;
  if (avgDrift >= 1) {
    result = "fail";
    reason = "Drift exceeds threshold.";
  }
  return {
    result: result,
    reason: reason,
    maxDrift: maxDrift,
    minDrift: minDrift,
    rangeDrift: maxDrift - minDrift,
    avgDrift: avgDrift,
    avgDriftMultiplier: totalDriftMultiplier.reduce((a, b) => a + b, 0) / driftValues.length
  };
});
/**
* Removes outliers from an array of numbers using the Interquartile Range (IQR) method.
* Values that fall outside Q1 - (multiplier * IQR) and Q3 + (multiplier * IQR) are considered outliers.
* @param {number[]} values - Array of numerical values
* @param {number} [multiplier=1.5] - Multiplier for IQR to determine outlier threshold
* @returns {number[]} Array with outliers removed
*/
function removeOutliers(values, multiplier = 1.5) {
  if (!Array.isArray(values) || values.length === 0) {
    return [];
  }

  // Sort the values
  const sortedValues = [...values].sort((a, b) => a - b);

  // Find Q1, Q3, and IQR
  const q1Index = Math.floor(sortedValues.length * 0.25);
  const q3Index = Math.floor(sortedValues.length * 0.75);
  const q1 = sortedValues[q1Index];
  const q3 = sortedValues[q3Index];
  const iqr = q3 - q1;

  // Calculate bounds
  const lowerBound = q1 - multiplier * iqr;
  const upperBound = q3 + multiplier * iqr;

  // Filter out outliers
  return values.filter(value => value >= lowerBound && value <= upperBound);
}