/* 
Docs: 
https://en.wikipedia.org/wiki/MIDI_Machine_Control#Goto/Locate
https://github.com/selenologist/midi-buttons-to-mmc/blob/master/src/main.rs

Midi Configuration Instructions: 

Prerequisite: Install Loop Midi on windows (https://www.tobias-erichsen.de/software/loopmidi.html). For Mac you can just setup a virtual midi device through the MIDI settings. Optional: Instal MidiOx to help with troubleshooting (http://www.midiox.com/).

Reaper: https://forums.cockos.com/showthread.php?t=15569 (set your midi input to "enabled + control")

Presonus Studio One:
Studio One -> Options -> Advanced -> Synchronization 

Sync To External Device x
Midi Machine Control (Select Midi Device)

Pro Tools:
MMC needs two settings. One in Peripherals. Setup > Peripherals > Machine Control. If Pro Tools is the master, enable it. Make sure you are sending out the port you want and that both devices have the same ID number.
Then in Setup>Options>Synchronization a lot of MMC needs under "Machine Control" Machine Chases Memory Location and Machine Follows Edit Insertion/Scrub checked.
Then, on the little clock ("online" button) found in the transport window, right click and make sure the settings are what you want. Two sections: Transport and Online. Transport is for what Pro Tools is listening to for transport (if Pro Tools is leading, then Pro Tools should be selected.) Online should be set to MMC with a little checkmark if you want MMC to be sent to other devices.
*/

import { writable, get } from "svelte/store";
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js';
import throttle from 'just-throttle';
import debounce from "just-debounce-it";

let pauseHold = false, updateTimeHold = false;
function createAdrStore(defaultValue) {
	const { subscribe, set, update } = writable(defaultValue);

	return {
		subscribe,
		set,
		update,
		reset: () => set(defaultValue),
		async toggleMidiControl() {
			let adrState = get(this);
			adrState.enable = !adrState.enable;
			adrState.inputDevices = [];
			adrState.outputDevices = [];
			adrState.selectedInput = undefined;
			adrState.selectedOutput = undefined;
			if (adrState.enable) {
				let midiAccess = await navigator.requestMIDIAccess({name: "Closed Caption Creator", sysex: true});

				Array.from(midiAccess.inputs).forEach((input) => {
					adrState.inputDevices = [...adrState.inputDevices, input[1]];
					adrState.selectedInput = adrState.inputDevices[0];
				});

				Array.from(midiAccess.outputs).forEach((output) => {
					adrState.outputDevices = [...adrState.outputDevices, output[1]];
					adrState.selectedOutput = adrState.outputDevices[0];
				});

                player.on("pause", () => {
                    pauseHold = true;
                    setTimeout(() =>{
                        pauseHold = false;
                    }, 500);
                });

                player.on("seeked", () => {
                    updateTimeHold = true;
                    setTimeout(() =>{
                        updateTimeHold = false;
                    }, 500);
                });

                player.on("timeupdate", () => {
                    updateTimeHold = true;
                    setTimeout(() =>{
                        updateTimeHold = false;
                    }, 500);
                });

				adrState.inputDevices.forEach((input) => {
					input.onmidimessage = (msg) => {
                        try {
                            /* Filter to only the selected input */
                            if (input.id === adrState.selectedInput.id){
                                //console.log(msg.data);
                                /* Sysex Cmd */ 
                                if (msg.data[0] == 240){             
                                    if (msg.data.length === 10){                                        
                                        /* Timcode Cmd */ 
                                        //console.log(`MIDI RECEIVED: TIMECODE`);
                                        goToMsgReceived(msg.data);
                                    }
                                } else if (msg.data[0] == 241){
                                    /* Quarter Frame */
                                    //console.log(`MIDI RECEIVED: Quarter Frame`);
                                    quarterFrameMsgReceived(msg.data);             
                                } else if (msg.data[0] == 242){
                                    songPositionPtrReceived(msg.data, adrState.bpm);
                                }  else if (msg.data[0] == 252){
                                    pausePlayer();
                                }  else if (msg.data[0] == 251){
                                    playPlayer();
                                }  
                            }
                        } catch(err){
                            console.log("ERROR: ", err.message);
                        }
                        
					};
				});
			}

			set(adrState);
		}
	};
}

export const adrState = createAdrStore({
	enable: false,
    record : false,
	bpm: 120,
	selectedInput: undefined,
	selectedOutput: undefined,
	inputDevices: [],
	outputDevices: []
});

function extractTimecodeFromMsg(data){
    let tc = data[5].toString().padStart(2, "0") + ":" + data[6].toString().padStart(2, "0") + ":" + data[7].toString().padStart(2, "0") + ":" + data[8].toString().padStart(2, "0");

    return tc; 
}

const goToMsgReceived = debounce((data) =>{

    if (!updateTimeHold){
        let tc = extractTimecodeFromMsg(data);
        let tcSec = tcLib.tcToSec(tc, 24);
        player.currentTime = tcSec;
    }
    
    
}, 250, true);

const quarterFrameMsgReceived = throttle((data) =>{
    if (!player.playing && !pauseHold){
        player.play();
    }

    pausePlayer();
}, 150);

const songPositionPtrReceived = debounce((data, bpm = 120) =>{
    if (!updateTimeHold){
        let intValue = parseInt(data[2].toString(2).padStart(7,"0") + data[1].toString(2).padStart(7,"0"), 2);
        goToTime((intValue*0.25) / (bpm/60));
    }
}, 250, true);

const pausePlayer = debounce(() =>{
    //console.log("PAUSE PLAYER");
    player.pause();
}, 250);

const playPlayer = debounce(() =>{
    player.play();
}, 250);

const goToTime = throttle((tcSec) =>{
    player.currentTime = tcSec;
}, 250);
