<script>
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    editorState
} from "@app/store/editorStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import { uiState } from "@app/store/uiStore.js";
import {
    styleState
} from "@app/store/styleStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import {
    autoReplaceState
} from "@app/store/autoReplaceStore.js";
import {
    onMount
} from "svelte";
import Quill from "quill";
import { clickOutside } from 'svelte-use-click-outside';
import throttle from "just-throttle";
import animateScrollTo from 'animated-scroll-to';
import quillClasses from "@app/external/cc-lib/dist/dict/quillClasses.js";
import insertEv from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';
import splitIntoParagraphs from '@app/external/cc-lib/dist/functions/quill/splitIntoParagraphs.js';
import textLengthLimiter from "@app/external/cc-lib/dist/functions/quill/textLengthLimiter.js";
import extractTextFromHtml from "@app/external/cc-lib/dist/functions/quill/extractTextFromHtml.js";
import convertToHtml from "@app/external/cc-lib/dist/functions/quill/convertToHtml.js";
import colorLookup from "@app/external/cc-lib/dist/dict/colors.js";

export let eventIndex;
const alignmentMap = {
    left: false,
    right: "right",
    center: "center",
};

let timer, paragraphs;

let bindings = {
    tab : {
        key: "Tab",
        handler: function(range) {
            return false;
        }
    }
}

let savedShortcuts = localStorage.getItem("cc-shortcuts");
if (savedShortcuts) {
    let shortcutGroups = JSON.parse(savedShortcuts);
    //Loop over each shortcut in the shortcuts array property of each shortcutGroup. Look for any shortcut where the keyCmd property is set to a single key (no + sign) and add that key to the bindings object.
    shortcutGroups.forEach(shortcutGroup => {
        shortcutGroup.shortcuts.forEach(shortcut => {
            if (!shortcut.keyCmd.includes("+") && shortcut.keyCmd !== "ArrowDown" && shortcut.keyCmd !== "ArrowUp" && shortcut.keyCmd !== "ArrowLeft" && shortcut.keyCmd !== "ArrowRight") {
                bindings[shortcut.keyCmd] = {
                    key: shortcut.keyCmd,
                    handler: function() {
                        return false;
                    }
                }
            }
        });
    }); 
} 

onMount(() => {
    window.quillEditor = new Quill("#QuillEditor", {
        theme: "bubble",
        modules: {
            keyboard: {
                bindings: bindings
            },
            toolbar: [
                ["bold", "underline", "italic"],
                [{
                    'color' : colorLookup.colors
                }, {
                    'background' : colorLookup.colors
                }],
                ["clean"]
            ],
            history: {
                maxStack: 0,
                userOnly: true
            }
        },
        formats: ["bold", "underline", "italic", "align", "direction", "color", "background"],
    });
    /* Paste the text contents from the event */
    quillEditor.clipboard.dangerouslyPasteHTML(
        $eventGroupState[$projectState.selected].events[eventIndex].text
    );

    /* Apply rtl, and alignment */
    quillEditor.setSelection(0, quillEditor.getLength());
    $eventGroupState[$projectState.selected].rtl ?
        quillEditor.format("direction", "rtl") :
        quillEditor.format("direction", false);
    quillEditor.format(
        "align",
        alignmentMap[
            $eventGroupState[$projectState.selected].events[eventIndex]
            .alignment
        ]
    );

    if ($editorState.editing){
        /* Move cursor to end of event text */
        quillEditor.setSelection(quillEditor.getLength(), 0);
    } else {
        quillEditor.blur();
    }

    setTimeout(() => {
        try {
            animateScrollTo(document.querySelector('#QuillEditor'), {
                elementToScroll: document.querySelector('#EventList'),
                verticalOffset: $uiState.layout === "vertical" ? -5 : -230
            });
        } catch (err) {
            console.log(err, err.message);
        }
        
    }, 100);

    const toolbarContainer =  quillEditor.getModule("toolbar").container;
    const mouseDownRef = toolbarContainer.addEventListener("mousedown", (e) => {
        e.preventDefault();
    });

    quillEditor.on("text-change", (delta, oldDelta, source) => {
        /* Auto Replacement / Shortforms */
        //console.log(JSON.stringify(oldDelta));
        if (source === "user" && delta.ops[1] && (delta.ops[1].insert === "\n" || delta.ops[1].insert === " ")) {
            let updateFlag = false;
            let offset = 0;
            $autoReplaceState.forEach(list => {
                if (!list.enabled) {
                    return;
                }

                Object.keys(list.replacements).forEach(replacement => {
                    let re = new RegExp(`(?<=^|\\s)${replacement}(?=\\s|$)`, "g");
                    oldDelta.ops.forEach(op => {
                        if (!op.insert) {
                            return;
                        }

                        let matches = re.exec(op.insert);
                        if (matches) {
                            op.insert = op.insert.replace(re, list.uppercase && matches.index === 0 ? capitalizeFirstLetter(list.replacements[replacement]) : list.replacements[replacement]);
                            offset = list.replacements[replacement].length - replacement.length;
                            updateFlag = true;
                        }
                    });
                });
            });

            if (updateFlag) {
                let range = quillEditor.getSelection();
                delta = quillEditor.setContents(oldDelta, "user");

                setTimeout(() => {
                    quillEditor.setSelection(range.index+offset, 0);
                }, 0);

                return;
            }
        }

        /* Auto Insert Event */
        if ($editorState.autoInsertEvent && delta.ops[1] && delta.ops[1].insert === "\n" && source === "user") {
            paragraphs = splitIntoParagraphs(quillEditor.root.innerHTML);
            if (paragraphs.length > $eventGroupState[$projectState.selected].maxLines) {
                //console.log("Automatic Event Insertion");
                //console.log(quillEditor.root.innerHTML);
                var range = quillEditor.getSelection();
                var length = quillEditor.getLength();
                //console.log("range:", range.index, length);
                $eventGroupState[$projectState.selected].events[eventIndex].text = paragraphs.splice(0, $eventGroupState[$projectState.selected].maxLines).join("");

                insertEvent({
                    text: paragraphs.filter(p => {return !p.includes("<br>")}).join(""),
                    alignment: $eventGroupState[$projectState.selected].events[eventIndex].alignment,
                    xPos: $eventGroupState[$projectState.selected].events[eventIndex].xPos,
                    yPos: $eventGroupState[$projectState.selected].events[eventIndex].yPos, //start, center, end
                    xOffset: $eventGroupState[$projectState.selected].events[eventIndex].xOffset,
                    yOffset: $eventGroupState[$projectState.selected].events[eventIndex].yOffset,
                    style: $eventGroupState[$projectState.selected].events[eventIndex].style,
                    vertical: $eventGroupState[$projectState.selected].events[eventIndex].vertical,
                    start: $editorState.insertStartOnInsert ? player.currentTime : false,
                    end: $editorState.insertStartOnInsert ? player.currentTime + $eventGroupState[$projectState.selected].minDuration : false,
                });

                let matches = paragraphs[0].match(/<p[^>]*>(.*?)<\/p>/);
                if (matches && matches[1] !== "<br>" && range.index !== length-2) {
                    $eventGroupState[$projectState.selected].selected = [eventIndex];
                    quillEditor.clipboard.dangerouslyPasteHTML(
                        $eventGroupState[$projectState.selected].events[eventIndex].text
                    );
                }

                pauseWhileTyping();
                saveToHistory();
            }
        } else if ($editorState.autoLineBreak && delta.ops[1] && delta.ops[1].insert !== " " && delta.ops[1].insert !== "\n" && source === "user") {
            /* Automatically Line Break */
            //console.log("Checking if line requires splitting");
            paragraphs = extractTextFromHtml(quillEditor.root.innerHTML);

            let lineErrors = paragraphs.findIndex(p => {
                return p.length >= $eventGroupState[$projectState.selected].maxChars;
            });

            if (lineErrors > -1) {
                //console.log("Line Requires Splitting");
                //console.log(paragraphs);
                var range = quillEditor.getSelection();
                //console.log(range);
                paragraphs = textLengthLimiter(paragraphs, $eventGroupState[$projectState.selected].maxChars);
                //console.log(paragraphs);
                quillEditor.clipboard.dangerouslyPasteHTML(
                    convertToHtml(paragraphs.splice(0, $eventGroupState[$projectState.selected].maxLines).join("\n"), [quillClasses.align[$eventGroupState[$projectState.selected].events[eventIndex].alignment]])
                );

                if ($editorState.autoInsertEvent && paragraphs.length > 0){
                    setTimeout(() => {
                        insertEvent({
                            text: convertToHtml(paragraphs.join("\n"), [quillClasses.align[$eventGroupState[$projectState.selected].events[eventIndex].alignment]]),
                            alignment: $eventGroupState[$projectState.selected].events[eventIndex].alignment,
                            xPos: $eventGroupState[$projectState.selected].events[eventIndex].xPos,
                            yPos: $eventGroupState[$projectState.selected].events[eventIndex].yPos, //start, center, end
                            xOffset: $eventGroupState[$projectState.selected].events[eventIndex].xOffset,
                            yOffset: $eventGroupState[$projectState.selected].events[eventIndex].yOffset,
                            style: $eventGroupState[$projectState.selected].events[eventIndex].style,
                            vertical: $eventGroupState[$projectState.selected].events[eventIndex].vertical,
                            start: $editorState.insertStartOnInsert ? player.currentTime : false,
                            end: $editorState.insertStartOnInsert ? player.currentTime + $eventGroupState[$projectState.selected].minDuration : false,
                        });
                    }, 0);
                } else {
                    setTimeout(() => {
                        quillEditor.setSelection(range ? range.index : quillEditor.getLength(), 0);
                    }, 0);
                }

                pauseWhileTyping();
                saveToHistory();
                
            } else {
                $eventGroupState[$projectState.selected].events[eventIndex].text = quillEditor.root.innerHTML;
                pauseWhileTyping();
                saveToHistory();
            }
        } else {
            $eventGroupState[$projectState.selected].events[eventIndex].text = quillEditor.root.innerHTML;

            if (source === "user") {
                pauseWhileTyping();
                saveToHistory();
            }
        }
    });

    quillEditor.on('selection-change', function(range, oldRange, source) {
        if (source == 'user' && range) {
            $editorState.editing = true;
        }
    });

    return toolbarContainer.removeEventListener("mousedown", mouseDownRef);
});

async function insertEvent(options) {
    try {
        if (!$eventGroupState[$projectState.selected]) {
            return;
        }

        /* Insert End On Insert Option */
        if ($editorState.insertEndOnInsert) {
            $eventGroupState[$projectState.selected].events[eventIndex].end = player.currentTime;
        }

        $eventGroupState[$projectState.selected] = insertEv($eventGroupState[$projectState.selected], options, eventIndex + 1);
        $eventGroupState[$projectState.selected].selected = [eventIndex + 1];

        /* Store in History */
        historyState.insert({
            name: "insert event", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err) {
        console.log(err, err.message);
    }
}

const saveToHistory = throttle(
    () => {
        $editorState.editing = true;
        historyState.insert({
            name: "update text", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    },
    250, {
        trailing: true
    }
);

async function pauseWhileTyping() {
    if ($editorState.pauseWhileTyping && player.state.playing) {
        player.pause();
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(function() {
            player.play();
        }, $editorState.pauseWhileTypeDuration || 500);
    }
}

function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}

function disableEditing(){
    setTimeout(() => {
        $editorState.editing = false;
    }, 50);
}

function selectEditor(){
    if (window.quillEditor && !quillEditor.getSelection()){
        quillEditor.setSelection(quillEditor.getLength(), 0);
    }
}
</script>


<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
    id="QuillEditor"
    role="region"
    aria-label="Text editor for event {eventIndex}"
    style="font-family: {$styleState.mode ? 'plexMono' : $styleState.fontFamily} !important; line-height: {$styleState.mode ? '100' : $styleState.lineSpacing}%; font-size: {$styleState.mode ? '16' : $styleState.fontSize}px;"
    use:clickOutside={() => disableEditing()}
    on:click={() =>  selectEditor()}
/>

<style>
#QuillEditor {
    background-color: #fbfbfb;
    color: #000;
    height: auto;
    min-height: 160px;
    word-break: break-word;
}
</style>
