<script>
import {
    uiState
} from '@app/store/uiStore.js'
import {
    createEventDispatcher
} from 'svelte';
export let currentState;
const dispatch = createEventDispatcher();

function toggleMenu() {
    dispatch('toggleMenu', {
        menu: 'workspaces'
    });
}

function updateLayout(layout) {
    localStorage.setItem("cc-workspace-default", layout);
    $uiState.timeline = false;
    let source;
    if (player && player.src) {
        source = player.src;
    }

    $uiState.layout = layout;

    setTimeout(() => {
        $uiState.timeline = true;
    }, 2000);
    
    if (source.src) {
        setTimeout(() => {
            player.src = source;
        }, 150);
    }
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}" role="presentation">
    <a on:click={toggleMenu} 
       class="dropdown-toggle nav-link" 
       href="#!/"
       id="workspaces-menu"
       role="button"
       aria-haspopup="true"
       aria-expanded={currentState}>Workspaces</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}" 
         role="menu" 
         aria-labelledby="workspaces-menu">
        <a class="dropdown-item" 
           href="#!/"
           role="menuitem"
           id="workspace-default"
           on:click="{() => updateLayout('default')}">Default {#if $uiState.layout === 'default'}<i class="bi bi-check-lg" aria-label="Selected"></i>{/if}</a>
        <a class="dropdown-item" 
           href="#!/"
           role="menuitem"
           id="workspace-reverse"
           on:click="{() => updateLayout('reverse')}">Default Reverse {#if $uiState.layout === 'reverse'}<i class="bi bi-check-lg" aria-label="Selected"></i>{/if}</a>
        <a class="dropdown-item" 
           href="#!/"
           role="menuitem"
           id="workspace-vertical"
           on:click="{() => updateLayout('vertical')}">Vertical Stack {#if $uiState.layout === 'vertical'}<i class="bi bi-check-lg" aria-label="Selected"></i>{/if}</a>
        <a class="dropdown-item" 
           href="#!/"
           role="menuitem"
           id="workspace-ultrawide"
           on:click="{() => updateLayout('ultrawide')}">Ultrawide Display {#if $uiState.layout === 'ultrawide'}<i class="bi bi-check-lg" aria-label="Selected"></i>{/if}</a>
    </div>
</li>
