import _tcLib from "../../lib/timecode.js";
const tcLib = _tcLib;
export default (function dropFrameConvert(eventGroup, options) {
  let tcIn, tcOut, incode, outcode;
  if (options.frameRate != "29.97" && options.frameRate != "59.94") {
    return eventGroup;
  }
  eventGroup.events.forEach(function (event, i, events) {
    tcIn = tcLib.createTc("00:00:00:00", options.frameRate, true);
    tcOut = tcLib.createTc("00:00:00:00", options.frameRate, true);
    incode = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, false);
    outcode = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, false);
    tcIn.add(incode.frameCount);
    tcOut.add(outcode.frameCount);
    events[i].start = tcLib.tcToSec(tcIn.toString(), options.frameRate);
    events[i].end = tcLib.tcToSec(tcOut.toString(), options.frameRate);
  });
  return eventGroup;
});