<script>
import {environment} from '@app/store/envStore.js';
import {lockState} from '@app/store/lockStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    highlightedEventState
} from '@app/store/highlightedEventStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {decode} from 'html-entities';
import {
    BarLoader
} from 'svelte-loading-spinners';
import { onDestroy } from 'svelte';
/* Firebase */
import firebase from '@app/configs/firebase.js'
import db from '@app/configs/firestore.js'

import {
    saveAs
} from 'file-saver';
import Papa from 'papaparse';

/* CC LIB */
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
import getLineCount from '@app/external/cc-lib/dist/functions/quill/getLineCount.js';
import sccLib from '@app/external/cc-lib/dist/dict/608.js';
import netflixLib from '@app/external/cc-lib/dist/dict/netflixGlyphs.js';
import orderByTime from '@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js'
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js'

let styleGuideList = [],
    errors = [],
    userId = $environment.online ? firebase.auth().currentUser.uid : null,
    eventListElement = document.getElementById("EventList"),
    selectedStyleGuide,
    selectedError,
    highlight = false,
    gettingRecords;

reloadStyleGuides();

onDestroy(() => {
    $highlightedEventState = {
        enabled : false,
        events : []
    };
});

function reloadStyleGuides(){
    styleGuideList = [];
    //Refactor code to use local storage instead of firestore when environment.online is set to false
    if ($environment.online){
        gettingRecords = db.collection("users").doc(userId).collection('guides').where("enabled", "==", true).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                styleGuideList = [...styleGuideList, doc.data()];
            });

            //Sort style guides by name
            styleGuideList.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            selectedStyleGuide = styleGuideList.length > 0 ? styleGuideList[0] : "None";
            
        }).catch((error) => {
            console.log("Error style guides: ", error);
            toast.push(error.message, {classes: ['toast-danger']});
        });
    } else {
        styleGuideList = JSON.parse(localStorage.getItem('cc-style-guides')) || [];
        selectedStyleGuide = styleGuideList.length > 0 ? styleGuideList[0] : "None";
    }
}

function runReview(){
    clearErrors();

    $eventGroupState[$projectState.selected] = orderByTime($eventGroupState[$projectState.selected]);
    $eventGroupState[$projectState.selected].events.forEach((event, count, events) => {
        let messages = [];
        let error = {
            id : event.id,
            index :  count+1,
            text : convertToPlainText(event.text),
            messages : []
        }
        
        messages.push(eventLineTest(event.text, selectedStyleGuide.maxLines));
        messages.push(eventCharTest(event.text, selectedStyleGuide.maxChars));
        messages.push(totalCharTest(event.text, selectedStyleGuide.totalMaxChars));
        messages.push(eventDurationTest(event.start, event.end, selectedStyleGuide.minDuration, selectedStyleGuide.maxDuration));
        messages.push(eventCpsTest(event.start, event.end, event.text, selectedStyleGuide.minCps, selectedStyleGuide.maxCps));
        messages.push(eventWpmTest(event.start, event.end, event.text, selectedStyleGuide.minWpm, selectedStyleGuide.maxWpm));
        if (selectedStyleGuide.illegalChars){
            messages.push(detectIllegalChars(event.text, sccLib.valid));
        }
        if (selectedStyleGuide.netflixGlyphs){
            messages.push(detectIllegalChars(event.text,netflixLib.valid));
        }
        if (events[count+1]){
            messages.push(eventGapTest(event.end, events[count+1].start, selectedStyleGuide.minEventGap, selectedStyleGuide.maxEventGap));
            if (selectedStyleGuide.overlap){
                messages.push(overlapTest(event.end, events[count+1].start));
            }            
        }
        /* Position Tests */
        if (selectedStyleGuide.positionTopLeft){
            messages.push(positionTest(event,'start','start','top left'));
        }
        if (selectedStyleGuide.positionTopCenter){
            messages.push(positionTest(event,'start','center','top center'));
        }
        if (selectedStyleGuide.positionTopRight){
            messages.push(positionTest(event,'start','end','top right'));
        }

        if (selectedStyleGuide.positionCenterLeft){
            messages.push(positionTest(event,'center','start','center left'));
        }
        if (selectedStyleGuide.positionCenter){
            messages.push(positionTest(event,'center','center','center'));
        }
        if (selectedStyleGuide.positionCenterRight){
            messages.push(positionTest(event,'center','end','center right'));
        }

        if (selectedStyleGuide.positionBottomLeft){
            messages.push(positionTest(event,'end','start','bottom left'));
        }
        if (selectedStyleGuide.positionBottomCenter){
            messages.push(positionTest(event,'end','center','bottom center'));
        }
        if (selectedStyleGuide.positionBottomRight){
            messages.push(positionTest(event,'end','end','bottom rigth'));
        }

        if (selectedStyleGuide.positionYOffset){
            messages.push(event.yOffset !== 0 ? 'vertical offset set' : undefined);
        }

        if (selectedStyleGuide.positionXOffset){
            messages.push(event.xOffset !== 0 ? 'horizontal offset set' : undefined);
        }
        /* Speaker Test */
        if (selectedStyleGuide.missingSpeaker){
            messages.push(event.speakers.length > 0 && event.speakers[0].name ? undefined : 'Event is missing a speaker');
        }

        /* Hyphen Test */
        if (selectedStyleGuide.hyphenSpace){
            messages.push(event.text.match(/-\s/) ? 'Text contains a hyphen followed by whitespace' : undefined);
        }
        /* Underscore Test */
        if (selectedStyleGuide.hasUnderscore){
            messages.push(event.text.match(/_/) ? 'Text contains an underscore' : undefined);
        }
        /* 2 or 4+ dots (periods)*/
        if (selectedStyleGuide.periods){
            messages.push(event.text.match(/(?<!\.)\.{2}(?!\.)|\.{4,}/) ? 'Text contains 2 or 4+ dots (periods)' : undefined);
        }
        /* 2 or 4+ dots (periods)*/
        if (selectedStyleGuide.useEllipses){
            messages.push(event.text.match(/\.{3,}/) ? 'Text is missing ellipses' : undefined);
        }
        /* Numbers 1-10 should be spelt*/
        if (selectedStyleGuide.spellNumbers){
            messages.push(event.text.match(/(?<![0-9])([1-9]|10)(?![0-9])/) ? 'Numbers 1-10 should be written' : undefined);
        }
        /* Numbers at the start of a sentence*/
        if (selectedStyleGuide.spellNumbersAtStart){
            messages.push(event.text.match(/>\s+[0-9]|>[0-9]/) ? 'Numbers at start of line should be written' : undefined);
        }

        /* Repeat Words */
        if (selectedStyleGuide.repeatWords){
            messages.push(event.text.match(/\b(\w+)\s+\1\b/g) ? 'Text contains repeat words' : undefined);
        }

        /* Fit Subtitles */
        if (selectedStyleGuide.fitSubtitles){
            messages.push(checkSubtitleFit(event.text, selectedStyleGuide.maxChars));
        }

        /* leadingTrailingSpace */
        if (selectedStyleGuide.leadingTrailingSpace){
            messages.push(checkLeadingSpaces(event.text));
        }

        /* whitespace */
        if (selectedStyleGuide.whitespace){
            messages.push(checkWhiteSpace(event.text));
        }

        /* blankLines */
        if (selectedStyleGuide.blankLines){
            messages.push(checkBlankLines(event.text));
        }

        /* partialItalics */
        if (selectedStyleGuide.partialItalics){
            messages.push(checkForFormatting(event.text, "em", false) ? 'Text contains partial italics' : undefined);
        }

        /* partialBold */
        if (selectedStyleGuide.partialBold){
            messages.push(checkForFormatting(event.text, "strong", false) ? 'Text contains partial bold' : undefined);
        }

        /* partialUnderline */
        if (selectedStyleGuide.partialUnderline){
            messages.push(checkForFormatting(event.text, "u", false) ? 'Text contains partial underline' : undefined);
        }

        /* fullItalics */
        if (selectedStyleGuide.fullItalics){
            messages.push(checkForFormatting(event.text, "em", true) ? 'Text contains italics' : undefined);
        }

        /* fullBold */
        if (selectedStyleGuide.fullBold){
            messages.push(checkForFormatting(event.text, "strong", true) ? 'Text contains bolded elements' : undefined);
        }

        /* fullUnderline */
        if (selectedStyleGuide.fullUnderline){
            messages.push(checkForFormatting(event.text, "u", true) ? 'Text contains underlined elements' : undefined);
        }

        /* Approval Passed */
        if (selectedStyleGuide.approvalPassed){
            messages.push(event.approved === true ? 'Event PASSED approved' : undefined);
        }
        /* Approval Failed */
        if (selectedStyleGuide.approvalFailed){
            messages.push(event.approved === false ? 'Event FAILED approval' : undefined);
        }
        /* Approval Not Set */
        if (selectedStyleGuide.approvalNotSet){
            messages.push(event.approved === undefined ? 'Event approval NOT SET' : undefined);
        }

        /* Event Notes */
        if (selectedStyleGuide.notes){
            messages.push(event.notes.trim() ? 'Event contains notes' : undefined);
        }

        /* Event Replies */
        if (selectedStyleGuide.reply){
            messages.push(event.reply.trim() ? 'Event contains replies' : undefined);
        }

        /* Event Notes Done */
        if (selectedStyleGuide.notesDone){
            messages.push(event.notes.trim() && !event.notesCheck ? 'Event contains notes that are incomplete' : undefined);
        }

        /* Event Replies Done */
        if (selectedStyleGuide.replyDone){
            messages.push(event.reply.trim() && !event.replyCheck ? 'Event contains replies that are incomplete' : undefined);
        }

        /* Event tags */
        if (selectedStyleGuide.tags){
            messages.push(event.tags.length > 0 ? 'Event contains tags' : undefined);
        }

        /* Event tags */
        if (selectedStyleGuide.forced){
            messages.push(event.forced ? 'Forced Subtitle Event' : undefined);
        }

        error.messages = messages.filter(message =>{
            return message;
        });

        error.messages.length > 0 ? errors.push(error) : null;
    });

    highlight ? enableHighlighting() : null;

    toast.push(`${errors.length} errors found`, {classes: ['toast-info']});
}

function checkForFormatting(htmlString, specificTag, full = true){
    try {
        const tagRegex = new RegExp(`<${specificTag}[^>]*>.*?</${specificTag}>`, 'i');
        if (!tagRegex.test(htmlString)) {
            return false;
        }
        console.log("-------------");
        console.log(htmlString);
        let cleaned = htmlString.replace(new RegExp(`<(?!/?${specificTag})[^>]+>`, 'g'), '');
        console.log(cleaned);
        // Remove the specific tag and its content
        let withoutSpecific = cleaned.replace(new RegExp(`<${specificTag}.*?</${specificTag}>`, 'g'), '');
        console.log(withoutSpecific);
        // Remove all whitespace
        let textLeft = withoutSpecific.replace(/\s/g, '');
        console.log(textLeft);
        if (full) {
            return textLeft.length === 0;
        } else {  // partial
            return textLeft.length > 0;
        }
    } catch(err){
        console.error(err.message);
        return false;
    }    
}

function ignoreError(){
    let errorIndex;
    errors = errors.filter((error, count, allErrors) => {
        if (error.id !== selectedError.id){
            return true;
        } else {
            errorIndex = count;
        }
    });

    if (errors[errorIndex]){
        selectedError = errors[errorIndex];
        selectEvent()
    } else {
        selectedError = undefined;
    }

    highlight ? enableHighlighting() : null;    
}

function selectAllErrors(){
    $eventGroupState[$projectState.selected].selected = errors.map(error =>{
        return error.index-1;
    });
}

function reloadPanel(){
    clearErrors();
    reloadStyleGuides();
}

function disableHighlighting(){
    highlight = false;
    $highlightedEventState = {
        enabled : false,
        events : []
    };
}

function enableHighlighting(){
    highlight = true;
    $highlightedEventState = {
        enabled : true,
        events : errors.map(error => {return error.id})
    }
}

function clearErrors(){
    selectedError = undefined;
    errors = [];
    $highlightedEventState = {
        enabled : false,
        events : []
    };
}

function selectEvent(){
    if (selectedError && selectedError.id){
        let eventIndex = $eventGroupState[$projectState.selected].events.findIndex(event => {
            return event.id === selectedError.id;
        });     
        
        if (!eventListElement){
            eventListElement = document.getElementById("EventList")
        }

        try {
            eventListElement.scrollTo(0, eventIndex*230);  
        } catch (err) {
            eventListElement = document.getElementById("EventList");
            eventListElement.scrollTo(0, eventIndex*230);  
        }
         
        $eventGroupState[$projectState.selected].selected = [eventIndex];

        if ($lockState.video && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events[eventIndex] && $eventGroupState[$projectState.selected].events[eventIndex].start >= 0){
            player.currentTime = $eventGroupState[$projectState.selected].events[eventIndex].start;
        }
    }
}

function exportQcReport(){
    toast.push("Exporting QC results...", {classes: ['toast-info']});

    let csvArray = []     
    errors.forEach(error =>{
        error.messages.forEach(message =>{
            csvArray.push({
                'Style Guide' : selectedStyleGuide.name,
                'UUID' : error.id,
                'Index' : error.index,
                'Start' : $eventGroupState[$projectState.selected].events[error.index-1].start,
                'End' : $eventGroupState[$projectState.selected].events[error.index-1].end,
                'Start SMPTE' : tcLib.secToTc($eventGroupState[$projectState.selected].events[error.index-1].start, $projectState.frameRate, $projectState.dropFrame),
                'End SMPTE' : tcLib.secToTc($eventGroupState[$projectState.selected].events[error.index-1].end || 0, $projectState.frameRate, $projectState.dropFrame),
                'Text' : error.text,
                'Error' : message
            });
        });
    });

    let csv = Papa.unparse(JSON.stringify(csvArray));    

    let fileBlob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
    });

    saveAs(fileBlob, `qc_report_${new Date().toString()}.csv`, {
        autoBom: true
    });
}

/* TESTS */
function eventLineTest(html, maxLines){
    if(convertToPlainText(html).trim().length === 0){
        return "Event text is empty";
    }

    if (convertToPlainText(html).trim() === "<br>"){
        return "Event text is empty";
    }

    let lineCount = getLineCount(html)
    if (lineCount > maxLines){
        return `Line count (${lineCount}) exceeds max line count of ${maxLines}`
    } else {
        return;
    }
}

function totalCharTest(html, totalMaxChars){
    if (!totalMaxChars){
        return;
    }
    
    let plainText = convertToPlainText(html, "");
    let failed = false;

    if(plainText.trim().length === 0){
        return "Event text is empty";
    }

    if (plainText.length > totalMaxChars){
        failed = true; 
    }

    if (failed){
        return "Maximum character count of line is higher than "+ totalMaxChars;
    }else {
        return;
    }
}

function eventCharTest(html, maxChars){
    let plainText = convertToPlainText(html);
    let failed = false;

    if(plainText.trim().length === 0){
        return "Event text is empty";
    }

    plainText.split("\n").forEach(line =>{
        if (line.length > maxChars){
            failed = true; 
        }
    });

    if (failed){
        return "Maximum character count of line is higher than "+maxChars;
    }else {
        return;
    }
}

function eventDurationTest(start, end, min, max){
    if (isNaN(start)){
        return "Start time is not set";
    }

    if (isNaN(end)){
        return "End time is not set";
    }

    let duration = end.toFixed(2) - start.toFixed(2);

    if (isNaN(duration)){
        return "Start or end time is not set";
    }

    if (duration > parseFloat(max)){
        return `Event duration (${duration.toFixed(2)}) is greater than ${parseFloat(max)} seconds`;
    } else if (duration < parseFloat(min)){
        return `Event duration (${duration.toFixed(2)}) is less than ${parseFloat(min)} seconds`;
    } else {
        return;
    }    
}

function eventCpsTest(start, end, html, min, max){
    if (isNaN(start)){
        return "Start time is not set";
    }

    if (isNaN(end)){
        return "End time is not set";
    }
    
    let duration = end - start.toFixed(2);
    let plainText = convertToPlainText(html);

    if(plainText.trim().length === 0){
        return "Event text is empty";
    }

    let lineInfo = []
    plainText.split("\n").forEach(line => {
        lineInfo.push(decode(line).length);
    });

    /* Total Characters */
    let totalChars = lineInfo.reduce((partialSum, a) => partialSum + a, 0);
    let cps = (totalChars / duration).toFixed(2);

    // console.log(plainText, totalChars, cps);
    
    if (cps > max){
        return `Reading rate (${cps}) is greater than ${max}`
    } else if (cps < min){
        return `Reading rate (${cps}) is less than ${min}`
    } else {
        return;
    }    
}

function eventWpmTest(start, end, html, min, max){
    if (isNaN(start)){
        return "Start time is not set";
    }

    if (isNaN(end)){
        return "End time is not set";
    }

    let duration = end - start.toFixed(2);
    let plainText = convertToPlainText(html);

    if(plainText.trim().length === 0){
        return "Event text is empty";
    }

    let totalWords = plainText.split(/\s/gm).length;
    let wpm = ((totalWords / duration) * 60).toFixed(2);

    if (wpm > max || wpm < min){
        return `Words per minute is ${wpm}. Min and max WPM is set to ${min}/${max}`
    } else {
        return;
    }
}

function eventGapTest(eventAEnd, eventBStart, min, max){
    if (isNaN(eventAEnd)){
        return "Failed Gap Test: End Timecode is not set";
    }

    if (isNaN(eventBStart)){
        return "Failed Gap Test: Start timecode of next event is not set";
    }

    let tcStart = tcLib.secToFrames(eventBStart, $projectState.frameRate);
    let tcEnd = tcLib.secToFrames(eventAEnd, $projectState.frameRate);

    let gap = tcStart - tcEnd;
    if (gap > parseInt(max)){
        return `The frame gap (${gap}) is larger than ${parseInt(max)} frames`
    } else if (parseInt(gap) < min){
        return `The frame gap (${gap}) is less then ${parseInt(min)} frames`
    } else {
        return;
    }
}

function overlapTest(eventAEnd, eventBStart){
    if (isNaN(eventAEnd)){
        return "Failed Overlap Test: End Timecode is not set";
    }

    if (isNaN(eventBStart)){
        return "Failed Overlap Test: Start timecode of next event is not set";
    }

    if (eventBStart < eventAEnd){
        return "Failed Overlap Test: Event overlap found"
    }

    return;
}

function detectIllegalChars(html, lib){
    let text = convertToPlainText(html, "");
    let illegalChars = text.split("").find(char =>{
        if (char.charCodeAt(0) === 32){
            return;
        }

        return lib.indexOf(char) === -1;
    });
    
    if (illegalChars && illegalChars.length > 0){
        console.log(illegalChars);
        return `Event contains illegal characters (${illegalChars[0]})`;
    }

    return;
}

function positionTest(event,y,x,positionName){
    if (event.xPos === x && event.yPos === y){
        return `Event is positioned ${positionName}`
    } else {
        return;
    }
}

function checkSubtitleFit(html, maxChars){
    let totalChars = 0;
    let plainText = convertToPlainText(html);
    let textLines = plainText.split("\n");
    if (textLines.length === 1){
        return;
    }

    textLines.forEach(line =>{
        totalChars += line.trim().length;
    });

    if (totalChars <= maxChars){
        return "Event text can fit on one line";
    }

    return;
}

function checkLeadingSpaces(html){
    //replace all tab characters:
    html = html.replace(/\t/g, "");

    if (html.match(/<p[^>]*>\s|\s<\/p>/)) {
        return "Leading or trailing spaces found";
    }

    return;
}

function checkWhiteSpace(html){
    if (html.match(/\s{2,}/)){
        return "Leading or trailing spaces found";
    }

    return;
}

function checkBlankLines(html){
    if(html.match(/><br><\/p>/)){
        return "Event text contains empty lines"
    }
}
</script>

<form>
    {#await gettingRecords}
    <div class="d-flex justify-content-center align-items-center p-3">
        <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
    </div>
    {:then}    
    <div class="btn-group mb-2" role="group" aria-label="Small button group">
        <div class="btn-group" role="group">
            <select class="form-select form-select-sm" bind:value={selectedStyleGuide} on:change={() => clearErrors()} title="Selected Style Guide">
                {#each styleGuideList as guide (guide.id)}
                <option value={guide} selected={selectedStyleGuide.id === guide.id}>{guide.name}</option>
                {/each}
                <option selected={!selectedStyleGuide.id}>None</option>
            </select>       
        </div>
        
        <button type="button" class="btn btn-light {$eventGroupState[$projectState.selected] ? '' : 'disabled'}" title="Start Review" on:click="{runReview}"><i class="bi bi-play-fill"></i> Start</button>
        <button type="button" class="btn btn-light {selectedError ? '' : 'disabled'}" title="Ignore Event" on:click="{ignoreError}"><i class="bi bi-x"></i> Ignore</button>
        <button type="button" on:click={exportQcReport} class="btn btn-light {errors.length > 0 ? '' : 'disabled'}"><i class="bi bi-download"></i> Report</button>

        <button type="button" title="Enable/Disable Filter View" class="btn btn-light" on:click={() => {highlight ? disableHighlighting() : enableHighlighting()}}>
            <i class="bi {highlight ? 'bi-funnel-fill text-primary' : 'bi-funnel'}"></i>
        </button>
        <button type="button" title="Select all Events with errors" class="btn btn-light {errors.length > 0 ? '' : 'disabled'}" on:click={() => {selectAllErrors()}}>
            <i class="bi bi-check-all"></i>
        </button>
        <button type="button" class="btn btn-light" title="Reset Panel" on:click="{() => reloadPanel()}"><i class="bi bi-arrow-clockwise"></i></button>
    </div>
    <button type="button" class="btn btn-light btn-sm float-end" on:click={() => modalState.showModal("styleGuideManager")}>
        <i class="bi bi-three-dots-vertical"></i>
    </button>
    
    {#if selectedStyleGuide.id}
    <div class="row mb-0">
        <div class="col-6">
            <label class="form-label" for="Event List">Failed Events 
                {#if errors.length > 0}
                    <span class="badge bg-danger align-middle">{errors.length}</span>
                {/if}
            </label>
            <select class="form-select form-select-sm resize-vertical" size="6" bind:value={selectedError} on:change={selectEvent}>
                {#each errors as error (error.id)}
                    <option value={error}>{error.index}. {error.text.substring(0,42)}</option>
                {/each}
            </select>
        </div>
        <div class="col-6">
            <label class="form-label" for="Event List">Errors</label>
            <select class="form-select form-select-sm disabled resize-vertical" size="6">
                {#if selectedError && selectedError.messages}
                    {#each selectedError.messages as message}
                        <option title="{message}">{message}</option>
                    {/each}
                {/if}
            </select>
        </div>
    </div>    
    {:else}
    <p class="text-muted my-5 text-center">Select a style guide from the list above to begin.</p>
    {/if}
    {/await}
</form>

<style>
    button {
        font-size: 0.8vw;
    }
</style>