<script>
import { onMount } from 'svelte';
import {
    environment
} from "@app/store/envStore.js";
import { toast } from '@zerodevx/svelte-toast';

let backupDestination = $environment.online ? "Cloud" : "Local"; //Cloud or Local
let targetFolder = ""; //Target Folder for Local Backup
let numberOfBackups = 5; //Number of backups to keep
let backupInterval = 15; //Backup Interval in minutes
let enableAutomaticSave = false; //Enable Automatic Save
let automaticSaveInterval = 5; //Automatic Save Interval in minutes

onMount(() => {
    //Load settings from local storage
    let settings = JSON.parse(localStorage.getItem("cc-automatic-backup-settings"));
    if(settings){
        backupDestination = $environment.online ?  settings.backupDestination : "Local";
        targetFolder = settings.targetFolder || "";
        numberOfBackups = Math.min(5, settings.numberOfBackups);
        backupInterval =  Math.max(15, settings.backupInterval);
        enableAutomaticSave = settings.enableAutomaticSave || false;
        automaticSaveInterval = Math.max(5, settings.automaticSaveInterval);
    }
});

//Save Changes to Local Storage
function saveChanges(){
    //Save changes to local storage
    localStorage.setItem("cc-automatic-backup-settings", JSON.stringify({
        backupDestination: backupDestination,
        targetFolder : targetFolder,
        numberOfBackups : numberOfBackups,
        backupInterval : backupInterval,
        enableAutomaticSave : enableAutomaticSave,
        automaticSaveInterval : Math.max(5, automaticSaveInterval)
    }));

    toast.push("Please restart the application for changes to take effect.", {
        classes: ["toast-warning"]
    });
    toast.push("Automatic backup settings updated.", {
        classes: ["toast-success"]
    });
    
}

function selectTargetFolder() {
    ipcRenderer.invoke('selectFolder', {
        title: "Automatic Backup - Select Folder",
        properties: ["openDirectory", "createDirectory"]
    }).then((path) => {
        if (!path.canceled) {
            targetFolder = path.filePaths[0];
        }
    });
}
</script>
<form id="automaticBackupForm" on:submit|preventDefault="{saveChanges}" aria-label="Automatic Backup Settings">   
    <div class="mb-4">
        <h5 class="text-warning">Automatic Save Settings</h5>
        <p class="lead">Enable or disable automatic saving for your project. Automatic saving will overwrite the current file. To restore to a previous version please use Snapshots (see below).</p>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" bind:checked={enableAutomaticSave} id="enableAutomaticSaveSwitch">
            <label class="form-check label" for="enableAutomaticSaveSwitch">Enable Automatic Save</label>
        </div>
        <div class="mb-3">
            <label class="form-label" for="Automatic Save Interval">Automatic Save Interval (minutes)</label>
            <input type="number" class="form-control" bind:value={automaticSaveInterval} min="5" max="999" disabled={!enableAutomaticSave}>
        </div>
    </div>
    <div class="mb-4">
        <h5 class="text-warning">Automatic Snapshot Settings</h5>
        <p class="lead">Event Group snapshots are restore points that are saved periodically. They allow users to go back to a specific time in their project.</p>
        <label class="form-label" for="Project Type">Backup Destination</label>
        <select id="backupDestinationSelect" class="form-select" bind:value={backupDestination} aria-label="Backup Destination">
            {#if $environment.online}
                <option>Cloud</option>
            {/if}
            {#if $environment.electron}
                <option>Local</option>
            {/if}
        </select>
    </div>    
    {#if backupDestination === "Local"}
    <div class="p-3 mb-3 border rounded">
        <button id="selectTargetFolderButton" type="button" class="btn btn-info text-white" on:click="{selectTargetFolder}" aria-label="Select target backup folder"><i class="bi bi-folder"></i> Target Folder</button>
        <p class="form-text text-muted text-truncate mt-2 mb-0">{targetFolder || ""}</p>
        {#if !targetFolder}
            <p class="form-text text-muted m-0">Select a target location that is used for automatic backups.</p>
        {/if}
    </div>
    <div class="mb-3">
        <label class="form-label" for="Number of Backups">Number of Backups to Keep</label>
        <input type="number" class="form-control" bind:value={numberOfBackups} min="1" max="10">
    </div>
    {/if}    
    <div class="mb-3">
        <label class="form-label" for="Backup Interval">Backup Interval (minutes)</label>
        <input type="number" class="form-control" bind:value={backupInterval} min="10" max="999">
    </div>
    
    <button id="saveBackupSettingsButton" class="btn btn-primary float-end" type="button" on:click={saveChanges} aria-label="Save automatic backup settings">Save Settings</button>
</form>