<script>
    export let errorMsg = "";
</script>
{#if errorMsg}
<button
  id="errorMsgBtn"
  type="button"
  class="btn btn-link text-custom-medium"
  aria-label="Show real-time errors"
  aria-live="polite"
  title="{errorMsg}">
    <i class="bi bi-exclamation-diamond-fill text-danger"></i>
</button>
{/if}

<style>
    button {
        padding : 2px;
        margin : 0;
    }
</style>