export default {
  "Arabic": {
    "male": [{
      "provider": "google",
      "name": "ar-XA-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "ar-XA"
    }, {
      "provider": "google",
      "name": "ar-XA-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "ar-XA"
    }],
    "female": [{
      "provider": "google",
      "name": "ar-XA-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ar-XA"
    }, {
      "provider": "google",
      "name": "ar-XA-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "ar-XA"
    }]
  },
  "Arabic - Gulf": {
    "male": [{
      "provider": "amazon",
      "name": "Zayd",
      "styles": ["default"],
      "gender": "male",
      "language": "ar-AE"
    }],
    "female": [{
      "provider": "amazon",
      "name": "Hala",
      "styles": ["default"],
      "gender": "female",
      "language": "ar-AE"
    }]
  },
  "Bengali": {
    "male": [{
      "provider": "google",
      "name": "bn-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "bn-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "bn-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "bn-IN"
    }]
  },
  "Catalan": {
    "male": [],
    "female": [{
      "provider": "amazon",
      "name": "Arlet",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "ca-ES"
    }]
  },
  "Czech": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "cs-CZ-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "cs-CZ"
    }]
  },
  "Danish": {
    "male": [{
      "provider": "google",
      "name": "da-DK-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "da-DK"
    }],
    "female": [{
      "provider": "google",
      "name": "da-DK-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "da-DK"
    }, {
      "provider": "google",
      "name": "da-DK-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "da-DK"
    }, {
      "provider": "google",
      "name": "da-DK-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "da-DK"
    }]
  },
  "Dutch - Belgium": {
    "male": [{
      "provider": "google",
      "name": "nl-BE-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "nl-BE"
    }],
    "female": [{
      "provider": "google",
      "name": "nl-BE-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "nl-BE"
    }, {
      "provider": "amazon",
      "name": "Lisa",
      "styles": ["default"],
      "gender": "female",
      "language": "nl-BE"
    }]
  },
  "Dutch - Netherlands": {
    "male": [{
      "provider": "google",
      "name": "nl-NL-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "nl-NL"
    }, {
      "provider": "google",
      "name": "nl-NL-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "nl-NL"
    }],
    "female": [{
      "provider": "google",
      "name": "nl-NL-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "nl-NL"
    }, {
      "provider": "google",
      "name": "nl-NL-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "nl-NL"
    }, {
      "provider": "google",
      "name": "nnl-NL-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "nl-NL"
    }, {
      "provider": "amazon",
      "name": "Laura",
      "styles": ["default"],
      "gender": "female",
      "language": "nl-NL"
    }]
  },
  "ElevenLabs": {
    "male": [],
    "female": []
  },
  "English - Australia": {
    "male": [{
      "provider": "google",
      "name": "en-AU-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "google",
      "name": "en-AU-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-DarrenNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-DuncanNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-KenNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-NeilNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-TimNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-WilliamNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-AU"
    }],
    "female": [{
      "provider": "google",
      "name": "en-AU-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "google",
      "name": "en-AU-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "amazon",
      "name": "Olivia",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-AnnetteNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-CarlyNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-ElsieNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-FreyaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-JoanneNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-KimNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-NatashaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }, {
      "provider": "microsoft",
      "name": "en-AU-TinaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-AU"
    }]
  },
  "English - India": {
    "male": [{
      "provider": "google",
      "name": "en-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "en-IN"
    }, {
      "provider": "google",
      "name": "en-IN-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "en-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "en-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "en-IN"
    }, {
      "provider": "google",
      "name": "en-IN-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "en-IN"
    }]
  },
  "English - New Zealand": {
    "male": [],
    "female": [{
      "provider": "amazon",
      "name": "Aria",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-NZ"
    }]
  },
  "English - South African": {
    "male": [],
    "female": [{
      "provider": "amazon",
      "name": "Ayanda",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-AF"
    }]
  },
  "English - UK": {
    "male": [{
      "provider": "google",
      "name": "en-GB-Studio-B",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "google",
      "name": "en-GB-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "google",
      "name": "en-GB-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "amazon",
      "name": "Brian",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-AlfieNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-ElliotNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-EthanNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-NoahNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-OliverNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-RyanNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-ThomasNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-GB"
    }],
    "female": [{
      "provider": "google",
      "name": "en-GB-Studio-C",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "google",
      "name": "en-GB-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "google",
      "name": "en-GB-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "google",
      "name": "en-GB-Wavenet-F",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "amazon",
      "name": "Amy",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "amazon",
      "name": "Emma",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-AbbiNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-BellaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-HollieNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-LibbyNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-MaisieNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-OliviaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }, {
      "provider": "microsoft",
      "name": "en-GB-SoniaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-GB"
    }]
  },
  "English - US": {
    "male": [{
      "provider": "google",
      "name": "en-US-Studio-Q",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-A",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-D",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-I",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-J",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-A",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-I",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-J",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Joey",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Justin",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Kevin",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Matthew",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Gregory",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-AndrewNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-BrianNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-DavisNeural",
      "styles": ["default", "angry", "chat", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-ChristopherNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-EricNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-GuyNeural",
      "styles": ["default", "angry", "cheerful", "excited", "friendly", "hopeful", "newscast", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-JasonNeural",
      "styles": ["default", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-TonyNeural",
      "styles": ["default", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-AIGenerate1Neural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-BrandonNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "en-US"
    }],
    "female": [{
      "provider": "google",
      "name": "en-US-Studio-O",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-C",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-D",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-E",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-F",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Neural2-H",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-F",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-G",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "google",
      "name": "en-US-Wavenet-H",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Ruth",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Danielle",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Ivy",
      "styles": ["default"],
      "ntts": true,
      "child": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Joanna",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Kendra",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Kimberly",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "amazon",
      "name": "Salli",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-AIGenerate2Neural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-EmmaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-AriaNeural",
      "styles": ["default", "angry", "chat", "cheerful", "customerservice", "empathetic", "excited", "friendly", "hopeful", "narration-professional", "newscast-casual", "newscast-formal", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-JaneNeural",
      "styles": ["default", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-JennyNeural",
      "styles": ["default", "angry", "assistant", "chat", "cheerful", "customerservice", "excited", "friendly", "hopeful", "newscast", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-NancyNeural",
      "styles": ["default", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "female",
      "language": "en-US"
    }, {
      "provider": "microsoft",
      "name": "en-US-SaraNeural",
      "styles": ["default", "angry", "cheerful", "excited", "friendly", "hopeful", "sad", "shouting", "terrified", "unfriendly", "whispering"],
      "gender": "female",
      "language": "en-US"
    }]
  },
  "Filipino": {
    "male": [{
      "provider": "google",
      "name": "fil-PH-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "fil-PH"
    }, {
      "provider": "google",
      "name": "fil-PH-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "fil-PH"
    }],
    "female": [{
      "provider": "google",
      "name": "fil-PH-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "fil-PH"
    }, {
      "provider": "google",
      "name": "fil-PH-Wavenet-B",
      "styles": ["default"],
      "gender": "female",
      "language": "fil-PH"
    }]
  },
  "Finnish": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "fi-FI-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "fi-FI"
    }]
  },
  "French - Canada": {
    "male": [{
      "provider": "google",
      "name": "fr-CA-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-CA"
    }, {
      "provider": "google",
      "name": "fr-CA-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-CA"
    }, {
      "provider": "microsoft",
      "name": "fr-CA-JeanNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-CA"
    }, {
      "provider": "microsoft",
      "name": "fr-CA-AntoineNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-CA"
    }, {
      "provider": "microsoft",
      "name": "fr-CA-ThierryNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-CA"
    }],
    "female": [{
      "provider": "google",
      "name": "fr-CA-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-CA"
    }, {
      "provider": "google",
      "name": "fr-CA-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-CA"
    }, {
      "provider": "amazon",
      "name": "Gabrielle",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "fr-CA"
    }, {
      "provider": "microsoft",
      "name": "fr-CA-SylvieNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-CA"
    }]
  },
  "French - France": {
    "male": [{
      "provider": "google",
      "name": "fr-FR-Studio-D",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "google",
      "name": "fr-FR-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "google",
      "name": "fr-FR-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-AlainNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-ClaudeNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-HenriNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-JeromeNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-MauriceNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-YvesNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "fr-FR"
    }],
    "female": [{
      "provider": "google",
      "name": "fr-FR-Studio-A",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "google",
      "name": "fr-FR-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "google",
      "name": "fr-FR-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "google",
      "name": "fr-FR-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "amazon",
      "name": "Lea",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-BrigitteNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-CelesteNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-CoralieNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-DeniseNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-EloiseNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-JacquelineNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-JosephineNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-YvetteNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }, {
      "provider": "microsoft",
      "name": "fr-FR-VivienneNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "fr-FR"
    }]
  },
  "German": {
    "male": [{
      "provider": "google",
      "name": "de-DE-Studio-B",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "google",
      "name": "de-DE-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "google",
      "name": "de-DE-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "google",
      "name": "de-DE-Wavenet-E",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-BerndNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-ChristophNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-ConradNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-KasperNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-KillianNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-KlausNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-RalfNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }, {
      "provider": "amazon",
      "name": "Daniel",
      "styles": ["default"],
      "gender": "male",
      "language": "de-DE"
    }],
    "female": [{
      "provider": "google",
      "name": "de-DE-Studio-C",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "google",
      "name": "de-DE-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "google",
      "name": "de-DE-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "google",
      "name": "de-DE-Wavenet-F",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "amazon",
      "name": "Vicki",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-SeraphinaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-AmalaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-ElkeNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-GiselaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-KatjaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-KlarissaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-LouisaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-MajaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "microsoft",
      "name": "de-DE-TanjaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }, {
      "provider": "amazon",
      "name": "Vicki",
      "styles": ["default"],
      "gender": "female",
      "language": "de-DE"
    }]
  },
  "German - Austrian": {
    "male": [],
    "female": [{
      "provider": "amazon",
      "name": "Hannah",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "de-AT"
    }]
  },
  "Greek": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "el-GR-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "el-GR"
    }]
  },
  "Gujarati": {
    "male": [{
      "provider": "google",
      "name": "gu-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "gu-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "gu-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "gu-IN"
    }]
  },
  "Hindi": {
    "male": [{
      "provider": "google",
      "name": "hi-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "hi-IN"
    }, {
      "provider": "google",
      "name": "hi-IN-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "hi-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "hi-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "hi-IN"
    }, {
      "provider": "google",
      "name": "hi-IN-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "hi-IN"
    }]
  },
  "Hungarian": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "hu-HU-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "hu-HU"
    }]
  },
  "Indonesian": {
    "male": [{
      "provider": "google",
      "name": "id-ID-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "id-ID"
    }, {
      "provider": "google",
      "name": "id-ID-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "id-ID"
    }],
    "female": [{
      "provider": "google",
      "name": "id-ID-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "id-ID"
    }, {
      "provider": "google",
      "name": "id-ID-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "id-ID"
    }]
  },
  "Italian": {
    "male": [{
      "provider": "google",
      "name": "it-IT-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "google",
      "name": "it-IT-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-GiuseppeNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-BenignoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-CalimeroNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-CataldoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-DiegoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-GianniNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-LisandroNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-RinaldoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }, {
      "provider": "amazon",
      "name": "Adriano",
      "styles": ["default"],
      "gender": "male",
      "language": "it-IT"
    }],
    "female": [{
      "provider": "google",
      "name": "it-IT-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "google",
      "name": "it-IT-Wavenet-B",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "amazon",
      "name": "Bianca",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-ElsaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-FabiolaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-FiammaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-ImeldaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-IrmaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-IsabellaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-PalmiraNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }, {
      "provider": "microsoft",
      "name": "it-IT-PierinaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "it-IT"
    }]
  },
  "Japanese": {
    "male": [{
      "provider": "google",
      "name": "ja-JP-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "ja-JP"
    }, {
      "provider": "google",
      "name": "ja-JP-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "ja-JP"
    }, {
      "provider": "amazon",
      "name": "Takumi",
      "styles": ["default"],
      "ntts": true,
      "gender": "male",
      "language": "ja-JP"
    }],
    "female": [{
      "provider": "google",
      "name": "ja-JP-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ja-JP"
    }, {
      "provider": "google",
      "name": "ja-JP-Wavenet-B",
      "styles": ["default"],
      "gender": "female",
      "language": "ja-JP"
    }, {
      "provider": "microsoft",
      "name": "ja-JP-NanamiNeural",
      "styles": ["default", "chat", "cheerful", "customerservice"],
      "gender": "female",
      "language": "ja-JP"
    }]
  },
  "Kannada": {
    "male": [{
      "provider": "google",
      "name": "kn-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "kn-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "kn-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "kn-IN"
    }]
  },
  "Korean": {
    "male": [{
      "provider": "google",
      "name": "ko-KR-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "ko-KR"
    }, {
      "provider": "google",
      "name": "ko-KR-Wavenet-D",
      "styles": ["default"],
      "language": "ko-KR"
    }, {
      "provider": "microsoft",
      "name": "ko-KR-HyunsuNeural",
      "styles": ["default"],
      "language": "ko-KR"
    }, {
      "provider": "microsoft",
      "name": "ko-KR-InJoonNeural",
      "styles": ["default"],
      "language": "ko-KR"
    }],
    "female": [{
      "provider": "google",
      "name": "ko-KR-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ko-KR"
    }, {
      "provider": "google",
      "name": "ko-KR-Wavenet-B",
      "styles": ["default"],
      "gender": "female",
      "language": "ko-KR"
    }, {
      "provider": "amazon",
      "name": "Seoyeon",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "ko-KR"
    }]
  },
  "Malay": {
    "male": [{
      "provider": "google",
      "name": "ms-MY-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "ms-MY"
    }, {
      "provider": "google",
      "name": "ms-MY-Wavenet-D",
      "styles": ["default"],
      "language": "ms-MY"
    }],
    "female": [{
      "provider": "google",
      "name": "ms-MY-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ms-MY"
    }, {
      "provider": "google",
      "name": "ms-MY-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "ms-MY"
    }]
  },
  "Malayalam": {
    "male": [{
      "provider": "google",
      "name": "ml-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "ml-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "ml-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ml-IN"
    }]
  },
  "Mandarin Chinese": {
    "male": [{
      "provider": "google",
      "name": "cmn-CN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "cmn-CN"
    }, {
      "provider": "google",
      "name": "cmn-CN-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "cmn-CN"
    }, {
      "provider": "google",
      "name": "cmn-TW-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "cmn-CN"
    }, {
      "provider": "google",
      "name": "cmn-TW-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "cmn-CN"
    }],
    "female": [{
      "provider": "google",
      "name": "cmn-CN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "cmn-CN"
    }, {
      "provider": "google",
      "name": "cmn-CN-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "cmn-CN"
    }, {
      "provider": "google",
      "name": "cmn-TW-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "cmn-CN"
    }, {
      "provider": "microsoft",
      "name": "zh-CN-YunjieNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "cmn-CN"
    }, {
      "provider": "microsoft",
      "name": "zh-CN-XiaoxiaoNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "cmn-CN"
    }]
  },
  "Norwegian": {
    "male": [{
      "provider": "google",
      "name": "nb-NO-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "nb-NO"
    }, {
      "provider": "google",
      "name": "nb-NO-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "nb-NO"
    }],
    "female": [{
      "provider": "google",
      "name": "nb-NO-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "nb-NO"
    }, {
      "provider": "google",
      "name": "nb-NO-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "nb-NO"
    }, {
      "provider": "google",
      "name": "nb-no-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "nb-NO"
    }]
  },
  "Polish": {
    "male": [{
      "provider": "google",
      "name": "pl-PL-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "pl-PL"
    }, {
      "provider": "google",
      "name": "pl-PL-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "pl-PL"
    }],
    "female": [{
      "provider": "google",
      "name": "pl-PL-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "pl-PL"
    }, {
      "provider": "google",
      "name": "pl-PL-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "pl-PL"
    }, {
      "provider": "google",
      "name": "pl-PL-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "pl-PL"
    }]
  },
  "Portuguese - Brazil": {
    "male": [{
      "provider": "google",
      "name": "pt-BR-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "pt-BR"
    }],
    "female": [{
      "provider": "google",
      "name": "pt-BR-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "pt-BR"
    }, {
      "provider": "amazon",
      "name": "Camila",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "pt-BR"
    }, {
      "provider": "amazon",
      "name": "Victoria",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "pt-BR"
    }, {
      "provider": "microsoft",
      "name": "pt-BR-ThalitaNeural",
      "styles": ["default", "calm"],
      "gender": "female",
      "language": "pt-BR"
    }, {
      "provider": "microsoft",
      "name": "pt-BR-FranciscaNeural",
      "styles": ["default", "calm"],
      "gender": "female",
      "language": "pt-BR"
    }]
  },
  "Portuguese - Portugal": {
    "male": [{
      "provider": "google",
      "name": "pt-PT-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "pt-PT"
    }, {
      "provider": "google",
      "name": "pt-PT-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "pt-PT"
    }],
    "female": [{
      "provider": "google",
      "name": "pt-PT-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "pt-PT"
    }, {
      "provider": "google",
      "name": "pt-PT-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "pt-PT"
    }, {
      "provider": "amazon",
      "name": "Ines",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "pt-PT"
    }]
  },
  "Punjabi": {
    "male": [{
      "provider": "google",
      "name": "pa-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "pa-IN"
    }, {
      "provider": "google",
      "name": "pa-IN-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "pa-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "pa-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "pa-IN"
    }, {
      "provider": "google",
      "name": "pa-IN-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "pa-IN"
    }]
  },
  "Romanian": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "ro-RO-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ro-RO"
    }]
  },
  "Russian": {
    "male": [{
      "provider": "google",
      "name": "ru-RU-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "ru-RU"
    }, {
      "provider": "google",
      "name": "ru-RU-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "ru-RU"
    }],
    "female": [{
      "provider": "google",
      "name": "ru-RU-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ru-RU"
    }, {
      "provider": "google",
      "name": "ru-RU-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "ru-RU"
    }, {
      "provider": "google",
      "name": "ru-RU-Wavenet-E",
      "styles": ["default"],
      "gender": "female",
      "language": "ru-RU"
    }]
  },
  "Slovak": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "sk-SK-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "sk-SK"
    }]
  },
  "Spanish - Spain": {
    "male": [{
      "provider": "google",
      "name": "es-ES-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-AlvaroNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-ArnauNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-DarioNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-EliasNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-NilNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-SaulNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-TeoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }, {
      "provider": "amazon",
      "name": "Sergio",
      "styles": ["default"],
      "gender": "male",
      "language": "es-ES"
    }],
    "female": [{
      "provider": "google",
      "name": "es-ES-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "google",
      "name": "es-ES-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "amazon",
      "name": "Lucia",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-XimenaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-AbrilNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-ElviraNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-EstrellaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-IreneNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-LaiaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-LiaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-TrianaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }, {
      "provider": "microsoft",
      "name": "es-ES-VeraNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-ES"
    }]
  },
  "Spanish - Mexico": {
    "male": [{
      "provider": "microsoft",
      "name": "es-MX-CecilioNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-GerardoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-JorgeNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-LibertoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-LucianoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-PelayoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-YagoNeural",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }, {
      "provider": "amazon",
      "name": "Andrés",
      "styles": ["default"],
      "gender": "male",
      "language": "es-MX"
    }],
    "female": [{
      "provider": "amazon",
      "name": "Mia",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-BeatrizNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-CandelaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-CarlotaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-DaliaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-LarissaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-MarinaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-NuriaNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }, {
      "provider": "microsoft",
      "name": "es-MX-RenataNeural",
      "styles": ["default"],
      "gender": "female",
      "language": "es-MX"
    }]
  },
  "Spanish - US": {
    "male": [{
      "provider": "google",
      "name": "es-US-Studio-B",
      "styles": ["default"],
      "gender": "male",
      "language": "es-US"
    }, {
      "provider": "google",
      "name": "es-US-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "es-US"
    }, {
      "provider": "google",
      "name": "es-US-Wavenet-C",
      "styles": ["default"],
      "language": "es-US"
    }, {
      "provider": "google",
      "name": "es-US-Studio-B",
      "styles": ["default"],
      "language": "es-US"
    }],
    "female": [{
      "provider": "google",
      "name": "es-US-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "es-US"
    }, {
      "provider": "amazon",
      "name": "Lupe",
      "styles": ["default"],
      "ntts": true,
      "gender": "female",
      "language": "es-US"
    }]
  },
  "Swedish": {
    "male": [{
      "provider": "google",
      "name": "sv-SE-Wavenet-C",
      "styles": ["default"],
      "gender": "male",
      "language": "sv-SE"
    }, {
      "provider": "google",
      "name": "sv-SE-Wavenet-E",
      "styles": ["default"],
      "gender": "male",
      "language": "sv-SE"
    }],
    "female": [{
      "provider": "google",
      "name": "sv-SE-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "sv-SE"
    }, {
      "provider": "google",
      "name": "sv-SE-Wavenet-B",
      "styles": ["default"],
      "gender": "female",
      "language": "sv-SE"
    }, {
      "provider": "google",
      "name": "sv-SE-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "sv-SE"
    }, {
      "provider": "amazon",
      "name": "Elin",
      "styles": ["default"],
      "gender": "female",
      "language": "sv-SE"
    }]
  },
  "Tamil": {
    "male": [{
      "provider": "google",
      "name": "ta-IN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "ta-IN"
    }],
    "female": [{
      "provider": "google",
      "name": "ta-IN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "ta-IN"
    }]
  },
  "Turkish": {
    "male": [{
      "provider": "google",
      "name": "tr-TR-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "tr-TR"
    }, {
      "provider": "google",
      "name": "tr-TR-Wavenet-E",
      "styles": ["default"],
      "gender": "male",
      "language": "tr-TR"
    }],
    "female": [{
      "provider": "google",
      "name": "tr-TR-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "tr-TR"
    }, {
      "provider": "google",
      "name": "tr-TR-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "tr-TR"
    }, {
      "provider": "google",
      "name": "tr-TR-Wavenet-D",
      "styles": ["default"],
      "gender": "female",
      "language": "tr-TR"
    }]
  },
  "Ukrainian": {
    "male": [],
    "female": [{
      "provider": "google",
      "name": "uk-UA-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "uk-UA"
    }]
  },
  "Vietnamese": {
    "male": [{
      "provider": "google",
      "name": "vi-VN-Wavenet-B",
      "styles": ["default"],
      "gender": "male",
      "language": "vi-VN"
    }, {
      "provider": "google",
      "name": "vi-VN-Wavenet-D",
      "styles": ["default"],
      "gender": "male",
      "language": "vi-VN"
    }],
    "female": [{
      "provider": "google",
      "name": "vi-VN-Wavenet-A",
      "styles": ["default"],
      "gender": "female",
      "language": "vi-VN"
    }, {
      "provider": "google",
      "name": "vi-VN-Wavenet-C",
      "styles": ["default"],
      "gender": "female",
      "language": "vi-VN"
    }]
  }
};