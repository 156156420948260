export default (function applyWindowOffsets(eventGroup, win, type = "add") {
  eventGroup.events.forEach((event, index, events) => {
    if (type === "subtract") {
      //console.log("-----------------");
      //console.log(index + ". BEFORE DECODE: "+ event.yOffset);
      /* X */
      if (event.xPos === "end") {
        events[index].xOffset = Math.min(events[index].xOffset + win.xOffset, 0);
      } else if (event.xPos === "start") {
        events[index].xOffset = Math.max(events[index].xOffset - win.xOffset, 0);
      }

      /* Y */
      if (event.yPos === "end") {
        events[index].yOffset = Math.min(events[index].yOffset + win.yOffset, 0);
      } else if (event.yPos === "start") {
        events[index].yOffset = Math.max(events[index].yOffset - win.yOffset, 0);
      }
    } else {
      //console.log("-----------------");
      //console.log(index + ". BEFORE ENCODE: "+ event.yOffset);
      /* X */
      if (event.xPos === "end") {
        events[index].xOffset -= win.xOffset;
      } else if (event.xPos === "start") {
        events[index].xOffset += win.xOffset;
      }

      /* Y */
      if (event.yPos === "end") {
        events[index].yOffset -= win.yOffset;
      } else if (event.yPos === "start") {
        events[index].yOffset += win.yOffset;
      }
    }
    events[index].xOffset = parseInt(events[index].xOffset) || 0;
    events[index].yOffset = parseInt(events[index].yOffset) || 0;
    //console.log("AFTER: "+ event.yOffset);
  });
  return eventGroup;
});