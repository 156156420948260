<script>
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import { projectState } from "@app/store/projectStore.js";
	import { historyState } from "@app/store/historyStore.js";
    import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
	import removeEvent from "@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js";
	import autoFormat from '@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js';

	function removeLastWord(htmlString) {
		// Create a temporary div element
		const tempDiv = document.createElement("div");
		tempDiv.innerHTML = htmlString;

		// Get all the text nodes within the div
		const textNodes = getTextNodes(tempDiv);

		// Find the last word and its parent element
		let lastWord = "";
		let parentElement = null;
		for (let i = textNodes.length - 1; i >= 0; i--) {
			const node = textNodes[i];
			const words = node.nodeValue.trim().split(" ");

			if (words.length > 1) {
				lastWord = words.pop();
				node.nodeValue = words.join(" ");
				parentElement = node.parentNode;
				break;
			} else if (words.length === 1) {
				lastWord = words[0];
				node.parentNode.removeChild(node);
				parentElement = node.parentNode;
				break;
			}
		}

		// Convert the temporary div back to a string
		const updatedString = tempDiv.innerHTML;

		// Return both the word and the updated string
		return [lastWord, updatedString];
	}

	function getTextNodes(element) {
		const textNodes = [];

		function traverse(node) {
			if (node.nodeType === Node.TEXT_NODE) {
				textNodes.push(node);
			} else {
				for (const childNode of node.childNodes) {
					traverse(childNode);
				}
			}
		}

		traverse(element);
		return textNodes;
	}

	/* remove last word from event and add it to the beginning of the next event. */
	function shiftWordForward() {
		try {
			//Return if no event is selected or if the selected event is the last event
			if (!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === $eventGroupState[$projectState.selected].events.length - 1) return;
            
            let eventRemoved = false;
            let html = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text;
            let plainText = convertToPlainText(html);
            //Split text at spaces and line endings to get number of words
            let numberOfWords = plainText.split(/\s+/g).length;
			let lastWordFlag = numberOfWords === 1;
            let oStart = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start;
            let oEnd = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end;
            let oDuration = oEnd - oStart;

			const [lastWord, updatedString] = removeLastWord($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text);

			// console.log("Last Word:", lastWord);
			// console.log("String without Last Word:", updatedString);

			if ($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].text) {
				$eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].text = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].text.replace(/(<p[^>]*>)/, "$1" + lastWord + " ");

				//Auto Format the next event
				$eventGroupState[$projectState.selected] = autoFormat($eventGroupState[$projectState.selected], 5, $eventGroupState[$projectState.selected].maxChars, $eventGroupState[$projectState.selected].minDuration, false, [$eventGroupState[$projectState.selected].selected[0] + 1]);
			} else {
				$eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].text = `<p class="ql-align-center">${lastWord}</p>`;
			}

			quillEditor.clipboard.dangerouslyPasteHTML(updatedString);

			if (lastWordFlag) {
				$eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected], $eventGroupState[$projectState.selected].selected[0]);
				$eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0]];
                eventRemoved = true;
			}

            if (oDuration && oDuration > 0){
                let avgDurationOfWord = oDuration / numberOfWords;
                if (eventRemoved){
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start -= avgDurationOfWord;
                } else {
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end -= avgDurationOfWord;
                    if ($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].end > 0){
                        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1].start -= avgDurationOfWord;
                    }
                }         
            }


			historyState.insert({
				name: "shift word", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});
		} catch (err) {
			console.log(err, err.message);
		}
	}
</script>

<li class="nav-item" title="Shift Word Down">
	<a class="nav-link" href="#!/" class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === $eventGroupState[$projectState.selected].events.length - 1} on:click={shiftWordForward}><strong>w</strong><sub><i class="bi bi-arrow-down"></i></sub></a>
</li>
