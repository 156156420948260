import { writable } from 'svelte/store';

function loadTimingAndSyncSettingsFromLocalStorage() {
    let timingAndSyncSettings = JSON.parse(localStorage.getItem("cc-timing-and-sync-settings")) || {
        timingMode : "dual", //long or dual
        compensation : 0,
        startTimeOnly : false,
        shortcuts : {
            "showEvent" : "Ctrl+ArrowUp",
            "hideEvent" : "Ctrl+ArrowDown",
            "togglePanel" : ""
        }
    }

    return timingAndSyncSettings;
}

export const timingAndSyncSettingsState = writable(loadTimingAndSyncSettingsFromLocalStorage());

