<script>
import {
    historyState
} from '@app/store/historyStore.js';
import {authState} from '@app/store/authStore.js';
import {
    explorerContextState
} from '@app/store/explorerContextStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    environment
} from '@app/store/envStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    fontState
} from '@app/store/fontStore.js';
import {
    tick
} from "svelte";
/* Components */
import OfflineSaveIcon from "./menus/items/OfflineSaveIcon.svelte";
import OnlineSaveIcon from "./menus/items/OnlineSaveIcon.svelte";

import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import _Event from '@app/external/cc-lib/dist/classes/event.js';

/* Components */
import AddEvent from './controls/AddEvent.svelte';
import RemoveEvent from './controls/RemoveEvent.svelte';
import AlignLeft from './controls/AlignLeft.svelte';
import AlignCenter from './controls/AlignCenter.svelte';
import AlignRight from './controls/AlignRight.svelte';
import Copy from './controls/Copy.svelte';
import Cut from './controls/Cut.svelte';
import Paste from './controls/Paste.svelte';
import Split from './controls/Split.svelte';
import Merge from './controls/Merge.svelte';
import Expand from './controls/Expand.svelte';
import Compress from './controls/Compress.svelte';
import Position from './controls/Position.svelte';
import PositionLineUp from './controls/PositionLineUp.svelte';
import PositionLineDown from './controls/PositionLineDown.svelte';
import ShiftWordBack from './controls/ShiftWordBack.svelte';
import ShiftWordForward from './controls/ShiftWordForward.svelte';
import ShiftLineBack from './controls/ShiftLineBack.svelte';
import ShiftLineForward from './controls/ShiftLineForward.svelte';
import SnapToStart from './controls/SnapToStart.svelte';
import SnapToEnd from './controls/SnapToEnd.svelte';

async function undo() {
    historyState.undo();
    loadSnapshots();

    $eventGroupState = $eventGroupState;
}

function redo() {
    historyState.redo();
    loadSnapshots();

    $eventGroupState = $eventGroupState;
}

async function loadSnapshots() {
    /* workaround to ensure no components break that rely on selected events */
    let selectedEvents = $eventGroupState[$projectState.selected].selected;
    let currentQuillIndex = quillEditor ? quillEditor.getSelection() : 0;
    let quillEditorLength = quillEditor ? quillEditor.getLength() : 0;

    $eventGroupState[$projectState.selected].selected = [];
    await tick();

    $historyState.actions[$historyState.position - 1].snapshots.forEach(async (snapshot) => {
        switch (snapshot.store) {
            case "eventGroupState":
                $eventGroupState = JSON.parse(snapshot.value).map((eventGroup) => {
                    let newEventGroup = new _EventGroup(eventGroup);
                    newEventGroup.id = eventGroup.id;
                    return newEventGroup;
                });
                break;
            case "metadataState":
                $metadataState = JSON.parse(snapshot.value);
                break;
            case "projectState":
                $projectState = JSON.parse(snapshot.value);
                break;
            case "speakerState":
                $speakerState = JSON.parse(snapshot.value);
                break;
            case "issueState":
                $issueState = JSON.parse(snapshot.value);
                break;
            case "markerState":
                $markerState = JSON.parse(snapshot.value);
                break;
            case "fontState":
                $fontState = JSON.parse(snapshot.value);
                break;
            default:
                console.log("UNKNOWN STORE NAME");
        }
    });

    /* Ensure that we're always looking at the correct event group */
    if (!$eventGroupState[$projectState.selected]){
        $projectState.selected = $historyState.actions[$historyState.position - 1].eventGroup ?? 0;
    }
    
    if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events.length > Math.max(...selectedEvents)) {
        $eventGroupState[$projectState.selected].selected = selectedEvents;
        if (quillEditor){
            currentQuillIndex = currentQuillIndex ? currentQuillIndex.index : 0;
            setTimeout(() =>{
                quillEditor.setSelection(currentQuillIndex - (quillEditorLength - quillEditor.getLength()), 0);
            }, 25);
        }
    } else {
        $eventGroupState[$projectState.selected].selected = [];
    }
}

function openProject(){
    $explorerContextState = "load";
    modalState.showModal("storageExplorer");
}

</script>

<nav class="navbar navbar-expand bg-light bg-opacity-50 shadow" aria-label="Secondary Toolbar" style="height: 3vh;">
    <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navcol-1">
            <ul class="nav navbar-nav">
                <li class="nav-item" title="Open Project">
                    <a class="nav-link" href="#!/" on:click={() => openProject()}><i class="bi bi-folder"></i></a>
                </li>
                {#if !$environment.online || $projectState.useLocalStorage}
                    <OfflineSaveIcon></OfflineSaveIcon>
                {:else}
                    <OnlineSaveIcon></OnlineSaveIcon>
                {/if}                
                <div class="vr align-self-center"></div>
                <li class="nav-item" title="Export File" aria-label="Export File">
                    <a class="nav-link" href="#!/" on:click={() => modalState.showModal('fileExport')}><i class="bi bi-upload"></i></a>
                </li>
                <li class="nav-item" title="Import File">
                    <a class="nav-link" href="#!/" on:click={() => modalState.showModal('fileImport')}><i class="bi bi-download"></i></a>
                </li>     
                <div class="vr align-self-center"></div>      
                <li class="nav-item" title="Import Media File">
                    <a class="nav-link" href="#!/" on:click={() => modalState.showModal('mediaImport')}><i class="bi bi-collection-play"></i></a>
                </li>     
                <div class="vr align-self-center"></div>     
                <li class="nav-item" title="Undo">
                    <a class="nav-link {$historyState.position <= 1 ? 'disabled' : ''}" href="#!/" on:click={() => undo()}><i class="bi bi-arrow-counterclockwise"></i></a>
                </li>  
                <li class="nav-item" title="Redo">
                    <a class="nav-link {$historyState.position === $historyState.actions.length ? 'disabled' : ''}" href="#!/" on:click={() => redo()}><i class="bi bi-arrow-clockwise"></i></a>
                </li>  
                <div class="vr align-self-center"></div>
                <li class="nav-item" title="Automatic Transcription">
                    <a class="nav-link {!$projectState.media.path || $projectState.media.storage === "HLS Manifest" || $projectState.media.storage === "Vimeo" ? 'disabled' : ''}" href="#!/" on:click={() => modalState.showModal('automaticTranscription')}><i class="bi bi-mic"></i><sub><i class="bi bi-blockquote-left"></i></sub></a>
                </li>  
                {#if $authState.plan === 'pro' || $authState.plan === 'enterprise'}
                <li class="nav-item" title="Automatic Translation">
                    <a class="nav-link {!$eventGroupState[$projectState.selected] ? 'disabled' : ''}" href="#!/" on:click={() => modalState.showModal('automaticTranslation')}><i class="bi bi-translate"></i></a>
                </li>  
                {/if}
                <div class="vr align-self-center"></div>
                <li class="nav-item" title="Settings">
                    <a class="nav-link" href="#!/" on:click={() => modalState.showModal('options')}><i class="bi bi-gear"></i></a>
                </li> 
                <li class="nav-item" title="Keyboard Shortcuts">
                    <a class="nav-link" href="#!/" on:click={() => modalState.showModal('shortcutKeys')}><i class="bi bi-keyboard"></i></a>
                </li>
            </ul>
            <ul class="nav navbar-nav ms-auto">
                <AddEvent></AddEvent>
                <RemoveEvent></RemoveEvent>
                <div class="vr align-self-center"></div>
                <AlignLeft></AlignLeft>
                <AlignCenter></AlignCenter>
                <AlignRight></AlignRight>
                <div class="vr align-self-center"></div>
                <Position></Position>
                <PositionLineUp></PositionLineUp>
                <PositionLineDown></PositionLineDown>
                <div class="vr align-self-center"></div>
                <Copy></Copy>
                <Cut></Cut>
                <Paste></Paste>
                <div class="vr align-self-center"></div>
                <ShiftWordBack></ShiftWordBack>
                <ShiftWordForward></ShiftWordForward>
                <ShiftLineBack></ShiftLineBack>
                <ShiftLineForward></ShiftLineForward>
                <div class="vr align-self-center"></div>
                <Split></Split>
                <Merge></Merge>
                <div class="vr align-self-center"></div>
                <Expand></Expand>
                <Compress></Compress>
                <div class="vr align-self-center"></div>
                <SnapToStart></SnapToStart>
                <SnapToEnd></SnapToEnd>                                
            </ul>
        </div>
    </div>
</nav>
<style>
    ul {
        align-items: baseline;
    }
    
    .nav-link {
        font-size: 0.75vw;
    }
</style>