import _orderByTime from "./orderByTime.js";
const orderByTime = _orderByTime;
export default (function checkForOverlaps(eventGroup) {
  let overlaps = false;
  eventGroup = orderByTime(eventGroup);

  //Check to see if there are any overlaps in the start/end times of the events
  eventGroup.events.forEach((event, index) => {
    if (index > 0 && event.start < eventGroup.events[index - 1].end) {
      overlaps = true;
    }
  });
  return overlaps;
});