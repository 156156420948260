<script>
import { eventGroupState } from "@app/store/eventGroupStore.js";
import { projectState } from "@app/store/projectStore.js";
import { playerState } from "@app/store/playerStore.js";
import { lockState } from "@app/store/lockStore.js";
import { styleState } from "@app/store/styleStore.js";
import { onMount } from "svelte";
import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";
import convertToPlainText from "@app/external/cc-lib/dist/functions/quill/convertToPlainText.js";
import throttle from "just-throttle";

/* Segment duration is the value used to segment the Events in the Event Group. */
const SEGMENT_DURATION = 60;
let unsubscribeFromPlayerState,
    eventListElement,
    previewEventWrapper = [],
    nextEvent;

const getEvent = throttle(() => {
    if ($playerState.playing) {
        playerState.updatePreviewEvent(getEventByTime($playerState.time));
        if ($playerState.previewEvent !== -1) {
            nextEvent = $eventGroupState[$projectState.selected].events.find(
                (event) => {
                    return event.start > $playerState.time;
                },
            );
        }

        /* Scroll to and select the event if caption lock is enabled */
        if (
            $lockState.caption &&
            $playerState.previewEvent !== -1 &&
            $eventGroupState[$projectState.selected].selected[0] !==
                $playerState.previewEvent
        ) {
            $eventGroupState[$projectState.selected].selected = [
                $playerState.previewEvent,
            ];
            if (!document.getElementById("QuillEditor")) {
                eventListElement =
                    eventListElement || document.getElementById("EventList");
                eventListElement.scrollTo(0, $playerState.previewEvent * 230);
            }
        } else if (
            $lockState.caption &&
            $playerState.previewEvent !== -1 &&
            !document.getElementById("QuillEditor")
        ) {
            eventListElement =
                eventListElement || document.getElementById("EventList");
            eventListElement.scrollTo(0, $playerState.previewEvent * 230);
        }
    } else {
        playerState.updatePreviewEvent(
            $eventGroupState[$projectState.selected]?.selected[0],
        );
    }
}, 150, { leading: true });

function getEventByTime(sec){
    return $eventGroupState[$projectState.selected].events.findIndex(event=>{
        return event.start <= sec && event.end >= sec;
    });
}

function decToHex(value) {
    return Math.floor((value / 100) * 255)
        .toString(16)
        .padStart(2, "0");
}

$: getEvent(
    $playerState.time,
    $eventGroupState[$projectState.selected]?.selected[0]
);
</script>

{#if $lockState.preview}
    <div
        id="SubtitlePreviewWrapper"
        class="d-flex justify-content-center align-items-start">
        <div id="SubtitlePreview"
             role="region"
             aria-live="polite"
             aria-label="ADR preview">
            {#if $eventGroupState[$projectState.selected]}
                {#each $eventGroupState[$projectState.selected].events as previewEvent, evIndex (evIndex)}
                    {#if previewEvent.start && previewEvent.end && previewEvent.start > $playerState.time - SEGMENT_DURATION && previewEvent.start < $playerState.time + SEGMENT_DURATION}
                        <div
                            class="preview-event-wrapper"
                            bind:this={previewEventWrapper[evIndex]}
                            style="direction: {$eventGroupState[
                                $projectState.selected
                            ].rtl
                                ? 'rtl'
                                : 'ltr'}; 
                                font-family: {$styleState.fontFamily};
                                font-size: {$styleState.fontSize}px;
                                color: {$styleState.color +
                                decToHex($styleState.opacity)}; 
                                {$styleState.borderStyle == '1'
                                ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor + decToHex($styleState.shadowOpacity)}; 
                                -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor + decToHex($styleState.outlineOpacity)}; background: none;`
                                : `background: ${$styleState.outlineColor + decToHex($styleState.outlineOpacity)};
                                padding: ${$styleState.outline}px`};
                                left: {(
                                (previewEvent.start - $playerState.time) /
                                (previewEvent.end - previewEvent.start)
                            ).toFixed(2) *
                                (previewEventWrapper[evIndex]
                                    ? previewEventWrapper[evIndex].clientWidth
                                    : 100)}px;
                                top: {(evIndex % 5) *
                                (($styleState.fontSize * 1.6 +
                                    $styleState.outline * 2) *
                                    ($styleState.lineSpacing / 100))}px">
                            {convertToPlainText(previewEvent.text, " ")}
                        </div>
                    {/if}
                {/each}
            {/if}
        </div>
        <div
            id="center-line"
            style="height: {5 *
                ($styleState.fontSize * 1.6 + $styleState.outline * 2) *
                ($styleState.lineSpacing / 100) +
                2}px;">
        </div>
        {#if true}
            <div
                id="countdown-clock"
                style="top: {5 *
                    (($styleState.fontSize * 1.6 + $styleState.outline * 2) *
                        ($styleState.lineSpacing / 100)) +
                    2}px; width: {Math.max(
                    0,
                    (
                        (nextEvent ? nextEvent.start : 0) - $playerState.time
                    ).toFixed(2) * 100,
                )}px; height: 10%;">
            </div>
        {/if}
    </div>
{/if}

<style>
#SubtitlePreviewWrapper {
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
}

#SubtitlePreview {
    position: absolute;
    left: 50%;
    margin-left: -3px;
    user-select: none;
    text-wrap: nowrap;
}

.preview-event-wrapper {
    position: absolute;
    will-change: transform, left;
    transition: left 250ms linear;
}

#center-line {
    border-left: 5px solid #ff000096;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

#countdown-clock {
    background-color: red;
    position: absolute;
    will-change: width;
    left: 50%;
    transition: width 0.25s linear;
    margin-left: -3px;
}
</style>
