<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    fade
} from 'svelte/transition';

import tcLib from '@app/external/cc-lib/dist/lib/timecode.js'
let option = "tcOfLastEvent",
    endOfLastEventSec = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].events.length - 1].end,
    tcOfLastEvent = tcLib.secToTc(endOfLastEventSec+$projectState.incode, $projectState.frameRate, $projectState.dropFrame),

    refSubA = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0]+1 : 1,

    refSubATime = $eventGroupState[$projectState.selected].events[refSubA-1] ? tcLib.secToTc($eventGroupState[$projectState.selected].events[refSubA-1].start, $projectState.frameRate, $projectState.dropFrame) : "00:00:00:00",
    refSubB = Math.min(refSubA + 1, $eventGroupState[$projectState.selected].events.length),
    
    refSubBTime = $eventGroupState[$projectState.selected].events[refSubB-1] ? tcLib.secToTc($eventGroupState[$projectState.selected].events[refSubB-1].start, $projectState.frameRate, $projectState.dropFrame) : "00:00:00:00",

    startIndex = 1,
    endIndex = $eventGroupState[$projectState.selected].events.length;

function applyTcMultiplier() {
    let multiplier = 100;
    if (option === "tcOfLastEvent") {
        multiplier = (tcLib.tcToSec(tcOfLastEvent, $projectState.frameRate, $projectState.dropFrame)-$projectState.incode) / endOfLastEventSec;
    } else if (option === "increase") {
        multiplier = 1.001;
    } else if (option === "decrease") {
        multiplier = 1.000 / 1.001;
    } else {
        //Calculate multiplier based on reference events timecodes
        let refSubAStart = $eventGroupState[$projectState.selected].events[refSubA-1].start;
        let refSubBStart = $eventGroupState[$projectState.selected].events[refSubB-1].start;
        let refSubATimeSec = tcLib.tcToSec(refSubATime, $projectState.frameRate, $projectState.dropFrame);
        let refSubBTimeSec = tcLib.tcToSec(refSubBTime, $projectState.frameRate, $projectState.dropFrame);

        /* console.log("Ref A:",refSubAStart, refSubATimeSec);
        console.log("Ref B:",refSubBStart, refSubBTimeSec);
        console.log(refSubATimeSec/refSubAStart);
        console.log(refSubBTimeSec/refSubBStart); */
        multiplier = (((refSubATimeSec/refSubAStart) + (refSubBTimeSec/refSubBStart))/2).toFixed(6);
    }

    // console.log("Multiplier:",multiplier);

    $eventGroupState[$projectState.selected].events.forEach((event, evIndex, events) => {
        if (!option === "custom" || (evIndex >= startIndex-1 && evIndex <= endIndex-1)){
            events[evIndex].start = event.start * multiplier;
            events[evIndex].end = event.end * multiplier;
        }        
    });

    $eventGroupState[$projectState.selected].events = $eventGroupState[$projectState.selected].events;

    historyState.insert({
        name: "timecode stretch/shrink", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    
    modalState.hideModal();
}

function validateTc(e) {
    tcOfLastEvent = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
}

function validateTcEventA(e) {
    refSubATime = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
}

function validateTcEventB(e) {
    refSubBTime = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
}

function validateStartIndex(){
    if (startIndex > $eventGroupState[$projectState.selected].events.length){
        startIndex = $eventGroupState[$projectState.selected].events.length;
    }

    if (startIndex < 1){
        startIndex = 1;
    }    

    if (startIndex > endIndex){
        startIndex = endIndex;
    }
}

function validateEndIndex(){
    if (endIndex > $eventGroupState[$projectState.selected].events.length){
        endIndex = $eventGroupState[$projectState.selected].events.length;
    }

    if (endIndex < 1){
        endIndex = 1;
    }

    if (startIndex > endIndex){
        startIndex = endIndex;
    }
}

function frameByFrame(e) {
    if (e.key === "ArrowUp") {
        try {
            let smpteTc = tcLib.createTc(tcOfLastEvent, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.add(1);
            tcOfLastEvent = smpteTc.toString();
        } catch (e) {
            console.log(e, e.message);
        }
    } else if (e.key === "ArrowDown") {
        try {
            let smpteTc = tcLib.createTc(tcOfLastEvent, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.subtract(1);
            tcOfLastEvent = smpteTc.toString();
        } catch (e) {
            console.log(e, e.message);
        }
    }
}

function frameByFrameEventA(e) {
    if (e.key === "ArrowUp") {
        try {
            let smpteTc = tcLib.createTc(refSubATime, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.add(1);
            refSubATime = smpteTc.toString();
        } catch (e) {
            console.log(e, e.message);
        }
    } else if (e.key === "ArrowDown") {
        try {
            let smpteTc = tcLib.createTc(refSubATime, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.subtract(1);
            refSubATime = smpteTc.toString();
        } catch (e) {
            console.log(e, e.message);
        }
    }
}

function frameByFrameEventB(e) {
    if (e.key === "ArrowUp") {
        try {
            let smpteTc = tcLib.createTc(refSubBTime, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.add(1);
            refSubBTime = smpteTc.toString();
        } catch (e) {
            console.log(e, e.message);
        }
    } else if (e.key === "ArrowDown") {
        try {
            let smpteTc = tcLib.createTc(refSubBTime, $projectState.frameRate, $projectState.dropFrame);
            smpteTc.subtract(1);
            refSubBTime = smpteTc.toString();
        } catch (e) {
            console.log(e, e.message);
        }
    }
}

function updateToRefSubA(){
    if (refSubA > $eventGroupState[$projectState.selected].events.length){
        refSubA = $eventGroupState[$projectState.selected].events.length;
    }

    if (refSubA < 1){
        refSubA = 1;
    }

    refSubATime = $eventGroupState[$projectState.selected].events[refSubA-1] ? tcLib.secToTc($eventGroupState[$projectState.selected].events[refSubA-1].start, $projectState.frameRate, $projectState.dropFrame) : "00:00:00:00";
}

function updateToRefSubB(){
    if (refSubB > $eventGroupState[$projectState.selected].events.length){
        refSubB = $eventGroupState[$projectState.selected].events.length;
    }

    if (refSubB < 1){
        refSubB = 1;
    }

    refSubBTime = $eventGroupState[$projectState.selected].events[refSubB-1] ? tcLib.secToTc($eventGroupState[$projectState.selected].events[refSubB-1].start, $projectState.frameRate, $projectState.dropFrame) : "00:00:00:00";

}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'timeStretch' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="TimeStretchModal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Timecode Shrink/Stretch</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>

            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{applyTcMultiplier}">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="tcStretchOption1" bind:group={option} name="frameRate" value={"tcOfLastEvent"}>
                            <label class="form-check-label" for="tcStretchOption1">New end time of last event</label>
                        </div>
                        {#if option === "tcOfLastEvent"}
                        <input class="form-control mt-1 w-50" type="text" placeholder="SMPTE Format (e.g. hh:mm:ss:ff)" bind:value="{tcOfLastEvent}" on:blur={validateTc} on:keydown={frameByFrame} on:focus="{(e)=>{e.target.select()}}">
                        {/if}
                    </div>

                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="tcStretchOption2" bind:group={option} name="frameRate" value={"increase"}>
                            <label class="form-check-label" for="tcStretchOption2">Increase timecode by 1.001/1.000</label>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="tcStretchOption3" bind:group={option} name="frameRate" value={"decrease"}>
                            <label class="form-check-label" for="tcStretchOption3">Decrease timecode by 1.000/1.001</label>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="tcStretchOption4" bind:group={option} name="frameRate" value={"custom"}>
                            <label class="form-check-label" for="tcStretchOption4">Custom</label>
                        </div>
                        {#if option === "custom"}
                        <p class="lead text-warning mb-2">Reference Events</p>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="input-group">
                                    <span class="input-group-text">Event A</span>
                                    <input type="number" class="form-control" bind:value={refSubA} on:change={updateToRefSubA} min=1 max={$eventGroupState[$projectState.selected].events.length}>
                                    <span class="input-group-text">New timecode</span>
                                    <input type="text" class="form-control" bind:value={refSubATime} on:blur={validateTcEventA} on:keydown={frameByFrameEventA} on:focus="{(e)=>{e.target.select()}}">
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="input-group">
                                    <span class="input-group-text">Event B</span>
                                    <input type="number" class="form-control" bind:value={refSubB} on:change={updateToRefSubB} min=1 max={$eventGroupState[$projectState.selected].events.length}>
                                    <span class="input-group-text">New timecode</span>
                                    <input type="text" class="form-control" bind:value={refSubBTime} on:blur={validateTcEventB} on:keydown={frameByFrameEventB} on:focus="{(e)=>{e.target.select()}}">
                                </div>
                            </div>
                        </div>
                        <p class="lead text-warning mb-2">Event Range</p>
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text">Starting Event Id</span>
                                    <input type="number" class="form-control" bind:value={startIndex} min=1 max={$eventGroupState[$projectState.selected].events.length} on:blur={validateStartIndex}>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text">Ending Event Id</span>
                                    <input type="number" class="form-control" bind:value={endIndex} min=1 max={$eventGroupState[$projectState.selected].events.length} on:blur={validateEndIndex}>
                                </div>
                            </div>
                        </div>
                        {/if}
                    </div>

                    <button class="btn btn-primary float-end" type="button" on:click="{applyTcMultiplier}">Apply</button>
                </form>

            </div>
        </div>
    </div>
</div>
