<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

function markEvents() {
    try {
        $eventGroupState[$projectState.selected].selected.forEach(selectedEventIndex => {
            $eventGroupState[$projectState.selected].events[selectedEventIndex].forced = true;
        });

        historyState.insert({
            name: "forced events", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch(err){
        console.log(err);
        console.log(err.message);
    } finally {
        dispatch("done");
    }
}
</script>

<a id="btnForcedSubtitle"
    class="dropdown-item"
   role="menuitem"
   aria-label="Mark events as forced subtitle"
   href="#!/"
   on:click={markEvents}>
   Forced Subtitle
</a>
