<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import removeEvent from '@app/external/cc-lib/dist/functions/eventGroups/removeEvent.js';
import convertToPlainText from '@app/external/cc-lib/dist/functions/quill/convertToPlainText.js';
import eol from 'eol';

function shiftLineBack() {
    try {
        let eventRemoved = false;
        let html = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text;
        let lines = html.split("</p>");
        let numberOfTotalWords = html.split(/\s+/g).length;
        let firstLine = lines.shift();
        let plainTextLine = convertToPlainText(firstLine);
        let numberOfWordsInLine = plainTextLine.split(/\s+/g).length;
        let oStart = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start;
        let oEnd = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end;
        let oDuration = oEnd - oStart;

        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] - 1].text += firstLine + "</p>"

        /* Remove first word from event */
        if (quillEditor) {
            quillEditor.deleteText(0, plainTextLine.length + 1);
            if (quillEditor.getLength() === 1) {
                $eventGroupState[$projectState.selected] = removeEvent($eventGroupState[$projectState.selected], $eventGroupState[$projectState.selected].selected[0]);
                $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0] - 1];
                eventRemoved = true;
            }
        }

        if (oDuration && oDuration > 0){
            let avgDurationOfLine = (oDuration / numberOfTotalWords) * numberOfWordsInLine;
            if (eventRemoved){
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end += avgDurationOfLine;
            } else {
                $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start += avgDurationOfLine;
                if ($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] - 1].end > 0){
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] - 1].end += avgDurationOfLine;
                }
            }
        }

        historyState.insert({
            name: "shift line", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    } catch (err){
        console.log(err, err.message);
    }
}
</script>
<li class="nav-item" title="Shift Line Up">
    <a class="nav-link" href="#!/" class:disabled={!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0 || $eventGroupState[$projectState.selected].selected[0] === 0} on:click="{shiftLineBack}"><i class="bi bi-arrow-bar-up"></i><sub><i class="bi bi-fonts"></i></sub></a>
</li>
