export default (function cleanExtraSpanTags(htmlString) {
  // Stack to keep track of opening tags
  const stack = [];
  // Convert string to array of characters for easier processing
  const chars = htmlString.split('');
  let result = '';
  let i = 0;
  while (i < chars.length) {
    if (chars[i] === '<') {
      // Check if it's an opening span tag
      if (chars.slice(i, i + 5).join('') === '<span') {
        stack.push(i);
        // Add the opening tag to result
        while (i < chars.length && chars[i] !== '>') {
          result += chars[i];
          i++;
        }
        result += chars[i]; // Add the closing '>'
      }
      // Check if it's a closing span tag
      else if (chars.slice(i, i + 7).join('') === '</span>') {
        if (stack.length > 0) {
          // This is a valid closing tag
          stack.pop();
          result += '</span>';
        }
        // Skip the closing tag if there's no matching opening tag
        i += 7;
        continue;
      }
      // Handle other tags (like <br/>)
      else {
        result += chars[i];
      }
    } else {
      result += chars[i];
    }
    i++;
  }
  return result;
});