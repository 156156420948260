<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    timingAndSyncSettingsState
} from '@app/store/timingAndSyncSettingsStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    lockState
} from '@app/store/lockStore.js';
import {
    onMount
} from 'svelte';
import throttle from 'just-throttle';
const offsetTime = 0.5;
let activeEvent;

onMount(async () => {
    // Set up keyboard event listeners. When key commands match the keys in the shortcuts object, run the function.
    window.addEventListener("keydown", shortcutKeyUse);
    window.addEventListener("keyup", shortcutKeyUseUp);

    $lockState.caption = false;
    $lockState.video = false;

    return () =>{
        window.removeEventListener("keydown", shortcutKeyUse);
        window.removeEventListener("keyup", shortcutKeyUseUp);
    }
});

if (player){
    player.addEventListener("pause", () =>{
        activeEvent = undefined;
    });
}

function shortcutKeyUse(e) {
    try {
        if (e.repeat || $modalState){
            return;
        }

        let keyCmd = [];
        if (e.shiftKey) {
            keyCmd.push("Shift");
        }

        if (e.ctrlKey) {
            keyCmd.push("Ctrl");
        }

        if (e.altKey) {
            keyCmd.push("Alt");
        }

        if (e.metaKey) {
            keyCmd.push("Meta(CMD)");
        }

        keyCmd.push(e.code || e.key);
        keyCmd = keyCmd.join("+");
        if (keyCmd) {
            if ($timingAndSyncSettingsState.shortcuts["showEvent"] === keyCmd){
                if (!player || !player.state.playing){
                    return;
                }

                e.preventDefault();
                e.stopPropagation();

                if ($timingAndSyncSettingsState.timingMode === "long"){
                    updateIncode();
                } else {
                    showEvent();
                }
            } else if ($timingAndSyncSettingsState.shortcuts["hideEvent"] === keyCmd){
                if (!player || !player.state.playing){
                    return;
                }

                if ($timingAndSyncSettingsState.timingMode === "dual"){
                    e.preventDefault();
                    e.stopPropagation();
                    hideEvent();
                }

            }
        }
    } catch(err){
        console.log(err.message);
    }    
}

function shortcutKeyUseUp(e) {
    try {
        if ($modalState || !player || !player.state.playing || $timingAndSyncSettingsState.timingMode === "dual"){
            return;
        }

        let keyCmd = [];
        if (e.shiftKey) {
            keyCmd.push("Shift");
        }

        if (e.ctrlKey) {
            keyCmd.push("Ctrl");
        }

        if (e.altKey) {
            keyCmd.push("Alt");
        }

        if (e.metaKey) {
            keyCmd.push("Meta(CMD)");
        }

        keyCmd.push(e.code || e.key);
        keyCmd = keyCmd.join("+");
        if (keyCmd) {
            if ($timingAndSyncSettingsState.shortcuts["showEvent"] === keyCmd){
                e.preventDefault();
                e.stopPropagation();
                markOutcode();
            }
        }
    } catch(err){
        console.log(err.message);
    }    
}

function showEvent() {
    if ($eventGroupState[$projectState.selected]?.selected[0] > 0 && activeEvent){
        $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]-1].end = window.player.currentTime - $timingAndSyncSettingsState.compensation/$projectState.frameRate;
    }

    updateIncode();

    if ($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] + 1]) {
        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0] + 1];
    } else {
        $eventGroupState[$projectState.selected].selected = [];
    }

    historyState.insert({
        name: "update timecode", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}

function hideEvent() {
    if (!activeEvent){
        return;
    }
    
    activeEvent.end = player.currentTime;
    activeEvent = undefined;
}

function updateIncode() {
    try {
        if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0) {            
            $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].start = player.currentTime - $timingAndSyncSettingsState.compensation/$projectState.frameRate;
            activeEvent = $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]];
        }
    } catch (err) {
        console.log(err, err.message);
    }
}

const updateOutcode = throttle(()=>{
    try {
        if ($eventGroupState[$projectState.selected].selected.length > 0) {
            $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0] - ($timingAndSyncSettingsState.timingMode === "long" ? 0 : 1)].end = player.currentTime+offsetTime;
        } else {
            $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].events.length - 1].end = player.currentTime+offsetTime;
        }
    } catch (err) {
        console.log(err, err.message);
    }
}, 250, {leading: true});

async function markOutcode(){
    activeEvent = undefined;
    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].end = player.currentTime - $timingAndSyncSettingsState.compensation/$projectState.frameRate;    
    if ($eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]+1]){
        $eventGroupState[$projectState.selected].selected = [$eventGroupState[$projectState.selected].selected[0]+1];
    } else {
        player.pause();
    }  
}

$: if ($playerState.time && activeEvent) {
    updateOutcode();
}
</script>

<form>
    <div class="row justify-content-end mb-2">
        <div class="col-auto">
            <button
                id="timingAndSyncSettingsBtn"
                aria-label="Open timing and sync settings"
                type="button"
                class="btn btn-light btn-sm float-end"
                on:click={() => modalState.showModal("timingAndSyncSettings")}>
                <i class="bi bi-three-dots-vertical"></i>
            </button>
        </div>  
    </div>   
    {#if $timingAndSyncSettingsState.timingMode === "long"}
    <div class="row mb-2">
        <div class="col">
            <p class="text-muted small mb-1"><i class="bi bi-info-circle" title="The selected event to be timed. Hold down the up arrow key for the duration of time you want the event to display on screen."></i> Selected:</p>
            <div class="p-2 bg-light rounded border-0 textPreview text-center">
                {#if $eventGroupState.length > 0 && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0}
                    {@html $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text}
                {/if}
            </div>
        </div>
    </div>
    <div class="row mb-1">
        <div class="col">
            <button
                id="showEventLongBtn"
                aria-label="Show selected event"
                class="btn btn-primary w-100"
                type="button"
                on:mousedown="{updateIncode}"
                on:mouseup="{markOutcode}"
                disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}">
                <i class="bi bi-play-btn" /> Show Event
            </button>
        </div>
    </div>
    {:else}
    <div class="row mb-1 g-2">
        <div class="col-6">
            <p class="text-muted small mb-1"><i class="bi bi-info-circle" title="The current event active on screen"></i> Current:</p>
            <div class="p-2 bg-light rounded border-0 textPreview text-center resize-vertical">
                {#if activeEvent && activeEvent.text}
                    {@html activeEvent.text}
                {/if}
            </div>
        </div>
        <div class="col-6">
            <p class="text-muted small mb-1"><i class="bi bi-info-circle" title="The next event to be shown on screen"></i> Next:</p>
            {#if $eventGroupState.length > 0 && $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0}
            <div class="p-2 bg-warning bg-opacity-25 rounded border-0 textPreview text-center resize-vertical">
                {@html $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text}      
            </div>
            {:else}
                <div class="p-2 bg-light rounded border-0 textPreview text-center resize-vertical"></div>    
            {/if}
        </div>
    </div>
    <div class="row mb-1 g-2">
        <div class="col">
            <button
                id="hideEventBtn"
                aria-label="Hide current event"
                class="btn btn-light w-100"
                type="button"
                on:click={hideEvent}
                disabled="{!activeEvent}">
                <i class="bi bi-eraser" /> Hide Event
            </button>
        </div>
        <div class="col">
            <button
                id="showEventBtn"
                aria-label="Show next event"
                class="btn btn-primary w-100"
                type="button"
                on:click={showEvent}
                disabled="{!$eventGroupState[$projectState.selected] || $eventGroupState[$projectState.selected].selected.length === 0}">
                <i class="bi bi-play-btn" /> Show Event
            </button>
        </div>
    </div>
    {/if}
</form>

<style>
.textPreview {
    font-size: small;
    height: 75px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
