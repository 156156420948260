<script>
    import { environment } from "@app/store/envStore.js";
    import { historyState } from "@app/store/historyStore.js";
    import { modalState } from "@app/store/modalStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { authState } from "@app/store/authStore.js";
    import { metadataState } from "@app/store/metadataStore.js";
    import { speakerState } from "@app/store/speakerStore.js";
    import { issueState } from "@app/store/issueStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { tick } from "svelte";
    import { createEventDispatcher } from "svelte";
    import firebase from "@app/configs/firebase.js";
    import { replace } from "svelte-spa-router";
    /* Firebase */
    import db from "@app/configs/firestore.js";
    /* CC LIB */
    import project from "@app/external/cc-lib/dist/classes/project.js";
    import { saveAs } from "file-saver";
    import Swal from "sweetalert2";

    export let currentState;

    let updateAvailable = false;

    if ($environment.online) {
        db.collection("system")
            .doc("creator")
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    updateAvailable = data.version !== $environment.version;
                }
            });
    }

    const dispatch = createEventDispatcher();

    function toggleMenu() {
        dispatch("toggleMenu", {
            menu: "help",
        });
    }

    function backupUserSettings() {
        console.log("Backing up User Settings!");
        // Define the settings keys we want to export
        const settingsKeys = [
            "cc-editor-defaults",
            "cc-ad-defaults-new",
            "cc-event-group-defaults",
            "cc-shortcuts",
            "cc-theme",
            "cc-project-defaults",
            "cc-style-preset",
            "cc-timing-and-sync-settings",
        ];

        try {
            // Create an object to store all settings
            const settings = {};

            // Iterate through each key and get its value from localStorage
            settingsKeys.forEach((key) => {
                const value = localStorage.getItem(key);
                if (value) {
                    try {
                        // Try to parse the value as JSON
                        settings[key] = JSON.parse(value);
                    } catch (e) {
                        // If parsing fails, store as string
                        settings[key] = value;
                    }
                }
            });

            // Create metadata for the export
            const exportData = {
                version: "1.0",
                timestamp: new Date().toISOString(),
                settings: settings,
            };

            // Convert settings object to a JSON string with pretty printing
            const jsonString = JSON.stringify(exportData, null, 2);

            // Create a Blob with the JSON data
            const blob = new Blob([jsonString], { type: "application/json" });

            // Generate filename with timestamp
            const filename = `user-settings-${new Date().toISOString().split("T")[0]}.json`;

            // Save the file
            saveAs(blob, filename);
        } catch (error) {
            console.error("Error exporting settings:", error);
            throw new Error("Failed to export settings");
        }
    }

    const validateImportedSettings = (data) => {
        // Check if the data has the expected structure
        if (!data || typeof data !== "object") return false;
        if (!data.version || !data.timestamp || !data.settings) return false;

        return true;
    };

    async function importUserSettings() {
        try {
            // Show file input dialog
            const result = await Swal.fire({
                title: "Import Settings",
                html: `
        <div class="mb-3">
          <label for="settings-file-input" class="form-label">
            Choose Settings File
          </label>
          <input type="file"
                 class="form-control"
                 accept=".json" 
                 id="settings-file-input">
        </div>
      `,
                showCancelButton: true,
                confirmButtonText: "Import",
                cancelButtonText: "Cancel",
                didOpen: () => {
                    // Initialize Bootstrap file input
                    const input = document.getElementById(
                        "settings-file-input",
                    );
                    input.addEventListener("change", () => {
                        // Bootstrap's form-control class handles the file name display automatically
                    });
                },
                preConfirm: () => {
                    const fileInput = document.getElementById(
                        "settings-file-input",
                    );
                    if (!fileInput.files.length) {
                        Swal.showValidationMessage("Please select a file");
                        return false;
                    }
                    return fileInput.files[0];
                },
            });

            if (!result.isConfirmed || !result.value) {
                return;
            }

            // Read the file
            const file = result.value;
            const fileContent = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result);
                reader.onerror = (e) => reject(e);
                reader.readAsText(file);
            });

            // Parse and validate the JSON
            const settingsData = JSON.parse(fileContent);

            if (!validateImportedSettings(settingsData)) {
                throw new Error("Invalid settings file format");
            }

            // Show confirmation dialog
            const confirmResult = await Swal.fire({
                title: "Confirm Import",
                text: "This will overwrite your current settings. Are you sure you want to continue?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, Import Settings",
                cancelButtonText: "Cancel",
            });

            if (!confirmResult.isConfirmed) {
                return;
            }

            // Import the settings
            Object.entries(settingsData.settings).forEach(([key, value]) => {
                console.log(JSON.stringify(value));
                //Check if value is already a string
                if (typeof value === "string") {
                    localStorage.setItem(key, value);
                } else {
                    localStorage.setItem(key, JSON.stringify(value));
                }                
            });

            // Show success message
            await Swal.fire({
                title: "Success!",
                text: "Settings imported successfully. Please refresh the page for changes to take effect.",
                icon: "success",
            });
        } catch (error) {
            console.error("Error importing settings:", error);

            // Show error message
            await Swal.fire({
                title: "Error",
                text: error.message || "Failed to import settings",
                icon: "error",
            });
        }
    }

    async function resetApplication() {
        /* Reset Stores */
        if ($eventGroupState[$projectState.selected]) {
            $eventGroupState[$projectState.selected].selected = [];
            await tick();
        }

        $projectState.selected = false;
        await tick();
        $eventGroupState = [];
        await tick();
        $projectState = new project();
        $metadataState = [];
        $speakerState = [];
        $issueState = [];
        $markerState = {
            selected: 0,
            lists: [
                {
                    id: "0",
                    name: "Shot Changes",
                    color: "#E74C3C",
                    markers: [],
                },
            ],
        };
        authState.reset();
        historyState.reset();
        localStorage.clear();

        if ($environment.online) {
            firebase.auth().signOut();
        }

        if ($environment.electron) {
            const ipcRenderer = window.ipcRenderer;
            ipcRenderer.invoke("quit");
        } else {
            replace("/login");
        }
    }
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}">
    <a on:click={toggleMenu} class="dropdown-toggle nav-link" href="#!/">Help</a
    >
    <div class="dropdown-menu {currentState ? 'show' : ''}">
        <!-- Team Management -->
        {#if ($authState.plan === "pro" || $authState.plan === "enterprise") && $environment.online}
            <a
                class="dropdown-item"
                href="#!/"
                on:click={() => modalState.showModal("teamManager")}
                ><i class="bi bi-people-fill"></i> Team Management</a
            >
            <div class="dropdown-divider"></div>
        {/if}
        {#if $environment.online}
            <!-- Subscription Management -->
            <a
                class="dropdown-item"
                href="https://closedcaptioncreator.chargebeeportal.com/portal/v2/login"
                target="_blank"
                rel="noreferrer"
                >Manage Subscription <i class="bi bi-box-arrow-up-right small"
                ></i></a
            >
            <!-- User Guide -->
            <a
                class="dropdown-item"
                href="https://docs.google.com/document/d/e/2PACX-1vQjCdy__j5AOQKbQpyoNTRtF28BIhRgkYhWB-3tLZ10bQhWebqR9CH2-Jsd9qCGLp5wa2U89wu9vQFf/pub"
                target="_blank"
                rel="noreferrer"
                >Online User Guide <i class="bi bi-box-arrow-up-right small"
                ></i></a
            >
            <a
                class="dropdown-item"
                href="https://www.closedcaptioncreator.com/support.html"
                target="_blank"
                rel="noreferrer"
            >
                Contact Support <i class="bi bi-box-arrow-up-right small"></i>
            </a>
            <a
                class="dropdown-item"
                href="#!/"
                on:click={() => modalState.showModal("offlineActivation")}
                >Offline Activation</a
            >
            <a
                class="dropdown-item"
                href="#!/"
                on:click={() => modalState.showModal("usage")}
                >Reporting &amp; Usage</a
            >
            <a
                class="dropdown-item"
                href="#!/"
                on:click={() => modalState.showModal("autoSave")}
                >Restore Event Groups</a
            >
            <div class="dropdown-divider"></div>
        {/if}
        <a on:click={backupUserSettings} class="dropdown-item" href="#!/"
            ><i class="bi bi-download"></i> Backup User Settings</a
        >
        <a on:click={importUserSettings} class="dropdown-item" href="#!/"
            >Import User Settings</a
        >
        <a on:click={resetApplication} class="dropdown-item" href="#!/"
            >Reset Application</a
        >
        <hr class="dropdown-divider" />
        <a
            class="dropdown-item"
            href="#!/"
            on:click={() => modalState.showModal("tips")}>What's New</a
        >
        <a
            class="dropdown-item"
            href="#!/"
            on:click={() => modalState.showModal("about")}>About CC Creator</a
        >
        <hr class="dropdown-divider" />
        {#if updateAvailable}
            <a
                class="dropdown-item text-warning"
                href="https://www.closedcaptioncreator.com/downloads.html"
                target="_blank"
                title="New Update Available | Download Now"
                ><i class="bi bi-stars"></i> Update Available</a
            >
        {:else}
            <a class="dropdown-item disabled" href="#!/">No Updates Available</a
            >
        {/if}
    </div>
</li>
