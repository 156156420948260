<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from '@app/store/historyStore.js';
import autoFormat from '@app/external/cc-lib/dist/functions/eventGroups/autoFormat.js';
import autoFormatCheck from '@app/external/cc-lib/dist/functions/eventGroups/autoFormatCheck.js';
import fixOverlap from '@app/external/cc-lib/dist/functions/eventGroups/fixOverlap.js';
let maxLines = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].maxLines : 2,
    maxChars = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].maxChars : 32,
    minDuration = $eventGroupState[$projectState.selected] ? $eventGroupState[$projectState.selected].minDuration : 1,
    allowOrphanWords = false,
    selectiveFormat = true,
    applyAll = true;

function applyAutoFormat() {
    try {
        for (let i = 0; i < 2; i++) {
            if (selectiveFormat && applyAll) {
                $eventGroupState[$projectState.selected].selected = [];
                $eventGroupState[$projectState.selected].events.forEach((evt, index) => {
                    try {
                        if (autoFormatCheck(evt, maxLines, maxChars)) {
                            $eventGroupState[$projectState.selected].selected.push(index);
                        }
                    } catch (err){
                        console.log(err.message);
                    }                    
                });
            } else if (selectiveFormat) {
                let tempSelect = [];
                $eventGroupState[$projectState.selected].selected.forEach(evIndex => {
                    try {
                            if (autoFormatCheck($eventGroupState[$projectState.selected].events[evIndex], maxLines, maxChars)) {
                            tempSelect.push(evIndex);
                        }
                    } catch (err){
                        console.log(err.message);
                    }                    
                });

                $eventGroupState[$projectState.selected].selected = tempSelect;
            } else if (applyAll) {
                $eventGroupState[$projectState.selected].selected = $eventGroupState[$projectState.selected].events.map((event, count) => {
                    return count;
                });
            }
            try {
                $eventGroupState[$projectState.selected] = autoFormat($eventGroupState[$projectState.selected], maxLines, maxChars, minDuration, allowOrphanWords, $eventGroupState[$projectState.selected].selected);
            } catch (err){
                console.log(err.message);
            }
            
        }

        $eventGroupState[$projectState.selected] = fixOverlap($eventGroupState[$projectState.selected]);

        toast.push("Auto format completed", {
            classes: ["toast-success"]
        });

        historyState.insert({
            name: "auto format", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });

    } catch (err) {
        console.log(err, err.message);
        toast.push("Failed to auto format events", {
            classes: ["toast-danger"]
        });
    } finally {
        $eventGroupState[$projectState.selected].selected = [];
        modalState.hideModal();
    }

}
</script>

<div class="modal fade {$modalState === 'autoFormat' ? 'show d-block' : ''}" role="dialog" tabindex="-1" id="AutoFormatModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Auto Format</h4>
                <button type="button" class="btn-close" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{applyAutoFormat}">
                    <div class="mb-3">
                        <label class="form-label" for="maxLinesRange">Max Lines Per Event</label>
                        <input class="w-100" type="range" min="1" max="10" step="1" id="maxLinesRange" bind:value={maxLines}>
                        {maxLines}
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="maxCharsRange">Max Characters Per Line</label>
                        <input class="w-100" type="range" min="1" max="100" step="1" id="maxCharsRange" bind:value={maxChars}>
                        {maxChars}
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="minDurationRange">Minimum Duration (Seconds)</label>
                        <input class="w-100" type="range" min="0" max="25" step="0.01" id="minDurationRange" bind:value={minDuration}>
                        {minDuration}
                    </div>
                    <div class="row mb-3">
                        <div class="col-6">
                            <div class=" form-check">
                                <input type="checkbox" class="form-check-input" id="allowOrphanWordsCheck" bind:checked={allowOrphanWords}>
                                <label class="form-check-label" for="allowOrphanWordsCheck">Allow Orphan Words <i class="bi bi-info-circle" title="When performing the automatic format, Events can be split so that single words are left orhpaned. Disabling this option can cause line lengths to exceed the maximum number of characters specified, but will ensure that no single word Events are created."></i></label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="smartSelectCheck" bind:checked={selectiveFormat}>
                                <label class="form-check-label" for="smartSelectCheck">Smart Select <i class="bi bi-info-circle" title="When performing automatic format, Selected Events are only formatted if required."></i></label>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="applyToAll" bind:checked={applyAll}>
                        <label class="form-check-label" for="applyToAll">Apply to All <i class="bi bi-info-circle" title="Auto format all events in the selected event group."></i></label>
                    </div>
                    <div class="float-end">
                        <button class="btn btn-primary" on:click="{applyAutoFormat}" type="button">Auto Format</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
