<script>
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import {
    editorState
} from "@app/store/editorStore.js";
import {
    createEventDispatcher
} from 'svelte';
import insertEv from '@app/external/cc-lib/dist/functions/eventGroups/insertEvent.js';
export let currentState;
const dispatch = createEventDispatcher();

function toggleMenu() {
    dispatch('toggleMenu', {
        menu: 'insert'
    });
}

function insertEvent(above = false) {
    let eventOptions;
    let eventIndex = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].selected[0] : null;
    eventIndex = above ? eventIndex : eventIndex !== null ? eventIndex + 1 : null;
    
    if ($eventGroupState[$projectState.selected].events.length > 0){
        try {
            let eventTemplate = $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].events.length-1];

            eventOptions = {
                alignment : eventTemplate.alignment, 
                xPos : eventTemplate.xPos,
                yPos : eventTemplate.yPos,
                xOffset : eventTemplate.xOffset,
                yOffset : eventTemplate.yOffset,
                vertical : eventTemplate.vertical,
                voice : eventTemplate.voice,
                speakingStyle : eventTemplate.speakingStyle,
                style : eventTemplate.style,
                rate : eventTemplate.rate,
                start: $editorState.insertStartOnInsert ? player.currentTime : false,
                end: $editorState.insertStartOnInsert ? player.currentTime + $eventGroupState[$projectState.selected].minDuration : false,
            }     
        } catch(err){
            console.log(err);
        }
    }

    $eventGroupState[$projectState.selected] = insertEv($eventGroupState[$projectState.selected], eventOptions, eventIndex);
    $eventGroupState[$projectState.selected].selected = eventIndex !== null ? [eventIndex] : [$eventGroupState[$projectState.selected].events.length - 1];

    historyState.insert({
        name: "insert event", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
}

function insertBlock() {
    if (window.quillEditor === undefined){
        return;
    }
    
    let range = quillEditor.getSelection();
    if (range) {
        quillEditor.insertText(range.index, "█", "api");
        historyState.insert({
            name: "insert block", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    }
}

function insertEllipsis() {
    if (window.quillEditor === undefined){
        return;
    }

    let range = quillEditor.getSelection();
    if (range) {
        quillEditor.insertText(range.index, "…", "api");
        historyState.insert({
            name: "insert ellipsis", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState)
            }]
        });
    }
}

</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}" role="menuitem">
    <a on:click={toggleMenu} 
       class="dropdown-toggle nav-link" 
       href="#!/" 
       id="insertMenuButton"
       aria-haspopup="true"
       aria-expanded={currentState}
       role="button"
       aria-label="Insert menu">Insert</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}" 
         role="menu" 
         aria-labelledby="insertMenuButton">
        <a class="dropdown-item" href="#!/" on:click="{() => insertEvent(true)}" id="insertEventBefore" aria-label="Insert event before" role="menuitem"><i class="bi bi-arrow-up-circle" aria-hidden="true"></i> Event Before</a>
        <a class="dropdown-item" href="#!/" on:click="{() => insertEvent(false)}" id="insertEventAfter" aria-label="Insert event after" role="menuitem"><i class="bi bi-arrow-down-circle" aria-hidden="true"></i> Event After</a>
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click="{() => {insertBlock()}}" id="insertBlock" aria-label="Insert full block" role="menuitem">( █ ) Full Block</a>
        <a class="dropdown-item" href="#!/" on:click="{() => {insertEllipsis()}}" id="insertEllipsis" aria-label="Insert ellipsis" role="menuitem">( … ) Ellipsis</a>
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("musicNotes")} id="insertMusicNotes" aria-label="Insert music notes" role="menuitem">( ♪ ) Music Notes...</a>
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("insertSpeaker")} id="insertSpeaker" aria-label="Insert speaker name" role="menuitem">Speaker Name...</a>
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click={() => modalState.showModal("blankFrames")} id="insertBlankFrames" aria-label="Insert blank frames" role="menuitem">Blank Frames...</a>
    </div>
</li>
