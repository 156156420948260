<script>
import {
    environment
} from '@app/store/envStore.js';
import {
    modalState
} from "@app/store/modalStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    projectState
} from "@app/store/projectStore.js";
import {
    toast } from '@zerodevx/svelte-toast';
import {
    historyState
} from "@app/store/historyStore.js";
import {
    authState
} from "@app/store/authStore.js";
import firebase from "@app/configs/firebase.js";
import db from "@app/configs/firestore.js";
import {
    BarLoader
} from 'svelte-loading-spinners';

let userId = $environment.online ? firebase.auth().currentUser.uid : null,
    teamId = $environment.online && $authState.team ? $authState.team.id : null,
    homeRef,
    userRef,
    tagSets = [],
    selectedTagSet = {};

if ($environment.online) {
    homeRef = $authState.team ? db.collection("teams").doc(teamId).collection("tags") : db.collection("users").doc(userId).collection("tags");
    userRef = db.collection("users").doc(userId);
}

let gettingTagSets = setup()

function insertEventTag(tag) {
    if ($eventGroupState[$projectState.selected].selected.length > 0 && $eventGroupState[$projectState.selected]) {
        $eventGroupState[$projectState.selected].selected.forEach((eventId) => {
            if ($eventGroupState[$projectState.selected].events[eventId]) {
                $eventGroupState[$projectState.selected].events[eventId].tags = [
                    ...$eventGroupState[$projectState.selected].events[eventId].tags.filter((tg) => {
                        return tg.id !== tag.id;
                    }),
                    tag,
                ];
            }
        });

        toast.push("Event tags updated successfully", {classes: ['toast-success']});

        historyState.insert({
            name: "insert tag", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    }
}

function setup() {
    let activeSets = [];
    tagSets = [];
    //If environment.online is true use firebase to get user tags from firestore. Otherwise use local storage.

    if ($environment.online) {
        return userRef
        .get()
        .then((userDoc) => {
            let userInfo = userDoc.data();
            activeSets = userInfo.tags || [];
            if (activeSets.length > 0) {
                return homeRef.where("id", "in", activeSets).get();
            } else {
                return [];
            }
        })
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                tagSets = [...tagSets, doc.data()];
            });

            if (tagSets.length > 0) {
                selectedTagSet = tagSets[0];
            }
        })
        .catch((error) => {
            console.log("Error getting user tag sets: ", error);
            toast.push(error.message, {classes: ['toast-danger']});
        });
    } else {
        return new Promise((resolve, reject) => {
            let savedActiveSets = JSON.parse(localStorage.getItem("cc-active-tag-sets"));
            let savedTagSets = JSON.parse(localStorage.getItem("cc-tag-sets"));
            console.log(savedActiveSets, savedTagSets);

            if (savedActiveSets && savedTagSets) {
                tagSets = savedTagSets.filter((set) => {
                    return savedActiveSets.includes(set.id);
                });

                if (tagSets.length > 0) {
                    selectedTagSet = tagSets[0];
                }
                
                resolve();
            } else{
                tagSets = [];
                selectedTagSet = {
                    tags : [],
                    id : null
                };
            }

            resolve();
        });
    }

    
}
</script>

<div>
    {#await gettingTagSets}
    <div class="d-flex justify-content-center align-items-center p-3">
        <BarLoader size="200" color="#1eb4b2" unit="px" duration="3s"></BarLoader>
    </div>
    {:then}
    <div class="row g-1 mb-2">
        <div class="col-auto">
            <select class="form-select form-select-sm" bind:value={selectedTagSet} title="Selected Style Guide">
                {#each tagSets as set (set.id)}
                <option value={set} selected={selectedTagSet && selectedTagSet.id === set.id}>{set.name}</option>
                {/each}
            </select>
        </div>
        <div class="col">
            <button type="button" class="btn btn-sm btn-light" title="Refresh" on:click="{() => setup()}"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div class="col">
            <button type="button" class="btn btn-light btn-sm float-end" on:click={() => modalState.showModal("tagsManager")}>
                <i class="bi bi-three-dots-vertical"></i>
            </button>
        </div>
    </div>

    <div id="TagsWrapper" class="rounded">
        <div class="row mx-1 py-1">
            <div id="TagsList" class="col-11 p-2">
                {#if selectedTagSet && selectedTagSet.tags}
                {#each selectedTagSet.tags.sort((tgA, tgB) => {return tgA.type - tgB.type}) as tag}
                <button type="button" class="btn btn-sm btn-{tag.color} m-1" on:click="{insertEventTag(tag)}">
                    {tag.term}
                </button>
                {/each}
                {:else}
                <p class="m-3 text-muted">No tags found</p>
                {/if}
            </div>
        </div>
    </div>
    {/await}
</div>

<style>
#TagsWrapper {
    background-color: rgba(255, 255, 255, 0.03);
}
</style>
