<script>
import {
    projectState
} from '@app/store/projectStore.js';
import {
    explorerState
} from '@app/store/explorerStore.js';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    environment
} from '@app/store/envStore.js';
import {
    createEventDispatcher
} from 'svelte';

/* Components */
import Exit from "./items/Exit.svelte";
import Logout from "./items/Logout.svelte";
import OnlineSave from "./items/OnlineSave.svelte";
import OfflineSave from "./items/OfflineSave.svelte";

/* Amimation */
import { slide } from 'svelte/transition';
import { quintOut } from 'svelte/easing';

export let currentState;
const dispatch = createEventDispatcher();

let newMenu = false,
    viewMenu = false;

function toggleMenu() {
    dispatch('toggleMenu', {
        menu: 'file'
    });
}

function toggleNewMenu() {
    newMenu = !newMenu;
    toggleMenu();
}

function toggleViewMenu() {
    viewMenu = !viewMenu;
    toggleMenu();
}

function openProjectModal() {
    $explorerState.context = "load";
    modalState.showModal("storageExplorer");
    dispatch('toggleMenu', {
        menu: 'file'
    });
}

function openModal(modalName) {
    modalState.showModal(modalName);
    dispatch('toggleMenu', {
        menu: 'file'
    });
}

function toggleFullscreen() {
    if (document.fullscreenElement) {
        document.exitFullscreen();
    } else {
        document.documentElement.requestFullscreen();
    }
}
</script>

<li class="nav-item dropdown {currentState ? 'show' : ''}" role="menuitem">
    <a on:click={toggleMenu} 
       class="dropdown-toggle nav-link" 
       href="#!/"
       id="fileMenuButton"
       aria-haspopup="true"
       aria-expanded={currentState}
       role="button"
       aria-label="File menu">File</a>
    <div class="dropdown-menu {currentState ? 'show' : ''}" 
         role="menu" 
         aria-labelledby="fileMenuButton">
        <a aria-label="New menu" 
           on:click={toggleNewMenu} 
           class="dropdown-item dropdown-toggle" 
           href="#!/"
           id="newSubMenu"
           aria-expanded={newMenu}
           aria-haspopup="true"
           role="menuitem">New</a>
        {#if newMenu}
        <div transition:slide="{{duration: 300, easing: quintOut }}">
        <a  class="dropdown-item bg-secondary bg-opacity-50" href="#!/" on:click={toggleMenu} on:click={() => modalState.showModal("newProject")}>Project...</a>
        <a class="dropdown-item bg-secondary bg-opacity-50" href="#!/" on:click={() => openModal("newEventGroup")}>Event Group...</a>
        </div>
        {/if}
        
        {#if $environment.online}
            <a class="dropdown-item" href="#!/" on:click={openProjectModal}>Open Project...</a>
            <a class="dropdown-item" href="#!/" on:click={() => openModal("recentProjects")}>Open Recent</a>
        {:else}
            <a class="dropdown-item" href="#!/" on:click={() => openModal("projectImport")}>Open Project...</a>
        {/if}

        <hr class="dropdown-divider">
        {#if !$environment.online || $projectState.useLocalStorage}
        <OfflineSave></OfflineSave>
        {:else}
        <OnlineSave></OnlineSave>
        {/if}       
        <hr class="dropdown-divider">
        <a class="dropdown-item" href="#!/" on:click={() => openModal("fileImport")}>Import...</a>
        <a class="dropdown-item" href="#!/" on:click={() => openModal("fileExport")}>Export...</a>
        <a class="dropdown-item" href="#!/" on:click={() => openModal("batchSubtitleExport")}>Batch Export...</a>
        {#if $environment.electron}
            <hr class="dropdown-divider">
            <a on:click={toggleViewMenu} class="dropdown-item dropdown-toggle" href="#!/">View</a>
            {#if viewMenu}
            <div transition:slide="{{duration: 300, easing: quintOut }}">
                <a on:click={() => openModal("zoom")} class="dropdown-item bg-secondary bg-opacity-50" href="#!/">Zoom...</a>
                <a on:click={toggleFullscreen} class="dropdown-item bg-secondary bg-opacity-50" href="#!/"><i class="bi bi-fullscreen me-1"></i> Full Screen</a>
            </div>
            {/if}
        {/if}
        <hr class="dropdown-divider">
        <Logout></Logout>
        {#if $environment.electron}
            <Exit></Exit>                   
        {/if}
        
    </div>
</li>

<style>
.bg-secondary:hover {
    background-color: #0fbc8c !important;
}
</style>
