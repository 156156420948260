<script>
    import { modalState } from "@app/store/modalStore.js";
    import { markerState } from "@app/store/markerStore.js";
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { toast } from "@zerodevx/svelte-toast";
    import { historyState } from "@app/store/historyStore.js";
    import { fade } from "svelte/transition";
    import { Circle } from "svelte-loading-spinners";
    import orderByTime from "@app/external/cc-lib/dist/functions/eventGroups/orderByTime.js";

    //Get settings from local storage
    let defaultShotChangeSettings = JSON.parse(
        localStorage.getItem("cc-snap-to-shot-changes-settings")
    ) || {};

    let eventGapFrames = defaultShotChangeSettings.eventGapFrames || 0,
        startTolerance = defaultShotChangeSettings.startTolerance || 0.5,
        endTolerance = defaultShotChangeSettings.endTolerance || 0.5,
        processing = false;

    function syncToShotChanges() {
        try {
            if ($markerState.lists[0].markers.length === 0) {
                throw new Error(
                    "No shot change markers found. Please run shot change detection from the Ai Tools menu first.",
                );
            }

            processing = true;

            toast.push("Snap To shot changes started... please wait.", {
                classes: ["toast-info"],
            });

            $eventGroupState[$projectState.selected] = orderByTime(
                $eventGroupState[$projectState.selected]
            );

            $markerState.lists[0].markers.forEach((shotChange, markerIndex) => {
                try {
                    let closestStartEvent, closestEndEvent;
                    //Find all events whose start time is within the start tolerance of the shot change
                    let startToleranceEvents = $eventGroupState[$projectState.selected].events.filter(
                        (event) => {
                            return event.start >= shotChange.time - startTolerance && event.start <= shotChange.time + startTolerance;
                        }                        
                    );

                    //Find all events whose end time is within the end tolerance of the shot change
                    let endToleranceEvents = $eventGroupState[$projectState.selected].events.filter(
                        (event) => {
                            return event.end >= shotChange.time - endTolerance && event.end <= shotChange.time + endTolerance;
                        }                        
                    );

                    console.log(`There are ${startToleranceEvents.length} events within the start tolerance of ${shotChange.time}`);
                    console.log(`There are ${endToleranceEvents.length} events within the end tolerance of ${shotChange.time}`);

                    if (startToleranceEvents.length > 1){
                        //Find the event whose start time is closest to the shot change
                        closestStartEvent = startToleranceEvents.reduce((prev, curr) => {
                            return Math.abs(curr.start - shotChange.time) < Math.abs(prev.start - shotChange.time) ? curr : prev;
                        });
                        
                    } else if (startToleranceEvents.length === 1){
                        closestStartEvent = startToleranceEvents[0];
                    }

                    if (endToleranceEvents.length > 1){
                        //Find the event whose end time is closest to the shot change
                        closestEndEvent = endToleranceEvents.reduce((prev, curr) => {
                            return Math.abs(curr.end - shotChange.time) < Math.abs(prev.end - shotChange.time) ? curr : prev;
                        });
                    } else if (endToleranceEvents.length === 1){
                        closestEndEvent = endToleranceEvents[0];
                    }

                    if (!closestEndEvent && !closestStartEvent){
                        console.log("No events close to shotchange.", markerIndex, "Skipping this shot change.")
                        return;
                    }

                    //console.log(`The closest start event is ${JSON.stringify(closestStartEvent)}`);
                    //console.log(`The closest end event is ${JSON.stringify(closestEndEvent)}`);                    

                    if (closestStartEvent && closestEndEvent && closestStartEvent.id === closestEndEvent.id){
                        //If the events match we only need to update the start time. 
                        closestStartEvent.start = shotChange.time;
                    } else if (closestStartEvent && closestEndEvent){
                        //If the events don't match we need to update the start and end times of the events
                        closestStartEvent.start = shotChange.time;
                        closestEndEvent.end = shotChange.time;

                        if (eventGapFrames > 0){
                            //If the event gap is set higher than 0 we need to update the start time of closestStartEvent to be + eventGapFrames/frameRate
                            closestStartEvent.start += eventGapFrames / $projectState.frameRate;
                        }
                    } else if (closestStartEvent){
                        //If there is only a start event we need to update the start time
                        closestStartEvent.start = shotChange.time;
                    } else if (closestEndEvent){
                        //If there is only an end event we need to update the end time
                        closestEndEvent.end = shotChange.time;
                    }

                    if (closestEndEvent){
                        let closestEndEventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => event.id === closestEndEvent.id);
                        $eventGroupState[$projectState.selected].events[closestEndEventIndex] = closestEndEvent;
                        if (closestEndEventIndex < $eventGroupState[$projectState.selected].events.length - 1 && $eventGroupState[$projectState.selected].events[closestEndEventIndex+1].start <= closestEndEvent.end){
                            //Update the end time of the closestEndEvent to be eventGapFrames frames before the start of the next event
                            $eventGroupState[$projectState.selected].events[closestEndEventIndex+1].start = closestEndEvent.end + (eventGapFrames / $projectState.frameRate);
                        }
                    }

                    //Update the timing of the Events in the eventGroupState store
                    if (closestStartEvent){
                        let closestStartEventIndex = $eventGroupState[$projectState.selected].events.findIndex((event) => event.id === closestStartEvent.id);
                        $eventGroupState[$projectState.selected].events[closestStartEventIndex] = closestStartEvent;
                        if (closestStartEventIndex > 0 && $eventGroupState[$projectState.selected].events[closestStartEventIndex-1].end >= closestStartEvent.start){
                            //Update the start time of the closestStartEvent to be eventGapFrames frames after the end of the previous event
                            $eventGroupState[$projectState.selected].events[closestStartEventIndex-1].end = closestStartEvent.start;
                            $eventGroupState[$projectState.selected].events[closestStartEventIndex].start += eventGapFrames / $projectState.frameRate;                            
                        }
                    }
                } catch (err){
                    console.info("Error processing shot change", err.message, markerIndex, shotChange)
                    console.error(err)
                }
            });

            $eventGroupState[$projectState.selected] = $eventGroupState[$projectState.selected];

            historyState.insert({
                name: "snap to shot changes",
                eventGroup: $projectState.selected,
                snapshots: [
                    {
                        store: "eventGroupState",
                        value: JSON.stringify($eventGroupState),
                    },
                ],
            });

            toast.push("Snap To shot changes finished successfully", {
                classes: ["toast-success"],
            });
            //Save settings to local storage
            localStorage.setItem(
                "cc-snap-to-shot-changes-settings",
                JSON.stringify({
                    eventGapFrames : eventGapFrames,
                    startTolerance : startTolerance,
                    endTolerance : endTolerance
                }),
            );
        } catch (err) {
            console.error(err, err.message);
            toast.push(
                "Snap To shot changes failed with message: " + err.message,
                { classes: ["toast-danger"] },
            );
        } finally {            
            modalState.hideModal();
        }
    }
</script>

<div
    transition:fade={{ duration: 100 }}
    class="modal {$modalState === 'snapToShotChanges' ? 'show d-block' : ''}"
    role="dialog"
    tabindex="-1"
    id="snapToShotChangesModal"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Snap To Shot Changes</h4>
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    on:click={modalState.hideModal}
                ></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault={syncToShotChanges}>
                    <div class="row">
                        <div class="col-12 my-3">
                            <label class="form-label" for="eventGapFramesInput"
                                >Event Gap (Frames)</label
                            >
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="1"
                                placeholder="eventGapFramesInput"
                                bind:value={eventGapFrames}
                            />
                        </div>
                        <div class="col-6 my-3">
                            <label class="form-label" for="startTolerance"
                                >Start Tolerance (Seconds)</label
                            >
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="0.01"
                                placeholder="startTolerance"
                                bind:value={startTolerance}
                            />
                        </div>
                        <div class="col-6 my-3">
                            <label class="form-label" for="endTolerance"
                                >End Tolerance (Seconds)</label
                            >
                            <input
                                class="form-control"
                                type="number"
                                min="0"
                                step="0.01"
                                placeholder="endTolerance"
                                bind:value={endTolerance}
                            />
                        </div>
                    </div>
                    <button
                        class="btn btn-primary float-end"
                        type="button"
                        on:click={syncToShotChanges}
                        disabled={processing}
                        >{#if processing}<Circle
                                size="10"
                                color="#1eb4b2"
                                unit="px"
                                duration="1s"
                            ></Circle>{/if} Apply</button
                    >
                </form>
            </div>
        </div>
    </div>
</div>
