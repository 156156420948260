export default {
  frameRates: [23.976, 24, 25, 29.97, 30, 50, 59.94, 60],
  dropFrameMap: {
    23.976: false,
    24: false,
    25: false,
    29.97: true,
    30: false,
    50: false,
    59.94: true,
    60: false
  },
  frameRateMapping: {
    "23.98": 23.976,
    "23.976": 23.976,
    "24": 24,
    "25": 25,
    "29.97": 29.97,
    "df30": 29.97,
    "DF30": 29.97,
    "30": 30,
    "50": 50,
    "59.94": 59.94,
    "60": 60
  }
};