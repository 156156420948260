import _orderByTime from "./orderByTime.js";
const orderByTime = _orderByTime;
export default (function fixOverlap(eventGroup) {
  let eventCount = eventGroup.events.length - 1,
    checkForOverlaps = true;
  eventGroup = orderByTime(eventGroup);
  while (checkForOverlaps) {
    checkForOverlaps = false;
    for (eventCount; eventCount > 0; eventCount--) {
      if (eventGroup.events[eventCount].start < eventGroup.events[eventCount - 1].end) {
        eventGroup.events[eventCount].start = eventGroup.events[eventCount - 1].end;
        checkForOverlaps = true;
      }
    }
  }
  return eventGroup;
});