<script>
    export let event = {};

    function markEvent() {
        event.forced = !event.forced;
    }
    </script>
<button
  id="forcedSubtitleBtn-{event.id}"
  type="button"
  class="btn btn-link text-decoration-none text-custom-medium {event.forced ? 'text-info fw-bold' : 'text-dark'}"
  on:click="{() => markEvent()}"
  aria-label="Toggle forced subtitle"
  title="Forced Subtitle">
    FS
</button>

<style>
    button {
        padding : 2px;
        margin: 0;
    }
</style>