const stripTags = require("./stripTags.js");
const decodeHtml = require("html-entities").decode;
module.exports = function convertToPlainText(text, divider = "\n"){
    text = stripTags(decodeHtml(text).replace(/<\/p>/gmi, divider)).trim();
    
    //replace one or more spaces with a one space
    text = text.replace(/ +/g, " ");
    //Replace two or more periods with elipsis
    // text = text.replace(/\.{2,}/g, "…");

    text = text.replace(/(?<!-) ([.,])/g, "$1");
    return text;
}