<script>
import {
    environment
} from '@app/store/envStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    uiState
} from '@app/store/uiStore.js';
import {authState} from '@app/store/authStore.js';
import Styles from './Styles.svelte';
import FindAndReplace from './FindAndReplace.svelte';
import SpellCheck from './SpellCheck.svelte';
import StyleGuide from './StyleGuide.svelte';
import TimingAndSync from './TimingAndSync.svelte';
import EventTemplates from './EventTemplates.svelte';
import Voices from './Voices.svelte';
import Speakers from './Speakers.svelte';
import Tags from './Tags.svelte';
import Markers from './Markers.svelte';
import Notes from './Notes.svelte';
import VideoFilters from './VideoFilters.svelte';
import ManualQc from './ManualQc.svelte';
import ErrorNav from './ErrorNav.svelte';

$uiState.selected = "styles";

function updateSelectedTool(tool) {
    $uiState.selected = tool;
    if ($uiState.selected === "timingAndSync") {
        $projectState.mode = "timing";
    } else {
        $projectState.mode = "edit";
    }
}
</script>

<div class="row g-0 mt-2" id="QuickToolsWrapper">
    <div id="QuickTools" role="complementary" aria-label="Quick tools panel">
        <div>
            <ul id="QuickToolsNav" class="nav nav-tabs" role="tablist" aria-label="Quick tools navigation">
                <li class="nav-item" role="presentation">
                    <button type="button" id="styles-tab" 
                        class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'styles' ? 'active bg-light text-dark border-light' : ''}" 
                        on:click={() => updateSelectedTool("styles")}
                        role="tab"
                        aria-controls="styles-panel"
                        aria-selected={$uiState.selected === 'styles'}>Styles</button>
                </li>
                {#if $uiState.quickTools.search}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="search-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'findAndReplace' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("findAndReplace")}
                            role="tab"
                            aria-controls="search-panel"
                            aria-selected={$uiState.selected === 'findAndReplace'}>Search & Replace</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.spellCheck}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="spellCheck-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'spellCheck' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("spellCheck")}
                            role="tab"
                            aria-controls="spellCheck-panel"
                            aria-selected={$uiState.selected === 'spellCheck'}>Spell Check</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.timing}
                    <li class="nav-item" role="presentation">
                        <button id="timingSyncTab" type="button"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'timingAndSync' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("timingAndSync")}
                            role="tab"
                            aria-controls="timingAndSync-panel"
                            aria-selected={$uiState.selected === 'timingAndSync'}>Timing & Sync</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.qc}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="qcAndReview-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'qcAndReview' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("qcAndReview")}
                            role="tab"
                            aria-controls="qcAndReview-panel"
                            aria-selected={$uiState.selected === 'qcAndReview'}>QC & Review</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.manualQc}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="manualQc-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'manualQc' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("manualQc")}
                            role="tab"
                            aria-controls="manualQc-panel"
                            aria-selected={$uiState.selected === 'manualQc'}>Manual QC</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.errorNav}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="errorNav-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'errorNav' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("errorNav")}
                            role="tab"
                            aria-controls="errorNav-panel"
                            aria-selected={$uiState.selected === 'errorNav'}>Errors</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.speakers}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="speakers-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'speakers' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("speakers")}
                            role="tab"
                            aria-controls="speakers-panel"
                            aria-selected={$uiState.selected === 'speakers'}>Speakers</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.tags}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="tags-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'tags' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("tags")}
                            role="tab"
                            aria-controls="tags-panel"
                            aria-selected={$uiState.selected === 'tags'}>Tags</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.markers}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="markers-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'markers' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("markers")}
                            role="tab"
                            aria-controls="markers-panel"
                            aria-selected={$uiState.selected === 'markers'}>Markers</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.notes}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="notes-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'notes' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("notes")}
                            role="tab"
                            aria-controls="notes-panel"
                            aria-selected={$uiState.selected === 'notes'}>Notes</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.videoFilters}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="videoFilters-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'videoFilters' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("videoFilters")}
                            role="tab"
                            aria-controls="videoFilters-panel"
                            aria-selected={$uiState.selected === 'videoFilters'}>Video Filters</button>
                    </li>
                {/if}
                {#if $uiState.quickTools.templates}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="eventTemplates-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'eventTemplates' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("eventTemplates")}
                            role="tab"
                            aria-controls="eventTemplates-panel"
                            aria-selected={$uiState.selected === 'eventTemplates'}>Event Templates</button>
                    </li>
                {/if}
                {#if $authState.ad && $uiState.quickTools.voices && $environment.online}
                    <li class="nav-item" role="presentation">
                        <button type="button" id="voices-tab"
                            class="btn nav-link btn-link rounded-0 rounded-top {$uiState.selected === 'voices' ? 'active bg-light text-dark border-light' : ''}" 
                            on:click={() => updateSelectedTool("voices")}
                            role="tab"
                            aria-controls="voices-panel"
                            aria-selected={$uiState.selected === 'voices'}>Voices</button>
                    </li>
                {/if}
            </ul>
            <div class="tab-content p-2 border border-top-0 border-secondary rounded-bottom">
                <div id="styles-panel" role="tabpanel" aria-labelledby="styles-tab" class:hidden={$uiState.selected !== "styles"}>
                    {#if $uiState.selected === "styles"}
                        <Styles />
                    {/if}
                </div>
                <div id="search-panel" role="tabpanel" aria-labelledby="search-tab" class:hidden={$uiState.selected !== "findAndReplace"}>
                    {#if $uiState.selected === "findAndReplace" && $uiState.quickTools.search}
                        <FindAndReplace />
                    {/if}
                </div>
                <div id="spellCheck-panel" role="tabpanel" aria-labelledby="spellCheck-tab" class:hidden={$uiState.selected !== "spellCheck"}>
                    {#if $uiState.selected === "spellCheck"}
                        <SpellCheck/>
                    {/if}
                </div>
                <div id="timingAndSync-panel" role="tabpanel" aria-labelledby="timingAndSync-tab" class:hidden={$uiState.selected !== "timingAndSync"}>
                    {#if $uiState.selected === "timingAndSync" && $uiState.quickTools.timing}
                        <TimingAndSync></TimingAndSync>
                    {/if}
                </div>
                <div id="qcAndReview-panel" role="tabpanel" aria-labelledby="qcAndReview-tab" class:hidden={$uiState.selected !== "qcAndReview"}>
                    {#if $uiState.selected === "qcAndReview" && $uiState.quickTools.qc}
                        <StyleGuide></StyleGuide>
                    {/if}
                </div>
                <div id="manualQc-panel" role="tabpanel" aria-labelledby="manualQc-tab" class:hidden={$uiState.selected !== "manualQc"}>
                    {#if $uiState.selected === "manualQc" && $uiState.quickTools.manualQc}
                        <ManualQc></ManualQc>
                    {/if}
                </div>
                <div id="errorNav-panel" role="tabpanel" aria-labelledby="errorNav-tab" class:hidden={$uiState.selected !== "errorNav"}>
                    {#if $uiState.selected === "errorNav" && $uiState.quickTools.errorNav}
                        <ErrorNav></ErrorNav>
                    {/if}
                </div>
                <div id="speakers-panel" role="tabpanel" aria-labelledby="speakers-tab" class:hidden={$uiState.selected !== "speakers"}>
                    {#if $uiState.selected === "speakers" && $uiState.quickTools.speakers}
                        <Speakers></Speakers>
                    {/if}
                </div>
                <div id="tags-panel" role="tabpanel" aria-labelledby="tags-tab" class:hidden={$uiState.selected !== "tags"}>
                    {#if $uiState.selected === "tags" && $uiState.quickTools.tags}
                        <Tags></Tags>
                    {/if}
                </div>
                <div id="markers-panel" role="tabpanel" aria-labelledby="markers-tab" class:hidden={$uiState.selected !== "markers"}>
                    {#if $uiState.selected === "markers" && $uiState.quickTools.markers}
                        <Markers></Markers>
                    {/if}
                </div>
                <div id="notes-panel" role="tabpanel" aria-labelledby="notes-tab" class:hidden={$uiState.selected !== "notes"}>
                    {#if $uiState.selected === "notes" && $uiState.quickTools.notes}
                        <Notes></Notes>
                    {/if}
                </div>
                <div id="videoFilters-panel" role="tabpanel" aria-labelledby="videoFilters-tab" class:hidden={$uiState.selected !== "videoFilters"}>
                    {#if $uiState.selected === "videoFilters" && $uiState.quickTools.videoFilters}
                        <VideoFilters></VideoFilters>
                    {/if}
                </div>
                <div id="eventTemplates-panel" role="tabpanel" aria-labelledby="eventTemplates-tab" class:hidden={$uiState.selected !== "eventTemplates"}>
                    {#if $uiState.selected === "eventTemplates" && $uiState.quickTools.templates}
                        <EventTemplates></EventTemplates>
                    {/if}
                </div>
                <div id="voices-panel" role="tabpanel" aria-labelledby="voices-tab" class:hidden={$uiState.selected !== "voices"}>
                    {#if $uiState.selected === "voices" && $authState.ad && $uiState.quickTools.voices && $environment.online}
                        <Voices></Voices>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</div>

<style>
#QuickToolsNav {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: -webkit-box;
    will-change: transform;
}
.hidden {
    display: none;
}
</style>
