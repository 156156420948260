import { v4 as _uuidv } from "uuid";
const uuidv4 = _uuidv;
export default (class Project {
  constructor(options = {
    id: uuidv4(),
    teamId: uuidv4(),
    /* this is the project team id (not the user's team Id) used to manage projects in the cloud */
    type: "default",
    /* default or team or live */
    name: "Untitled",
    /* project title */
    mode: "edit",
    /* edit, timing, or review */
    state: "To Do",
    /* project state */
    description: "",
    /* project description */
    username: "",
    /* username/email */
    commit: false,
    //Commit Id of the current commit
    ownerId: false,
    //Team Owner Id for team projects
    rootDir: "personal",
    folderId: false,
    //Location where project is saved in Firebase Storage
    created: Date.now(),
    //When the project was created
    frameRate: 29.97,
    /* Project framerate */
    dropFrame: true,
    /* dropframe value */
    metadata: [],
    /* Project metadata */
    tags: [],
    /* Project tags */
    eventGroups: [],
    /* Event Groups */
    selected: false,
    /* Selected Event Group Index */
    compare: false,
    incode: 0,
    markers: {},
    issues: [],
    speakers: [],
    /* Speaker Names */
    media: {},
    /* Media/Video/Audio */
    style: {},
    useLocalStorage: false,
    localPath: null
  }) {
    this.id = options.id || uuidv4();
    this.teamId = options.teamId || uuidv4();
    this.version = 3;
    this.commit = options.commit;
    this.ownerId = options.ownerId;
    this.type = options.type || "default";
    this.name = options.name || "Untitled";
    this.mode = options.mode || "edit";
    this.state = options.state || "To Do";
    this.description = options.description || "";
    this.username = options.username || "";
    this.rootDir = options.rootDir || "personal";
    this.folderId = options.folderId;
    this.created = options.created || Date.now();
    this.frameRate = options.frameRate;
    this.dropFrame = options.dropFrame;
    this.metadata = options.metadata || [];
    this.eventGroups = options.eventGroups || [];
    this.selected = options.selected, this.compare = options.compare, this.incode = options.incode || 0;
    this.markers = options.markers || {};
    this.speakers = options.speakers || [];
    this.issues = options.issues || [];
    this.tags = options.tags || [];
    this.media = options.media || {};
    this.style = options.style || {};
    this.useLocalStorage = options.useLocalStorage;
    this.localPath = options.localPath;
  }
});