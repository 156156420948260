export default (function encodeHtml(eventGroup) {
  eventGroup.events.forEach((event, index, events) => {
    try {
      // let before = event.text;
      events[index].text = encodeHtmlEntities(event.text);
      // let after = events[index].text;

      // if (before !== after) {
      //     console.log("-------------");
      //     console.log(before);
      //     console.log(after);
      // }
    } catch (err) {
      console.log(index, event.text);
      console.log(err.message);
      console.log(err);
    }
  });
  return eventGroup;
});
function encodeHtmlEntities(str) {
  // Define allowed tags
  const allowedTags = ['p', 'span', 'strong', 'em', 'u'];

  // Build a regex to match allowed tags with attributes
  const allowedTagRegex = new RegExp(`<\\/?(${allowedTags.join('|')})(\\s+[^<>]*?)?>`, 'gi');

  // Replace allowed tags with placeholders
  const placeholders = [];
  let placeholderIndex = 0;
  str = str.replace(allowedTagRegex, function (match) {
    // Create a unique placeholder
    const placeholder = `___HTML_PLACEHOLDER_${placeholderIndex}___`;
    placeholders[placeholder] = match;
    placeholderIndex++;
    return placeholder;
  });

  // Encode the remaining < and > characters
  str = str.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Restore the allowed tags from placeholders
  str = str.replace(/___HTML_PLACEHOLDER_(\d+)___/g, function (match) {
    return placeholders[match];
  });
  return str;
}