<script>
    import { eventGroupState } from "@app/store/eventGroupStore.js";
    import { projectState } from "@app/store/projectStore.js";
    import { lockState } from "@app/store/lockStore.js";
    import { styleState } from "@app/store/styleStore.js";
    import { playerState } from "@app/store/playerStore.js";
    import { onMount } from "svelte";
    import throttle from 'just-throttle';

    let audioPlayer,
        eventListElement,
        currentAudioEvent = null,
        queuedAudioEvent = null,
        nextAudioEvent = null,
        unsubscribeFromPlayerState;

    onMount(() => {
        audioPlayer = document.getElementById("adPlayer");
        eventListElement = document.getElementById("EventList");

        player.addEventListener("seeked", playerSeekedFunc);
        player.addEventListener("play", playerPlayFunc);

        unsubscribeFromPlayerState = player.subscribe(
            ({ playing, currentTime }) => {
                if (!playing) {
                    pauseAudioPlayer();
                    clearAudioSrc();
                    currentAudioEvent = null;
                    queuedAudioEvent = null;
                    nextAudioEvent = null;
                } else {
                    if (
                        currentAudioEvent &&
                        currentTime >= currentAudioEvent.end
                    ) {
                        pauseAudioPlayer();
                        
                        /* If events are chained together, we need to load the next event right away */
                        if (nextAudioEvent && nextAudioEvent.start <= currentTime){
                            currentAudioEvent = nextAudioEvent;
                            nextAudioEvent = null;
                            queuedAudioEvent = null;

                            loadAudioSrc(currentAudioEvent.audioFile);
                            playAudioPlayer();                            
                            
                            playerState.updatePreviewEvent(
                                $eventGroupState[
                                    $projectState.selected
                                ].events.findIndex(
                                    (event) => event.id === currentAudioEvent.id,
                                )
                            );

                            selectEvent();                            
                        } else {
                            currentAudioEvent = null;
                            playerState.updatePreviewEvent(-1);
                        }                        
                    }

                    /* If there is a queued event and the current time goes over the start then start playback */
                    if (
                        queuedAudioEvent &&
                        currentTime >= queuedAudioEvent.start
                    ) {
                        playAudioPlayer();
                        currentAudioEvent = queuedAudioEvent;

                        playerState.updatePreviewEvent(
                            $eventGroupState[
                                $projectState.selected
                            ].events.findIndex(
                                (event) => event.id === currentAudioEvent.id,
                            ),
                        );

                        selectEvent();

                        queuedAudioEvent = null;
                        nextAudioEvent = null;
                    }
                }

                /* If there is no current event playing and there is a next event then load the next event */
                if (
                    !currentAudioEvent &&
                    nextAudioEvent &&
                    ((!queuedAudioEvent && nextAudioEvent) ||
                        queuedAudioEvent?.id !== nextAudioEvent?.id)
                ) {
                    queuedAudioEvent = nextAudioEvent;
                    nextAudioEvent = null;
                    loadAudioSrc(queuedAudioEvent.audioFile);
                }

                updateNextAudioEvent(currentTime);                
            });

        return () => {
            if (unsubscribeFromPlayerState) {
                unsubscribeFromPlayerState();
            }

            player.removeEventListener("seeked", playerSeekedFunc);
            player.removeEventListener("play", playerPlayFunc);
        };
    });

    function playerPlayFunc(){
        if (currentAudioEvent && player.currentTime >= currentAudioEvent.start && player.currentTime <= currentAudioEvent.end){
            playAudioPlayer();
        }
    }

    function playerSeekedFunc(ev){
        pauseAudioPlayer();
        clearAudioSrc();
        currentAudioEvent = null;
        queuedAudioEvent = null;
        nextAudioEvent = null;

        updateCurrentAudioEvent(ev.detail);  
        /* If there is no current event playing and there is a next event then load the next event */
        if (currentAudioEvent) {
            loadAudioSrc(currentAudioEvent.audioFile);
            audioPlayer.currentTime = Math.max(0, ev.detail - currentAudioEvent.start);
            if ($playerState.playing){
                playAudioPlayer();  
            }
        }
    }

    const updateNextAudioEvent = throttle((sec) => {
        if (nextAudioEvent){
            return;
        }

        const events = $eventGroupState[$projectState.selected].events;

        // Only consider events that start after current time
        const futureEvents = events.filter((event) => event.start >= sec);

        if (futureEvents.length === 0) {
            nextAudioEvent = null;
            return;
        }

        // Find the closest future event
        let closestEvent = futureEvents.reduce((closest, event) => {
            if (!closest) return event;
            return event.start < closest.start ? event : closest;
        }, null);

        if (closestEvent) {
            nextAudioEvent = closestEvent;
        }
    }, 100, {leading: true});

    function updateCurrentAudioEvent(sec){
        currentAudioEvent = getEventByTime(sec);
    }

    function getEventByTime(sec){
        return $eventGroupState[$projectState.selected].events.find(event=>{
            return event.start <= sec && event.end >= sec;
        });
    }

    function pauseAudioPlayer() {
        try {
            audioPlayer.pause();
        } catch (err) {
            console.log(err.message);
            audioPlayer = document.getElementById("adPlayer");
        }
    }

    function loadAudioSrc(audioFilePath) {
        try {
            audioPlayer.src = audioFilePath;
            audioPlayer.load();
        } catch(err){
            console.log("Load Source Error: " + err.message);
            audioPlayer = document.getElementById("adPlayer");
        }
        
    }

    function clearAudioSrc() {
        try {
            audioPlayer.pause();
            audioPlayer.removeAttribute("src");
        } catch (err) {
            console.log("Load Source Error: " + err.message);
            audioPlayer = document.getElementById("adPlayer");
        }
    }

    function playAudioPlayer() {
        try {
            audioPlayer.play();
        } catch (err) {
            console.log(err.message);
            audioPlayer = document.getElementById("adPlayer");
        }
    }

    function decToHex(value) {
        return Math.floor((value / 100) * 255)
            .toString(16)
            .padStart(2, "0");
    }

    function updatePreviewEvent(eventIndex) {
        if (!$playerState.playing){
            playerState.updatePreviewEvent(eventIndex);
            selectEvent();
        }        
    }

    function selectEvent() {
        /* Scroll to and select the event if caption lock is enabled */
        if ($lockState.caption && $playerState.previewEvent !== -1 && $eventGroupState[$projectState.selected].selected[0] !== $playerState.previewEvent){
            $eventGroupState[$projectState.selected].selected = [$playerState.previewEvent];

            if (!document.getElementById("QuillEditor")){
                eventListElement = eventListElement || document.getElementById("EventList");
                eventListElement.scrollTo(0, $playerState.previewEvent*230);
            }

        } else if ($lockState.caption && $playerState.previewEvent !== -1 && !document.getElementById("QuillEditor")){
            eventListElement = eventListElement || document.getElementById("EventList");                    
            eventListElement.scrollTo(0, $playerState.previewEvent*230);
        }
    }

$: updatePreviewEvent($eventGroupState[$projectState.selected]?.selected[0]);
</script>

<audio id="adPlayer" class="d-none" preload="auto">
    <source src="" type="audio/mpeg">
</audio>

{#if $lockState.preview && $eventGroupState[$projectState.selected]?.events[$playerState.previewEvent]}
    <div
        id="SubtitlePreviewWrapper"
        class="d-flex justify-content-start align-items-end"
    >
        <div
            id="SubtitlePreview"
            style="direction: {$eventGroupState[$projectState.selected].rtl
                ? 'rtl'
                : 'ltr'}; font-family: {$styleState.mode
                ? 'plexMono'
                : $styleState.fontFamily}; font-size: {$styleState.mode
                ? '16'
                : $styleState.fontSize}px; color: {$styleState.mode
                ? '#fff'
                : $styleState.color +
                  decToHex($styleState.opacity)}; line-height: {$styleState.mode
                ? 100
                : $styleState.lineSpacing}%; letter-spacing: {$styleState.mode
                ? `0.59ex`
                : `${$styleState.spacing}px`}; {$styleState.borderStyle == '1'
                ? `text-shadow: ${$styleState.shadow}px ${$styleState.shadow}px 3px ${$styleState.shadowColor + decToHex($styleState.shadowOpacity)}; -webkit-text-stroke: ${$styleState.outline}px ${$styleState.outlineColor + decToHex($styleState.outlineOpacity)}; background: none;`
                : `background: ${$styleState.mode ? 'black' : $styleState.outlineColor + decToHex($styleState.outlineOpacity)}; 
            padding: ${$styleState.mode ? 0 : $styleState.outline}px`};"
        >
            {@html $eventGroupState[$projectState.selected].events[
                $playerState.previewEvent
            ].text}
        </div>
    </div>
{/if}

<style>
    #SubtitlePreviewWrapper {
        position: absolute;
        pointer-events: none;
        overflow: hidden;
        height: 100%;
        width: 100%;
        top: 0;
    }

    #SubtitlePreview {
        user-select: none;
    }
</style>
