<script>
import {
    projectState
} from "@app/store/projectStore.js";
import {
    eventGroupState
} from "@app/store/eventGroupStore.js";
import {
    historyState
} from "@app/store/historyStore.js";
import throttle from "just-throttle";
import { clickOutside } from 'svelte-use-click-outside';
export let event = {};
let show = false;

function toggleNotes() {
    show = !show;
}

function closeNotes(){
    show = false;
}

function updateNotesStatus(){
    setTimeout(() => {
        event.notesCheck = !event.notesCheck;
        saveToHistory();
    }, 50);
}

function updateReplyStatus(){
    setTimeout(() => {
        event.replyCheck = !event.replyCheck;
        saveToHistory();
    }, 50);
}

function getNotesStatus(){
    //Return the class for the button based on the status of the notes and reply. If there is a note and a reply we need to check if both are done.
    if (event.notes && event.reply){
        return event.notesCheck && event.replyCheck ? "text-primary" : "text-warning";
    } else if (event.notes){
        return event.notesCheck ? "text-primary" : "text-warning";
    } else if (event.reply){
        return event.replyCheck ? "text-primary" : "text-warning";
    }
}

const saveToHistory = throttle(
    () => {
        historyState.insert({
            name: "update notes", //action name
            eventGroup: $projectState.selected,
            snapshots: [{
                store: "eventGroupState",
                value: JSON.stringify($eventGroupState),
            }, ],
        });
    },
    250, {
        leading: false,
        trailing: true
    }
);
</script>

<button type="button" class="btn btn-link text-custom-medium {event.notes || event.reply ? getNotesStatus() : 'text-dark'}" title="{event.notes}" on:click={() => toggleNotes()}>
    <i class="bi bi-chat-right-text"></i>
</button>
<ul class="dropdown-menu p-0 mt-4 border-0 {show ? 'show' : ''}" use:clickOutside={closeNotes}>
    <li class="p-4 bg-light rounded shadow">
        <button type="button" class="btn btn-link closeButton" title="Close" on:click={() => closeNotes()}><i class="bi bi-x-lg shadow"></i></button>
        
        <textarea id="NotesTextArea" on:input={() => saveToHistory()} class="notesInput p-1 rounded" bind:value={event.notes} placeholder="Notes"></textarea>

        <div class="form-check mb-2">
            <input id="eventNotesStatusCheck" class="form-check-input" type="checkbox" bind:checked={event.notesCheck} on:change={() => updateNotesStatus()}/>
            <label class="form-check-label" for="eventNotesStatusCheck"> Done </label>
        </div>
        <textarea id="ReplyTextArea" on:input={() => saveToHistory()} class="notesInput p-1 rounded" bind:value={event.reply} placeholder="Reply"></textarea>
        <div class="form-check">
            <input id="eventReplyStatusCheck" class="form-check-input" type="checkbox" bind:checked={event.replyCheck} on:change={() => updateReplyStatus()} />
            <label class="form-check-label" for="eventReplyStatusCheck"> Done </label>
        </div>
    </li>
</ul>

<style>
    .notesInput {
        resize:both;
        font-size: 1vw;
        height: 100px;
        width: 250px;
    }

    button {
        padding : 2px;
        margin: 0;
    }

    .closeButton {
        position: absolute;
        top: 0;
        right : 3px;
    }
</style>