import _convertToPlainText from "../quill/convertToPlainText.js";
const convertToPlainText = _convertToPlainText;
export default (function autoFormatCheck(event, maxLines, maxChars) {
  try {
    let text = convertToPlainText(event.text);
    let textLines = text.split("\n");
    //console.log(textLines, maxLines, maxChars);     
    if (textLines.length > maxLines) {
      //console.log("TOO MANY LINES. "+ textLines.length);
      return true;
    }
    if (textLines.some(st => st.length > maxChars)) {
      //console.log("TOO MANY CHARS. "+ textLines.length);
      return true;
    }
    return false;
  } catch (err) {
    console.log(err, err.message);
    return false;
  }
});