import _sccFunc from "../functions/profiles/scenerist.js";
import _sccLookup from "../dict/608.js";
import _mccFunc from "../functions/profiles/macCaption.js";
import _getFormatOptions from "../functions/helpers/getFormatOptions.js";
import _tcLib from "../lib/timecode.js";
import _removeInvalidEvents from "../functions/eventGroups/removeInvalidEvents.js";
const removeInvalidEvents = _removeInvalidEvents;
const tcLib = _tcLib;
const getFormatOptions = _getFormatOptions;
const mccFunc = _mccFunc;
const sccLookup = _sccLookup;
const sccFunc = _sccFunc;
export default {
  decode: function () {
    throw new Error("This profile [608/708 Vanc Data] is not supported for decoding by Closed Caption Converter.");
  },
  encode: async function (eventGroups, options) {
    let prefixMapping = {
      "23.976": "000100090104006B0000006C00000001",
      "24": "000100090104006B0000006C00000001",
      "25": "00010009010400680000006800000001",
      "29.97": "000100090104005C0000005C00000001",
      "30": "000100090104005C0000005C00000001",
      "50": "00010009010400680000006800000001",
      "59.94": "00010009010400680000006800000001",
      "60": "00010009010400680000006800000001"
    };
    let output = "",
      prefix = prefixMapping[options.frameRate.toString()],
      suffix = "",
      clock,
      channels = [],
      encodingOptions = getFormatOptions(options.formatOptions),
      mccVersion = "2.0",
      eventGroupChannelMappings,
      languageChannelMappings,
      vChipCmds,
      contentAdvisoryCmds,
      programNameCmds,
      programLengthCmds,
      vancChannelData,
      vancData = "",
      xdsDataWrittenAt = 0,
      writingVancData = false,
      ccCount = mccFunc.frameRateMapping[options.frameRate.toString()].cc_count;

    /* console.log(JSON.stringify(eventGroups, null, 4));
    console.log(JSON.stringify(options, null, 4)); */

    //The ccChannelStatus manages the status of each channel so that we know when clear/and start commands need to be sent/updated.
    let ccChannelStatus = [{
      id: "ch01",
      screen: false,
      buffer: false
    }, {
      id: "ch02",
      screen: false,
      buffer: false
    }, {
      id: "ch03",
      screen: false,
      buffer: false
    }, {
      id: "ch04",
      screen: false,
      buffer: false
    }, {
      id: "programA",
      screen: false,
      buffer: false
    }, {
      id: "programB",
      screen: false,
      buffer: false
    }, {
      id: "programC",
      screen: false,
      buffer: false
    }, {
      id: "programD",
      screen: false,
      buffer: false
    }, {
      id: "programE",
      screen: false,
      buffer: false
    }, {
      id: "programF",
      screen: false,
      buffer: false
    }];
    if (encodingOptions["MCC Version"]) {
      mccVersion = encodingOptions["MCC Version"];
    }

    /* Encode the V-Chip and other XDS Information */
    if (encodingOptions["Program Information"] && encodingOptions["Program Information"].enable) {
      if (encodingOptions["Program Information"].programName) {
        //Program Name & maybe description later on
        programNameCmds = sccFunc.encodeProgramName(encodingOptions["Program Information"].programName);
        programNameCmds = sccFunc.formatEncodedCmds(programNameCmds);
        /* Program Length */
        programLengthCmds = sccFunc.encodeProgramLength(encodingOptions["Program Information"].programLengthHours, encodingOptions["Program Information"].programLengthMinutes);
        programLengthCmds = sccFunc.formatEncodedCmds(programLengthCmds);
        /* Keyword Groups */
        contentAdvisoryCmds = sccFunc.encodeProgramType([encodingOptions["Program Information"].basicKeywordGroup, ...encodingOptions["Program Information"].detailKeywordGroup]);
        contentAdvisoryCmds = sccFunc.formatEncodedCmds(contentAdvisoryCmds);

        /* console.log("Program Name Commands:", programNameCmds);
        console.log("Program Length Commands:", programLengthCmds);
        console.log("Content Advisory Commands:", contentAdvisoryCmds); */
      }
    }
    if (encodingOptions["V-Chip Information"] && encodingOptions["V-Chip Information"].enable) {
      vChipCmds = sccFunc.encodeVChipInfo(encodingOptions["V-Chip Information"].type, encodingOptions["V-Chip Information"].rating, encodingOptions["V-Chip Information"].content);
      vChipCmds = sccFunc.formatEncodedCmds(vChipCmds);
      // console.log("VChip Commands:", vChipCmds);
    }
    if (!vChipCmds && !contentAdvisoryCmds && !programNameCmds && !programLengthCmds) {
      //Set XDS Data Written At way in the future so that we never try to write it. (because there is none set);
      xdsDataWrittenAt = 99999999999999999;
    }

    /* Sort through the Event Group Channel Mapping so that we know which channels to assign Event Groups to */
    if (encodingOptions["Event Group Channel Mappings"]) {
      eventGroupChannelMappings = encodingOptions["Event Group Channel Mappings"];
      // console.log(eventGroupChannelMappings["cc1"], eventGroups.length, eventGroups[eventGroupChannelMappings["cc1"]]);

      eventGroupChannelMappings = {
        "cc1": eventGroups[eventGroupChannelMappings["cc1"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc1"]])) : undefined,
        "cc2": eventGroups[eventGroupChannelMappings["cc2"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc2"]])) : undefined,
        "cc3": eventGroups[eventGroupChannelMappings["cc3"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc3"]])) : undefined,
        "cc4": eventGroups[eventGroupChannelMappings["cc4"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["cc4"]])) : undefined,
        "programA": eventGroups[eventGroupChannelMappings["programA"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programA"]])) : undefined,
        "programB": eventGroups[eventGroupChannelMappings["programB"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programB"]])) : undefined,
        "programC": eventGroups[eventGroupChannelMappings["programC"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programC"]])) : undefined,
        "programD": eventGroups[eventGroupChannelMappings["programD"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programD"]])) : undefined,
        "programE": eventGroups[eventGroupChannelMappings["programE"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programE"]])) : undefined,
        "programF": eventGroups[eventGroupChannelMappings["programF"]] ? JSON.parse(JSON.stringify(eventGroups[eventGroupChannelMappings["programF"]])) : undefined
      };
    } else {
      eventGroupChannelMappings = {
        "cc1": JSON.parse(JSON.stringify(eventGroups[0])),
        "programA": JSON.parse(JSON.stringify(eventGroups[0]))
      };
    }

    /* Figure out what language each channel is assigned. Default to English for all */
    if (encodingOptions["Language Channel Mappings"]) {
      languageChannelMappings = encodingOptions["Language Channel Mappings"];
    } else {
      languageChannelMappings = {
        "programA": "eng",
        "programB": "eng",
        "programC": "eng",
        "programD": "eng",
        "programE": "eng",
        "programF": "eng"
      };
    }

    /* Set the incode based on the options or default to the first event minus 300 frames */
    if (options.incode) {
      try {
        clock = tcLib.createTc(options.incode, options.frameRate, options.dropFrame);
      } catch (err) {
        throw new Error(err.message);
      }
    } else {
      clock = tcLib.createTc(tcLib.secToTc(eventGroups[0].events[0].start, options.frameRate), options.frameRate, options.dropFrame);
      try {
        clock.subtract(300);
      } catch (e) {
        clock = tcLib.createTc("00:00:00:01", options.frameRate, options.dropFrame);
      }
    }

    /* Loop over the Event GRoup Channel Mappings and for each Event figure out what the encode time, and encode codes are going to be. */
    Object.keys(eventGroupChannelMappings).forEach(ch => {
      if (!eventGroupChannelMappings[ch]) {
        return;
      }
      let channel = ch.replace("cc", "ch0");
      channels.push(channel);
      eventGroupChannelMappings[ch].events.forEach((event, index, events) => {
        events[index].processed = false;
        events[index].channel = channel;
        events[index].dtvWindow = index % 2 === 0 ? 0 : 1;
        events[index].startFrame = tcLib.createTc(tcLib.secToTc(event.start, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].endFrame = tcLib.createTc(tcLib.secToTc(event.end, options.frameRate), options.frameRate, options.dropFrame).frameCount;
        events[index].eventDetails = sccFunc.getEventDetails(event, options.window);
        events[index].encodedText = sccFunc.encodeEvent(events[index].eventDetails, channel.includes("ch0") ? channel : "ch01", options.window);
        events[index].encoded708Text = mccFunc.encodeDtvEvent(events[index].eventDetails, index % 2 === 0, options.window);
        events[index].encodedTextString = sccFunc.formatEncodedCmds(events[index].encodedText);
        events[index].encodeTime = sccFunc.calculateEncodeTime(events[index].encodedTextString);
        events[index].encodeStartTime = event.style === "Pop-On" ? events[index].startFrame - (events[index].encodeTime + 200) : events[index].startFrame;
      });
    });

    // console.log(JSON.stringify(eventGroupChannelMappings, null, 4));
    vancChannelData = {
      "dtv": {
        "programA": [],
        "programB": [],
        "programC": [],
        "programD": [],
        "programE": [],
        "programF": []
      },
      "atv": {
        "ch01": [],
        "ch02": [],
        "ch03": [],
        "ch04": []
      },
      "scc": []
    };

    /* CLEAR AND HIDE WINDOWS CMDS START */
    channels.forEach(channel => {
      if (channel.includes("ch0")) {
        vancChannelData.atv[channel].push(sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}"));
        vancChannelData.atv[channel].push(sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}"));
      } else {
        vancChannelData.dtv[channel].push("8A"); //Hide Window
        vancChannelData.dtv[channel].push("FF"); //All Windows
        vancChannelData.dtv[channel].push("8C"); //Delete Windows
        vancChannelData.dtv[channel].push("FF"); //All Windows
      }
    });
    writingVancData = true;
    while (writingVancData) {
      vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
      if (!vancData) {
        writingVancData = false;
      } else {
        if (vancData.length % 4) {
          vancData = vancData.slice(0, -2);
        }
        output += prefix + vancData + suffix;
        clock.add(1);
      }
    }
    /* CLEAR AND HIDE WINDOWS CMDS END */

    let eventsToProcess,
      processing = true;
    while (processing) {
      // console.log("Checking for events to display or clear at "+clock.toString());
      vancChannelData = sccFunc.getDisplayOrClearCommands(ccChannelStatus, clock.frameCount);

      // console.log("Display or Clear Commands", vancChannelData);
      writingVancData = true;
      while (writingVancData) {
        /* This is where display or clear commands would be written to the output otherwise we exit out of this. */
        vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
        if (!vancData) {
          writingVancData = false;
        } else {
          if (vancData.length % 4) {
            vancData = vancData.slice(0, -2);
          }
          output += prefix + vancData + suffix;
          clock.add(1);
        }
      }
      eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, clock.frameCount);
      // console.log("---------")
      // console.log("Events to Process at clock: "+clock.toString(),eventsToProcess.length,"\n", eventsToProcess.map(ev => ev.text.trim()).join("\n"));

      if (eventsToProcess.length > 0 && clock.frameCount < xdsDataWrittenAt + 90) {
        vancChannelData = {
          "dtv": {
            "programA": [],
            "programB": [],
            "programC": [],
            "programD": [],
            "programE": [],
            "programF": []
          },
          "atv": {
            "ch01": [],
            "ch02": [],
            "ch03": [],
            "ch04": []
          },
          "scc": []
        };
        let increaseClock = true;
        eventsToProcess.forEach((event, index, events) => {
          let channelIndex = ccChannelStatus.findIndex(ccChannel => ccChannel.id === event.channel);
          //console.log(channelIndex);
          if (event.style === "Pop-On" && ccChannelStatus[channelIndex].buffer) {
            // console.log("EVENTS IN BUFFER. CAN'T PROCESS");
            return;
          } else {
            increaseClock = false;
            // console.log("Writing event to channel: "+event.channel+" at "+clock.toString());
          }
          if (event.channel.includes('program')) {
            vancChannelData.dtv[event.channel].push(...event.encoded708Text);
          } else {
            vancChannelData.atv[event.channel].push(...event.encodedTextString.split(" "));
          }
          if (event.style === "Pop-On") {
            ccChannelStatus[channelIndex].buffer = event;
          } else {
            ccChannelStatus[channelIndex].screen = event;
          }
          events[index].processed = true;
        });

        // console.log(vancChannelData);

        writingVancData = true;
        while (writingVancData) {
          vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
          if (!vancData) {
            writingVancData = false;
          } else {
            if (vancData.length % 4) {
              vancData = vancData.slice(0, -2);
            }
            output += prefix + vancData + suffix;
            clock.add(1);
          }
        }
        if (increaseClock) {
          clock.add(1);
        }
      } else if (vChipCmds || contentAdvisoryCmds || programNameCmds || programLengthCmds) {
        // console.log("WRITING VCHIP AT "+clock.toString());
        let startTime = clock.frameCount;
        /* insert XDS Data */
        if (vChipCmds) {
          vancChannelData = {
            "dtv": {
              "programA": [],
              "programB": [],
              "programC": [],
              "programD": [],
              "programE": [],
              "programF": []
            },
            "atv": {
              "ch01": [],
              "ch02": [],
              "ch03": vChipCmds.split(" "),
              "ch04": []
            },
            "scc": []
          };
          writingVancData = true;
          while (writingVancData) {
            vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
            if (!vancData) {
              writingVancData = false;
            } else {
              if (vancData.length % 4) {
                vancData = vancData.slice(0, -2);
              }
              output += prefix + vancData + suffix;
              clock.add(1);
            }
          }
        }
        if (contentAdvisoryCmds) {
          vancChannelData = {
            "dtv": {
              "programA": [],
              "programB": [],
              "programC": [],
              "programD": [],
              "programE": [],
              "programF": []
            },
            "atv": {
              "ch01": [],
              "ch02": [],
              "ch03": contentAdvisoryCmds.split(" "),
              "ch04": []
            },
            "scc": []
          };
          writingVancData = true;
          while (writingVancData) {
            vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
            if (!vancData) {
              writingVancData = false;
            } else {
              if (vancData.length % 4) {
                vancData = vancData.slice(0, -2);
              }
              output += prefix + vancData + suffix;
              clock.add(1);
            }
          }
        }
        if (programNameCmds) {
          //First check to see if there are any Events to process or display:
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programNameCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            vancChannelData = {
              "dtv": {
                "programA": [],
                "programB": [],
                "programC": [],
                "programD": [],
                "programE": [],
                "programF": []
              },
              "atv": {
                "ch01": [],
                "ch02": [],
                "ch03": programNameCmds.split(" "),
                "ch04": []
              },
              "scc": []
            };
            writingVancData = true;
            while (writingVancData) {
              vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
              if (!vancData) {
                writingVancData = false;
              } else {
                if (vancData.length % 4) {
                  vancData = vancData.slice(0, -2);
                }
                output += prefix + vancData + suffix;
                clock.add(1);
              }
            }
          }
        }
        if (programLengthCmds) {
          //First check to see if there are any Events to process or display:
          eventsToProcess = sccFunc.getEventsToProcess(eventGroupChannelMappings, parseInt(clock.frameCount) + programLengthCmds.split(" ").length + 2);
          if (eventsToProcess.length === 0 && !sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
            vancChannelData = {
              "dtv": {
                "programA": [],
                "programB": [],
                "programC": [],
                "programD": [],
                "programE": [],
                "programF": []
              },
              "atv": {
                "ch01": [],
                "ch02": [],
                "ch03": programLengthCmds.split(" "),
                "ch04": []
              },
              "scc": []
            };
            writingVancData = true;
            while (writingVancData) {
              vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData, languageChannelMappings);
              if (!vancData) {
                writingVancData = false;
              } else {
                if (vancData.length % 4) {
                  vancData = vancData.slice(0, -2);
                }
                output += prefix + vancData + suffix;
                clock.add(1);
              }
            }
          }
        }
        if (startTime === clock.frameCount) {
          clock.add(1);
        }
        xdsDataWrittenAt = clock.frameCount;
      } else {
        vancData = mccFunc.encodeVancDataNew(clock.frameCount, options.frameRate, ccCount, vancChannelData = {
          "dtv": {
            "programA": [],
            "programB": [],
            "programC": [],
            "programD": [],
            "programE": [],
            "programF": []
          },
          "atv": {
            "ch01": ["8080"],
            "ch02": [],
            "ch03": [],
            "ch04": []
          },
          "scc": []
        }, languageChannelMappings);
        if (vancData.length % 4) {
          vancData = vancData.slice(0, -2);
        }
        output += prefix + vancData + suffix;
        clock.add(1);
      }

      /* Figure out if we need to close the loop */
      let eventsToStillProcess = sccFunc.getNumberOfEventsToProcess(eventGroupChannelMappings);
      if (eventsToStillProcess === 0) {
        if (sccFunc.eventsToDisplay(ccChannelStatus, clock.frameCount)) {
          processing = true;
        } else {
          processing = false;
        }
      }
    }

    // console.log(output);
    return output;
  },
  preProcess: {
    encode: function (eventGroup) {
      return removeInvalidEvents(eventGroup);
    },
    decode: function (input) {
      return input;
    }
  },
  postProcess: {
    encode: function (output) {
      return output;
    },
    decode: function (eventGroup) {
      return eventGroup;
    }
  }
};