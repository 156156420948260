<script>
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    metadataState
} from '@app/store/metadataStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    speakerState
} from '@app/store/speakerStore.js';
import {
    issueState
} from '@app/store/issueStore.js';
import {
    markerState
} from '@app/store/markerStore.js';
import {
    fontState
} from '@app/store/fontStore.js';
import {
    historyState
} from '@app/store/historyStore.js';

import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import _Event from '@app/external/cc-lib/dist/classes/event.js';

function undo() {
    historyState.undo();
    loadSnapshots();
    $eventGroupState = $eventGroupState;
}

function redo() {
    historyState.redo();
    loadSnapshots();
    $eventGroupState = $eventGroupState;
}

function loadSnapshots() {
    /* workaround to ensure no components break that rely on selected events */
    let selectedEvents = $eventGroupState[$projectState.selected]?.selected || [];
    let currentQuillIndex = window.quillEditor ? window.quillEditor.getSelection() : 0;
    let quillEditorLength = window.quillEditor ? window.quillEditor.getLength() : 0;

    $eventGroupState[$projectState.selected].selected = [];
    $historyState.actions[$historyState.position - 1].snapshots.forEach(snapshot => {
        switch (snapshot.store) {
            case "eventGroupState":
                $eventGroupState = JSON.parse(snapshot.value).map((eventGroup) => {
                    let newEventGroup = new _EventGroup(eventGroup);
                    newEventGroup.id = eventGroup.id;
                    return newEventGroup;
                });
                break;
            case "metadataState":
                $metadataState = JSON.parse(snapshot.value);
                break;
            case "projectState":
                $projectState = JSON.parse(snapshot.value);
                break;
            case "speakerState":
                $speakerState = JSON.parse(snapshot.value);
                break;
            case "issueState":
                $issueState = JSON.parse(snapshot.value);
                break;
            case "markerState":
                $markerState = JSON.parse(snapshot.value);
                break;
            case "fontState":
                $fontState = JSON.parse(snapshot.value);
                break;
            default:
                console.log("UNKNOWN STORE NAME");
        }
    });

    /* Ensure that we're always looking at the correct event group */
    if (!$eventGroupState[$projectState.selected]){
        $projectState.selected = $historyState.actions[$historyState.position - 1].eventGroup ?? 0;
    }
    
    if ($eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].events.length > Math.max(...selectedEvents)) {
        $eventGroupState[$projectState.selected].selected = selectedEvents;
        if (window.quillEditor && $eventGroupState[$projectState.selected]?.selected[0]){
            if (window.quillEditor.getSemanticHTML() !== $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text){
                quillEditor.clipboard.dangerouslyPasteHTML(
                    $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]].text
                );
            }
            
            currentQuillIndex = currentQuillIndex ? currentQuillIndex.index : 0;
            setTimeout(() =>{
                quillEditor.setSelection(currentQuillIndex - (quillEditorLength - quillEditor.getLength()), 0);
            }, 25);
        }
    } else {
        $eventGroupState[$projectState.selected].selected = [];
    }
}
</script>

<!-- Undo -->
<a class="dropdown-item {$historyState.position <= 1 ? 'disabled' : ''}" 
   href="#!/" 
   on:click="{undo}"
   role="menuitem"
   id="edit-undo"
   aria-disabled={$historyState.position <= 1}>
    <i class="bi bi-arrow-counterclockwise" aria-hidden="true"></i> Undo
    {#if $historyState.position > 1}
        <span class="sr-only">action: </span>{$historyState.actions[$historyState.position-1].name}
    {/if}
</a>
<!-- Redo -->
<a class="dropdown-item {$historyState.position === $historyState.actions.length ? 'disabled' : ''}" 
   href="#!/" 
   on:click="{redo}"
   role="menuitem"
   id="edit-redo"
   aria-disabled={$historyState.position === $historyState.actions.length}>
    <i class="bi bi-arrow-clockwise" aria-hidden="true"></i> Redo
    {#if $historyState.actions.length > $historyState.position}
    <span class="sr-only">action: </span>{$historyState.actions[$historyState.position].name}
    {/if}
</a>
