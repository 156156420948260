<script>
	import { historyState } from "@app/store/historyStore.js";
	import { projectState } from "@app/store/projectStore.js";
	import { eventGroupState } from "@app/store/eventGroupStore.js";
	import throttle from "just-throttle";

	let event = $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : false;

	const saveToHistory = throttle(
		() => {
			historyState.insert({
				name: "update notes", //action name
				eventGroup: $projectState.selected,
				snapshots: [
					{
						store: "eventGroupState",
						value: JSON.stringify($eventGroupState),
					},
				],
			});
		},
		250,
		{
			leading: false,
			trailing: true,
		}
	);

	$: event = $eventGroupState[$projectState.selected] && $eventGroupState[$projectState.selected].selected.length > 0 ? $eventGroupState[$projectState.selected].events[$eventGroupState[$projectState.selected].selected[0]] : false;
</script>

<form id="notes-form" role="form" aria-label="Event Notes">
	<div class="row">
		{#if event}
			<div class="col">
				<label for="notes-input" class="form-label">Notes:</label>
				<textarea 
					id="notes-input"
					class="w-100 p-1 rounded" 
					rows="3" 
					bind:value={event.notes} 
					disabled={!event} 
					placeholder="Notes" 
					on:input={() => saveToHistory()}
					aria-label="Event notes"
				></textarea>
				<div class="form-check">
					<input 
						id="notes-status-check" 
						class="form-check-input" 
						type="checkbox" 
						bind:checked={event.notesCheck} 
						on:change={() => saveToHistory()}
						aria-label="Mark notes as done"
					/>
					<label class="form-check-label" for="notes-status-check">Done</label>
				</div>
			</div>

			<div class="col">
				<label for="reply-input" class="form-label">Reply:</label>
				<textarea 
					id="reply-input"
					class="w-100 p-1 rounded" 
					rows="3" 
					bind:value={event.reply} 
					disabled={!event} 
					placeholder="Reply" 
					on:input={() => saveToHistory()}
					aria-label="Event reply"
				></textarea>
				<div class="form-check">
					<input 
						id="reply-status-check" 
						class="form-check-input" 
						type="checkbox" 
						bind:checked={event.replyCheck} 
						on:change={() => saveToHistory()}
						aria-label="Mark reply as done"
					/>
					<label class="form-check-label" for="reply-status-check">Done</label>
				</div>
			</div>
		{:else}
			<div class="col">
				<label for="disabled-textarea" class="form-label text-muted">No event selected</label>
				<textarea 
					id="disabled-textarea"
					class="w-100 p-1 rounded" 
					disabled 
					placeholder="Please select an Event"
					aria-label="No event selected"
				></textarea>
			</div>
		{/if}
	</div>
</form>
