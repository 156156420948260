<script>
import {
    fade
} from 'svelte/transition';
import {
    modalState
} from '@app/store/modalStore.js';
import {
    projectState
} from '@app/store/projectStore.js';
import {
    eventGroupState
} from '@app/store/eventGroupStore.js';
import {
    playerState
} from '@app/store/playerStore.js';
import {
    historyState
} from '@app/store/historyStore.js';
import { toast } from '@zerodevx/svelte-toast';
import {
    v4 as uuidv4
} from 'uuid';

import _Event from '@app/external/cc-lib/dist/classes/event.js';
import _EventGroup from '@app/external/cc-lib/dist/classes/eventGroup.js';
import tcLib from '@app/external/cc-lib/dist/lib/timecode.js'
let eventGroup = getSelectedEventGroup(["subtitle", "translation", "audio description"]),
    segmentationOption = "quantity", //time, quantity, manual
    numberOfSegments = 3,
    userSegments = [{
        id: uuidv4(),
        start : "00:00:00:00",
        end : "00:00:00:00"
    }];

function insertUserSegment(){
    userSegments = [...userSegments, {
        id: uuidv4(),
        start : "00:00:00:00",
        end : "00:00:00:00"
    }];
}

function removeUserSegment(){
    userSegments = userSegments.slice(0,-1);
}

function removeAllUserSegments(){
    userSegments = [{
        id: uuidv4(),
        start : "00:00:00:00",
        end : "00:00:00:00"
    }];
}

function validateTc(e, index, tcType) {
    userSegments[index][tcType] = tcLib.tcValidate(e.target.value, $projectState.frameRate, $projectState.dropFrame);
}

function getSelectedEventGroup(validTypes) {
    let evGroup = $eventGroupState[$projectState.selected];
    if (evGroup){
        if (validTypes.indexOf(evGroup.type) > -1) {
            return evGroup.id;
        } else {
            return false;
        }
    } else {
        return false;
    }    
}

function segmentEventGroups() {
    let segmentId = 1;
    let selectedEventGroup = $eventGroupState.find(evGroup =>{
        return eventGroup === evGroup.id;
    });

    if (segmentationOption === "quantity"){
        let avgNumberEventsPerGroup = Math.floor(selectedEventGroup.events.length/numberOfSegments) || 1;
        selectedEventGroup.events.forEach((event,count) =>{
            if (count%avgNumberEventsPerGroup === 0 && segmentId <= numberOfSegments){
                $eventGroupState.push(new _EventGroup({
                    ...selectedEventGroup, 
                    events : [], 
                    name : "Segment - "+segmentId++
                }));
            }

            $eventGroupState[$eventGroupState.length-1].events = [...$eventGroupState[$eventGroupState.length-1].events, new _Event(event)];
        });
    } else if (segmentationOption === "time"){
        let duration = $playerState.duration || selectedEventGroup.events[selectedEventGroup.events.length-1].end;
        if (!duration){
            toast.push("Unable to segment Event Group. No duration detected.", {classes: ['toast-danger']});

            return;
        }

        let avgDurationPerEventGroup = Math.floor(duration/numberOfSegments) || 1;
        let maxStart = avgDurationPerEventGroup;
        $eventGroupState.push(new _EventGroup({
            ...selectedEventGroup, 
            events : [], 
            name : "Segment - "+segmentId++
        }));
        selectedEventGroup.events.forEach(event =>{
            if (event.start>=maxStart && segmentId <= numberOfSegments){
                $eventGroupState.push(new _EventGroup({
                    ...selectedEventGroup, 
                    events : [], 
                    name : "Segment - "+segmentId++
                }));
                maxStart+=avgDurationPerEventGroup;
            }

            $eventGroupState[$eventGroupState.length-1].events = [...$eventGroupState[$eventGroupState.length-1].events, new _Event(event)];
        });
    } else {
        if (userSegments.length === 0){
            toast.push("Unable to segment Event Group. Please insert segments.", {classes: ['toast-danger']});

            return;
        }
        let minStartTime, maxStartTime, segmentEvents = [];
        userSegments.forEach(segment =>{
            $eventGroupState.push(new _EventGroup({
                ...selectedEventGroup, 
                events : [], 
                name : "Segment - "+segmentId++
            }));

            minStartTime = tcLib.tcToSec(segment.start, $projectState.frameRate, $projectState.dropFrame);
            maxStartTime = tcLib.tcToSec(segment.end, $projectState.frameRate, $projectState.dropFrame);
            segmentEvents = selectedEventGroup.events.filter(event =>{
                return event.start >= minStartTime && event.start < maxStartTime;
            })

            segmentEvents.forEach(event =>{
                $eventGroupState[$eventGroupState.length-1].events = [...$eventGroupState[$eventGroupState.length-1].events, new _Event(event)];
            });
        });
    }
    toast.push("Segmentation Complete", {classes: ['toast-success']});
    historyState.insert({
        name: "segment event group", //action name
        eventGroup: $projectState.selected,
        snapshots: [{
            store: "eventGroupState",
            value: JSON.stringify($eventGroupState)
        }]
    });
    modalState.hideModal();
}
</script>

<div transition:fade="{{duration: 100}}" class="modal {$modalState === 'segmentEventGroup' ? 'show d-block' : ''}" role="dialog" aria-labelledby="segmentEventGroupTitle" tabindex="-1" id="SegmentEventGroupModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="segmentEventGroupTitle">Segment Event Group <i class="bi bi-info-circle small" role="img" aria-label="Information" title="Divide the selected source Event Group into smaller Event Groups."></i></h4>
                <button type="button" class="btn-close" id="closeSegmentModal" aria-label="Close" on:click={modalState.hideModal}></button>
            </div>
            <div class="modal-body">
                <form on:submit|preventDefault="{segmentEventGroups}" aria-label="Segment Event Group Form">
                    <div class="mb-3">
                        <label class="form-label" for="eventGroupSelect">Source Event Group</label>
                        <select class="form-select" id="eventGroupSelect" bind:value="{eventGroup}" aria-label="Select source event group">
                            {#each $eventGroupState as eventGroupOption}
                            <option value="{eventGroupOption.id}">{eventGroupOption.name}</option>
                            {/each}
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="segmentationSelect">Segmentation Option</label>
                        <select class="form-select" id="segmentationSelect" bind:value="{segmentationOption}" aria-label="Select segmentation option">
                           <option value="time">By Time</option>
                           <option value="quantity">By Number of Events</option>
                           <option value="manual">Manual</option>
                        </select>
                        {#if segmentationOption === "time"}
                            <small class="form-text text-muted"><i class="bi bi-info-circle"></i> New Event Groups are created based on the media duration divided by the number of segments. Events are then placed into each new Event Group based on their start and end times.</small> 
                        {:else if segmentationOption === "quantity"}
                            <small class="form-text text-muted"><i class="bi bi-info-circle"></i> New Event Groups are created based on the number of events divided by the number of segments. Events are then placed into each new Event Group based on their original order.</small>
                        {:else if segmentationOption === "manual"}
                            <small class="form-text text-muted"><i class="bi bi-info-circle"></i> New Event Groups are created based on the segmentation data provided by the user.</small>
                        {/if}
                    </div>
                    {#if segmentationOption === "manual"}
                        <p class="lead text-warning">User Segments<span class="ms-3"><div class="btn-group btn-group-sm" role="group" aria-label="Segment controls">
                            <button type="button" class="btn btn-light" id="insertSegmentBtn" on:click="{insertUserSegment}" aria-label="Insert segment"><i class="bi bi-plus-lg" aria-hidden="true"></i></button>
                            <button type="button" class="btn btn-outline-dark" id="removeSegmentBtn" on:click="{removeUserSegment}" aria-label="Remove segment" disabled={userSegments.length === 0}><i class="bi bi-dash-lg" aria-hidden="true"></i></button>
                        </div><button type="button" class="btn btn-sm btn-outline-danger ms-2" id="clearSegmentsBtn" on:click="{removeAllUserSegments}" aria-label="Clear all segments">Clear All</button></span></p>
                        {#each userSegments as userSegment, index (userSegment.id)}
                            <div class="row">
                                <div class="input-group input-group-sm col">
                                    <div class="input-group input-group-sm">
                                      <span class="input-group input-group-sm-text">{index+1}</span>
                                    </div>
                                    <input type="text" class="form-control col" on:blur={(e) => validateTc(e,index,"start")} on:keyup={e=>e.key==='Enter' && validateTc(e,index,"start")} on:focus="{(e)=>{e.target.select()}}" bind:value={userSegment.start}>
                                    <input type="text" class="form-control col" on:blur={(e) => validateTc(e,index,"end")} on:keyup={e=>e.key==='Enter' && validateTc(e,index,"end")} on:focus="{(e)=>{e.target.select()}}" bind:value={userSegment.end}>
                                </div>                       
                            </div>
                        {/each}
                    {:else}
                        <div class="mb-3">
                            <label class="form-label" for="numberOfSegments">Number of Segments</label>
                            <input type="number" class="form-control" id="numberOfSegments" min="1" step="1" bind:value="{numberOfSegments}" aria-label="Enter number of segments">
                        </div>
                    {/if}
                </form>
            </div>
            <div class="modal-footer"><button class="btn btn-primary" type="button" id="segmentBtn" disabled="{!eventGroup}" on:click={segmentEventGroups} aria-label="Segment group">Segment Group</button></div>
        </div>
    </div>
</div>
