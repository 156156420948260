import { writable } from 'svelte/store';

function loadUiSettingsFromLocalStorage() {
    let uiSettings = {
        layout : "default", //default, reverse, vertical
        timeline : true,
        listPreview : false,
        quickTools : {
            selected : "styles",
            search : true,
            spellCheck : true,
            timing : true,
            qc : true,
            templates : true,
            voices : true,
            speakers : true,
            tags : true,
            notes : true,
            markers : true,
            videoFilters : true,
            manualQc : true
        }
    }

    let settings = JSON.parse(localStorage.getItem("cc-ui-settings")) || {};
    let defaultWorkspace = localStorage.getItem("cc-workspace-default") || 'default';
    uiSettings.layout = defaultWorkspace;
    Object.entries(settings).forEach(entry =>{
        if (typeof(entry[1]) === "object"){
            Object.entries(entry[1]).forEach(setting =>{
                uiSettings[entry[0]][setting[0]] = setting[1];
            }); 
        } else {
            uiSettings[entry[0]] = entry[1];
        }               
    });

    return uiSettings;
}

export const uiState = writable(loadUiSettingsFromLocalStorage());

